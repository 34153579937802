import React from "react";
import { Link } from "react-router-dom";
import { FadeLoader } from "react-spinners";
import activity from "../../Assets/images/icon/activity.png";
import cashbackBalance from "../../Assets/images/icon/cashback-balance.png";
import cashbackCalculation from "../../Assets/images/icon/cashback-calculation.png";
import earningBalance from "../../Assets/images/icon/earning-balance.png";
import earningCalculation from "../../Assets/images/icon/earning-calculation.png";
import link from "../../Assets/images/icon/link.png";
import referral from "../../Assets/images/icon/referral.png";
import setting from "../../Assets/images/icon/setting.png";
import update_password from "../../Assets/images/icon/update_password.png";
import workshop from "../../Assets/images/icon/workshop.png";
import avatar from "../../Assets/images/placeholder-image/avatar.png";

const UserDashboardMobile = ({
  auth,
  data,
  isLoadingUser,
  isLoading,
  pathname,
}) => {
  return (
    <div className={pathname === "/my-account" ? "block" : "hidden"}>
      <div className="w-full flex md:hidden items-center justify-start px-4 py-4 gap-x-4 bg-gradient-to-r from-green-100 via-gray-100 to-gray-50 border rounded-md">
        <div className="w-16 h-16 relative">
          <img
            src={
              auth?.currentUser?.photoURL ||
              data?.user?.googlePhoto ||
              data?.user?.photo?.url ||
              avatar
            }
            alt=""
            className="w-full h-full rounded-full border object-contain"
          />

          {(isLoading || isLoadingUser) && (
            <span className="absolute top-[50%] left-[50%] flex justify-center items-center">
              <FadeLoader
                color="#ffffff"
                height={5}
                margin={-9}
                radius={5}
                width={3}
              />
            </span>
          )}
        </div>
        <div className="flex flex-col items-start justify-start gap-y-1 mt-2">
          <h1
            className={`${
              data?.user?.fullname.length > 16
                ? "text-sm"
                : data?.user?.fullname.length > 12
                ? "text-base"
                : "text-lg"
            } font-outfit text-gray-600 font-medium rounded-md`}
          >
            {data?.user?.fullname}
          </h1>
          <p className="text-xs font-outfit text-gray-600 font-medium rounded-md">
            {data?.user?.phone}
          </p>
          <p className="text-xs font-outfit text-primaryColor">
            {data?.user?.email}
          </p>
        </div>
      </div>

      <div
        className={`grid grid-cols-3 sm:grid-cols-4 gap-x-4 gap-y-8 md:hidden w-full mt-6`}
      >
        <Link
          to="/my-account/cashback-balance"
          className="px-4 py-2 transition-all duration-500 ease-in-out cursor-pointer text-gray-500 text-sm flex flex-col justify-center items-center text-center  gap-x-2"
        >
          {/* <TfiWallet size={20} /> */}
          <img src={cashbackBalance} alt="" className="w-8" />
          <span className="whitespace-pre text-[10px]">Cashback Balance</span>
        </Link>

        <Link
          to="/my-account/earning-balance"
          className="px-4 py-2 transition-all duration-500 ease-in-out cursor-pointer text-gray-500 text-sm flex flex-col justify-center items-center text-center  gap-x-2"
        >
          {/* <TfiWallet size={20} /> */}
          <img src={earningBalance} alt="" className="w-8" />
          <span className="whitespace-pre text-[10px]">Affiliate Balance</span>
        </Link>
        <Link
          to="/my-account/cashback-calculation"
          className="px-4 py-2 transition-all duration-500 ease-in-out cursor-pointer text-gray-500 text-sm flex flex-col justify-center items-center text-center  gap-x-2"
        >
          <img src={cashbackCalculation} alt="" className="w-8" />
          <span className="whitespace-pre text-[10px]">Cashback Calc.</span>
        </Link>
        <Link
          to="/my-account/earning-calculation"
          className="px-4 py-2 transition-all duration-500 ease-in-out cursor-pointer text-gray-500 text-sm flex flex-col justify-center items-center text-center  gap-x-2"
        >
          <img src={earningCalculation} alt="" className="w-8" />
          <span className="whitespace-pre text-[10px]">Affiliate Calc.</span>
        </Link>
        <Link
          to="/my-account/make-affiliate-link"
          className="px-4 py-2 transition-all duration-500 ease-in-out cursor-pointer text-gray-500 text-sm flex flex-col justify-center items-center text-center gap-x-2"
        >
          {/* <AiOutlineLink size={20} /> */}
          <img src={link} alt="" className="w-8" />
          <span className="whitespace-pre text-[10px]">
            Make Affiliate Link
          </span>
        </Link>
        <Link
          to="/my-account/user-activity-log"
          className="px-4 py-2 transition-all duration-500 ease-in-out cursor-pointer text-gray-500 text-sm flex flex-col justify-center items-center text-center  gap-x-2"
        >
          <img src={activity} alt="" className="w-8" /> {/* <BsActivity /> */}
          <span className="whitespace-pre text-[10px]">Activity Log</span>
        </Link>
        <Link
          to="/my-account/online-workshop"
          className="px-4 py-2 transition-all duration-500 ease-in-out cursor-pointer text-gray-500 text-sm flex flex-col justify-center items-center text-center  gap-x-2"
        >
          {/* <SiGoogleclassroom size={30} /> */}
          <img src={workshop} alt="" className="w-8" />
          <span className="whitespace-pre text-[10px]">Online Workshop</span>
        </Link>

        {/* <Link
                  to="/my-account/cashback-favourite-brands"
                  className="px-4 py-2 transition-all duration-500 ease-in-out cursor-pointer text-gray-500 text-sm flex flex-col justify-center items-center text-center  gap-x-2"
                >
               
                  <img src={favCashback} alt="" className="w-10" />
                  <span className="whitespace-pre text-[10px]">
                    Cashback Fav. Brand
                  </span>
                </Link> */}

        {/* <Link
                  to="/my-account/earning-favourite-brands"
                  className="px-4 py-2 transition-all duration-500 ease-in-out cursor-pointer text-gray-500 text-sm flex flex-col justify-center items-center text-center  gap-x-2"
                >
           
                  <img src={favEarning} alt="" className="w-10" />
                  <span className="whitespace-pre text-[10px]">
                    Earning Fav. Brand
                  </span>
                </Link> */}

        <Link
          to="/my-account/my-referrals"
          className="px-4 py-2 transition-all duration-500 ease-in-out cursor-pointer text-gray-500 text-sm flex flex-col justify-center items-center text-center  gap-x-2"
        >
          <img src={referral} alt="" className="w-10" />
          <span className="whitespace-pre text-[10px]"> Referrals</span>
        </Link>
        <Link
          to={"/my-account/update-account"}
          className="px-4 py-2 transition-all duration-500 ease-in-out cursor-pointer text-gray-500 text-sm flex flex-col justify-center items-center text-center  gap-x-2"
        >
          {/* <TfiSettings size={20} /> */}
          <img src={setting} alt="" className="w-8" />
          <span className="whitespace-pre text-[10px]"> Edit Profile</span>
        </Link>
        <Link
          to={"/my-account/update-password"}
          className="px-4 py-2 transition-all duration-500 ease-in-out cursor-pointer text-gray-500 text-sm flex flex-col justify-center items-center text-center  gap-x-2"
        >
          {/* <TfiSettings size={20} /> */}
          <img src={update_password} alt="" className="w-8" />
          <span className="whitespace-pre text-[10px]">Change Password</span>
        </Link>

        {/* <div
                  onClick={handleLogout}
                  className="px-4 py-2 transition-all duration-500 ease-in-out cursor-pointer text-gray-500 text-sm flex flex-col justify-center items-center text-center  gap-x-2"
                >
                  <VscSignOut size={20} />
                  <span className="whitespace-pre text-[10px]"> Sign Out</span>
                </div> */}
      </div>
    </div>
  );
};

export default UserDashboardMobile;
