import React from "react";
const ReferalCard = ({ Icon, title, text }) => {
  return (
    <div className="h-full flex flex-row md:flex-col  gap-2 items-center justify-center">
      <div className="bg-green-50 p-4 md:p-8 rounded-full">
        <Icon className="text-primaryColor text-xl md:text-3xl" />
      </div>
      <div>
        <h1 className="text-gray-500 text-sm text-medium font-outfit text-left md:text-center">
          {title}
        </h1>
        <p className="text-gray-400 text-xs font-outfit md:text-center ">{text}</p>
      </div>
    </div>
  );
};

export default ReferalCard;
