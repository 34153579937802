import { apiRequest } from "../apiRequest/apiRequest";

export const balanceApiSlice = apiRequest.injectEndpoints({
  endpoints: (builder) => ({
    getUserOrderTrack: builder.query({
      query: (data) => {
        const { access_token, source, page, limit } = data;
        return {
          url: `/orders/track/user-activity-log?source=${source}&page=${page}&limit=${limit}`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${access_token}`,
            "Content-Type": "application/json;charset=UTF-8",
          },
        };
      },
      providesTags: ["Balance"],
    }),
    getUserClicks: builder.query({
      query: (data) => {
        const {
          access_token,
          source,
          month,
          date,
          startDate,
          endDate,
          page,
          limit,
        } = data;
        return {
          url: date
            ? `/get-shortlink-details?source=${source}&date=${date}&page=${page}&limit=${limit}`
            : month
            ? `/get-shortlink-details?source=${source}&month=${month}&page=${page}&limit=${limit}`
            : `/get-shortlink-details?source=${source}&startDate=${startDate}&endDate=${endDate}&page=${page}&limit=${limit}`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${access_token}`,
            "Content-Type": "application/json;charset=UTF-8",
          },
        };
      },
      providesTags: ["Balance"],
    }),
  }),
});

export const { useGetUserOrderTrackQuery, useGetUserClicksQuery } =
  balanceApiSlice;
