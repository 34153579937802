import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  brandDetails: "",
};

export const singleBrandSlice = createSlice({
  name: "singleBrandSlice",
  initialState,
  reducers: {
    setBrandDetails: (state, action) => {
      state.brandDetails = action.payload;
    },
    removeBrandDetails: (state) => {
      state.brandDetails = "";
    },
  },
});

// Action creators are generated for each case reducer function
export const { setBrandDetails, removeBrandDetails } = singleBrandSlice.actions;

export default singleBrandSlice.reducer;
