// copyToClipboardFunction.js
const copyToClipboardFunctionTest = (textToCopy) => {
  // Create a temporary textarea element
  const textarea = document.createElement("textarea");

  // Set the value of the textarea to the text you want to copy
  textarea.value = textToCopy;

  // Append the textarea to the document
  document.body.appendChild(textarea);

  // Select the text in the textarea
  textarea.select();

  try {
    // Execute the 'copy' command to copy the selected text to the clipboard
    document.execCommand("copy");
    // console.log("Text copied to clipboard:", textToCopy);
  } catch (err) {
    // console.error("Unable to copy to clipboard:", err);
  }

  // Remove the temporary textarea from the document
  document.body.removeChild(textarea);
};

export default copyToClipboardFunctionTest;
