import React from "react";
import error from "../../Assets/images/icon/error.png";

const Error = () => {
  return (
    <div className="w-full container ">
      <div className="flex flex-col justify-center items-center gap-x-2 text-center py-8 text-red-300 text-base">
        <img src={error} alt="" className="w-10 opacity-50" />{" "}
        <span className="text-xs text-gray-400">
          Something went wrong. <br />
          Please check your connection and try again.
        </span>
      </div>
    </div>
  );
};

export default Error;
