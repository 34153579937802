import Cookies from "js-cookie";
import React from "react";
import { AiOutlineLink } from "react-icons/ai";
import { useGetProductsByCategoryQuery } from "../../features/product/productApiSlice";
import ProductCard from "../Card/ProductCard";
import CommonComponent from "../CommonComponent/CommonComponent";
import Error from "../Error/Error";
import NoDataFound from "../NodataFound/NoDataFound";
import ProductCardSkeleton from "../Skeleton/ProductCardSkeleton";
const BestDealToShare = () => {
  const access_token = Cookies.get("ayykori");
  const { data, isLoading, isSuccess, isError } =
    useGetProductsByCategoryQuery(
      {
        access_token,
        page: 1,
        limit: 10,
      },
      { refetchOnReconnect: true }
    ) || {};

  let content = null;
  // handle loading case
  if (isLoading && !isSuccess) {
    content = (
      <>
        <div
          className=" w-full px-3 md:px-[55px] grid grid-cols-1 sm:grid-cols-2  md:grid-cols-3 lg:grid-cols-5
      gap-y-4 gap-x-4"
        >
          {Array(12)
            .fill()
            ?.map((item, i) => (
              <ProductCardSkeleton key={i} />
            ))}
        </div>
      </>
    );
  }
  // handle success case
  if (!isLoading && isSuccess && !isError) {
    content = (
      <>
        <div
          className="w-full px-3 md:px-[55px] grid grid-cols-2 sm:grid-cols-2  md:grid-cols-3 lg:grid-cols-5
      gap-y-2 md:gap-y-4 gap-x-2 md:gap-x-4"
        >
          {data?.resData &&
            isSuccess &&
            data?.resData?.map((item, i) => (
              <ProductCard
                key={i}
                data={{
                  id: item?._id,
                  image: item?.productImg,
                  name: item?.productTitle,
                  price: item?.productPrice,
                  rating: item?.productRating,
                  category: item?.category,
                  subCategory: item?.subCategory,
                  productDetails: item?.productDetails,
                  // brandId: item?.brandDetails[0]?._id,
                  brandId: item?.brandUnid,
                  productLink: item?.productLink,
                  discount: item?.productProfitInPercent,
                  profit: item?.earningProductProfitAmount,
                  brandimg: item?.brandimg,
                  whichBasisFav: "earning",
                  buttontitle: "Affiliate  Link",
                  Icon: AiOutlineLink,
                }}
              />
            ))}
        </div>
      </>
    );
  }
  // handle error case
  if (!isLoading && isError) {
    content = (
      <>
        <Error />
      </>
    );
  }
  // handle empty case
  if (!isLoading && isSuccess && data?.resData?.length === 0) {
    content = (
      <>
        <NoDataFound />
      </>
    );
  }
  return (
    <div className="my-10 md:my-16">
      <CommonComponent
        data={{
          title: "Best Deals To Share",
          link: "/affiliate/best-deals-to-share",
          linktitle: "See All",
          show: true,
        }}
      />
      {content}
    </div>
  );
};

export default BestDealToShare;
