import React, { useState } from "react";
import { Helmet } from "react-helmet";
import BanglaTramsAndConditions from "../../components/BanglaTramsAndConditions";
import CommonLayout from "../../layout/CommonLayout";

const TermsAndConditionPage = () => {
  const [bangla, setBangla] = useState(false);
  const handleReadBangla = () => {
    setBangla(!bangla);
  };
  return (
    <>
      <Helmet>
        <title>Terms & Conditions and Privacy Policy | Ayykori</title>
      </Helmet>
      <CommonLayout>
        <div className="w-full px-3 md:px-[55px] mt-20 md:mt-52 mb-10  ">
          <h1 className="text-center text-2xl font-bold custom-font ">
            {bangla
              ? "শর্তাবলী এবং গোপনীয়তা নীতি – AyyKori"
              : "Terms & Conditions and Privacy Policy – AyyKori"}
          </h1>

          {/* <div> */}
          <button
            className=" bg-primaryColor py-1 px-3 text-white rounded-md "
            onClick={handleReadBangla}
          >
            {" "}
            {bangla ? "Read English" : "Read Bangla"}
          </button>
          {/* </div> */}

          {bangla ? (
            <BanglaTramsAndConditions />
          ) : (
            <div>
              <h2>&nbsp;</h2>
              <p>
                <strong>1. Introduction:</strong>
              </p>
              <p className="">
                Welcome to www.AyyKori.net. The Service is operated by Masterkey
                Technologies Limited (“We”, “Us”, “AyyKori” or “Our” where such
                expression shall unless repugnant to the context thereof, be
                deemed to include its respective legal heirs, representatives,
                administrators, permitted successors and assigns). Please read
                these terms and conditions (“Terms and Conditions” or
                “Agreement”) carefully as they contain the legal terms and
                conditions that you agree to when you use the service provided
                by us through the Website and the App (“Platform”, “Website”).
              </p>
              <p>
                <strong>2. Membership:</strong>
              </p>
              <p>&nbsp;</p>
              <p>
                Our membership is available through registration after you
                submit certain requested information to AyyKori. During
                registration, we display our current Terms and Conditions for
                your perusal. AyyKori reserves the right to update and change
                the Terms and Conditions provided hereunder as and when
                required, without any prior consent or permission of the Users.
              </p>
              <p>
                Please be informed that AyyKori members may run any sponsored
                advertisements referring to AyyKori on Google, Facebook, or any
                other site. In such cases prior permission must be obtained from
                AyyKori in writing, failing to do so may cause the member’s
                account to be terminated at the discretion of AyyKori.
              </p>
              <p>
                <strong>3. Our Service:</strong>
              </p>
              <p>&nbsp;</p>
              <p>
                <strong>4. Becoming a Member:</strong>
              </p>
              <p>&nbsp;</p>
              If You are a natural person, (i) as a condition to Your use of the
              Platform, You must be 18 (eighteen) years of age or older; and
              (ii) by visiting the Platform or accepting these Terms and
              Conditions, You represent and warrant to AyyKori that You are 18
              (eighteen) years of age or older and that You have the right,
              authority and capacity to use the Platform and agree to and abide
              by these Terms and Conditions; provided however that if You are a
              parent or legal guardian of a person younger than 18 (eighteen)
              years of age, You are permitted to access and use the Platform for
              the purposes of procuring the Services, on behalf of such persons.
              You must register for the Service using accurate and current
              information about yourself – including your correct name, address
              and any other requested details. If you are asked for and provide,
              details of a bank account or Cheque payment details or other
              payment mode details into which you wish to receive payments (your
              “Profit/Cashback Receipt Method“), you (a) must ensure that you
              are, and remain, fully entitled to use that profit Receipt Method,
              and (b) confirm that you wish to receive profit through that
              Profit Receipt Method. You should keep this information updated
              through your Account. Generation, collection and dissemination of
              ‘Sensitive Personal Data or Information’ is in accordance with
              Digital Security Act, 2018 as amended from time to time and allied
              rules require the User’s express consent. By affirming assent to
              this Policy by clicking on the “I agree with the Terms and Policy”
              button at the time of registration, the User provides consent to
              such information generation and the collection of the same as
              required under applicable laws. We may also be required to share
              this data with our Brand Partners for the purpose of their risk
              analysis and any other statutory requirements. By clicking on the
              “I agree with the Terms and Policy” button you allow us to share
              your data and information with the Brand Partners. Note that your
              Profit/Cashback Receipt Method (such as BFTN, Cheque, MFS
              transfer, Gift Cards or other payment modes as We may introduce)
              may have rules about the maximum or minimum payment that you can
              receive through that Profit Receipt Method. Important: You must
              ensure that the email address and mobile number we hold for you
              are kept up-to-date and that you have full access to it – we will
              be sending you important messages there. If you change your email
              address or mobile number, then you must change the details we hold
              for you on your Account.
              <p>
                <strong>5. Profit and Referral Fees:</strong>
              </p>
              <p>
                After a Promoter/Shopper successfully completes a Qualifying
                Transaction, and once we have received the resulting Profit for
                that Qualifying Transaction, we pass that Profit to the
                Promoter/Shopper through his/her Profit Receipt Method. Such
                payments shall be applied for and will bedisbursed automatically
                after 45-80days of the application, to the Promoter/Shopper on
                the 1st day and the 16th day of every month, as long as it is a
                working day. If not, the amount will be disbursed on the first
                next working day.The Promoter/Shoppershall have to option to
                withdraw the full or a partial sum of the earned amount by
                clicking the Pop-Up withdraw button which will be made available
                on their portals on the dates specified above.The
                Promoter/Shopper shall have the right to choose their preferred
                payment mode (i.e Bank/Mobile Wallet) and Ayykori shall not be
                liable for non-transfer or wrongful transfer of payments in
                cases where the Promoter/Shopper has failed to provide the
                correct payment information into the Ayykori Portal/Dashboard. A
                Promoter/Shopper shall be allowed to connect their preferred
                payment details to a maximum of two (2) profiles with AyyKori.
                Please note that there are various circumstances in which a
                transaction with a Brand Partner may not constitute a Qualifying
                Transaction or Profit may not result from it.The
                Promoter/Shopper understands that all transactions made by
                him/her are not with us, but with the individual Brand Partner.
                Our help pages provide further information about these
                circumstances. Further, from time-to-timeBrand Partners may
                increase or decrease the commission paid – in which case the
                profit offer illustrated on our Platform may be incorrect. By
                default, your applicable transactions will be credited in line
                with the commission reported to us by the Brand Partner, which
                may be more or less than the advertised rate. We shall not be
                liable for any difference in the expected profit by the
                Promoter/Shopperand the actual amount received by them. When we
                have traced a paying, Qualifying Transaction to your Account, we
                will credit your Account with a ‘pending payment’. Once the
                Brand Partner confirms the transaction, the payment will be
                marked as ‘validated’ – this can take30 to80 days or more from
                the date of the purchase. If the goods are returned or the sale
                reversed or amended by any means then the payment will not be
                made. Apart from the Brand Partner, it is also mandatory for the
                Promoter/Shopperto intimate AyyKori about any returns or
                exchanges for which they might have earned money unduly. Whether
                a transaction shall qualify as a Qualifying for-Profit
                Transaction (including a Qualifying Referral Fee) shall be at
                the sole discretion of the Brand Partner or us and the
                Promoter/Shopperunderstands and agrees to the same. The maximum
                number of Qualifying Referrals are limited to _____ per
                user.AyyKori retains the right to check and cancel any referrals
                if it was created or obtained by fraud or self-referral.AyyKori
                shall not be held responsible if the Brand Partner fails to
                report a sale to us or does not qualify a transaction as
                Qualifying Transaction. Further, we shall not be responsible in
                the event due to some technical or other error we cannot trace a
                sale back to the Promoter/Shopperand does not qualify the
                transaction as a Qualifying Transaction or a Qualifying Referral
                for any reason whatsoever. Whilst we will endeavour to
                recuperate non-payments from the Brand Partner, the decision of
                ourselves, the Brand Partner or Brand Partner’s tracking agent
                is final. Whilst we will try and recuperate missing commissions,
                at any point we reserve the right not to chase missing
                commission claims, particularly where no purchase has been made.
                Further, in the event that the Brand Partner feels that the
                purchase is not genuine for any reason whatsoever and we do not
                receive any Profit for the transaction, the Promoter/Shopperwill
                not receive any payment. The AyyKori Service also provides a
                unique one-time referral fee to its Promoter/Shopperfor new
                Promoter/Shopper’s introduced by them in the manner provided on
                the Platform (“Qualifying Referral“), provided that the newly
                referredPromoter/Shopperremains a Promoter/Shopperthemselves for
                at least two successful payment cycles as specified above and
                additionally they will have to earn a qualifying amount of
                1000tk via Qualifying Transactions. No referral fee shall be
                disbursed to the referringPromoter/Shopperuntil and unless the
                referredPromoter/Shopperhas earned the qualifying amount as
                above. The company reserves the right to revise the qualifying
                amount at its own discretion without any notification to its
                Promoter/Shopper. We reserve the right to reclaim or make
                balance adjustments accordingly where it has been established
                that any credit has been applied in error. This will include but
                is not limited to transactions where the credit is not genuinely
                due or payment for any credited transaction has not been
                received from a Brand Partner or its agencies and/or misuse or
                fraud. This can include transactions which are already marked as
                payable or validated in your Account, or for transactions which
                have already been paid over to you by BFTN, MFS transfer,
                Cheque, Gift Cards or any other payment modes thatwe may
                introduce from time to time. In all such incidents, AyyKori has
                the right to recover all unduly paid profit that the Promoter is
                not entitled to earn, through legal proceedings as per the laws
                of the land.
              </p>
              <p>
                <strong>6. Your Account:</strong>
              </p>
              <p>&nbsp;</p>
              <p>
                You can withdraw your validated profit regardless of the amount
                earned on the above-mentioned payment cycle days. In the event,
                the Promoter/Shopper decides to close the Account with us (on
                purpose/by mistake), and if at such time of closure is before a
                designated payment cycle day, the Profits in your account shall
                be forfeited and the Promoter/Shopper will not be able to get
                any further payment from Ayykori. Even in situations where a
                Promoter/Shopper’s previously published links are being used to
                place orders, no payments shall be remitted to theiraccount and
                they will have to reopen an account on our website to avail of
                our services and benefits. Ayykori unequivocally retains the
                right to terminate or remove any user from its services, if any
                trace or suspicion of illegal or fraudulent activity is found or
                if they do not follow the terms and conditions provided herein
              </p>
              <p>
                <strong>7. Intellectual Property:</strong>
              </p>
              <p>&nbsp;</p>
              <p>
                You acknowledge that all copyright, trademarks, and other
                intellectual property rights in and relating to the Platform
                (including the material which is contributed by Promoters or
                Brand Partners) are owned by, or licensed to, us. It is easy to
                copy material which appears on web-sites, but this does not mean
                it is legal. Therefore, no-one may copy, distribute, show in
                public or create any derivative work from the Platform, or any
                of the material which is found on the Platform unless properly
                licensed to do so by us. By uploading or including any material
                on the Platform, a Promoter expressly grants: a) to us a
                non-exclusive license (including the right to grant
                sub-licenses) to use, reproduce and distribute that material
                through our Service and any other interactive services through
                which we or our sub-license make the Service (or a service based
                on our service) available; and b) to other Promoters (through
                us, under the license referred to herein above), the
                non-exclusive, personal, non-transferable right to view the
                relevant material.
              </p>
              <p>
                <strong>8. Privacy Policy:</strong>
              </p>
              <p>&nbsp;</p>
              <p>
                Our Privacy Policy forms part of this Agreement, and by entering
                into this Agreement you also give your consent to the way we
                handle your personal data under that policy. Given the global
                nature of the World Wide Web, please note that a posting on the
                Platform may be accessible to internet users around the world.
                Masterkey Technologies Limited (as the case may be) (We”, “Our”,
                “AyyKori” or “Us”, where such expression shall unless repugnant
                to the context thereof, be deemed to include its respective
                legal heirs, representatives, administrators, permitted
                successors and assigns) own and/ or operate the website and
                mobile application AyyKori.com (“Website”, “Our Website”,
                “Site”). For the purpose of providing the Services (as defined
                in clause 1 below), AyyKori is required to collect and use
                certain information of the users of the Website (“Users”) using
                the Services and involves capturing, storage and transmission of
                such information. This privacy policy (“Privacy Policy“/
                “Policy”) explains how We collect, use, share and protect
                personal information of the Users of the Services (jointly and
                severally referred to as “You”, “Your”, “Yourself” or “User” or
                “Users” in this Privacy Policy). We have created this Privacy
                Policy to ensure our steady commitment to the privacy of the
                information of the Users who interact with our Services. Your
                use of and access to the Services is subject to this Privacy
                Policy and our Terms and Conditions. Any capitalized term used,
                but not defined, in this Privacy Policy shall have the meaning
                attributed to it in our Terms and Conditions. The headings used
                herein are only for the purpose of arranging the various
                provisions of the Privacy Policy. The headings are for the
                purpose of reference only and shall not be interpreted to limit
                or expand the provisions of the clauses contained therein.
              </p>
              <p>
                <strong>8.1. Definitions</strong>
              </p>
              <p>
                In this Privacy Policy, unless the context otherwise requires,
                the terms defined shall bear the meanings assigned to them
                below, and their cognate expressions shall be construed
                accordingly. a) “Identity Information” shall have the same
                meaning as given in Section 26 of the Digital Security Act 2018
                to mean any information that relates to a natural person, which,
                either directly or indirectly, in combination with other
                information available or likely to be available to a body
                corporate, is capable of identifying such person. b) The
                “identity information” means any external, biological or
                physical information or any other information which singly or
                jointly can identify a person or a system, such as name,
                photograph, address, date of birth, mother’s name, father’s
                name, signature, national identity card, birth and death
                registration number, finger print, passport number, bank account
                number, driving license, e-TIN number, electronic or digital
                signature, username, credit or debit card number, voice print,
                retina image, iris image, DNA profile, security related question
                or any other identification which are available for advance
                technology. c) “You”, “Your”, “Yourself” and “User” shall mean
                and refer to natural & legal individuals and legal
                entities/companies who visit and/or use the Services and will
                also include the individuals/entities/companies who avail the
                services by submission of details by some other person d) “Third
                Parties” refer to any website/application/web portal, company or
                individual apart from the User and Us. e) “Services'' shall mean
                the Website (https://AyyKori.com/) and Mobile Application
                (AyyKori) and contextual information transmitted to/ received
                from Users via various communication channels including but not
                limited to e-mail, SMS, WhatsApp, phone calls, website chat,
                IVR. We are primarily engaged in the business of allowing Users
                to share customized links for various products in the network of
                such Users, the customized links drive sales to e-commerce
                websites or the Brand Partners and in turn the Users earn
                profits. We currently operate under the brand name AyyKori. f)
                “User Information” shall mean Personal Information and Sensitive
                Personal Data or Information. g) “Website” shall mean and refer
                to https://AyyKori.com /, the “Application” and/ or “App” shall
                refer to the AyyKori mobile application available on Android
                Play Store or iOS App Store. These shall be collectively
                referred to as the “Platform”
              </p>
              8.3. GENERAL a) The User unequivocally agrees that this Policy and
              the aforementioned Terms and Conditions constitute a legally
              binding agreement between the User and AyyKori, and that the User
              shall be subject to the rules, guidelines, policies, terms, and
              conditions applicable to any service that is provided by AyyKori
              including the Services, and that the same shall be deemed to be
              incorporated into the Terms and Conditions, and shall be treated
              as part of the same. b) This document is an electronic record in
              terms of the Information & Communication Technology Act, 2006 and
              rules there under as applicable and the amended provisions
              pertaining to electronic records in various statutes as amended by
              the Information & Communication Technology Act, 2006. This
              electronic record is generated by a computer system and does not
              require any physical or digital signatures. Further, this document
              is published in accordance with the provisions of the relevant
              Guidelines. c) The terms ‘Party’ and ‘Parties’ shall respectively
              be used to refer to the User and AyyKori individually and
              collectively, as the context so requires. d) The headings of each
              section in this Policy are only for the purpose of organizing the
              various provisions under this Policy in an orderly manner and
              shall not be used by either Party to interpret the provisions
              contained herein in any manner. Further, it is specifically agreed
              to by the Parties that the headings shall have no legal or
              contractual value. e) The Parties expressly agree that subject to
              clause 2 of this Document, AyyKori retains the sole and exclusive
              right to amend or modify the Policy and the aforementioned Terms
              and Conditions without any prior permission or intimation to the
              User keeping in mind best practices and laws set by the Government
              of Bangladesh, and the User expressly agrees that any such
              amendments or modifications shall come into effect immediately.
              The User has a duty to periodically check the Policy and Terms and
              Conditions and stay updated on their provisions and requirements.
              If the User continues to use the Services following such a change,
              the User will be deemed to have consented to any and all
              amendments/ modifications made to the Policy and Terms and
              Conditions. In so far as the User complies with the Policy and
              Terms and Conditions, he/she is granted a personal, non-exclusive,
              non-transferable, revocable, limited privilege to enter, access
              and use the Services. 8.4 COLLECTION AND HANDLING OF PERSONAL
              INFORMATION Privacy of the Parties is of prime importance to Us
              and all Services are strictly designed within the jurisdiction of
              laws defined by the Government of Bangladesh. Generally, the
              Services require us to know who you are so that we can best meet
              your needs. When you access the Services, we may ask you to
              voluntarily provide us with certain information that personally
              identifies you or could be used to personally identify you.
              Without prejudice to the generality of the above, information
              collected by us from you may include (but is not limited to) the
              following: I. Contact data (such as your email address and phone
              number); II. Username and passwords; III. Demographic data (such
              as your name, gender, age, your date of birth and your pin code);
              IV. Data regarding your usage of the services and other
              transactions made by or with you through the use of Services; V.
              Information about your clicks on and from AyyKori mobile device,
              web browser, web and mobile browsing patterns, Brand Partner
              preferences VI. Your bank account information including the name
              of the bank account, account number, routing no., bank branch or
              any other payment-related information VII. Any other information
              that you voluntarily choose to provide to us (such as information
              shared by you with us through emails, calls or letters, your work
              details, home / work address, your family details, details about
              transactions done on e-commerce sites, screenshots of
              transactions, order IDs for transactions, alternate numbers and
              emails and various other information provided from time to time).
              The information collected from You by Us shall constitute
              ‘Personal Information’ under the Digital Security Act of
              Bangladesh. 8.5. PRIVACY STATEMENTS The User expressly agrees and
              acknowledges a) Information that is freely available in the public
              domain or accessible under the Right to Information Act, 2009 or
              any other law will not be considered as ‘Personal Information’ for
              the purposes of this Policy.That AyyKori may automatically track
              information about the User based on the User’s IP address and the
              User’s behaviour on the Platform, and the User expressly consents
              to the same. The User is aware that this information may be used
              to conduct internal research on user demographics, interests, and
              behaviour, to enable AyyKori to better understand, and cater to
              the interests of the Users. Further, the User is expressly made
              aware that such information may include the User’s computer & web
              browser information, the User’s IP address, mobile device details
              etc. The linkage between User’s IP address and User’s personally
              identifiable information may be shared with or disclosed to third
              parties in order to facilitate the provisions of the Services to
              You. The User hereby consents to the sharing of such information
              to such third parties as may be determined by AyyKori from time to
              time. Further, we may also share and/or disclose some of the
              aggregate findings (not the specific data) in anonymized form
              (i.e., non-personally identifiable) with third parties for market
              research and new feature development. b) That any and all
              information pertaining to the User collected by AyyKori, whether
              or not directly provided by the User to AyyKori, including but not
              limited to personal correspondence such as emails or letters or
              SMS or WhatsApp or calls, feedback from other users or third
              parties regarding the User’s activities or postings on the
              Platform, etc., may be collected and compiled by AyyKori into a
              file/folder specifically created for/allotted to the User, and the
              User hereby expressly consents to the same.Also, in order to keep
              You informed of Your activities on the Website we occasionally
              send You emails, SMS, App notifications and other marketing
              communication. These include Your transaction messages to show how
              much You have earned, referral messages that show You how much You
              have earned from referrals, payment confirmations for payments to
              You and, important administrative messages and messages to confirm
              Your activities on the Website. These emails are not shared with
              anyone else apart from You.We also send newsletters, SMSs, App
              notifications, browser notifications and other marketing that
              features some of our best ideas to help You save more. You may
              choose not to receive this marketing communication from AyyKori by
              informing Us at any time. We do not support spamming by our
              Promoters and we explicitly prohibit it in our Terms and
              Conditions. If You would like to report an incident of spamming,
              please contact us so we can investigate and take suitable action.
              c) That the contact information provided to AyyKori may be used to
              send the User offers and promotions, whether or not based on the
              User’s previous interests, and the User hereby expressly consents
              to receive the same. The User may choose to unsubscribe from
              promotional communications by clicking on the ‘unsubscribe’ link
              provided at the end of such promotional communication or by
              emailing us on support@AyyKori.com d) AyyKori may occasionally
              request the User to complete optional online surveys. These
              surveys may require the User to provide contact information and
              demographic information (like zip code, age, income bracket, sex,
              etc.). The User is aware that this information is used to
              improve/customise the Services for the benefit of the User and
              providing all users of the Platform with services that AyyKori
              believes they might be interested in availing of. e) That AyyKori
              may keep records of electronic communications and telephone calls
              received and made for support or other purposes for the purpose of
              administration of Services, customer support, research and
              development and for better assistance to Users.That AyyKori may
              occasionally request the User to write reviews for services
              availed of by the User from the Platform. The User is aware that
              such reviews will help potential users of the Platform in availing
              the Services, and the User hereby expressly authorizes AyyKori to
              publish any and all reviews written by the User on the Platform,
              along with the User’s name and certain contact details, for the
              benefit and use of other users. f) Nothing contained herein shall
              be deemed to compel AyyKori to store, upload, publish, or display
              in any manner content/reviews/surveys/feedback submitted by the
              User, and the User hereby expressly authorizes AyyKori to remove
              from the Platform any such content, review, survey, or feedback
              submitted by the User, without cause or being required to notify
              the User of the same. g) Generation and collection of ‘Personal
              Information’ in accordance with the Digital Security Act, 2018 as
              amended from time to time and allied rules require the User’s
              express consent. By affirming assent to this Policy as well as
              clicking on the “I agree with Terms and Policy” button at the time
              of registration, the User provides consent to such generation and
              collection as required under applicable laws. h) The User is
              responsible for ensuring the accuracy of the information submitted
              to AyyKori. The User may correct, delete inaccuracies, or amend
              information by contacting AyyKori through email on
              support@AyyKori.com . AyyKori will make good faith efforts to make
              requested changes in the databases as soon as reasonably
              practicable. If the User provides any information that is untrue,
              inaccurate, out of date or incomplete (or becomes untrue,
              inaccurate, out of date or incomplete), or AyyKori has reasonable
              grounds to suspect that the information provided by the User is
              untrue, inaccurate, out of date or incomplete, AyyKori may, at its
              sole discretion, discontinue the provision of the Services to you
              as per the provisions laid down in the Terms and Conditions. There
              may be circumstances where AyyKori will not correct, delete or
              update your Personal Data, including (a) where the Personal Data
              is opinion data that is kept solely for an evaluative purpose; and
              (b) the Personal Data is in documents related to a prosecution if
              all proceedings relating to the prosecution have not been
              completed. i) All the information provided to Us by a User,
              including Sensitive Personal Data or Information, is voluntary.
              User has the right to withdraw his/ her/ its consent at any time,
              in accordance with the terms of this Privacy Policy, and the Terms
              and Conditions applicable to such User, it being however clarified
              that withdrawal of consent will not be retroactive. If the User
              wishes to delete his/her account or request that AyyKori no longer
              uses the User’s information to provide Services, the User may
              contact AyyKori on support@AyyKori.com . We shall not retain such
              information for longer than is required for the purposes for which
              the information may lawfully be used or is otherwise required
              under any other law for the time being in force. After a period of
              time, your data may be anonymized and aggregated, and then may be
              held by us as long as necessary for us to provide our Services
              effectively, but our use of the anonymized data will be solely for
              analytic purposes. Please note that your withdrawal of consent or
              cancellation of account may result in AyyKori being unable to
              provide you with its Services or to terminate any existing
              relationship thatAyyKori may have with you. j) If you wish to opt
              out of receiving non-essential communications such as promotional
              and marketing-related information regarding the Services, please
              send us an email at support@AyyKori.com . 8.6. OUR USE OF YOUR
              INFORMATION All the information provided to AyyKori by a User,
              including Personal Information or any Sensitive Personal Data or
              Information, is voluntary. Such information in its original form
              may be shared with any Third Parties in furtherance of the consent
              from the User as provided hereunder. You understand that AyyKori
              may use certain information of yours, which has been designated as
              Personal Information for the following purposes: I. providing you
              the Services; II. taking product & Services feedback; III. for
              offering new products or services and marketing of the Services;
              IV. for analysing software usage patterns for improving product
              design and utility; V. for providing the services of generating
              alerts/reminders/SMS for offers and also for internal record. VI.
              for commercial purposes and in an aggregated or non-personally
              identifiable form for research, statistical analysis and business
              intelligence purposes, VII. for sale or transfer of such research,
              statistical or intelligence data in a non-personally identifiable
              form to third parties and affiliates; VIII. debugging customer
              support-related issues; and We may use your tracking information
              such as IP addresses, and or Device ID to help identify You and to
              gather broad demographic information. In case we are acquired by
              or merged with another company, We shall transfer information
              disclosed by You and information about You to the company we are
              acquired by or merged with, and such company will have the right
              to continue to use the User’s Personal Information and/ or other
              information that a User provides to Us. The Users expressly agree
              and acknowledge that AyyKori collects and stores the User’s
              Personal Information and/or Sensitive Personal Information in a
              secure cloud-based platform which is provided by the User from
              time to time on the Platform or while using other Services. The
              User is aware that this information will be used by AyyKori to
              deliver its services and help customize/improve the Platform
              experience safer and easier but no personally identifiable
              information will be shared with any Third Party under any
              circumstances without the User’s explicit consent unless directed
              by the law. AyyKori may need to disclose/ transfer User’s Personal
              Information to the following third parties for the purposes
              mentioned in this Privacy Policy, and the Terms and Conditions as
              applicable to such User: 1. To government institutions/
              authorities to the extent required: I. under the laws, rules, and
              regulations and/ or under orders of any relevant judicial or
              quasi-judicial authority; II. to protect and defend the rights or
              property of AyyKori; III. to fight fraud and credit risk; IV. to
              enforce AyyKori’s Terms and Conditions applicable to the Users; or
              V. when AyyKori, in its sole discretion, deems it necessary in
              order to protect its rights or the rights of others. 2. If
              otherwise required by an order under any law for the time being in
              force including in response to enquiries by government agencies
              for the purpose of verification of identity, or for prevention,
              detection, investigation including cyber incidents, prosecution,
              and punishment of offences. However, We contract with third
              parties to serve ads on our behalf across the Internet and
              sometimes on this site. They may collect information about Your
              visits to our website, and Your interaction with our products and
              services. They may also use information about Your visits to this
              and other websites to target advertisements for goods and
              services. This information is collected through the use of a pixel
              tag, which is industry standard technology used by most major
              websites. Such third parties are not permitted to sell or share
              Your personally identifiable information as part of this process.
              The following third-party vendors, including Google, Facebook,
              advertising platforms, remarketing platforms like CleverTap, and
              customer query management platforms like Freshworks&Exotel use
              cookies to serve ads based on a user’s prior visits to a website.
              Google’s use of the DoubleClick cookie enables it and its partners
              to serve ads to users based on their visits to this sites and/or
              other sites on the Internet. Users may opt-out of the use of the
              DoubleClick cookie for interest-based advertising by visiting Ads
              Settings. 8.7. CONFIDENTIALITY AND SECURITY 8.7.1. Your
              information is regarded as confidential and therefore shall not be
              divulged to any Third Parties, unless as provided hereunder and
              unless legally required to do so to the appropriate authorities,
              or if necessary, for providing the Services through the Platform.
              8.7.2. Your Personal Information/Sensitive Personal Data is
              maintained by Us in an electronic form on our equipment, and on
              the equipment of our employees. Such information may also be
              converted to physical form from time to time. 8.7.3. People who
              can access your Personal Information: User Information will be
              processed by our employees, authorised staff, marketing agencies
              or agents, on a need-to-know basis, depending on the specific
              purposes for which the User Information has been collected by Us.
              AyyKori may, therefore, retain and submit all such records to the
              relevant stakeholders. 8.7.4. Security Practices. We treat data as
              an asset that must be protected against loss and unauthorised
              access. We employ many different security techniques to protect
              such data from unauthorized access by Promoters inside and outside
              AyyKori. We follow generally accepted industry standards to
              protect the User Information submitted to Us and information that
              We have accessed, including managerial, technical, operational and
              physical security control measures. However, for any data loss or
              theft due to unauthorized access to the User’s electronic devices
              through which the User avails the Services, We shall not be held
              liable for any loss whatsoever incurred by the User. 8.7.5.
              Measures We expect you to take: It is important that you also play
              a role in keeping your User Information safe and secure. When
              signing up for an online account, please be sure to choose an
              account password that would be difficult for others to guess and
              never reveal your password to anyone else. You are responsible for
              keeping this password confidential and for any use of your
              account. If you use a shared or public computer, never choose to
              have your login ID/email address or password rePromotered and make
              sure to log out of your account every time you leave the computer.
              You should also make use of any privacy settings or controls We
              provide you on Our Platform. 8.7.6. Unauthorised use of User’s
              account. We do not undertake any liability for any unauthorized
              use of your account and password. If you suspect any unauthorized
              use of your account, you must immediately notify Us by sending an
              email to support@AyyKori.com . 8.7.7. Notwithstanding the above,
              AyyKori is not responsible for the confidentiality, security or
              distribution of your Personal Information by third parties outside
              the scope of our agreement with such third parties. Further,
              AyyKori shall not be responsible for any breach of security or for
              any actions of any third parties or events that are beyond the
              reasonable control of AyyKori including but not limited to the
              acts of government, computer hacking, unauthorised access to
              computer data and storage device, computer crashes, breach of
              security and encryption. 8.8. RETENTION OF YOUR PERSONAL DATA In
              accordance with applicable laws, We will use the User Information
              for as long as necessary to satisfy the purposes for which such
              User Information was collected (as described in Section 8.6 above)
              or to comply with applicable legal requirements. 8.9. YOUR RIGHTS
              8.9.1. Access to Personal Data. You have the right to access,
              review and request a physical or electronic copy of the
              information held about you. You also have the right to request
              information on the source of your Personal Information. 8.9.2.
              Additional rights (e.g. modification, deletion of Personal Data).
              Where provided by law, you can (i) request deletion, the
              portability, correction or revision of your User Information; (ii)
              limit the use and disclosure of your Personal Data; and (iii)
              revoke consent to any of our data processing activities. Provided
              that, we may be required to retain some of your User Information
              after you have requested deletion, to satisfy our legal or
              contractual obligations. We may also be permitted by applicable
              laws to retain some of your User Information to satisfy our
              business needs. 8.10. CHILDREN’S AND MINOR’S PRIVACY We strongly
              encourage parents and guardians to supervise the online activities
              of their minor children and consider using parental control tools
              available from online services and software manufacturers to help
              provide a child-friendly online environment. These tools can also
              prevent minors from disclosing their name, address, and other
              personally identifiable information online without parental
              permission. Although the Services are not intended for use by
              minors, We respect the privacy of minors who may inadvertently use
              the internet or the mobile application. 8.11. CONSENT TO THIS
              POLICY You acknowledge that this Privacy Policy is a part of the
              Terms and Conditions of the Website and the other Services, and
              you acknowledge that you have unconditionally agreed as User of
              the Platform and the Services signifies your assent to this
              Privacy Policy. Your visit to the Website, use of the App and use
              of the Services is subject to this Privacy Policy and the Terms
              and Conditions. 8.12. COOKIES When You avail of our Services on
              the Platform, a persistent cookie is placed on Your computer. This
              enables us to track any purchases You make with our participating
              Brand Partners and award cashback / rewards / points to You. If
              You do not have such persistent cookies enabled on Your computer
              You will not be able to earn profits / points on Your online
              shopping via Our Platform. Disabling/enabling cookies: You have
              the ability to accept or decline cookies by modifying the settings
              in Your browser. However, You may not be able to use all the
              interactive features of Our Platform if cookies are disabled.
              Please note: if You disable the cookies in Your browser which are
              used to track Your purchases via Our Platform, You will not be
              able to earn Profits / point when You shop from our website. There
              are a number of ways to manage cookies. If You use different
              computers in different locations You will need to ensure that each
              browser is adjusted to suit Your cookie preferences. You can
              easily delete any cookies that have been installed in the cookie
              folder of your browser as and when required. 8.13. AFFILIATE
              MARKETING COMMISSION AyyKori, an affiliate marketing platform, is
              essentially a service provider that connects Brand Partners and
              publishers. AyyKori is not liable to pay for any commission which
              is cancelled by the partner Brand Partner due to any reason
              whatsoever and not limited to bulk buying, self-consumption,
              unattributed sale, return or cancellation of product and/or
              violating any affiliate policy of our partner Brand Partner. Users
              are required to constantly keep a check on profit rates and terms
              of campaigns as these are subject to change real-time without
              prior notice. AyyKori reserves the right to cancel the profit as
              directed by the partner Brand Partner. By signing up on AyyKori,
              the user agrees to abide by this clause. 8.14. AMENDMENTS OR
              CHANGES TO PRIVACY POLICY AyyKori may update this Privacy Policy
              at any time, with or without advance notice. In the event, there
              are significant changes in the way AyyKori treats User
              Information, or in the Privacy Policy itself, AyyKori will display
              a notice on the Website or send Users an email, as provided above,
              so that the User may review the changed terms prior to continuing
              to use the Services. As always, if the User objects to any of the
              changes to our terms, and the User no longer wishes to use the
              Services, the User may communicate the same to support@AyyKori.com
              to deactivate Your account. Unless stated otherwise, the current
              Privacy Policy applies to all information that AyyKori has about
              You and Your account. If a User uses the Services after a notice
              of changes has been sent to such User or published on the
              Platform, such User hereby provides his/her/its consent to the
              changed terms. 8.15. ADDRESS FOR PRIVACY QUESTIONS Should You have
              any questions about this Privacy Policy or AyyKori’s information
              collection, use and disclosure practices, You may contact the Data
              Protection Officer appointed by AyyKori. We will use reasonable
              efforts to respond promptly to any requests, questions or
              concerns, You may have regarding our use of Your Personal
              Information. If You have any grievance with respect to Our use of
              Your information, You may communicate such grievance to the Data
              Protection Officer by emailing us at the following email address
              Email: support@AyyKori.com or via the supports page available on
              our website 9. Our Role: We are not a party to any transactions
              with Brand Partners and are not the seller or supplier of any of
              the goods or services that they make available. Thus, we do not
              have any of the legal obligations that apply to the sellers of
              those goods or services. Accordingly, we have no control over or
              responsibility for: I. the quality, safety, or legality of those
              goods or services available from Brand Partners; or II. whether
              the Brand Partner can or will supply and pass good titles to any
              goods or services. Promoters should exercise no lesser degree of
              caution in entering into transactions with Brand Partners than
              they would when entering into a similar transaction offline. To
              the extent that the Applicable Law permits, you release us, our
              agents and employees from all liability arising out of or in
              connection with any transactions with Brand Partners, including
              (without limitation) all claims and demands relating to
              uncompleted or completed transactions with Brand Partners, or
              goods or services offered for sale or supply, or actually sold or
              supplied, through or in connection with any transactions with
              Brand Partners. 10. Misuse: We reserve the right to suspend or
              terminate any Promoters access to our service, or parts of it, if
              in our reasonable view the relevant Promoter or Account appears to
              be in breach of any provision of this Agreement. Promoters must
              not enter into, or attempt to enter into, any transaction with a
              Brand Partner or to gain Profit (a) by providing personal
              information of someone else, or a payment method which they are
              not entitled to use, (b) by deceptively or unfairly exploiting a
              Brand Partners’ offering including but not limited to creating
              fake or unauthorized referral links, or (c) in breach of any terms
              and conditions applied by us or the Brand Partner to that
              transaction. We reserve the right to forfeit any pending payments
              or validated payments in the Promoter’s Account in case of such
              misuse of our service by the Promoter. It is each Promoter’s
              obligation to ensure that any material posted by him/her or
              associated with his/her Account: I. is not defamatory, offensive,
              or abusive or of an obscene, indecent or menacing nature; II. is
              not intended or likely to cause needless annoyance, inconvenience
              or distress to any person; III. does not contain any computer
              virus, macro virus, Trojan horse, worm, or anything else designed
              to interfere with, interrupt, or disrupt the normal operating
              procedures of a computer or to surreptitiously intercept, access
              without authority, or expropriate any system, data or personal
              information; IV. does not contravene the Applicable Law or
              regulation (including, but not limited to, laws governing consumer
              protection, distance selling, unfair competition,
              anti-discrimination, false advertising, copyright, trademark and
              privacy); V. does not breach the rights of any person or entity
              (including any rights or expectations of privacy); VI. where it
              constitutes feedback on a Brand Partner, is accurate and fair; and
              VII. does not advertise any goods or services. Notwithstanding
              anything contained elsewhere in this Agreement and the Privacy
              Policy, we reserve the right to investigate complaints or reported
              violations of this Agreement and to take any action we deem
              appropriate, including but not limited to reporting any suspected
              unlawful activity to law enforcement officials, regulators, or
              other third parties and disclosing any information necessary or
              appropriate to such persons or entities relating to your Account,
              email addresses, usage history, posted materials, IP addresses and
              traffic information. Any Promoter, who in the sole discretion of
              the Brand Partner or us, has committed fraud in the use of the
              Platform or our Service or misused the Platform or the Service,
              their Account will be closed, email and IP address will be
              blacklisted so they cannot use it again and cannot register from
              the same PC/Laptop/Smart device. If you see or experience anything
              on our Platform that appears to infringe any of the above
              requirements, we would like you to inform us by using our contact
              form. Each Promoter acknowledges that we are entitled, but not
              obliged, to withdraw any material, which appears – based on
              information received from third parties or other Promoters – to be
              in breach of this Agreement. 11. Contact from third parties: If
              anyone contacts us in relation to material or transactions
              associated with you or your Account, then you agree: I. to provide
              all reasonable information and assistance we may require in
              connection with responding to that contact; and II. to respond
              promptly and accurately to it, should we pass the message to you
              for a response. 12. Additional services: We or our partners may
              offer new or additional services through the Platform from time to
              time. Your use of those services may be subject to additional
              terms and conditions, which you must comply with. Provided that
              those terms are notified to you on the Platform in an appropriate
              manner (as determined by us in our reasonable discretion) when you
              agree to take those services, any failure by you to comply with a
              material provision of the terms governing those services will
              amount to a breach of this Agreement. 13. Operation of our
              Service: We reserve the right to withdraw, modify or suspend
              aspects of the Service, or the entirety of it, where we have
              legal, security, technical or commercial reasons to do so. We will
              endeavour to give you 30 days’ advance notice before taking such
              action, except where it is necessary to take earlier action for
              security reasons or because of technical difficulties which would
              otherwise adversely affect our service. There may also be times
              when the Service becomes inaccessible as a result of technical
              difficulties experienced by us or on the Internet; we will,
              however, use reasonable skill and care to overcome these
              difficulties where they are within our control. Please note,
              however, that we cannot guarantee continuous access to the Service
              or any of the content that appears on it. Nevertheless, we will
              strive to ensure that any periods of planned unavailability, which
              you will be informed of when you access the Service at the
              relevant time, are kept to a minimum. For security or other
              reasons, we may require you to change your password or other
              information which facilitates access to the Service; however, we
              will never ask you for your password via email, telephone, or any
              other means other than through the Platform. You are solely
              responsible for maintaining the confidentiality of your password
              and any additional identifying information. 14. Disclaimer and
              Limitation of Liability: Disclaimer: The content and material from
              or through the Platform are provided “as-is,” “as available,” with
              “all faults”, and all warranties, express or implied, are
              disclaimed (including but not limited to the disclaimer of any
              implied warranties of merchantability, non-infringement, freedom
              from error, and fitness for a particular purpose). The information
              and services may contain bugs, errors, problems or other
              limitations. We and our affiliated parties have no liability
              whatsoever for your use of any information or service. In
              particular, but not as a limitation thereof, we and our affiliated
              parties are not liable for any indirect, special, incidental or
              consequential damages (including damages for loss of business,
              loss of profits, savings, litigation, or the like), whether based
              on breach of contract, breach of warranty, tort (including
              negligence), product liability or otherwise, even if advised of
              the possibility of such damages. The negation and limitation of
              damages set forth above are fundamental elements of the basis of
              the agreement between us and you. This Platform and the products,
              services, documents, content and materials and information
              presented would not be provided without such limitations. No
              advice or information, whether oral or written, obtained by you
              from us through the Platform or otherwise shall create any
              warranty, representation or guarantee not expressly stated in this
              agreement. All responsibility or liability for any damages caused
              by viruses contained within the electronic file containing a form
              or document is disclaimed. Liability: a) We warrant that the
              Service will be provided with reasonable care and skill with the
              intention of meeting our specifications for the Service, but we
              cannot and do not guarantee that the Service will meet your
              requirements. b) We shall be liable as expressly provided in this
              Agreement, but shall have no other obligation, duty or liability
              whatsoever in contract, tort (including negligence, breach of
              statutory duty and any other tort) or otherwise. c) Limitation of
              Liability: Subject always to sub-Clause d. below, we shall be
              liable for direct loss or damage only, whether in contract, tort
              (including negligence, breach of statutory duty or other tort) or
              otherwise, and whether caused by its act or omission or that of
              its employees, agents or subcontractors. The aggregate liability
              of AyyKori and the affiliated parties in connection with any claim
              arising out of or relating to the Platform and/or the products,
              information, documents and services provided herein or hereby
              shall not exceed BDT20,000 and that amount shall be in lieu of all
              other remedies which you may have against us and any affiliated
              party to us. d) We will not be liable to you or anyone else,
              whether in contract, tort (including negligence, breach of
              statutory duty or other tort) or otherwise I. (A) for any loss of
              revenue, business, anticipated savings or profits; (B)any errors
              in or omissions from the Platform or any services or products
              obtainable therefrom; (C) the unavailability or interruption of
              the Platform or any features thereof; (D) your use of the
              Platform; (E) the content and materials contained on the Platform;
              (F) or any delay or failure in performance beyond our control or
              any of our affiliated parties. II. for any indirect, special or
              consequential loss damage, costs or other claims, however caused
              or arising, whether through non-supply or late supply of the
              Service or other non-performance of this Agreement or otherwise.
              III. Except as expressly stated elsewhere in this Agreement, all
              representations, warranties, conditions and other terms, whether
              express or implied (by common law, statute, collateral or
              otherwise) are hereby excluded, except in the case of fraud, or
              where such exclusion is not permitted by Applicable Law. IV. For
              the avoidance of doubt, we will not have liability to you or any
              other person in respect of material contributed by Promoters,
              transactions (or non-transactions) with Brand Partners, or any
              activity or communication relating to such material or
              transactions. The provisions of this Clause 14 shall survive the
              termination or expiry of this Agreement. 15. Third Party Content:
              Third party content and materials may appear on the Platform or
              may be accessible via hyperlinks from the Platform. We are not
              responsible for and assume no liability whatsoever for any
              mistakes, misstatements of law, defamation, omissions, falsehood,
              obscenity, pornography or profanity in the statements, opinions,
              representations or any other form of content and materials
              appearing on the Platform or accessible via hyperlinks from the
              Platform. 16. Communications: You hereby expressly agree to
              receive communications by way of SMS, e-mails, mobile app and
              browser notifications, and any other messages from time to time
              from AyyKori relating to Services provided through the Website.
              17. Indemnity: You agree to indemnify us against all liabilities,
              claims and expenses that may arise out of or in connection with
              (a) any breach of this Agreement by you or through your Account,
              or (b) any transaction with a Brand Partner. 18. Assignment: We
              reserve the right to assign this Agreement, and to assign or
              subcontract any or all of our rights and obligations under this
              Agreement, but will not do so in such a way as to reduce any
              guarantees you are given under this Agreement. You may not without
              our written consent assign or dispose of this Agreement, nor
              subcontract any of your rights and obligations under it. 19.
              Entire Agreement: This Agreement is intended to contain your
              entire agreement with us relating to the Service; we believe it to
              be fair and reasonable. It replaces all earlier agreements and
              understandings with you relating to the Service, except for any
              fraud or fraudulent representation by either of us. 20. Changes to
              this Agreement: We reserve the right to change this Agreement from
              time to time, and post the new version on the Service. When we do
              so, we will post the new version of the Agreement on the Service,
              and the new version of these terms and conditions will take
              effect, and will govern the Service and your relationship with us:
              I. commencing no less than three days after the date of posting
              (or such later date as we indicate in the relevant posting), if
              any of the changes is to an operative provision of this Agreement
              which is capable of adversely affecting you; if you do not wish to
              be governed by the new version of the Agreement, you may notify us
              on or before the date when the new version of the Agreement is to
              take effect, and from that date you must cease to use our service
              or II. immediately upon the date of posting (or such later date as
              we indicate in the relevant posting), if the changes are not to
              operative provisions, or not capable of adversely affecting you –
              examples of which would include, without limitation, changes to
              contact details referred to, or the refinement of provisions that
              are already included, in this Agreement. 21. General: In the event
              that any term of this Agreement is held to be invalid or
              unenforceable, the remainder of this Agreement shall remain valid
              and enforceable. You and us are independent contractors, and no
              agency, partnership, joint venture or employee-employer
              relationship is intended or created by this Agreement. Our failure
              to act with respect to a breach by you or others does not waive
              our right to act with respect to subsequent or similar breaches.
              22. Governing Law: This Agreement, and our relationship with you
              and each Promoter, is governed as per the Bangladeshi Laws. You
              and we each submit to the non-exclusive jurisdiction of the
              Bangladesh courts in relation to disputes arising in connection
              with this Agreement. 23. Keeping this Agreement: We don’t
              separately file the individual Agreements entered into by
              Promoters when they register for the Service. You can access it at
              www.AyyKori.com . Please make a durable copy of this Agreement by
              printing and/or saving a downloaded copy on your own computer. It
              is offered in English only. 24. Contact: You can reach us on
              ‘support@AyyKori.com’ .
            </div>
          )}
        </div>
      </CommonLayout>
    </>
  );
};

export default TermsAndConditionPage;
