import moment from "moment";
import React from "react";
import { AiOutlineCalendar } from "react-icons/ai";
import ReactPlayer from "react-player/lazy";
import youtubeLogo from "../../Assets/images/icon/icons8-youtube-94.png";
import { useUpdateBlogViewCountMutation } from "../../features/blog/blogApiSlice";

const SidebarCardVideo = ({ data, styleData }) => {
  const [updateBlogViewCount] = useUpdateBlogViewCountMutation() || {};
  return (
    <a
      onClick={() => {
        updateBlogViewCount({ id: data?._id });
      }}
      href={data?.blogLink}
      target="_blank"
      rel="noreferrer"
    >
      <div className="flex cursor-pointer rounded-md gap-x-2 bg-white shadow-sm">
        <div className="w-[30%] relative flex justify-center items-center">
          <ReactPlayer
            width={styleData?.width}
            height={styleData?.height}
            playing={true}
            light={true}
            stopOnUnmount={true}
            controls={true}
            playIcon={<img src={youtubeLogo} alt="" className="w-4   " />}
            url={data?.blogLink}
          />
          <div className="absolute w-full h-full left-0 top-0 z-10"></div>
        </div>
        <div className="w-[70%] p-2 flex flex-col justify-between">
          <h1 className="text-xs font-medium font-outfit text-gray-700 hover:text-primaryColor">
            {data?.blogTitle}
          </h1>
          <p className=" flex text-[12px] items-center gap-1 text-gray-400">
            <span className="text-xl">
              <AiOutlineCalendar />
            </span>
            {moment(data?.createdAt).format("ll")}
          </p>
        </div>
      </div>
    </a>
  );
};

export default SidebarCardVideo;
