import React, { useEffect, useState } from "react";
import BottomNavbar from "./BottomNavbar";
import MobileBottomNavbar from "./MobileBottomNavbar";
import UpperNavBar from "./UpperNavBar";

const Navbar = () => {
  const [open, setOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [upperMenu, setUpperMenu] = useState("");
  const [isHidden, setIsHidden] = useState(false);

  // for bottom navbar hide when srolling
  function handleScroll() {
    if (window.scrollY >= 90) {
      setIsHidden(true);
    } else {
      setIsHidden(false);
    }
  }
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <div className="fixed top-0 left-0 right-0 z-[1000] bg-white ">
      <UpperNavBar />
      <div
        className={` ${
          isHidden ? "hidden " : "block"
        } transition-all duration-1000 z-[1000]  `}
      >
        <BottomNavbar />
      </div>

      {/* mobile menu */}

      {/* <MobileNavbar
        data={{ open, setOpen, isOpen, setIsOpen, upperMenu, setUpperMenu }}
      /> */}

      <MobileBottomNavbar
        data={{ open, setOpen, isOpen, setIsOpen, upperMenu, setUpperMenu }}
      />
    </div>
  );
};

export default Navbar;
