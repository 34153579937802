import { apiRequest } from "../apiRequest/apiRequest";

export const favProductApiSlice = apiRequest.injectEndpoints({
  endpoints: (builder) => ({
    createFavProduct: builder.mutation({
      query: (data) => {
        const { access_token, favProductID, whichBasisFav } = data || {};
        return {
          url: `/create-fav-product`,
          method: "POST",
          headers: {
            Authorization: `Bearer ${access_token}`,
            "Content-Type": "application/json;charset=UTF-8",
          },
          body: {
            favProductID,
            whichBasisFav:
              whichBasisFav === "cashback" ? "cashback" : "earning",
          },
        };
      },
      invalidatesTags: ["FavProduct"],
    }),
    deleteFavProduct: builder.mutation({
      query: (data) => {
        const { access_token, id } = data || {};
        return {
          url: `/delete-fav-product/${id}`,
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${access_token}`,
            "Content-Type": "application/json;charset=UTF-8",
          },
        };
      },
      invalidatesTags: ["FavProduct"],
    }),
    getFavProducts: builder.query({
      query: (data) => {
        const { access_token, favproduct, page, limit } = data || {};
        return {
          url: `/get-fav-product?favproduct=${
            favproduct === "cashback" ? "cashback" : "earning"
          }&page=${page}&limit=${limit}`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${access_token}`,
            "Content-Type": "application/json;charset=UTF-8",
          },
        };
      },
      providesTags: ["FavProduct"],
    }),
  }),
});

export const {
  useCreateFavProductMutation,
  useDeleteFavProductMutation,
  useGetFavProductsQuery,
} = favProductApiSlice;
