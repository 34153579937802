import React, { useCallback, useMemo, useState } from "react";
import { Controller, useForm } from "react-hook-form"; // React Hook Form import করুন
import { BiChevronRight } from "react-icons/bi";
import OTPInput from "react-otp-input";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { BeatLoader } from "react-spinners";
import { setErrorMsg } from "../../features/ErrorHelper/ErrorHelperSlice";
import {
  useIsExistUserUsingPhoneMutation,
  useResetPasswordOtpVarificationMutation,
} from "../../features/auth/authApiSlice";
import { prevSendData, validTime } from "../../features/auth/authHelperSlice";
import { goback } from "../../features/backPrev/goBackSlice";
import { useVarifyOTPMutation } from "../../features/otp/otpApiSlice";
import Counter from "../counter/Counter";

const OTPVarification = ({ setSteps, source }) => {
  const { phone, user, time, prevSend } = useSelector(
    (state) => state?.authHelper
  );
  const [isTimeEnded, setIsTimeEnded] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [
    isExistUserUsingPhone,
    {
      isError: isErrorExistUserUsingPhone,
      isSuccess: isSuccessExistUserUsingPhone,
      isLoading: isLoadingExistUsingPhone,
      data: dataExistUserUsingPhone,
      error: errorExistUserUsingPhone,
    },
  ] = useIsExistUserUsingPhoneMutation() || {};

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [
    resetPasswordOtpVarification,
    { isLoading: isLoadinresetPasswordOtpVarification },
  ] = useResetPasswordOtpVarificationMutation() || {};
  const [varifyOTP, { isLoading: isLoadingVarifyOTP }] =
    useVarifyOTPMutation() || {};

  const onSubmit = async (data) => {
    const getOTP = data.otp;
    let res;
    dispatch(
      prevSendData({
        prevSend: false,
      })
    );
    dispatch(
      validTime({
        time: 0,
      })
    );
    if (source === "ResetOTPVarification") {
      res = await resetPasswordOtpVarification({
        otpCode: getOTP,
        phone: phone,
      });
      const { data: resData, error } = res || {};
      if (resData?.validation === true) {
        setSteps("ResetPassword");
      } else if (error) {
        dispatch(
          setErrorMsg({
            errorMsg: error?.data?.message || "Please Try again!!",
            isError: true,
          })
        );
        setSteps("OTPNotMatch");
      }
    } else if (source === "phoneOTPVarification") {
      res = await varifyOTP({ otpCode: getOTP, phone: phone });
      const { validation } = res?.data || {};
      if (validation) {
        setSteps("CreateOrUpdateUser");
      } else {
        dispatch(
          setErrorMsg({
            errorMsg: res?.error?.data?.message || "Please Try again!!",
            isError: true,
          })
        );
        dispatch(
          goback({
            prevComponent: "OTPVarification",
          })
        );
        setSteps("OTPNotMatch");
      }
    }
  };

  const sendOTPAgain = async (e) => {
    // console.log(source);
    e.preventDefault();
    if (source === "phoneOTPVarification") {
      setSteps("InitialDefault");
    }
    if (source === "ResetOTPVarification") {
      setSteps("LoginWhenVarified");
    }
  };

  const handleTimeEnd = useCallback(() => {
    setIsTimeEnded(true);

    dispatch(
      prevSendData({
        prevSend: false,
      })
    );
    dispatch(
      validTime({
        time: 0,
      })
    );
  }, [dispatch]);

  const counterComponent = useMemo(() => {
    return time && !isTimeEnded ? (
      <Counter totalTime={time} onTimeEnd={handleTimeEnd} />
    ) : null;
  }, [time, isTimeEnded, handleTimeEnd]);

  return (
    <div
      style={{ zIndex: 999 }}
      className="relative bg-white border lg:p-10 md:p-8 p-5 min-h-[400px] rounded-lg flex flex-col justify-center"
    >
      <h1 className="text-3xl font-bold text-gray-700 mb-5">
        Start your cashback journey
      </h1>

      <span className="flex items-center gap-2 mb-4">
        {counterComponent}

        {isTimeEnded ? (
          <h2 className="text-sm text-gray-600">
            {/* Didn't receive OTP?{" "}
            <button
              disabled={!isTimeEnded}
              onClick={sendOTPAgain}
              className={`text-red-600 ${!isTimeEnded && "cursor-not-allowed"}`}
            >
              Try Again
            </button> */}
          </h2>
        ) : (
          prevSend && (
            <h3 className="text-xs text-red-500">OTP Already Sent!!</h3>
          )
        )}
      </span>
      <h3 className="text-sm text-gray-600">Submit your OTP Code</h3>
      <form onSubmit={handleSubmit(onSubmit)} className="h-[100px] mt-4">
        <Controller
          name="otp"
          control={control}
          rules={{
            required: "OTP is required",
            minLength: { value: 4, message: "OTP must be 4 digits" },
          }}
          render={({ field: { onChange, value } }) => (
            <OTPInput
              value={value}
              onChange={onChange}
              numInputs={4}
              inputStyle={{
                height: "40px",
                width: "40px",
                border: "1px solid #ccc",
                borderRadius: "6px",
              }}
              renderSeparator={<span>&emsp;</span>}
              renderInput={(props) => <input {...props} />}
            />
          )}
        />
        {errors.otp && (
          <p className="text-red-500 text-xs">{errors.otp.message}</p>
        )}

        <h2 className="text-sm text-gray-600 mt-6">
          Didn't receive OTP?{" "}
          <button
            type="button"
            disabled={!isTimeEnded}
            onClick={sendOTPAgain}
            className={`text-red-600 ${!isTimeEnded && "cursor-not-allowed"}`}
          >
            Try Again
          </button>
        </h2>
      </form>
      <button
        type="submit"
        onClick={handleSubmit(onSubmit)}
        disabled={isLoadingVarifyOTP || isLoadinresetPasswordOtpVarification}
        className={`w-fit bg-primaryColor pt-1 pb-1.5 px-6 text-white flex items-center gap-1 rounded-full group hover:opacity-80 transition duration-300 ${
          (isLoadingVarifyOTP || isLoadinresetPasswordOtpVarification) &&
          "cursor-not-allowed"
        }`}
      >
        {isLoadingVarifyOTP || isLoadinresetPasswordOtpVarification ? (
          <>
            Next <BeatLoader color="#fff" size={8} />
          </>
        ) : (
          <>
            Next{" "}
            <BiChevronRight className="group-hover:translate-x-1 transition duration-300 mt-0.5" />
          </>
        )}
      </button>
    </div>
  );
};

export default OTPVarification;
