import { apiRequest } from "../apiRequest/apiRequest";

export const bannerApiSlice = apiRequest.injectEndpoints({
  endpoints: (builder) => ({
    getAllBanner: builder.query({
      query: (data) => {
        const {
          bannerName,
          bannerStatus,
          category,
          systemDevice,
          page,
          limit,
        } = data;
        let url = `/banner/get-banner`;
        const queryParams = new URLSearchParams();

        if (systemDevice) {
          queryParams.append("systemDevice", systemDevice);
        }

        if (bannerStatus) {
          queryParams.append("bannerStatus", bannerStatus);
        }

        if (page) {
          queryParams.append("page", page);
        }

        if (limit) {
          queryParams.append("limit", limit);
        }

        url += `?${queryParams.toString()}`;

        return {
          url: url,
          method: "GET",
          headers: {
            "Content-Type": "application/json;charset=UTF-8",
          },
        };
      },
      keepUnusedDataFor: 10000,
    }),
  }),
});

export const { useGetAllBannerQuery } = bannerApiSlice;
