import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { shebaBannerGif } from "../../Data/bannerData";
import { sheba } from "../../Data/campaignVideoData";
import response from "../../Data/tempData";
import BrandPageRightPanel from "../../components/Brands/BrandPageRightPanel";
import CampaignCard from "../../components/Campaign/CampaignCard";
import CampaignStaticCard from "../../components/Campaign/CampaignStaticCard";
import CashbackProduct from "../../components/Products/CashBackProduct";
import Trending from "../../components/Trending/Trending";
import CommonLayout from "../../layout/CommonLayout";
export const ShebaLandingPage = ({ data }) => {
  const [type, setType] = useState("Cashback");
  const [sliceValue, setSliceValue] = useState(500);

  return (
    <>
      <Helmet>
        <title>Campaign | Ayykori</title>
      </Helmet>
      <CommonLayout>
        <div className="px-3 md:mx-14 md:mb-16">
          {shebaBannerGif &&
            shebaBannerGif.map((item, i) => (
              <CampaignCard
                campaignVideo={item.image}
                link={"/campaign"}
                key={i}
              />
            ))}
        </div>

        <div className="my-10 md:my-12">
          <div className=" w-full px-3 md:px-[55px] my-5 grid md:grid-cols-5 grid-cols-1 gap-y-5 md:gap-y-0 md:gap-5">
            <div className="md:col-span-3">
              <BrandPageRightPanel
                type={type}
                response={response}
                campaignData={sheba}
              />
            </div>

            <div className="w-full col-span-2">
              <iframe
                className="md:block hidden rounded-lg"
                width="100%"
                height="100%"
                src="https://www.youtube.com/embed/w7bUtERXK60?si=ijzLsOrrJusJepds"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen
              ></iframe>

              <iframe
                className="block md:hidden rounded-lg"
                width="100%"
                height="200px"
                src="https://www.youtube.com/embed/w7bUtERXK60?si=ijzLsOrrJusJepds"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen
              ></iframe>

              {/* <ReactPlayer
            width={"475px"}
            height={"300px"}
            playing={true}
            light={true}
            stopOnUnmount={true}
            controls={true}
            playIcon={<img src={youtubeLogo} alt="" className="w-16   " />}
            url={item?.blogLink}
          /> */}
            </div>
          </div>
        </div>

        <CampaignStaticCard
          newData={{ title: "Exclusive Offer", dataLimit: 20, source: "sheba" }}
        />
        {/* <div className="px-3 md:mx-14 md:mb-16">
          {campaignBanner && (
            <CampaignCard
              campaignVideo={campaignBanner[1].image}
              link={"/campaign"}
            />
          )}
        </div> */}
        <Trending
          newData={{
            title: "Daraz Exclusive offer",
            dataLimit: 20,
            source: "sheba",
          }}
        />
        <CampaignStaticCard
          newData={{ title: "Premium Brands", dataLimit: 20, source: "sheba" }}
        />

        <CashbackProduct path={"/cashback-products/all"} />

        {data ? (
          <div className="px-3 md:mx-14 md:mb-16">
            <div className="">
              <h2 className="text-xl font-semibold text-gray-700 my-2">
                {data.title}
              </h2>
              <p className=" text-base text-gray-500 my-2">{data?.subTitle}</p>
              <ul className=" text-base text-gray-500 ml-8 my-2 space-y-1 list-disc">
                {data.list &&
                  data.list.map((item, i) => <li>{item.listData}</li>)}
              </ul>
              <p className=" text-base text-gray-500 my-2">{data?.paragraph}</p>
            </div>
          </div>
        ) : (
          <div className="px-3 md:mx-14 md:mb-16">
            {/* <div className="">
              <h2 className="text-xl font-semibold text-gray-700 my-2">
                AyyKori and Sheba Employee Privilege Program
              </h2>
              <p className=" text-base text-gray-500 my-2">
                AyyKori and Sheba teamed up for a special program just for Sheba
                employees. Let's check out what this program offers:
              </p>
              <ul className=" text-base text-gray-500 ml-8 my-2 space-y-1 list-disc">
                <li>
                  A BDT 50 (Fifty Taka) bonus on Sheba Employee’s first purchase
                  (Minimum order value 1,000 BDT)
                </li>
                <li>
                  A Bonus of 200 BDT for a total of 10 purchases within two
                  months (minimum purchase value per order 1000 BDT; this offer
                  is applicable for the first two months only, starting from the
                  first order placement date)
                </li>
                <li>A dedicated customer service agent for Sheba Employees</li>
                <li>
                  A dedicated landing page for Sheba employees on the Ayykori
                  website/m-site/app displaying premium offers for only Sheba
                  employees
                </li>
                <li>
                  The highest Purchaser from Sheba employees will get an
                  exclusive surprise giveaway from Ayykori
                </li>
              </ul>
              <p className=" text-base text-gray-500 my-2">
                AyyKori and Sheba Employee Privilege Program brings a bundle of
                exclusive benefits to Sheba employees. From a warm welcome bonus
                and bonus rewards for regular shopping to personalized customer
                service, social media promotions, and a special landing page
                with premium offers – there's a lot to look forward to. And for
                the Sheba employee with the most purchases, a fantastic surprise
                giveaway awaits! This program is designed to make AyyKori
                experience extra special for Sheba's dedicated team. Happy
                saving while shopping, Sheba employees!
              </p>
            </div> */}
          </div>
        )}
      </CommonLayout>
    </>
  );
};
