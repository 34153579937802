import moment from "moment";
import React, { useState } from "react";
import { BsEye } from "react-icons/bs";
import ProductsListModal from "../Modal/ProductsListModal";

const BalanceTable = ({ source, data }) => {
  const [show, setShow] = useState(false);
  const [products, setProducts] = useState([]);

  return (
    <>
      {show && <ProductsListModal products={products} setShow={setShow} />}

      <tbody>
        {data?.OrderActivity &&
          data?.OrderActivity?.map((item, index) =>
            item?.OrderActivity?.length === 0 ? (
              ""
            ) : (
              <tr key={index} className="border-t border-gray-300">
                <td className="text-xs w-fit px-3 py-2 gap-x-0 text-gray-500 text-left">
                  {item?.orderCreationDate
                    ? moment(item?.orderCreationDate || "").format("LL")
                    : moment(item?.createdAt).format("LL")}
                </td>
                <td className="text-xs w-fit px-3 py-2 gap-x-0 text-gray-500 text-left">
                  {item?.brandName}
                </td>
                <td className="text-xs w-fit px-3 py-2 gap-x-0 text-gray-500 text-left">
                  {item?.deliveryStatus}
                </td>
                <td className="text-xs w-fit px-3 py-2 gap-x-0 text-gray-500 text-left">
                  {item?.commission > 0
                    ? item?.commission.toFixed(2)
                    : "Pending"}{" "}
                  &#2547;
                </td>
                <td className="text-xs w-fit px-3 py-2 gap-x-0 text-gray-500 text-left">
                  {/* <img src={product_view} className="w-8" alt="" /> */}
                  <BsEye
                    onClick={() => {
                      setShow(true);
                      setProducts(item?.Product[0]?.products);
                    }}
                    className="text-xl cursor-pointer text-primaryColor"
                  />
                </td>
              </tr>
            )
          )}
      </tbody>
    </>
  );
};

export default BalanceTable;
