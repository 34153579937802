import React from "react";
import { Link } from "react-router-dom";

const CategoryCard = ({ data }) => {
  // const bgImage = data.img;
  return (
    <Link
      to={`/brands/category/${data?.categoryName
        ?.toLowerCase()
        .replace(/\s+/g, "-")}`}
      className="shadow-md rounded-lg 
     flex items-center justify-center"
    >
      <div
        className="relative w-full flex  justify-between items-center  "
        //   style={{backgroundImage: `url(${data.img})`}}
      >
        <img
          className="w-full h-full rounded-lg "
          src={data?.categoryImg}
          alt=""
        />
        {/* <h1
          className=" px-2 py-2 w-[50%] absolute  top-[20%] left-2 text-base md:text-sm  lg:text-base text-gray-800  
         font-semibold"
        >
          {data?.categoryName}
        </h1> */}
      </div>
    </Link>
  );
};

export default CategoryCard;
