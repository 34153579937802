import { apiRequest } from "../apiRequest/apiRequest";
import { user } from "../auth/authHelperSlice";

export const otpApiSlice = apiRequest.injectEndpoints({
  endpoints: (builder) => ({
    otpCreate: builder.mutation({
      query: (newData) => ({
        url: "/user-registation",
        method: "POST",
        body: newData,
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
        },
      }),
      invalidatesTags: ["Auth"],
    }),
    varifyOTP: builder.mutation({
      query: (newData) => {
        return {
          url: `/user/varify-otp`,
          method: "POST",
          body: newData,
          headers: {
            "Content-Type": "application/json;charset=UTF-8",
          },
        };
      },
      async onQueryStarted(arg, { queryFulfilled, dispatch }) {
        try {
          const result = await queryFulfilled;

          // console.log(result);
          dispatch(
            user({
              user: result?.data?.user,
            })
          );
        } catch (error) {
          // do nothing
        }
      },
      //   invalidatesTags: ["Auth"],
    }),
  }),
});

export const { useVarifyOTPMutation } = otpApiSlice;
