import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  reload: 0,
};

export const reloadSlice = createSlice({
  name: "reload",
  initialState,
  reducers: {
    setReload: (state, action) => {
      state.reload = action.payload + 1;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setReload } = reloadSlice.actions;

export default reloadSlice.reducer;
