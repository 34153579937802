import React from "react";
import { Helmet } from "react-helmet";
import ContactUs from "../../components/ContactUs/ContactUs";
import CommonLayout from "../../layout/CommonLayout";

const ContactUsPage = () => {
  return (
    <>
      <Helmet>
        <title>Contact Us | Ayykori</title>
      </Helmet>
      <CommonLayout>
        <ContactUs />
      </CommonLayout>
    </>
  );
};

export default ContactUsPage;
