import React, { useEffect, useState } from "react";
import magic from "../../Assets/images/gif/magic.gif";
import share from "../../Assets/images/gif/share2.gif";
import stars from "../../Assets/images/gif/stars.gif";
import { RxCross1 } from "react-icons/rx";
import {
  FacebookShareButton,
  FacebookMessengerShareButton,
  WhatsappShareButton,
  FacebookIcon,
  FacebookMessengerIcon,
  WhatsappIcon,
  LinkedinShareButton,
  LinkedinIcon,
  TwitterShareButton,
  TwitterIcon,
} from "react-share";
import { useSelector } from "react-redux";

const ShareModal = ({ shareModal, setShareModal }) => {
  const sharelink = useSelector((state) => state.sharelink.sharelink);
  const [process, setProcess] = useState(true);
  useEffect(() => {
    if (sharelink) {
      setProcess(false);
    }
    if (!sharelink) {
      setProcess(true);
    }
    // if(!sharelink)
    // {
    //   setTimeout(()=>{

    //   },5000)
    // }
  }, [sharelink, process]);
  return (
    <div
      className={`w-screen h-screen bg-[rgba(0,0,0,0.5)] ${shareModal ? "flex" : "hidden"
        } items-center justify-center fixed top-0 left-0 right-0 bottom-0 z-[10000]`}
    >
      <div className="w-[80%] md:w-[35%] md:h-[55vh] overflow-y-auto scrollbar-w-0 bg-white rounded-lg  p-4 md:p-6 relative flex flex-col items-center">
        {process && (
          <img className="w-40 md:w-64 object-cover -mb-8" src={magic} alt="" />
        )}
        {!process && (
          <img className="w-40 md:w-64 object-cover -mb-10" src={share} alt="" />
        )}
        {!process && (
          <img
            className="absolute top-0 left-0 w-32 h-32 md:w-44 md:h-44 object-cover"
            src={stars}
            alt=""
          />
        )}
        <h1 className="text-center font-outfit text-sm md:text-xl text-primaryColor font-medium mt-3">
          {process && "Please Wait.."}
        </h1>
        <h1 className="text-center font-outfit text-sm md:text-xl text-primaryColor font-medium">
          {process && "We Are Crating Your Magic Link"}
        </h1>
        <h5 className="text-sm text-gray-400 my-2">
          {!process && "Share With.."}
        </h5>
        {!process && (
          <div className="flex items-center gap-x-4">
            <FacebookShareButton url={sharelink}>
              <FacebookIcon size={30} className="rounded-full" />
            </FacebookShareButton>

            <FacebookMessengerShareButton url={sharelink}>
              <FacebookMessengerIcon size={30} className="rounded-full" />
            </FacebookMessengerShareButton>

            <WhatsappShareButton url={sharelink}>
              <WhatsappIcon size={30} className="rounded-full" />
            </WhatsappShareButton>

            <LinkedinShareButton url={sharelink}>
              <LinkedinIcon size={30} className="rounded-full" />
            </LinkedinShareButton>
            <TwitterShareButton url={sharelink}>
              <TwitterIcon size={30} className="rounded-full" />
            </TwitterShareButton>
          </div>
        )}
        <span
          onClick={() => setShareModal(false)}
          className="absolute top-4 right-4 cursor-pointer"
        >
          <RxCross1 size={20} className="text-gray-500" />
        </span>
      </div>
    </div>
  );
};

export default ShareModal;
