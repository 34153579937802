import { apiRequest } from "../apiRequest/apiRequest";

export const brandApiSlice = apiRequest.injectEndpoints({
  endpoints: (builder) => ({
    getAllBrandsByCategory: builder.query({
      query: (data) => {
        const { access_token, categoryname, page, limit } = data || {};
        return {
          url: `/brand-all-marketplace-false?category=${categoryname}&page=${page}&limit=${limit}`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${access_token}`,
            "Content-Type": "application/json;charset=UTF-8",
          },
        };
      },
      providesTags: ["Brands"],
    }),
    getAllBrandsAndSubBrands: builder.query({
      query: (data) => {
        const { access_token, categoryname, subCategory, page, limit } =
          data || {};
        let link =
          categoryname && subCategory
            ? `/brand-all-brand-subbrand?category=${categoryname}&subCategory=${subCategory}&page=${page}&limit=${limit}`
            : categoryname
            ? `/brand-all-brand-subbrand?category=${categoryname}&page=${page}&limit=${limit}`
            : `/brand-all-brand-subbrand?page=${page}&limit=${limit}`;
        return {
          url: link,
          method: "GET",
          headers: {
            Authorization: `Bearer ${access_token}`,
            "Content-Type": "application/json;charset=UTF-8",
          },
        };
      },
      providesTags: ["Brands"],
    }),
    getBrandsByKeyValue: builder.query({
      query: (data) => {
        let { access_token, key, page, limit } = data || {};
        let link =
          key === "topnotch_cashbacks" || key === "highest_commission"
            ? `/brands-topnotch-highest?${key}=${true}&page=${page}&limit=${limit}&highestlowest_commision=${-1}`
            : `/brand-all-brand-subbrand-key-value?${key}=${true}&page=${page}&limit=${limit}&highestlowest_commision=${
                key === "trendy_brands" ? 1 : -1
              }`;
        return {
          url: link,
          method: "GET",
          headers: {
            Authorization: `Bearer ${access_token}`,
            "Content-Type": "application/json;charset=UTF-8",
          },
        };
      },
      providesTags: ["Brands"],
    }),
    getYouMayLikeBrands: builder.query({
      query: (data) => {
        const { access_token, id, page, limit } = data || {};
        return {
          url: `/brand-for-you-may-also-like/${id}?page=${page}&limit=${limit}`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${access_token}`,
            "Content-Type": "application/json;charset=UTF-8",
          },
        };
      },
      providesTags: ["Brands"],
    }),
    getAllBrands: builder.query({
      query: (data) => {
        const { access_token, page } = data || {};
        return {
          url: `/brand-all?page=${page}&limit=${100}`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${access_token}`,
            "Content-Type": "application/json;charset=UTF-8",
          },
        };
      },
      providesTags: ["Brands"],
    }),
    getSingleBrand: builder.query({
      query: (data) => {
        const { access_token, id } = data || {};
        return {
          url: `/brand-single-brand-subbrand/${id}`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${access_token}`,
            "Content-Type": "application/json;charset=UTF-8",
          },
        };
      },
      providesTags: ["Brands"],
    }),
    getSingleBrandByUID: builder.query({
      query: (data) => {
        const { access_token, id } = data || {};
        return {
          url: `/single-brand-subbrand/${id}`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${access_token}`,
            "Content-Type": "application/json;charset=UTF-8",
          },
        };
      },
      providesTags: ["Brands"],
    }),
    getAllMarketplaceByCategory: builder.query({
      query: (data) => {
        const { access_token, categoryname, page, limit } = data || {};
        return {
          url: `/brand-all-marketplace?category=${categoryname}&page=${page}&limit=${limit}`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${access_token}`,
            "Content-Type": "application/json;charset=UTF-8",
          },
        };
      },
      providesTags: [""],
    }),
  }),
});

export const {
  useGetAllBrandsByCategoryQuery,
  useGetAllBrandsAndSubBrandsQuery,
  useGetBrandsByKeyValueQuery,
  useGetAllBrandsQuery,
  useGetSingleBrandQuery,
  useGetSingleBrandByUIDQuery,
  useGetYouMayLikeBrandsQuery,
  useGetAllMarketplaceByCategoryQuery,
} = brandApiSlice;
