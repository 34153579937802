import { useFormik } from "formik";
import Cookies from "js-cookie";
import React, { useEffect } from "react";
import { toast } from "react-hot-toast";
import { CiLock } from "react-icons/ci";
import { useNavigate } from "react-router-dom";
import "tippy.js/dist/tippy.css"; // optional
import update_password from "../../Assets/images/gif/update_password.gif";
import { useUpdatePasswordMutation } from "../../features/user/userApiSlice";
import { updatePasswordSchema } from "../../validation/Schema";
// fullname
// email
// password
const UpdatePasswordPage = () => {
  const navigate = useNavigate();
  const access_token = Cookies?.get("ayykori");

  const [
    updatePassword,
    {
      isLoading: isLoadingUser,
      isError: isErrorUser,
      isSuccess: isSuccessUser,
      error: errorUser,
    },
  ] = useUpdatePasswordMutation();

  const userInitialValue = {
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  };

  // update password form
  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      initialValues: userInitialValue,
      validationSchema: updatePasswordSchema,
      onSubmit: async (values, action) => {
        updatePassword({
          access_token,
          data: {
            oldPassword: values?.oldPassword,
            newPassword: values?.newPassword,
          },
        });
        // action.resetForm();
      },
    });

  // success message
  useEffect(() => {
    if (isSuccessUser && !isErrorUser) {
      toast.success("Password Updated Successfully!!");
      navigate("/my-account");
    }
  }, [isSuccessUser, isErrorUser, navigate]);

  // error message
  useEffect(() => {
    if (isErrorUser) {
      toast.error(errorUser?.data?.message);
    }
  }, [isErrorUser, errorUser?.data?.message]);

  // after navigate this page show in top
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="w-full py-2 md:p-8">
      <div className="flex items-start justify-between">
        <div className="w-full">
          <div className="w-full flex items-center justify-between">
            <h1 className="text-base md:text-lg text-gray-500 font-outfit font-medium">
              Change Password
            </h1>
          </div>
          <p className="text-xs md:text-base text-gray-400 font-outfit font-light">
            Manage your Ayykori account password with easy access to personal
            info, account balance & more.
          </p>
        </div>
      </div>

      <form onSubmit={handleSubmit} className="md:my-7">
        <div className="flex flex-col-reverse md:grid  md:grid-cols-2 gap-x-4">
          <div className="flex flex-col">
            <div className="flex flex-col gap-y-1 my-2 text-sm">
              <label htmlFor="">Old Password</label>
              <div className=" text-gray-500 flex items-center border gap-2 bg-white rounded-md px-3 py-2">
                <CiLock size={20} className="text-gray-500" />
                <input
                  type="password"
                  name="oldPassword"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values?.oldPassword}
                  placeholder="Enter Old Password"
                  className="outline-none border-none w-full  bg-transparent text-gray-500"
                />
              </div>
              {errors.oldPassword && touched.oldPassword ? (
                <p className="text-xs text-red-500">{errors.oldPassword}</p>
              ) : null}
            </div>
            <div className="flex flex-col gap-y-1 my-2 text-sm">
              <label htmlFor="">New Password</label>
              <div className=" text-gray-500 flex items-center border gap-2 bg-white rounded-md px-3 py-2">
                <CiLock size={20} className="text-gray-500" />
                <input
                  type="password"
                  name="newPassword"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values?.newPassword}
                  placeholder="Enter New Password"
                  className="outline-none border-none w-full  bg-transparent text-gray-500"
                />
              </div>
              {errors.newPassword && touched.newPassword ? (
                <p className="text-xs text-red-500">{errors.newPassword}</p>
              ) : null}
            </div>
            <div className="flex flex-col gap-y-1 my-2 text-sm">
              <label htmlFor="">Confirm Password</label>
              <div className=" text-gray-500 flex items-center border gap-2 bg-white rounded-md px-3 py-2">
                <CiLock size={20} className="text-gray-500" />
                <input
                  type="password"
                  name="confirmPassword"
                  value={values?.confirmPassword}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  placeholder="Enter Confirm Password"
                  className="outline-none border-none w-full  bg-transparent text-gray-500"
                />
              </div>
              {errors.confirmPassword && touched.confirmPassword ? (
                <p className="text-xs text-red-500">{errors.confirmPassword}</p>
              ) : null}
            </div>
          </div>
          <div className="w-full h-full flex justify-center items-center">
            <img className="w-72  object-cover" src={update_password} alt="" />
          </div>
        </div>

        <div className="flex items-center pt-1 justify-between text-sm w-full">
          <button
            type="submit"
            disabled={isLoadingUser ? true : false}
            className="rounded-md border group text-white bg-primaryColor py-1.5 px-7 flex gap-2 items-center mr-2"
          >
            {isLoadingUser ? "Loading.." : "Update"}
          </button>
        </div>
      </form>
    </div>
  );
};

export default UpdatePasswordPage;
