import Cookies from "js-cookie";
import React, { useEffect } from "react";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { showModal } from "../../features/loginModalShowSlice.js/loginModalShowSlice";
import { useCreateProfitLinkMutation } from "../../features/profitlink/profitlinkApiSlice";
import useAuth from "../../hooks/useAuth";

const BrandSearchCard = ({ data, type }) => {
  const dispatch = useDispatch();
  const access_token = Cookies.get("ayykori");
  const isAuth = useAuth();
  const [createProfitLink, { isLoading: isLoadingLink, isError: isErrorLink }] =
    useCreateProfitLinkMutation() || {};

  // creating shortlink from sub brand link
  const handleCashbackOrEarning = async () => {
    if (isAuth || Cookies.get("ayykori")) {
      if (type === "cashback") {
        const shortlinkRes = await createProfitLink({
          access_token,
          fullLink: data?.subBrandLink,
          source: "cashback",
        });
        const { data: shortlinkData } = shortlinkRes || {};
        if (
          shortlinkData &&
          shortlinkData?.shortLink &&
          shortlinkData?.succss
        ) {
          let uid =
            shortlinkData?.shortLink?.split("_")[
              shortlinkData?.shortLink?.split("_")?.length - 1
            ];
          window.open(
            `/cashback-activated/${data?._id}?uid=${uid}`,
            "_blank",
            // "popup",
            "noreferrer"
          );
        }
      }
      // if (type === "earning") {
      //   setCopyModal(true);
      //   createProfitLink({
      //     access_token,
      //     fullLink: data?.subBrandLink,
      //     source: "earning",
      //   });
      // }
    } else {
      dispatch(showModal());
    }
  };
  useEffect(() => {
    if (isErrorLink) {
      toast.error("Something Went Wrong!! Please try again!");
    }
  }, [isErrorLink]);

  return (
    <>
      {data?.subBrandName ? (
        isLoadingLink && !isErrorLink ? (
          <div className=" rounded-md md:p-2 flex gap-x-2 cursor-pointer text-gray-500 hover:bg-gray-50">
            <h1 className="text-xs md:text-sm font-outfit">Loading..</h1>
          </div>
        ) : (
          <div
            onClick={handleCashbackOrEarning}
            className=" rounded-md md:p-2 flex gap-x-2 cursor-pointer hover:bg-gray-50"
          >
            <img
              src={data?.subBrandimg ? data?.subBrandimg : data?.brandimg}
              alt=""
              className="w-10 h-10 rounded-md object-cover"
            />
            <div>
              {data?.brandName?.length > 15 ||
              data?.subBrandName?.length > 15 ? (
                <h1 className="text-xs md:text-sm font-outfit text-primaryColor font-medium">
                  {data?.subBrandName
                    ? data?.subBrandName?.slice(0, 15)
                    : data?.brandName?.slice(0, 15)}
                  ...
                </h1>
              ) : (
                <h1 className="text-xs md:text-sm font-outfit text-primaryColor font-medium">
                  {data?.subBrandName ? data?.subBrandName : data?.brandName}
                </h1>
              )}

              {data?.brandUnid === "2040" ||
              data?.brandId === "65e844670754c7e14046814a" ||
              data?.brandName === "Shohoz (Bus)" ? (
                <h6 className="text-xs font-outfit text-gray-400 font-normal">
                  Up to {data?.cashbackUserCommission}&#2547; cashback
                </h6>
              ) : (
                <h6 className="text-xs font-outfit text-gray-400 font-normal">
                  Up to {data?.cashbackUserCommission}% cashback
                </h6>
              )}
            </div>
          </div>
        )
      ) : (
        <Link
          to={`/brand/cashback/${data?._id}`}
          className=" rounded-md md:p-2 flex gap-x-2 cursor-pointer hover:bg-gray-50"
        >
          <img
            src={data?.subBrandimg ? data?.subBrandimg : data?.brandimg}
            alt=""
            className="w-10 h-10 rounded-md object-cover"
          />
          <div>
            {data?.brandName?.length > 15 || data?.subBrandName?.length > 15 ? (
              <h1 className="text-xs md:text-sm font-outfit text-primaryColor font-medium">
                {data?.subBrandName
                  ? data?.subBrandName?.slice(0, 15)
                  : data?.brandName?.slice(0, 15)}
                ...
              </h1>
            ) : (
              <h1 className="text-xs md:text-sm font-outfit text-primaryColor font-medium">
                {data?.subBrandName ? data?.subBrandName : data?.brandName}
              </h1>
            )}
            {data?.brandUnid === "2040" ||
            data?.brandId === "65e844670754c7e14046814a" ||
            data?.brandName === "Shohoz (Bus)" ? (
              <h6 className="text-xs font-outfit text-gray-400 font-normal">
                Up to {data?.cashbackUserCommission}&#2547; cashback
              </h6>
            ) : (
              <h6 className="text-xs font-outfit text-gray-400 font-normal">
                Up to {data?.cashbackUserCommission}% cashback
              </h6>
            )}
          </div>
        </Link>
      )}
    </>
  );
};

export default BrandSearchCard;
