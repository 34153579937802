import Cookies from "js-cookie";
import { useGetSingleUserQuery } from "../features/user/userApiSlice";

const useAuthUser = () => {
  const access_token = Cookies?.get("ayykori");

  const {
    isLoading,
    isSuccess,
    isError,
    data: userData,
  } = useGetSingleUserQuery(access_token, {
    skip: !access_token,
    refetchOnReconnect: true,
  }) || {};

  return isSuccess && !isLoading && !isError ? userData?.user : null;
};

export default useAuthUser;
