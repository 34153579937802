import * as Yup from "yup";
// const urlRegex =
//   /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/;
const phoneRegex = /(^(\+88|0088)?(01){1}[3456789]{1}(\d){8})$/;
// const userRegex = /^[A-z][A-z0-9-_]{3,23}$/;
// const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%]).{8,24}$/;
// const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]).{8,24}$/;

export const loginSchema = Yup.object({
  email: Yup.string().email().required("Enter your email"),
  password: Yup.string().required("Enter your password"),
});
export const forgetPasswordSchema = Yup.object({
  email: Yup.string().email().required("Enter your email"),
});
export const resetPasswordSchema = Yup.object().shape({
  newPassword: Yup.string()
    .min(8, "Your password is too weak. Please enter minimum 8 character")
    .max(24, "Your password is too long. Please enter within 24 character")
    .required("New Password is required"),
  confirmPassword: Yup.string()
    .min(8, "Your password is too weak. Please enter minimum 8 character")
    .max(24, "Your password is too long. Please enter within 24 character")
    .oneOf([Yup.ref("newPassword"), null], "Passwords must match")
    .required("Confirm Password is required"),
});
export const signupSchema = Yup.object({
  fullname: Yup.string().required("Enter your name"),
  email: Yup.string().email().required("Enter your email"),
  phone: Yup.string()
    .matches(phoneRegex, "Invalid Phone Number")
    .required("Enter your phone number"),
  password: Yup.string()
    .min(8, "Your password is too weak. Please enter minimum 8 character")
    .max(24, "Your password is too long. Please enter within 24 character")
    .required("Enter your password"),
});
export const updatePasswordSchema = Yup.object({
  oldPassword: Yup.string().required("Enter your old password"),
  newPassword: Yup.string()
    .min(8, "Your password is too weak. Please enter minimum 8 character")
    .max(24, "Your password is too long. Please enter within 24 character")
    .required("Enter your new password"),
  confirmPassword: Yup.string()
    .min(8, "Your password is too weak. Please enter minimum 8 character")
    .max(24, "Your password is too long. Please enter within 24 character")
    .oneOf([Yup.ref("newPassword"), null], "Confirm Password does not match")
    .required("Enter your confirm password"),
});
export const contactUsSchema = Yup.object({
  fullName: Yup.string().required("Enter your name"),
  phoneNumber: Yup.string()
    .matches(phoneRegex, "Invalid Phone Number")
    .required("Enter your phone number"),
  email: Yup.string().email().required("Enter your email"),
  description: Yup.string().required("Enter your description"),
});
export const updateProfileSchema = Yup.object({
  phone: Yup.string(),
  gender: Yup.string("Please Select Your Gender"),
  occupation: Yup.string(),
  age: Yup.number().required("Enter Your Age"),
  address: Yup.string(),
  paymentMethod: Yup.string().required("Enter Your Payment Method"),
  accountnumber: Yup.string(),
  accountname: Yup.string(),
  districtname: Yup.string(),
  routingno: Yup.number(),
  bkashno: Yup.string().matches(phoneRegex, "Invalid Phone Number"),
  nagadno: Yup.string().matches(phoneRegex, "Invalid Phone Number"),
});
export const phoneNumberValidationSchema = Yup.object({
  phone: Yup.string()
    .matches(phoneRegex, "Invalid Phone Number")
    .required("Enter your phone number"),
});
