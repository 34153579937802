import Cookies from "js-cookie";
import React, { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { TbLink } from "react-icons/tb";
import profit from "../../Assets/images/make-commission-link/profitlink.gif";
import ProfitLinkModal from "../../components/Modal/ProfitLinkModal";
import MotherBrandSlider from "../../components/Slider/MotherBrandSlider";
import { useCreateProfitLinkMutation } from "../../features/profitlink/profitlinkApiSlice";

const MakeProfitLinkPage = () => {
  const access_token = Cookies.get("ayykori");
  const [createProfitLink, { data, isLoading, isError, isSuccess, error }] =
    useCreateProfitLinkMutation() || {};
  const [profitModal, setProfitModal] = useState(false);
  const [productlink, setProductLink] = useState("");
  const [, setProfitLink] = useState("");
  const handleCreateProfitLink = (e) => {
    if (!productlink) {
      toast.error("Please enter the brand/product link..");
    } else {
      createProfitLink({
        access_token,
        fullLink: productlink,
        source: "earning",
      });
      if (isSuccess && !isError) {
        setProfitLink(data?.shortLink);
      }
    }
  };

  useEffect(() => {
    if (isSuccess) {
      setProductLink("");
      setProfitModal(true);
    }
  }, [isSuccess]);
  // showing error message
  useEffect(() => {
    if (isError) {
      toast.error(error?.data?.message || "Something Went Wrong!!");
    }
  }, [isError, error?.data]);

  // after navigate this page show in top
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="py-2 md:p-8">
      <ProfitLinkModal
        profitModal={profitModal}
        setProfitModal={setProfitModal}
        profitLink={data?.shortLink}
      />
      <div className="flex flex-col justify-center items-center gap-y-2">
        <img src={profit} alt="" className="w-36" />
        <h1 className="text-sm md:text-lg text-gray-500 font-outfit font-medium">
          Create Your Affiliate Link Here
        </h1>
        <p className="text-xs md:text-base text-center text-gray-400 font-outfit font-light -mt-2 mb-1">
          Copy the brand homepage or product link, paste it, and generate the
          Affiliate link.
        </p>
        <input
          className="w-[90%] md:w-[50%] text-xs md:text-base border rounded-full px-4 py-2  outline-none font-light mr-1 text-gray-500"
          value={productlink}
          placeholder="Paste the product/brand link here"
          type="text"
          onChange={(e) => setProductLink(e.target.value)}
        />
        <button
          onClick={handleCreateProfitLink}
          disabled={isLoading}
          className="flex items-center gap-x-1 text-xs md:text-base bg-primaryColor text-white rounded-full px-4 py-1"
        >
          {isLoading ? "Processing.." : "Create affiliate  link"}
          <TbLink />
        </button>
      </div>
      <div className="flex flex-col justify-center items-center mt-8">
        <h1 className="text-base md:text-lg text-gray-500 font-outfit font-medium">
          Put the Brand Link in the box above
        </h1>
        <p className="text-xs md:text-base text-center text-gray-400 font-outfit font-light mb-1">
          Copy the Brand's homepage link from here and paste in Create Affiliate
          Link box.
        </p>
        <MotherBrandSlider />
      </div>
    </div>
  );
};

export default MakeProfitLinkPage;
