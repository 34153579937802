import Cookies from "js-cookie";
import React, { useState } from "react";
import { toast } from "react-hot-toast";
import { AiOutlineLink, AiOutlineShareAlt } from "react-icons/ai";
import { BsActivity } from "react-icons/bs";
import { CiBoxList, CiUser } from "react-icons/ci";
import { FiChevronRight } from "react-icons/fi";
import { HiOutlineBookOpen, HiOutlineHome } from "react-icons/hi";
import {
  IoCalculatorOutline,
  IoExitOutline,
  IoLogInOutline,
} from "react-icons/io5";
import { MdArrowBackIos } from "react-icons/md";
import { RiDashboardFill } from "react-icons/ri";
import { RxCross2, RxDotFilled, RxHamburgerMenu } from "react-icons/rx";
import { SiGoogleclassroom } from "react-icons/si";
import { TbMessageCircle } from "react-icons/tb";
import { TfiWallet } from "react-icons/tfi";
import { useDispatch } from "react-redux";
import { NavLink, useNavigate } from "react-router-dom";
import { isLogoutUser } from "../../features/auth/authSlice";
import { showModal } from "../../features/loginModalShowSlice.js/loginModalShowSlice";
import { setReload } from "../../features/reload/reloadSlice";
import { auth } from "../../firebase/Firebase";
import useAuth from "../../hooks/useAuth";
import { removeToken } from "../Utility/LocalStorage";

const MobileBottomNavbar = ({ data }) => {
  const [showHelp, setShowHelp] = useState(false);
  const [showFavBrand, setShowFavBrand] = useState(false);
  const [showBalance, setShowBalance] = useState(false);
  const [showCalculation, setShowCalculation] = useState(false);
  const [showKnowInsideOut, setShowKnowInsideOut] = useState(false);
  const [store, setStore] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { open, setOpen } = data;
  const isAuth = useAuth();
  // console.log(isAuth);
  const [token, setToken] = useState();
  const handleLogout = () => {
    Cookies.remove("ayykori");
    removeToken("ayykori");
    auth.signOut();
    dispatch(isLogoutUser());
    dispatch(setReload(1));
    setOpen(false);
    navigate("/");
    toast.success("Logout Successfull!!");
  };

  const activeNavLink = ({ isActive }) => {
    return {
      color: isActive ? "#11998E" : "#6b7280",
    };
  };

  return (
    <>
      <div className=" lg:hidden flex items-center justify-between gap-4 fixed bottom-0 left-0 right-0 px-2 bg-gray-100 w-full h-16 shadow-[0px_2px_2px_#00000040] border-t z-[200]">
        <div className=" w-[90%] mx-auto p-1 flex items-center justify-between z-[400]">
          <div className="flex flex-col items-center justify-center">
            <NavLink
              to={"/"}
              title="Home"
              className="p-[5px] rounded-full bg-gray-200"
              style={activeNavLink}
            >
              <HiOutlineHome size={20} className="" />
            </NavLink>
            <h6 className="text-[10px] text-gray-700">Home</h6>
          </div>
          <div className="flex flex-col items-center justify-center">
            <NavLink
              to={"/brands/categories"}
              title="Categories"
              className="p-1 rounded-full bg-gray-200"
              style={activeNavLink}
            >
              <CiBoxList size={20} className="" />
            </NavLink>
            <h6 className="text-[10px] text-gray-700">Categories</h6>
          </div>
          {(isAuth || Cookies.get("ayykori")) && (
            <div className="flex flex-col items-center justify-center">
              <NavLink
                to={"/my-account"}
                title="Dashboard"
                className="p-2 -mt-[20px] rounded-full bg-gray-200 ring-white ring-4 "
                style={activeNavLink}
                end
              >
                <RiDashboardFill className="text-2xl"></RiDashboardFill>
              </NavLink>
              <h6 className="text-[10px] mt-2.5 text-gray-700">Dashboard</h6>
            </div>
          )}
          <div className="flex flex-col items-center justify-center">
            <button
              // to={isAuth ? "/my-account/personal-info" : "/login"}
              onClick={() => {
                if (isAuth || Cookies.get("ayykori")) {
                  navigate("/my-account/personal-info");
                } else {
                  dispatch(showModal());
                }
              }}
              className="p-1 rounded-full bg-gray-200"
            >
              <CiUser size={20} className="" />
            </button>
            <h6 className="text-[10px] text-gray-700">Account</h6>
          </div>
          <div className="flex flex-col items-center justify-center">
            <span
              onClick={() => setOpen(!open)}
              className="p-1 rounded-full bg-gray-200"
            >
              {open ? (
                <RxCross2
                  size={20}
                  className={`${open ? "text-primaryColor" : "text-gray-500"}`}
                />
              ) : (
                <RxHamburgerMenu
                  size={20}
                  className={`${open ? "text-primaryColor" : "text-gray-500"}`}
                />
              )}
            </span>
            <h6 className="text-[10px]">Menu</h6>
          </div>
        </div>
        {/* mobile side navigation bar */}
        <div
          className={`w-full  xs:w-[70%] sm:w-[60%] h-[calc(100vh-60px)] overflow-auto bg-white border text-left  absolute bottom-full 
        right-0 ${
          open ? "translate-x-0" : "translate-x-[110%]"
        } transition-all duration-1000 z-[300]`}
        >
          <ul className="w-full">
            <div className="h-[60px] border-b flex items-center">
              <h1 className="flex text-lg items-center px-2 py-4 text-primaryColor">
                <MdArrowBackIos
                  className="animate-pulse text-primaryColor"
                  onClick={() => setOpen(false)}
                />
                Ayykori
              </h1>
            </div>
            <div
              className="text-textColor font-semibold text-sm 
         transition-all duration-1000 flex flex-col gap-y-6 list-none px-4 mt-4"
            >
              {/*cashback */}
              {/* {isAuth &&  } */}
              <NavLink
                to="/my-account"
                className=" text-sm font-normal text-gray-500 font-outfit hover:text-primaryColor 
        transition-all duration-500  cursor-pointer flex items-center gap-2"
              >
                <TfiWallet className="" size={20} />
                Cashback Balance
              </NavLink>{" "}
              <NavLink
                to="/my-account/cashback-calculation"
                className=" text-sm font-normal text-gray-500 font-outfit hover:text-primaryColor 
        transition-all duration-500  cursor-pointer flex items-center gap-2"
              >
                <IoCalculatorOutline />
                Cashback Calculator
              </NavLink>{" "}
              {/* share and earn */}
              {/* know inside out */}
              <div className="flex w-full flex-col items-center justify-between">
                <div
                  onClick={() => setShowKnowInsideOut(!showKnowInsideOut)}
                  className="w-full flex items-center justify-between gap-x-2 text-gray-500 text-base font-normal font-outfit hover:text-primaryColor 
        transition-all duration-500  cursor-pointer"
                >
                  <span className="flex text-sm items-center gap-x-2">
                    <HiOutlineBookOpen size={20} />
                    Know inside Out
                  </span>
                  <FiChevronRight
                    onClick={() => setShowKnowInsideOut(!showKnowInsideOut)}
                    className={`${
                      showKnowInsideOut && "rotate-90"
                    } transition-all duration-500`}
                  />
                </div>

                {showKnowInsideOut && (
                  <div
                    className={`rounded-md w-full flex flex-col ${
                      showKnowInsideOut ? "opacity-100" : "opacity-0"
                    } transition-all duration-500 ease-in mt-4 gap-y-4 pl-4`}
                  >
                    <NavLink
                      to={"/know-inside-out/learn-about-cashback"}
                      className=" text-sm font-normal text-gray-500 font-outfit hover:text-primaryColor 
        transition-all duration-500  cursor-pointer flex items-center gap-2"
                    >
                      <RxDotFilled className="text-gray-300" size={20} />
                      Cashback
                    </NavLink>{" "}
                    <NavLink
                      to={"/know-inside-out/learn-about-earn"}
                      className=" text-sm font-normal text-gray-500 font-outfit hover:text-primaryColor 
        transition-all duration-500  cursor-pointer flex items-center gap-2"
                    >
                      <RxDotFilled className="text-gray-300" size={20} />
                      Affiliate
                    </NavLink>{" "}
                  </div>
                )}
              </div>
              <button
                onClick={() => {
                  if (isAuth || Cookies.get("ayykori")) {
                    navigate("/my-account/my-referrals");
                  } else {
                    dispatch(showModal());
                  }
                }}
                className=" text-sm font-normal text-gray-500 font-outfit hover:text-primaryColor 
        transition-all duration-500  cursor-pointer flex items-center gap-2"
              >
                <AiOutlineShareAlt size={20} />
                Refrrals
              </button>
              {/* refer and earn */}
            </div>
            <div
              className="w-full text-textColor font-semibold text-sm 
          transition-all duration-1000 flex flex-col gap-y-6 mt-4 list-none border-t-8 pt-4 px-4"
            >
              {/* login */}
              {(!isAuth || !Cookies.get("ayykori")) && (
                <button
                  // to={"/login"}
                  onClick={() => dispatch(showModal())}
                  className=" text-sm font-normal text-gray-500 font-outfit hover:text-primaryColor 
        transition-all duration-500  cursor-pointer flex items-center gap-2"
                >
                  <IoLogInOutline size={20} />
                  Login
                </button>
              )}
              {/*      Affiliate */}
              {(isAuth || Cookies.get("ayykori")) && (
                <div className="flex w-full flex-col items-center justify-between">
                  <div
                    onClick={() => setShowBalance(!showBalance)}
                    className="w-full flex items-center justify-between gap-x-2 text-gray-500 text-base font-normal font-outfit hover:text-primaryColor 
        transition-all duration-500  cursor-pointer"
                  >
                    <span className="flex text-sm items-center gap-x-2">
                      <TfiWallet size={20} />
                      Affiliate
                    </span>
                    <FiChevronRight
                      onClick={() => setShowBalance(!showBalance)}
                      className={`${
                        showBalance && "rotate-90"
                      } transition-all duration-500`}
                    />
                  </div>

                  {showBalance && (
                    <div
                      className={`rounded-md w-full flex flex-col ${
                        showBalance ? "opacity-100" : "opacity-0"
                      } transition-all duration-500 ease-in mt-4 gap-y-4 pl-4`}
                    >
                      {/* <NavLink
                        to={"/affiliate"}
                        className=" text-sm font-normal text-gray-500 font-outfit hover:text-primaryColor
        transition-all duration-500  cursor-pointer flex items-center gap-2"
                      >
                        <span>
                          <GiTakeMyMoney size={20} />
                        </span>
                        Affiliate{" "}
                      </NavLink> */}
                      <NavLink
                        to="/my-account/earning-balance"
                        className=" text-sm font-normal text-gray-500 font-outfit hover:text-primaryColor 
        transition-all duration-500  cursor-pointer flex items-center gap-2"
                      >
                        <TfiWallet className="" size={16} />
                        Affiliate Balance
                      </NavLink>{" "}
                      <NavLink
                        to="/my-account/earning-calculation"
                        className=" text-sm font-normal text-gray-500 font-outfit hover:text-primaryColor 
        transition-all duration-500  cursor-pointer flex items-center gap-2"
                      >
                        <IoCalculatorOutline />
                        Affiliate Calculator
                      </NavLink>{" "}
                      {(isAuth || Cookies.get("ayykori")) && (
                        <NavLink
                          to="/my-account/make-affiliate-link"
                          className=" text-xs font-normal text-gray-500 font-outfit hover:text-primaryColor
        transition-all duration-500  cursor-pointer flex items-center gap-2"
                        >
                          <span>
                            <AiOutlineLink size={20} />
                          </span>
                          Make Affiliate Link
                        </NavLink>
                      )}
                      {(isAuth || Cookies.get("ayykori")) && (
                        <NavLink
                          to="/my-account/online-workshop"
                          className=" text-sm font-normal text-gray-500 font-outfit hover:text-primaryColor
        transition-all duration-500  cursor-pointer flex items-center gap-2"
                        >
                          <span>
                            <SiGoogleclassroom size={20} />
                          </span>
                          Online Workshop
                        </NavLink>
                      )}
                      {(isAuth || Cookies.get("ayykori")) && (
                        <NavLink
                          to="/my-account/user-activity-log"
                          className=" text-sm font-normal text-gray-500 font-outfit hover:text-primaryColor
        transition-all duration-500  cursor-pointer flex items-center gap-2"
                        >
                          <span>
                            <BsActivity size={20} />
                          </span>
                          Activity Log
                        </NavLink>
                      )}
                    </div>
                  )}
                </div>
              )}

              {/* my profit calculation*/}

              {/* online workshop */}

              {/* activity log */}

              {/* favourite brands */}
              {/* {isAuth && (
                <div className="flex w-full flex-col items-center justify-between">
                  <div
                    onClick={() => setShowFavBrand(!showFavBrand)}
                    className="w-full flex items-center justify-between gap-x-2 text-gray-500 text-base font-normal font-outfit hover:text-primaryColor 
        transition-all duration-500  cursor-pointer"
                  >
                    <span className="flex text-sm items-center gap-x-2">
                      <AiOutlineHeart size={20} />
                      Favourite Brands
                    </span>
                    <FiChevronRight
                      onClick={() => setShowFavBrand(!showFavBrand)}
                      className={`${
                        showFavBrand && "rotate-90"
                      } transition-all duration-500`}
                    />
                  </div>

                  {showFavBrand && (
                    <div
                      className={`rounded-md w-full flex flex-col ${
                        showFavBrand ? "opacity-100" : "opacity-0"
                      } transition-all duration-500 ease-in mt-4 gap-y-4 pl-4`}
                    >
                      <NavLink
                        to="/my-account/cashback-favourite-brands"
                        className=" text-sm font-normal text-gray-500 font-outfit hover:text-primaryColor 
        transition-all duration-500  cursor-pointer flex items-center gap-2"
                      >
                        <RxDotFilled className="text-gray-300" size={20} />
                        Cashback
                      </NavLink>{" "}
                      <NavLink
                        to="/my-account/earning-favourite-brands"
                        className=" text-sm font-normal text-gray-500 font-outfit hover:text-primaryColor 
        transition-all duration-500  cursor-pointer flex items-center gap-2"
                      >
                        <RxDotFilled className="text-gray-300" size={20} />
                        Affiliate
                      </NavLink>{" "}
                    </div>
                  )}
                </div>
              )} */}

              {/* shop for mobile  */}
              {/* <div
                className="relative text-base font-medium  font-inter hover:text-primaryColor text-gray-500
        transition-all duration-500  cursor-pointer flex items-center gap-1 whitespace-pre"
                onClick={() => setStore(!store)}
              >
                <span>
                  <Lordicon
                    link={"hyhnpiza"}
                    size={20}
                    color={!store ? "#11998E" : "#6b7280"}
                    trigger={"hover"}
                    target={"div"}
                    customStyle={{
                      width: `20px`,
                      height: `20px`,
                      fontWeight: "600px",
                      marginTop: "6px",
                    }}
                    className={`text-lg  ${
                      open ? "text-primaryColor" : "text-gray-500"
                    }`}
                  />

                  {/* <TbMessageCircle size={20} /> */}
              {/* </span>
                Store
                <RiArrowDownSLine
                  className={`${store ? "rotate-180" : "rotate-0"}`}
                />
               
                <ShopCompo store={store} />
              
              </div> */}

              {/* help */}
              <div className="flex w-full flex-col items-center justify-between">
                <div
                  onClick={() => setShowHelp(!showHelp)}
                  className="w-full flex items-center justify-between gap-x-2 text-gray-500 text-base font-normal font-outfit hover:text-primaryColor 
        transition-all duration-500  cursor-pointer"
                >
                  <span className="flex text-sm items-center gap-x-2">
                    <TbMessageCircle size={20} />
                    Help
                  </span>
                  <FiChevronRight
                    onClick={() => setShowHelp(!showHelp)}
                    className={`${
                      showHelp && "rotate-90"
                    } transition-all duration-500`}
                  />
                </div>

                {showHelp && (
                  <div
                    className={`rounded-md w-full flex flex-col ${
                      showHelp ? "opacity-100" : "opacity-0"
                    } transition-all duration-500 ease-in mt-4 gap-y-4 pl-4`}
                  >
                    <NavLink
                      to={"/customer-support"}
                      className=" text-sm font-normal text-gray-500 font-outfit hover:text-primaryColor 
        transition-all duration-500  cursor-pointer flex items-center gap-2"
                    >
                      <RxDotFilled className="text-gray-300" size={20} />
                      Customer Support
                    </NavLink>{" "}
                    <NavLink
                      to={"/faqs"}
                      className=" text-sm font-normal text-gray-500 font-outfit hover:text-primaryColor 
        transition-all duration-500  cursor-pointer flex items-center gap-2"
                    >
                      <RxDotFilled className="text-gray-300" size={20} />
                      FAQ
                    </NavLink>{" "}
                    <NavLink
                      to={"/contact"}
                      className=" text-sm font-normal text-gray-500 font-outfit hover:text-primaryColor 
        transition-all duration-500  cursor-pointer flex items-center gap-2"
                    >
                      <RxDotFilled className="text-gray-300" size={20} />
                      Contact Us
                    </NavLink>{" "}
                  </div>
                )}
              </div>

              {(isAuth || Cookies.get("ayykori")) && (
                <li
                  onClick={handleLogout}
                  className={`text-sm font-normal text-gray-500 font-outfit hover:text-primaryColor 
        transition-all duration-500  cursor-pointer flex items-center gap-2 pb-4`}
                >
                  <IoExitOutline size={20} />
                  Sign Out
                </li>
              )}
            </div>
          </ul>
        </div>
      </div>
      <div
        onClick={() => setOpen(false)}
        className={`${
          open ? "block" : "hidden"
        } block sm:hidden fixed transition-all duration-1000 left-0 right-0 bottom-0 top-0 w-screen h-screen bg-[rgba(0,0,0,0.5)] z-[100]`}
      ></div>
    </>
  );
};

export default MobileBottomNavbar;
