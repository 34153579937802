import React from "react";
import { RxDashboard } from "react-icons/rx";

const CategoryPageSortComponent = ({
  title,
  limit,
  setLimit,
  cashback,
  setCashBack,
}) => {
  return (
    <div className="w-full px-3 md:px-[55px] my-10">
      <div className=" text-center flex items-center justify-between gap-2 ">
        <div className=" w-full h-[2.5px] flex justify-between items-end gap-x-5 bg-gray-300 ">
          <h1
            className=" whitespace-pre w-fit text-sm sm:text-lg md:text-2xl text-gray-600 font-medium pb-2 border-b-[3px]
     border-primaryColor "
          >
            {title}
          </h1>

          {/* sort start */}
          <div className="flex items-center gap-x-2 mb-2 text-gray-500">
            <div className="flex items-center border text-xs  md:text-sm rounded-lg px-1 md:px-3 gap-x-2">
              <RxDashboard />
              Show
              <select
                value={limit}
                onChange={(e) => setLimit(e.target.value)}
                className=" px-1 py-1 md:py-2 bg-transparent  text-gray-500 outline-none cursor-pointer"
              >
                <option value={15}>15</option>
                <option value={25}>24</option>
                <option value={35}>35</option>
                <option value={40}>40</option>
              </select>
            </div>
            {/* <div className="flex items-center border rounded-lg text-sm px-2">
              <BiSort />
              Sort By:
              <select
                onChange={(e) => setCashBack(e.target.value)}
                value={cashback}
                className=" px-2 py-2 bg-transparent  text-gray-500 outline-none cursor-pointer"
              >
                <option value={"descending"}>Highest Cashback</option>
                <option value={"ascending"}>Lowest Cashback</option>
              </select>
            </div> */}
          </div>
          {/* sort end */}
        </div>
      </div>
    </div>
  );
};

export default CategoryPageSortComponent;
