import React, { useEffect, useState } from "react";
import { AiOutlineFire } from "react-icons/ai";
import { FiChevronDown } from "react-icons/fi";
import { RiArrowDownSLine } from "react-icons/ri";
import { useDispatch } from "react-redux";
import { NavLink, useNavigate } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import Lordicon from "../Utility/LordIcon";
import CategoryNavbar from "./CategoryNavbar";
const BottomNavbar = () => {
  const isAuth = useAuth();
  let icon = "svbmmyue";
  const [help, setHelp] = useState(false);
  const [categoryHover, setCategoryHover] = useState(false);
  const [knowinsideout, setknowinsideout] = useState(false);
  const [refferearn, setRefferearn] = useState(false);
  const [store, setStore] = useState(false);
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // for active link style
  const activeNavLink = ({ isActive }) => {
    return {
      color: isActive ? "#11998E" : "#6b7280",
      // borderColor: isActive ? "#7efff5" : "#ffffff",
    };
  };

  // another active Link Style
  const [, setActiveItem] = useState("home");
  const [hoverStyle, setHoverStyle] = useState(false);
  const [isHovered, setIsHovered] = useState(false);

  const handleClick = (itemName) => {
    setActiveItem(itemName);
  };
  useEffect((itemName) => {
    handleClick(itemName);
  }, []);

  return (
    <div className="hidden lg:block py-2 border-b-2 mb-2 z-[1000]">
      <div className="w-full px-3 md:px-[55px] flex items-center justify-between lg:gap-x-8 max-w-[1632px] mx-auto">
        <div
          className="relative group py-3 text-gray-500"
          onMouseOver={() => setCategoryHover(true)}
          onMouseOut={() => setCategoryHover(false)}
        >
          <button
            className="flex items-center gap-3  cursor-pointer  text-gray-500 "
            onClick={() => setOpen(!open)}
          >
            <Lordicon
              link={"ynwbvguu"}
              size={22}
              color={categoryHover ? "#11998E" : "#6b7280"}
              trigger={"hover"}
              target={"div"}
              customStyle={{
                width: `22px`,
                height: `22px`,
                fontWeight: "600px",
              }}
              className={`text-lg  ${
                open ? "text-primaryColor" : "text-gray-500"
              }`}
            />
            <h1
              className={`text-base font-medium font-outfit ${
                open ? "text-primaryColor" : "text-gray-500"
              } ${categoryHover ? "text-primaryColor" : "text-gray-500"}`}
            >
              Categories
            </h1>
            {open ? (
              <FiChevronDown
                className={`text-lg rotate-180 transition-all duration-700 ${
                  open ? "text-primaryColor" : "text-gray-500"
                }`}
              />
            ) : (
              <FiChevronDown
                className={`text-lg transition-all duration-700 ${
                  open ? "text-primaryColor" : "text-gray-500"
                }`}
              />
            )}
          </button>
          <div
            className={`${
              open ? "block" : "hidden"
            } w-screen sm:w-[400px]  absolute -left-2 top-12 py-3  bg-white  rounded-md shadow-md border border-[#b1e2cb] transition-all duration-700 group-hover:block`}
          >
            <CategoryNavbar />
          </div>
        </div>

        <span
          onClick={() => {
            navigate("/know-inside-out/learn-about-cashback");
            // dispatch(knowInsideModalShow());
            // setHoverStyle(setHoverStyle(!hoverStyle));
          }}
          onMouseOver={() => setknowinsideout(true)}
          onMouseOut={() => setknowinsideout(false)}
          className=" text-base font-medium text-gray-500 font-inter hover:text-primaryColor 
        transition-all duration-500  cursor-pointer flex items-center gap-1 whitespace-pre"
        >
          <div
            className="hover:text-primaryColor  flex items-center gap-1 "
            // onClick={() => setHoverStyle(setHoverStyle(!hoverStyle))}
          >
            <div>
              <Lordicon
                link={"kipaqhoz"}
                size={20}
                trigger={"hover"}
                target={"span"}
                customStyle={{
                  width: `20px`,
                  height: `20px`,
                  fontWeight: "500px",
                  marginTop: "5px",
                }}
                color={knowinsideout ? "#11998E" : "#6b7280"}
              />
              {/* <HiOutlineBookOpen size={20} /> */}
            </div>
          </div>
          How to get Cashback{" "}
        </span>

        <NavLink
          to={`/`}
          className={`text-base font-medium font-inter hover:text-primaryColor
        transition-all duration-500  cursor-pointer flex items-center gap-1 whitespace-pre `}
          style={activeNavLink}
        >
          <span>
            <AiOutlineFire size={20} />
          </span>
          <p>Cashbacks</p>
        </NavLink>

        {/* <NavLink
          to={"/app-download"}
          className=" text-base font-medium text-gray-500 font-inter hover:text-primaryColor
        transition-all duration-500  cursor-pointer flex items-center gap-1 whitespace-pre"
        >
          <span>
            <MdCloudDownload size={20} />
          </span>
          App Download{" "}
        </NavLink> */}

        {/* <NavLink
          to={
            isAuth || Cookies.get("ayykori")
              ? "/my-account/my-referrals"
              : "/login"
          }
          className={`text-base font-medium  font-inter hover:text-primaryColor
          transition-all duration-500  cursor-pointer flex items-center gap-1 whitespace-pre `}
          style={activeNavLink}
          onMouseOver={() => setRefferearn(true)}
          onMouseOut={() => setRefferearn(false)}
        >
          <span>
            <Lordicon
              link={"wxhtpnnk"}
              size={20}
              color={refferearn ? "#11998E" : "#6b7280"}
              trigger={"hover"}
              target={"a"}
              customStyle={{
                width: `20px`,
                height: `20px`,
                fontWeight: "600px",
                marginTop: "6px",
              }}
              className={`text-lg  ${
                open ? "text-primaryColor" : "text-gray-500"
              }`}
            />
           
          </span>
          Refer & Earn{" "}
        </NavLink> */}

        {/* <NavLink
          to={"/affiliate"}
          className={`text-base font-medium  font-inter hover:text-primaryColor
        transition-all duration-500  cursor-pointer flex items-center gap-1 whitespace-pre `}
          style={activeNavLink}
        >
          <span>
            <GiTakeMyMoney size={20} />
          </span>
          Affiliate{" "}
        </NavLink> */}

        {/* <div
          className="relative text-base font-medium  font-inter hover:text-primaryColor text-gray-500
        transition-all duration-500  cursor-pointer flex items-center gap-1 whitespace-pre"
          onClick={() => setStore(!store)}
        >
          <span>
            <Lordicon
              link={"hyhnpiza"}
              size={20}
              color={help ? "#11998E" : "#6b7280"}
              trigger={"hover"}
              target={"div"}
              customStyle={{
                width: `20px`,
                height: `20px`,
                fontWeight: "600px",
                marginTop: "6px",
              }}
              className={`text-lg  ${
                open ? "text-primaryColor" : "text-gray-500"
              }`}
            />

            
          </span>
          Store
          <RiArrowDownSLine
            className={`${store ? "rotate-180" : "rotate-0"}`}
          />
          
          <ShopCompo store={store} />
          
        </div> */}
        <div
          onMouseOver={() => setHelp(true)}
          onMouseOut={() => setHelp(false)}
          className="relative text-base font-medium  font-inter hover:text-primaryColor text-gray-500
        transition-all duration-500  cursor-pointer flex items-center gap-1 whitespace-pre"
        >
          <span>
            <Lordicon
              link={"hpivxauj"}
              size={20}
              color={help ? "#11998E" : "#6b7280"}
              trigger={"hover"}
              target={"div"}
              customStyle={{
                width: `20px`,
                height: `20px`,
                fontWeight: "600px",
                marginTop: "6px",
              }}
              className={`text-lg  ${
                open ? "text-primaryColor" : "text-gray-500"
              }`}
            />

            {/* <TbMessageCircle size={20} /> */}
          </span>
          Help{" "}
          <RiArrowDownSLine className={`${help ? "rotate-180" : "rotate-0"}`} />
          <ul
            className={`${
              help ? " absolute" : "hidden"
            }  top-6 right-1 w-44 z-[100] bg-white transition-all duration-500 shadow-[1px_1px_10px_rgba(0,0,0,0.2)] rounded-lg p-4 flex flex-col gap-y-6 justify-between text-sm text-gray-500`}
          >
            <li className="hover:text-primaryColor ">
              <NavLink to={"/customer-support"} style={activeNavLink}>
                Customer Support
              </NavLink>
            </li>
            <li className="hover:text-primaryColor ">
              <NavLink to={"/faqs"} style={activeNavLink}>
                FAQs
              </NavLink>
            </li>
            <li className="hover:text-primaryColor ">
              <NavLink to={"/contact"} style={activeNavLink}>
                Contact Us
              </NavLink>
            </li>
            {/* <li className="hover:text-primaryColor ">
              <NavLink to={"/how-to-use-ayykori"} style={activeNavLink}>
                How to use Ayykori
              </NavLink>
            </li> */}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default BottomNavbar;
