// cashback  banner  [desktop]

// cashback  banner  [mobile]

// earning banner [desktop]
import earning_banner2 from "../../src/Assets/images/banner/earning-banner/earning-banner-2.jpg";
import earning_banner3 from "../../src/Assets/images/banner/earning-banner/earning-banner-3.jpg";

// earning banner [mobile]
import earning_mobile_banner1 from "../../src/Assets/images/banner/earning-banner/mobile/earning-mobile-banner-1.jpg";
import earning_mobile_banner2 from "../../src/Assets/images/banner/earning-banner/mobile/earning-mobile-banner-2.jpg";

let version = Date.now();

export const shareAndEarnBannerData = [
  {
    image: `${earning_banner2}?${version}`,
  },
  {
    image: `${earning_banner3}?${version}`,
  },
  {
    image: `${earning_banner2}?${version}`,
  },
  {
    image: `${earning_banner3}?${version}`,
  },
];

export const shareAndEarnMobileBannerData = [
  {
    image: `${earning_mobile_banner1}?${version}`,
  },
  {
    image: `${earning_mobile_banner2}?${version}`,
  },

  {
    image: `${earning_mobile_banner1}?${version}`,
  },
  {
    image: `${earning_mobile_banner2}?${version}`,
  },
];
// Regular Banner [Laptop Version]
// export const afterLoginBannerData = [
//   {
//     image: `${big_banner}?${version}`,
//   },
//   {
//     image: `${all_brand_banner}?${version}`,
//   },

//   {
//     image: `${cashback_calculator_brand_banner}?${version}`,
//   },
//   {
//     image: `${big_banner}?${version}`,
//   },
//   {
//     image: `${all_brand_banner}?${version}`,
//   },

//   {
//     image: `${cashback_calculator_brand_banner}?${version}`,
//   },
// ];
export const afterLoginBannerData = [
  {
    image: `https://res.cloudinary.com/dxodgllun/image/upload/v1700896466/ayykori_management/homePageBanner/rnriusw2f6mfy1tq5erm.webp`,
  },
  {
    image: `https://res.cloudinary.com/dxodgllun/image/upload/v1700896500/ayykori_management/homePageBanner/em6pwrdexrhiryfarm8t.webp`,
  },

  {
    image: `https://res.cloudinary.com/dxodgllun/image/upload/v1700896535/ayykori_management/homePageBanner/uyyayjrdnjopavfmhb0p.webp`,
  },
  {
    image: `https://res.cloudinary.com/dxodgllun/image/upload/v1700896617/ayykori_management/homePageBanner/plsp5vrra1xwtm6u6yrd.webp`,
  },
  {
    image: `https://res.cloudinary.com/dxodgllun/image/upload/v1700896585/ayykori_management/homePageBanner/optgp4w4y7aoedls6uqe.webp`,
  },

  // {
  //   image: `${big_banner6}?${version}`,
  // },
  // {
  //   image: `${big_banner7}?${version}`,
  // },
  // {
  //   image: `${big_banner8}?${version}`,
  // },
  // {
  //   image: `${big_banner9}?${version}`,
  // },
  // {
  //   image: `${big_banner10}?${version}`,
  // },
  // {
  //   image: `${big_banner11}?${version}`,
  // },
  // {
  //   image: `${big_banner12}?${version}`,
  // },
  // {
  //   image: `${big_banner13}?${version}`,
  // },
];

// Regular Banner [Mobile Version]
export const afterLoginMobileBannerData = [
  {
    image: `https://res.cloudinary.com/dxodgllun/image/upload/v1700903101/ayykori_management/banner/hplcmccb4ndrfugw7zhb.jpg`,
  },

  {
    image: `https://res.cloudinary.com/dxodgllun/image/upload/v1700902945/ayykori_management/banner/krvrnqmbbkngkr9iktv1.jpg`,
  },
  {
    image: `https://res.cloudinary.com/dxodgllun/image/upload/v1700903185/ayykori_management/banner/wwqvbjbsokzq0hkdshww.jpg`,
  },
  {
    image: `https://res.cloudinary.com/dxodgllun/image/upload/v1700903237/ayykori_management/banner/dyxya3i0kgxhtpjgoqtc.jpg`,
  },
  {
    image: `https://res.cloudinary.com/dxodgllun/image/upload/v1700903259/ayykori_management/banner/kdupc9ybh8t8wbtfsihp.jpg`,
  },
  {
    image: `https://res.cloudinary.com/dxodgllun/image/upload/v1700903341/ayykori_management/banner/cwrc3uppvx9hryebev5x.jpg`,
  },
];
