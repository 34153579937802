import React, { useState } from "react";
import toast from "react-hot-toast";
import { BiChevronRight, BiLockOpenAlt, BiSolidError } from "react-icons/bi";
import { useDispatch } from "react-redux";
import { setErrorMsg } from "../../features/ErrorHelper/ErrorHelperSlice";
import { useSetNewPasswordMutation } from "../../features/auth/authApiSlice";
import { goback } from "../../features/backPrev/goBackSlice";
import { hideModal } from "../../features/loginModalShowSlice.js/loginModalShowSlice";

const ResetPassword = ({ phone, setSteps }) => {
  const dispatch = useDispatch();
  const [password, setPassword] = useState("");
  const [confPassword, setConfPassword] = useState("");
  const [passError, setPassError] = useState("");
  const [
    setNewPassword,
    {
      isError: isErrorsetNewPassword,
      isSuccess: isSuccesssetNewPassword,
      isLoading: isLoadinsetNewPassword,
      data: datasetNewPassword,
      error: errorsetNewPassword,
    },
  ] = useSetNewPasswordMutation() || {};
  const handleResetPassword = async (e) => {
    e.preventDefault();
    if (password !== confPassword) {
      setPassError("Password didn't match");
    } else if (password === confPassword) {
      const res = await setNewPassword({
        password: password,
        confirmPassword: confPassword,
        phone,
      });
      const { data, error } = res || {};

      if (res?.data?.data?.user && res?.data?.data?.access_token) {
        dispatch(
          setErrorMsg({
            errorMsg: data?.message || "Welcome On Board",
            isError: false,
          })
        );
        setSteps("OTPNotMatch");
        setTimeout(() => {
          dispatch(hideModal());
          toast.success("Login Successful!!");
        }, 800);
      } else if (error) {
        dispatch(
          setErrorMsg({
            errorMsg: error?.data?.message || "Please Try again!!",
            isError: true,
          })
        );
        dispatch(
          goback({
            prevComponent: "ResetPassword",
          })
        );
        setSteps("OTPNotMatch");
      }
    }
  };

  return (
    <div
      style={{ zIndex: 999 }}
      className="relative bg-white border lg:px-10 md:px-8 px-5 min-h-[400px] rounded-lg flex flex-col justify-center"
    >
      <h1 className="text-2xl font-bold text-gray-700 ">Reset your password</h1>

      <form onSubmit={handleResetPassword}>
        <div className="h-[140px] mt-6">
          <div className="mt-3 relative">
            <input
              id="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              type="password"
              placeholder="New password"
              name="password"
              required
              className="border border-gray-300 py-2 pl-8 pr-2 rounded-md focus:outline-none focus:border-primaryColor text-gray-700 w-full"
            />
            <div className="absolute top-1/2 -translate-y-1/2 left-2 flex items-center gap-2 text-gray-500">
              <BiLockOpenAlt />
            </div>
          </div>
          <div className="relative mt-3">
            <input
              id="password"
              value={confPassword}
              onChange={(e) => setConfPassword(e.target.value)}
              type="password"
              placeholder="Confirm password"
              required
              name="password"
              className="border border-gray-300 py-2 pl-8 pr-2 rounded-md focus:outline-none focus:border-primaryColor text-gray-700 w-full"
            />
            <div className="absolute top-1/2 -translate-y-1/2 left-2 flex items-center gap-2 text-gray-500">
              <BiLockOpenAlt />
            </div>
          </div>
          <div className="mt-3">
            {passError && (
              <h2 className="text-xs flex items-center gap-2 text-red-500">
                <BiSolidError />
                {passError}
              </h2>
            )}
          </div>
        </div>
        <button
          type="submit"
          className="w-fit bg-primaryColor pt-1 pb-1.5 px-5 text-white flex items-center gap-1 rounded-full group hover:opacity-80 transition duration-300"
        >
          Continue{" "}
          <BiChevronRight className="group-hover:translate-x-1 transition duration-300 mt-0.5" />
        </button>
      </form>
      <h2 className="text-sm mt-6">
        By clicking "Continue”, I agree to Ayykori's{" "}
        <a
          href="https://www.ayykori.net/"
          target="_blank"
          rel="noreferrer"
          className="text-blue-500"
        >
          Terms of Use
        </a>{" "}
        and{" "}
        <a
          href="https://www.ayykori.net/"
          target="_blank"
          rel="noreferrer"
          className="text-blue-500"
        >
          Privacy Policy
        </a>
      </h2>
    </div>
  );
};

export default ResetPassword;
