import { apiRequest } from "../apiRequest/apiRequest";

export const categoryApiSlice = apiRequest.injectEndpoints({
  endpoints: (builder) => ({
    getAllCategory: builder.query({
      query: (data) => {
        const { access_token, page, limit } = data;
        return {
          url: `/brand-category?page=${page}&limit=${limit}`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${access_token}`,
            "Content-Type": "application/json;charset=UTF-8",
          },
        };
      },
      invalidatesTags: [""],
    }),
    getAllSubCategoryByCategory: builder.query({
      query: (data) => {
        const { access_token, categoryname, limit } = data;
        return {
          url: `/brand-all-subcategory?category=${categoryname}&page=1&limit=${limit}`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${access_token}`,
            "Content-Type": "application/json;charset=UTF-8",
          },
        };
      },
      invalidatesTags: [""],
    }),
  }),
});

export const { useGetAllCategoryQuery, useGetAllSubCategoryByCategoryQuery } =
  categoryApiSlice;
