import React from "react";

const BrandCardSkeleton = () => {
  return (
    <div
      className={`group h-fit flex flex-col justify-center items-center md:items-start md:justify-between border  
    rounded-lg py-3 px-4 space-y-3   transition-all duration-500`}
    >
      <div className=" flex flex-col md:flex-row  gap-4 ">
        <div className="w-20 h-20 mx-auto flex rounded-md justify-center items-center bg-gray-200 animate-pulse"></div>
        <div className="flex flex-col justify-center md:justify-start items-center md:items-start space-y-1">
          <div className="w-24 h-4 text-lg whitespace-pre bg-gray-200 rounded-sm animate-pulse"></div>
          <div className="w-28 h-4 text-lg whitespace-pre bg-gray-200 rounded-sm animate-pulse"></div>
          <div className="w-32 h-4 text-lg whitespace-pre bg-gray-200 rounded-sm animate-pulse"></div>
        </div>
      </div>
      <div className="w-full h-8 rounded-md text-lg whitespace-pre bg-gray-200 animate-pulse"></div>
    </div>
  );
};

export default BrandCardSkeleton;
