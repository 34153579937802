// formResetSlice.js
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  manualOrderResetRef: null,
  formResetRef: null,
};

const formResetSlice = createSlice({
  name: "formReset",
  initialState,
  reducers: {
    setManualOrderResetRef: (state, action) => {
      state.manualOrderResetRef = action.payload;
    },
    setFormResetRef: (state, action) => {
      state.formResetRef = action.payload;
    },
  },
});

export const { setManualOrderResetRef, setFormResetRef } =
  formResetSlice.actions;
export default formResetSlice.reducer;
