import React, { useState } from "react";
import { MdArrowForwardIos } from "react-icons/md";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import Cookies from "js-cookie";
import { Autoplay } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import { useGetAllCategoryQuery } from "../../features/category/categoryApiSlice";
import CategoryCard from "../Card/CategoryCard";

const CategoriesPageSlider = () => {
  const access_token = Cookies.get("ayykori");
  const { data, isLoading } =
    useGetAllCategoryQuery({ access_token }, { refetchOnReconnect: true }) ||
    {};
  const [swiperRef, setSwiperRef] = useState(null);

  const prevHandler = () => {
    swiperRef.slidePrev();
  };

  const nextHandler = () => {
    swiperRef.slideNext();
  };

  return (
    <div className="w-[90%] mx-auto pb-4 md:py-4 relative">
      <div className="absolute -left-4 top-[50%] translate-y-[-50%] z-[100] text-primaryColor">
        <MdArrowForwardIos
          size={28}
          className="rotate-180 cursor-pointer"
          onClick={prevHandler}
        />
      </div>
      <Swiper
        slidesPerView={1}
        spaceBetween={10}
        loop={true}
        autoplay={{ delay: 5000 }}
        modules={[Autoplay]}
        onSwiper={(swiper) => setSwiperRef(swiper)}
        className="mySwiper p-2"
        breakpoints={{
          // when window width is >= 320px
          320: {
            slidesPerView: 2,
          },
          // when window width is >= 480px
          480: {
            slidesPerView: 2,
          },
          // when window width is >= 640px
          640: {
            slidesPerView: 3,
          },
          // when window width is >= 640px
          920: {
            slidesPerView: 5,
          },
        }}
      >
        <div className="">
          {data &&
            !isLoading &&
            data?.category?.map((item, i) => (
              <SwiperSlide key={i}>
                <CategoryCard data={item?.uniqueCategories} />
              </SwiperSlide>
            ))}
        </div>
      </Swiper>
      <div className="absolute -right-4 top-[50%] translate-y-[-50%] z-[100] text-primaryColor">
        <MdArrowForwardIos
          size={28}
          className="cursor-pointer"
          onClick={nextHandler}
        />
      </div>
    </div>
  );
};

export default CategoriesPageSlider;
