import Cookies from "js-cookie";
import React from "react";
import { useLocation } from "react-router-dom";
import { useGetBrandsByKeyValueQuery } from "../../features/brand/brandApiSlice";
import BrandCard from "../Card/BrandCard";
import CommonComponent from "../CommonComponent/CommonComponent";
import Error from "../Error/Error";
import NoDataFound from "../NodataFound/NoDataFound";
import BrandCardSkeleton from "../Skeleton/BrandCardSkeleton";
const HighestCommision = () => {
  const { pathname } = useLocation();
  const access_token = Cookies.get("ayykori");
  const { data, isLoading, isSuccess, isError } = useGetBrandsByKeyValueQuery(
    {
      access_token,
      key: "highest_commission",
    },
    { refetchOnReconnect: true }
  );

  let content = null;
  // handle loading case
  if (isLoading && !isSuccess) {
    content = (
      <>
        <div
          className=" w-full px-3 md:px-[55px] grid grid-cols-1 sm:grid-cols-2  md:grid-cols-3 lg:grid-cols-4  
        gap-y-6 gap-x-8"
        >
          {Array(8)
            .fill()
            ?.map((item, i) => (
              <BrandCardSkeleton key={i} />
            ))}
        </div>
      </>
    );
  }

  // handle success case
  if (!isLoading && isSuccess && !isError) {
    content = (
      <>
        <div
          className=" w-full px-3 md:px-[55px] grid grid-cols-2 sm:grid-cols-2  md:grid-cols-3 lg:grid-cols-4  
        gap-y-3 md:gap-y-6 gap-x-3 md:gap-x-8"
        >
          {data?.resData &&
            data?.resData?.map((item, i) => (
              <BrandCard
                key={i}
                data={{
                  id: item?.brandId,
                  brandUnid: item?.brandUnid,
                  name: item?.brandName,
                  brandCardimg: item?.brandimg,
                  brandHomepageLink: item?.brandHomepageLink,
                  brandType: item?.brandType,
                  userCommission:
                    pathname === "/affiliate"
                      ? item?.earningUserCommission
                      : item?.cashbackUserCommission,
                  borderShow: true,
                  subBrand: "No",
                  type: pathname === "/affiliate" ? "earning" : "cashback",
                }}
              />
            ))}
        </div>
      </>
    );
  }
  // handle error case
  if (!isLoading && isError) {
    content = (
      <>
        <Error />
      </>
    );
  }
  // handle emppty case
  if (!isLoading && isSuccess && data?.resData?.length === 0) {
    content = (
      <>
        <NoDataFound />
      </>
    );
  }
  const param = pathname?.replace(/\//g, "");

  return (
    <div className="my-10 md:my-16">
      <CommonComponent
        data={{
          title: "Highest Commission",
          link:
            pathname === "/affiliate"
              ? `/brands/highest_commission?share_and_earn_brands=${param}`
              : ` /brands/highest_commission`,
          linktitle: "See All",
          show: true,
        }}
      />
      {content}
    </div>
  );
};

export default HighestCommision;
