import React, { useEffect, useState } from "react";
import appimg from "../../Assets/images/logo/appimg.png";

const AppDownloadBanner = () => {
  /*For PWA Start - Please don't touch this code*/
  const [deferredPrompt, setDeferredPrompt] = useState(null);

  useEffect(() => {
    const handleBeforeInstallPrompt = (e) => {
      e.preventDefault();
      setDeferredPrompt(e);
    };

    window.addEventListener("beforeinstallprompt", handleBeforeInstallPrompt);

    return () => {
      window.removeEventListener(
        "beforeinstallprompt",
        handleBeforeInstallPrompt
      );
    };
  }, [window.location]);

  const handleInstall = () => {
    // if (deferredPrompt) {
    //   deferredPrompt.prompt();

    //   deferredPrompt.userChoice
    //     .then((choiceResult) => {
    //       if (choiceResult.outcome === "accepted") {
    //         console.log("User accepted the install prompt");
    //       } else {
    //         console.log("User dismissed the install prompt");
    //       }
    //     })
    //     .catch((error) => {
    //       console.error("Error occurred while handling install prompt:", error);
    //     })
    //     .finally(() => {
    //       setDeferredPrompt(null);
    //     });
    // }
    window.open(
      `https://play.google.com/store/apps/details?id=com.ayykoriapp`,
      "_blank",
      // "popup",
      "noreferrer"
    );
  };

  /*For PWA End - Please don't touch this code*/

  return (
    <div className=" my-5  bg-gradient-to-r from-[#67B26F]  to-[#4CA2CD] py-10">
      <div className="  w-full px-3 md:px-[55px] font-outfit">
        <h1 className=" text-3xl text-white font-medium pb-3 ">AyyKori App</h1>
        {/* <p className=" text-lg text-primaryColor pb-1">AyyKori.net App</p> */}
        <p className=" text-base text-gray-300 whitespace-pre">
          Only Android Version is Available
        </p>

        <div className="flex items-center gap-y-3 gap-x-1 md:gap-2  my-5 flex-wrap ">
          <div className="flex gap-2 items-center">
            <img src={appimg} alt="" />
            {/* <span className=" ml-1">
              <p className="text-sm md:text-base text-center text-gray-100 flex gap-1 items-center justify-center font-semibold">
                4.2{" "}
                <span className="text-yellow-400">
                  <FiStar />
                </span>
              </p>
              <p className="text-xs md:text-sm text-gray-100 whitespace-pre">
                50k Reviews
              </p>
            </span> */}
          </div>
          {/* <p className=" rotate-90 text-lightText text-3xl">
            <HiOutlineMinus />
          </p> */}

          <div>
            <span className="text-center">
              <p className="text-sm md:text-lg text-gray-100 flex gap-1 items-center justify-center text-center font-semibold ">
                50K+{" "}
              </p>
              <p className="text-xs md:text-sm text-gray-100 whitespace-pre">
                Downloads
              </p>
            </span>
          </div>
          {/* <p className=" rotate-90 text-lightText text-3xl">
            <HiOutlineMinus />
          </p> */}
          {/* <div>
            <span className="text-center">
              <p className="text-sm md:text-lg text-gray-100 flex gap-1 items-center justify-center text-center font-semibold ">
                8K+{" "}
              </p>
              <p className="text-xs md:text-sm text-gray-100 whitespace-pre">
                Monthly Active User
              </p>
            </span>
          </div> */}
        </div>
        <button
          onClick={handleInstall}
          className=" tracking-wide text-base py-2 px-8 text-primaryColor bg-white border rounded-md mt-3 md:mt-0 "
        >
          Install
        </button>
      </div>
    </div>
  );
};

export default AppDownloadBanner;
