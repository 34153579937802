import Cookies from "js-cookie";
import React from "react";
import { useLocation } from "react-router-dom";
import { useGetBrandsByKeyValueQuery } from "../../features/brand/brandApiSlice";
import BrandCard from "../Card/BrandCard";
import CommonComponent from "../CommonComponent/CommonComponent";
import Error from "../Error/Error";
import NoDataFound from "../NodataFound/NoDataFound";
import BrandCardSkeleton from "../Skeleton/BrandCardSkeleton";
const OnlyMainBrands = () => {
    const { pathname } = useLocation();
    const access_token = Cookies.get("ayykori");
    const { data, isLoading, isSuccess, isError } = useGetBrandsByKeyValueQuery(
        {
            access_token,
            limit: 200,
            key: "topnotch_cashbacks",
        },
        { refetchOnReconnect: true }
    );

    let content = null;
    // handle loading case
    if (isLoading && !isSuccess) {
        content = (
            <>
                <div className="w-full px-3 md:px-[55px] justify-center grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-y-3 md:gap-y-6 gap-x-3 md:gap-x-8">
                    {Array(8)
                        .fill()
                        ?.map((item, i) => (
                            <BrandCardSkeleton key={i} />
                        ))}
                </div>
            </>
        );
    }

    // handle success case
    if (!isLoading && isSuccess && !isError && data) {
        const sortOrder = [
            "ayykori.net",
            "othoba.com",
            "chaldal.com",
            "medeasy.health",
            "pickaboo.com",
            "jatri.co",
            "khaasfood.com",
            "greengrocery.com.bd",
            "organiconline.com.bd",
            "banglanatural.com",
            "ribana.com.bd",
            "beauti4me.com",
            "crocodilebd.com",
            "deencommerce.com",
            // Add other domain values in the desired order
        ];

        const sortedBrandsData = Array.isArray(data?.resData)
            ? [...data.resData].sort((a, b) => {
                  // Convert domains to lowercase for case-insensitive comparison
                  const indexA = sortOrder.indexOf(
                      a.brandDomain?.toLowerCase()
                  );
                  const indexB = sortOrder.indexOf(
                      b.brandDomain?.toLowerCase()
                  );

                  // Handle missing domains
                  if (indexA === -1 && indexB === -1) return 0; // Both unknown, maintain original order
                  if (indexA === -1) return 1; // Place unknown domains at the end
                  if (indexB === -1) return -1; // Place unknown domains at the end

                  return indexA - indexB;
              })
            : [];

        content = (
            <>
                <div className="w-full px-3 md:px-[55px] justify-center grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-y-3 md:gap-y-6 gap-x-3 md:gap-x-8">
                    {sortedBrandsData &&
                        sortedBrandsData?.map((item, i) => {
                            if (
                                // daraz sub brands
                                item?.brandName === "vision" ||
                                item?.brandName === "Dettol" ||
                                item?.brandName === "Minister" ||
                                item?.brandName === "Lotto" ||
                                item?.brandName === "Realme" ||
                                item?.brandName === "Marico" ||
                                item?.brandName === "Nestle" ||
                                item?.brandName === "ACI" ||
                                item?.brandName === "Haier" ||
                                item?.brandName === "Walton" ||
                                item?.brandName === "Unilever" ||
                                item?.brandName === "RFL Houseware" ||
                                item?.brandName === "Fresh" ||
                                item?.brandName === "Bata" ||
                                item?.brandName === "Ryans" ||
                                // othoba sub brands
                                item?.brandName === "Tasty Treat" ||
                                item?.brandName === "Vision" ||
                                item?.brandName === "Walker" ||
                                item?.brandName === "Mithai" ||
                                item?.brandName === "Regal" ||
                                item?.brandName === "Winner" ||
                                item?.brandName === "Samsung" ||
                                item?.brandName === "Xiaomi" ||
                                item?.brandName === "Fry Bucket" ||
                                item?.brandName === "Click"
                            ) {
                                return null;
                            } else
                                return (
                                    <BrandCard
                                        key={i}
                                        data={{
                                            id: item?.brandId,
                                            brandUnid: item?.brandUnid,
                                            name: item?.brandName,
                                            brandCardimg: item?.brandimg,
                                            brandHomepageLink:
                                                item?.brandHomepageLink,
                                            brandType: item?.brandType,
                                            userCommission:
                                                pathname === "/affiliate"
                                                    ? item?.earningUserCommission
                                                    : item?.cashbackUserCommission,
                                            borderShow: true,
                                            subBrand: "No",
                                            type:
                                                pathname === "/affiliate"
                                                    ? "earning"
                                                    : "cashback",
                                        }}
                                    />
                                );
                        })}
                </div>
            </>
        );
    }

    // handle error case
    if (!isLoading && isError) {
        content = (
            <>
                <Error />
            </>
        );
    }
    // handle empty case
    if (!isLoading && isSuccess && data?.resData?.length === 0) {
        content = (
            <>
                <NoDataFound />
            </>
        );
    }

    return (
        <div className=" my-10 md:my-16">
            <CommonComponent
                data={{
                    title: "Winter Exclusive",
                    end: "Ends in:  00:00:00",
                    link: "/brands/topnotch_cashbacks",
                    linktitle: "",
                    show: false,
                }}
            />
            {content}
        </div>
    );
};

export default OnlyMainBrands;
