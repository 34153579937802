import moment from "moment";
import React from "react";
import { AiOutlineCalendar } from "react-icons/ai";
import { useUpdateBlogViewCountMutation } from "../../features/blog/blogApiSlice";

const SidebarCard = ({ data }) => {
  const [updateBlogViewCount] = useUpdateBlogViewCountMutation() || {};
  return (
    <a
      onClick={() => {
        updateBlogViewCount({ id: data?._id });
      }}
      href={data?.blogLink}
      target="_blank"
      rel="noreferrer"
    >
      <div className="flex cursor-pointer rounded-md gap-x-2 bg-white shadow-sm">
        <div className="w-[30%]">
          <img
            className="w-full h-full object-contain rounded-l-md"
            src={data?.blogImg}
            alt=""
          />
        </div>
        <div className="w-[70%] p-2 flex flex-col justify-between">
          <a href={data?.blogLink} target="_blank" rel="noreferrer">
            <h1 className="text-xs font-medium font-outfit text-gray-700 hover:text-primaryColor">
              {data?.blogTitle}
            </h1>
          </a>
          <p className=" flex text-[12px] items-center gap-1 text-gray-400">
            <span className="text-xl">
              <AiOutlineCalendar />
            </span>
            {moment(data?.createdAt).format("ll")}
          </p>
        </div>
      </div>
    </a>
  );
};

export default SidebarCard;
