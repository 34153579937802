// formResetSlice.js
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  mkactivityId: null,
};

const mkActivityIdSlice = createSlice({
  name: "mkActivityId",
  initialState,
  reducers: {
    setMkActivityId: (state, action) => {
      state.mkactivityId = action.payload;
    },
    // setFormResetRef: (state, action) => {
    //   state.formResetRef = action.payload;
    // },
  },
});

export const { setMkActivityId } = mkActivityIdSlice.actions;
export default mkActivityIdSlice.reducer;
