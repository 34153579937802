import React from "react";
import { AiOutlineExclamationCircle } from "react-icons/ai";
import { GiMoneyStack } from "react-icons/gi";
import { IoMdTime } from "react-icons/io";
import { cashbackStepperData } from "../../Data/brandCashbackStepper";
import CampaignDescription from "../Campaign/CampaignDescription";
import BrandPageSteper from "./BrandPageSteper";

const BrandPageRightPanel = ({ type, response, campaignData }) => {
  const urlRegex =
    /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/;
  const { data } = response;
  const brandDetails = data?.resData[0];

  const filteredStepperData = cashbackStepperData.filter(
    (item) => item.brandDomain === brandDetails?.brandDomain
  );

  return (
    <div className="basis-full md:basis-[65%] border rounded-lg p-3 md:p-8 text-xs md:text-base">
      <div className="w-full md:w-[90%] lg:w-[80%] md:ml-2 mt-3 md:mt-0">
        <BrandPageSteper type={type} response={response} />
      </div>

      {campaignData ? (
        <CampaignDescription campaignData={campaignData} />
      ) : (
        <div className="mt-6">
          <div className="flex items-center py-2 px-2 gap-x-2 text-gray-500 border-b">
            <GiMoneyStack /> Max:{" "}
            {brandDetails?.cashbackLimit !== "No Limit" ? (
              <>{brandDetails?.cashbackLimit} &#2547;</>
            ) : (
              "No Limit"
            )}
          </div>
          <div className="flex items-center py-2 px-2 gap-x-2 text-gray-500 border-b">
            <IoMdTime /> Avg. {type === "earning" ? "Affiliate" : "Cashback"}{" "}
            time: {brandDetails?.cashbackReturnTime}
          </div>
          <div className=" flex items-start py-2 px-2 gap-x-2 text-gray-500">
            <AiOutlineExclamationCircle className="my-1" />
            <ul>
              {brandDetails?.applicableMethods
                ?.split(";")
                ?.map((item, i) =>
                  urlRegex.test(item?.trim()) ? "" : <li key={i}>- {item}</li>
                )}
              {urlRegex.test(
                brandDetails?.applicableMethods
                  ?.split(";")
                  [
                    brandDetails?.applicableMethods?.split(";")?.length - 1
                  ]?.trim()
              ) && (
                <a
                  className=" text-blue-500"
                  href={
                    brandDetails?.applicableMethods?.split(";")[
                      brandDetails?.applicableMethods?.split(";")?.length - 1
                    ]
                  }
                  target="_blank"
                  rel="noreferrer"
                >
                  - <span className="text-sm hover:underline">Learn More</span>
                </a>
              )}
            </ul>
          </div>

          <div className="flex flex-col  py-2 px-2 gap-x-2 text-gray-500 border-b">
            <p>- Steps to Avail Cashback</p>

            <ul className="ml-4">
              {filteredStepperData[0]?.steps?.map((item, i) => (
                <li key={i}> {item?.step}</li>
              ))}
            </ul>
          </div>
        </div>
      )}
    </div>
  );
};

export default BrandPageRightPanel;
