import Cookies from "js-cookie";
import { storeToken } from "../../components/Utility/LocalStorage";
import { apiRequest } from "../apiRequest/apiRequest";
import { prevSendData, user, userPhone, validTime } from "./authHelperSlice";
import { userLoggedIn } from "./authUserSlice";

export const authApiSlice = apiRequest.injectEndpoints({
  endpoints: (builder) => ({
    createUser: builder.mutation({
      query: (newData) => ({
        url: "/user-registation",
        method: "POST",
        body: newData,
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
        },
      }),
      invalidatesTags: ["Auth"],
    }),
    loginUser: builder.mutation({
      query: (newData) => ({
        url: "/login",
        method: "POST",
        body: newData,
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
        },
      }),
      async onQueryStarted(arg, { queryFulfilled, dispatch }) {
        try {
          const result = await queryFulfilled;

          Cookies.set("ayykori", result.data.access_token, { expires: 2 });
          storeToken(result.data.access_token);
          dispatch(
            userLoggedIn({
              access_token: result.data.access_token,
              user: result.data.user,
            })
          );
        } catch (error) {
          // do nothing
        }
      },
      invalidatesTags: ["Auth"],
    }),
    loggedInUser: builder.query({
      query: (data) => {
        const { access_token } = data || {};
        return {
          url: `/myprofile`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${access_token}`,
            "Content-Type": "application/json;charset=UTF-8",
          },
        };
      },
      invalidatesTags: ["Auth"],
    }),
    forgetPassword: builder.mutation({
      query: (newData) => ({
        url: "/forget-password",
        method: "POST",
        body: newData,
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
        },
      }),
      invalidatesTags: [""],
    }),
    resetPassword: builder.mutation({
      query: (data) => {
        const { token, bodyData } = data;
        return {
          url: `/reset-password/${token}`,
          method: "POST",
          body: bodyData,
          headers: {
            "Content-Type": "application/json;charset=UTF-8",
          },
        };
      },
    }),
    isExistUserUsingPhone: builder.mutation({
      query: (data) => {
        return {
          url: `/user/exist-user`,
          method: "POST",
          body: data,
          headers: {
            "Content-Type": "application/json;charset=UTF-8",
          },
        };
      },
      async onQueryStarted(arg, { queryFulfilled, dispatch }) {
        try {
          const result = await queryFulfilled;
          dispatch(
            userPhone({
              phone: result?.data?.phone,
            })
          );
          dispatch(
            user({
              user: result?.data?.result,
            })
          );
          dispatch(
            prevSendData({
              prevSend: result?.data?.prevSend,
            })
          );
          dispatch(
            validTime({
              time: result?.data?.time,
            })
          );
        } catch (error) {
          // do nothing
        }
      },
    }),
    otpLoginUser: builder.mutation({
      query: (newData) => ({
        url: "/user/login-user",
        method: "POST",
        body: newData,
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
        },
      }),
      async onQueryStarted(arg, { queryFulfilled, dispatch }) {
        try {
          const result = await queryFulfilled;
          const { user, access_token } = result?.data?.data;
          Cookies.set("ayykori", access_token, {
            expires: 100,
          });
          storeToken(access_token);
          dispatch(
            userLoggedIn({
              access_token: access_token,
              user: user,
            })
          );
        } catch (error) {
          // do nothing
        }
      },
      invalidatesTags: ["Auth"],
    }),
    otpUserCreateAndUpdate: builder.mutation({
      query: (newData) => ({
        url: "/user/user-create-or-update",
        method: "POST",
        body: newData,
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
        },
      }),
      async onQueryStarted(arg, { queryFulfilled, dispatch }) {
        try {
          const result = await queryFulfilled;

          const { user, access_token } = result?.data?.data;
          Cookies.set("ayykori", access_token, {
            expires: 100,
          });
          storeToken(access_token);
          dispatch(
            userLoggedIn({
              access_token: access_token,
              user: user,
            })
          );
        } catch (error) {
          // do nothing
        }
      },
      invalidatesTags: ["Auth"],
    }),
    resetPasswordOtpSend: builder.mutation({
      query: (newData) => ({
        url: "/user/reset-credentials",
        method: "POST",
        body: newData,
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
        },
      }),
      async onQueryStarted(arg, { queryFulfilled, dispatch }) {
        try {
          const result = await queryFulfilled;
          dispatch(
            userPhone({
              phone: result?.data?.phone,
            })
          );
          dispatch(
            user({
              user: result?.data?.result,
            })
          );
          dispatch(
            prevSendData({
              prevSend: result?.data?.prevSend,
            })
          );
          dispatch(
            validTime({
              time: result?.data?.time,
            })
          );
        } catch (error) {
          // do nothing
        }
      },
      invalidatesTags: ["Auth"],
    }),
    setNewPassword: builder.mutation({
      query: (newData) => ({
        url: "/user/reset-user-credentials",
        method: "POST",
        body: newData,
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
        },
      }),
      async onQueryStarted(arg, { queryFulfilled, dispatch }) {
        try {
          const result = await queryFulfilled;
          const { user, access_token } = result?.data?.data;
          Cookies.set("ayykori", access_token, {
            expires: 100,
          });
          storeToken(access_token);
          dispatch(
            userLoggedIn({
              access_token: access_token,
              user: user,
            })
          );
        } catch (error) {
          // do nothing
        }
      },
      invalidatesTags: ["Auth"],
    }),
    resetPasswordOtpVarification: builder.mutation({
      query: (newData) => ({
        url: "/user/reset-otp-varification",
        method: "POST",
        body: newData,
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
        },
      }),
      invalidatesTags: ["Auth"],
    }),
  }),
});

export const {
  useCreateUserMutation,
  useLoginUserMutation,
  useLoggedInUserQuery,
  useForgetPasswordMutation,
  useResetPasswordMutation,
  useIsExistUserUsingPhoneMutation,
  useOtpLoginUserMutation,
  useOtpUserCreateAndUpdateMutation,
  useResetPasswordOtpSendMutation,
  useResetPasswordOtpVarificationMutation,
  useSetNewPasswordMutation,
} = authApiSlice;
