import Cookies from "js-cookie";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useGetAllCategoryQuery } from "../../features/category/categoryApiSlice";

const CategoryNavbar = () => {
  const access_token = Cookies.get("ayykori");
  const [category, setCategory] = useState([]);
  const { data, isLoading, isSuccess, isError } =
    useGetAllCategoryQuery({ access_token }, { refetchOnReconnect: true }) ||
    {};

  let content = null;

  if (isLoading && !isSuccess) {
    Array(14)
      .fill()
      ?.map((item, i) => (
        <div
          key={i}
          role="status"
          className="transition-all duration-500 ease-in-out group  my-1 w-full h-6 space-y-4 divide-y divide-gray-200 rounded shadow animate-pulse  bg-gray-200 py-2"
        ></div>
      ));
  }
  if (!isLoading && isSuccess && !isError) {
    const myArray = [
      "online tickets",
      "mother & baby",
      "medicine",
      "life style",
      "legal service platform",
      "home appliances",
      "health and beauty",
      "food and groceries",
      "education",
      "electronics & gadgets",
    ];

    const filteredCategories = data?.category?.filter((category) =>
      myArray.includes(category._id)
    );

    content = (
      <>
        {filteredCategories &&
          isSuccess &&
          filteredCategories?.map((item, i) => (
            <li
              className=" hover:shadow-md w-full  border rounded-md  transition-all duration-500 text-gray-600 ease-in-out group my-1 hover:border-[#b1e2cb] py-2"
              key={i}
            >
              <Link
                className=" w-full flex items-center  "
                to={`/brands/category/${item?.uniqueCategories?.categoryName
                  ?.toLowerCase()
                  .replace(/\s+/g, "-")}`}
              >
                <img
                  className=" w-8 p-[5px] object-cover "
                  src={item?.uniqueCategories?.categoryIcon}
                  alt=""
                />
                <h1 className=" text-xs whitespace-pre  font-medium hover:text-primaryColor capitalize ">
                  {" "}
                  {item?.uniqueCategories?.categoryName}
                </h1>
              </Link>
            </li>
          ))}
      </>
    );
  }

  // console.log(data);
  return (
    <ul className="w-full px-3 mx-auto  grid grid-cols-2 gap-y-1 gap-x-2 items-center justify-between py-1 ">
      {content}
    </ul>
  );
};

export default CategoryNavbar;
