import { FaCircleArrowLeft } from "react-icons/fa6";
import { useSelector } from "react-redux";

const BackButton = ({ setSteps }) => {
  const prevComponent = useSelector(
    (state) => state?.goPrevNextSlice?.prevComponent
  );

  return (
    <div
      onClick={() => setSteps(prevComponent)}
      className="absolute top-4 left-10 flex items-center gap-2 text-gray-600 hover:text-gray-800 transition duration-300 cursor-pointer"
    >
      <FaCircleArrowLeft className="text-2xl" />
      Go Back
    </div>
  );
};
export default BackButton;
