import React, { useState } from "react";
import toast from "react-hot-toast";
import { BiChevronRight, BiLockOpenAlt, BiUser } from "react-icons/bi";
import { HiOutlineMail } from "react-icons/hi";
import { useDispatch } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { useSearchParams } from "react-router-dom";
import { BeatLoader } from "react-spinners";
import { setErrorMsg } from "../../features/ErrorHelper/ErrorHelperSlice";
import { useOtpUserCreateAndUpdateMutation } from "../../features/auth/authApiSlice";
import { goback } from "../../features/backPrev/goBackSlice";
import { useCreateDeviceTrackingMutation } from "../../features/deviceEventLog/trackDevicesApiSlice";
import { hideModal } from "../../features/loginModalShowSlice.js/loginModalShowSlice";
import { useCreateReferralMutation } from "../../features/referral/referralApiSlice";
import { getTokenUsingKey } from "../Utility/LocalStorage";

const CreateOrUpdateUser = ({ phone, user, setSteps }) => {
  const isMobile = useMediaQuery({ query: "(max-width: 767px)" });

  const [query] = useSearchParams();

  const refCode = query?.get("r_id");
  const dispatch = useDispatch();
  // console.log(user);
  const [userInfo, setUserInfo] = useState({
    fullname: "",
    email: "",
    password: "",
  });
  const [
    otpUserCreateAndUpdate,
    {
      isError: isErrorUserCreateAndUpdate,
      isSuccess: isSuccessUserCreateAndUpdate,
      isLoading: isLoadingUserCreateAndUpdate,
      data: dataUserCreateAndUpdate,
      error: errorUserCreateAndUpdate,
    },
  ] = useOtpUserCreateAndUpdateMutation() || {};

  // reffer create
  const [createReferral] = useCreateReferralMutation() || {};
  // ? devices tracking

  const [
    createDeviceTracking,
    {
      isError: isErrorTracking,
      isSuccess: isSuccessTracking,
      isLoading: isLoadingTracking,
      data: dataUserTracking,
      error: errorTracking,
    },
  ] = useCreateDeviceTrackingMutation() || {};

  const handleUserDetails = async (e) => {
    e.preventDefault();
    const fullname = user?.fullname ? user?.fullname : userInfo?.fullname;
    const email = user?.email ? user?.email : userInfo?.email;
    const password = userInfo?.password;
    const ref_code = getTokenUsingKey("r_");
    let payload = {
      fullname,
      email,
      password,
      phone: phone,
      userId: user?._id ? user?._id : undefined,
      device: localStorage.getItem("app") ? "app" : undefined,
    };
    if (ref_code) {
      payload.ref_code = ref_code;
    }
    if (fullname && email && password) {
      const res = await otpUserCreateAndUpdate(payload);

      const { data, error } = res || {};

      if (data) {
        dispatch(
          setErrorMsg({
            errorMsg: data?.message || "Welcome On Board",
            isError: false,
          })
        );

        setSteps("OTPNotMatch");
        setTimeout(() => {
          dispatch(hideModal());
          toast.success("Login Successful!!");
        }, 800);

        // ? device tracking
        let devicePayload = {
          userEmail: email,
          eventName: "Signup",
          collectionName: "users",
          collectionID: data?.data?.user?._id,
        };
        if (isMobile) {
          await createDeviceTracking(devicePayload);
        }
        // reffer create
        if (data?.data?.user?._id && refCode) {
          await createReferral({
            _id: data?.data?.user?._id,
            reffercode: refCode,
          });
        } else if (
          data?.data?.user?._id &&
          localStorage?.getItem("referral_code")
        ) {
          await createReferral({
            _id: data?.data?.user?._id,
            reffercode: localStorage?.getItem("referral_code"),
          });
        }
        localStorage.removeItem("referral_code");
      } else if (error) {
        dispatch(
          setErrorMsg({
            errorMsg: error?.data?.message || "Please Try again!!",
            isError: true,
          })
        );
        dispatch(
          goback({
            prevComponent: "CreateOrUpdateUser",
          })
        );

        setSteps("OTPNotMatch");
      }
    }
  };

  return (
    <div
      style={{ zIndex: 999 }}
      className="relative bg-white border lg:px-10 md:px-8 px-5 min-h-[400px] rounded-lg flex flex-col justify-center"
    >
      <h1 className="text-3xl font-bold text-gray-700 ">Final step</h1>
      <h3 className="text-sm text-gray-600">
        If you have previous account in ayykori, don't worry about openning new
        account
      </h3>
      <form onSubmit={handleUserDetails}>
        <div className="h-[170px] mt-3">
          <div className="relative">
            <input
              id="fullname"
              name="fullname"
              type="text"
              placeholder="Your fullname"
              value={user?.fullname ? user?.fullname : userInfo?.fullname}
              readOnly={user?.fullname ? true : false}
              onChange={(e) => {
                setUserInfo({
                  ...userInfo,
                  fullname: e.target.value,
                });
              }}
              required
              className="border border-gray-300 py-2 pl-8 pr-2 rounded-md focus:outline-none focus:border-primaryColor text-gray-700 w-full"
            />
            <div className="absolute top-1/2 -translate-y-1/2 left-2 flex items-center gap-2 text-gray-500">
              <BiUser />
            </div>
          </div>
          <div className="mt-3 relative">
            <input
              id="email"
              type="email"
              placeholder="Your Email"
              name="email"
              value={user?.email ? user?.email : userInfo?.email}
              readOnly={user?.email ? true : false}
              onChange={(e) => {
                setUserInfo({
                  ...userInfo,
                  email: e.target.value,
                });
              }}
              required
              className="border border-gray-300 py-2 pl-8 pr-2 rounded-md focus:outline-none focus:border-primaryColor text-gray-700 w-full"
            />
            <div className="absolute top-1/2 -translate-y-1/2 left-2 flex items-center gap-2 text-gray-500">
              <HiOutlineMail />
            </div>
          </div>
          <div className="relative mt-3">
            <input
              id="password"
              type="password"
              placeholder="Password"
              onChange={(e) => {
                setUserInfo({
                  ...userInfo,
                  password: e.target.value,
                });
              }}
              required
              name="password"
              className="border border-gray-300 py-2 pl-8 pr-2 rounded-md focus:outline-none focus:border-primaryColor text-gray-700 w-full"
            />
            <div className="absolute top-1/2 -translate-y-1/2 left-2 flex items-center gap-2 text-gray-500">
              <BiLockOpenAlt />
            </div>
          </div>
        </div>
        {isLoadingUserCreateAndUpdate ? (
          <button
            type="submit"
            className="w-fit bg-primaryColor pt-1 pb-1.5 px-5 text-white flex items-center gap-1 rounded-full group hover:opacity-80 transition duration-300 cursor-not-allowed"
            disabled
          >
            Continue <BeatLoader color="#fff" size={8} />{" "}
            <BiChevronRight className="group-hover:translate-x-1 transition duration-300 mt-0.5" />
          </button>
        ) : (
          <button
            type="submit"
            className="w-fit bg-primaryColor pt-1 pb-1.5 px-5 text-white flex items-center gap-1 rounded-full group hover:opacity-80 transition duration-300"
          >
            Continue{" "}
            <BiChevronRight className="group-hover:translate-x-1 transition duration-300 mt-0.5" />
          </button>
        )}
      </form>
      <h2 className="text-sm mt-3">
        By clicking "Continue”, I agree to Ayykori's{" "}
        <a
          href="https://www.ayykori.net/"
          target="_blank"
          className="text-blue-500"
          rel="noreferrer"
        >
          Terms of Use
        </a>{" "}
        and{" "}
        <a
          href="https://www.ayykori.net/"
          target="_blank"
          className="text-blue-500"
          rel="noreferrer"
        >
          Privacy Policy
        </a>
      </h2>
    </div>
  );
};

export default CreateOrUpdateUser;
