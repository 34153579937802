export const activitionDescription = [
    {
        title: "Why will you do the Affiliate Marketing Masterclass?",
        subTitle: "",
        list: [
            {
                listData: "3 Dedicated insightful Online Classes",
            },
            {
                listData: " Training From Affiliate Expert",
            },
            { listData: "Real Time Market Exposure" },
            {
                listData: " Corporate experience",
            },
            {
                listData: " Earning Opportunity from home",
            },
            {
                listData: "  Learning Sales tactics to Increase Earning",
            },
        ],
    },
];

export const affiliateAmbassadorData = [
    {
        id: 1,
        testimonials: true,
        title: "Affiliate Marketing MasterClass Program",
        titleSlug: "affiliate-marketing-masterClass-program",
        banner: "https://images.ayykori.net/api/v1/images/get/activation/affiliate-marketing-website-gif.gif",
        about: activitionDescription,
        youtubeVideo:
            "https://www.youtube.com/embed/AKGxAyNB9QA?si=8_BJrehjfB-adSeR",
        partnerShip: [
            {
                _id: 1,

                brandimg:
                    "https://images.ayykori.net/api/v1/images/get/partner/1.webp",
            },
            {
                _id: 2,

                brandimg:
                    "https://images.ayykori.net/api/v1/images/get/partner/2.webp",
            },
            {
                _id: 3,

                brandimg:
                    "https://images.ayykori.net/api/v1/images/get/partner/3.webp",
            },
            {
                _id: 4,

                brandimg:
                    "https://images.ayykori.net/api/v1/images/get/partner/4.webp",
            },
            {
                _id: 5,

                brandimg:
                    "https://images.ayykori.net/api/v1/images/get/partner/5.webp",
            },
            {
                _id: 6,

                brandimg:
                    "https://images.ayykori.net/api/v1/images/get/partner/6.webp",
            },
            {
                _id: 7,

                brandimg:
                    "https://images.ayykori.net/api/v1/images/get/partner/8.webp",
            },
            {
                _id: 8,

                brandimg:
                    "https://images.ayykori.net/api/v1/images/get/partner/7.webp",
            },
        ],
        affiliateApplyLink: "https://forms.gle/ApTSKNw3cUBB9wmp7",

        downloadCarriculam:
            "https://docs.google.com/spreadsheets/d/1G8ZYEV5UrdJeC7ciz7JJLeXJJQFz2Chfw9P0DIHSjQ8/edit?usp=sharing",

        description: {
            title: "Affiliate Marketing Masterclass Program Details",
            subTitle:
                "Are you looking for a reliable way to earn extra income without investment? ",
            // list: [
            //   {
            //     listData:
            //       "Looking for a reliable way to earn extra income without any investment? if, yes then AyyKori Affiliate Program is here for you – your ticket to earning money online from your home. With our program, you can turn your spare time into a great source of income by simply sharing our affiliate link and promoting top-notch products from authentic brands.",
            //   },
            //   {
            //     listData:
            //       "Working as an AyyKori Affiliate gives you the freedom to choose your own hours, making it a flexible job that fits around your schedule. Whether you're a stay-at-home, parent, a student, a specially abled, or simply looking to grow your income, our program offers a convenient work opportunity that allows you to earn money on your terms",
            //   },
            //   {
            //     listData:
            //       "Our commission structure ensures that you earn a commission for every sale made through your affiliate link, guaranteeing you a steady stream of income with each successful transaction.",
            //   },
            //   {
            //     listData:
            //       " But that's not all. When you join our program, you'll receive top-notch training from affiliate marketing experts. With 6 dedicated insightful classes available both online and offline, 1st class and last class will take place at our office premises physically and the rest of the classes will be conducted Online ( google meet). Snacks will be provided during physical classes. Each class duration will be 45 minutes. 1st batch starts from 8th/10th March (Probable time) and ends within the first week of April with a total duration of 1 month. You can choose classes according to your flexibility either on weekdays or on weekends. You'll gain invaluable knowledge about sales tactics and strategies to maximize your earning potential. Class Contents will be provided to you in the first class. Registration fees for the total tenure will be 500 BDT only. Registration fees will be received from you in the first class.           ",
            //   },
            //   {
            //     listData:
            //       "  Moreover, you'll get real-time exposure to the market and benefit from corporate experiences, providing you with a comprehensive understanding of the industry landscape. Our program also offers guidance from previous top affiliates, giving you access to insider tips and tricks to accelerate your success.     ",
            //   },
            //   {
            //     listData:
            //       "  As you progress through the program, you'll have the opportunity to earn professional work certificates and exciting goodies as incentives for your smart work and dedication.   ",
            //   },
            //   {
            //     listData:
            //       " Don't let this opportunity slip through your fingers. Apply Now for the AyyKori Affiliate Program today and start your journey towards financial freedom without any investment .   ",
            //   },
            // ],
            paragraph:
                "If yes, then the AyyKori Affiliate Marketing Masterclass Program is here for you—your ticket to earning money online from the comfort of your home. With our program, you can turn your spare time into a great source of income by simply sharing our affiliate link and promoting top-quality products from authentic brands. As an AyyKori Affiliate, you have the freedom to choose your own hours, making it a flexible opportunity that fits around your schedule. Whether you're a stay-at-home parent, a student, a person with disabilities, or simply looking to grow your income, our program offers a convenient way to earn money on your terms. Our commission structure ensures you receive a commission for every sale made through your affiliate link, providing you with a steady stream of income for each successful transaction. But that’s not all. When you join our program, you'll receive top-notch training from affiliate marketing experts. We offer 3 insightful online classes conducted via Google Meet. Each class lasts 2.5 hours, and the new batch is expected to start on September 20th, 2024. The course will be completed within 3 weeks, with classes held every Friday. The class materials will be provided in the final session.The registration fee for the entire course is only 300 BDT, payable before the first class. Additionally, you'll gain real-time market exposure and benefit from corporate experiences, giving you a comprehensive understanding of the industry landscape. As you progress through the program, you’ll also have the opportunity to earn professional certificates.Don’t let this opportunity slip through your fingers. Apply now for the AyyKori Affiliate Marketing Masterclass Program and start your journey towards financial freedom without investment.",
        },
    },
    {
        id: 2,
        testimonials: false,
        title: "Campus Ambassador",
        titleSlug: "campus-ambassador",
        banner: "https://images.ayykori.net/api/v1/images/get/activation/campus-ambassador-program-gif.gif",
        about: [
            {
                title: "AyyKori Campus Ambassador Benefits: ",
                subTitle: "",
                list: [
                    {
                        listData:
                            "Earning Opportunity at Flexible Timing            ",
                    },
                    {
                        listData:
                            " Professional work experience certificate             ",
                    },
                    { listData: "Goodies & ID Cards" },
                    {
                        listData: " Expert Guideline & Career Growth  ",
                    },
                    {
                        listData: "  Performance based Incentive",
                    },
                    {
                        listData: " Field Work & Corporate Experience ",
                    },
                    {
                        listData: "Networking Opportunity             ",
                    },
                    {
                        listData:
                            " Internship & Full time job opportunity in AyyKori in Future",
                    },
                ],
            },
        ],
        youtubeVideo: "https://www.youtube.com/watch?v=deu3pJs2HA4",
        partnerShip: [
            {
                _id: 1,

                brandimg:
                    "https://images.ayykori.net/api/v1/images/get/partner/1.webp",
            },
            {
                _id: 2,

                brandimg:
                    "https://images.ayykori.net/api/v1/images/get/partner/2.webp",
            },
            {
                _id: 3,

                brandimg:
                    "https://images.ayykori.net/api/v1/images/get/partner/3.webp",
            },
            {
                _id: 4,

                brandimg:
                    "https://images.ayykori.net/api/v1/images/get/partner/4.webp",
            },
            {
                _id: 5,

                brandimg:
                    "https://images.ayykori.net/api/v1/images/get/partner/5.webp",
            },
            {
                _id: 6,

                brandimg:
                    "https://images.ayykori.net/api/v1/images/get/partner/6.webp",
            },
            {
                _id: 7,

                brandimg:
                    "https://images.ayykori.net/api/v1/images/get/partner/8.webp",
            },
            {
                _id: 8,

                brandimg:
                    "https://images.ayykori.net/api/v1/images/get/partner/7.webp",
            },
        ],
        affiliateApplyLink:
            "https://docs.google.com/forms/d/e/1FAIpQLSde6pl6U_LnvaFVZTa8bCpHE1oaPBnDIi1xnHWu3BuzRifQxw/viewform",

        downloadCarriculam:
            "https://drive.google.com/drive/folders/1mU4WN5D6Yu2FpHF-WmHXgvNxIJSAJsmq?usp=drive_link",
        description: {
            title: "AyyKori Campus Ambassador Program Details      ",
            subTitle:
                "Join the Future, Shape the Future: Are you passionate about making an impact, networking with professionals, and gaining valuable work experience while still in college / University? Welcome to the AyyKori Campus Ambassador Program, where we empower dynamic individuals like you to become the face of our organization on your campus.Becoming a Campus Ambassador of AyyKori offers numerous benefits for students who are looking to grow their professional skills and gain valuable experiences. As a Campus Ambassador, individuals have the opportunity to represent their university or college and act as a connection between the institution (College/University) and AyyKori. This role not only provides students with a platform to develop their leadership and communication skills, but it also offers a chance to network with professionals in their field of interest. By becoming Ayykori Campus Ambassador, students can build their college experience and prepare themselves for the challenges of the professional world. At AyyKori, we believe in supporting talent and providing opportunities for growth. Our Campus Ambassador Program offers a bunch of benefits tailored to suit your academic schedule and career aspirations:      ",
            list: [
                {
                    listData:
                        "Earning Opportunity: As a Campus Ambassador, you'll have the chance to earn attractive incentives through various engagement activities.",
                },
                {
                    listData:
                        "Professional Work Experience Certificate: Enhance your resume with a valuable work experience certificate from AyyKori, recognized and respected by industry professionals.",
                },
                {
                    listData:
                        "Goodies & ID Cards: Enjoy exclusive AyyKori merchandise and official identification, showcasing your affiliation with a reputable organization.",
                },
                {
                    listData:
                        "Performance-Based Incentives: Your hard work doesn't go unnoticed! We offer performance-based incentives to reward your dedication and achievements.",
                },
                {
                    listData:
                        "Expert Guideline & Career Growth: Gain access to mentorship from industry experts, who will provide invaluable guidance to help shape your career path.",
                },

                {
                    listData:
                        "Field Work & Corporate Experience: Immerse yourself in a corporate environment and gain firsthand experience in marketing, communications, and offline activations.",
                },

                {
                    listData:
                        "Exclusive Exposure: Expand your network and build connections with professionals in your field through exclusive events and opportunities. You will be given exclusive exposure from Ayykori.",
                },

                {
                    listData:
                        "Internship and Job Opportunity in future: Successful AyyKori Campus Ambassadors will get priority for doing their internship/ full time job at AyyKori in the future.",
                },

                {
                    listData:
                        "Required Qualifications: We require following abilities for this specific program’s recruitment",
                },

                {
                    listData:
                        "Must be a currently enrolled college/ university student.",
                },

                {
                    listData:
                        "Possess good communication and negotiation skills.",
                },

                {
                    listData:
                        "Expert Guideline & Career Growth: Gain access to mentorship from industry experts, who will provide invaluable guidance to help shape your career path.",
                },

                {
                    listData: "Demonstrate problem-solving abilities.",
                },

                {
                    listData:
                        "How to Apply: Ready to kickstart your journey as an AyyKori Campus Ambassador? Simply fill out our online application form and tell us why you'd be the perfect fit for the role. We're looking for enthusiastic individuals with excellent communication skills and a passion for making a difference.Don't miss out on this incredible opportunity to develop your skills, earn valuable experience, and be part of a dynamic community. Join the AyyKori family today and embark on a rewarding journey towards personal and professional growth!            ",
                },

                {
                    listData:
                        "Click “Apply now” and fill up the google form with necessary information and become a prompt for change on your campus!",
                },
                {
                    listData:
                        "Click “Apply now” and fill up the google form with necessary information and become a prompt for change on your campus!",
                },
                {
                    listData:
                        "In conclusion, becoming AyyKori Campus Ambassador offers numerous benefits for students who are looking to enhance their professional skills and gain valuable experiences. From developing leadership abilities and improving communication skills to accessing exclusive internship opportunities and making a positive impact on campus, this role provides an invaluable platform for personal and professional growth. By embracing this role, students can make the most out of their college/university experience and prepare themselves for future success in their careers.",
                },
                {
                    listData:
                        "About AyyKori: AyyKori is a Sales Solution that increases sales & traffic on the brand’s own platform (website/app) by connecting with customers. Brands can increase their visibility in the market and attract new customers through AyyKori's innovative business model while also retaining existing customers through cashback and reward programs. Many businesses struggle with low website sales, and facing issues with awareness and SEO. We're here to change that! AyyKori is a cost-effective business solution that works with brands on cost per sale modality and helps to increase brand equity through free marketing opportunities.            ",
                },
                {
                    listData:
                        "First ever Cashback & Affiliate Platform: Ayykori App is Bangladesh's first cashback & affiliate app. Users can earn through affiliate marketing (promoting authentic brands/products) and save through getting cashback while shopping. ",
                },
                {
                    listData:
                        "Ayykori has Partnership with 100+ authentic brands including Daraz Bangladesh Limited, Othoba.com, Khaas Food, Pickaboo.com, Chaldal.com, Shohoz.com, Sheba.xyz, Esquire Electronics, Medeasy, Green Grocery, Organic Online, One Ummah BD, Deen, Stygen, Aladdin, LegalX, Beauty4me, UVTR Learning, BrandBox, Olab BD, Shikkha, Amar Lab, IHW, Gear Exact, In2Travels, DocTime, Interactive Cares, BacchaKaccha, PinkFlash BD, Dazzy Online, Best Electronics etc. ",
                },
                {
                    listData:
                        "AyyKori is Funded by Government, local & International Venture Capitals",
                },
                {
                    listData:
                        "Big 2023: The Bangabandhu Innovation Grant (BIG) 2023 is a unique source of inspiration for success and boldness for the new generation of startups or entrepreneurs. BIG 2023 is being organized by the Innovation Design and Entrepreneurship Academy (IDEA) Project of the Bangladesh Computer Council (BCC) under the ICT Division of the Government of the People's Republic of Bangladesh for innovators, entrepreneurs, and startups. AyyKori was recently ranked as one of the top 10 startups in the Bangabandhu Innovation Grant (BIG 2023) event.            ",
                },
                {
                    listData:
                        "BVCL: AyyKori has signed a 'SAFE' Note with Bangladesh Venture Capital Ltd setting the stage for future investments. On top of that, AyyKori has secured $1,00,000 in AWS Credits to support our innovative endeavors.            ",
                },
                {
                    listData:
                        "BYLC Ventures: Bangladesh Youth Leadership Center (BYLC) Ventures has successfully wrapped up the seventh cohort, adding six new start-ups to their portfolio of companies. AyyKori Digital Ltd is the winner of BYLC Ventures cohort 7. AyyKori was awarded in seed funding. In addition, AyyKori will gain access to a world class accelerator programme, in-house mentorship, networking support, leadership development training, legal & virtual CFO support, and access to co-working space at a prominent location in Dhaka.",
                },
                {
                    listData:
                        "Startup Dhaka: AyyKori was in top 5 startups from Startup Dhaka Accelerator Batch 2.",
                },
                {
                    listData:
                        "NSU Startup Next: NSU Startups Next (NSUSN) is an incubation program designed to inspire innovation, and support founders who want to scale their startups. At NSUSN, founders will learn from experts, form strong connections with those in the startup ecosystem and build a network, to create strong foundations for the rest of their journey as entrepreneurs. AyyKori is a portfolio startup of  NSU Startups Next. ",
                },
                {
                    listData:
                        "Smart Bangladesh Accelerator: AyyKori has joined Smart Bangladesh Accelerator's Growth-Stage Startup Program (GSS-C01 Cohort), empowering the true spirit of innovation, scalability in Bangladesh's consumer tech sector. ",
                },
            ],
            paragraph:
                "Click “Apply now” and fill up the google form with necessary information to increase traffic and sales on your brand’s own platform (website/app). Let’s have an amazing partnership! ",
        },
    },
    {
        id: 3,
        testimonials: false,
        title: "Brand Partnership ",
        titleSlug: "brand-partnership",
        banner: "https://images.ayykori.net/api/v1/images/get/activation/brand-partner-gif.gif",
        about: [
            {
                title: "Why partnership with AyyKori? ",
                subTitle: "",
                list: [
                    {
                        listData: "AyyKori: Business Solution for Brands  ",
                    },
                    {
                        listData:
                            " Boosted Sales from brand’s own platform ( Website/ App)            ",
                    },
                    {
                        listData:
                            "Increasing traffic on brands’ own platform ( Website/ App)",
                    },
                    {
                        listData: "Heightened Brand Visibility ",
                    },
                    {
                        listData: "AyyKori Bonus for Customer Acquisition",
                    },
                    {
                        listData:
                            " AyyKori Cashback to increase Brand Equity & Retention ",
                    },
                    {
                        listData:
                            "Providing Data Insights & Customizing Campaigns ",
                    },
                    {
                        listData:
                            " Cost-Effective Online and Offline Marketing",
                    },
                    {
                        listData: "Gaining Competitive Advantage",
                    },
                    {
                        listData: " Creating Customer Engagement",
                    },
                    {
                        listData: " Strengthening Brand’s Online Presence",
                    },
                ],
            },
        ],
        youtubeVideo: "https://www.youtube.com/watch?v=YDyDdBpqsbg",
        partnerShip: [
            {
                _id: 1,

                brandimg:
                    "https://images.ayykori.net/api/v1/images/get/partner/1.webp",
            },
            {
                _id: 2,

                brandimg:
                    "https://images.ayykori.net/api/v1/images/get/partner/2.webp",
            },
            {
                _id: 3,

                brandimg:
                    "https://images.ayykori.net/api/v1/images/get/partner/3.webp",
            },
            {
                _id: 4,

                brandimg:
                    "https://images.ayykori.net/api/v1/images/get/partner/4.webp",
            },
            {
                _id: 5,

                brandimg:
                    "https://images.ayykori.net/api/v1/images/get/partner/5.webp",
            },
            {
                _id: 6,

                brandimg:
                    "https://images.ayykori.net/api/v1/images/get/partner/6.webp",
            },
            {
                _id: 7,

                brandimg:
                    "https://images.ayykori.net/api/v1/images/get/partner/8.webp",
            },
            {
                _id: 8,

                brandimg:
                    "https://images.ayykori.net/api/v1/images/get/partner/7.webp",
            },
        ],
        affiliateApplyLink: "https://forms.gle/VV5VmYPs5iGREBB77",

        downloadCarriculam:
            "https://drive.google.com/drive/folders/1mU4WN5D6Yu2FpHF-WmHXgvNxIJSAJsmq?usp=drive_link",
        description: {
            title: "Objective & Partnership Benefits (Business Solution For Boosting Sales & Traffic)",
            subTitle:
                "This partnership's key objective is to make the user experience easier by seamlessly incorporating the brand's promotional features into AyyKori platform. Through this collaboration, our aim is to deliver enhanced value to our mutual customer base, fostering a mutually beneficial scenario for both entities.",
            list: [
                {
                    listData:
                        "AyyKori Bonus to the brand’s users: Every month brand’s users will receive a dedicated bonus cashback from AyyKori.",
                },
                {
                    listData:
                        "Boosted Sales: Expect increased sales through AyyKori's cashback rewards and exclusive offers.",
                },
                {
                    listData:
                        "Heightened Brand Visibility: Brands can reach a broader audience actively seeking cashback opportunities.",
                },
                {
                    listData:
                        "Customer Loyalty: Foster customer loyalty through cashback incentives.",
                },
                {
                    listData:
                        "Data Insights: Brands can gain valuable customer data and shopping trends of similar industry.",
                },

                {
                    listData:
                        "Customizing Campaigns: Brands will have the opportunity to reach broader audiences through customized campaigns with AyyKori.",
                },

                {
                    listData:
                        "Cost-Effective Marketing: Reach potential customers through a pay-per-sale model",
                },

                {
                    listData:
                        "Competitive Advantage: Stand out in the market and attract more customers.",
                },

                {
                    listData:
                        "Required Qualifications: We require following abilities for this specific program’s recruitment",
                },

                {
                    listData:
                        "Customer Engagement: Engage a broader audience, strengthening brand-customer relationships.",
                },

                {
                    listData:
                        "Our Requirements : We expect a commission rate a small tech integration and a formal MOU signature from both the authority to smoothen up the partnership procedure.",
                },

                {
                    listData:
                        "Commission Rate: We are suggesting a cooperative partnership wherein we collaborate with CPS (Cost Per Sale), operating on a commission basis. That means you only pay when your product gets sold by us. The commission rate can either be a fixed amount or categories based.The commission rate is open to negotiation, and your brand reserves the right to modify it at any time, on a pre-notifying basis.",
                },

                {
                    listData:
                        "Tech Integration: In order to identify customers directed to your website through our platform, a straightforward technological integration is required. It's important to emphasize that we do not access any private data. This integration will enable us to track and identify customers visiting your website via AyyKori for maintaining transparency.",
                },

                {
                    listData:
                        "How to start the partnership: We can begin by signing the Memorandum of Understanding (MOU). Once the MOU is signed by both parties, we will initiate our marketing efforts, working collaboratively to enhance traffic and boost sales on your platform.            ",
                },

                {
                    listData:
                        "Click “Apply now” and fill up the google form with necessary information to increase traffic and sales on your brand’s own platform (website/app). Let’s have an amazing partnership! ",
                },
                {
                    listData:
                        "About AyyKori: AyyKori is a Sales Solution that increases sales & traffic on the brand’s own platform (website/app) by connecting with customers. Brands can increase their visibility in the market and attract new customers through AyyKori's innovative business model while also retaining existing customers through cashback and reward programs. Many businesses struggle with low website sales, and facing issues with awareness and SEO. We're here to change that! AyyKori is a cost-effective business solution that works with brands on cost per sale modality and helps to increase brand equity through free marketing opportunities.",
                },
                {
                    listData:
                        "First ever Cashback & Affiliate Platform: Ayykori App is Bangladesh's first cashback & affiliate app. Users can earn through affiliate marketing (promoting authentic brands/products) and save through getting cashback while shopping. ",
                },
                {
                    listData:
                        "Ayykori has Partnership with 100+ authentic brands including Daraz Bangladesh Limited, Othoba.com, Khaas Food, Pickaboo.com, Chaldal.com, Shohoz.com, Sheba.xyz, Esquire Electronics, Medeasy, Green Grocery, Organic Online, One Ummah BD, Deen, Stygen, Aladdin, LegalX, Beauty4me, UVTR Learning, BrandBox, Olab BD, Shikkha, Amar Lab, IHW, Gear Exact, In2Travels, DocTime, Interactive Cares, BacchaKaccha, PinkFlash BD, Dazzy Online, Best Electronics etc. ",
                },
                {
                    listData:
                        "AyyKori is Funded by Government, local & International Venture Capitals",
                },
                {
                    listData:
                        "Big 2023: The Bangabandhu Innovation Grant (BIG) 2023 is a unique source of inspiration for success and boldness for the new generation of startups or entrepreneurs. BIG 2023 is being organized by the Innovation Design and Entrepreneurship Academy (IDEA) Project of the Bangladesh Computer Council (BCC) under the ICT Division of the Government of the People's Republic of Bangladesh for innovators, entrepreneurs, and startups. AyyKori was recently ranked as one of the top 10 startups in the Bangabandhu Innovation Grant (BIG 2023) event.",
                },
                {
                    listData:
                        "BVCL: AyyKori has signed a 'SAFE' Note with Bangladesh Venture Capital Ltd setting the stage for future investments. On top of that, AyyKori has secured $1,00,000 in AWS Credits to support our innovative endeavors.",
                },
                {
                    listData:
                        "BYLC Ventures: Bangladesh Youth Leadership Center (BYLC) Ventures has successfully wrapped up the seventh cohort, adding six new start-ups to their portfolio of companies. AyyKori Digital Ltd is the winner of BYLC Ventures cohort 7. AyyKori was awarded in seed funding. In addition, AyyKori will gain access to a world class accelerator programme, in-house mentorship, networking support, leadership development training, legal & virtual CFO support, and access to co-working space at a prominent location in Dhaka. ",
                },
                {
                    listData:
                        "Startup Dhaka: AyyKori was in top 5 startups from Startup Dhaka Accelerator Batch 2.  ",
                },
                {
                    listData:
                        "NSU Startup Next: NSU Startups Next (NSUSN) is an incubation program designed to inspire innovation, and support founders who want to scale their startups. At NSUSN, founders will learn from experts, form strong connections with those in the startup ecosystem and build a network, to create strong foundations for the rest of their journey as entrepreneurs. AyyKori is a portfolio startup of  NSU Startups Next. ",
                },
                {
                    listData:
                        "Smart Bangladesh Accelerator: AyyKori has joined Smart Bangladesh Accelerator's Growth-Stage Startup Program (GSS-C01 Cohort), empowering the true spirit of innovation, scalability in Bangladesh's consumer tech sector.",
                },
            ],
            paragraph:
                "Click “Apply now” and fill up the google form with necessary information to increase traffic and sales on your brand’s own platform (website/app). Let’s have an amazing partnership! ",
        },
    },
    {
        id: 3,
        testimonials: false,
        title: "Area Ambassador ",
        titleSlug: "area-ambassador",
        banner: "https://images.ayykori.net/api/v1/images/get/activation/area-ambassador-program-gif.gif",
        about: [
            {
                title: "Why will you become AyyKori Area Ambassador? ",
                subTitle: "",
                list: [
                    {
                        listData: "Earning Opportunity ",
                    },
                    {
                        listData: " Flexible Timing ",
                    },
                    {
                        listData: "Professional work experience certificate ",
                    },
                    {
                        listData: "Goodies & ID Cards",
                    },
                    {
                        listData: "Performance-based Incentive",
                    },
                    {
                        listData: " Expert Guideline & Career Growth  ",
                    },
                    {
                        listData: "Corporate & Field work Experience  ",
                    },
                    {
                        listData:
                            " Free Communication, Sales, Affiliate Marketing & Digital Marketing Training from Industry Leaders",
                    },
                    {
                        listData: "Insurance facilities",
                    },
                    {
                        listData: " Full time Job Opportunity in the Future",
                    },
                ],
            },
        ],
        youtubeVideo: "https://www.youtube.com/watch?v=xy_g0eANEvk",
        partnerShip: [
            {
                _id: 1,

                brandimg:
                    "https://images.ayykori.net/api/v1/images/get/partner/1.webp",
            },
            {
                _id: 2,

                brandimg:
                    "https://images.ayykori.net/api/v1/images/get/partner/2.webp",
            },
            {
                _id: 3,

                brandimg:
                    "https://images.ayykori.net/api/v1/images/get/partner/3.webp",
            },
            {
                _id: 4,

                brandimg:
                    "https://images.ayykori.net/api/v1/images/get/partner/4.webp",
            },
            {
                _id: 5,

                brandimg:
                    "https://images.ayykori.net/api/v1/images/get/partner/5.webp",
            },
            {
                _id: 6,

                brandimg:
                    "https://images.ayykori.net/api/v1/images/get/partner/6.webp",
            },
            {
                _id: 7,

                brandimg:
                    "https://images.ayykori.net/api/v1/images/get/partner/8.webp",
            },
            {
                _id: 8,

                brandimg:
                    "https://images.ayykori.net/api/v1/images/get/partner/7.webp",
            },
        ],
        affiliateApplyLink: "https://forms.gle/ATDS51bGT6R6qmNb6",

        downloadCarriculam:
            "https://drive.google.com/drive/folders/1mU4WN5D6Yu2FpHF-WmHXgvNxIJSAJsmq?usp=drive_link",
        description: {
            title: "AyyKori Area Ambassador Program Details:        ",
            subTitle:
                "Are you a driven individual looking to significantly impact your community while gaining valuable professional experience? Join the AyyKori Area Ambassador Program and become a catalyst for change in your area! At AyyKori, we believe in empowering leaders passionate about creating positive change. Our Area Ambassador Program offers a wide range of benefits designed to support your personal and professional growth:",
            list: [
                {
                    listData:
                        "Earning Opportunity: As an Area Ambassador, you'll have the opportunity to earn competitive incentives while making a difference in your community.",
                },
                {
                    listData:
                        "Flexible Timing: We understand the importance of balancing your commitments. That's why we offer flexible working hours that allow you to manage your schedule effectively.",
                },
                {
                    listData:
                        "Professional Work Experience Certificate: Enhance your resume with a prestigious work experience certificate from AyyKori, recognized and respected by industry professionals.",
                },
                {
                    listData:
                        "Customer Loyalty: Foster customer loyalty through cashback incentives.",
                },
                {
                    listData:
                        "Goodies & ID Cards: Enjoy exclusive AyyKori merchandise and official identification, showcasing your affiliation with a reputable organization.",
                },

                {
                    listData:
                        "Performance-Based Incentives: Your hard work and dedication will be rewarded with performance-based incentives, motivating you to achieve your goals.",
                },

                {
                    listData:
                        "Expert Guideline & Career Growth: Receive mentorship and guidance from industry experts, who will provide valuable insights to help you advance in your career.",
                },

                {
                    listData:
                        "Corporate & Field Work Experience: Gain hands-on experience in marketing, communications, and event management within a corporate environment, setting you up for success in your future endeavors.",
                },

                {
                    listData:
                        "Exclusive Exposure: Expand your network and connect with professionals in your field through exclusive events and networking opportunities, providing you with invaluable exposure and connections.",
                },

                {
                    listData:
                        "Internship and Job Opportunity in future: Successful AyyKori Area Ambassadors will get priority for doing their internship/ full time job at AyyKori in the future.",
                },

                {
                    listData:
                        "Required Qualifications: We require following abilities for this specific program’s recruitment",
                },

                {
                    listData:
                        "Proficient in operating and browsing electronic gadgets including mobile phones and laptops.",
                },

                {
                    listData:
                        "Possess good communication and negotiation skills.",
                },

                {
                    listData:
                        "How to Apply: Are you ready to take the next step in your journey as a leader and make a lasting impact in your community? Apply now to become an AyyKori Area Ambassador! We're looking for dedicated individuals with excellent communication skills and a passion for driving change. Don't miss out on this incredible opportunity to develop your skills, earn valuable experience, and be part of a dynamic community dedicated to making a difference. Join us today and become a leader in your area with AyyKori! ",
                },

                {
                    listData:
                        "Click “Apply now” and fill up the google form with necessary information to be the change you wish to see in your community! ",
                },
                {
                    listData:
                        "About AyyKori: AyyKori is a Sales Solution that increases sales & traffic on the brand’s own platform (website/app) by connecting with customers. Brands can increase their visibility in the market and attract new customers through AyyKori's innovative business model while also retaining existing customers through cashback and reward programs. Many businesses struggle with low website sales, and facing issues with awareness and SEO. We're here to change that! AyyKori is a cost-effective business solution that works with brands on cost per sale modality and helps to increase brand equity through free marketing opportunities.",
                },
                {
                    listData:
                        "First ever Cashback & Affiliate Platform: Ayykori App is Bangladesh's first cashback & affiliate app. Users can earn through affiliate marketing (promoting authentic brands/products) and save through getting cashback while shopping.  ",
                },
                {
                    listData:
                        "Ayykori has Partnership with 100+ authentic brands including Daraz Bangladesh Limited, Othoba.com, Khaas Food, Pickaboo.com, Chaldal.com, Shohoz.com, Sheba.xyz, Esquire Electronics, Medeasy, Green Grocery, Organic Online, One Ummah BD, Deen, Stygen, Aladdin, LegalX, Beauty4me, UVTR Learning, BrandBox, Olab BD, Shikkha, Amar Lab, IHW, Gear Exact, In2Travels, DocTime, Interactive Cares, BacchaKaccha, PinkFlash BD, Dazzy Online, Best Electronics etc. ",
                },
                {
                    listData:
                        "Ayykori has Partnership with 100+ authentic brands including Daraz Bangladesh Limited, Othoba.com, Khaas Food, Pickaboo.com, Chaldal.com, Shohoz.com, Sheba.xyz, Esquire Electronics, Medeasy, Green Grocery, Organic Online, One Ummah BD, Deen, Stygen, Aladdin, LegalX, Beauty4me, UVTR Learning, BrandBox, Olab BD, Shikkha, Amar Lab, IHW, Gear Exact, In2Travels, DocTime, Interactive Cares, BacchaKaccha, PinkFlash BD, Dazzy Online, Best Electronics etc.",
                },
                {
                    listData:
                        "AyyKori is Funded by Government, local & International Venture Capitals",
                },
                {
                    listData:
                        "Big 2023: The Bangabandhu Innovation Grant (BIG) 2023 is a unique source of inspiration for success and boldness for the new generation of startups or entrepreneurs. BIG 2023 is being organized by the Innovation Design and Entrepreneurship Academy (IDEA) Project of the Bangladesh Computer Council (BCC) under the ICT Division of the Government of the People's Republic of Bangladesh for innovators, entrepreneurs, and startups. AyyKori was recently ranked as one of the top 10 startups in the Bangabandhu Innovation Grant (BIG 2023) event.",
                },
                {
                    listData:
                        "BVCL: AyyKori has signed a 'SAFE' Note with Bangladesh Venture Capital Ltd setting the stage for future investments. On top of that, AyyKori has secured $1,00,000 in AWS Credits to support our innovative endeavors.",
                },
                {
                    listData:
                        "BYLC Ventures: Bangladesh Youth Leadership Center (BYLC) Ventures has successfully wrapped up the seventh cohort, adding six new start-ups to their portfolio of companies. AyyKori Digital Ltd is the winner of BYLC Ventures cohort 7. AyyKori was awarded in seed funding. In addition, AyyKori will gain access to a world class accelerator programme, in-house mentorship, networking support, leadership development training, legal & virtual CFO support, and access to co-working space at a prominent location in Dhaka. ",
                },
                {
                    listData:
                        "Startup Dhaka: AyyKori was in top 5 startups from Startup Dhaka Accelerator Batch 2. ",
                },
                {
                    listData:
                        "NSU Startup Next: NSU Startups Next (NSUSN) is an incubation program designed to inspire innovation, and support founders who want to scale their startups. At NSUSN, founders will learn from experts, form strong connections with those in the startup ecosystem and build a network, to create strong foundations for the rest of their journey as entrepreneurs. AyyKori is a portfolio startup of  NSU Startups Next. ",
                },
                {
                    listData:
                        "Smart Bangladesh Accelerator: AyyKori has joined Smart Bangladesh Accelerator's Growth-Stage Startup Program (GSS-C01 Cohort), empowering the true spirit of innovation, scalability in Bangladesh's consumer tech sector.",
                },
            ],
            paragraph:
                "Click “Apply now” and fill up the google form with necessary information to increase traffic and sales on your brand’s own platform (website/app). Let’s have an amazing partnership! ",
        },
    },
];
