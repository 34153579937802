import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  errorMsg: "",
  isError: false,
};

export const errorHelperSlice = createSlice({
  name: "errorHelperSlice",
  initialState,
  reducers: {
    setErrorMsg: (state, action) => {
      state.errorMsg = action.payload.errorMsg;
      state.isError = action.payload.isError;
    },

    removeErrorMsg: (state, action) => {
      state.errorMsg = " ";
    },
  },
});

// Action creators are generated for each case reducer function
export const { setErrorMsg, removeErrorMsg } = errorHelperSlice.actions;

export default errorHelperSlice.reducer;
