const response = {
  data: {
    succss: true,
    resData: [
      {
        aboutTheBrand:
          "OLAB BD is an affordable online platform offering high-quality digital marketing courses in Bangladesh. Taught by experienced professionals, the practical courses cover essential topics like Facebook Marketing, SEO, UI/UX Design, Graphic Design, and Digital Image Editing. Networking opportunities allow students to learn from other digital marketers' experiences.",
        applicableMethods:
          "Payment Method: bKash, Nagad, Onilne Banking available",
        brandBanner:
          "https://drive.google.com/uc?export=view&id=1BdNcqySTj6KwWCFVNz-vKW8YapHHNHra",

        brandDomain: "olabbd.com",
        brandHomepageLink: "https://olabbd.com/",
        brandName: "OLAB BD",
        brandType: "Education",
        brandUnid: "2027",
        brandimg:
          "https://drive.google.com/uc?export=view&id=1AyQi5zwg-j6eFw2FClsHj5EV2uEV3Ejd",
        cashbackLimit: "No Limit",
        cashbackReturnTime: "20 Days",
        cashbackUserCommission: 14.4,
        categories: "",
        commissionType: "flat",
        earningUserCommission: 15.99,
        orderNotifyTime: "5 Minutes",
        specialities: "Education",
      },
    ],
  },
};

export default response;
export const responseForSheba = {
  data: {
    succss: true,
    resData: [
      {
        aboutTheBrand:
          "OLAB BD is an affordable online platform offering high-quality digital marketing courses in Bangladesh. Taught by experienced professionals, the practical courses cover essential topics like Facebook Marketing, SEO, UI/UX Design, Graphic Design, and Digital Image Editing. Networking opportunities allow students to learn from other digital marketers' experiences.",
        applicableMethods:
          "Payment Method: bKash, Nagad, Onilne Banking available",
        brandBanner:
          "https://drive.google.com/uc?export=view&id=1BdNcqySTj6KwWCFVNz-vKW8YapHHNHra",

        brandDomain: "olabbd.com",
        brandHomepageLink: "https://olabbd.com/",
        brandName: "OLAB BD",
        brandType: "Education",
        brandUnid: "2027",
        brandimg:
          "https://drive.google.com/uc?export=view&id=1AyQi5zwg-j6eFw2FClsHj5EV2uEV3Ejd",
        cashbackLimit: "No Limit",
        cashbackReturnTime: "20 Days",
        cashbackUserCommission: 14.4,
        categories: "",
        commissionType: "flat",
        earningUserCommission: 15.99,
        orderNotifyTime: "5 Minutes",
        specialities: "Education",
      },
    ],
  },
};
