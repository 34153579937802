import { apiRequest } from "../apiRequest/apiRequest";

export const campaignApiSlice = apiRequest.injectEndpoints({
  endpoints: (builder) => ({
    createPageVisitTracking: builder.mutation({
      query: (newData) => ({
        url: "/analytice/create-page-visit-tracking",
        method: "POST",
        body: newData,
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
        },
      }),
      invalidatesTags: ["visitTracking"],
    }),

    getRunningCampaignForUser: builder.query({
      query: () => {
        return {
          url: `/campaign/get-running-campaign-user`,
          method: "GET",
          headers: {
            "Content-Type": "application/json;charset=UTF-8",
          },
        };
      },
      invalidatesTags: ["campaign"],
    }),
  }),
});

export const {
  useCreatePageVisitTrackingMutation,
  useGetRunningCampaignForUserQuery,
} = campaignApiSlice;
