import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { useSelector } from "react-redux";
import ShareAndEarnBanner from "../../components/Banner/ShareAndEarnBanner";
import Brands from "../../components/Brands/Brands";
import HighestCommision from "../../components/Brands/HighestCommision";
import TrendyBrands from "../../components/Brands/TrendyBrands";
import BestDealToShare from "../../components/Products/BestDealToShare";
import useAuth from "../../hooks/useAuth";
import CommonLayout from "../../layout/CommonLayout";

const ShareAndEarnPage = () => {
  const isAuth = useAuth();
  const reloadPage = useSelector((state) => state?.reload?.reload);
  useEffect(() => {}, [reloadPage]);

  return (
    <>
      <Helmet>
        <title>Affiliate| Ayykori</title>
      </Helmet>
      <CommonLayout>
        <ShareAndEarnBanner />
        {/* <ShareAndEarnSlider /> */}
        <TrendyBrands />
        <HighestCommision />
        <BestDealToShare />
        <Brands />
      </CommonLayout>
    </>
  );
};

export default ShareAndEarnPage;
