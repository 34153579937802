import { AiOutlineLink } from "react-icons/ai";
import { BiCalculator } from "react-icons/bi";
import { GiTakeMyMoney } from "react-icons/gi";
import { IoWalletOutline } from "react-icons/io5";
import { RiAccountPinCircleFill } from "react-icons/ri";
import { SiGoogleclassroom } from "react-icons/si";
import { TfiHelpAlt } from "react-icons/tfi";

export const navbarAccessMenuLinks = [
  {
    title: "Cashback Balance",
    link: "/my-account",
    id: "A1",
    // sublinks: [
    //   {
    //     title: "Cashback Balance",
    //     link: "/my-account",
    //     id: "B1",
    //     Icon: GiReceiveMoney,
    //   },

    // ],
    Icon: IoWalletOutline,
  },

  {
    // title: "Profit Calculation",
    // link: "/my-account/cashback-calculation",
    // id: "C1",
    title: "Cashback Calculator",
    link: "/my-account/cashback-calculation",
    Icon: BiCalculator,
    id: "A2",
    // sublinks: [
    //   {
    //     title: "Earning Calc.",
    //     link: "/my-account/earning-calculation",
    //     id: "C2",
    //     Icon: IoWalletOutline,
    //   },
    // ],
    // Icon: SlCalculator,
  },

  {
    title: "Affiliate",
    link: "my-account/earning-balance",
    id: "A1",
    sublinks: [
      {
        title: "Affiliate",
        link: "/affiliate",
        id: "B1",
        Icon: GiTakeMyMoney,
      },
      {
        title: "Affiliate Balance",
        link: "/my-account/earning-balance",
        id: "B1",
        Icon: GiTakeMyMoney,
      },
      {
        title: "Affiliate Calc.",
        link: "/my-account/earning-calculation",
        id: "B2",
        Icon: IoWalletOutline,
      },
      {
        title: "Make Affiliate  Link",
        link: "/my-account/make-affiliate-link",
        id: "B3",
        Icon: AiOutlineLink,
      },
      {
        title: "Online Workshop",
        link: "/my-account/online-workshop",
        id: "B4",
        Icon: SiGoogleclassroom,
      },
    ],
    Icon: IoWalletOutline,
  },

  // {
  //   title: "Make Affiliate  Link",
  //   link: "/my-account/make-affiliate-link",
  //   id: "A3",
  //   Icon: AiOutlineLink,
  // },
  // {
  //   title: "Online Workshop",
  //   link: "/my-account/online-workshop",
  //   id: "A4",
  //   Icon: SiGoogleclassroom,
  // },
  // {
  //   title: "Referrals",
  //   link: "/my-account/my-referrals",
  //   id: "A5",
  //   Icon: AiOutlineShareAlt,
  // },
  {
    title: "My Account",
    link: "/my-account",
    id: "A6",
    Icon: RiAccountPinCircleFill,
  },
  {
    title: "Help",
    link: "/customer-support",
    id: "A7",
    Icon: TfiHelpAlt,
  },
];
