import { createSlice } from "@reduxjs/toolkit";
import toast from "react-hot-toast";
import { AiOutlineClear } from "react-icons/ai";

export const wishlistSlice = createSlice({
  name: "wishlist",
  initialState: {
    wishlist: localStorage.getItem("wishlist")
      ? JSON.parse(localStorage.getItem("wishlist"))
      : [],
  },
  reducers: {
    addToWishlist: (state, action) => {
      const id = action.payload.product._id;

      const isProductInWishlist = state.wishlist.find(
        (product) => product._id === id
      );

      if (!isProductInWishlist) {
        state.wishlist.push({
          ...action.payload.product,
        });
        toast.success("Product wishlist added");
      } else {
        state.wishlist = state.wishlist.filter(
          (product) => product?._id !== id
        );
        toast("Clear from wishlist", {
          icon: <AiOutlineClear />,
          style: {
            borderRadius: "10px",
            color: " rgb(249 115 22)",
            background: "",
          },
        });
      }

      // add data to local storage
      localStorage.setItem("wishlist", JSON.stringify(state.wishlist));
    },
  },
});

export const { addToWishlist } = wishlistSlice.actions;

export default wishlistSlice.reducer;
