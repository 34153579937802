import React from "react";
import { Helmet } from "react-helmet";
import Change_Payment_Information_img from "../../Assets/images/3D-image/change-payment-info.webp";
import Change_Personal_Information_img from "../../Assets/images/3D-image/change-personal-info.png";
import Missing_Cashback_img from "../../Assets/images/3D-image/missing-cashback.png";
import Missing_Payment_img from "../../Assets/images/3D-image/missing-payment.png";
import Missing_Referral_Bonus_img from "../../Assets/images/3D-image/missing-referral.png";
import Others_img from "../../Assets/images/3D-image/other-problem.avif";
import CommonLayout from "../../layout/CommonLayout";

const CustomerSupportPage = () => {
  return (
    <>
      <Helmet>
        <title>Customer Support | Ayykori</title>
      </Helmet>
      <CommonLayout>
        <div className="w-full px-3 md:px-[55px] mt-24 md:mt-40 mb-10">
          <div>
            <h1 className="text-gray-700 text-base md:text-2xl font-medium font-outfit">
              Customer Support
            </h1>
            <h5 className="text-gray-400 text-sm md:text-lg font-normal font-outfit">
              What can we help you with?
            </h5>
          </div>
          <div className="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3 gap-3 md:gap-6 my-6">
            <div>
              <a
                href="https://forms.gle/UtqnPgZfXeyfms5c8"
                target="_blank"
                rel="noopener noreferrer"
                // to={`/customer-support/missing-order`}
                className="border rounded-md flex flex-col gap-4 items-center justify-evenly py-7"
              >
                <img
                  className="w-32 h-32 md:w-48 md:h-48"
                  src={Missing_Referral_Bonus_img}
                  alt=""
                />
                <h1 className="text-sm md:text-lg font-medium font-outfit text-gray-700">
                  Missing Referral Bonus
                </h1>
              </a>
            </div>
            <div>
              <a
                href="https://forms.gle/2v5x7GhjUzQycNYJ7"
                target="_blank"
                rel="noopener noreferrer"
                // to={`/customer-support/missing-order`}
                className="border rounded-md flex flex-col gap-4 items-center justify-evenly py-7"
              >
                <img
                  className="w-32 md:w-48 h-32  md:h-48"
                  src={Missing_Cashback_img}
                  alt=""
                />
                <h1 className="text-sm md:text-lg  font-medium font-outfit text-gray-700">
                  Missing Cashback
                </h1>
              </a>
            </div>
            <div>
              <a
                href="https://forms.gle/YVQ3RGFB391G33339"
                target="_blank"
                rel="noopener noreferrer"
                // to={`/customer-support/missing-order`}
                className="border rounded-md flex flex-col gap-4 items-center justify-evenly py-7"
              >
                <img
                  className="w-32 md:w-48 h-32  md:h-48"
                  src={Missing_Payment_img}
                  alt=""
                />
                <h1 className="text-sm md:text-lg font-medium font-outfit text-gray-700">
                  Missing Payment
                </h1>
              </a>
            </div>
            <div>
              <a
                href="https://forms.gle/wxhygHCCyaUZVeF8A"
                target="_blank"
                rel="noopener noreferrer"
                // to={`/customer-support/missing-order`}
                className="border rounded-md flex flex-col gap-4 items-center justify-evenly py-7"
              >
                <img
                  className="w-32 md:w-48 h-32  md:h-48"
                  src={Change_Payment_Information_img}
                  alt=""
                />
                <h1 className="  text-center text-sm md:text-lg font-medium font-outfit text-gray-700">
                  Change Payment Info
                </h1>
              </a>
            </div>
            <div>
              <a
                href="https://forms.gle/2r2zqJZVNgQFz9Ei7"
                target="_blank"
                rel="noopener noreferrer"
                // to={`/customer-support/missing-order`}
                className="border rounded-md flex flex-col gap-4 items-center justify-evenly py-7"
              >
                <img
                  className="w-28 md:w-48 h-28  md:h-48"
                  src={Change_Personal_Information_img}
                  alt=""
                />
                <h1 className="text-center text-sm md:text-lg font-medium font-outfit text-gray-700">
                  Change Personal Info
                </h1>
              </a>
            </div>
            <div>
              <a
                href="https://forms.gle/tFYSjSkSvooQzaQ16"
                target="_blank"
                rel="noopener noreferrer"
                // to={`/customer-support/missing-order`}
                className="border rounded-md flex flex-col gap-4 items-center justify-evenly py-7"
              >
                <img
                  className="w-28 md:w-48 h-28  md:h-48"
                  src={Others_img}
                  alt=""
                />
                <h1 className="text-center text-sm md:text-lg font-medium font-outfit text-gray-700">
                  Others
                </h1>
              </a>
            </div>
          </div>
          <h1 className="text-gray-500 text-sm font-medium italic">
            Support Terms & Conditions Applied
          </h1>
        </div>
      </CommonLayout>
    </>
  );
};

export default CustomerSupportPage;
