import React from "react";

const BrandRedirectPageStepper = ({ response }) => {
  const { data } = response;
  const brandDetails = data?.resData[0];
  return (
    <div className="w-full flex flex-col gap-y-6">
      <div className=" flex items-center justify-between w-full relative h-[2px] ">
        <div className="w-28 text-[10px] md:text-sm text-center -ml-7 md:-ml-0 text-gray-700 whitespace-pre">
          Order Placed
        </div>
        <div className="w-28 text-[10px] md:text-sm -ml-4 md:-ml-0 text-center text-gray-700 whitespace-pre">
          Notified In
        </div>
        <div className="w-28 text-[10px] md:text-sm text-center -mr-6 md:-mr-1 text-gray-700  whitespace-pre">
          Expected Cashback
        </div>
      </div>
      <div className=" flex items-center justify-between w-full relative h-[2px] gap-x-4 md:gap-x-0 bg-gray-300">
        <button className="px-2 md:px-4 py-1 border-[2px] text-[10px] md:text-xs whitespace-pre border-gray-200 text-secondary bg-white rounded-full">
          Today
        </button>
        <button className="px-2 md:px-4 py-1 border-[2px] text-[10px] md:text-xs whitespace-pre border-gray-200 text-secondary bg-white rounded-full">
          {brandDetails?.orderNotifyTime}
        </button>
        <button className="px-2 md:px-4 py-1 border-[2px] text-[10px] md:text-xs whitespace-pre border-gray-200 text-secondary bg-white rounded-full">
          {brandDetails?.cashbackReturnTime}
        </button>
      </div>
    </div>
  );
};

export default BrandRedirectPageStepper;
