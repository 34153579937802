import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { getToken, setToken } from "../components/Utility/LocalStorage";

const useUserTracking = () => {
  const [code, setCode] = useState();
  const [query] = useSearchParams();
  const refferalCode = query?.get("active");

  useEffect(() => {
    if (refferalCode) {
      const r_ = getToken("r_");
      if (r_ === refferalCode) {
        setCode(r_);
      } else {
        setToken("r_", refferalCode);
        setCode(r_);
      }
    }
  }, [refferalCode]);

  return code; // Optionally return the 'code' state if needed in the component.
};

export default useUserTracking;
