import { apiRequest } from "../apiRequest/apiRequest";

export const trendingApiSlice = apiRequest.injectEndpoints({
  endpoints: (builder) => ({
    getAllTrendingData: builder.query({
      query: (data) => {
        const { access_token, page, limit } = data || {};
        return {
          url: `/get-trending-data?page=${page}&limit=${limit}`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${access_token}`,
            "Content-Type": "application/json;charset=UTF-8",
          },
        };
      },
      keepUnusedDataFor: 1000,
      providesTags: [""],
    }),
    getCampaignTrendingData: builder.query({
      query: (data) => {
        const { access_token, page, limit, tag } = data || {};
        const queryParams = new URLSearchParams();
        if (tag) {
          queryParams.append("tag", tag);
        }
        queryParams.append("page", page || "");
        queryParams.append("limit", limit || "");
        return {
          url: `/get-campaign-trending-data?${queryParams.toString()}`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${access_token}`,
            "Content-Type": "application/json;charset=UTF-8",
          },
        };
      },
      providesTags: [""],
    }),
  }),
});

export const { useGetAllTrendingDataQuery, useGetCampaignTrendingDataQuery } =
  trendingApiSlice;
