import Cookies from "js-cookie";
import React, { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { BiUserPin } from "react-icons/bi";
import { GiTakeMyMoney } from "react-icons/gi";
import { MdOutlineContentCopy } from "react-icons/md";
import { TbUnlink } from "react-icons/tb";
import { TiMessages } from "react-icons/ti";
import {
  FacebookIcon,
  FacebookMessengerIcon,
  FacebookMessengerShareButton,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from "react-share";
import referral from "../../Assets/images/gif/referral1.gif";
import shade1 from "../../Assets/images/shapes/shade1.png";
import shade2 from "../../Assets/images/shapes/shade2.png";
import ReferalCard from "../../components/Card/ReferalCard";
import Error from "../../components/Error/Error";
import NoDataFound from "../../components/NodataFound/NoDataFound";
import AllReferralTable from "../../components/Table/AllReferralTable";
import { useGetReferralUsersQuery } from "../../features/referral/referralApiSlice";
import { useGetSingleUserQuery } from "../../features/user/userApiSlice";
import modernCopyToClipboardFunction from "../../utils/modernCopyClipBoard";
const MyReferallasPage = () => {
  // console.log(location);
  // after navigate this page show in top
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const access_token = Cookies?.get("ayykori");
  const { isSuccess, isError, data } =
    useGetSingleUserQuery(access_token, { refetchOnReconnect: true }) || {};
  const {
    isLoading: isLoadingRefer,
    isSuccess: isSuccessRefer,
    isError: isErrorRefer,
    data: dataRefer,
  } = useGetReferralUsersQuery(
    { access_token },
    { refetchOnReconnect: true }
  ) || {};

  const [referlink, setReferLink] = useState("");
  const handleCopy = () => {
    modernCopyToClipboardFunction(referlink);
    // navigator.clipboard.writeText(referlink);
    toast.success("Link Copied!!");
  };

  useEffect(() => {
    if (isSuccess && !isError) {
      setReferLink(`https://ayykori.net/login?r_id=${data?.user?.reffercode}`);
    }
  }, [isSuccess, isError, data?.user]);

  const handleCopyColumn = (columnName) => {
    if (isSuccessRefer && dataRefer?.refferalUser) {
      console.log(dataRefer?.refferalUser);
      const columnData = dataRefer.refferalUser
        .map((user) => {
          console.log(user?.usedUserDetails[0]);
          switch (columnName) {
            case "userEmaill":
              return user?.usedUserDetails[0]?.email;
            case "phoneNumber":
              return user?.usedUserDetails[0]?.phone;

            default:
              return "";
          }
        })
        .join("\n");

      modernCopyToClipboardFunction(columnData);
      toast.success(`All ${columnName}  copied!`);
    }
  };
  let content = null;
  // handle loading case
  if (isLoadingRefer && !isSuccessRefer) {
    content = (
      <>
        <tbody>
          {Array(5)
            ?.fill()
            ?.map((item, index) => (
              <tr key={index} className="border-t border-gray-300">
                <td className="text-xs min-w-[250px] w-fit px-3 py-2 gap-x-0 text-gray-500 text-left">
                  <div className="bg-gray-200 animate-pulse w-full h-5"></div>
                </td>
                <td className="text-xs w-fit px-3 py-2 gap-x-0 text-gray-500 text-left">
                  <div className="bg-gray-200 animate-pulse w-full h-5"></div>
                </td>
                <td className="text-xs w-fit px-3 py-2 gap-x-0 text-gray-500 text-left">
                  <div className="bg-gray-200 animate-pulse w-full h-5"></div>
                </td>
              </tr>
            ))}
        </tbody>
      </>
    );
  }

  // handle success case
  if (!isLoadingRefer && isSuccessRefer && !isErrorRefer) {
    content = (
      <>
        <AllReferralTable dataRefer={dataRefer} />
      </>
    );
  }
  // handle error case
  if (!isLoadingRefer && isErrorRefer) {
    content = (
      <>
        <tbody>
          <tr className="border-t border-gray-300">
            <td
              colSpan={4}
              className="text-xs min-w-[250px] w-fit px-3 py-2 gap-x-0 text-gray-500 text-left"
            >
              <Error />
            </td>
          </tr>
        </tbody>
      </>
    );
  }

  // handle empty case
  if (
    !isLoadingRefer &&
    isSuccessRefer &&
    dataRefer?.refferalUser?.length === 0
  ) {
    content = (
      <>
        <tbody>
          <tr className="border-t border-gray-300">
            <td
              colSpan={4}
              className="text-xs min-w-[250px] w-fit px-3 py-2 gap-x-0 text-gray-500 text-left"
            >
              <NoDataFound />
            </td>
          </tr>
        </tbody>
      </>
    );
  }

  return (
    <div className="py-2 md:p-8">
      <h1 className="text-base md:text-lg text-gray-500 font-outfit font-medium">
        Refer & Earn
      </h1>
      <p className="text-xs md:text-base text-gray-400 font-outfit font-light">
        Refer Ayykori to friends and family, get a referral bonus when they
        earn, or get cashbacks.
      </p>
      <div className="flex flex-col md:flex-row items-start md:items-center md:justify-between gap-6 md:gap-x-2 my-7">
        <ReferalCard
          Icon={TiMessages}
          title="Copy Link"
          text="Use the referral link provided below"
        />
        <img
          src={shade1}
          alt=""
          className="hidden md:block w-36 object-cover self-start"
        />
        <ReferalCard
          Icon={BiUserPin}
          title="Share"
          text="Share with family, friends, and communities, encouraging them to install the AyyKori app and make purchases."
        />
        <img
          src={shade2}
          alt=""
          className="hidden md:block w-36 mt-8 object-cover self-start"
        />
        <ReferalCard
          Icon={GiTakeMyMoney}
          title="Earn Rewards"
          text=" Get 30 BDT bonus for each successful installation with a 1000 BDT minimum purchase. Also 10% bonus of their cashback amount for the next 3 months.
"
        />
      </div>

      <div className="flex flex-col justify-center items-center gap-y-2">
        <img src={referral} alt="" className="w-20" />
        <h1 className="text-base md:text-lg text-gray-500 font-outfit font-medium">
          Find your referral link
        </h1>
        <p className="text-xs md:text-base text-gray-400 text-center font-outfit font-light mb-1">
          Copy referral link & share with others, get bonus when they earn/get
          cashbacks.
        </p>
        <div className="w-full md:w-[60%] flex items-center border rounded-full pl-4">
          <input
            className="w-full text-sm md:text-base outline-none font-light mr-1 text-gray-500 cursor-not-allowed"
            readOnly
            value={referlink}
            type="text"
          />
          <button
            onClick={handleCopy}
            className="flex items-center text-sm md:text-base gap-x-1 bg-primaryColor text-white rounded-r-full px-2 md:px-4 py-1.5"
          >
            Copy
            <TbUnlink />
          </button>
        </div>
        <div className="flex items-center gap-x-4 mt-2">
          <FacebookShareButton url={referlink}>
            <FacebookIcon size={30} className="rounded-full" />
          </FacebookShareButton>

          <FacebookMessengerShareButton url={referlink}>
            <FacebookMessengerIcon size={30} className="rounded-full" />
          </FacebookMessengerShareButton>

          <WhatsappShareButton url={referlink} title="Share This">
            <WhatsappIcon size={30} className="rounded-full" />
          </WhatsappShareButton>

          <LinkedinShareButton url={referlink}>
            <LinkedinIcon size={30} className="rounded-full" />
          </LinkedinShareButton>
        </div>
      </div>
      <a
        href={"https://forms.gle/xb9XBmxWpRXYv3Vr5"}
        target="_blank"
        rel="noreferrer"
        className=" text-base md:text-lg text-primaryColor font-outfit font-medium mt-4 mb-2 md:my-4"
      >
        Claim Here
      </a>
      <p className=" w-1/2 md:w-1/3 text-gray-400 text-xs">
        To claim your referral bonus, please submit the required information via
        the Google form
      </p>
      <h1 className="text-base md:text-lg text-gray-500 font-outfit font-medium mt-3 mb-2 md:my-4">
        Referred User
      </h1>

      <div
        className="w-full max-h-80 overflow-auto scroll-smooth 
    scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-white 
    scrollbar-thumb-rounded-full scrollbar-track-rounded-full scrollbar-h-1"
      >
        <table
          className=" whitespace-nowrap w-full h-full p-[4rem]
border-collapse rounded transition duration-700 shadow-lg"
        >
          <thead>
            <tr className="bg-gray-50">
              <th className=" flex items-center gap-2 text-xs py-2 px-3 gap-x-0 text-gray-400 font-medium text-left">
                User Name
                <button
                  className=" px-2"
                  onClick={() => handleCopyColumn("userEmaill")}
                >
                  <MdOutlineContentCopy />
                </button>
              </th>
              <th className=" text-xs py-2 px-3 gap-x-0 text-gray-400 font-medium text-left">
                Phone Number
                <button
                  className="px-2"
                  onClick={() => handleCopyColumn("phoneNumber")}
                >
                  <MdOutlineContentCopy />
                </button>
              </th>
              <th className="text-xs  py-2 px-3 gap-x-0 text-gray-400 font-medium text-left">
                Joining Date
              </th>
              <th className="text-xs  py-2 px-3 gap-x-0 text-gray-400 font-medium text-left">
                Joining Time
              </th>
            </tr>
          </thead>
          {content}
        </table>
      </div>
    </div>
  );
};

export default MyReferallasPage;
