import { apiRequest } from "../apiRequest/apiRequest";

export const shareandjoyApiSlice = apiRequest.injectEndpoints({
  endpoints: (builder) => ({
    getAllShareAndJoy: builder.query({
      query: (data) => {
        const { access_token, page, limit, campaign } = data || {};
        return {
          url: `/get-share-joy?campain=${campaign}&page=${page}&limit=${limit}`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${access_token}`,
            "Content-Type": "application/json;charset=UTF-8",
          },
        };
      },
      providesTags: [""],
    }),
  }),
});

export const { useGetAllShareAndJoyQuery } = shareandjoyApiSlice;
