import React from "react";
import "./Newsletter.css";
import { Link } from "react-router-dom";

const Newsletter = () => {
    return (
        <div
            dir="ltr"
            className="es-wrapper-color"
            lang="en"
            style={{ backgroundColor: "#F6F6F6" }}
        >
            {/*[if gte mso 9]>
                  <v:background xmlns:v="urn:schemas-microsoft-com:vml" fill="t">
                      <v:fill type="tile" color="#f6f6f6"></v:fill>
                  </v:background>
              <![endif]*/}
            <table
                className="es-wrapper"
                width="100%"
                cellSpacing={0}
                cellPadding={0}
                role="none"
                style={{
                    msoTableLspace: "0pt",
                    msoTableRspace: "0pt",
                    borderCollapse: "collapse",
                    borderSpacing: 0,
                    padding: 0,
                    margin: 0,
                    width: "100%",
                    height: "100%",
                    backgroundRepeat: "repeat",
                    backgroundPosition: "center top",
                    backgroundColor: "#F6F6F6",
                }}
            >
                <tbody>
                    <tr>
                        <td valign="top" style={{ padding: 0, margin: 0 }}>
                            <table
                                cellPadding={0}
                                cellSpacing={0}
                                className="es-header"
                                align="center"
                                role="none"
                                style={{
                                    msoTableLspace: "0pt",
                                    msoTableRspace: "0pt",
                                    borderCollapse: "collapse",
                                    borderSpacing: 0,
                                    tableLayout: "fixed !important",
                                    width: "100%",
                                    backgroundColor: "transparent",
                                    backgroundRepeat: "repeat",
                                    backgroundPosition: "center top",
                                }}
                            >
                                <tbody>
                                    <tr>
                                        <td
                                            align="center"
                                            bgcolor="transparent"
                                            style={{ padding: 0, margin: 0 }}
                                        >
                                            <table
                                                cellPadding={0}
                                                cellSpacing={0}
                                                bgcolor="#ffffff"
                                                align="center"
                                                className="es-header-body"
                                                role="none"
                                                style={{
                                                    msoTableLspace: "0pt",
                                                    msoTableRspace: "0pt",
                                                    borderCollapse: "collapse",
                                                    borderSpacing: 0,
                                                    backgroundColor: "#FFFFFF",
                                                    width: 600,
                                                }}
                                            >
                                                <tbody>
                                                    <tr>
                                                        <td
                                                            align="left"
                                                            style={{
                                                                padding: 0,
                                                                margin: 0,
                                                                paddingLeft: 20,
                                                                paddingRight: 20,
                                                            }}
                                                        >
                                                            <table
                                                                width="100%"
                                                                cellPadding={0}
                                                                cellSpacing={0}
                                                                role="none"
                                                                style={{
                                                                    msoTableLspace:
                                                                        "0pt",
                                                                    msoTableRspace:
                                                                        "0pt",
                                                                    borderCollapse:
                                                                        "collapse",
                                                                    borderSpacing: 0,
                                                                }}
                                                            >
                                                                <tbody>
                                                                    <tr>
                                                                        <td
                                                                            align="left"
                                                                            style={{
                                                                                padding: 0,
                                                                                margin: 0,
                                                                                width: 560,
                                                                            }}
                                                                        >
                                                                            <table
                                                                                cellPadding={
                                                                                    0
                                                                                }
                                                                                cellSpacing={
                                                                                    0
                                                                                }
                                                                                width="100%"
                                                                                role="presentation"
                                                                                style={{
                                                                                    msoTableLspace:
                                                                                        "0pt",
                                                                                    msoTableRspace:
                                                                                        "0pt",
                                                                                    borderCollapse:
                                                                                        "collapse",
                                                                                    borderSpacing: 0,
                                                                                }}
                                                                            >
                                                                                <tbody>
                                                                                    <tr>
                                                                                        <td
                                                                                            align="center"
                                                                                            style={{
                                                                                                padding: 0,
                                                                                                margin: 0,
                                                                                                fontSize: 0,
                                                                                            }}
                                                                                        >
                                                                                            <Link to="/">
                                                                                                <img
                                                                                                    alt=""
                                                                                                    src="https://fpreqnb.stripocdn.email/content/guids/CABINET_30441bda11fa9f51c9732b24d71252b7a1c39a998fd640cc4eb58ee268981303/images/untitled_368_x_204_px.png"
                                                                                                    className="img-1670"
                                                                                                    style={{
                                                                                                        display:
                                                                                                            "block",
                                                                                                        border: 0,
                                                                                                        outline:
                                                                                                            "none",
                                                                                                        textDecoration:
                                                                                                            "none",
                                                                                                        msInterpolationMode:
                                                                                                            "bicubic",
                                                                                                    }}
                                                                                                    width={
                                                                                                        231
                                                                                                    }
                                                                                                />
                                                                                            </Link>
                                                                                        </td>
                                                                                    </tr>
                                                                                </tbody>
                                                                            </table>
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <table
                                cellPadding={0}
                                cellSpacing={0}
                                className="es-content"
                                align="center"
                                role="none"
                                style={{
                                    msoTableLspace: "0pt",
                                    msoTableRspace: "0pt",
                                    borderCollapse: "collapse",
                                    borderSpacing: 0,
                                    tableLayout: "fixed !important",
                                    width: "100%",
                                }}
                            >
                                <tbody>
                                    <tr>
                                        <td
                                            align="center"
                                            bgcolor="transparent"
                                            style={{ padding: 0, margin: 0 }}
                                        >
                                            <table
                                                cellPadding={0}
                                                cellSpacing={0}
                                                bgcolor="#ffffff"
                                                align="center"
                                                className="es-content-body"
                                                role="none"
                                                style={{
                                                    msoTableLspace: "0pt",
                                                    msoTableRspace: "0pt",
                                                    borderCollapse: "collapse",
                                                    borderSpacing: 0,
                                                    backgroundColor: "#FFFFFF",
                                                    width: 600,
                                                }}
                                            >
                                                <tbody>
                                                    <tr>
                                                        <td
                                                            align="left"
                                                            bgcolor="#e6fbf4"
                                                            style={{
                                                                padding: 0,
                                                                margin: 0,
                                                                backgroundColor:
                                                                    "#e6fbf4",
                                                            }}
                                                        >
                                                            <table
                                                                cellPadding={0}
                                                                cellSpacing={0}
                                                                width="100%"
                                                                role="none"
                                                                style={{
                                                                    msoTableLspace:
                                                                        "0pt",
                                                                    msoTableRspace:
                                                                        "0pt",
                                                                    borderCollapse:
                                                                        "collapse",
                                                                    borderSpacing: 0,
                                                                }}
                                                            >
                                                                <tbody>
                                                                    <tr>
                                                                        <td
                                                                            align="left"
                                                                            style={{
                                                                                padding: 0,
                                                                                margin: 0,
                                                                                width: 600,
                                                                            }}
                                                                        >
                                                                            <table
                                                                                cellPadding={
                                                                                    0
                                                                                }
                                                                                cellSpacing={
                                                                                    0
                                                                                }
                                                                                width="100%"
                                                                                role="presentation"
                                                                                style={{
                                                                                    msoTableLspace:
                                                                                        "0pt",
                                                                                    msoTableRspace:
                                                                                        "0pt",
                                                                                    borderCollapse:
                                                                                        "collapse",
                                                                                    borderSpacing: 0,
                                                                                }}
                                                                            >
                                                                                <tbody>
                                                                                    <tr>
                                                                                        <td
                                                                                            align="left"
                                                                                            bgcolor="#0E6153"
                                                                                            style={{
                                                                                                padding: 5,
                                                                                                margin: 0,
                                                                                            }}
                                                                                        >
                                                                                            <p
                                                                                                style={{
                                                                                                    margin: 0,
                                                                                                    WebkitTextSizeAdjust:
                                                                                                        "none",
                                                                                                    msTextSizeAdjust:
                                                                                                        "none",
                                                                                                    msoLineHeightRule:
                                                                                                        "exactly",
                                                                                                    fontFamily:
                                                                                                        'arial, "helvetica neue", helvetica, sans-serif',
                                                                                                    // // lineHeight 30,
                                                                                                    color: "#ffebae",
                                                                                                    fontSize: 20,
                                                                                                }}
                                                                                            >
                                                                                                <b>
                                                                                                    Empowering
                                                                                                    Story:
                                                                                                    Sohel
                                                                                                    Rana
                                                                                                    –
                                                                                                    Earning
                                                                                                    BDT
                                                                                                    40,000
                                                                                                    Through
                                                                                                    AyyKori{" "}
                                                                                                </b>
                                                                                            </p>
                                                                                        </td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td
                                                                                            align="left"
                                                                                            style={{
                                                                                                padding: 0,
                                                                                                margin: 0,
                                                                                                paddingLeft: 5,
                                                                                            }}
                                                                                        >
                                                                                            <b>
                                                                                                <u />
                                                                                            </b>
                                                                                            <p
                                                                                                style={{
                                                                                                    margin: 0,
                                                                                                    WebkitTextSizeAdjust:
                                                                                                        "none",
                                                                                                    msTextSizeAdjust:
                                                                                                        "none",
                                                                                                    msoLineHeightRule:
                                                                                                        "exactly",
                                                                                                    fontFamily:
                                                                                                        'arial, "helvetica neue", helvetica, sans-serif',
                                                                                                    // // lineHeight 21,
                                                                                                    color: "#333333",
                                                                                                    fontSize: 14,
                                                                                                }}
                                                                                            >
                                                                                                <b>
                                                                                                    Sohel
                                                                                                    Rana
                                                                                                </b>

                                                                                                ,
                                                                                                a
                                                                                                determined
                                                                                                individual
                                                                                                from
                                                                                                rural
                                                                                                Bangladesh,
                                                                                                changed
                                                                                                his
                                                                                                life
                                                                                                through{" "}
                                                                                                <b>
                                                                                                    affiliate
                                                                                                    marketing
                                                                                                    with
                                                                                                    AyyKori
                                                                                                </b>

                                                                                                .
                                                                                                Facing
                                                                                                limited
                                                                                                income
                                                                                                opportunities
                                                                                                in
                                                                                                his
                                                                                                community,
                                                                                                Sohel
                                                                                                was
                                                                                                searching
                                                                                                for
                                                                                                a
                                                                                                way
                                                                                                to
                                                                                                support
                                                                                                his
                                                                                                family
                                                                                                without
                                                                                                significant
                                                                                                investment.
                                                                                                That’s
                                                                                                when
                                                                                                he
                                                                                                discovered
                                                                                                AyyKori’s{" "}
                                                                                                <b>
                                                                                                    cashback
                                                                                                    and
                                                                                                    affiliate
                                                                                                    marketing
                                                                                                    platform
                                                                                                </b>
                                                                                                —a
                                                                                                game-changer
                                                                                                for
                                                                                                someone
                                                                                                with
                                                                                                Sohel's
                                                                                                ambitions.
                                                                                            </p>
                                                                                            <p
                                                                                                style={{
                                                                                                    margin: 0,
                                                                                                    WebkitTextSizeAdjust:
                                                                                                        "none",
                                                                                                    msTextSizeAdjust:
                                                                                                        "none",
                                                                                                    msoLineHeightRule:
                                                                                                        "exactly",
                                                                                                    fontFamily:
                                                                                                        'arial, "helvetica neue", helvetica, sans-serif',
                                                                                                    // // lineHeight 21,
                                                                                                    color: "#333333",
                                                                                                    fontSize: 14,
                                                                                                }}
                                                                                            >
                                                                                                Sohel
                                                                                                began
                                                                                                his
                                                                                                journey
                                                                                                by
                                                                                                promoting
                                                                                                products
                                                                                                from
                                                                                                AyyKori’s
                                                                                                partner
                                                                                                brands
                                                                                                on
                                                                                                social
                                                                                                media.
                                                                                                With
                                                                                                dedication
                                                                                                and
                                                                                                strategic
                                                                                                outreach,
                                                                                                he
                                                                                                quickly
                                                                                                built
                                                                                                a
                                                                                                strong
                                                                                                affiliate
                                                                                                network.
                                                                                                His
                                                                                                efforts
                                                                                                paid
                                                                                                off—within
                                                                                                a
                                                                                                few
                                                                                                months,
                                                                                                Sohel
                                                                                                earned{" "}
                                                                                                <b>
                                                                                                    BDT
                                                                                                    40,000
                                                                                                </b>{" "}
                                                                                                in
                                                                                                commissions,
                                                                                                a
                                                                                                remarkable
                                                                                                achievement
                                                                                                that
                                                                                                brought
                                                                                                both{" "}
                                                                                                <b>
                                                                                                    financial
                                                                                                    stability
                                                                                                    and
                                                                                                    confidence
                                                                                                </b>

                                                                                                .
                                                                                            </p>
                                                                                            <p
                                                                                                style={{
                                                                                                    margin: 0,
                                                                                                    WebkitTextSizeAdjust:
                                                                                                        "none",
                                                                                                    msTextSizeAdjust:
                                                                                                        "none",
                                                                                                    msoLineHeightRule:
                                                                                                        "exactly",
                                                                                                    fontFamily:
                                                                                                        'arial, "helvetica neue", helvetica, sans-serif',
                                                                                                    // lineHeight 21,
                                                                                                    color: "#333333",
                                                                                                    fontSize: 14,
                                                                                                }}
                                                                                            >
                                                                                                This
                                                                                                success
                                                                                                story
                                                                                                highlights
                                                                                                how{" "}
                                                                                                <b>
                                                                                                    AyyKori’s
                                                                                                    accessible
                                                                                                    platform
                                                                                                </b>{" "}
                                                                                                empowers
                                                                                                individuals,
                                                                                                especially
                                                                                                from
                                                                                                underserved
                                                                                                communities,
                                                                                                to
                                                                                                earn
                                                                                                income
                                                                                                without
                                                                                                upfront
                                                                                                costs.
                                                                                                Today,
                                                                                                Sohel
                                                                                                is
                                                                                                not
                                                                                                only
                                                                                                supporting
                                                                                                his
                                                                                                family
                                                                                                but
                                                                                                also
                                                                                                inspiring
                                                                                                others
                                                                                                in
                                                                                                his
                                                                                                community
                                                                                                to
                                                                                                explore
                                                                                                digital
                                                                                                opportunities.
                                                                                                His
                                                                                                journey
                                                                                                is
                                                                                                a
                                                                                                testament
                                                                                                to
                                                                                                the
                                                                                                power
                                                                                                of{" "}
                                                                                                <b>
                                                                                                    resilience,
                                                                                                    smart
                                                                                                    work,
                                                                                                    and
                                                                                                    digital
                                                                                                    inclusion
                                                                                                </b>

                                                                                                .
                                                                                            </p>
                                                                                            <p
                                                                                                style={{
                                                                                                    margin: 0,
                                                                                                    WebkitTextSizeAdjust:
                                                                                                        "none",
                                                                                                    msTextSizeAdjust:
                                                                                                        "none",
                                                                                                    msoLineHeightRule:
                                                                                                        "exactly",
                                                                                                    fontFamily:
                                                                                                        'arial, "helvetica neue", helvetica, sans-serif',
                                                                                                    // lineHeight 21,
                                                                                                    color: "#333333",
                                                                                                    fontSize: 14,
                                                                                                }}
                                                                                            >
                                                                                                Through
                                                                                                stories
                                                                                                like
                                                                                                Sohel’s,
                                                                                                AyyKori
                                                                                                continues
                                                                                                to
                                                                                                foster{" "}
                                                                                                <b>
                                                                                                    financial
                                                                                                    independence
                                                                                                    and
                                                                                                    economic
                                                                                                    empowerment
                                                                                                </b>{" "}
                                                                                                for
                                                                                                users
                                                                                                across
                                                                                                Bangladesh.
                                                                                                <b>
                                                                                                    <u>
                                                                                                        {" "}
                                                                                                    </u>
                                                                                                </b>
                                                                                            </p>
                                                                                        </td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td
                                                                                            align="center"
                                                                                            style={{
                                                                                                padding: 0,
                                                                                                margin: 0,
                                                                                                fontSize: 0,
                                                                                            }}
                                                                                        >
                                                                                            <img
                                                                                                className="adapt-img"
                                                                                                src="https://fpreqnb.stripocdn.email/content/guids/CABINET_30441bda11fa9f51c9732b24d71252b7a1c39a998fd640cc4eb58ee268981303/images/untitled_368_x_204_px_3.png"
                                                                                                alt=""
                                                                                                style={{
                                                                                                    display:
                                                                                                        "block",
                                                                                                    border: 0,
                                                                                                    outline:
                                                                                                        "none",
                                                                                                    textDecoration:
                                                                                                        "none",
                                                                                                    msInterpolationMode:
                                                                                                        "bicubic",
                                                                                                }}
                                                                                                width={
                                                                                                    600
                                                                                                }
                                                                                            />
                                                                                        </td>
                                                                                    </tr>
                                                                                </tbody>
                                                                            </table>
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td
                                                            align="left"
                                                            bgcolor="#0E6153"
                                                            style={{
                                                                padding: 0,
                                                                margin: 0,
                                                                paddingTop: 5,
                                                                paddingBottom: 5,
                                                                paddingLeft: 5,
                                                                backgroundColor:
                                                                    "#0e6153",
                                                            }}
                                                        >
                                                            <table
                                                                cellPadding={0}
                                                                cellSpacing={0}
                                                                width="100%"
                                                                role="none"
                                                                style={{
                                                                    msoTableLspace:
                                                                        "0pt",
                                                                    msoTableRspace:
                                                                        "0pt",
                                                                    borderCollapse:
                                                                        "collapse",
                                                                    borderSpacing: 0,
                                                                }}
                                                            >
                                                                <tbody>
                                                                    <tr>
                                                                        <td
                                                                            align="center"
                                                                            valign="top"
                                                                            style={{
                                                                                padding: 0,
                                                                                margin: 0,
                                                                                width: 595,
                                                                            }}
                                                                        >
                                                                            <table
                                                                                cellPadding={
                                                                                    0
                                                                                }
                                                                                cellSpacing={
                                                                                    0
                                                                                }
                                                                                width="100%"
                                                                                role="presentation"
                                                                                style={{
                                                                                    msoTableLspace:
                                                                                        "0pt",
                                                                                    msoTableRspace:
                                                                                        "0pt",
                                                                                    borderCollapse:
                                                                                        "collapse",
                                                                                    borderSpacing: 0,
                                                                                }}
                                                                            >
                                                                                <tbody>
                                                                                    <tr>
                                                                                        <td
                                                                                            align="left"
                                                                                            bgcolor="#0E6153"
                                                                                            style={{
                                                                                                padding: 0,
                                                                                                margin: 0,
                                                                                            }}
                                                                                        >
                                                                                            <p
                                                                                                style={{
                                                                                                    margin: 0,
                                                                                                    WebkitTextSizeAdjust:
                                                                                                        "none",
                                                                                                    msTextSizeAdjust:
                                                                                                        "none",
                                                                                                    msoLineHeightRule:
                                                                                                        "exactly",
                                                                                                    fontFamily:
                                                                                                        'arial, "helvetica neue", helvetica, sans-serif',
                                                                                                    // lineHeight 30,
                                                                                                    color: "#ffebae",
                                                                                                    fontSize: 20,
                                                                                                }}
                                                                                            >
                                                                                                <b>
                                                                                                    Empowering
                                                                                                    Women
                                                                                                    Through
                                                                                                    Affiliate
                                                                                                    Marketing
                                                                                                    –
                                                                                                    AyyKori’s
                                                                                                    Collaboration
                                                                                                    with
                                                                                                    BNPS
                                                                                                </b>
                                                                                            </p>
                                                                                        </td>
                                                                                    </tr>
                                                                                </tbody>
                                                                            </table>
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td
                                                            align="left"
                                                            bgcolor="#259582"
                                                            style={{
                                                                margin: 0,
                                                                paddingTop: 5,
                                                                paddingBottom: 5,
                                                                paddingRight: 5,
                                                                paddingLeft: 10,
                                                                backgroundColor:
                                                                    "#259582",
                                                            }}
                                                        >
                                                            <table
                                                                cellPadding={0}
                                                                cellSpacing={0}
                                                                width="100%"
                                                                role="none"
                                                                style={{
                                                                    msoTableLspace:
                                                                        "0pt",
                                                                    msoTableRspace:
                                                                        "0pt",
                                                                    borderCollapse:
                                                                        "collapse",
                                                                    borderSpacing: 0,
                                                                }}
                                                            >
                                                                <tbody>
                                                                    <tr>
                                                                        <td
                                                                            align="center"
                                                                            valign="top"
                                                                            style={{
                                                                                padding: 0,
                                                                                margin: 0,
                                                                                width: 585,
                                                                            }}
                                                                        >
                                                                            <table
                                                                                cellPadding={
                                                                                    0
                                                                                }
                                                                                cellSpacing={
                                                                                    0
                                                                                }
                                                                                width="100%"
                                                                                role="presentation"
                                                                                style={{
                                                                                    msoTableLspace:
                                                                                        "0pt",
                                                                                    msoTableRspace:
                                                                                        "0pt",
                                                                                    borderCollapse:
                                                                                        "collapse",
                                                                                    borderSpacing: 0,
                                                                                }}
                                                                            >
                                                                                <tbody>
                                                                                    <tr>
                                                                                        <td
                                                                                            align="left"
                                                                                            bgcolor="#259582"
                                                                                            style={{
                                                                                                padding: 0,
                                                                                                margin: 0,
                                                                                            }}
                                                                                        >
                                                                                            <p
                                                                                                style={{
                                                                                                    margin: 0,
                                                                                                    WebkitTextSizeAdjust:
                                                                                                        "none",
                                                                                                    msTextSizeAdjust:
                                                                                                        "none",
                                                                                                    msoLineHeightRule:
                                                                                                        "exactly",
                                                                                                    fontFamily:
                                                                                                        'arial, "helvetica neue", helvetica, sans-serif',
                                                                                                    // lineHeight 21,
                                                                                                    color: "#ffffff",
                                                                                                    fontSize: 14,
                                                                                                }}
                                                                                            >
                                                                                                AyyKori
                                                                                                Digital
                                                                                                Limited
                                                                                                is
                                                                                                proud
                                                                                                to
                                                                                                announce
                                                                                                the
                                                                                                launch
                                                                                                of
                                                                                                a{" "}
                                                                                                <b>
                                                                                                    free
                                                                                                    Affiliate
                                                                                                    Marketing
                                                                                                    Training
                                                                                                    Program
                                                                                                </b>{" "}
                                                                                                in
                                                                                                partnership
                                                                                                with{" "}
                                                                                                <b>
                                                                                                    Bangladeshi
                                                                                                    Nari
                                                                                                    Pragati
                                                                                                    Sangha
                                                                                                    (BNPS)
                                                                                                </b>

                                                                                                .
                                                                                                Since
                                                                                                1990,
                                                                                                BNPS
                                                                                                has
                                                                                                been
                                                                                                committed
                                                                                                to{" "}
                                                                                                <b>
                                                                                                    women’s
                                                                                                    empowerment
                                                                                                </b>

                                                                                                ,
                                                                                                focusing
                                                                                                on
                                                                                                addressing
                                                                                                challenges
                                                                                                like{" "}
                                                                                                <b>
                                                                                                    domestic
                                                                                                    violence,
                                                                                                    poverty,
                                                                                                    and
                                                                                                    social
                                                                                                    discrimination
                                                                                                </b>

                                                                                                .
                                                                                                This
                                                                                                partnership
                                                                                                marks
                                                                                                a
                                                                                                significant
                                                                                                milestone
                                                                                                for
                                                                                                both
                                                                                                organizations,
                                                                                                aiming
                                                                                                to
                                                                                                empower
                                                                                                women
                                                                                                by
                                                                                                equipping
                                                                                                them
                                                                                                with{" "}
                                                                                                <b>
                                                                                                    digital
                                                                                                    marketing
                                                                                                    skills
                                                                                                </b>{" "}
                                                                                                to
                                                                                                achieve{" "}
                                                                                                <b>
                                                                                                    financial
                                                                                                    independence
                                                                                                </b>

                                                                                                .
                                                                                            </p>
                                                                                        </td>
                                                                                    </tr>
                                                                                </tbody>
                                                                            </table>
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td
                                                            align="left"
                                                            bgcolor="#d8eadc"
                                                            style={{
                                                                padding: 0,
                                                                margin: 0,
                                                                paddingTop: 20,
                                                                paddingLeft: 20,
                                                                paddingRight: 20,
                                                                backgroundColor:
                                                                    "#d8eadc",
                                                            }}
                                                        >
                                                            {/*[if mso]><table style="width:560px" cellpadding="0" cellspacing="0"><tr><td style="width:271px" valign="top"><![endif]*/}
                                                            <table
                                                                align="left"
                                                                cellPadding={0}
                                                                cellSpacing={0}
                                                                className="es-left"
                                                                role="none"
                                                                style={{
                                                                    msoTableLspace:
                                                                        "0pt",
                                                                    msoTableRspace:
                                                                        "0pt",
                                                                    borderCollapse:
                                                                        "collapse",
                                                                    borderSpacing: 0,
                                                                    float: "left",
                                                                }}
                                                            >
                                                                <tbody>
                                                                    <tr>
                                                                        <td
                                                                            align="left"
                                                                            className="es-m-p20b"
                                                                            style={{
                                                                                padding: 0,
                                                                                margin: 0,
                                                                                width: 271,
                                                                            }}
                                                                        >
                                                                            <table
                                                                                cellPadding={
                                                                                    0
                                                                                }
                                                                                cellSpacing={
                                                                                    0
                                                                                }
                                                                                width="100%"
                                                                                role="presentation"
                                                                                style={{
                                                                                    msoTableLspace:
                                                                                        "0pt",
                                                                                    msoTableRspace:
                                                                                        "0pt",
                                                                                    borderCollapse:
                                                                                        "collapse",
                                                                                    borderSpacing: 0,
                                                                                }}
                                                                            >
                                                                                <tbody>
                                                                                    <tr>
                                                                                        <td
                                                                                            align="left"
                                                                                            style={{
                                                                                                padding: 0,
                                                                                                margin: 0,
                                                                                            }}
                                                                                        >
                                                                                            <p
                                                                                                style={{
                                                                                                    margin: 0,
                                                                                                    WebkitTextSizeAdjust:
                                                                                                        "none",
                                                                                                    msTextSizeAdjust:
                                                                                                        "none",
                                                                                                    msoLineHeightRule:
                                                                                                        "exactly",
                                                                                                    fontFamily:
                                                                                                        'arial, "helvetica neue", helvetica, sans-serif',
                                                                                                    // lineHeight 30,
                                                                                                    color: "#333333",
                                                                                                    fontSize: 14,
                                                                                                }}
                                                                                            >
                                                                                                <b>
                                                                                                    📈{" "}
                                                                                                    <span
                                                                                                        style={{
                                                                                                            fontSize: 20,
                                                                                                        }}
                                                                                                    >
                                                                                                        Transforming
                                                                                                        Lives
                                                                                                        Through
                                                                                                        Skills
                                                                                                        Development
                                                                                                    </span>
                                                                                                </b>
                                                                                            </p>
                                                                                            <p
                                                                                                style={{
                                                                                                    margin: 0,
                                                                                                    WebkitTextSizeAdjust:
                                                                                                        "none",
                                                                                                    msTextSizeAdjust:
                                                                                                        "none",
                                                                                                    msoLineHeightRule:
                                                                                                        "exactly",
                                                                                                    fontFamily:
                                                                                                        'arial, "helvetica neue", helvetica, sans-serif',
                                                                                                    // lineHeight 30,
                                                                                                    color: "#333333",
                                                                                                    fontSize: 14,
                                                                                                }}
                                                                                            >
                                                                                                <b
                                                                                                    style={{
                                                                                                        fontSize: 20,
                                                                                                    }}
                                                                                                />
                                                                                                <br />
                                                                                            </p>
                                                                                            <p
                                                                                                style={{
                                                                                                    margin: 0,
                                                                                                    WebkitTextSizeAdjust:
                                                                                                        "none",
                                                                                                    msTextSizeAdjust:
                                                                                                        "none",
                                                                                                    msoLineHeightRule:
                                                                                                        "exactly",
                                                                                                    fontFamily:
                                                                                                        'arial, "helvetica neue", helvetica, sans-serif',
                                                                                                    // lineHeight 21,
                                                                                                    color: "#333333",
                                                                                                    fontSize: 14,
                                                                                                }}
                                                                                            >
                                                                                                Our{" "}
                                                                                                <b>
                                                                                                    Affiliate
                                                                                                    Marketing
                                                                                                    Training
                                                                                                    Program
                                                                                                </b>

                                                                                                ,
                                                                                                launched
                                                                                                on{" "}
                                                                                                <b>
                                                                                                    October
                                                                                                    25,
                                                                                                    2024
                                                                                                </b>

                                                                                                ,
                                                                                                offers
                                                                                                participants
                                                                                                essential
                                                                                                digital
                                                                                                skills
                                                                                                such
                                                                                                as{" "}
                                                                                                <b>
                                                                                                    SEO,
                                                                                                    content
                                                                                                    marketing
                                                                                                </b>

                                                                                                ,
                                                                                                and{" "}
                                                                                                <b>
                                                                                                    affiliate
                                                                                                    marketing
                                                                                                    strategies
                                                                                                </b>

                                                                                                .
                                                                                                These
                                                                                                skills
                                                                                                will
                                                                                                enable
                                                                                                women
                                                                                                to
                                                                                                build
                                                                                                independent
                                                                                                income
                                                                                                streams
                                                                                                without
                                                                                                any
                                                                                                upfront
                                                                                                investment.
                                                                                                With
                                                                                                flexible
                                                                                                online
                                                                                                learning
                                                                                                options
                                                                                                via{" "}
                                                                                                <b>
                                                                                                    Google
                                                                                                    Classroom
                                                                                                    and
                                                                                                    Zoom
                                                                                                </b>

                                                                                                ,
                                                                                                participants
                                                                                                can{" "}
                                                                                                <b>
                                                                                                    learn
                                                                                                    at
                                                                                                    their
                                                                                                    own
                                                                                                    pace
                                                                                                </b>{" "}
                                                                                                and
                                                                                                apply
                                                                                                their
                                                                                                knowledge
                                                                                                in
                                                                                                real-world
                                                                                                scenarios.
                                                                                            </p>
                                                                                            <h3
                                                                                                style={{
                                                                                                    margin: 0,
                                                                                                    // lineHeight 24,
                                                                                                    msoLineHeightRule:
                                                                                                        "exactly",
                                                                                                    fontFamily:
                                                                                                        'arial, "helvetica neue", helvetica, sans-serif',
                                                                                                    fontSize: 20,
                                                                                                    fontStyle:
                                                                                                        "normal",
                                                                                                    fontWeight:
                                                                                                        "normal",
                                                                                                    color: "#333333",
                                                                                                }}
                                                                                            >
                                                                                                <br />
                                                                                            </h3>
                                                                                        </td>
                                                                                    </tr>
                                                                                </tbody>
                                                                            </table>
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                            {/*[if mso]></td><td style="width:20px"></td><td style="width:269px" valign="top"><![endif]*/}
                                                            <table
                                                                cellPadding={0}
                                                                cellSpacing={0}
                                                                align="right"
                                                                className="es-right"
                                                                role="none"
                                                                style={{
                                                                    msoTableLspace:
                                                                        "0pt",
                                                                    msoTableRspace:
                                                                        "0pt",
                                                                    borderCollapse:
                                                                        "collapse",
                                                                    borderSpacing: 0,
                                                                    float: "right",
                                                                }}
                                                            >
                                                                <tbody>
                                                                    <tr>
                                                                        <td
                                                                            align="left"
                                                                            style={{
                                                                                padding: 0,
                                                                                margin: 0,
                                                                                width: 269,
                                                                            }}
                                                                        >
                                                                            <table
                                                                                cellPadding={
                                                                                    0
                                                                                }
                                                                                cellSpacing={
                                                                                    0
                                                                                }
                                                                                width="100%"
                                                                                role="presentation"
                                                                                style={{
                                                                                    msoTableLspace:
                                                                                        "0pt",
                                                                                    msoTableRspace:
                                                                                        "0pt",
                                                                                    borderCollapse:
                                                                                        "collapse",
                                                                                    borderSpacing: 0,
                                                                                }}
                                                                            >
                                                                                <tbody>
                                                                                    <tr>
                                                                                        <td
                                                                                            align="left"
                                                                                            style={{
                                                                                                padding: 0,
                                                                                                margin: 0,
                                                                                            }}
                                                                                        >
                                                                                            <h3
                                                                                                style={{
                                                                                                    margin: 0,
                                                                                                    // lineHeight 24,
                                                                                                    msoLineHeightRule:
                                                                                                        "exactly",
                                                                                                    fontFamily:
                                                                                                        'arial, "helvetica neue", helvetica, sans-serif',
                                                                                                    fontSize: 20,
                                                                                                    fontStyle:
                                                                                                        "normal",
                                                                                                    fontWeight:
                                                                                                        "normal",
                                                                                                    color: "#333333",
                                                                                                }}
                                                                                            >
                                                                                                <b>
                                                                                                    🏆
                                                                                                    Why
                                                                                                    This
                                                                                                    Initiative
                                                                                                    Matters
                                                                                                </b>
                                                                                            </h3>
                                                                                            <p
                                                                                                style={{
                                                                                                    margin: 0,
                                                                                                    WebkitTextSizeAdjust:
                                                                                                        "none",
                                                                                                    msTextSizeAdjust:
                                                                                                        "none",
                                                                                                    msoLineHeightRule:
                                                                                                        "exactly",
                                                                                                    fontFamily:
                                                                                                        'arial, "helvetica neue", helvetica, sans-serif',
                                                                                                    // lineHeight 21,
                                                                                                    color: "#333333",
                                                                                                    fontSize: 14,
                                                                                                }}
                                                                                            >
                                                                                                This
                                                                                                program
                                                                                                is
                                                                                                more
                                                                                                than
                                                                                                just
                                                                                                a
                                                                                                course—it’s
                                                                                                a{" "}
                                                                                                <b>
                                                                                                    pathway
                                                                                                    to
                                                                                                    self-sufficiency
                                                                                                </b>

                                                                                                .
                                                                                                Participants
                                                                                                will
                                                                                                gain
                                                                                                the
                                                                                                confidence
                                                                                                and
                                                                                                tools
                                                                                                to{" "}
                                                                                                <b>
                                                                                                    navigate
                                                                                                    the
                                                                                                    digital
                                                                                                    world
                                                                                                </b>

                                                                                                ,
                                                                                                unlock{" "}
                                                                                                <b>
                                                                                                    income
                                                                                                    opportunities
                                                                                                </b>

                                                                                                ,
                                                                                                and
                                                                                                build{" "}
                                                                                                <b>
                                                                                                    long-term
                                                                                                    careers
                                                                                                </b>{" "}
                                                                                                in
                                                                                                affiliate
                                                                                                marketing.
                                                                                            </p>
                                                                                            <p
                                                                                                style={{
                                                                                                    margin: 0,
                                                                                                    WebkitTextSizeAdjust:
                                                                                                        "none",
                                                                                                    msTextSizeAdjust:
                                                                                                        "none",
                                                                                                    msoLineHeightRule:
                                                                                                        "exactly",
                                                                                                    fontFamily:
                                                                                                        'arial, "helvetica neue", helvetica, sans-serif',
                                                                                                    // lineHeight 21,
                                                                                                    color: "#333333",
                                                                                                    fontSize: 14,
                                                                                                }}
                                                                                            >
                                                                                                Our
                                                                                                goal
                                                                                                is
                                                                                                to{" "}
                                                                                                <b>
                                                                                                    train
                                                                                                    and
                                                                                                    uplift
                                                                                                    women
                                                                                                </b>{" "}
                                                                                                who
                                                                                                have
                                                                                                faced
                                                                                                various
                                                                                                social
                                                                                                and
                                                                                                economic
                                                                                                barriers,
                                                                                                opening
                                                                                                doors
                                                                                                to{" "}
                                                                                                <b>
                                                                                                    new
                                                                                                    career
                                                                                                    possibilities
                                                                                                </b>

                                                                                                .
                                                                                                Each
                                                                                                success
                                                                                                story
                                                                                                from
                                                                                                this
                                                                                                initiative
                                                                                                strengthens
                                                                                                our
                                                                                                shared
                                                                                                mission
                                                                                                of{" "}
                                                                                                <b>
                                                                                                    inclusivity,
                                                                                                    empowerment,
                                                                                                    and
                                                                                                    financial
                                                                                                    transformation
                                                                                                </b>

                                                                                                .
                                                                                            </p>
                                                                                        </td>
                                                                                    </tr>
                                                                                </tbody>
                                                                            </table>
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                            {/*[if mso]></td></tr></table><![endif]*/}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td
                                                            align="left"
                                                            bgcolor="#d8eadc"
                                                            style={{
                                                                padding: 0,
                                                                margin: 0,
                                                                paddingTop: 5,
                                                                paddingLeft: 5,
                                                                paddingRight: 5,
                                                                backgroundColor:
                                                                    "#d8eadc",
                                                            }}
                                                        >
                                                            <table
                                                                cellPadding={0}
                                                                cellSpacing={0}
                                                                width="100%"
                                                                role="none"
                                                                style={{
                                                                    msoTableLspace:
                                                                        "0pt",
                                                                    msoTableRspace:
                                                                        "0pt",
                                                                    borderCollapse:
                                                                        "collapse",
                                                                    borderSpacing: 0,
                                                                }}
                                                            >
                                                                <tbody>
                                                                    <tr>
                                                                        <td
                                                                            align="center"
                                                                            valign="top"
                                                                            style={{
                                                                                padding: 0,
                                                                                margin: 0,
                                                                                width: 590,
                                                                            }}
                                                                        >
                                                                            <table
                                                                                cellPadding={
                                                                                    0
                                                                                }
                                                                                cellSpacing={
                                                                                    0
                                                                                }
                                                                                width="100%"
                                                                                role="presentation"
                                                                                style={{
                                                                                    msoTableLspace:
                                                                                        "0pt",
                                                                                    msoTableRspace:
                                                                                        "0pt",
                                                                                    borderCollapse:
                                                                                        "collapse",
                                                                                    borderSpacing: 0,
                                                                                }}
                                                                            >
                                                                                <tbody>
                                                                                    <tr>
                                                                                        <td
                                                                                            align="left"
                                                                                            bgcolor="#d8eadc"
                                                                                            style={{
                                                                                                padding: 0,
                                                                                                margin: 0,
                                                                                                paddingLeft: 5,
                                                                                            }}
                                                                                        >
                                                                                            <h3
                                                                                                style={{
                                                                                                    margin: 0,
                                                                                                    // lineHeight 24,
                                                                                                    msoLineHeightRule:
                                                                                                        "exactly",
                                                                                                    fontFamily:
                                                                                                        'arial, "helvetica neue", helvetica, sans-serif',
                                                                                                    fontSize: 20,
                                                                                                    fontStyle:
                                                                                                        "normal",
                                                                                                    fontWeight:
                                                                                                        "normal",
                                                                                                    color: "#333333",
                                                                                                }}
                                                                                            >
                                                                                                <b>
                                                                                                    🌍
                                                                                                    Building
                                                                                                    a
                                                                                                    Better
                                                                                                    Future
                                                                                                    Together
                                                                                                </b>
                                                                                            </h3>
                                                                                            <p
                                                                                                style={{
                                                                                                    margin: 0,
                                                                                                    WebkitTextSizeAdjust:
                                                                                                        "none",
                                                                                                    msTextSizeAdjust:
                                                                                                        "none",
                                                                                                    msoLineHeightRule:
                                                                                                        "exactly",
                                                                                                    fontFamily:
                                                                                                        'arial, "helvetica neue", helvetica, sans-serif',
                                                                                                    // lineHeight 21,
                                                                                                    color: "#333333",
                                                                                                    fontSize: 14,
                                                                                                }}
                                                                                            >
                                                                                                <br />
                                                                                            </p>
                                                                                            <p
                                                                                                style={{
                                                                                                    margin: 0,
                                                                                                    WebkitTextSizeAdjust:
                                                                                                        "none",
                                                                                                    msTextSizeAdjust:
                                                                                                        "none",
                                                                                                    msoLineHeightRule:
                                                                                                        "exactly",
                                                                                                    fontFamily:
                                                                                                        'arial, "helvetica neue", helvetica, sans-serif',
                                                                                                    // lineHeight 21,
                                                                                                    color: "#333333",
                                                                                                    fontSize: 14,
                                                                                                }}
                                                                                            >
                                                                                                Through
                                                                                                this
                                                                                                collaboration,
                                                                                                AyyKori
                                                                                                and
                                                                                                BNPS
                                                                                                are
                                                                                                creating
                                                                                                a
                                                                                                supportive
                                                                                                environment
                                                                                                for
                                                                                                women
                                                                                                to{" "}
                                                                                                <b>
                                                                                                    thrive
                                                                                                </b>{" "}
                                                                                                and{" "}
                                                                                                <b>
                                                                                                    lead
                                                                                                    meaningful
                                                                                                    lives
                                                                                                </b>

                                                                                                .
                                                                                                As
                                                                                                we
                                                                                                continue
                                                                                                to
                                                                                                grow
                                                                                                this
                                                                                                partnership,
                                                                                                we
                                                                                                look
                                                                                                forward
                                                                                                to
                                                                                                witnessing
                                                                                                the
                                                                                                impact
                                                                                                these
                                                                                                empowered
                                                                                                women
                                                                                                will
                                                                                                bring
                                                                                                to
                                                                                                their
                                                                                                communities
                                                                                                and
                                                                                                beyond.
                                                                                            </p>
                                                                                        </td>
                                                                                    </tr>
                                                                                </tbody>
                                                                            </table>
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td
                                                            align="left"
                                                            bgcolor="#0E6153"
                                                            style={{
                                                                padding: 0,
                                                                margin: 0,
                                                                paddingTop: 5,
                                                                paddingBottom: 5,
                                                                paddingLeft: 5,
                                                                backgroundColor:
                                                                    "#0e6153",
                                                            }}
                                                        >
                                                            <table
                                                                cellPadding={0}
                                                                cellSpacing={0}
                                                                width="100%"
                                                                role="none"
                                                                style={{
                                                                    msoTableLspace:
                                                                        "0pt",
                                                                    msoTableRspace:
                                                                        "0pt",
                                                                    borderCollapse:
                                                                        "collapse",
                                                                    borderSpacing: 0,
                                                                }}
                                                            >
                                                                <tbody>
                                                                    <tr>
                                                                        <td
                                                                            align="center"
                                                                            valign="top"
                                                                            style={{
                                                                                padding: 0,
                                                                                margin: 0,
                                                                                width: 595,
                                                                            }}
                                                                        >
                                                                            <table
                                                                                cellPadding={
                                                                                    0
                                                                                }
                                                                                cellSpacing={
                                                                                    0
                                                                                }
                                                                                width="100%"
                                                                                role="presentation"
                                                                                style={{
                                                                                    msoTableLspace:
                                                                                        "0pt",
                                                                                    msoTableRspace:
                                                                                        "0pt",
                                                                                    borderCollapse:
                                                                                        "collapse",
                                                                                    borderSpacing: 0,
                                                                                }}
                                                                            >
                                                                                <tbody>
                                                                                    <tr>
                                                                                        <td
                                                                                            align="left"
                                                                                            style={{
                                                                                                padding: 0,
                                                                                                margin: 0,
                                                                                            }}
                                                                                        >
                                                                                            <p
                                                                                                style={{
                                                                                                    margin: 0,
                                                                                                    WebkitTextSizeAdjust:
                                                                                                        "none",
                                                                                                    msTextSizeAdjust:
                                                                                                        "none",
                                                                                                    msoLineHeightRule:
                                                                                                        "exactly",
                                                                                                    fontFamily:
                                                                                                        'arial, "helvetica neue", helvetica, sans-serif',
                                                                                                    // lineHeight 36,
                                                                                                    color: "#ffebae",
                                                                                                    fontSize: 24,
                                                                                                }}
                                                                                            >
                                                                                                <b>
                                                                                                    Campus
                                                                                                    Ambassador
                                                                                                    Program
                                                                                                    (Cohort
                                                                                                    1)
                                                                                                    Wrap-Up
                                                                                                </b>
                                                                                            </p>
                                                                                        </td>
                                                                                    </tr>
                                                                                </tbody>
                                                                            </table>
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td
                                                            align="left"
                                                            bgcolor="#259582"
                                                            style={{
                                                                margin: 0,
                                                                paddingBottom: 5,
                                                                paddingLeft: 5,
                                                                paddingRight: 10,
                                                                paddingTop: 20,
                                                                backgroundColor:
                                                                    "#259582",
                                                            }}
                                                        >
                                                            {/*[if mso]><table style="width:585px" cellpadding="0" cellspacing="0"><tr><td style="width:324px" valign="top"><![endif]*/}
                                                            <table
                                                                align="left"
                                                                cellPadding={0}
                                                                cellSpacing={0}
                                                                className="es-left"
                                                                role="none"
                                                                style={{
                                                                    msoTableLspace:
                                                                        "0pt",
                                                                    msoTableRspace:
                                                                        "0pt",
                                                                    borderCollapse:
                                                                        "collapse",
                                                                    borderSpacing: 0,
                                                                    float: "left",
                                                                }}
                                                            >
                                                                <tbody>
                                                                    <tr>
                                                                        <td
                                                                            align="left"
                                                                            className="es-m-p20b"
                                                                            style={{
                                                                                padding: 0,
                                                                                margin: 0,
                                                                                width: 324,
                                                                            }}
                                                                        >
                                                                            <table
                                                                                cellSpacing={
                                                                                    0
                                                                                }
                                                                                width="100%"
                                                                                role="presentation"
                                                                                cellPadding={
                                                                                    0
                                                                                }
                                                                                style={{
                                                                                    msoTableLspace:
                                                                                        "0pt",
                                                                                    msoTableRspace:
                                                                                        "0pt",
                                                                                    borderCollapse:
                                                                                        "collapse",
                                                                                    borderSpacing: 0,
                                                                                }}
                                                                            >
                                                                                <tbody>
                                                                                    <tr>
                                                                                        <td
                                                                                            align="left"
                                                                                            className="es-text-8197"
                                                                                            style={{
                                                                                                margin: 0,
                                                                                                paddingLeft: 5,
                                                                                                paddingTop: 10,
                                                                                                paddingBottom: 10,
                                                                                                paddingRight: 30,
                                                                                            }}
                                                                                        >
                                                                                            <p
                                                                                                style={{
                                                                                                    margin: 0,
                                                                                                    WebkitTextSizeAdjust:
                                                                                                        "none",
                                                                                                    msTextSizeAdjust:
                                                                                                        "none",
                                                                                                    msoLineHeightRule:
                                                                                                        "exactly",
                                                                                                    fontFamily:
                                                                                                        'arial, "helvetica neue", helvetica, sans-serif',
                                                                                                    // lineHeight: "22.5px",
                                                                                                    color: "#ffffff",
                                                                                                    fontSize: 15,
                                                                                                }}
                                                                                            >
                                                                                                AyyKori
                                                                                                Digital
                                                                                                Limited
                                                                                                is
                                                                                                excited
                                                                                                to
                                                                                                celebrate
                                                                                                the{" "}
                                                                                                <b>
                                                                                                    successful
                                                                                                    completion
                                                                                                    of
                                                                                                    the
                                                                                                    first
                                                                                                    cohort
                                                                                                    of
                                                                                                    our
                                                                                                    Campus
                                                                                                    Ambassador
                                                                                                    Program
                                                                                                </b>

                                                                                                !
                                                                                                Designed
                                                                                                to
                                                                                                connect
                                                                                                with
                                                                                                young
                                                                                                leaders
                                                                                                across
                                                                                                universities,
                                                                                                this
                                                                                                initiative
                                                                                                empowered
                                                                                                students
                                                                                                to
                                                                                                explore{" "}
                                                                                                <b>
                                                                                                    affiliate
                                                                                                    marketing
                                                                                                </b>{" "}
                                                                                                while
                                                                                                helping
                                                                                                spread
                                                                                                AyyKori’s
                                                                                                vision
                                                                                                of{" "}
                                                                                                <b>
                                                                                                    financial
                                                                                                    inclusion
                                                                                                    and
                                                                                                    digital
                                                                                                    empowerment
                                                                                                </b>

                                                                                                .
                                                                                            </p>
                                                                                        </td>
                                                                                    </tr>
                                                                                </tbody>
                                                                            </table>
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                            {/*[if mso]></td><td style="width:5px"></td><td style="width:256px" valign="top"><![endif]*/}
                                                            <table
                                                                cellPadding={0}
                                                                cellSpacing={0}
                                                                align="right"
                                                                className="es-right"
                                                                role="none"
                                                                style={{
                                                                    msoTableLspace:
                                                                        "0pt",
                                                                    msoTableRspace:
                                                                        "0pt",
                                                                    borderCollapse:
                                                                        "collapse",
                                                                    borderSpacing: 0,
                                                                    float: "right",
                                                                }}
                                                            >
                                                                <tbody>
                                                                    <tr>
                                                                        <td
                                                                            align="left"
                                                                            style={{
                                                                                padding: 0,
                                                                                margin: 0,
                                                                                width: 256,
                                                                            }}
                                                                        >
                                                                            <table
                                                                                cellPadding={
                                                                                    0
                                                                                }
                                                                                cellSpacing={
                                                                                    0
                                                                                }
                                                                                width="100%"
                                                                                role="presentation"
                                                                                style={{
                                                                                    msoTableLspace:
                                                                                        "0pt",
                                                                                    msoTableRspace:
                                                                                        "0pt",
                                                                                    borderCollapse:
                                                                                        "collapse",
                                                                                    borderSpacing: 0,
                                                                                }}
                                                                            >
                                                                                <tbody>
                                                                                    <tr>
                                                                                        <td
                                                                                            align="center"
                                                                                            style={{
                                                                                                padding: 0,
                                                                                                margin: 0,
                                                                                                fontSize: 0,
                                                                                            }}
                                                                                        >
                                                                                            <img
                                                                                                alt=""
                                                                                                src="https://fpreqnb.stripocdn.email/content/guids/CABINET_30441bda11fa9f51c9732b24d71252b7a1c39a998fd640cc4eb58ee268981303/images/untitled_design_2.png"
                                                                                                className="adapt-img"
                                                                                                style={{
                                                                                                    display:
                                                                                                        "block",
                                                                                                    border: 0,
                                                                                                    outline:
                                                                                                        "none",
                                                                                                    textDecoration:
                                                                                                        "none",
                                                                                                    msInterpolationMode:
                                                                                                        "bicubic",
                                                                                                }}
                                                                                                width={
                                                                                                    256
                                                                                                }
                                                                                            />
                                                                                        </td>
                                                                                    </tr>
                                                                                </tbody>
                                                                            </table>
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                            {/*[if mso]></td></tr></table><![endif]*/}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td
                                                            align="left"
                                                            bgcolor="#d8eadc"
                                                            style={{
                                                                padding: 0,
                                                                margin: 0,
                                                                paddingTop: 5,
                                                                paddingLeft: 10,
                                                                paddingRight: 20,
                                                                backgroundColor:
                                                                    "#d8eadc",
                                                            }}
                                                        >
                                                            <table
                                                                cellPadding={0}
                                                                cellSpacing={0}
                                                                width="100%"
                                                                role="none"
                                                                style={{
                                                                    msoTableLspace:
                                                                        "0pt",
                                                                    msoTableRspace:
                                                                        "0pt",
                                                                    borderCollapse:
                                                                        "collapse",
                                                                    borderSpacing: 0,
                                                                }}
                                                            >
                                                                <tbody>
                                                                    <tr>
                                                                        <td
                                                                            align="center"
                                                                            valign="top"
                                                                            style={{
                                                                                padding: 0,
                                                                                margin: 0,
                                                                                width: 570,
                                                                            }}
                                                                        >
                                                                            <table
                                                                                cellPadding={
                                                                                    0
                                                                                }
                                                                                cellSpacing={
                                                                                    0
                                                                                }
                                                                                width="100%"
                                                                                role="presentation"
                                                                                style={{
                                                                                    msoTableLspace:
                                                                                        "0pt",
                                                                                    msoTableRspace:
                                                                                        "0pt",
                                                                                    borderCollapse:
                                                                                        "collapse",
                                                                                    borderSpacing: 0,
                                                                                }}
                                                                            >
                                                                                <tbody>
                                                                                    <tr>
                                                                                        <td
                                                                                            align="left"
                                                                                            style={{
                                                                                                padding: 0,
                                                                                                margin: 0,
                                                                                            }}
                                                                                        >
                                                                                            <p
                                                                                                style={{
                                                                                                    margin: 0,
                                                                                                    WebkitTextSizeAdjust:
                                                                                                        "none",
                                                                                                    msTextSizeAdjust:
                                                                                                        "none",
                                                                                                    msoLineHeightRule:
                                                                                                        "exactly",
                                                                                                    fontFamily:
                                                                                                        'arial, "helvetica neue", helvetica, sans-serif',
                                                                                                    // lineHeight 21,
                                                                                                    color: "#333333",
                                                                                                    fontSize: 14,
                                                                                                }}
                                                                                            >
                                                                                                Over
                                                                                                the
                                                                                                course
                                                                                                of
                                                                                                this
                                                                                                program,{" "}
                                                                                                <b>
                                                                                                    ambassadors
                                                                                                    from
                                                                                                    multiple
                                                                                                    universities
                                                                                                </b>{" "}
                                                                                                actively
                                                                                                promoted
                                                                                                our
                                                                                                platform,
                                                                                                encouraging
                                                                                                their
                                                                                                peers
                                                                                                to
                                                                                                participate
                                                                                                in
                                                                                                affiliate
                                                                                                marketing.
                                                                                                Their
                                                                                                hard
                                                                                                work
                                                                                                resulted
                                                                                                in
                                                                                                the{" "}
                                                                                                <b>
                                                                                                    onboarding
                                                                                                    of
                                                                                                    hundreds
                                                                                                    of
                                                                                                    new
                                                                                                    users
                                                                                                </b>

                                                                                                ,
                                                                                                driving
                                                                                                increased
                                                                                                awareness
                                                                                                about
                                                                                                how
                                                                                                affiliate
                                                                                                marketing
                                                                                                can
                                                                                                become
                                                                                                a{" "}
                                                                                                <b>
                                                                                                    source
                                                                                                    of
                                                                                                    income
                                                                                                    without
                                                                                                    any
                                                                                                    upfront
                                                                                                    investment
                                                                                                </b>

                                                                                                .
                                                                                            </p>
                                                                                            <p
                                                                                                style={{
                                                                                                    margin: 0,
                                                                                                    WebkitTextSizeAdjust:
                                                                                                        "none",
                                                                                                    msTextSizeAdjust:
                                                                                                        "none",
                                                                                                    msoLineHeightRule:
                                                                                                        "exactly",
                                                                                                    fontFamily:
                                                                                                        'arial, "helvetica neue", helvetica, sans-serif',
                                                                                                    // lineHeight 21,
                                                                                                    color: "#333333",
                                                                                                    fontSize: 14,
                                                                                                }}
                                                                                            >
                                                                                                The
                                                                                                program
                                                                                                provided{" "}
                                                                                                <b>
                                                                                                    real-world
                                                                                                    experience
                                                                                                </b>{" "}
                                                                                                to
                                                                                                students,
                                                                                                offering
                                                                                                them
                                                                                                the
                                                                                                opportunity
                                                                                                to
                                                                                                develop{" "}
                                                                                                <b>
                                                                                                    leadership,
                                                                                                    communication,
                                                                                                    and
                                                                                                    marketing
                                                                                                    skills
                                                                                                </b>

                                                                                                .
                                                                                                Ambassadors
                                                                                                also
                                                                                                participated
                                                                                                in{" "}
                                                                                                <b>
                                                                                                    workshops
                                                                                                    and
                                                                                                    mentorship
                                                                                                    sessions
                                                                                                </b>{" "}
                                                                                                facilitated
                                                                                                by
                                                                                                AyyKori’s
                                                                                                team,
                                                                                                giving
                                                                                                them
                                                                                                insights
                                                                                                into
                                                                                                the
                                                                                                growing
                                                                                                world
                                                                                                of{" "}
                                                                                                <b>
                                                                                                    FinTech
                                                                                                </b>{" "}
                                                                                                and
                                                                                                affiliate
                                                                                                commerce.
                                                                                            </p>
                                                                                            <p
                                                                                                style={{
                                                                                                    margin: 0,
                                                                                                    WebkitTextSizeAdjust:
                                                                                                        "none",
                                                                                                    msTextSizeAdjust:
                                                                                                        "none",
                                                                                                    msoLineHeightRule:
                                                                                                        "exactly",
                                                                                                    fontFamily:
                                                                                                        'arial, "helvetica neue", helvetica, sans-serif',
                                                                                                    // lineHeight 21,
                                                                                                    color: "#333333",
                                                                                                    fontSize: 14,
                                                                                                }}
                                                                                            >
                                                                                                We
                                                                                                are
                                                                                                incredibly
                                                                                                proud
                                                                                                of{" "}
                                                                                                <b>
                                                                                                    Cohort
                                                                                                    1’s
                                                                                                    accomplishments
                                                                                                </b>{" "}
                                                                                                and
                                                                                                look
                                                                                                forward
                                                                                                to
                                                                                                scaling
                                                                                                this
                                                                                                program
                                                                                                even
                                                                                                further,
                                                                                                engaging
                                                                                                more
                                                                                                students
                                                                                                from
                                                                                                across
                                                                                                Bangladesh.
                                                                                                Together,
                                                                                                we
                                                                                                are
                                                                                                shaping
                                                                                                the
                                                                                                future
                                                                                                of
                                                                                                digital
                                                                                                entrepreneurship
                                                                                                and
                                                                                                empowering
                                                                                                youth
                                                                                                to
                                                                                                make
                                                                                                a
                                                                                                lasting
                                                                                                impact.
                                                                                            </p>
                                                                                        </td>
                                                                                    </tr>
                                                                                </tbody>
                                                                            </table>
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td
                                                            align="left"
                                                            bgcolor="#d8eadc"
                                                            style={{
                                                                padding: 0,
                                                                margin: 0,
                                                                paddingTop: 5,
                                                                backgroundColor:
                                                                    "#d8eadc",
                                                            }}
                                                        >
                                                            <table
                                                                width="100%"
                                                                cellPadding={0}
                                                                cellSpacing={0}
                                                                align="right"
                                                                className="es-right"
                                                                role="none"
                                                                style={{
                                                                    msoTableLspace:
                                                                        "0pt",
                                                                    msoTableRspace:
                                                                        "0pt",
                                                                    borderCollapse:
                                                                        "collapse",
                                                                    borderSpacing: 0,
                                                                    float: "right",
                                                                }}
                                                            >
                                                                <tbody>
                                                                    <tr>
                                                                        <td
                                                                            align="left"
                                                                            style={{
                                                                                padding: 0,
                                                                                margin: 0,
                                                                                width: 600,
                                                                            }}
                                                                        >
                                                                            <table
                                                                                cellSpacing={
                                                                                    0
                                                                                }
                                                                                width="100%"
                                                                                role="presentation"
                                                                                cellPadding={
                                                                                    0
                                                                                }
                                                                                style={{
                                                                                    msoTableLspace:
                                                                                        "0pt",
                                                                                    msoTableRspace:
                                                                                        "0pt",
                                                                                    borderCollapse:
                                                                                        "collapse",
                                                                                    borderSpacing: 0,
                                                                                }}
                                                                            >
                                                                                <tbody>
                                                                                    <tr>
                                                                                        <td
                                                                                            align="left"
                                                                                            className="es-text-5745"
                                                                                            bgcolor="#0E6153"
                                                                                            style={{
                                                                                                padding: 0,
                                                                                                margin: 0,
                                                                                                paddingTop: 5,
                                                                                                paddingBottom: 5,
                                                                                                paddingLeft: 5,
                                                                                            }}
                                                                                        >
                                                                                            <h1
                                                                                                className="es-override-size es-text-mobile-size-50"
                                                                                                style={{
                                                                                                    margin: 0,
                                                                                                    // lineHeight: "26.4px !important",
                                                                                                    msoLineHeightRule:
                                                                                                        "exactly",
                                                                                                    fontFamily:
                                                                                                        'arial, "helvetica neue", helvetica, sans-serif',
                                                                                                    fontSize: 22,
                                                                                                    fontStyle:
                                                                                                        "normal",
                                                                                                    fontWeight:
                                                                                                        "normal",
                                                                                                    color: "#ffebae",
                                                                                                }}
                                                                                            >
                                                                                                <b>
                                                                                                    Affiliate
                                                                                                    Marketing
                                                                                                    Masterclass
                                                                                                    Batch
                                                                                                    3
                                                                                                    –
                                                                                                    Completed!
                                                                                                </b>
                                                                                            </h1>
                                                                                        </td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td
                                                                                            align="center"
                                                                                            style={{
                                                                                                padding: 0,
                                                                                                margin: 0,
                                                                                                fontSize: 0,
                                                                                            }}
                                                                                        >
                                                                                            <img
                                                                                                src="https://fpreqnb.stripocdn.email/content/guids/CABINET_30441bda11fa9f51c9732b24d71252b7a1c39a998fd640cc4eb58ee268981303/images/pic.PNG"
                                                                                                alt=""
                                                                                                className="adapt-img"
                                                                                                style={{
                                                                                                    display:
                                                                                                        "block",
                                                                                                    border: 0,
                                                                                                    outline:
                                                                                                        "none",
                                                                                                    textDecoration:
                                                                                                        "none",
                                                                                                    msInterpolationMode:
                                                                                                        "bicubic",
                                                                                                }}
                                                                                                width="598.672"
                                                                                            />
                                                                                        </td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td
                                                                                            align="left"
                                                                                            className="es-text-4039"
                                                                                            bgcolor="#259582"
                                                                                            style={{
                                                                                                padding: 0,
                                                                                                margin: 0,
                                                                                                paddingBottom: 5,
                                                                                                paddingLeft: 5,
                                                                                                paddingRight: 5,
                                                                                            }}
                                                                                        >
                                                                                            <p
                                                                                                style={{
                                                                                                    margin: 0,
                                                                                                    WebkitTextSizeAdjust:
                                                                                                        "none",
                                                                                                    msTextSizeAdjust:
                                                                                                        "none",
                                                                                                    msoLineHeightRule:
                                                                                                        "exactly",
                                                                                                    fontFamily:
                                                                                                        'arial, "helvetica neue", helvetica, sans-serif',
                                                                                                    // lineHeight 21,
                                                                                                    color: "#ffffff",
                                                                                                    fontSize: 14,
                                                                                                }}
                                                                                            >
                                                                                                We
                                                                                                are
                                                                                                excited
                                                                                                to
                                                                                                share
                                                                                                the
                                                                                                success
                                                                                                of{" "}
                                                                                                <b>
                                                                                                    AyyKori’s
                                                                                                    Affiliate
                                                                                                    Marketing
                                                                                                    Masterclass
                                                                                                    (Batch
                                                                                                    3)
                                                                                                </b>

                                                                                                ,
                                                                                                which
                                                                                                concluded
                                                                                                with
                                                                                                remarkable
                                                                                                outcomes
                                                                                                with
                                                                                                the
                                                                                                participation
                                                                                                of
                                                                                                100
                                                                                                students
                                                                                                from
                                                                                                30
                                                                                                districs!
                                                                                                This
                                                                                                batch
                                                                                                brought
                                                                                                together{" "}
                                                                                                <b>
                                                                                                    diverse
                                                                                                    participants
                                                                                                </b>
                                                                                                —including
                                                                                                students,
                                                                                                and
                                                                                                early-career
                                                                                                professionals—eager
                                                                                                to
                                                                                                explore
                                                                                                the
                                                                                                opportunities
                                                                                                of
                                                                                                affiliate
                                                                                                marketing.
                                                                                            </p>
                                                                                            <p
                                                                                                style={{
                                                                                                    margin: 0,
                                                                                                    WebkitTextSizeAdjust:
                                                                                                        "none",
                                                                                                    msTextSizeAdjust:
                                                                                                        "none",
                                                                                                    msoLineHeightRule:
                                                                                                        "exactly",
                                                                                                    fontFamily:
                                                                                                        'arial, "helvetica neue", helvetica, sans-serif',
                                                                                                    // lineHeight 21,
                                                                                                    color: "#ffffff",
                                                                                                    fontSize: 14,
                                                                                                }}
                                                                                            >
                                                                                                Throughout
                                                                                                the
                                                                                                program,
                                                                                                participants
                                                                                                gained{" "}
                                                                                                <b>
                                                                                                    practical
                                                                                                    skills
                                                                                                </b>{" "}
                                                                                                in
                                                                                                affiliate
                                                                                                marketing,
                                                                                                SEO,
                                                                                                content
                                                                                                strategies,
                                                                                                and
                                                                                                social
                                                                                                media
                                                                                                promotion.
                                                                                                With
                                                                                                expert
                                                                                                guidance
                                                                                                and
                                                                                                hands-on
                                                                                                exercises,
                                                                                                they
                                                                                                learned
                                                                                                how
                                                                                                to
                                                                                                promote
                                                                                                partner
                                                                                                products,
                                                                                                build
                                                                                                personal
                                                                                                brands,
                                                                                                and
                                                                                                earn
                                                                                                commissions.
                                                                                                Several
                                                                                                attendees
                                                                                                even{" "}
                                                                                                <b>
                                                                                                    started
                                                                                                    generating
                                                                                                    income
                                                                                                </b>{" "}
                                                                                                during
                                                                                                the
                                                                                                course
                                                                                                itself,
                                                                                                marking
                                                                                                a
                                                                                                promising
                                                                                                start
                                                                                                to
                                                                                                their
                                                                                                affiliate
                                                                                                journeys.
                                                                                            </p>
                                                                                            <p
                                                                                                className="es-text-mobile-size-14"
                                                                                                style={{
                                                                                                    margin: 0,
                                                                                                    WebkitTextSizeAdjust:
                                                                                                        "none",
                                                                                                    msTextSizeAdjust:
                                                                                                        "none",
                                                                                                    msoLineHeightRule:
                                                                                                        "exactly",
                                                                                                    fontFamily:
                                                                                                        'arial, "helvetica neue", helvetica, sans-serif',
                                                                                                    // lineHeight 21,
                                                                                                    color: "#ffffff",
                                                                                                    fontSize: 14,
                                                                                                }}
                                                                                            >
                                                                                                The
                                                                                                feedback
                                                                                                from
                                                                                                this
                                                                                                batch
                                                                                                has
                                                                                                been
                                                                                                overwhelmingly
                                                                                                positive.
                                                                                                Participants
                                                                                                appreciated
                                                                                                the{" "}
                                                                                                <b>
                                                                                                    interactive
                                                                                                    learning
                                                                                                    sessions
                                                                                                </b>{" "}
                                                                                                and
                                                                                                found
                                                                                                the
                                                                                                course
                                                                                                structure
                                                                                                easy
                                                                                                to
                                                                                                follow,
                                                                                                regardless
                                                                                                of
                                                                                                their
                                                                                                prior
                                                                                                experience.
                                                                                                AyyKori's
                                                                                                instructors
                                                                                                focused
                                                                                                on{" "}
                                                                                                <b>
                                                                                                    real-world
                                                                                                    applications
                                                                                                </b>

                                                                                                ,
                                                                                                preparing
                                                                                                participants
                                                                                                to
                                                                                                achieve{" "}
                                                                                                <b>
                                                                                                    financial
                                                                                                    independence
                                                                                                </b>{" "}
                                                                                                in
                                                                                                the
                                                                                                growing
                                                                                                digital
                                                                                                economy.
                                                                                            </p>
                                                                                        </td>
                                                                                    </tr>
                                                                                </tbody>
                                                                            </table>
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <table
                                cellPadding={0}
                                cellSpacing={0}
                                className="es-content"
                                align="center"
                                role="none"
                                style={{
                                    msoTableLspace: "0pt",
                                    msoTableRspace: "0pt",
                                    borderCollapse: "collapse",
                                    borderSpacing: 0,
                                    tableLayout: "fixed !important",
                                    width: "100%",
                                }}
                            >
                                <tbody>
                                    <tr>
                                        <td
                                            align="center"
                                            style={{ padding: 0, margin: 0 }}
                                        >
                                            <table
                                                bgcolor="#ffffff"
                                                className="es-content-body"
                                                align="center"
                                                cellPadding={0}
                                                cellSpacing={0}
                                                role="none"
                                                style={{
                                                    msoTableLspace: "0pt",
                                                    msoTableRspace: "0pt",
                                                    borderCollapse: "collapse",
                                                    borderSpacing: 0,
                                                    backgroundColor: "#FFFFFF",
                                                    width: 600,
                                                }}
                                            >
                                                <tbody>
                                                    <tr>
                                                        <td
                                                            align="left"
                                                            bgcolor="#0E6153"
                                                            style={{
                                                                padding: 0,
                                                                margin: 0,
                                                                paddingBottom: 5,
                                                                paddingLeft: 5,
                                                                paddingRight: 10,
                                                                backgroundColor:
                                                                    "#0e6153",
                                                            }}
                                                        >
                                                            <table
                                                                cellPadding={0}
                                                                cellSpacing={0}
                                                                width="100%"
                                                                role="none"
                                                                style={{
                                                                    msoTableLspace:
                                                                        "0pt",
                                                                    msoTableRspace:
                                                                        "0pt",
                                                                    borderCollapse:
                                                                        "collapse",
                                                                    borderSpacing: 0,
                                                                }}
                                                            >
                                                                <tbody>
                                                                    <tr>
                                                                        <td
                                                                            align="center"
                                                                            valign="top"
                                                                            style={{
                                                                                padding: 0,
                                                                                margin: 0,
                                                                                width: 585,
                                                                            }}
                                                                        >
                                                                            <table
                                                                                cellPadding={
                                                                                    0
                                                                                }
                                                                                cellSpacing={
                                                                                    0
                                                                                }
                                                                                width="100%"
                                                                                role="presentation"
                                                                                style={{
                                                                                    msoTableLspace:
                                                                                        "0pt",
                                                                                    msoTableRspace:
                                                                                        "0pt",
                                                                                    borderCollapse:
                                                                                        "collapse",
                                                                                    borderSpacing: 0,
                                                                                }}
                                                                            >
                                                                                <tbody>
                                                                                    <tr>
                                                                                        <td
                                                                                            align="left"
                                                                                            bgcolor="#0E6153"
                                                                                            style={{
                                                                                                padding: 0,
                                                                                                margin: 0,
                                                                                            }}
                                                                                        >
                                                                                            <p
                                                                                                style={{
                                                                                                    margin: 0,
                                                                                                    WebkitTextSizeAdjust:
                                                                                                        "none",
                                                                                                    msTextSizeAdjust:
                                                                                                        "none",
                                                                                                    msoLineHeightRule:
                                                                                                        "exactly",
                                                                                                    fontFamily:
                                                                                                        'arial, "helvetica neue", helvetica, sans-serif',
                                                                                                    // lineHeight 33,
                                                                                                    color: "#ffebae",
                                                                                                    fontSize: 22,
                                                                                                }}
                                                                                            >
                                                                                                <b>
                                                                                                    AyyKori’s
                                                                                                    Success
                                                                                                    Story:
                                                                                                    A
                                                                                                    Thriving
                                                                                                    Partnership
                                                                                                    with
                                                                                                    Bishworang,
                                                                                                    Sadakalo,
                                                                                                    and
                                                                                                    Bangla
                                                                                                    Natural
                                                                                                    Agro
                                                                                                </b>
                                                                                            </p>
                                                                                        </td>
                                                                                    </tr>
                                                                                </tbody>
                                                                            </table>
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td
                                                            align="left"
                                                            style={{
                                                                padding: 0,
                                                                margin: 0,
                                                            }}
                                                        >
                                                            {/*[if mso]><table style="width:600px" cellpadding="0" cellspacing="0"><tr><td style="width:349px" valign="top"><![endif]*/}
                                                            <table
                                                                cellPadding={0}
                                                                cellSpacing={0}
                                                                className="es-left"
                                                                align="left"
                                                                role="none"
                                                                style={{
                                                                    msoTableLspace:
                                                                        "0pt",
                                                                    msoTableRspace:
                                                                        "0pt",
                                                                    borderCollapse:
                                                                        "collapse",
                                                                    borderSpacing: 0,
                                                                    float: "left",
                                                                }}
                                                            >
                                                                <tbody>
                                                                    <tr>
                                                                        <td
                                                                            className="es-m-p20b"
                                                                            align="left"
                                                                            style={{
                                                                                padding: 0,
                                                                                margin: 0,
                                                                                width: 349,
                                                                            }}
                                                                        >
                                                                            <table
                                                                                cellPadding={
                                                                                    0
                                                                                }
                                                                                cellSpacing={
                                                                                    0
                                                                                }
                                                                                width="100%"
                                                                                role="presentation"
                                                                                style={{
                                                                                    msoTableLspace:
                                                                                        "0pt",
                                                                                    msoTableRspace:
                                                                                        "0pt",
                                                                                    borderCollapse:
                                                                                        "collapse",
                                                                                    borderSpacing: 0,
                                                                                }}
                                                                            >
                                                                                <tbody>
                                                                                    <tr>
                                                                                        <td
                                                                                            align="center"
                                                                                            style={{
                                                                                                padding: 0,
                                                                                                margin: 0,
                                                                                                fontSize: 0,
                                                                                            }}
                                                                                        >
                                                                                            <img
                                                                                                className="adapt-img"
                                                                                                src="https://fpreqnb.stripocdn.email/content/guids/CABINET_30441bda11fa9f51c9732b24d71252b7a1c39a998fd640cc4eb58ee268981303/images/1726907045623.jpeg"
                                                                                                alt=""
                                                                                                style={{
                                                                                                    display:
                                                                                                        "block",
                                                                                                    border: 0,
                                                                                                    outline:
                                                                                                        "none",
                                                                                                    textDecoration:
                                                                                                        "none",
                                                                                                    msInterpolationMode:
                                                                                                        "bicubic",
                                                                                                }}
                                                                                                width={
                                                                                                    349
                                                                                                }
                                                                                            />
                                                                                        </td>
                                                                                    </tr>
                                                                                </tbody>
                                                                            </table>
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                            {/*[if mso]></td><td style="width:15px"></td><td style="width:236px" valign="top"><![endif]*/}
                                                            <table
                                                                cellPadding={0}
                                                                cellSpacing={0}
                                                                className="es-right"
                                                                align="right"
                                                                role="none"
                                                                style={{
                                                                    msoTableLspace:
                                                                        "0pt",
                                                                    msoTableRspace:
                                                                        "0pt",
                                                                    borderCollapse:
                                                                        "collapse",
                                                                    borderSpacing: 0,
                                                                    float: "right",
                                                                }}
                                                            >
                                                                <tbody>
                                                                    <tr>
                                                                        <td
                                                                            align="left"
                                                                            style={{
                                                                                padding: 0,
                                                                                margin: 0,
                                                                                width: 236,
                                                                            }}
                                                                        >
                                                                            <table
                                                                                cellPadding={
                                                                                    0
                                                                                }
                                                                                cellSpacing={
                                                                                    0
                                                                                }
                                                                                width="100%"
                                                                                role="presentation"
                                                                                style={{
                                                                                    msoTableLspace:
                                                                                        "0pt",
                                                                                    msoTableRspace:
                                                                                        "0pt",
                                                                                    borderCollapse:
                                                                                        "collapse",
                                                                                    borderSpacing: 0,
                                                                                }}
                                                                            >
                                                                                <tbody>
                                                                                    <tr>
                                                                                        <td
                                                                                            align="center"
                                                                                            style={{
                                                                                                padding: 0,
                                                                                                margin: 0,
                                                                                                fontSize: 0,
                                                                                            }}
                                                                                        >
                                                                                            <img
                                                                                                className="adapt-img"
                                                                                                src="https://fpreqnb.stripocdn.email/content/guids/CABINET_30441bda11fa9f51c9732b24d71252b7a1c39a998fd640cc4eb58ee268981303/images/1727594512998.jpeg"
                                                                                                alt=""
                                                                                                style={{
                                                                                                    display:
                                                                                                        "block",
                                                                                                    border: 0,
                                                                                                    outline:
                                                                                                        "none",
                                                                                                    textDecoration:
                                                                                                        "none",
                                                                                                    msInterpolationMode:
                                                                                                        "bicubic",
                                                                                                }}
                                                                                                width={
                                                                                                    236
                                                                                                }
                                                                                            />
                                                                                        </td>
                                                                                    </tr>
                                                                                </tbody>
                                                                            </table>
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                            {/*[if mso]></td></tr></table><![endif]*/}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td
                                                            align="left"
                                                            bgcolor="#259582"
                                                            style={{
                                                                margin: 0,
                                                                paddingRight: 5,
                                                                paddingTop: 10,
                                                                paddingBottom: 10,
                                                                paddingLeft: 10,
                                                                backgroundColor:
                                                                    "#259582",
                                                            }}
                                                        >
                                                            <table
                                                                cellPadding={0}
                                                                cellSpacing={0}
                                                                width="100%"
                                                                role="none"
                                                                style={{
                                                                    msoTableLspace:
                                                                        "0pt",
                                                                    msoTableRspace:
                                                                        "0pt",
                                                                    borderCollapse:
                                                                        "collapse",
                                                                    borderSpacing: 0,
                                                                }}
                                                            >
                                                                <tbody>
                                                                    <tr>
                                                                        <td
                                                                            align="center"
                                                                            valign="top"
                                                                            style={{
                                                                                padding: 0,
                                                                                margin: 0,
                                                                                width: 585,
                                                                            }}
                                                                        >
                                                                            <table
                                                                                cellPadding={
                                                                                    0
                                                                                }
                                                                                cellSpacing={
                                                                                    0
                                                                                }
                                                                                width="100%"
                                                                                role="presentation"
                                                                                style={{
                                                                                    msoTableLspace:
                                                                                        "0pt",
                                                                                    msoTableRspace:
                                                                                        "0pt",
                                                                                    borderCollapse:
                                                                                        "collapse",
                                                                                    borderSpacing: 0,
                                                                                }}
                                                                            >
                                                                                <tbody>
                                                                                    <tr>
                                                                                        <td
                                                                                            align="left"
                                                                                            bgcolor="#259582"
                                                                                            style={{
                                                                                                padding: 0,
                                                                                                margin: 0,
                                                                                            }}
                                                                                        >
                                                                                            <p
                                                                                                style={{
                                                                                                    margin: 0,
                                                                                                    WebkitTextSizeAdjust:
                                                                                                        "none",
                                                                                                    msTextSizeAdjust:
                                                                                                        "none",
                                                                                                    msoLineHeightRule:
                                                                                                        "exactly",
                                                                                                    fontFamily:
                                                                                                        'arial, "helvetica neue", helvetica, sans-serif',
                                                                                                    // lineHeight 24,
                                                                                                    color: "#e7dfdf",
                                                                                                    fontSize: 16,
                                                                                                }}
                                                                                            >
                                                                                                AyyKori
                                                                                                Digital
                                                                                                Limited
                                                                                                is
                                                                                                excited
                                                                                                to
                                                                                                celebrate
                                                                                                the
                                                                                                success
                                                                                                of
                                                                                                our{" "}
                                                                                                <b>
                                                                                                    brand
                                                                                                    partnerships
                                                                                                </b>{" "}
                                                                                                with{" "}
                                                                                                <b>
                                                                                                    Bishworang
                                                                                                </b>

                                                                                                ,{" "}
                                                                                                <b>
                                                                                                    Sadakalo
                                                                                                </b>

                                                                                                ,
                                                                                                and{" "}
                                                                                                <b>
                                                                                                    Bangla
                                                                                                    Natural
                                                                                                    Agro
                                                                                                </b>
                                                                                                —three
                                                                                                iconic
                                                                                                Bangladeshi
                                                                                                brands
                                                                                                known
                                                                                                for
                                                                                                their
                                                                                                unique
                                                                                                offerings
                                                                                                that
                                                                                                promote
                                                                                                local
                                                                                                craftsmanship
                                                                                                and
                                                                                                sustainable
                                                                                                practices.
                                                                                            </p>
                                                                                            <h3
                                                                                                style={{
                                                                                                    margin: 0,
                                                                                                    // lineHeight: "19.2px",
                                                                                                    msoLineHeightRule:
                                                                                                        "exactly",
                                                                                                    fontFamily:
                                                                                                        'arial, "helvetica neue", helvetica, sans-serif',
                                                                                                    fontSize: 16,
                                                                                                    fontStyle:
                                                                                                        "normal",
                                                                                                    fontWeight:
                                                                                                        "normal",
                                                                                                    color: "#e7dfdf",
                                                                                                }}
                                                                                            >
                                                                                                <b>
                                                                                                    🤝
                                                                                                    About
                                                                                                    the
                                                                                                    Partners
                                                                                                </b>
                                                                                            </h3>
                                                                                            <ul>
                                                                                                <li
                                                                                                    style={{
                                                                                                        WebkitTextSizeAdjust:
                                                                                                            "none",
                                                                                                        msTextSizeAdjust:
                                                                                                            "none",
                                                                                                        msoLineHeightRule:
                                                                                                            "exactly",
                                                                                                        fontFamily:
                                                                                                            'arial, "helvetica neue", helvetica, sans-serif',
                                                                                                        // lineHeight 24,
                                                                                                        marginBottom: 15,
                                                                                                        marginLeft: 0,
                                                                                                        color: "#e7dfdf",
                                                                                                        fontSize: 14,
                                                                                                    }}
                                                                                                >
                                                                                                    <p
                                                                                                        style={{
                                                                                                            margin: 0,
                                                                                                            WebkitTextSizeAdjust:
                                                                                                                "none",
                                                                                                            msTextSizeAdjust:
                                                                                                                "none",
                                                                                                            msoLineHeightRule:
                                                                                                                "exactly",
                                                                                                            fontFamily:
                                                                                                                'arial, "helvetica neue", helvetica, sans-serif',
                                                                                                            // lineHeight 24,
                                                                                                            color: "#e7dfdf",
                                                                                                            fontSize: 16,
                                                                                                        }}
                                                                                                    >
                                                                                                        <b>
                                                                                                            Bishworang
                                                                                                        </b>

                                                                                                        :
                                                                                                        A
                                                                                                        pioneer
                                                                                                        in
                                                                                                        promoting{" "}
                                                                                                        <b>
                                                                                                            indigenous
                                                                                                            fashion
                                                                                                        </b>

                                                                                                        ,
                                                                                                        Bishworang
                                                                                                        is
                                                                                                        renowned
                                                                                                        for
                                                                                                        its
                                                                                                        vibrant
                                                                                                        collections
                                                                                                        inspired
                                                                                                        by
                                                                                                        Bangladeshi
                                                                                                        culture
                                                                                                        and
                                                                                                        traditions.
                                                                                                    </p>
                                                                                                </li>
                                                                                                <li
                                                                                                    style={{
                                                                                                        WebkitTextSizeAdjust:
                                                                                                            "none",
                                                                                                        msTextSizeAdjust:
                                                                                                            "none",
                                                                                                        msoLineHeightRule:
                                                                                                            "exactly",
                                                                                                        fontFamily:
                                                                                                            'arial, "helvetica neue", helvetica, sans-serif',
                                                                                                        // lineHeight 24,
                                                                                                        marginBottom: 15,
                                                                                                        marginLeft: 0,
                                                                                                        color: "#e7dfdf",
                                                                                                        fontSize: 14,
                                                                                                    }}
                                                                                                >
                                                                                                    <p
                                                                                                        style={{
                                                                                                            margin: 0,
                                                                                                            WebkitTextSizeAdjust:
                                                                                                                "none",
                                                                                                            msTextSizeAdjust:
                                                                                                                "none",
                                                                                                            msoLineHeightRule:
                                                                                                                "exactly",
                                                                                                            fontFamily:
                                                                                                                'arial, "helvetica neue", helvetica, sans-serif',
                                                                                                            // lineHeight 24,
                                                                                                            color: "#e7dfdf",
                                                                                                            fontSize: 16,
                                                                                                        }}
                                                                                                    >
                                                                                                        <b>
                                                                                                            Sadakalo
                                                                                                        </b>

                                                                                                        :
                                                                                                        Famous
                                                                                                        for
                                                                                                        its{" "}
                                                                                                        <b>
                                                                                                            monochrome-themed
                                                                                                            fashion
                                                                                                        </b>

                                                                                                        ,
                                                                                                        Sadakalo
                                                                                                        showcases
                                                                                                        minimalist
                                                                                                        yet
                                                                                                        artistic
                                                                                                        designs
                                                                                                        that
                                                                                                        appeal
                                                                                                        to
                                                                                                        both
                                                                                                        young
                                                                                                        and
                                                                                                        mature
                                                                                                        consumers.
                                                                                                    </p>
                                                                                                </li>
                                                                                                <li
                                                                                                    style={{
                                                                                                        WebkitTextSizeAdjust:
                                                                                                            "none",
                                                                                                        msTextSizeAdjust:
                                                                                                            "none",
                                                                                                        msoLineHeightRule:
                                                                                                            "exactly",
                                                                                                        fontFamily:
                                                                                                            'arial, "helvetica neue", helvetica, sans-serif',
                                                                                                        // lineHeight 24,
                                                                                                        marginBottom: 15,
                                                                                                        marginLeft: 0,
                                                                                                        color: "#e7dfdf",
                                                                                                        fontSize: 14,
                                                                                                    }}
                                                                                                >
                                                                                                    <p
                                                                                                        style={{
                                                                                                            margin: 0,
                                                                                                            WebkitTextSizeAdjust:
                                                                                                                "none",
                                                                                                            msTextSizeAdjust:
                                                                                                                "none",
                                                                                                            msoLineHeightRule:
                                                                                                                "exactly",
                                                                                                            fontFamily:
                                                                                                                'arial, "helvetica neue", helvetica, sans-serif',
                                                                                                            // lineHeight 24,
                                                                                                            color: "#e7dfdf",
                                                                                                            fontSize: 16,
                                                                                                        }}
                                                                                                    >
                                                                                                        <b>
                                                                                                            Bangla
                                                                                                            Natural
                                                                                                            Agro
                                                                                                        </b>

                                                                                                        :
                                                                                                        This
                                                                                                        brand
                                                                                                        is
                                                                                                        committed
                                                                                                        to{" "}
                                                                                                        <b>
                                                                                                            natural
                                                                                                            and
                                                                                                            organic
                                                                                                            farming
                                                                                                        </b>

                                                                                                        ,
                                                                                                        offering
                                                                                                        a
                                                                                                        range
                                                                                                        of
                                                                                                        eco-friendly
                                                                                                        products
                                                                                                        that
                                                                                                        support
                                                                                                        sustainable
                                                                                                        agriculture
                                                                                                        and
                                                                                                        healthy
                                                                                                        living.
                                                                                                    </p>
                                                                                                </li>
                                                                                            </ul>
                                                                                            <p
                                                                                                style={{
                                                                                                    margin: 0,
                                                                                                    WebkitTextSizeAdjust:
                                                                                                        "none",
                                                                                                    msTextSizeAdjust:
                                                                                                        "none",
                                                                                                    msoLineHeightRule:
                                                                                                        "exactly",
                                                                                                    fontFamily:
                                                                                                        'arial, "helvetica neue", helvetica, sans-serif',
                                                                                                    // lineHeight 24,
                                                                                                    color: "#e7dfdf",
                                                                                                    fontSize: 16,
                                                                                                }}
                                                                                            >
                                                                                                This
                                                                                                collaboration
                                                                                                has
                                                                                                not
                                                                                                only
                                                                                                enhanced
                                                                                                the
                                                                                                reach
                                                                                                of{" "}
                                                                                                <b>
                                                                                                    local
                                                                                                    SMEs
                                                                                                </b>{" "}
                                                                                                but
                                                                                                has
                                                                                                also
                                                                                                aligned
                                                                                                with
                                                                                                AyyKori’s
                                                                                                mission
                                                                                                to
                                                                                                empower
                                                                                                individuals
                                                                                                with{" "}
                                                                                                <b>
                                                                                                    income
                                                                                                    opportunities
                                                                                                    without
                                                                                                    upfront
                                                                                                    investment
                                                                                                </b>

                                                                                                .
                                                                                                The
                                                                                                partnership
                                                                                                marks
                                                                                                a
                                                                                                significant
                                                                                                step
                                                                                                toward
                                                                                                building{" "}
                                                                                                <b>
                                                                                                    stronger
                                                                                                    connections
                                                                                                    between
                                                                                                    brands
                                                                                                    and
                                                                                                    customers
                                                                                                </b>

                                                                                                ,
                                                                                                contributing
                                                                                                to
                                                                                                a{" "}
                                                                                                <b>
                                                                                                    more
                                                                                                    inclusive
                                                                                                    digital
                                                                                                    economy
                                                                                                </b>

                                                                                                .
                                                                                            </p>
                                                                                        </td>
                                                                                    </tr>
                                                                                </tbody>
                                                                            </table>
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
};

export default Newsletter;
