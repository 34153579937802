import React from "react";

const WarningTooltip = ({ content }) => {
  return (
    <div className="w-36 h-auto text-white rounded-md flex flex-col justify-center items-center gap-y-2">
      <div>
        <p className="text-xs">{content}</p>
      </div>
    </div>
  );
};

export default WarningTooltip;
