import { Markup } from "interweave";
import Cookies from "js-cookie";
import React, { useEffect } from "react";
import { toast } from "react-hot-toast";
import { BsFillShareFill, BsStarFill } from "react-icons/bs";
import { RxCross1 } from "react-icons/rx";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ClipLoader } from "react-spinners";
import { showModal } from "../../features/loginModalShowSlice.js/loginModalShowSlice";
import {
  useCreateProfitLinkForShareMutation,
  useCreateProfitLinkMutation,
} from "../../features/profitlink/profitlinkApiSlice";
import {
  changeShareLink,
  resetShareLink,
} from "../../features/sharelink/sharelinkSlice";
import useAuth from "../../hooks/useAuth";
import modernCopyToClipboardFunction from "../../utils/modernCopyClipBoard";
import CopyLinkModal from "./CopyLinkModal";
import ShareModal from "./ShareModal";

const ProductModal = ({
  show,
  setShow,
  data,
  shareModal,
  setShareModal,
  copyModal,
  setCopyModal,
}) => {
  const access_token = Cookies?.get("ayykori");
  const navigate = useNavigate();
  const Icon = data?.Icon;
  const isAuth = useAuth();
  const dispatch = useDispatch();
  const [
    createProfitLink,
    {
      data: dataProfit,
      isLoading: isLoadingProfit,
      isError: isErrorProfit,
      isSuccess: isSuccessProfit,
    },
  ] = useCreateProfitLinkMutation() || {};
  const [
    createProfitLinkForShare,
    {
      data: dataProfitShare,
      isLoading: isLoadingProfitShare,
      isError: isErrorProfitShare,
      isSuccess: isSuccessProfitShare,
    },
  ] = useCreateProfitLinkForShareMutation() || {};
  // showing error message
  useEffect(() => {
    if (isErrorProfit && data?.buttontitle === "Get Cashback") {
      toast.error("Something Went Wrong!!");
    }
    if (isErrorProfit && data?.buttontitle === "Affiliate  Link") {
      toast.error("Something Went Wrong!!");
    }
    if (isErrorProfitShare && data?.buttontitle === "Get Cashback") {
      toast.error("Something Went Wrong!!");
    }
    if (isErrorProfitShare && data?.buttontitle === "Affiliate  Link") {
      toast.error("Something Went Wrong!!");
    }
  }, [isErrorProfit, isErrorProfitShare, data?.buttontitle]);
  useEffect(() => {
    if (isSuccessProfitShare && dataProfitShare?.shortLink) {
      modernCopyToClipboardFunction(dataProfitShare?.shortLink);
      // navigator?.clipboard?.writeText(dataProfitShare?.shortLink);
      dispatch(changeShareLink(dataProfitShare?.shortLink));
    }
  }, [
    isSuccessProfit,
    isSuccessProfitShare,

    dataProfitShare?.shortLink,
    dispatch,
  ]);

  const handleCashback = async () => {
    if (isAuth || Cookies.get("ayykori")) {
      const shortlink = await createProfitLink({
        access_token,
        fullLink: data?.productLink,
        source: "cashback",
      });
      const { data: shortlinkData } = shortlink || {};
      if (shortlinkData && shortlinkData?.shortLink && shortlinkData?.succss) {
        let uid =
          shortlinkData?.shortLink?.split("_")[
            shortlinkData?.shortLink?.split("_")?.length - 1
          ];
        window.open(
          `/cashback-activated/${data?.id ? data?.id : data?._id}?uid=${uid}`,
          "_blank",
          // "popup",
          "noreferrer"
        );
      }
    } else {
      dispatch(showModal());
    }
  };

  const handleEarningShare = async () => {
    if (isAuth || Cookies.get("ayykori")) {
      const shortlinkRes = await createProfitLinkForShare({
        access_token,
        fullLink: data?.productLink,
        source: "earning",
      });
      dispatch(resetShareLink());
      setShareModal(true);
      const { data: shortlinkData } = shortlinkRes || {};

      if (shortlinkData && shortlinkData?.shortLink && shortlinkData?.succss) {
        // navigator?.clipboard?.writeText(shortlinkData?.shortLink);
        modernCopyToClipboardFunction(shortlinkData?.shortLink);
        toast.success("Link Copied successfull!!");
        dispatch(resetShareLink());
        dispatch(changeShareLink(shortlinkData?.shortLink));
        setShareModal(true);
      }
    } else {
      dispatch(showModal());
      // navigate("/login", { state: location?.pathname });
    }
  };
  return (
    <>
      <CopyLinkModal
        copyModal={copyModal}
        setCopyModal={setCopyModal}
        proftlink={dataProfit?.shortLink}
        isLoading={isLoadingProfit}
        isError={isErrorProfit}
      />
      <ShareModal shareModal={shareModal} setShareModal={setShareModal} />
      <div
        className={`w-screen h-screen bg-[rgba(0,0,0,0.5)] ${
          show ? "flex" : "hidden"
        } items-center justify-center fixed top-0 left-0 right-0 bottom-0 z-[10000] `}
      >
        <div className=" w-[90%] md:w-[80%] lg:w-[55%] h-[90vh] md:h-[70vh] overflow-y-auto scrollbar-w-0 bg-white rounded-lg grid grid-cols-1 md:grid-cols-2 gap-x-4 p-1 md:p-6 relative items-center">
          <div className="w-full h-full mx-auto relative overflow-hidden ">
            <div className="hover:scale-125 w-full h-full duration-500 cursor-grab">
              <img
                className="w-full h-full object-cover "
                src={data?.image}
                alt=""
              />
            </div>
            <div className="absolute left-0 bottom-0">
              <img
                className="w-14 h-14 object-cover border rounded-full"
                src={data?.brandimg}
                alt=""
              />
            </div>
          </div>

          <div
            className="w-full h-full overflow-y-auto p-4 scroll-smooth 
    scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-white 
    scrollbar-thumb-rounded-full scrollbar-track-rounded-full scrollbar-w-0"
          >
            <h1
              className={`${
                data?.name?.length > 100
                  ? "text-sm md:text-base"
                  : "text-sm md:text-xl"
              } rounded-md font-outfit text-gray-600 hover:text-primaryColor cursor-pointer font-medium mb-2`}
            >
              {data?.name}
            </h1>
            <div className="flex items-center gap-x-3">
              <p className="text-xs text-gray-400">
                - {data?.subCategory ? data?.subCategory : data?.category}
              </p>
              <div className="flex gap-x-1 items-center">
                <BsStarFill className="text-[#FF790F] text-xs" />
                <p className="text-xs text-gray-500 font-outfit">
                  {data?.rating}
                </p>
              </div>
            </div>

            <div className="flex gap-2 mt-1 md:mt-3 items-center text-gray-600">
              <div>
                <h4 className="text-xs"> Price: {data?.price} &#2547;</h4>
              </div>
              <div className="border-l-2 pl-2">
                <h4 className="text-lg text-primaryColor">
                  Commission: {data?.profit?.toFixed(2)} &#2547;
                </h4>
              </div>
            </div>
            <div>
              <h1 className="mb-1  md:mt-3 text-base md:text-md text-gray-600">
                Product Highlight
              </h1>
              <p
                className="h-24 md:h-36 text-gray-400 overflow-y-auto scroll-smooth 
    scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-white 
    scrollbar-thumb-rounded-full scrollbar-track-rounded-full scrollbar-w-0 text-xs md:text-sm rounded-md"
              >
                <Markup content={data?.productDetails} />
              </p>
            </div>
            <div className="mt-5">
              <div className="flex items-center gap-x-4">
                {data?.buttontitle === "Affiliate  Link" && (
                  <button
                    disabled={isLoadingProfit}
                    onClick={() => {
                      if (isAuth || Cookies.get("ayykori")) {
                        data?.buttontitle === "Affiliate  Link" &&
                          setCopyModal(true);
                        createProfitLink({
                          access_token,
                          fullLink: data?.productLink,
                          source: "earning",
                        });
                      } else {
                        dispatch(showModal());
                      }
                    }}
                    className="border rounded-lg px-4 py-2 text-sm w-fit flex items-center gap-x-2 transition-all duration-300 bg-primaryColor text-white hover:bg-white hover:text-primaryColor hover:border-primaryColor"
                  >
                    <Icon size={15} />
                    {data?.buttontitle}
                  </button>
                )}
                {data?.buttontitle === "Get Cashback" && (
                  <button
                    disabled={isLoadingProfit}
                    onClick={handleCashback}
                    className="border rounded-lg px-4 py-2 text-sm w-fit flex items-center gap-x-2 transition-all duration-300 bg-primaryColor text-white hover:bg-white hover:text-primaryColor"
                  >
                    <Icon size={15} />
                    {data?.buttontitle}
                  </button>
                )}
                <span
                  className={`bg-white border border-primaryColor p-2 rounded-lg text-primaryColor ${
                    isLoadingProfitShare && "bg-primaryColor"
                  } hover:bg-primaryColor hover:text-white cursor-pointer transition-all duration-300`}
                >
                  {isLoadingProfitShare ? (
                    <ClipLoader color="#ffffff" size={15} />
                  ) : (
                    <BsFillShareFill
                      title="Share"
                      disabled={isLoadingProfitShare}
                      // onClick={() => {
                      //   if (isAuth || Cookies.get("ayykori")) {
                      //     createProfitLinkForShare({
                      //       access_token,
                      //       fullLink: data?.productLink,
                      //       source:
                      //         data?.buttontitle === "Get Cashback"
                      //           ? "cashback"
                      //           : "earning",
                      //     });
                      //     dispatch(resetShareLink());
                      //     setShareModal(true);
                      //   } else {
                      //     dispatch(showModal());
                      //   }
                      // }}
                      onClick={handleEarningShare}
                      size={15}
                      className=""
                    />
                  )}
                </span>
              </div>
            </div>
          </div>
          <span
            onClick={() => setShow(false)}
            className="absolute top-4 right-4 cursor-pointer bg-primaryColor md:bg-transparent rounded-full p-1"
          >
            <RxCross1 size={20} className="text-white md:text-gray-500" />
          </span>
        </div>
      </div>
    </>
  );
};

export default ProductModal;
