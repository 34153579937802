import Cookies from "js-cookie";
import React, { useState } from "react";
import { Helmet } from "react-helmet";
import ReactPaginate from "react-paginate";
import { useParams, useSearchParams } from "react-router-dom";
import BrandCard from "../../components/Card/BrandCard";
import BrandSortComponent from "../../components/CommonComponent/BrandSortComponent";
import NoDataFound from "../../components/NodataFound/NoDataFound";
import BrandCardSkeleton from "../../components/Skeleton/BrandCardSkeleton";
import AfterLoginSlider from "../../components/Slider/AfterLoginSlider";
import { useGetYouMayLikeBrandsQuery } from "../../features/brand/brandApiSlice";
import CommonLayout from "../../layout/CommonLayout";
const YouMayLikeBrandPage = () => {
  const [query] = useSearchParams();
  const brandtype = query?.get("share_and_earn_brands");
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  // const { pathname } = useLocation();
  // const dispatch = useDispatch();
  // useEffect(() => {
  //   dispatch(resetLimit());
  //   dispatch(resetSort());
  //   dispatch(resetPage());
  // }, [pathname]);

  // url to title convert
  const { id } = useParams();
  // let arr = title.split("_");
  // for (let i = 0; i < arr.length; i++) {
  //   arr[i] = arr[i].slice(0, 1).toUpperCase() + arr[i].slice(1);
  // }
  // let result = arr.join(" ");

  // const { limit, page, cashback } = useSelector((state) => state.brand);
  const access_token = Cookies.get("ayykori");
  const { data, isLoading, isSuccess, isError } = useGetYouMayLikeBrandsQuery(
    {
      access_token,
      id,
      limit: limit,
      page: page,
    },
    { refetchOnReconnect: true }
  );

  let content = null;
  // let content2 = null;
  // handle loading case
  if (isLoading && !isSuccess) {
    content = (
      <>
        <div className=" md:container px-3 md:px-0 grid grid-cols-1 sm:grid-cols-2  md:grid-cols-3 lg:grid-cols-4 ">
          {Array(8)
            .fill()
            ?.map((item, i) => (
              <BrandCardSkeleton key={i} />
            ))}
        </div>
      </>
    );
  }
  // handle success case
  if (!isLoading && isSuccess && !isError) {
    content = (
      <>
        <div className=" w-full px-3 md:px-[55px]   grid grid-cols-2 sm:grid-cols-2  md:grid-cols-3 lg:grid-cols-4 gap-y-3 md:gap-y-6 gap-x-3 md:gap-x-8">
          {data?.brands &&
            data?.brands?.map((item, i) => (
              <BrandCard
                key={i}
                data={{
                  id: item?.brandId,
                  brandUnid: item?.brandUnid,
                  name: item?.subBrandName
                    ? item?.subBrandName
                    : item?.brandName,
                  brandCardimg: item?.subBrandimg
                    ? item?.subBrandimg
                    : item?.brandImg,
                  brandType: item?.brandType,
                  userCommission:
                    brandtype === "affiliate"
                      ? item?.earningUserCommission
                      : brandtype === "earning"
                      ? item?.earningUserCommission
                      : item?.cashbackUserCommission,
                  borderShow: true,
                  type: brandtype === "affiliate" ? "earning" : "cashback",
                }}
              />
            ))}
        </div>
      </>
    );
  }
  // handle error case
  if (!isLoading && isError) {
    content = (
      <>
        <div className="w-full container bg-gray-100">
          <h1 className="text-center py-8 text-red-500 text-base">
            Something Went Wrong!! There is an Error!!
          </h1>
        </div>
      </>
    );
  }
  // handle empty case
  if (!isLoading && isSuccess && data?.brands?.length === 0) {
    content = (
      <>
        <NoDataFound />
      </>
    );
  }

  return (
    <>
      <Helmet>
        <title>You May Also Like | Ayykori</title>
      </Helmet>
      <CommonLayout>
        {/* banner section start */}
        <AfterLoginSlider />
        {/* banner section end */}

        {/* common component use */}
        <div className=" mt-20">
          {/* <CommonComponent
          data={{
            title: result,
            end: "Ends in:  00:00:00",
            showSort: true,
          }}
        /> */}
          <BrandSortComponent
            title="You May Also Like"
            limit={limit}
            setLimit={setLimit}
          />
        </div>

        {/* all brand start */}
        <div className="mb-10">{content}</div>
        {/* all brand end */}

        {/* load more */}
        {data?.totalBrands[0]?.count > limit && (
          <div className="w-full px-3 md:px-[55px] flex justify-center md:justify-end items-center my-8">
            <ReactPaginate
              breakLabel="..."
              previousLabel="Prev"
              className="text-primaryColor flex items-center gap-2 text-sm"
              previousClassName="text-primaryColor text-sm"
              nextClassName="text-primaryColor text-sm"
              activeClassName="bg-primaryColor text-sm"
              activeLinkClassName="text-white px-2"
              nextLabel="Next"
              onPageChange={(e) => setPage(e.selected + 1)}
              pageRangeDisplayed={2}
              marginPagesDisplayed={2}
              pageCount={Math.ceil(data?.totalBrands[0]?.count / limit)}
              renderOnZeroPageCount={null}
            />
          </div>
        )}
      </CommonLayout>
    </>
  );
};

export default YouMayLikeBrandPage;
