import { useMediaQuery } from "react-responsive";

const useDeviceLogs = () => {
  const isMobile = useMediaQuery({ query: "(max-width: 767px)" });

  if (isMobile) {
    localStorage.setItem("app", true);
  } else {
    localStorage.removeItem("app");
  }
};

export default useDeviceLogs;
