import React from "react";
import Error from "../Error/Error";
import NoDataFound from "../NodataFound/NoDataFound";

const CalculationHistoryTable = ({ source, response }) => {
  const { data, isLoading, isError, isSuccess } = response;

  let content = null;
  // handle loading case
  if (isLoading && !isError) {
    content = (
      <>
        <tbody>
          {Array(5)
            ?.fill()
            ?.map((item, index) => (
              <tr key={index} className="border-t border-gray-300">
                <td className="text-xs min-w-[250px] w-fit px-3 py-2 gap-x-0 text-gray-500 text-left">
                  <div className="bg-gray-200 animate-pulse w-full h-5"></div>
                </td>
                <td className="text-xs w-fit px-3 py-2 gap-x-0 text-gray-500 text-left">
                  <div className="bg-gray-200 animate-pulse w-full h-5"></div>
                </td>
                <td className="text-xs w-fit px-3 py-2 gap-x-0 text-gray-500 text-left">
                  <div className="bg-gray-200 animate-pulse w-full h-5"></div>
                </td>
                <td className="text-xs w-fit px-3 py-2 gap-x-0 text-gray-500 text-left">
                  <div className="bg-gray-200 animate-pulse w-full h-5"></div>
                </td>
              </tr>
            ))}
        </tbody>
      </>
    );
  }

  // handle success case
  if (isSuccess && !isError && data?.data?.length !== 0) {
    content = (
      <tbody>
        {data?.data &&
          data?.data?.map((item, index) => (
            <tr key={index} className="border-t border-gray-300">
              <td className="text-xs min-w-[250px] w-fit px-3 py-2 gap-x-0 text-gray-500 text-left">
                <a className="text-blue-400" href={item?.fullLink}>
                  {item?.fullLink?.slice(0, 40)}...
                </a>
              </td>
              <td className="text-xs w-fit px-3 py-2 gap-x-0 text-gray-500 text-left">
                {item?.category || "N/A"}
              </td>
              <td className="text-xs w-fit px-3 py-2 gap-x-0 text-gray-500 text-left">
                {item?.price || "N/A"} &#2547;
              </td>
              <td className="text-xs w-fit px-3 py-2 gap-x-0 text-gray-500 text-left">
                {(item?.calculateCommission).toFixed(2)} &#2547;
              </td>
            </tr>
          ))}
      </tbody>
    );
  }

  // handle error case
  if (!isLoading && isError) {
    content = (
      <>
        <tbody>
          <tr className="border-t border-gray-300">
            <td colSpan={4}>
              <Error />
            </td>
          </tr>
        </tbody>
      </>
    );
  }

  // handle empty case
  if (!isLoading && isSuccess && data?.data?.length === 0) {
    content = (
      <tbody>
        <tr className="border-t border-gray-300">
          <td colSpan={4}>
            <NoDataFound />
          </td>
        </tr>
      </tbody>
    );
  }
  return (
    <div
      className="w-full overflow-x-auto scroll-smooth 
    scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-white 
    scrollbar-thumb-rounded-full scrollbar-track-rounded-full scrollbar-h-1"
    >
      <table
        className=" whitespace-nowrap w-full h-full p-[4rem]
border-collapse border rounded transition duration-700 shadow-lg"
      >
        <thead>
          <tr className="bg-gray-50">
            <th className="text-xs py-2 px-3 gap-x-0 text-gray-400 font-medium text-left">
              Product Link
            </th>
            <th className="text-xs  py-2 px-3 gap-x-0 text-gray-400 font-medium text-left">
              Category
            </th>
            <th className="text-xs  py-2 px-3 gap-x-0 text-gray-400 font-medium text-left">
              Price
            </th>
            <th className="text-xs  py-2 px-3 gap-x-0 text-gray-400 font-medium text-left">
              {source === "cashback" ? "Cashback" : "Earning"} Amount
            </th>
          </tr>
        </thead>
        {content}
      </table>
    </div>
  );
};

export default CalculationHistoryTable;
