import React, { useState } from "react";
import { BiChevronRight } from "react-icons/bi";
import { useDispatch } from "react-redux";
import { BeatLoader } from "react-spinners";
import bd from "../../Assets/icons/bd.png";
import { setErrorMsg } from "../../features/ErrorHelper/ErrorHelperSlice";
import { useIsExistUserUsingPhoneMutation } from "../../features/auth/authApiSlice";
import { user } from "../../features/auth/authHelperSlice";
import { goback } from "../../features/backPrev/goBackSlice";
const InitialDefault = ({ setSteps }) => {
  const dispatch = useDispatch();
  const [phoneNumber, setPhoneNumber] = useState("");
  // console.log(phoneNumber);
  const [
    isExistUserUsingPhone,
    {
      isError: isErrorExistUserUsingPhone,
      isSuccess: isSuccessExistUserUsingPhone,
      isLoading: isLoadingExistUsingPhone,
      data: dataExistUserUsingPhone,
      error: errorExistUserUsingPhone,
    },
  ] = useIsExistUserUsingPhoneMutation() || {};

  const handleInputChange = (e) => {
    e.preventDefault();
    const inputValue = e.target.value;
    // console.log(inputValue);
    const sanitizedValue = inputValue.replace(/\D/g, "");
    if (sanitizedValue.length <= 11) {
      setPhoneNumber(sanitizedValue);
    }
    // console.log(inputValue);
  };

  const handleSubmitPhone = async (e) => {
    e.preventDefault();
    const res = await isExistUserUsingPhone({ phone: phoneNumber });
    const { data, error } = res || {};

    dispatch(
      user({
        user: res?.data?.result,
      })
    );
    if (data?.result?.verified) {
      dispatch(
        goback({
          prevComponent: "InitialDefault",
        })
      );
      setSteps("LoginWhenVarified");
    } else if (
      (data && !data?.result?.verified) ||
      (data?.result?.verified === null && data?.phone)
    ) {
      setSteps("OTPVarification");
    } else if (error) {
      dispatch(
        setErrorMsg({
          errorMsg: error?.data?.message || "Please try again later!!",
          isError: true,
        })
      );
      dispatch(
        goback({
          prevComponent: "InitialDefault",
        })
      );

      setSteps("OTPNotMatch");
    }
  };

  return (
    <div
      style={{ zIndex: 999 }}
      className="relative bg-white border lg:p-10 md:p-8 p-5 min-h-[400px] rounded-lg flex flex-col justify-center"
    >
      <h1 className="text-3xl font-bold text-gray-700 mb-10">
        Start your cashback journey
      </h1>
      <h3 className="text-sm text-gray-600">Start with your phone number</h3>
      <form onSubmit={handleSubmitPhone} className="">
        <div className="h-[80px]">
          <div className="relative mt-3">
            <input
              type="text"
              placeholder="Phone"
              value={phoneNumber}
              maxLength={11}
              onChange={handleInputChange}
              required
              className="border border-gray-300 py-2 pl-[74px] pr-2 rounded-md focus:outline-none focus:border-primaryColor text-gray-700 w-full"
            />
            <div className="absolute top-1/2 -translate-y-1/2 left-2 flex items-center gap-2 text-gray-500">
              <img src={bd} alt="" className="h-4" />
              +88
            </div>
          </div>
        </div>
        {isLoadingExistUsingPhone ? (
          <button
            type="submit"
            className="w-fit bg-primaryColor pt-1 pb-1.5 px-6 text-white flex items-center gap-1 rounded-full group hover:opacity-80 transition duration-300 cursor-not-allowed "
            disabled
          >
            Checking <BeatLoader color="#fff" size={8} />{" "}
            <BiChevronRight className="group-hover:translate-x-1 transition duration-300 mt-0.5" />
          </button>
        ) : (
          <button
            type="submit"
            className="w-fit bg-primaryColor pt-1 pb-1.5 px-6 text-white flex items-center gap-1 rounded-full group hover:opacity-80 transition duration-300"
          >
            Next{" "}
            <BiChevronRight className="group-hover:translate-x-1 transition duration-300 mt-0.5" />
          </button>
        )}

        <div className="mt-4 text-sm text-gray-700 lg:hidden md:hidden block">
          Want to know how cashback work?
          {/* <button onClick={() => setSteps(7)} className="text-blue-500">
            Click here
          </button> */}
        </div>
      </form>
    </div>
  );
};

export default InitialDefault;
