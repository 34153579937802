import { apiRequest } from "../apiRequest/apiRequest";

export const productApiSlice = apiRequest.injectEndpoints({
  endpoints: (builder) => ({
    getProductsByCategory: builder.query({
      query: (data) => {
        const { access_token, categoryname, page, limit } = data || {};
        return {
          url: categoryname
            ? `/get-product-by-category?productType=${categoryname}&page=${page}&limit=${limit}`
            : `/get-product-by-category?page=${page}&limit=${limit}`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${access_token}`,
            "Content-Type": "application/json;charset=UTF-8",
          },
        };
      },
      providesTags: ["Products"],
    }),
  }),
});

export const { useGetProductsByCategoryQuery } = productApiSlice;
