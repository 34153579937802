import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { useDispatch } from "react-redux";
import LoginModal from "../../components/LoginRegi/LoginModal";
import { showModal } from "../../features/loginModalShowSlice.js/loginModalShowSlice";
import CommonLayout from "../../layout/CommonLayout";

const LoginPageModal = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(showModal());
  }, [dispatch]);

  return (
    <>
      <Helmet>
        <title>Login | Ayykori</title>
      </Helmet>
      <CommonLayout>
        <div className=" w-screen h-screen  flex justify-center ">
          <div className="h-[60%] w-[80%] lg:w-[60%]   shadow-md rounded-none flex flex-col justify-center items-center mt-5">
            <LoginModal source={"page"} />
          </div>
        </div>
      </CommonLayout>
    </>
  );
};

export default LoginPageModal;
