import Cookies from "js-cookie";
import React, { useState } from "react";
import { toast } from "react-hot-toast";
import { MdArrowForwardIos } from "react-icons/md";
import { TbLink } from "react-icons/tb";
import { Autoplay, Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { useGetAllBrandsQuery } from "../../features/brand/brandApiSlice";
import modernCopyToClipboardFunction from "../../utils/modernCopyClipBoard";
import MotherBrandSliderCardSkeleton from "../Skeleton/MotherBrandsSliderCardSkeleton";

const MotherBrandSlider = () => {
  const access_token = Cookies.get("ayykori");
  const { data, isLoading, isSuccess, isError } =
    useGetAllBrandsQuery(
      { access_token, page: 1, limit: 10 },
      { refetchOnReconnect: true }
    ) || {};
  const [swiperRef, setSwiperRef] = useState(null);
  const prevHandler = () => {
    swiperRef.slidePrev();
  };
  const nextHandler = () => {
    swiperRef.slideNext();
  };
  const handleCopy = (brandHomepageLink) => {
    modernCopyToClipboardFunction(brandHomepageLink);
    // navigator.clipboard.writeText(brandHomepageLink);
    toast.success("Link Copied!!");
  };
  let content = null;
  // handle loading case
  if (isLoading && !isSuccess) {
    content = (
      <>
        <div className="w-full grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-4">
          {Array(
            window.innerWidth > 992
              ? 5
              : window.innerWidth > 764
              ? 4
              : window.innerWidth > 576
              ? 3
              : 2
          )
            .fill()
            ?.map((item, i) => (
              <div key={i} className="w-full flex justify-center items-center">
                <MotherBrandSliderCardSkeleton />
              </div>
            ))}
        </div>
      </>
    );
  }
  // handle success case
  if (!isLoading && isSuccess && !isError) {
    content = (
      <>
        <Swiper
          slidesPerView={2}
          spaceBetween={2}
          loop={true}
          autoplay={{
            delay: 5000,
            pauseOnMouseEnter: true,
            disableOnInteraction: false,
          }}
          modules={[Autoplay, Navigation]}
          onSwiper={(swiper) => setSwiperRef(swiper)}
          breakpoints={{
            // when window width is >= 320px
            320: {
              slidesPerView: 2,
              spaceBetween: 2,
            },
            // when window width is >= 480px
            480: {
              slidesPerView: 3,
              spaceBetween: 4,
            },
            // when window width is >= 640px
            640: {
              slidesPerView: 4,
              spaceBetween: 5,
            },
            // when window width is >= 640px
            768: {
              slidesPerView: 5,
              spaceBetween: 5,
            },
            // when window width is >= 640px
            920: {
              slidesPerView: 6,
              spaceBetween: 5,
            },
            // when window width is >= 920
            1200: {
              slidesPerView: 8,
              spaceBetween: 5,
            },
          }}
          className="mySwiper w-full"
        >
          {data?.brands &&
            data?.brands?.map((item, i) => (
              <SwiperSlide key={i}>
                <div className="w-full h-full flex justify-center items-center">
                  <div className="m-0 w-24 h-24 relative group">
                    <div className="w-full h-full rounded-md bg-[rgba(0,0,0,0.2)] absolute top-0 left-0 right-0 bottom-0 transition-all duration-500 translate-y-[110%] group-hover:translate-y-0 z-10 flex  justify-center items-center">
                      <span
                        onClick={() => handleCopy(item?.brandHomepageLink)}
                        className="bg-white p-1 rounded-full cursor-pointer"
                      >
                        <TbLink className="text-primaryColor text-2xl" />
                      </span>
                    </div>
                    <img
                      src={item?.brandImg}
                      alt=""
                      className="absolute top-0 left-0 right-0 bottom-0 rounded-md object-contain  w-full h-full border cursor-pointer hover:shadow-lg"
                    />
                  </div>
                </div>
              </SwiperSlide>
            ))}
        </Swiper>
      </>
    );
  }
  // handle error case
  if (!isLoading && isError) {
    content = (
      <>
        <div className="w-full container bg-gray-100">
          <h1 className="text-center py-8 text-red-500 text-base">
            Something Went Wrong!! There is an Error!!
          </h1>
        </div>
      </>
    );
  }
  // handle empty case
  if (!isLoading && isSuccess && data?.brands?.length === 0) {
    content = (
      <>
        <div className="w-full container bg-gray-100">
          <h1 className="text-center py-8 text-gray-700 text-base">
            No Data Found
          </h1>
        </div>
      </>
    );
  }

  return (
    <>
      <div className="w-full container mx-auto mt-6 mb-2 relative">
        {!isLoading && (
          <button
            className="absolute left-0 top-[50%] translate-y-[-50%] z-[100] rounded-full p-2 bg-gray-100 shadow-md shadow-gray-300"
            onClick={prevHandler}
          >
            <MdArrowForwardIos
              size={18}
              className="rotate-180 cursor-pointer text-gray-600"
            />
          </button>
        )}
        {content}
        {!isLoading && (
          <button
            className="absolute right-0 top-[50%] translate-y-[-50%] z-[100] rounded-full p-2 bg-gray-100 shadow-md shadow-gray-300"
            onClick={nextHandler}
          >
            <MdArrowForwardIos
              size={18}
              className="cursor-pointer text-gray-600"
            />
          </button>
        )}
      </div>
    </>
  );
};

export default MotherBrandSlider;
