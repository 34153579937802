import React from "react";

const BrandSearchCardSkeleton = () => {
  return (
    <div className="border rounded-md p-2 flex gap-x-2 cursor-pointer">
      <div className="w-10 h-10 rounded-md animate-pulse bg-gray-200"></div>
      <div className="w-full flex flex-col gap-2">
        <div className="w-[50%] h-4 rounded-sm animate-pulse bg-gray-200"></div>
        <div className="w-[60%] h-2 rounded-sm animate-pulse bg-gray-200"></div>
      </div>
    </div>
  );
};

export default BrandSearchCardSkeleton;
