import React from "react";
import empty from "../../Assets/images/icon/empty.png";

const NoDataFound = () => {
  return (
    <div className="w-full container">
      <div className="flex flex-col items-center justify-center gap-x-2 text-center py-8 text-gray-700 text-base">
        <img src={empty} alt="" className="w-10 opacity-50" />
        <span className="text-xs text-gray-400">No Data Found</span>
      </div>
    </div>
  );
};

export default NoDataFound;
