import React, { useState } from "react";
import { Helmet } from "react-helmet";
import star from "../../Assets/images/icon/question.png";
import { faqData } from "../../Data/FAQs";
import TestAccordion from "../../components/Accordion/Accordion";
import CommonLayout from "../../layout/CommonLayout";

const FAQPage = () => {
  const [active, setActive] = useState(0);
  const [activeTitle, setActiveTitle] = useState("General Questions");
  return (
    <>
      <Helmet>
        <title>FAQs | Ayykori</title>
      </Helmet>
      <CommonLayout>
        <div className="w-full px-3 md:px-[55px] mt-24 md:mt-40 mb-10">
          <div className=" w-full md:w-[40%] mb-5">
            <h1 className="text-gray-700 text-base md:text-2xl font-medium font-outfit">
              FAQs
            </h1>
            <p className=" pt-1 text-xs md:text-base text-gray-500 ">
              Seeking Assistance? Delve into Our Comprehensive FAQs for Detailed
              Answers!
            </p>
          </div>
          {faqData &&
            faqData?.map((item, index) => (
              <div key={index}>
                <h1 className="text-xl flex items-center gap-1 text-gray-500 font-semibold">
                  <img src={star} alt="" className="w-12" />
                  {item?.title}
                </h1>
                {item?.data?.map((faq, indexfaq) => (
                  <TestAccordion
                    key={indexfaq}
                    data={faq}
                    title={item?.title}
                    index={indexfaq}
                    active={active}
                    setActive={setActive}
                    activeTitle={activeTitle}
                    setActiveTitle={setActiveTitle}
                  />
                ))}
              </div>
            ))}
        </div>
      </CommonLayout>
    </>
  );
};

export default FAQPage;
