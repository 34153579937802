import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  shop: localStorage.getItem("shop") || "",
};

const shopSlice = createSlice({
  name: "shop",
  initialState,
  reducers: {
    shopChoice: (state, action) => {
      const { shop } = action.payload;
      localStorage.setItem("shop", shop);
      state.shop = shop;
    },
  },
});

export const { shopChoice } = shopSlice.actions;
export default shopSlice.reducer;
