import React from "react";

const CommonDescriptions = ({ data }) => {
  return (
    <div className="px-3">
      <div className="">
        <h2 className="text-xl font-semibold text-gray-700 my-2">
          {data?.title}
        </h2>
        <p className=" text-base text-gray-500 my-2">{data?.subTitle}</p>
        <ul className=" text-base text-gray-500 ml-8 my-2 space-y-1 list-disc">
          {data.list &&
            data.list.map((item, i) => <li key={i}>{item.listData}</li>)}
        </ul>
        <p className=" text-base text-gray-500 my-2">{data?.paragraph}</p>
      </div>
    </div>
  );
};

export default CommonDescriptions;
