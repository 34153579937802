export const storeToken = (token) => {
  localStorage.setItem("ayykori", token);
};
export const setToken = (key, token) => {
  localStorage.setItem(key, token);
};
export const getToken = () => {
  return localStorage.getItem("ayykori");
};
export const getTokenUsingKey = (key) => {
  return localStorage.getItem(key);
};
export const removeToken = (token) => {
  localStorage.removeItem(token);
};
