import Cookies from "js-cookie";
import React, { useState } from "react";
import { Helmet } from "react-helmet";
import ReactPaginate from "react-paginate";
import CategoryCard from "../../components/Card/CategoryCard";
import BrandCategorySortComponent from "../../components/CommonComponent/BrandCategorySortComponent";
import Error from "../../components/Error/Error";
import NoDataFound from "../../components/NodataFound/NoDataFound";
import AfterLoginSlider from "../../components/Slider/AfterLoginSlider";
import { useGetAllCategoryQuery } from "../../features/category/categoryApiSlice";
import CommonLayout from "../../layout/CommonLayout";

const AllBrandCategoriesPage = () => {
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [cashback, setCashBack] = useState("");
  const access_token = Cookies.get("ayykori");
  const { data, isLoading, isSuccess, isError } =
    useGetAllCategoryQuery(
      { access_token, page, limit },
      { refetchOnReconnect: true }
    ) || {};

  let content = null;
  // handle loading case
  if (isLoading && !isSuccess) {
    content = (
      <>
        <div
          className="w-full px-3 md:px-[55px] grid grid-cols-2 sm:grid-cols-2  md:grid-cols-3 lg:grid-cols-5
          gap-y-4 gap-x-4 my-7 md:my-10"
        >
          {Array(12)
            .fill()
            ?.map((item, i) => (
              <div
                key={i}
                role="status"
                className="transition-all duration-500 text-gray-600 ease-in-out group py-1 w-full h-20 space-y-4  rounded shadow animate-pulse bg-gray-200"
              ></div>
            ))}
        </div>
      </>
    );
  }
  // handle success case
  if (!isLoading && isSuccess && !isError) {
    content = (
      <>
        <div
          className="w-full px-3 md:px-[55px] grid grid-cols-2 sm:grid-cols-2  md:grid-cols-3 lg:grid-cols-5
        gap-y-4 gap-x-4 my-7 md:my-10"
        >
          {data?.category &&
            data?.category?.map((item, i) => (
              <CategoryCard data={item?.uniqueCategories} key={i} />
            ))}
        </div>
      </>
    );
  }
  // handle error case
  if (!isLoading && isError) {
    content = (
      <>
        <Error />
      </>
    );
  }
  // handle empty case
  if (!isLoading && isSuccess && data?.resData?.length === 0) {
    content = (
      <>
        <NoDataFound />
      </>
    );
  }

  return (
    <>
      <Helmet>
        <title>Categories | Ayykori</title>
      </Helmet>
      <CommonLayout>
        <AfterLoginSlider />
        <div className=" my-16">
          <BrandCategorySortComponent
            title="Categories"
            limit={limit}
            setLimit={setLimit}
            cashback={cashback}
            setCashBack={setCashBack}
          />

          {content}
        </div>
        {/* load more */}
        {data?.total[0]?.count > limit && (
          <div className="w-full px-3 md:px-[55px] flex justify-center md:justify-end items-center my-8">
            <ReactPaginate
              breakLabel="..."
              previousLabel="Prev"
              className="text-primaryColor flex items-center gap-2 text-sm"
              previousClassName="text-primaryColor text-sm"
              nextClassName="text-primaryColor text-sm"
              activeClassName="bg-primaryColor text-sm"
              activeLinkClassName="text-white px-2"
              nextLabel="Next"
              onPageChange={(e) => setPage(e.selected + 1)}
              pageRangeDisplayed={2}
              marginPagesDisplayed={2}
              pageCount={Math.ceil(data?.total[0]?.count / limit)}
              renderOnZeroPageCount={null}
            />
          </div>
        )}
      </CommonLayout>
    </>
  );
};

export default AllBrandCategoriesPage;
