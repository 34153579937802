import Cookies from "js-cookie";
import * as React from "react";
import { Controller, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { BeatLoader } from "react-spinners";
import { showModal } from "../../features/loginModalShowSlice.js/loginModalShowSlice";
import {
  setFormResetRef,
  setManualOrderResetRef,
} from "../../features/manualOrder/formResetSlice";
import { useCreatemanualOrderMutation } from "../../features/manualOrder/manualOrderApiSlice";
import { useCreateImageMutation } from "../../features/manualorderImage/manualOrderImageApislice";
import useAuth from "../../hooks/useAuth";
import ImageUpload from "./ImageUpload";
import SuccessCaseContent from "./SuccessCaseContent";
const Form = () => {
  const auth = useAuth();

  const [globalError, setGlobalError] = React.useState(null);
  const dispatch = useDispatch();
  const { brandDetails: singleBrand } = useSelector(
    (state) => state?.singleBrand
  );
  const { mkactivityId } = useSelector((state) => state?.mkActivityId);

  const [
    createmanualOrder,
    {
      isLoading: manualOrderLoading,
      isSuccess: manualOrderisSuccess,
      reset: manualOrderReset,
      isError: isErrorManualOrder,
      error: errormanualOrder,
    },
  ] = useCreatemanualOrderMutation();
  const [
    createImage,
    {
      isLoading: imageCreateLoading,
      isError: isErrorImageCreate,
      error: errorImageCreate,
    },
  ] = useCreateImageMutation();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    control,
  } = useForm();
  const onSubmit = async (data) => {
    const access_token = Cookies.get("ayykori");

    if (auth || access_token) {
      data.brandDomain = singleBrand.brandDomain || "";

      try {
        const imageRes = await createImage(data);
        // console.log(imageRes);
        if (imageRes?.error) {
          setGlobalError("There is an Error!! please try again later!!!");
          toast.error("There is an Error!! please try again later!!!");
        }
        const { data: linkData } = imageRes?.data || {};
        if (linkData?.link) {
          // console.log(mkactivityId);
          data.order_screenshot = linkData?.link;
          data.activity = mkactivityId;
          const orderRes = await createmanualOrder({ access_token, data });

          const { error, data: orderResData } = orderRes || {};

          // console.log(error);
          if (error) {
            setGlobalError(
              error?.data?.message ||
                "There is an Error!! please try again later!!!"
            );
            toast.error(
              error?.data?.message ||
                "There is an Error!! please try again later!!!"
            );
          }
          // manualOrderReset();
          reset();
        }
      } catch (error) {
        setGlobalError(error);
        toast.error(error || "Error creating  order");
      }
    } else {
      dispatch(showModal());
    }
  };
  React.useEffect(() => {
    dispatch(setManualOrderResetRef(manualOrderReset));
    dispatch(setFormResetRef(reset));
    setGlobalError(null);
  }, [dispatch, manualOrderReset, reset]);
  return (
    <div className=" flex flex-col items-center justify-center w-full  ">
      {globalError && <p className="text-red-500 py-2 ">{globalError}</p>}

      {manualOrderisSuccess ? (
        <SuccessCaseContent />
      ) : (
        <form
          onSubmit={handleSubmit(onSubmit)}
          className=" border px-2 py-2 md:py-8  rounded-md  w-full"
        >
          <div className="">
            {/* <h2 className="text-md font-bold">Billing details</h2> */}
            <div className=" ">
              <div className=" grid grid-cols-9 gap-2 md:gap-4 ">
                <div className="banner col-span-9 xs:col-span-9   sm:col-span-4 h-full ">
                  <div className="screenshot">
                    <label htmlFor="screenshot ">
                      Screenshot of order
                      <span className="text-red-500">*</span>
                    </label>
                    <br />
                    {/* <ImageUpload
                    selectedFile={selectedFile}
                    setSelectedFile={setSelectedFile}
                  /> */}
                    <Controller
                      name="selectedFile" // Give a name to the field
                      control={control}
                      rules={{
                        required:
                          "Please share a screenshot of your order confirmation page",
                      }} // Add validation rules
                      render={({
                        field: { onChange, value },
                        fieldState: { error },
                      }) => (
                        <ImageUpload
                          maxSize={2}
                          selectedFile={value}
                          setSelectedFile={onChange}
                          error={error}
                        />
                      )}
                    />
                    {errors.selectedFile && (
                      <p className=" text-xs md:text-sm text-red-500">
                        {errors.selectedFile.message}
                      </p>
                    )}
                  </div>
                </div>
                <div className="col-span-9 xs:col-span-9 sm:col-span-5 flex flex-col gap-2">
                  {singleBrand?.brandDomain === "keeron.com" ? (
                    <div className="order-id">
                      <label htmlFor="order-id">
                        Course Name<span className="text-red-500">*</span>
                      </label>
                      <br />
                      <input
                        className="w-full border border-gray-400 p-2 focus:outline-none rounded-md"
                        type="text"
                        name="orderId"
                        id="orderId"
                        {...register("orderId", { required: true })}
                        placeholder="Enter Course Name"
                      />
                      {errors.orderId && (
                        <p className=" text-xs md:text-sm text-red-500">
                          Course Name is required
                        </p>
                      )}
                    </div>
                  ) : (
                    <div className="order-id">
                      <label htmlFor="order-id">
                        Order ID<span className="text-red-500">*</span>
                      </label>
                      <br />
                      <input
                        className="w-full border border-gray-400 p-2 focus:outline-none rounded-md"
                        type="text"
                        name="orderId"
                        id="orderId"
                        {...register("orderId", { required: true })}
                        placeholder="Enter Order ID"
                      />
                      {errors.orderId && (
                        <p className=" text-xs md:text-sm text-red-500">
                          Order ID is required
                        </p>
                      )}
                    </div>
                  )}
                  {/* <div className="order-id">
                    <label htmlFor="order-id">
                      Order ID<span className="text-red-500">*</span>
                    </label>
                    <br />
                    <input
                      className="w-full border border-gray-400 p-2 focus:outline-none rounded-md"
                      type="text"
                      name="orderId"
                      id="orderId"
                      {...register("orderId", { required: true })}
                      placeholder="Enter Order ID"
                    />
                    {errors.orderId && (
                      <p className=" text-xs md:text-sm text-red-500">
                        Order ID is required
                      </p>
                    )}
                  </div> */}

                  <div className="date">
                    <label htmlFor="date">
                      Date <span className="text-red-500">*</span>
                    </label>
                    <br />
                    <input
                      className="w-full border border-gray-400 p-2 focus:outline-none rounded-md"
                      type="date"
                      name="date"
                      id="date"
                      {...register("date", { required: true })}
                      placeholder="Enter Order Date"
                    />
                    {errors.date && (
                      <p className=" text-xs md:text-sm text-red-500">
                        Date is required
                      </p>
                    )}
                  </div>

                  <div className="price">
                    <label htmlFor="price">
                      Order Amount <span className="text-red-500">*</span>
                    </label>
                    <br />
                    <input
                      className="w-full border border-gray-400 p-2 focus:outline-none rounded-md"
                      type="number"
                      id="price"
                      name="price"
                      {...register("price", { required: true })}
                      placeholder="Enter Price"
                    />
                    {errors.price && (
                      <p className=" text-xs md:text-sm text-red-500">
                        Price is required
                      </p>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="button flex justify-center mt-3 md:mt-6 mb-2">
            {manualOrderLoading || imageCreateLoading ? (
              <button className="bg-[#05ccbb] text-white px-7 py-2 rounded-md">
                <span>
                  Checking
                  <BeatLoader color="#fff" size={6} />
                </span>
              </button>
            ) : (
              <button
                className="bg-[#11998E] text-white px-7 py-2 rounded-md"
                type="submit"
              >
                <span>Get Cashback</span>
              </button>
            )}
          </div>
        </form>
      )}
    </div>
  );
};

export default Form;
