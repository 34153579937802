import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const BaseURL = process.env.REACT_APP_BASE_URL_SHOP;

export const shopApiSlice = createApi({
  reducerPath: "shopApiSlice",
  baseQuery: fetchBaseQuery({
    baseUrl: BaseURL,
  }),

  tagTypes: ["Product", "Search", "Order"],
  endpoints: (builder) => ({}),
});
