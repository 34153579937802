import React, { useState } from "react";
import { BsArrowRight } from "react-icons/bs";

const BrandDescription = ({ response }) => {
  const [sliceValue, setSliceValue] = useState(false);
  const { data } = response;
  const brandDetails = data?.resData[0];
  return (
    <div className="px-3 md:px-[55px] flex flex-col gap-y-2 pb-10">
      <h1 className="text-sm md:text-xl text-gray-700 font-semibold font-inter">
        Description of the Brand
      </h1>
      <p className="text-xs md:text-sm text-gray-500 font-normal font-outfit text-justify">
        {sliceValue
          ? brandDetails?.brandDescriptions
          : brandDetails?.brandDescriptions?.slice(0, 500)}
      </p>
      {!sliceValue && (
        <span
          onClick={() => setSliceValue(true)}
          className=" cursor-pointer flex items-center font-outfit text-xs md:text-base gap-x-1 text-gray-800"
        >
          See more <BsArrowRight />
        </span>
      )}
    </div>
  );
};

export default BrandDescription;
