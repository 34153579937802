import { useFormik } from "formik";
import React from "react";
import { RxCross1 } from "react-icons/rx";
const WorkShopModal = ({ show, setShow }) => {
  const initialValues = {
    name: "",
    email: "",
    phone: "",
    occupation: "",
    nid: "",
    timeslot: "",
  };
  const { values, handleBlur, handleSubmit, handleChange, errors, touched } =
    useFormik({
      initialValues,
      // validationSchema,
      onSubmit: (values, action) => {},
    });
  return (
    <div
      className={`w-screen h-screen bg-[rgba(0,0,0,0.5)] ${
        show ? "flex" : "hidden"
      } items-center justify-center fixed top-0 left-0 right-0 bottom-0 z-[10000] `}
    >
      <div className=" w-[90%] md:w-[70%] lg:w-[55%] h-[90vh] md:h-[80vh] overflow-y-auto scrollbar-w-0 bg-white rounded-lg p-10 relative">
        <h1 className="text-lg text-gray-500 font-outfit font-medium">
          Ayykori Online Workshop
        </h1>
        {/* <p className="text-sm px-2 text-white font-outfit font-light bg-primaryColor rounded-sm">
          Every Saturday from 03:00AM to 04:00PM
        </p> */}
        <p className="text-sm px-2 text-white font-outfit font-light bg-primaryColor rounded-sm">
          Every Saturday to Thursday
        </p>
        <form onSubmit={handleSubmit} className="flex flex-col gap-6 mt-5">
          <div className="grid grid-cols-2 gap-4">
            <div className="mx-auto w-full md:mx-0 ">
              <label
                className="text-[1rem] font-medium text-gray-700"
                htmlFor=""
              >
                Name <span className="text-red-600">*</span>
              </label>
              <input
                type="text"
                name="name"
                placeholder="Enter Your Full Name"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.name}
                className="w-full outline-none text-sm px-3 py-1.5 rounded-full border"
              />
              {errors.name && touched.name ? (
                <p className="text-sm text-red-500">{errors.name}</p>
              ) : null}
            </div>
            <div className="mx-auto w-full md:mx-0 ">
              <label
                className="text-[1rem] font-medium text-gray-700"
                htmlFor=""
              >
                Email Address <span className="text-red-600">*</span>
              </label>
              <input
                type="text"
                name="email"
                placeholder="Enter Your Email Address"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.email}
                className="w-full outline-none text-sm px-3 py-1.5 rounded-full border"
              />
              {errors.email && touched.email ? (
                <p className="text-sm text-red-500">{errors.email}</p>
              ) : null}
            </div>
          </div>
          <div className="grid grid-cols-2 gap-4">
            <div className="mx-auto w-full md:mx-0 ">
              <label
                className="text-[1rem] font-medium text-gray-700"
                htmlFor=""
              >
                Gender <span className="text-red-600">*</span>
              </label>

              <div className=" text-sm px-3 py-1.5 rounded-full border">
                <select
                  name="gender"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.gender}
                  className="w-full outline-none"
                >
                  <option value="">Choose Gender</option>
                  <option value="Male">Male</option>
                  <option value="Female">Female</option>
                  <option value="Other">Other</option>
                </select>
              </div>
              {errors.gender && touched.gender ? (
                <p className="text-sm text-red-500">{errors.gender}</p>
              ) : null}
            </div>
            <div className="mx-auto w-full md:mx-0 ">
              <label
                className="text-[1rem] font-medium text-gray-700"
                htmlFor=""
              >
                Your Occupation <span className="text-red-600">*</span>
              </label>

              <div className=" text-sm px-3 py-1.5 rounded-full border">
                <select
                  name="occupation"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.occupation}
                  className="w-full outline-none"
                >
                  <option value="">Your Occupation</option>
                  <option value="Student">Student</option>
                  <option value="Housewife">Housewife</option>
                  <option value="Employee">Employee</option>
                  <option value="Businessman">Businessman</option>
                  <option value="Unemployed">Unemployed</option>
                  <option value="Other">Other</option>
                </select>
              </div>
              {errors.occupation && touched.occupation ? (
                <p className="text-sm text-red-500">{errors.occupation}</p>
              ) : null}
            </div>
          </div>
          <div className="grid grid-cols-2 gap-4">
            <div className="mx-auto w-full md:mx-0 ">
              <label
                className="text-[1rem] font-medium text-gray-700"
                htmlFor=""
              >
                Phone <span className="text-red-600">*</span>
              </label>
              <input
                type="text"
                name="phone"
                placeholder="Enter Your Phone Number"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.phone}
                className="w-full outline-none text-sm px-3 py-1.5 rounded-full border"
              />
              {errors.phone && touched.phone ? (
                <p className="text-sm text-red-500">{errors.phone}</p>
              ) : null}
            </div>
            <div className="mx-auto w-full md:mx-0 ">
              <label
                className="text-[1rem] font-medium text-gray-700"
                htmlFor=""
              >
                NID Number <span className="text-red-600">*</span>
              </label>
              <input
                type="text"
                name="phone"
                placeholder="Enter Your NID Number"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.nid}
                className="w-full outline-none text-sm px-3 py-1.5 rounded-full border"
              />
              {errors.nid && touched.nid ? (
                <p className="text-sm text-red-500">{errors.phone}</p>
              ) : null}
            </div>
          </div>
          <div className="grid grid-cols-2 gap-4">
            <div className="mx-auto w-full md:mx-0 ">
              <label
                className="text-[1rem] font-medium text-gray-700"
                htmlFor=""
              >
                Choose Your Time Slot <span className="text-red-600">*</span>
              </label>

              <div className=" text-sm px-3 py-1.5 rounded-full border">
                <select
                  name="timeslot"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.timeslot}
                  className="w-full outline-none"
                >
                  <option value="">Choose Timeslot</option>
                  <option value="09:30AM-10:30PM">09:30AM to 10:0PM</option>
                  <option value="11:00AM-11:30PM">11:00AM to 11:30PM</option>
                </select>
              </div>
              {errors.gender && touched.gender ? (
                <p className="text-sm text-red-500">{errors.gender}</p>
              ) : null}
            </div>
            <div className="flex flex-col justify-end">
              <button className="w-fit border bg-primaryColor text-white outline-none px-4 py-1 rounded-full">
                Submit
              </button>
            </div>
          </div>
        </form>

        <span
          onClick={() => setShow(false)}
          className="absolute top-4 right-4 cursor-pointer"
        >
          <RxCross1 size={20} className="text-gray-500" />
        </span>
      </div>
    </div>
  );
};

export default WorkShopModal;
