import React, { useEffect, useState } from "react";
import { BiErrorCircle } from "react-icons/bi";
import { BsCheckCircleFill } from "react-icons/bs";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import bd from "../../Assets/icons/bd.png";
import logo from "../../Assets/icons/logo.png";
import CreateOrUpdateUser from "./CreateOrUpdateUser";
import InitialDefault from "./InitialDefault";
import Journey from "./Journey";
import LoginWhenVarified from "./LoginWhenVarified";
import OTPNotMatch from "./OTPNotMatch";
import OTPVarification from "./OTPVarification";
import ResetPassword from "./ResetPassword";

const LoginModal = ({ source }) => {
  const { phone, user, time, prevSend } = useSelector(
    (state) => state?.authHelper
  );

  const [query] = useSearchParams();
  const refCode = query?.get("r_id");
  const [phoneNumber, setPhoneNumber] = useState("");

  const [steps, setSteps] = useState("");

  let content = <InitialDefault setSteps={setSteps} />;

  if (steps === "InitialDefault") {
    content = <InitialDefault setSteps={setSteps} />;
  }

  if (steps === "LoginWhenVarified") {
    content = <LoginWhenVarified bd={bd} setSteps={setSteps} />;
  }
  if (steps === "OTPNotMatch") {
    content = <OTPNotMatch Icon={BiErrorCircle} setSteps={setSteps} />;
  }

  if (steps === "CreateOrUpdateUser") {
    content = (
      <CreateOrUpdateUser phone={phone} user={user} setSteps={setSteps} />
    );
  }

  if (steps === "OTPNotMatch") {
    if (source === "page") {
      content = <OTPNotMatch Icon={BsCheckCircleFill} setSteps={setSteps} />;
    } else {
      content = <OTPNotMatch Icon={BsCheckCircleFill} setSteps={setSteps} />;
    }
  }

  // reset password component render
  if (steps === "ResetPassword") {
    content = <ResetPassword phone={phone} setSteps={setSteps} />;
  }

  // forgot password otp varification
  if (steps === "ResetOTPVarification") {
    content = (
      <OTPVarification
        source={"ResetOTPVarification"}
        phone={phone}
        setSteps={setSteps}
        time={time}
        prevSend={prevSend}
      />
    );
  }
  if (steps === "OTPVarification") {
    content = (
      <OTPVarification
        source={"phoneOTPVarification"}
        phone={phone}
        setSteps={setSteps}
        time={time}
        prevSend={prevSend}
      />
    );
  }

  useEffect(() => {
    if (refCode) {
      localStorage?.setItem("referral_code", refCode);
    }
  }, [refCode]);

  return (
    <div
      className={` relative grid lg:grid-cols-2 md:grid-cols-2 grid-cols-1 ${
        source !== "page" ? "gap-10" : ""
      }`}
    >
      <div className=" bg-gray-700 w-full h-24 absolute top-0 left-0 rounded-t-md z-[10]">
        <div className=" flex gap-2 items-center my-auto ml-6 py-4 ">
          <img src={logo} alt="" className="h-12 mb-1" />
          <h2 className="text-white text-lg ">Get started with ayykori </h2>
        </div>
      </div>

      <div className=" mt-[70px] ml-6 lg:block md:block hidden">
        <Journey setSteps={setSteps} />
      </div>
      <div className="lg:mt-16 md:mt-[60px] mt-[80px] mx-4 ">
        <div className=" ">
          {content}
          {/* 
          {steps === 7 && <Journey setSteps={setSteps} />}  */}
        </div>
      </div>
    </div>
  );
};

export default LoginModal;
