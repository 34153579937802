import React from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import error404 from "../../Assets/images/gif/error-404.gif";
const Error404Page = () => {
  return (
    <>
      <Helmet>
        <title>Error-404 | Ayykori</title>
      </Helmet>

      <div className="w-full h-full flex flex-col justify-start items-center">
        <div className="w-[600px]">
          <img src={error404} className="w-full h-full object-cover " alt="" />
        </div>
        <Link
          to={"/"}
          className="-mt-36 ml-8 text-base text-primaryColor font-medium border-b"
        >
          Back To Homepage
        </Link>
      </div>
    </>
  );
};

export default Error404Page;
