export const journeyData = [
  {
    img: "https://www.pngall.com/wp-content/uploads/12/Illustration-PNG.png",
    title: "Open an account",
    description:
      "Get started on your financial journey by opening an account with us. It's quick and easy. Enjoy secure access to a range of banking services.",
  },
  {
    img: "https://png.pngtree.com/png-vector/20220801/ourmid/pngtree-people-business-activities-smartphone-communication-png-image_6051535.png",
    title: "Explore products",
    description:
      "Once you've opened your account, explore our diverse range of products and services. From savings accounts to loans and investment options.",
  },
  {
    img: "https://i.pinimg.com/originals/d9/9e/15/d99e156ba0b07feefbabef871eda0f23.png",
    title: "Generate Link",
    description:
      "Easily generate shareable links for your purchases and transactions. Share your favorite products and deals with friends and family, and earn rewards.",
  },
  {
    img: "https://png.pngtree.com/png-vector/20220614/ourmid/pngtree-support-helping-customer-with-login-problem-png-image_5089033.png",
    title: "Get Cashback",
    description:
      "Start earning cashback rewards on your transactions. Whether you're shopping, investing, or using our services, you can enjoy cashback benefits.",
  },
];
