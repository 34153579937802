import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const BaseURL = process.env.REACT_APP_BASE_URL_KB;

export const apiSliceKrishanBazar = createApi({
  reducerPath: "apiSliceKrishanBazar",
  baseQuery: fetchBaseQuery({
    baseUrl: BaseURL,
  }),
  endpoints: (builder) => ({}),
});
