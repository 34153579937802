import React from "react";

const BrandImageCard = ({ data }) => {
  return (
    <div className=" flex items-center justify-center rounded-md h-32 bg-white shadow-[0px_2px_2px_#00000040] hover:scale-105 duration-300 select-none px-10 py-1">
      <img className="w-full   " src={data.brandimg} alt="" />
    </div>
  );
};

export default BrandImageCard;
