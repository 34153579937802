import React from "react";
import { BiSort } from "react-icons/bi";
import { RxDashboard } from "react-icons/rx";
import { useDispatch, useSelector } from "react-redux";
import { changeLimit, changeSort } from "../../features/brand/brandSlice";

const CommonSort = () => {
  const { limit, cashback } = useSelector((state) => state.brand);
  const dispatch = useDispatch();

  const handleLimit = (e) => {
    dispatch(changeLimit(e.target.value));
  };
  const handleCashback = (e) => {
    dispatch(changeSort(e.target.value));
  };

  return (
    <div className="flex items-center gap-x-2 mb-2 text-gray-500">
      <div className="flex items-center border text-sm rounded-lg px-3 gap-x-2">
        <RxDashboard />
        Show
        <select
          value={limit}
          onChange={handleLimit}
          className=" px-1 py-2 bg-transparent  text-gray-500 outline-none cursor-pointer"
        >
          <option value={10}>10</option>
          <option value={15}>15</option>
          <option value={20}>20</option>
          <option value={25}>25</option>
        </select>
      </div>
      <div className="flex items-center border rounded-lg text-sm px-2">
        <BiSort />
        Sort By:
        <select
          onChange={handleCashback}
          value={cashback}
          className=" px-2 py-2 bg-transparent  text-gray-500 outline-none cursor-pointer"
        >
          <option value={""}>Cashback</option>
          <option value={"descending"}>Highest Cashback</option>
          <option value={"ascending"}>Lowest Cashback</option>
        </select>
      </div>
    </div>
  );
};

export default CommonSort;
