import { apiRequest } from "../apiRequest/apiRequest";

export const referralApiSlice = apiRequest.injectEndpoints({
  endpoints: (builder) => ({
    createReferral: builder.mutation({
      query: (data) => {
        let device = localStorage.getItem("app") ? "app" : undefined;
        data.device = device;
        return {
          url: `/create-reffer`,
          method: "POST",
          headers: {
            "Content-Type": "application/json;charset=UTF-8",
          },
          body: data,
        };
      },
      invalidatesTags: ["Refer"],
    }),
    getReferralUsers: builder.query({
      query: (data) => {
        const { access_token } = data || {};
        return {
          url: `/get-reffer-details`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${access_token}`,
            "Content-Type": "application/json;charset=UTF-8",
          },
        };
      },
      providesTags: ["Refer"],
    }),
    createRefferalUserCount: builder.mutation({
      query: (r_) => {
        return {
          url: `/refferals/user-count`,
          method: "POST",
          body: { r_ },
          headers: {
            "Content-Type": "application/json;charset=UTF-8",
          },
        };
      },
      providesTags: ["Refer"],
    }),
  }),
});

export const {
  useCreateReferralMutation,
  useGetReferralUsersQuery,
  useCreateRefferalUserCountMutation,
} = referralApiSlice;
