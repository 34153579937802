import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/dist/query";
import errorHelperReducer from "../features/ErrorHelper/ErrorHelperSlice";
import { apiRequest } from "../features/apiRequest/apiRequest";
import { apiSliceKrishanBazar } from "../features/apiRequest/kishanBazarApiSlice";
import { shopApiSlice } from "../features/apiRequest/shopApiSlice";
import authHelperSlice from "../features/auth/authHelperSlice";
import authReducer from "../features/auth/authSlice";
import authUserReducer from "../features/auth/authUserSlice";
import goPrevNextReducer from "../features/backPrev/goBackSlice";
import brandReducer from "../features/brand/brandSlice";
import cartReducer from "../features/cart/cartSlice";
import loginModalShowSlice from "../features/loginModalShowSlice.js/loginModalShowSlice";
import modalReducer from "../features/modal/modalSlice";
import paymentReducer from "../features/payment/paymentSlice";
import productReducer from "../features/product/productSlice";

import { imageCreateApiSlice } from "../features/apiRequest/imageCreateApiSlice";
import { managementApiSlice } from "../features/apiRequest/managementApiSlice";
import singleBrandReducer from "../features/brand/singleBrandSlice";
import formResetReducer from "../features/manualOrder/formResetSlice";
import mkActivityIdReducer from "../features/mkActivityId/mkActivityIdSlice";
import reloadReducer from "../features/reload/reloadSlice";
import searchReducer from "../features/search/searchSlice";
import sharelinkReducer from "../features/sharelink/sharelinkSlice";
import shopReducer from "../features/shop/shopSlice";
import wishlistReducer from "../features/wishlist/wishlistSlice";

// import userReducer from '../State/userSlice'
export const store = configureStore({
  reducer: {
    [apiRequest.reducerPath]: apiRequest.reducer,
    [apiSliceKrishanBazar.reducerPath]: apiSliceKrishanBazar.reducer,
    [shopApiSlice.reducerPath]: shopApiSlice.reducer,
    [managementApiSlice.reducerPath]: managementApiSlice.reducer,
    [imageCreateApiSlice.reducerPath]: imageCreateApiSlice.reducer,
    modal: modalReducer,
    auth: authReducer,
    authHelper: authHelperSlice,
    goPrevNextSlice: goPrevNextReducer,
    errorHelperSlice: errorHelperReducer,
    authUser: authUserReducer,
    loginModalShow: loginModalShowSlice,
    brand: brandReducer,
    singleBrand: singleBrandReducer,
    product: productReducer,

    search: searchReducer,
    sharelink: sharelinkReducer,
    reload: reloadReducer,
    wishlist: wishlistReducer,
    searchShop: searchReducer,
    cart: cartReducer,
    payment: paymentReducer,
    shop: shopReducer,
    formReset: formResetReducer,
    mkActivityId: mkActivityIdReducer,
  },
  middleware: (gDM) =>
    gDM().concat(
      apiRequest.middleware,
      apiSliceKrishanBazar.middleware,
      shopApiSlice.middleware,
      managementApiSlice.middleware,
      imageCreateApiSlice.middleware
    ),
  devTools: false,
});
setupListeners(store.dispatch);
