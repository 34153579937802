import React, { useState } from "react";
import toast from "react-hot-toast";
import { BiChevronRight, BiLockAlt, BiLockOpenAlt } from "react-icons/bi";
import { useDispatch, useSelector } from "react-redux";
import { BeatLoader } from "react-spinners";
import { setErrorMsg } from "../../features/ErrorHelper/ErrorHelperSlice";
import {
  useOtpLoginUserMutation,
  useResetPasswordOtpSendMutation,
} from "../../features/auth/authApiSlice";
import { goback } from "../../features/backPrev/goBackSlice";
import { hideModal } from "../../features/loginModalShowSlice.js/loginModalShowSlice";
import BackButton from "./BackButton";

const LoginWhenVarified = ({ bd, setSteps }) => {
  const dispatch = useDispatch();
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const { phone } = useSelector((state) => state?.authHelper);
  const [
    resetPasswordOtpSend,
    {
      isError: isErrorresetPasswordOtpSend,
      isSuccess: isSuccessresetPasswordOtpSend,
      isLoading: isLoadingresetPasswordOtpSend,
      data: dataresetPasswordOtpSend,
      error: errorresetPasswordOtpSend,
    },
  ] = useResetPasswordOtpSendMutation() || {};

  const [
    otpLoginUser,
    {
      isError: isErrorOTPLogin,
      isSuccess: isSuccessOTPLogin,
      isLoading: isLoadingOTPLogin,
      data: dataOTPLogin,
      error: errorOTPLogin,
    },
  ] = useOtpLoginUserMutation() || {};
  const handleForgotPassword = async (e) => {
    const res = await resetPasswordOtpSend({
      phone: phone,
    });
    if (res.data) {
      setSteps("ResetOTPVarification");
    }
  };

  const handlePasswordSubmit = async (e) => {
    e.preventDefault();
    if (phone && password) {
      const res = await otpLoginUser({
        password,
        phone,
      });
      const { data, error } = res || {};

      if (data) {
        dispatch(
          setErrorMsg({
            errorMsg: data?.message || "Welcome On Board",
            isError: false,
          })
        );

        setSteps("OTPNotMatch");
        setTimeout(() => {
          dispatch(hideModal());
          toast.success("Login Successful!!");
        }, 800);
      } else if (error) {
        dispatch(
          setErrorMsg({
            errorMsg: error?.data?.message || "Please Try again!!",
            isError: true,
          })
        );

        dispatch(
          goback({
            prevComponent: "LoginWhenVarified",
          })
        );
        setSteps("OTPNotMatch");
      }
    }
  };
  const handlePasswordShow = (e) => {
    e.preventDefault();
    setShowPassword(!showPassword);
  };
  return (
    <div
      style={{ zIndex: 999 }}
      className="relative bg-white border lg:p-10 md:p-8 p-5 min-h-[400px] rounded-lg flex flex-col justify-center"
    >
      <BackButton setSteps={setSteps} />
      <h1 className="text-3xl font-bold text-gray-700 mb-4">
        Start your cashback journey
      </h1>
      <h3 className="text-sm text-gray-600">Enter your password</h3>
      <form onSubmit={handlePasswordSubmit}>
        <div className="h-[120px] mt-3">
          <div className="relative mt-3">
            <input
              type="text"
              placeholder="Phone"
              value={phone}
              maxLength={11}
              required
              disabled
              readOnly
              className="border border-gray-300 py-2 pl-[74px] pr-2 rounded-md focus:outline-none focus:border-primaryColor text-gray-700 w-full"
            />
            <div className="absolute top-1/2 -translate-y-1/2 left-2 flex items-center gap-2 text-gray-500">
              <img src={bd} alt="" className="h-4" />
              +88
            </div>
          </div>
          <div className="relative mt-3 ">
            <input
              type={showPassword ? "text" : "password"}
              placeholder="Password"
              required
              onChange={(e) => setPassword(e.target.value)}
              className="border border-gray-300 py-2 pl-8 pr-2 rounded-md focus:outline-none focus:border-primaryColor text-gray-700 w-full"
            />
            <div className="absolute top-1/2 -translate-y-1/2 left-2 flex items-center gap-2 text-gray-500">
              <button
                type="button"
                className=" cursor-pointer border-none"
                onClick={handlePasswordShow}
              >
                {showPassword ? <BiLockOpenAlt /> : <BiLockAlt />}
              </button>
            </div>
          </div>
        </div>

        {isLoadingOTPLogin ? (
          <button
            type="submit"
            className="w-fit bg-primaryColor pt-1 pb-1.5 px-6 text-white flex items-center gap-1 rounded-full group hover:opacity-80 transition duration-300 cursor-not-allowed lg:my-3"
            disabled
          >
            Login
            <BeatLoader color="#fff" size={8} />{" "}
            <BiChevronRight className="group-hover:translate-x-1 transition duration-300 mt-0.5" />
          </button>
        ) : (
          <button
            type="submit"
            className="w-fit bg-primaryColor pt-1 pb-1.5 px-6 text-white flex items-center gap-1 rounded-full group hover:opacity-80 transition duration-300 lg:my-3 "
          >
            Login
            <BiChevronRight className="group-hover:translate-x-1 transition duration-300 mt-0.5" />
          </button>
        )}
      </form>

      {isLoadingresetPasswordOtpSend ? (
        <button
          className=" text-blue-400 hover:text-blue-600 transition duration-300 text-sm px-1  py-2 lg:absolute lg:bottom-[100px]  text-left  mt-2 cursor-not-allowed"
          disabled
        >
          Sending OTP...
        </button>
      ) : (
        <button
          onClick={handleForgotPassword}
          className=" text-blue-500 hover:text-blue-600 transition duration-300 text-sm px-1 cursor-pointer lg:absolute lg:bottom-[100px]  text-left  mt-2 py-2"
        >
          Forgot password?
        </button>
      )}
    </div>
  );
};

export default LoginWhenVarified;
