import React, { useState } from "react";
import { MdArrowForwardIos } from "react-icons/md";
import { Link } from "react-router-dom";
import { flashSaleTime } from "../../Data/flashSaleData";
import CommonSort from "../sort/CommonSort";
const CommonComponent = ({ data }) => {
  const [flashSaleData, setFlashSaleData] = useState(flashSaleTime);
  const [dateTimeAfterThreeDays, setDateTimeAfterThreeDays] = useState();
  // if (data?.flashSale) {
  //   console.log(data?.flashSale);
  //   const dateAndTime = dateDifference(
  //     flashSaleData?.startDate,
  //     flashSaleData?.endDate
  //   );

  //   const NOW_IN_MS = new Date().getTime();
  //   const dateTimeAfterThreeDays = NOW_IN_MS + dateAndTime.totalMs;
  //   setDateTimeAfterThreeDays(dateTimeAfterThreeDays);
  // }

  return (
    <div className="w-full px-3 md:px-[55px] my-2 mx-auto md:my-5 ">
      <div className=" text-center flex items-center justify-between gap-2 ">
        <div className=" w-full h-[2.5px] flex justify-between items-end gap-x-5 bg-gray-300 ">
          <h1
            className=" w-fit text-sm sm:text-lg md:text-2xl text-gray-600 font-medium pb-2 border-b-[3px]
       border-primaryColor "
          >
            {data?.title}
          </h1>
          {/* {data?.flashSale && (
            <CountdownTimer
              title="Flash Sale"
              targetDate={dateTimeAfterThreeDays}
              startDate={flashSaleTime.startDate}
            />
          )} */}

          {/* {data?.end && (
            <p className=" text-gray-600 m-2 py-1 px-5 border-2 whitespace-pre border-primaryColor rounded-full">
              {moment().format("llll")}
            </p>
          )} */}
          {data?.showSort && <CommonSort />}
        </div>

        <div className="pb-3 text-right self-start flex items-center gap-2 ">
          <Link
            to={data?.link}
            className=" text-xs md:text-base text-right text-primaryColor whitespace-pre font-medium"
          >
            {data?.linktitle}
          </Link>
          {data?.show && (
            <div className=" text-primaryColor flex gap-8">
              {/* <MdArrowForwardIos
              size={20}
              className="rotate-180 cursor-pointer"
              //   onClick={prevHandler}
            /> */}
              <MdArrowForwardIos
                size={12}
                className="cursor-pointer"
                //   onClick={nextHandler}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default CommonComponent;
