import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  limit: 12,
  page: 1,
  cashback: "",
  brandCount: 0,
};

export const brandSlice = createSlice({
  name: "brand",
  initialState,
  reducers: {
    changeLimit: (state, action) => {
      state.limit = action.payload;
    },
    resetLimit: (state) => {
      state.limit = 12;
    },
    changeSort: (state, action) => {
      state.cashback = action.payload;
    },
    resetSort: (state) => {
      state.cashback = "";
    },
    changePage: (state, action) => {
      state.page = action.payload;
    },
    resetPage: (state) => {
      state.page = 1;
    },
    changeBrandCount: (state, action) => {
      state.brandCount = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  changeLimit,
  resetLimit,
  changeSort,
  resetSort,
  changePage,
  resetPage,
  changeBrandCount
} = brandSlice.actions;

export default brandSlice.reducer;
