import React from "react";
import { HiXMark } from "react-icons/hi2";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { hideModal } from "../../features/loginModalShowSlice.js/loginModalShowSlice";

const Modal = ({ isOpen, setIsOpen, height, width, children }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const handleClose = () => {
    dispatch(hideModal());

    if (location?.pathname) {
      navigate(location.pathname);
    } else {
      navigate("/");
    }
  };
  return (
    <div
      className={`fixed bg-black bg-opacity-10  grid place-items-center inset-0 z-[10000]`}
    >
      <div
        style={{ height: height, maxWidth: width, width: "full" }}
        className={`relative  mx-3 bg-gradient-to-tr from-blue-50 via-purple-50 to-emerald-50 rounded-md border shadow-lg ${
          isOpen ? "fade-in" : "fade-out"
        }`}
      >
        <HiXMark
          onClick={handleClose}
          className="bg-slate-500 text-white absolute text-3xl top-4 right-5 cursor-pointer hover:bg-slate-200 text-gray-600 hover:text-gray-800 p-1 rounded-full transition duration-300 z-[100]"
        />
        {children}
      </div>
    </div>
  );
};

export default Modal;
