import Tippy from "@tippyjs/react";
import { useFormik } from "formik";
import Cookies from "js-cookie";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import {
  AiOutlineBranches,
  AiOutlinePhone,
  AiOutlineUser,
} from "react-icons/ai";
import { BsBriefcase, BsExclamationDiamond } from "react-icons/bs";
import { CiBank, CiLocationArrow1, CiLocationOn } from "react-icons/ci";
import { GiMoneyStack } from "react-icons/gi";
import { HiOutlineMail } from "react-icons/hi";
import { MdEdit, MdOutlineNumbers } from "react-icons/md";
import { RiMapPinUserLine } from "react-icons/ri";
import { TfiReload } from "react-icons/tfi";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { FadeLoader } from "react-spinners";
import "tippy.js/dist/tippy.css"; // optional
import avatar from "../../Assets/images/placeholder-image/avatar.png";
import ButtonLoader from "../../Loading/ButtonLoader";
import {
  useGetSingleUserQuery,
  useUpdateUserMutation,
} from "../../features/user/userApiSlice";
import WarningTooltip from "../../utils/WarningTooltip";
import { updateProfileSchema } from "../../validation/Schema";
// fullname
// email
// password
const UpdateProfilePage = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [, setImageSize] = useState(0);
  const [, setPhoto] = useState("");
  const access_token = Cookies?.get("ayykori");
  const { isLoading, data } =
    useGetSingleUserQuery(access_token, { refetchOnReconnect: true }) || {};

  const [
    updateUser,
    {
      isLoading: isLoadingUser,
      isError: isErrorUser,
      isSuccess: isSuccessUser,
      error: errorUser,
    },
  ] = useUpdateUserMutation() || {};

  // check file size
  const fileSize = (_size) => {
    // console.log(_size/(824*1024))
    var fSExt = ["Bytes", "KB", "MB", "GB"],
      i = 0;
    while (_size > 900) {
      _size /= 1024;
      i++;
    }
    var exactSize = Math.round(_size * 100) / 100 + " " + fSExt[i];
    return exactSize;
  };
  // converting normal image to base64 string
  const convertImageToBase64 = (file) => {
    if (file?.type?.split("/")[0] !== "image") {
      toast.error("Only Image");
    } else {
      const reader = new FileReader();
      if (file) {
        reader.readAsDataURL(file);
        reader.onload = () => {
          setPhoto(reader.result);
        };
        reader.onerror = (error) => {
          // console.log(error);
        };
      }
    }
  };

  const userInitialValue = {
    gender: "",
    occupation: data?.user?.occupation || "",
    age: "",
    address: data?.user?.address || "",
    paymentMethod: "",
    accountnumber: "",
    accountname: "",
    bankname: "",
    branchname: "",
    districtname: "",
    routingno: "",
    bkashno: "",
    nagadno: "",
  };

  // update profile form
  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      initialValues: userInitialValue,
      validationSchema: updateProfileSchema,
      onSubmit: async (values, action) => {
        updateUser({ access_token, data: values });
        // action.resetForm();
      },
    });

  // update profile success message
  useEffect(() => {
    if (isSuccessUser && !isErrorUser) {
      toast.success("Profile Updated Successfully!!");
      navigate("/my-account");
    }
  }, [isSuccessUser, isErrorUser]);

  // update profile error message
  useEffect(() => {
    if (isErrorUser) {
      toast.error("Profile Not Updated!!");
    }
  }, [isErrorUser]);

  // after navigate this page show in top
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="w-full py-2 md:p-8">
      <div className="flex items-start justify-between">
        <div className="w-full">
          <div className="w-full flex items-center justify-between">
            <h1 className="text-base md:text-lg text-gray-500 font-outfit font-medium relative">
              Personl Information
              {pathname === "/my-account" && (
                <span className="absolute top-0 -right-16">
                  <Link
                    to={`/my-account/update-account`}
                    className="text-blue-400 text-xs font-normal underline"
                  >
                    Edit Profile
                  </Link>
                </span>
              )}
            </h1>

            <h4 className="text-[10px] md:text-sm text-gray-400 whitespace-pre">
              Member Since:
              <span className="text-[10px] md:text-sm text-primaryColor ml-1">
                {moment(data?.user?.createdAt).format("LL")}
              </span>
            </h4>
          </div>
          <p className="text-xs md:text-base text-gray-400 font-outfit font-light">
            Manage your Ayykori account with easy access to personal info,
            account balance & more.
          </p>
        </div>
      </div>
      <div className="w-full flex md:hidden justify-center items-center mt-4">
        <div className="w-20 h-20 relative">
          <img
            src={data?.user?.googlePhoto || data?.user?.photo?.url || avatar}
            alt=""
            className="w-full h-full rounded-full border object-cover"
          />
          <label
            htmlFor="image"
            title="Update Profile Image"
            className="absolute cursor-pointer bottom-1 right-1 text-white p-1 rounded-full bg-primaryColor"
          >
            <MdEdit size={10} />
            <input
              id="image"
              type="file"
              className="hidden"
              onChange={(e) => {
                setImageSize(fileSize(e.target?.files[0]?.size));
                convertImageToBase64(e.target.files[0]);
              }}
            />
          </label>

          {(isLoading || isLoadingUser) && (
            <span className="absolute top-[50%] left-[50%] flex justify-center items-center">
              <FadeLoader
                color="#ffffff"
                height={5}
                margin={-9}
                radius={5}
                width={3}
              />
            </span>
          )}
          {/* <span
                    className={`absolute top-0 right-0 w-2 h-2 ${
                      data?.user?.userStatus === "Active"
                        ? "bg-green-500"
                        : data?.user?.userStatus === "Restricted"
                        ? "bg-blue-500"
                        : "bg-red-500"
                    } rounded-full`}
                  ></span> */}
        </div>
      </div>

      <form onSubmit={handleSubmit} className="md:my-7 relative">
        <div
          className={`${
            pathname === "/my-account/update-account" ? "hidden" : "block"
          } absolute top-0 left-0 right-0 bottom-0 w-full h-full`}
        ></div>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-x-4">
          <div className="flex flex-col gap-y-1 my-2 text-sm">
            <label htmlFor="">User Name</label>
            <div className=" text-gray-500 flex items-center border gap-2 bg-white rounded-md px-3 py-2">
              <AiOutlineUser size={20} className="text-primaryColor" />
              <input
                type="text"
                disabled={data?.user?.fullname ? true : false}
                value={
                  data?.user?.fullname ? data?.user?.fullname : values?.fullname
                }
                className={`${
                  data?.user?.fullname ? "cursor-not-allowed" : "cursor-pointer"
                } outline-none border-none w-full  bg-transparent text-gray-500`}
              />
            </div>
          </div>
          <div className="flex flex-col gap-y-1 my-2 text-sm">
            <label htmlFor="">Email Address</label>
            <div className=" text-gray-500 flex items-center border gap-2 bg-white rounded-md px-3 py-2">
              <HiOutlineMail size={20} className="text-primaryColor" />
              <input
                type="text"
                disabled={data?.user?.email ? true : false}
                value={data?.user?.email}
                className="cursor-not-allowed outline-none border-none w-full  bg-transparent text-gray-500"
              />
            </div>
          </div>
          <div className={`flex flex-col gap-y-1 my-2 text-sm`}>
            <label htmlFor="">Gender</label>
            <div
              className={` text-gray-500 flex items-center border gap-2 bg-white rounded-md px-3 py-2 ${
                data?.user?.gender && "cursor-not-allowed"
              }`}
            >
              <select
                type="text"
                name="gender"
                disabled={data?.user?.gender ? true : false}
                value={data?.user?.gender ? data?.user?.gender : values?.gender}
                onChange={handleChange}
                onBlur={handleBlur}
                className="outline-none border-none w-full  bg-transparent text-gray-500"
              >
                <option value="">Choose Gender</option>
                <option value="Male">Male</option>
                <option value="Female">Female</option>
                <option value="Other">Other</option>
              </select>
            </div>
            {errors.gender && touched.gender ? (
              <p className="text-xs text-red-500">{errors.gender}</p>
            ) : null}
          </div>
          <div className="flex flex-col gap-y-1 my-2 text-sm">
            <label htmlFor="">Address</label>
            <div
              className={`${
                data?.user?.address && "cursor-not-allowed"
              } text-gray-500 flex items-center border gap-2 bg-white rounded-md px-3 py-2`}
            >
              <CiLocationOn size={20} className="text-primaryColor" />
              <input
                type="text"
                name="address"
                value={
                  data?.user?.address ? data?.user?.address : values?.address
                }
                onChange={handleChange}
                onBlur={handleBlur}
                readOnly={data?.user?.address ? true : false}
                // disabled={data?.user?.address ? true : false}
                placeholder="Address"
                className="outline-none border-none w-full bg-transparent text-gray-500"
              />
            </div>
            {errors?.address && touched?.address ? (
              <p className="text-xs text-red-500">{errors?.address}</p>
            ) : null}
          </div>
          <div className="flex flex-col gap-y-1 my-2 text-sm">
            <label htmlFor="">Phone Number</label>
            <div className=" cursor-not-allowed text-gray-500 flex items-center border gap-2 bg-white rounded-md px-3 py-2">
              <AiOutlinePhone size={20} />
              <input
                type="text"
                name="phone"
                disabled={data?.user?.phone ? true : false}
                value={data?.user?.phone}
                placeholder="Phone"
                className="outline-none border-none w-full  bg-transparent text-gray-500"
              />
            </div>
            {errors.phone && touched.phone ? (
              <p className="text-xs text-red-500">{errors.phone}</p>
            ) : null}
          </div>
          <div className="flex flex-col gap-y-1 my-2 text-sm">
            <label htmlFor="">Occupation</label>
            <div
              className={`${
                data?.user?.occupation && "cursor-not-allowed"
              } text-gray-500 flex items-center border gap-2 bg-white rounded-md px-3 py-2`}
            >
              <BsBriefcase className="text-primaryColor" />
              <input
                type="text"
                name="occupation"
                value={
                  data?.user?.occupation
                    ? data?.user?.occupation
                    : values?.occupation
                }
                onChange={handleChange}
                onBlur={handleBlur}
                readOnly={data?.user?.occupation ? true : false}
                placeholder="Occupation"
                className="outline-none border-none w-full  bg-transparent text-gray-500"
              />
            </div>
            {errors.occupation && touched.occupation ? (
              <p className="text-xs text-red-500">{errors.occupation}</p>
            ) : null}
          </div>
          <div className="flex flex-col gap-y-1 my-2 text-sm">
            <label htmlFor="">Age</label>
            <div
              className={`${
                data?.user?.age && "cursor-not-allowed"
              } text-gray-500 flex items-center border gap-2 bg-white rounded-md px-3 py-2`}
            >
              <TfiReload size={14} className="text-primaryColor" />
              <input
                type="number"
                name="age"
                value={data?.user?.age ? data?.user?.age : values?.age}
                onChange={handleChange}
                onBlur={handleBlur}
                disabled={data?.user?.age ? true : false}
                placeholder="Age"
                min={0}
                className="outline-none border-none w-full  bg-transparent text-gray-500"
              />
            </div>
            {errors.age && touched.age ? (
              <p className="text-xs text-red-500">{errors.age}</p>
            ) : null}
          </div>
          <div className={`flex flex-col gap-y-1 my-2 text-sm`}>
            <label htmlFor="">Payment Method</label>
            <div
              className={`${
                data?.user?.paymentMethod && "cursor-not-allowed"
              } text-gray-500 flex items-center border gap-2 bg-white rounded-md px-3 py-2 ${
                data?.user?.paymentMethod && "cursor-not-allowed"
              }`}
            >
              <select
                type="text"
                name="paymentMethod"
                disabled={data?.user?.paymentMethod ? true : false}
                value={
                  data?.user?.paymentMethod
                    ? data?.user?.paymentMethod
                    : values?.paymentMethod
                }
                onChange={handleChange}
                onBlur={handleBlur}
                className="outline-none border-none w-full  bg-transparent text-gray-500"
              >
                <option value="">Choose Payment Method</option>
                <option value="Bank Account">Bank Account</option>
                <option value="Bkash">Bkash</option>
                <option value="Nagad">Nagad</option>
              </select>
            </div>
            {errors.paymentMethod && touched.paymentMethod ? (
              <p className="text-xs text-red-500">{errors.paymentMethod}</p>
            ) : null}
          </div>

          {(values?.paymentMethod === "Nagad" ||
            data?.user?.paymentMethod === "Nagad") && (
            <div className="flex flex-col gap-y-1 my-2 text-sm">
              <label htmlFor="">Nagad Number</label>
              <div
                className={`${
                  data?.user?.nagadno && "cursor-not-allowed"
                } text-gray-500 flex items-center border gap-2 bg-white rounded-md px-3 py-2`}
              >
                <GiMoneyStack size={20} className="text-primaryColor" />
                <input
                  type="text"
                  name="nagadno"
                  required={values?.paymentMethod === "Nagad" ? true : false}
                  value={
                    data?.user?.nagadno ? data?.user?.nagadno : values?.nagadno
                  }
                  onChange={handleChange}
                  onBlur={handleBlur}
                  disabled={data?.user?.nagadno ? true : false}
                  placeholder="Enter your nagad number"
                  className="outline-none border-none w-full  bg-transparent text-gray-500"
                />
              </div>
              {errors.nagadno && touched.nagadno ? (
                <p className="text-xs text-red-500">{errors.nagadno}</p>
              ) : null}
            </div>
          )}
          {(values?.paymentMethod === "Bkash" ||
            data?.user?.paymentMethod === "Bkash") && (
            <div className="flex flex-col gap-y-1 my-2 text-sm">
              <label htmlFor="">Bkash Number</label>
              <div
                className={`${
                  data?.user?.bkashno && "cursor-not-allowed"
                } text-gray-500 flex items-center border gap-2 bg-white rounded-md px-3 py-2`}
              >
                <GiMoneyStack size={20} className="text-primaryColor" />
                <input
                  type="text"
                  name="bkashno"
                  required={values?.paymentMethod === "Bkash" ? true : false}
                  value={
                    data?.user?.bkashno ? data?.user?.bkashno : values?.bkashno
                  }
                  onChange={handleChange}
                  onBlur={handleBlur}
                  disabled={data?.user?.bkashno ? true : false}
                  placeholder="Enter your bkash number"
                  className="outline-none border-none w-full  bg-transparent text-gray-500"
                />
              </div>
              {errors.bkashno && touched.bkashno ? (
                <p className="text-xs text-red-500">{errors.bkashno}</p>
              ) : null}
            </div>
          )}
        </div>
        {(values?.paymentMethod === "Bank Account" ||
          data?.user?.paymentMethod === "Bank Account") && (
          <div className="grid grid-cols-1 md:grid-cols-2 gap-x-4">
            <div className="flex flex-col gap-y-1 my-2 text-sm">
              <label htmlFor="">Account Number</label>
              <div
                className={`${
                  data?.user?.accountnumber && "cursor-not-allowed"
                } text-gray-500 flex items-center border gap-2 bg-white rounded-md px-3 py-2`}
              >
                <MdOutlineNumbers size={20} className="text-primaryColor" />
                <input
                  type="text"
                  name="accountnumber"
                  required={
                    values?.paymentMethod === "Bank Account" ? true : false
                  }
                  value={
                    data?.user?.accountnumber
                      ? data?.user?.accountnumber
                      : values.accountnumber
                  }
                  onChange={handleChange}
                  onBlur={handleBlur}
                  disabled={data?.user?.accountnumber ? true : false}
                  placeholder="Enter Your Bank Account No."
                  className="outline-none border-none w-full  bg-transparent text-gray-500"
                />
              </div>
              {errors.accountnumber && touched.accountnumber ? (
                <p className="text-xs text-red-500">{errors.accountnumber}</p>
              ) : null}
            </div>
            <div className="flex flex-col gap-y-1 my-2 text-sm">
              <label htmlFor="">Account Name</label>
              <div
                className={`${
                  data?.user?.accountname && "cursor-not-allowed"
                } text-gray-500 flex items-center border gap-2 bg-white rounded-md px-3 py-2`}
              >
                <RiMapPinUserLine size={20} className="text-primaryColor" />
                <input
                  type="text"
                  name="accountname"
                  required={
                    values?.paymentMethod === "Bank Account" ? true : false
                  }
                  value={
                    data?.user?.accountname
                      ? data?.user?.accountname
                      : values?.accountname
                  }
                  onChange={handleChange}
                  onBlur={handleBlur}
                  disabled={data?.user?.accountname ? true : false}
                  placeholder="Enter Your Bank Account Name"
                  className="outline-none border-none w-full  bg-transparent text-gray-500"
                />
              </div>
              {errors.accountname && touched.accountname ? (
                <p className="text-xs text-red-500">{errors.accountname}</p>
              ) : null}
            </div>
            <div className="flex flex-col gap-y-1 my-2 text-sm">
              <label htmlFor="">Bank Name</label>
              <div
                className={`${
                  data?.user?.bankname && "cursor-not-allowed"
                } text-gray-500 flex items-center border gap-2 bg-white rounded-md px-3 py-2`}
              >
                <CiBank size={20} className="text-primaryColor" />
                <input
                  type="text"
                  name="bankname"
                  required={
                    values?.paymentMethod === "Bank Account" ? true : false
                  }
                  value={
                    data?.user?.bankname
                      ? data?.user?.bankname
                      : values?.bankname
                  }
                  onChange={handleChange}
                  onBlur={handleBlur}
                  disabled={data?.user?.bankname ? true : false}
                  placeholder="Enter the Bank Name"
                  className="outline-none border-none w-full  bg-transparent text-gray-500"
                />
              </div>
              {errors.bankname && touched.bankname ? (
                <p className="text-xs text-red-500">{errors.bankname}</p>
              ) : null}
            </div>
            <div className="flex flex-col gap-y-1 my-2 text-sm">
              <label htmlFor="">Branch Name</label>
              <div
                className={`${
                  data?.user?.branchname && "cursor-not-allowed"
                } text-gray-500 flex items-center border gap-2 bg-white rounded-md px-3 py-2`}
              >
                <AiOutlineBranches size={20} className="text-primaryColor" />
                <input
                  type="text"
                  name="branchname"
                  required={
                    values?.paymentMethod === "Bank Account" ? true : false
                  }
                  value={
                    data?.user?.branchname
                      ? data?.user?.branchname
                      : values?.branchname
                  }
                  onChange={handleChange}
                  onBlur={handleBlur}
                  disabled={data?.user?.branchname ? true : false}
                  placeholder="Enter the Branch Name"
                  className="outline-none border-none w-full  bg-transparent text-gray-500"
                />
              </div>
              {errors.branchname && touched.branchname ? (
                <p className="text-xs text-red-500">{errors.branchname}</p>
              ) : null}
            </div>
            <div className="flex flex-col gap-y-1 my-2 text-sm">
              <label htmlFor="">District Name</label>
              <div
                className={`${
                  data?.user?.districtname && "cursor-not-allowed"
                } text-gray-500 flex items-center border gap-2 bg-white rounded-md px-3 py-2`}
              >
                <CiLocationArrow1 size={20} className="text-primaryColor" />
                <input
                  type="text"
                  name="districtname"
                  required={
                    values?.paymentMethod === "Bank Account" ? true : false
                  }
                  value={
                    data?.user?.districtname
                      ? data?.user?.districtname
                      : values?.districtname
                  }
                  onChange={handleChange}
                  onBlur={handleBlur}
                  disabled={data?.user?.districtname ? true : false}
                  placeholder="Enter the District Name"
                  className="outline-none border-none w-full  bg-transparent text-gray-500"
                />
              </div>
              {errors.districtname && touched.districtname ? (
                <p className="text-xs text-red-500">{errors.districtname}</p>
              ) : null}
            </div>
            <div className="flex flex-col gap-y-1 my-2 text-sm">
              <label htmlFor="">Routing Number</label>
              <div
                className={`${
                  data?.user?.routingno && "cursor-not-allowed"
                } text-gray-500 flex items-center border gap-2 bg-white rounded-md px-3 py-2`}
              >
                <MdOutlineNumbers size={20} className="text-primaryColor" />
                <input
                  type="text"
                  name="routingno"
                  required={
                    values?.paymentMethod === "Bank Account" ? true : false
                  }
                  value={
                    data?.user?.routingno
                      ? data?.user?.routingno
                      : values?.routingno
                  }
                  onChange={handleChange}
                  onBlur={handleBlur}
                  disabled={data?.user?.routingno ? true : false}
                  placeholder="Enter the Routing No."
                  className="outline-none border-none w-full  bg-transparent text-gray-500"
                />
              </div>
              {errors.routingno && touched.routingno ? (
                <p className="text-xs text-red-500">{errors.routingno}</p>
              ) : null}
            </div>
          </div>
        )}

        <div
          className={`${
            pathname === "/my-account/update-account" ? "flex" : "hidden"
          } items-center pt-1 justify-between mt-4 text-sm w-full`}
        >
          {isLoadingUser ? (
            <button
              disabled={true}
              className="rounded-md border text-white bg-primaryColor py-1.5 px-7 flex gap-2 items-center mr-2"
            >
              Processing <ButtonLoader />
            </button>
          ) : data?.user?.paymentMethod ? (
            <Tippy
              delay={300}
              placement="right-end"
              className="bg-[#a8a8a8]"
              content={
                <WarningTooltip content="Please contact with customer support to update your profile!" />
              }
            >
              <div>
                <button
                  type="submit"
                  disabled={true}
                  className="rounded-md border group text-white bg-primaryColor py-1.5 px-7 flex gap-2 items-center mr-2"
                >
                  Update{" "}
                  {data?.user?.paymentMethod && (
                    <BsExclamationDiamond className={`text-white`} />
                  )}
                </button>
              </div>
            </Tippy>
          ) : (
            <div>
              <button
                type="submit"
                disabled={data?.user?.paymentMethod ? true : false}
                className="rounded-md border group text-white bg-primaryColor py-1.5 px-7 flex gap-2 items-center mr-2"
              >
                Update{" "}
                {data?.user?.paymentMethod && (
                  <BsExclamationDiamond className={`text-white`} />
                )}
              </button>
            </div>
          )}
        </div>
      </form>
    </div>
  );
};

export default UpdateProfilePage;
