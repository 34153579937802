import React from "react";
import CommonLayout from "../../layout/CommonLayout";

const PrivacyPolicyPage = () => {
  return (
    <CommonLayout>
      {/* {!user ? <HomeHeader></HomeHeader> : <DashHeader></DashHeader>} */}
      <div className="w-full px-3 md:px-[55px] mt-20 md:mt-52 mb-10 ">
        <h1 className="text-center text-2xl font-bold custom-font ">
          Terms & Conditions and Privacy Policy – AyyKori
        </h1>

        <div>
          <h2>&nbsp;</h2>
          <p>
            <strong>1. Introduction:</strong>
          </p>
          <p>
            Welcome to www.AyyKori .com. The Service is operated by Masterkey
            Technologies Limited (“We”, “Us”, “AyyKori” or “Our” where such
            expression shall unless repugnant to the context thereof, be deemed
            to include its respective legal heirs, representatives,
            administrators, permitted successors and assigns). Please read these
            terms and conditions (“Terms and Conditions” or “Agreement”)
            carefully as they contain the legal terms and conditions that you
            agree to when you use the service provided by us through the Website
            and the App (“Platform”, “Website”).
          </p>
          <p>
            <strong>2. Membership:</strong>
          </p>
          <p>&nbsp;</p>
          <p>
            Our membership is available through registration after you submit
            certain requested information to AyyKori. During registration, we
            display our current Terms and Conditions for your perusal.AyyKori
            reserves the right to update and change the Terms and Conditions
            provided hereunder as and when required, without any prior consent
            or permission of the Users. Please be informed that AyyKori
            membersmay run any sponsored advertisements referring to AyyKori on
            Google, Facebook, or any other site. In such cases prior permission
            must be obtained from AyyKori in writing, failing to do so may cause
            the member’s account to be terminated at the discretion of AyyKori.
          </p>
          <p>
            Please be informed that AyyKori members may run any sponsored
            advertisements referring to AyyKori on Google, Facebook, or any
            other site. In such cases prior permission must be obtained from
            AyyKori in writing, failing to do so may cause the member’s account
            to be terminated at the discretion of AyyKori.
          </p>
          <p>
            <strong>3. Our Service:</strong>
          </p>
          <p>&nbsp;</p>
          <p>
            Our Service allows users who register on the Platform as a Promoter
            or Shopper and therefore create an account with us (“Account“) to
            earn a profits or cashback on tracked purchases of products
            belonging to the Brand Partners,through customized links shared by
            Promoters of the AyyKoriPlatform.
          </p>
          <p>
            <span className="underline font-medium">Affiliate Marketing</span>{" "}
            <br />
            To qualify for profit, the Brand Partner must confirm that the
            Promoter’s purchase through the customized link is tracked, genuine
            and successful (constituting a “Qualifying Transaction”). Promoters
            can share these customized AyyKori links with their friends, family
            and so on. When any Qualifying Transactions are generated through
            these links and AyyKori receives a commission fee for these
            Qualifying Transactions from Brand Partners, AyyKori will pay
            Promoters “Profit” for sales generated via their customized AyyKori
            links. Please note that if for any reason whatsoever, AyyKori does
            not get paid commission from Brand Partners for Qualifying
            Transactions, then no Profit is due to the Promoter as well.
          </p>
          <p>&nbsp;</p>
          <p>
            <strong>4. Becoming a Member:</strong>
          </p>
          <p>&nbsp;</p>
          <p>
            If You are a natural person, (i) as a condition to Your use of the
            Platform, You must be 18 (eighteen) years of age or older; and
            <strong> </strong>(ii) by visiting the Platform or accepting these
            Terms and Conditions, You represent and warrant to AyyKori that You
            are 18 (eighteen) years of age or older and that You have the right,
            authority and capacity to use the Platform and agree to and abide by
            these Terms and Conditions; provided however that if You are a
            parent or legal guardian of a person younger than 18 (eighteen)
            years of age, You are permitted to access and use the Platform for
            the purposes of procuring the Services, on behalf of such persons.
          </p>
          <p>
            You must register for the Service using accurate and current
            information about yourself – including your correct name, address
            and any other requested details. If you are asked for and provide,
            details of a bank account or Cheque payment details or other payment
            mode details into which you wish to receive payments (your “Profit
            Receipt Method“), you (a) must ensure that you are, and remain,
            fully entitled to use that profit Receipt Method, and (b) confirm
            that you wish to receive profit through that Profit Receipt Method.
            You should keep this information updated through your Account.
          </p>
          <p>
            Generation, collection and dissemination of ‘Sensitive Personal Data
            or Information’ is in accordance with Digital Security Act, 2018 as
            amended from time to time and allied rules require the User’s
            express consent. By affirming assent to this Policy by clicking on
            the “I agree with the Terms and Policy” button at the time of
            registration, the User provides consent to such information
            generation and the collection of the same as required under
            applicable laws. We may also be required to share this data with our
            Brand Partners for the purpose of their risk analysis and any other
            statutory requirements. By clicking on the “I agree with the Terms
            and Policy” button you allow us to share your data and information
            with the Brand Partners.
          </p>
          <p>
            Note that your Profit Receipt Method (such as BFTN, Cheque, MFS
            transfer, Gift Cards or other payment modes as We may introduce) may
            have rules about the maximum or minimum payment that you can receive
            through that Profit Receipt Method.
          </p>
          <p>
            Important: You must ensure that the email address and mobile number
            we hold for you are kept up-to-date and that you have full access to
            it – we will be sending you important messages there. If you change
            your email address or mobile number, then you must change the
            details we hold for you on your Account.
          </p>
          <p>
            <strong>5. Profit and Referral Fees:</strong>
          </p>
          <p>
            After a Member successfully completes a Qualifying Transaction, and
            once we have received the resulting Profit for that Qualifying
            Transaction, we pass that Profit to the Member through his/her
            Profit Receipt Method. Such payments shall be<strong> </strong>
            applied for and will be disbursed automatically after 45-80 of the
            application, to the member on the 1st day and the 16th day of every
            month, as long as it is a working day. If not, the amount will be
            disbursed on the first next working day. The Published shall have
            the option to withdraw the full or a partial sum of the earned
            Profits by clicking the Pop-Up withdraw button which will be made
            available on their portals on the dates specified[NR1] &nbsp;above.
            The members shall have the right to choose their preferred payment
            mode (i.e Bank/Mobile Wallet) and AyyKori shall not be liable for
            non-transfer or wrongful transfer of payments in cases where the
            Member has failed to provide the correct payment information into
            the AyyKori Portal/Dashboard.
          </p>
          <p>
            Please note that there are various circumstances in which a
            transaction with a Brand Partner may not constitute a Qualifying
            Transaction or Profit may not result from it. The Member understands
            that all transactions made by him/her are not with us,
            <strong> </strong>but with the individual Brand Partner. Our help
            pages provide further information about these circumstances.
            Further, from time-to-time Brand Partners may increase or decrease
            the commission paid – in which case the profit offer illustrated on
            our Platform may be incorrect. By default, your applicable
            transactions will be credited in line with the commission reported
            to us by the Brand Partner, which may be more or less than the
            advertised rate. We shall not be liable for any difference in the
            expected profit by the Member and the actual Profit received by the
            Member.
          </p>
          <p>
            When we have traced a paying, Qualifying Transaction to your
            Account, we will credit your Account with a ‘pending payment’. Once
            the Brand Partner confirms the transaction, the payment will be
            marked as ‘validated’ – this can take 30 to 80 days or more from the
            date of the purchase. If the goods are returned or the sale reversed
            or amended by any means then the Profit payment will not be made.
            Apart from the Brand Partner, it is also mandatory for the members
            to intimate AyyKori about any returns or exchanges for which they
            might have earned Profit unduly.
          </p>
          <p>
            Whether a transaction shall qualify as a Qualifying for-Profit
            Transaction (including a Qualifying Referral Fee) shall be at the
            <strong> </strong>sole discretion of the Brand Partner or us and the
            Member understands and agrees to the same. We shall not be held
            responsible if the Brand Partner fails to report a sale to us or
            does not qualify a transaction as Qualifying Transaction. Further,
            we shall not be responsible in the event due to some technical or
            other error we cannot trace a sale back to the Member and does not
            qualify the transaction as a Qualifying Transaction or a Qualifying
            Referral for any reason whatsoever. Whilst we will endeavour to
            recuperate non-payments from the Brand Partner, the decision of
            ourselves, the Brand Partner or Brand Partner’s tracking agent is
            final. Whilst we will try and recuperate missing commissions, at any
            point we reserve the right not to chase missing commission claims,
            particularly where no purchase has been made.
          </p>
          <p>
            Further, in the event that the Brand Partner feels that the purchase
            is not genuine for any reason whatsoever and we do not receive any
            Profit for the transaction, the Member will not receive any Profit.
          </p>
          <p>
            The AyyKori Service also provides a unique one-time referral fee to
            its Members for new members introduced by them in the manner
            provided on the Platform (“Qualifying Referral“), provided that the
            newly referred member remains a Member themselves for at least two
            successful payment cycles as specified above and additionally they
            will have to earn a qualifying amount of 1000tk via Qualifying
            Transactions. No referral fee shall be disbursed to the referring
            member until and unless the referred member has earned the
            qualifying amount as above. The company reserves the right to revise
            the qualifying amount at its own discretion without any notification
            to its members.
          </p>
          <p>
            We reserve the right to reclaim or make balance adjustments
            accordingly where it has been established that any credit has been
            applied in error. This will include but is not limited to
            transactions where the credit is not genuinely due or payment for
            any credited transaction has not been received from a Brand Partner
            or its agencies and/or misuse or fraud. This can include
            transactions which are already marked as payable or validated in
            your Account, or for transactions which have already been paid over
            to you by BFTN, MFS transfer, Cheque, Gift Cards or any other
            payment modes that we may introduce from time to time. In all such
            incidents, AyyKori has the right to recover all unduly paid profit
            that the member is not entitled to earn, through legal proceedings
            as per the laws of the land.
          </p>
          <p>
            <strong>6. Your Account:</strong>
          </p>
          <p>&nbsp;</p>
          <p>
            You can withdraw your validated profit regardless of the amount
            earned on the above-mentioned payment cycle days. In the event, the
            Member decides to close the Account with us (on purpose/by mistake),
            and if at such time of closure is before a designated payment cycle
            day, the Profits in your account shall be forfeited and the member
            will not be able to get any further payment from AyyKori. Even in
            situations where a member’s previously published links are being
            used to place orders, no payments shall be remitted to the member
            and the member will have to reopen an account on our website to
            avail of our services and benefits<strong>.</strong>
          </p>
          <p>
            <strong>7. Intellectual Property:</strong>
          </p>
          <p>&nbsp;</p>
          <p>
            You acknowledge that all copyright, trademarks, and other
            intellectual property rights in and relating to the Platform
            (including the material which is contributed by Members or Brand
            Partners) are owned by, or licensed to, us. It is easy to copy
            material which appears on web-sites, but this does not mean it is
            legal. Therefore, no-one may copy, distribute, show in public or
            create any derivative work from the Platform, or any of the material
            which is found on the Platform unless properly licensed to do so by
            us.
          </p>
          <p>
            By uploading or including any material on the Platform, a Member
            expressly grants:
          </p>
          <p>
            a)&nbsp; &nbsp; &nbsp; to us a non-exclusive license (including the
            right to grant sub-licenses) to use, reproduce and distribute that
            material through our Service and any other interactive services
            through which we or our sub-licensee make the Service (or a service
            based on our service) available; and
          </p>
          <p>
            b)&nbsp; &nbsp; &nbsp; to other Members (through us, under the
            license referred to herein above), the non-exclusive, personal,
            non-transferable right to view the relevant material.
          </p>
          <p>
            <strong>8. Privacy Policy:</strong>
          </p>
          <p>&nbsp;</p>
          <p>
            Our Privacy Policy forms part of this Agreement, and by entering
            into this Agreement you also give your consent to the way we handle
            your personal data under that policy. Given the global nature of the
            World Wide Web, please note that a posting on the Platform may be
            accessible to internet users around the world.
          </p>
          <p>
            Masterkey Technologies Limited (as the case may be) (We”, “Our”,
            “AyyKori” or “Us”, where such expression shall unless repugnant to
            the context thereof, be deemed to include its respective legal
            heirs, representatives, administrators, permitted successors and
            assigns) own and/ or operate the website and mobile application
            AyyKori.net (“Website”, “Our Website”, “Site”).
          </p>
          <p>
            For the purpose of providing the Services (as defined in clause 1
            below), AyyKori is required to collect and use certain information
            of the users of the Website (“Users”) using the Services and
            involves capturing, storage and transmission of such information.
            This privacy policy (“Privacy Policy“/ “Policy”) explains how We
            collect, use, share and protect personal information of the Users of
            the Services (jointly and severally referred to as “You”, “Your”,
            “Yourself” or “User” or “Users” in this Privacy Policy). We have
            created this Privacy Policy to ensure our steady commitment to the
            privacy of the information of the Users who interact with our
            Services. Your use of and access to the Services is subject to this
            Privacy Policy and our Terms and Conditions. Any capitalized term
            used, but not defined, in this Privacy Policy shall have the meaning
            attributed to it in our Terms and Conditions.
          </p>
          <p>
            The headings used herein are only for the purpose of arranging the
            various provisions of the Privacy Policy. The headings are for the
            purpose of reference only and shall not be interpreted to limit or
            expand the provisions of the clauses contained therein.
          </p>
          <p>
            <strong>8.1. Definitions</strong>
          </p>
          <p>
            In this Privacy Policy, unless the context otherwise requires, the
            terms defined shall bear the meanings assigned to them below, and
            their cognate expressions shall be construed accordingly.
          </p>
          <p>
            a)&nbsp; &nbsp; &nbsp; “Identity Information” shall have the same
            meaning as given in Section 26 of the Digital Security Act 2018 to
            mean any information that relates to a natural person, which, either
            directly or indirectly, in combination with other information
            available or likely to be available to a body corporate, is capable
            of identifying such person.
          </p>
          <p>
            b)&nbsp; &nbsp; &nbsp; The “identity information” means any
            external, biological or physical information or any other
            information which singly<strong> </strong>or jointly can identify a
            person or a system, such as name, photograph, address, date of
            birth, mother’s name, father’s name, signature, national identity
            card, birth and death registration number, finger print, passport
            number, bank account number, driving license, e-TIN number,
            electronic or digital signature, username, credit or debit card
            number, voice print, retina image, iris image, DNA profile, security
            related question or any other identification which are available for
            advance technology.
          </p>
          <p>
            c)&nbsp; &nbsp; &nbsp; “You”, “Your”, “Yourself” and “User” shall
            mean and refer to natural &amp; legal individuals and legal
            entities/companies who visit and/or use the Services and will also
            include the individuals/entities/companies who avail the services by
            submission of details by some other person
          </p>
          <p>
            d)&nbsp; &nbsp; &nbsp; “Third Parties” refer to any
            website/application/web portal, company or individual apart from the
            User and Us.
          </p>
          <p>
            e)&nbsp; &nbsp; &nbsp; “Services'' shall mean the Website
            (https://AyyKori.net/) and Mobile Application (AyyKori) and
            contextual information transmitted to/ received from Users via
            various communication channels including but not limited to e-mail,
            SMS, WhatsApp, phone calls, website chat, IVR. We are primarily
            engaged in the business of allowing Users to share customized links
            for various products in the network of such Users, the customized
            links drive sales to e-commerce websites or the Brand Partners and
            in turn the Users earn profits. We currently operate under the brand
            name AyyKori.
          </p>
          <p>
            f) &nbsp; &nbsp; &nbsp; “User Information” shall mean Personal
            Information and Sensitive Personal Data or Information.
          </p>
          <p>
            g)&nbsp; &nbsp;<strong> &nbsp; </strong>“Website” shall mean and
            refer to https://AyyKori.net/, the “Application” and/ or “App” shall
            refer to the AyyKori mobile application available on Android Play
            Store or iOS App Store. These shall be collectively referred to as
            the “Platform”
          </p>
          <p>
            <strong>8.2. WHY IS THIS PRIVACY POLICY?</strong>
          </p>
          <p>
            This Privacy Policy is published in compliance with, the Digital
            Security Act 2018 of Bangladesh.
          </p>
          <p>This Privacy Policy states, inter alia, the following:</p>
          <p>
            The type of information collected from the Users, including identity
            Information;
          </p>
          <p>The purpose, means and modes of usage of such information; and</p>
          <p>How and to whom we will disclose such information</p>
          <p>
            <strong>8.3. GENERAL</strong>
          </p>
          <p>
            <strong>a)&nbsp; &nbsp; &nbsp; </strong>The User unequivocally
            agrees that this Policy and the aforementioned Terms and Conditions
            constitute a legally binding agreement between the User and AyyKori,
            and that the User shall be subject to the rules, guidelines,
            policies, terms, and conditions applicable to any service that is
            provided by AyyKori including the Services, and that the same shall
            be deemed to be incorporated into the Terms and Conditions, and
            shall be treated as part of the same.
          </p>
          <p>
            <strong>b)&nbsp; &nbsp; &nbsp; </strong>This document is an
            electronic record in terms of the Information &amp; Communication
            Technology Act, 2006 and rules there under as applicable and the
            amended provisions pertaining to electronic records in various
            statutes as amended by the Information &amp; Communication
            Technology Act, 2006. This electronic record is generated by a
            computer system and does not require any physical or digital
            signatures. Further, this document is published in accordance with
            the provisions of the relevant Guidelines.
          </p>
          <p>
            <strong>c)&nbsp; &nbsp; &nbsp; </strong>The terms ‘Party’ and
            ‘Parties’ shall respectively be used to refer to the User and
            AyyKori individually and collectively, as the context so requires.
          </p>
          <p>
            <strong>d)&nbsp; &nbsp; &nbsp; </strong>The headings of each section
            in this Policy are only for the purpose of organizing the various
            provisions under this Policy in an orderly manner and shall not be
            used by either Party to interpret the provisions contained herein in
            any manner. Further, it is specifically agreed to by the Parties
            that the headings shall have no legal or contractual value.
          </p>
          <p>
            <strong>e)&nbsp; &nbsp; &nbsp; </strong>The Parties expressly agree
            that subject to clause 2 of this Document, AyyKori retains the sole
            and exclusive right to amend or modify the Policy and the
            aforementioned Terms and Conditions without any prior permission or
            intimation to the User keeping in mind best practices and laws set
            by the Government of Bangladesh, and the User expressly agrees that
            any such amendments or modifications shall come into effect
            immediately. The User has a duty to periodically check the Policy
            and Terms and Conditions and stay updated on their provisions and
            requirements. If the User continues to use the Services following
            such a change, the User will be deemed to have consented to any and
            all amendments/ modifications made to the Policy and Terms and
            Conditions. In so far as the User complies with the Policy and Terms
            and Conditions, he/she is granted a personal, non-exclusive,
            non-transferable, revocable, limited privilege to enter, access and
            use the Services.
          </p>
          <p>
            <strong>8.4 COLLECTION AND HANDLING OF PERSONAL INFORMATION</strong>
          </p>
          <p>
            Privacy of the Parties is of prime importance to Us and all Services
            are strictly designed within the jurisdiction of laws defined by the
            Government of Bangladesh.
          </p>
          <p>
            Generally, the Services require us to know who you are so that we
            can best meet your needs. When you access the Services, we
            <strong> </strong>may ask you to voluntarily provide us with certain
            information that personally identifies you or could be used to
            personally identify you. Without prejudice to the generality of the
            above, information collected by us from you may include (but is not
            limited to) the following:
          </p>
          <p>Contact data (such as your email address and phone number);</p>
          <p>Username and passwords;</p>
          <p>
            Demographic data (such as your name, gender, age, your date of birth
            and your pin code);
          </p>
          <p>
            Data regarding your usage of the services and other transactions
            made by or with you through the use of Services;
          </p>
          <p>
            Information about your clicks on and from AyyKori mobile device, web
            browser, web and mobile browsing patterns, Brand Partner preferences
          </p>
          <p>
            Your bank account information including the name of the bank
            account, account number, routing no., bank branch or any other
            payment-related information
          </p>
          <p>
            Any other information that you voluntarily choose to provide to us
            (such as information shared by you with us through emails, calls or
            letters, your work details, home / work address, your family
            details, details about transactions done on e-commerce sites,
            screenshots of transactions, order IDs for transactions, alternate
            numbers and emails and various other information provided from time
            to time).
          </p>
          <p>
            The information collected from You by Us shall constitute ‘Personal
            Information’ under the Digital Security Act of Bangladesh.
          </p>
          <p>
            <strong>8.5. PRIVACY STATEMENTS</strong>
          </p>
          <p>The User expressly agrees and acknowledges</p>
          <p>
            <strong>a)&nbsp; &nbsp; </strong>&nbsp; Information that is freely
            available in the public domain or accessible under the Right to
            Information Act, 2009 or any other law will not be considered as
            ‘Personal Information’ for the purposes of this Policy. That AyyKori
            may automatically track information about the User based on the
            User’s IP address and the User’s behaviour on the Platform, and the
            User expressly consents to the same. The User is aware that this
            information may be used to conduct internal research on user
            demographics, interests, and behaviour, to enable AyyKori to better
            understand, and cater to the interests of the Users. Further, the
            User is expressly made aware that such information may include the
            User’s computer &amp; web browser information, the User’s IP
            address, mobile device details etc. The linkage between User’s IP
            address and User’s personally identifiable information may be shared
            with or disclosed to third parties in order to facilitate the
            provisions of the Services to You. The User hereby consents to the
            sharing of such information to such third parties as may be
            determined by AyyKori from time to time. Further, we may also share
            and/or disclose some of the aggregate findings (not the specific
            data) in anonymized form (i.e., non-personally identifiable) with
            third parties for market research and new feature development.
          </p>
          <p>
            <strong>b)&nbsp; &nbsp; &nbsp;</strong> That any and all information
            pertaining to the User collected by AyyKori, whether or not directly
            provided by the User to AyyKori, including but not limited to
            personal correspondence such as emails or letters or SMS or WhatsApp
            or calls, feedback from other users or third parties regarding the
            User’s activities or postings on the Platform, etc., may be
            collected and compiled by AyyKori into a file/folder specifically
            created for/allotted to the User, and the User hereby expressly
            consents to the same. Also, in order to keep You informed of Your
            activities on the Website we occasionally send You emails, SMS, App
            notifications and other marketing communication. These include Your
            transaction messages to show how much You have earned, referral
            messages that show You how much You have earned from referrals,
            payment confirmations for payments to You and, important
            administrative messages and messages to confirm Your activities on
            the Website. These emails are not shared with anyone else apart from
            You. We also send newsletters, SMSs, App notifications, browser
            notifications and other marketing that features some of our best
            ideas to help You save more. You may choose not to receive this
            marketing communication from AyyKori by informing Us at any time.
          </p>
          <p>
            We do not support spamming by our members and we explicitly prohibit
            it in our Terms and Conditions. If You would like to report an
            incident of spamming, please contact us so we can investigate and
            take suitable action.
          </p>
          <p>
            <strong>c)&nbsp; &nbsp; &nbsp; </strong>That the contact information
            provided to AyyKori may be used to send the User offers and
            promotions, whether or not based on the User’s previous interests,
            and the User hereby expressly consents to receive the same. The User
            may choose to unsubscribe from promotional communications by
            clicking on the ‘unsubscribe’ link provided at the end of such
            promotional communication or by emailing us on
            <strong> support@ayykori.net</strong>
          </p>
          <p>
            <strong>d)&nbsp; &nbsp; &nbsp; </strong>AyyKori may occasionally
            request the User to complete optional online surveys. These surveys
            may require the User to provide contact information and demographic
            information (like zip code, age, income bracket, sex, etc.). The
            User is aware that this information is used to improve/customise the
            Services for the benefit of the User and providing all users of the
            Platform with services that AyyKori believes they might be
            interested in availing of.
          </p>
          <p>
            <strong>e)&nbsp; &nbsp; &nbsp;</strong> That AyyKori may keep
            records of electronic communications and telephone calls received
            and made for support or other purposes for the purpose of
            administration of Services, customer support, research and
            development and for better assistance to Users. That AyyKori may
            occasionally request the User to write reviews for services availed
            of by the User from the Platform. The User is aware that such
            reviews will help potential users of the Platform in availing the
            Services, and the User hereby expressly authorizes AyyKori to
            publish any and all reviews written by the User on the Platform,
            along with the User’s name and certain contact details, for the
            benefit and use of other users.
          </p>
          <p>
            <strong>f) &nbsp; &nbsp; &nbsp; </strong>Nothing contained herein
            shall be deemed to compel AyyKori to store, upload, publish, or
            display in any manner content/reviews/surveys/feedback submitted by
            the User, and the User hereby expressly authorizes AyyKori to remove
            from the Platform any such content, review, survey, or feedback
            submitted by the User, without cause or being required to notify the
            User of the same.
          </p>
          <p>
            <strong>g)&nbsp; &nbsp; &nbsp;</strong> Generation and collection of
            ‘Personal Information’ in accordance with the Digital Security Act,
            2018 as amended from time to time and allied rules require the
            User’s express consent. By affirming assent to this Policy as well
            as clicking on the “I agree with Terms and Policy” button at the
            time of registration, the User provides consent to such generation
            and collection as required under applicable laws.
          </p>
          <p>
            <strong>h)&nbsp; &nbsp; &nbsp; </strong>The User is responsible for
            ensuring the accuracy of the information submitted to AyyKori. The
            User may correct, delete inaccuracies, or amend information by
            contacting AyyKori through email on support@ayykori.net. AyyKori
            will make good<strong> </strong>faith efforts to make requested
            changes in the databases as soon as reasonably practicable. If the
            User provides any information that is untrue, inaccurate, out of
            date or incomplete (or becomes untrue, inaccurate, out of date or
            incomplete), or AyyKori has reasonable grounds to suspect that the
            information provided by the User is untrue, inaccurate, out of date
            or incomplete, AyyKori may, at its sole discretion, discontinue the
            provision of the Services to you as per the provisions laid down in
            the Terms and Conditions. There may be circumstances where AyyKori
            will not correct, delete or update your Personal Data, including (a)
            where the Personal Data is opinion data that is kept solely for an
            evaluative purpose; and (b) the Personal Data is in documents
            related to a prosecution if all proceedings relating to the
            prosecution have not been completed.
          </p>
          <p>
            <strong>i) &nbsp; &nbsp; &nbsp;</strong> All the information
            provided to Us by a User, including Sensitive Personal Data or
            Information, is voluntary. User has the right to withdraw his/ her/
            its consent at any time, in accordance with the terms of this
            Privacy Policy, and the Terms and Conditions applicable to such
            User, it being however clarified that withdrawal of consent will not
            be retroactive. If the User wishes to delete his/her account or
            request that AyyKori no longer uses the User’s information to
            provide Services, the User may contact AyyKori on{" "}
            <strong>support@ayykori.net</strong>. We shall not retain such
            information for longer than is required for the purposes for which
            the information may lawfully be used or is otherwise required under
            any other law for the time being in force. After a period of time,
            your data may be anonymized and aggregated, and then may be held by
            us as long as necessary for us to provide our Services effectively,
            but our use of the anonymized data will be solely for analytic
            purposes. Please note that your withdrawal of consent or
            cancellation of account may result in AyyKori being unable to
            provide you with its Services or to terminate any existing
            relationship that AyyKori may have with you.
          </p>
          <p>
            j) &nbsp; &nbsp; &nbsp; If you wish to opt out of receiving
            non-essential communications such as promotional and
            marketing-related information regarding the Services, please send us
            an email at<strong> support@ayykori.net.</strong>
          </p>
          <p>
            <strong>8.6. OUR USE OF YOUR INFORMATION</strong>
          </p>
          <p>
            All the information provided to AyyKori by a User, including
            Personal Information or any Sensitive Personal Data or Information,
            is voluntary. Such information in its original form may be shared
            with any Third Parties in furtherance of the consent from the User
            as provided hereunder. You understand that AyyKori may use certain
            information of yours, which has been designated as Personal
            Information for the following purposes:
          </p>
          <p>providing you the Services;</p>
          <p>taking product &amp; Services feedback;</p>
          <p>
            for offering new products or services and marketing of the Services;
          </p>
          <p>
            for analysing software usage patterns for improving product design
            and utility;
          </p>
          <p>
            for providing the services of generating alerts/reminders/SMS for
            offers and also for internal record.
          </p>
          <p>
            for commercial purposes and in an aggregated or non-personally
            identifiable form for research, statistical analysis and business
            intelligence purposes,
          </p>
          <p>
            for sale or transfer of such research, statistical or intelligence
            data in a non-personally identifiable form to third parties and
            affiliates;
          </p>
          <p>debugging customer support-related issues; and</p>
          <p>
            We may use your tracking information such as IP addresses, and or
            Device ID to help identify You and to gather broad demographic
            information.
          </p>
          <p>
            In case we are acquired by or merged with another company, We shall
            transfer information disclosed by You and information about You to
            the company we are acquired by or merged with, and such company will
            have the right to continue to use the User’s Personal Information
            and/ or other information that a User provides to Us.
          </p>
          <p>
            The Users expressly agree and acknowledge that AyyKori collects and
            stores the User’s Personal Information and/or Sensitive Personal
            Information in a secure cloud-based platform which is provided by
            the User from time to time on the Platform or while using other
            Services.
          </p>
          <p>
            The User is aware that this information will be used by AyyKori to
            deliver its services and help customize/improve the Platform
            experience safer and easier but no personally identifiable
            information will be shared with any Third Party under any
            circumstances without the User’s explicit consent unless directed by
            the law.
          </p>
          <p>
            AyyKori may need to disclose/ transfer User’s Personal Information
            to the following third parties for the purposes mentioned in this
            Privacy Policy, and the Terms and Conditions as applicable to such
            User:
          </p>
          <p>To government institutions/ authorities to the extent required:</p>
          <p>
            under the laws, rules, and regulations and/ or under orders of any
            relevant judicial or quasi-judicial authority;
          </p>
          <p>to protect and defend the rights or property of AyyKori;</p>
          <p>to fight fraud and credit risk;</p>
          <p>
            to enforce AyyKori’s Terms and Conditions applicable to the Users;
            or
          </p>
          <p>
            when AyyKori, in its sole discretion, deems it necessary in order to
            protect its rights or the rights of others.
          </p>
          <p>
            If otherwise required by an order under any law for the time being
            in force including in response to enquiries by government agencies
            for the purpose of verification of identity, or for prevention,
            detection, investigation including cyber incidents, prosecution, and
            punishment of offences.
          </p>
          <p>
            However, We contract with third parties to serve ads on our behalf
            across the Internet and sometimes on this site. They may collect
            information about Your visits to our website, and Your interaction
            with our products and services. They may also use information about
            Your visits to this and other websites to target advertisements for
            goods and services. This information is collected through the use of
            a pixel tag, which is industry standard technology used by most
            major websites. Such third parties are not permitted to sell or
            share Your personally identifiable information as part of this
            process.
          </p>
          <p>
            The following third-party vendors, including Google, Facebook,
            advertising platforms, remarketing platforms like CleverTap, and
            customer query management platforms like Freshworks &amp; Exotel use
            cookies to serve ads based on a user’s prior visits to a website.
          </p>
          <p>
            Google’s use of the DoubleClick cookie enables it and its partners
            to serve ads to users based on their visits to this sites and/or
            other sites on the Internet.
          </p>
          <p>
            Users may opt-out of the use of the DoubleClick cookie for
            interest-based advertising by visiting Ads Settings.
          </p>
          <p>
            <strong>8.7. CONFIDENTIALITY AND SECURITY</strong>
          </p>
          <p>
            8.7.1. Your information is regarded as confidential and therefore
            shall not be divulged to any Third Parties, unless as provided
            hereunder and unless legally required to do so to the appropriate
            authorities, or if necessary, for providing the Services through the
            Platform.
          </p>
          <p>
            8.7.2. Your Personal Information/Sensitive Personal Data is
            maintained by Us in an electronic form on our equipment, and on the
            equipment of our employees. Such information may also be converted
            to physical form from time to time.
          </p>
          <p>
            8.7.3. People who can access your Personal Information: User
            Information will be processed by our employees, authorised staff,
            marketing agencies or agents, on a need-to-know basis, depending on
            the specific purposes for which the User Information has been
            collected by Us. AyyKori may, therefore, retain and submit all such
            records to the relevant stakeholders.
          </p>
          <p>
            8.7.4. Security Practices. We treat data as an asset that must be
            protected against loss and unauthorised access. We employ many
            different security techniques to protect such data from unauthorized
            access by members inside and outside AyyKori. We follow generally
            accepted industry standards to protect the User Information
            submitted to Us and information that We have accessed, including
            managerial, technical, operational and physical security control
            measures. However, for any data loss or theft due to unauthorized
            access to the User’s electronic devices through which the User
            avails the Services, We shall not be held liable for any loss
            whatsoever incurred by the User.
          </p>
          <p>
            8.7.5. Measures We expect you to take: It is important that you also
            play a role in keeping your User Information safe and secure. When
            signing up for an online account, please be sure to choose an
            account password that would be difficult for others to guess and
            never reveal your password to anyone else. You are responsible for
            keeping this password confidential and for any use of your account.
            If you use a shared or public computer, never choose to have your
            login ID/email address or password remembered and make sure to log
            out of your account every time you leave the computer. You should
            also make use of any privacy settings or controls We provide you on
            Our Platform.
          </p>
          <p>
            8.7.6. Unauthorised use of User’s account. We do not undertake any
            liability for any unauthorized use of your account and password. If
            you suspect any unauthorized use of your account, you must
            immediately notify Us by sending an email to support@ayykori.net.
          </p>
          <p>
            8.7.7. Notwithstanding the above, AyyKori is not responsible for the
            confidentiality, security or distribution of your Personal
            Information by third parties outside the scope of our agreement with
            such third parties. Further, AyyKori shall not be responsible for
            any breach of security or for any actions of any third parties or
            events that are beyond the reasonable control of AyyKori including
            but not limited to the acts of government, computer hacking,
            unauthorised access to computer data and storage device, computer
            crashes, breach of security and encryption.
          </p>
          <p>
            <strong>8.8. RETENTION OF YOUR PERSONAL DATA</strong>
          </p>
          <p>
            In accordance with applicable laws, We will use the User Information
            for as long as necessary to satisfy the purposes for which such User
            Information was collected (as described in Section 8.6 above) or to
            comply with applicable legal requirements.
          </p>
          <p>
            <strong>8.9. YOUR RIGHTS</strong>
          </p>
          <p>
            8.9.1. Access to Personal Data. You have the right to access, review
            and request a physical or electronic copy of the information held
            about you. You also have the right to request information on the
            source of your Personal Information.
          </p>
          <p>
            8.9.2. Additional rights (e.g. modification, deletion of Personal
            Data). Where provided by law, you can (i) request deletion, the
            portability, correction or revision of your User Information; (ii)
            limit the use and disclosure of your Personal Data; and (iii) revoke
            consent to any of our data processing activities. Provided that, we
            may be required to retain some of your User Information after you
            have requested deletion, to satisfy our legal or contractual
            obligations. We may also be permitted by applicable laws to retain
            some of your User Information to satisfy our business needs.
          </p>
          <p>
            <strong>8.10. CHILDREN’S AND MINOR’S PRIVACY</strong>
          </p>
          <p>
            We strongly encourage parents and guardians to supervise the online
            activities of their minor children and consider using parental
            control tools available from online services and software
            manufacturers to help provide a child-friendly online environment.
            These tools can also prevent minors from disclosing their name,
            address, and other personally identifiable information online
            without parental permission. Although the Services are not intended
            for use by minors, We respect the privacy of minors who may
            inadvertently use the internet or the mobile application.
          </p>
          <p>
            <strong>8.11. CONSENT TO THIS POLICY</strong>
          </p>
          <p>
            You acknowledge that this Privacy Policy is a part of the Terms and
            Conditions of the Website and the other Services, and you
            acknowledge that you have unconditionally agreed as User of the
            Platform and the Services signifies your assent to this Privacy
            Policy. Your visit to the Website, use of the App and use of the
            Services is subject to this Privacy Policy and the Terms and
            Conditions.
          </p>
          <p>
            <strong>8.12. COOKIES</strong>
          </p>
          <p>
            When You avail of our Services on the Platform, a persistent cookie
            is placed on Your computer.
          </p>
          <p>
            This enables us to track any purchases You make with our
            participating Brand Partners and award cashback / rewards / points
            to You. If You do not have such persistent cookies enabled on Your
            computer You will not be able to earn profits / points on Your
            online shopping via Our Platform.
          </p>
          <p>
            Disabling/enabling cookies: You have the ability to accept or
            decline cookies by modifying the settings in Your browser. However,
            You may not be able to use all the interactive features of Our
            Platform if cookies are disabled.
          </p>
          <p>
            Please note: if You disable the cookies in Your browser which are
            used to track Your purchases via Our Platform, You will not be able
            to earn Profits&nbsp; / point when You shop from our website.
          </p>
          <p>
            There are a number of ways to manage cookies. If You use different
            computers in different locations You will need to ensure that each
            browser is adjusted to suit Your cookie preferences.
          </p>
          <p>
            You can easily delete any cookies that have been installed in the
            cookie folder of your browser as and when required.
          </p>
          <p>
            <strong>8.13. AFFILIATE MARKETING COMMISSION</strong>
          </p>
          <p>
            AyyKori, an affiliate marketing platform, is essentially a service
            provider that connects Brand Partners and publishers. AyyKori is not
            liable to pay for any commission which is cancelled by the partner
            Brand Partner due to any reason whatsoever and not limited to bulk
            buying, self-consumption, unattributed sale, return or cancellation
            of product and/or violating any affiliate policy of our partner
            Brand Partner. Users are required to constantly keep a check on
            profit rates and terms of campaigns as these are subject to change
            real-time without prior notice. AyyKori reserves the right to cancel
            the profit as directed by the partner Brand Partner. By signing up
            on AyyKori, the user agrees to abide by this clause.
          </p>
          <p>
            <strong>8.14. AMENDMENTS OR CHANGES TO PRIVACY POLICY</strong>
          </p>
          <p>
            AyyKori may update this Privacy Policy at any time, with or without
            advance notice. In the event, there are significant changes in the
            way AyyKori treats User Information, or in the Privacy Policy
            itself, AyyKori will display a notice on the Website or send Users
            an email, as provided above, so that the User may review the changed
            terms prior to continuing to use the Services. As always, if the
            User objects to any of the changes to our terms, and the User no
            longer wishes to use the Services, the User may communicate the same
            to <strong>support@ayykori.net</strong> to deactivate Your account.
            Unless stated otherwise, the current Privacy Policy applies to all
            information that AyyKori has about You and Your account.
          </p>
          <p>
            If a User uses the Services after a notice of changes has been sent
            to such User or published on the Platform, such User hereby provides
            his/her/its consent to the changed terms.
          </p>
          <p>
            <strong>8.15. ADDRESS FOR PRIVACY QUESTIONS</strong>
          </p>
          <p>
            Should You have any questions about this Privacy Policy or AyyKori’s
            information collection, use and disclosure practices, You may
            contact the Data Protection Officer appointed by AyyKori. We will
            use reasonable efforts to respond promptly to any requests,
            questions or concerns, You may have regarding our use of Your
            Personal Information. If You have any grievance with respect to Our
            use of Your information, You may communicate such grievance to the
            Data Protection Officer by emailing us at the following email
            address Email: <strong>support@ayykori.net</strong> or via the
            supports page available on our website
          </p>
          <p>
            <strong>9. Our Role:</strong>
          </p>
          <p>
            We are not a party to any transactions with Brand Partners and are
            not the seller or supplier of any of the goods or services that they
            make available. Thus, we do not have any of the legal obligations
            that apply to the sellers of those goods or services.
          </p>
          <p>Accordingly, we have no control over or responsibility for:</p>
          <p>
            the quality, safety, or legality of those goods or services
            available from Brand Partners; or
          </p>
          <p>
            whether the Brand Partner can or will supply and pass good titles to
            any goods or services.
          </p>
          <p>
            Members should exercise no lesser degree of caution in entering into
            transactions with Brand Partners than they would when entering into
            a similar transaction offline. To the extent that the Applicable Law
            permits, you release us, our agents and employees from all liability
            arising out of or in connection with any transactions with Brand
            Partners, including (without limitation) all claims and demands
            relating to uncompleted or completed transactions with Brand
            Partners, or goods or services offered for sale or supply, or
            actually sold or supplied, through or in connection with any
            transactions with Brand Partners.
          </p>
          <p>
            <strong>10. Misuse:</strong>
          </p>
          <p>&nbsp;</p>
          <p>
            We reserve the right to suspend or terminate any Members access to
            our service, or parts of it, if in our reasonable view the relevant
            Member or Account appears to be in breach of any provision of this
            Agreement.
          </p>
          <p>
            Members must not enter into, or attempt to enter into, any
            transaction with a Brand Partner or to gain Profit&nbsp;
          </p>
          <p>
            (a) by providing personal information of someone else, or a payment
            method which they are not entitled to use,&nbsp;
          </p>
          <p>
            (b) by deceptively or unfairly exploiting a Brand Partners’ offering
            including but not limited to creating fake or unauthorized referral
            links, or&nbsp;
          </p>
          <p>
            (c) in breach of any terms and conditions applied by us or the Brand
            Partner to that transaction. We reserve the right to forfeit any
            pending payments or validated payments in the Member’s Account in
            case of such misuse of our service by the Member.
          </p>
          <p>
            It is each Member’s obligation to ensure that any material posted by
            him/her or associated with his/her Account:
          </p>
          <p>
            is not defamatory, offensive, or abusive or of an obscene, indecent
            or menacing nature;
          </p>
          <p>
            is not intended or likely to cause needless annoyance, inconvenience
            or distress to any person;
          </p>
          <p>
            does not contain any computer virus, macro virus, Trojan horse,
            worm, or anything else designed to interfere with, interrupt, or
            disrupt the normal operating procedures of a computer or to
            surreptitiously intercept, access without authority, or expropriate
            any system, data or personal information;
          </p>
          <p>
            does not contravene the Applicable Law or regulation (including, but
            not limited to, laws governing consumer protection, distance
            selling, unfair competition, anti-discrimination, false advertising,
            copyright, trademark and privacy);
          </p>
          <p>
            does not breach the rights of any person or entity (including any
            rights or expectations of privacy);
          </p>
          <p>
            where it constitutes feedback on a Brand Partner, is accurate and
            fair; and
          </p>
          <p>does not advertise any goods or services.</p>
          <p>
            Notwithstanding anything contained elsewhere in this Agreement and
            the Privacy Policy, we reserve the right to investigate complaints
            or reported violations of this Agreement and to take any action we
            deem appropriate, including but not limited to<strong> </strong>
            reporting any suspected unlawful activity to law enforcement
            officials, regulators, or other third parties and disclosing any
            information necessary or appropriate to such persons or entities
            relating to your Account, email addresses, usage history, posted
            materials, IP addresses and traffic information. Any Member, who in
            the sole discretion of the Brand Partner or us, has committed fraud
            in the use of the Platform or our Service or misused the Platform or
            the Service, their Account will be closed, email and IP address will
            be blacklisted so they cannot use it again and cannot register from
            the same PC/Laptop/Smart device.
          </p>
          <p>
            If you see or experience anything on our Platform that appears to
            infringe any of the above requirements, we would like you to inform
            us by using our contact form.
          </p>
          <p>
            Each Member acknowledges that we are entitled, but not obliged, to
            withdraw any material, which appears – based on information received
            from third parties or other Members – to be in breach of this
            Agreement.
          </p>
          <p>
            <strong>11. Contact from third parties:</strong>
          </p>
          <p>&nbsp;</p>
          <p>
            If anyone contacts us in relation to material or transactions
            associated with you or your Account, then you agree:
          </p>
          <p>
            to provide all reasonable information and assistance we may require
            in connection with responding to that contact; and
          </p>
          <p>
            to respond promptly and accurately to it, should we pass the message
            to you for a response.
          </p>
          <p>
            <strong>12. Additional services:</strong>
          </p>
          <p>
            We or our partners may offer new or additional services through the
            Platform from time to time. Your use of those services may be
            subject to additional terms and conditions, which you must comply
            with. Provided that those terms are notified to you on the Platform
            in an appropriate manner (as determined by us in our reasonable
            discretion) when you agree to take those services, any failure by
            you to comply with a material provision of the terms governing those
            services will amount to a breach of this Agreement.
          </p>
          <p>
            <strong>13. Operation of our Service:</strong>
          </p>
          <p>
            We reserve the right to withdraw, modify or suspend aspects of the
            Service, or the entirety of it, where we have legal, security,
            technical or commercial reasons to do so. We will endeavour to give
            you 30 days’ advance notice before taking such action, except where
            it is necessary to take earlier action for security reasons or
            because of technical difficulties which would otherwise adversely
            affect our service. There may also be times when the Service becomes
            inaccessible as a result of technical difficulties experienced by us
            or on the Internet; we will, however, use reasonable skill and care
            to overcome these difficulties where they are within our control.
            Please note, however, that we cannot guarantee continuous access to
            the Service or any of the content that appears on it.
          </p>
          <p>
            Nevertheless, we will strive to ensure that any periods of planned
            unavailability, which you will be informed of when you access the
            Service at the relevant time, are kept to a minimum.
          </p>
          <p>
            For security or other reasons, we may require you to change your
            password or other information which facilitates access to the
            Service; however, we will never ask you for your password via email,
            telephone, or any other means other than through the Platform. You
            are solely responsible for maintaining the confidentiality of your
            password and any additional identifying information.
          </p>
          <p>
            <strong>14. Disclaimer and Limitation of Liability:</strong>
          </p>
          <p>&nbsp;</p>
          <p>
            <strong>Disclaimer:</strong>
          </p>
          <p>
            The content and material from or through the Platform are provided
            “as-is,” “as available,” with “all faults”, and all warranties,
            express or implied, are disclaimed (including but not limited to the
            disclaimer of any implied warranties of merchantability,
            non-infringement, freedom from error, and fitness for a particular
            purpose). The information and services may contain bugs, errors,
            problems or other limitations. We and our affiliated parties have no
            liability whatsoever for your use of any information or service. In
            particular, but not as a limitation thereof, we and our affiliated
            parties are not liable for any indirect, special, incidental or
            consequential damages (including damages for loss of business, loss
            of profits, savings, litigation, or the like), whether based on
            breach of contract, breach of warranty, tort (including negligence),
            product liability or otherwise, even if advised of the possibility
            of such damages. The negation and limitation of damages set forth
            above are fundamental elements of the basis of the agreement between
            us and you. This Platform and the products, services, documents,
            content and materials and information presented would not be
            provided without such limitations. No advice or information, whether
            oral or written, obtained by you from us through the Platform or
            otherwise shall create any warranty, representation or guarantee not
            expressly stated in this agreement. All responsibility or liability
            for any damages caused by viruses contained within the electronic
            file containing a form or document is disclaimed.
          </p>
          <p>
            <strong>Liability:</strong>
          </p>
          <p>
            a)&nbsp; &nbsp; &nbsp; We warrant that the Service will be provided
            with reasonable care and skill with the intention of meeting our
            specifications for the Service, but we cannot and do not guarantee
            that the Service will meet your requirements.
          </p>
          <p>
            b)&nbsp; &nbsp; &nbsp; We shall be liable as expressly provided in
            this Agreement, but shall have no other obligation, duty or
            liability whatsoever in contract, tort (including negligence, breach
            of statutory duty and any other tort) or otherwise.
          </p>
          <p>
            c)&nbsp; &nbsp; &nbsp; Limitation of Liability: Subject always to
            sub-Clause d. below, we shall be liable for direct loss or damage
            only, whether in contract, tort (including negligence, breach of
            statutory duty or other tort) or otherwise, and whether caused by
            its act or omission or that of its employees, agents or
            subcontractors. The aggregate liability of AyyKori and the
            affiliated parties in connection with any claim arising out of or
            relating to the Platform and/or the products, information, documents
            and services provided herein or hereby shall not exceed BDT 20,000
            and that amount shall be in lieu of all other remedies which you may
            have against us and any affiliated party to us.
          </p>
          <p>
            d)&nbsp; &nbsp; &nbsp; We will not be liable to you or anyone else,
            whether in contract, tort (including negligence, breach of statutory
            duty or other tort) or otherwise
          </p>
          <p>
            (A) for any loss of revenue, business, anticipated savings or
            profits;&nbsp;
          </p>
          <p>
            (B)any errors in or omissions from the Platform or any services or
            products obtainable therefrom;&nbsp;
          </p>
          <p>
            (C) the unavailability or interruption of the Platform or any
            features thereof;
          </p>
          <p>(D) your use of the Platform;&nbsp;</p>
          <p>(E) the content and materials contained on the Platform;</p>
          <p>
            (F) or any delay or failure in performance beyond our control or any
            of our affiliated parties.
          </p>
          <p>
            for any indirect, special or consequential loss damage, costs or
            other claims, however caused or arising, whether through non-supply
            or late supply of the Service or other non-performance of this
            Agreement or otherwise.
          </p>
          <p>
            Except as expressly stated elsewhere in this Agreement, all
            representations, warranties, conditions and other terms, whether
            express or implied (by common law, statute, collateral or otherwise)
            are hereby excluded, except in the case of fraud, or where such
            exclusion is not permitted by Applicable Law.
          </p>
          <p>
            For the avoidance of doubt, we will not have liability to you or any
            other person in respect of material contributed by Members,
            transactions (or non-transactions) with Brand Partners, or any
            activity or communication relating to such material or transactions.
          </p>
          <p>
            The provisions of this Clause 14 shall survive the termination or
            expiry of this Agreement.
          </p>
          <p>
            <strong>15. Third Party Content:</strong>
          </p>
          <p>&nbsp;</p>
          <p>
            Third party content and materials may appear on the Platform or may
            be accessible via hyperlinks from the Platform. We are not
            responsible for and assume no liability whatsoever for any mistakes,
            misstatements of law, defamation, omissions, falsehood, obscenity,
            pornography or profanity in the statements, opinions,
            representations or any other form of content and materials appearing
            on the Platform or accessible via hyperlinks from the Platform.
          </p>
          <p>
            <strong>16. Communications:</strong>
          </p>
          <p>&nbsp;</p>
          <p>
            You hereby expressly agree to receive communications by way of SMS,
            e-mails, mobile app and browser notifications, and any other
            messages from time to time from AyyKori relating to Services
            provided through the Website.
          </p>
          <p>
            <strong>17. Indemnity:</strong>
          </p>
          <p>&nbsp;</p>
          <p>
            You agree to indemnify us against all liabilities, claims and
            expenses that may arise out of or in connection with&nbsp;
          </p>
          <p>
            (a) any breach of this Agreement by you or through your Account,
            or&nbsp;
          </p>
          <p>(b) any transaction with a Brand Partner.</p>
          <p>
            <strong>18. Assignment:</strong>
          </p>
          <p>&nbsp;</p>
          <p>
            We reserve the right to assign this Agreement, and to assign or
            subcontract any or all of our rights and obligations under this
            Agreement, but will not do so in such a way as to reduce any
            guarantees you are given under this Agreement. You may not without
            our written consent assign or dispose of this Agreement, nor
            subcontract any of your rights and obligations under it.
          </p>
          <p>
            <strong>19. Entire Agreement:</strong>
          </p>
          <p>&nbsp;</p>
          <p>
            This Agreement is intended to contain your entire agreement with us
            relating to the Service; we believe it to be fair and reasonable. It
            replaces all earlier agreements and understandings with you relating
            to the Service, except for any fraud or fraudulent representation by
            either of us.
          </p>
          <p>20. Changes to this Agreement:</p>
          <p>&nbsp;</p>
          <p>
            We reserve the right to change this Agreement from time to time, and
            post the new version on the Service. When we do so, we will post the
            new version of the Agreement on the Service, and the new version of
            these terms and conditions will take effect, and will govern the
            Service and your relationship with us:
          </p>
          <p>
            commencing no less than three days after the date of posting (or
            such later date as we indicate in the relevant posting), if any of
            the changes is to an operative provision of this Agreement which is
            capable of adversely affecting you; if you do not wish to be
            governed by the new version of the Agreement, you may notify us on
            or before the date when the new version of the Agreement is to take
            effect, and from that date you must cease to use our service or
          </p>
          <p>
            immediately upon the date of posting (or such later date as we
            indicate in the relevant posting), if the changes are not to
            operative provisions, or not capable of adversely affecting you –
            examples of which would include, without limitation, changes to
            contact details referred to, or the refinement of provisions that
            are already included, in this Agreement.
          </p>
          <p>
            <strong>21. General:</strong>
          </p>
          <p>&nbsp;</p>
          <p>
            In the event that any term of this Agreement is held to be invalid
            or unenforceable, the remainder of this Agreement shall remain valid
            and enforceable. You and us are independent contractors, and no
            agency, partnership, joint venture or employee-employer relationship
            is intended or created by this Agreement. Our failure to act with
            respect to a breach by you or others does not waive our right to act
            with respect to subsequent or similar breaches.
          </p>
          <p>
            <strong>22. Governing Law:</strong>
          </p>
          <p>&nbsp;</p>
          <p>
            This Agreement, and our relationship with you and each Member, is
            governed as per the Bangladeshi Laws. You and we each submit to the
            non-exclusive jurisdiction of the Bangladesh courts in relation to
            disputes arising in connection with this Agreement.
          </p>
          <p>
            <strong>23. Keeping this Agreement:</strong>
          </p>
          <p>&nbsp;</p>
          <p>
            We don’t separately file the individual Agreements entered into by
            Members when they register for the Service. You can access it at
            www.AyyKori.net. Please make a durable copy of this Agreement by
            printing and/or saving a downloaded copy on your own computer. It is
            offered in English only.
          </p>
          <p>
            <strong>24. Contact:</strong>
          </p>
          <p>&nbsp;</p>
          <p>
            You can reach us on ‘<strong>support@ayykori.net</strong>’.
          </p>
          <p>&nbsp;</p>
          <p>&nbsp;</p>
          <p>&nbsp;</p>
          <p>
            &nbsp;[NR1]The Pop-Up withdraw button will be available on 1st-5th
            and 16th-20th of the month.
          </p>
        </div>
      </div>
    </CommonLayout>
  );
};

export default PrivacyPolicyPage;
