import React from "react";
import { useSelector } from "react-redux";
import { errorIconData } from "../../Data/errorIconData";
import BackButton from "./BackButton";
const OTPNotMatch = ({ setSteps }) => {
  const { errorMsg, isError } =
    useSelector((state) => state?.errorHelperSlice) || {};

  let Icon = isError
    ? errorIconData?.BiErrorCircle
    : errorIconData?.BsCheckCircleFill;
  return (
    <>
      <div
        style={{ zIndex: 999 }}
        className="relative bg-white border lg:p-10 md:p-8 p-5 min-h-[400px] rounded-lg flex flex-col justify-center"
      >
        <BackButton setSteps={setSteps} />
        <div className=" h-[200px] w-full -mt-12 flex flex-col items-center justify-center gap-2">
          {<Icon className="text-6xl text-primaryColor rounded-full" />}
          <h2 className="w-full text-xl font-bold text-gray-600 text-left">
            {errorMsg ? errorMsg : "Please Try Again Later"}
          </h2>
        </div>
      </div>
    </>
  );
};

export default OTPNotMatch;
