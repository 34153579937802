import Cookies from "js-cookie";
import React from "react";
import { Link } from "react-router-dom";
import star from "../../Assets/images/icon/stars.png";
import allBrandsImg from "../../Assets/images/share-earn/allBrands.gif";
import bestDealsImg from "../../Assets/images/share-earn/bestDeals.gif";
import makeLinkImg from "../../Assets/images/share-earn/makelink.gif";
import workshop from "../../Assets/images/share-earn/workshop.gif";
import { useGetSingleUserQuery } from "../../features/user/userApiSlice";
import useAuth from "../../hooks/useAuth";

const ShareAndEarnBanner = () => {
  const isAuth = useAuth();
  const access_token = Cookies.get("ayykori");
  const { isSuccess, isError, data } =
    useGetSingleUserQuery(access_token, { refetchOnReconnect: true }) || {};
  return (
    <div className="w-full px-3 md:px-[55px] bg-gradient-to-r from-[#67B26F]  to-[#4CA2CD] flex items-center justify-center py-4 ">
      <div className="  py-2 md:container  grid grid-cols-1 md:grid-cols-4 gap-2 items-center   ">
        <div className="col-span-3 w-full md:px-[55px] md:w-[80%]">
          <div className="flex gap-2 md:mb-2">
            <h1 className="text-xl md:text-4xl text-white font-bold">
              Hey {isSuccess && !isError && data?.user?.fullname}
            </h1>
            <img className="w-8" src={star} alt="" />
          </div>
          <h5 className="mb-2 md:text-base text-xs text-gray-100">
            Glad you’re open to earning some extra cash!
          </h5>
          <h5 className="text-gray-100 md:text-base text-xs">
            Create your own affiliate link and earn money with every purchase.
          </h5>
          <h5 className="text-gray-100 md:text-base text-xs mt-2">
            Want to boost your earning? Share your link far and wide with loved
            ones and on social media.
          </h5>
        </div>
        <div className="col-span-1 h-full md:container bg-white rounded-md px-4 md:px-7 py-3 md:py-5 flex flex-col gap-4 mt-4 md:mt-0 text-xs md:text-base">
          <Link
            to={"/affiliate/affiliate-marketing-masterClass-program"}
            className="flex items-center gap-x-2"
          >
            <img className="w-4 md:w-5" src={workshop} alt="" />

            <h1>Affiliate Masterclass</h1>
          </Link>
          <Link
            to={
              isAuth || Cookies.get("ayykori")
                ? "/my-account/make-affiliate-link"
                : "/login"
            }
            className="flex items-center gap-x-2"
          >
            <img
              className="w-[18px] md:w-[23px] -ml-0.5"
              src={makeLinkImg}
              alt=""
            />
            <h1>Make Affiliate Link</h1>
          </Link>

          <Link
            to={"/affiliate/best-deals-to-share"}
            className="flex items-center gap-x-2"
          >
            <img className="w-5 md:w-6 -ml-0.5" src={bestDealsImg} alt="" />
            <h1>Best Deals To Share</h1>
          </Link>

          <Link
            to={
              "/brands/brands-subbrands/all_brands?share_and_earn_brands=affiliate"
            }
            className="flex items-center gap-x-2"
          >
            <img className="w-4 md:w-5" src={allBrandsImg} alt="" />
            <h1>All Brands</h1>
          </Link>
          <Link
            to={
              isAuth || Cookies.get("ayykori")
                ? "/my-account/online-workshop"
                : "/login"
            }
            className="flex items-center gap-x-2"
          >
            <img className="w-4 md:w-5" src={workshop} alt="" />
            <h1>Online Workshop</h1>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default ShareAndEarnBanner;
