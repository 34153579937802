import Cookies from "js-cookie";
import React, { useEffect } from "react";
import { AiOutlineBranches, AiOutlinePhone } from "react-icons/ai";
import { BsBriefcase, BsEnvelope } from "react-icons/bs";
import { CiBank, CiLocationArrow1, CiLocationOn } from "react-icons/ci";
import { FaRegUser } from "react-icons/fa";
import { GiFamilyHouse, GiMoneyStack } from "react-icons/gi";
import { HiOutlineMail } from "react-icons/hi";
import { ImMobile2 } from "react-icons/im";
import { MdOutlineNumbers } from "react-icons/md";
import { RiMapPinUserLine, RiSecurePaymentLine } from "react-icons/ri";
import { SlUser, SlUserFemale } from "react-icons/sl";
import { TbGenderBigender } from "react-icons/tb";
import { TfiReload } from "react-icons/tfi";
import { useNavigate } from "react-router-dom";
import userinfo from "../../Assets/images/gif/userinfo.gif";
import { useGetSingleUserQuery } from "../../features/user/userApiSlice";

const PersonalInfoPage = () => {
  const navigate = useNavigate();
  const access_token = Cookies?.get("ayykori");
  const { data } =
    useGetSingleUserQuery(access_token, { refetchOnReconnect: true }) || {};

  useEffect(() => {
    if (!data?.user?.address) {
      navigate("/my-account/personal-info");
    }
  }, [data?.user, navigate]);

  return (
    <div className="w-full py-2 md:p-8 flex flex-col gap-y-4">
      <div>
        <h1 className="text-base md:text-lg text-gray-500 font-outfit font-medium">
          Personl Information
        </h1>
        <p className="text-xs md:text-base text-gray-400 font-outfit font-light">
          Manage your Ayykori account with easy access to personal info, account
          balance & more.
        </p>
      </div>
      <div className="flex flex-col-reverse md:grid  md:grid-cols-2 gap-4">
        <div className="flex flex-col gap-y-2">
          <div className="hidden md:block">
            <h1 className="text-gray-500 text-sm md:text-base border-b w-full">
              {" "}
              Contact Info
            </h1>
            <div className="flex items-center gap-4">
              <div className="flex items-center gap-x-2 text-gray-400 my-2">
                <BsEnvelope className="text-primaryColor text-sm md:text-base" />
                <h1 className="text-xs md:text-sm font-medium ">
                  {data?.user?.email}
                </h1>
              </div>
              <span className="text-gray-300">|</span>
              <div className="flex items-center gap-x-2 text-gray-400 my-2">
                <AiOutlinePhone className="text-primaryColor text-sm md:text-base" />
                <h1 className="text-xs md:text-sm font-medium ">
                  {data?.user?.phone}
                </h1>
              </div>
            </div>
          </div>
          <div className="md:hidden">
            <h1 className="text-gray-500 text-sm md:text-base border-b w-full">
              Email
            </h1>
            <div className="flex items-center gap-x-2 text-gray-400 my-2">
              <HiOutlineMail className="text-primaryColor text-sm  md:text-base" />
              <h1 className="text-xs md:text-sm font-medium ">
                {data?.user?.email}
              </h1>
            </div>
          </div>
          <div className="md:hidden">
            <h1 className="text-gray-500 text-sm md:text-base border-b w-full">
              Phone
            </h1>
            <div className="flex items-center gap-x-2 text-gray-400 my-2">
              <AiOutlinePhone className="text-primaryColor text-sm  md:text-base" />
              <h1 className="text-xs md:text-sm font-medium ">
                {data?.user?.phone}
              </h1>
            </div>
          </div>
          <div className="">
            <h1 className="text-gray-500 text-sm md:text-base border-b w-full">
              {" "}
              Address
            </h1>
            <div className="flex items-center gap-x-2 text-gray-400 my-2">
              <CiLocationOn className="text-primaryColor text-sm  md:text-base" />
              <h1 className="text-xs md:text-sm font-medium ">
                {data?.user?.address}
              </h1>
            </div>
          </div>
          <div className="">
            <h1 className="text-gray-500 text-sm md:text-base border-b w-full">
              {" "}
              Age
            </h1>
            <div className="flex items-center gap-x-2 text-gray-400 my-2">
              <TfiReload className="text-primaryColor text-sm md:text-base" />
              <h1 className="text-xs md:text-sm font-medium ">
                {data?.user?.age}
              </h1>
            </div>
          </div>
          <div className="">
            <h1 className="text-gray-500 text-sm md:text-base border-b w-full">
              {" "}
              Gender
            </h1>
            <div className="flex text-sm md:text-base items-center gap-x-2 text-gray-400 my-2">
              {data?.user?.gender === "Female" && (
                <SlUserFemale className="text-primaryColor text-sm md:text-base" />
              )}
              {data?.user?.gender === "Male" && (
                <SlUser className="text-primaryColor text-sm md:text-base" />
              )}
              {data?.user?.gender === "Other" && (
                <FaRegUser className="text-primaryColor text-sm md:text-base" />
              )}
              {data?.user?.gender !== "Female" &&
                data?.user?.gender !== "Male" &&
                data?.user?.gender !== "Other" && (
                  <TbGenderBigender className="text-primaryColor text-sm md:text-lg" />
                )}
              <h1 className="text-xs md:text-sm font-medium ">
                {data?.user?.gender}
              </h1>
            </div>
          </div>
          <div className="">
            <h1 className="text-gray-500 text-sm md:text-base border-b w-full">
              {" "}
              Occcupation
            </h1>
            <div className="flex items-center gap-x-2 text-gray-400 my-2">
              <BsBriefcase className="text-primaryColor text-sm md:text-base" />
              <h1 className="text-xs md:text-sm font-medium ">
                {data?.user?.occupation}
              </h1>
            </div>
          </div>
        </div>
        <div className="w-full h-full">
          <img
            src={userinfo}
            alt=""
            className="w-[80%] md:w-full md:h-[80%] object-cover mx-auto"
          />
        </div>
      </div>

      {/* payment info */}
      <div className="mt-2">
        <h1 className="text-base md:text-2xl text-center font-normal hover:text-gray-400 text-primaryColor transition-all duration-500 cursor-pointer capitalize flex justify-center items-center gap-x-2 rounded-sm">
          Payment Method <RiSecurePaymentLine />
        </h1>
        <p className="text-center text-xs md:text-sm font-light text-gray-400">
          Effortlessly review & access your secure payment method & details
          here.
        </p>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mt-4 md:mt-10">
          {data?.user?.paymentMethod === "Bank Account" && (
            <div className="md:col-span-2 border p-4 rounded-md">
              <h1 className="text-sm md:text-base bg-gradient-to-l from-gray-100 to-transparent rounded-md w-full flex items-center gap-x-2 text-primaryColor">
                Bank Account{" "}
                <GiFamilyHouse className="text-primaryColor text-sm md:text-base" />
              </h1>
              <div className="w-full flex flex-col gap-y-4 mt-4">
                <div className="w-full flex items-center gap-4 justify-between">
                  <div className="w-full">
                    <h1 className="text-gray-500 text-sm md:text-base border-b w-full">
                      Account Number
                    </h1>
                    <div className="flex items-center gap-x-2 text-gray-400 my-2">
                      <MdOutlineNumbers className="text-primaryColor text-sm md:text-base" />
                      <h1 className="text-xs md:text-sm font-medium ">
                        {data?.user?.accountnumber}
                      </h1>
                    </div>
                  </div>
                  <div className="w-full">
                    <h1 className="text-gray-500 text-sm md:text-base border-b w-full">
                      Account Name
                    </h1>
                    <div className="flex items-center gap-x-2 text-gray-400 my-2">
                      <RiMapPinUserLine className="text-primaryColor text-sm md:text-base" />
                      <h1 className="text-xs md:text-sm font-medium ">
                        {data?.user?.accountname}
                      </h1>
                    </div>
                  </div>
                </div>
                <div className="w-full flex items-center gap-4 justify-between">
                  <div className="w-full">
                    <h1 className="text-gray-500 text-sm md:text-base border-b w-full">
                      {" "}
                      Bank Name
                    </h1>
                    <div className="flex items-center gap-x-2 text-gray-400 my-2">
                      <CiBank className="text-primaryColor text-sm md:text-base" />
                      <h1 className="text-xs md:text-sm font-medium ">
                        {data?.user?.bankname}
                      </h1>
                    </div>
                  </div>
                  <div className="w-full">
                    <h1 className="text-gray-500 text-sm md:text-base border-b w-full">
                      {" "}
                      Branch Name
                    </h1>
                    <div className="flex items-center gap-x-2 text-gray-400 my-2">
                      <AiOutlineBranches className="text-primaryColor text-sm md:text-base" />
                      <h1 className="text-xs md:text-sm font-medium ">
                        {data?.user?.branchname}
                      </h1>
                    </div>
                  </div>
                </div>
                <div className="w-full flex items-center gap-4 justify-between">
                  <div className="w-full">
                    <h1 className="text-gray-500 text-sm md:text-base border-b w-full">
                      District Name
                    </h1>
                    <div className="flex items-center gap-x-2 text-gray-400 my-2">
                      <CiLocationArrow1 className="text-primaryColor text-sm md:text-base" />
                      <h1 className="text-xs md:text-sm font-medium ">
                        {data?.user?.districtname}
                      </h1>
                    </div>
                  </div>
                  <div className="w-full">
                    <h1 className="text-gray-500 text-sm md:text-base border-b w-full">
                      Routing Number
                    </h1>
                    <div className="flex items-center gap-x-2 text-gray-400 my-2">
                      <MdOutlineNumbers className="text-primaryColor text-sm md:text-base" />
                      <h1 className="text-xs md:text-sm font-medium ">
                        {data?.user?.routingno}
                      </h1>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          {(data?.user?.paymentMethod === "Bkash" ||
            data?.user?.paymentMethod === "Nagad") && (
            <div className="md:col-span-1 border p-4 rounded-md">
              <h1 className="text-sm md:text-base bg-gradient-to-l from-gray-100 to-transparent rounded-md w-full flex items-center gap-x-2 text-primaryColor">
                Mobile Banking{" "}
                <ImMobile2 className="text-primaryColor text-sm md:text-base" />
              </h1>

              <div className="flex flex-col gap-y-6 mt-4">
                {data?.user?.paymentMethod === "Bkash" && (
                  <div className="w-full">
                    <h1 className="text-gray-500 text-sm md:text-base border-b w-full">
                      Bkash
                    </h1>
                    <div className="flex items-center gap-x-2 text-gray-400 mt-2">
                      <GiMoneyStack className="text-primaryColor text-sm md:text-base" />
                      <h1 className="text-xs md:text-sm font-medium ">
                        {data?.user?.bkashno}
                      </h1>
                    </div>
                  </div>
                )}
                {data?.user?.paymentMethod === "Nagad" && (
                  <div className="w-full">
                    <h1 className="text-gray-500 text-sm md:text-base border-b w-full">
                      Nagad
                    </h1>
                    <div className="flex items-center gap-x-2 text-gray-400 mt-2">
                      <GiMoneyStack className="text-primaryColor text-sm md:text-base" />
                      <h1 className="text-xs md:text-sm font-medium ">
                        {data?.user?.nagadno}
                      </h1>
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default PersonalInfoPage;
