import moment from "moment";
import React from "react";
import { AiOutlineCalendar } from "react-icons/ai";
import { MdOutlineWatchLater } from "react-icons/md";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import { useUpdateBlogViewCountMutation } from "../../features/blog/blogApiSlice";
const BlogCard = ({ data }) => {
  const [updateBlogViewCount] = useUpdateBlogViewCountMutation() || {};
  return (
    <a
      onClick={() => {
        updateBlogViewCount({ id: data?._id });
      }}
      href={data?.blogLink}
      target="_blank"
      rel="noopener noreferrer"
    >
      <div className="w-full md:h-72 flex flex-col cursor-pointer rounded-md  bg-white shadow-md shadow-gray-100">
        <div className=" w-full">
          <LazyLoadImage
            className="w-full  object-cover  rounded-t-md"
            src={data?.blogImg}
            alt=""
          />
        </div>
        <div className="w-full h-[30%] md:h-[40%] p-2 flex flex-col justify-between gap-3 md:gap-1 ">
          <h1 className="text-sm md:text-base font-medium font-outfit text-gray-700  hover:text-primaryColor">
            {data?.blogTitle}
          </h1>

          <div className="flex items-center gap-x-3 text-gray-400">
            <p className=" flex text-xs items-center gap-1 ">
              <span className=" text-sm md:text-xl">
                <AiOutlineCalendar />
              </span>
              {moment(data?.createdAt).format("ll")}
            </p>
            <p className=" flex text-xs items-center gap-1 ">
              <span className="text-sm md:text-xl">
                <MdOutlineWatchLater />
              </span>
              {moment(data?.createdAt).format("LT")}
            </p>
          </div>
        </div>
      </div>{" "}
    </a>
  );
};

export default BlogCard;
