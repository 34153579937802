import Cookies from "js-cookie";
import { useDispatch } from "react-redux";
import { Navigate } from "react-router-dom";
import { showModal } from "../features/loginModalShowSlice.js/loginModalShowSlice";
import useAuth from "../hooks/useAuth";

const PrivateRoute = ({ children, allowedRoles, path }) => {
  const dispatch = useDispatch();
  // const authUser = useAuthUser();
  const isAuth = useAuth();
  if (!isAuth || !Cookies.get("ayykori")) {
    dispatch(showModal());
  }
  // const location = useLocation();
  return isAuth || Cookies.get("ayykori") ? (
    // <Outlet />
    children
  ) : (
    // <Navigate to={path} replace={true} />
    <Navigate to={"/login"} replace />
  );
};

export default PrivateRoute;
