import Cookies from "js-cookie";
import React, { useEffect, useMemo, useState } from "react";
import CountUp from "react-countup";
import { toast } from "react-hot-toast";
import { BsArrowCounterclockwise } from "react-icons/bs";
import { CiLock } from "react-icons/ci";
import ReactPaginate from "react-paginate";
import CalculationHistoryTable from "../../../components/Table/CalculationHistoryTable";
import {
  useCalculateProfitMutation,
  useCommissionCategoryMutation,
  useGetAllCalculationListQuery,
} from "../../../features/profitCalculation/profitCalculationApiSlice";

const CashbackCalculationPage = () => {
  const urlRegex =
    /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/;

  const access_token = Cookies.get("ayykori");
  const [page, setPage] = useState(1);
  const [limit] = useState(10);
  const [allCategories, setAllCategories] = useState({});
  const [category, setCategory] = useState("");
  const [calculateValue, setCalculateValue] = useState(0);
  const [productLink, setProductLink] = useState("");
  const [price, setPrice] = useState();

  const response =
    useGetAllCalculationListQuery(
      { access_token, source: "cashback", page, limit },
      { refetchOnReconnect: true }
    ) || {};
  const { data } = response;
  // const {
  //   data: dataCategory,
  //   isSuccess: isSuccessCategory,
  //   isError: isErrorCategory,
  // } = useGetCommissionCategoryQuery(
  //   { access_token, fullLink: productLink },
  //   {
  //     skip: productLink ? false : true,
  //     refetchOnReconnect: true,
  //   }
  // ) || {};
  const [
    commissionCategory,
    {
      data: dataCategory,
      isSuccess: isSuccessCategory,
      isError: isErrorCategory,
    },
  ] =
    useCommissionCategoryMutation({ access_token, fullLink: productLink }) ||
    {};
  const [
    calculateProfit,
    {
      data: dataCalculate,
      isLoading: isLoadingCalculate,
      isError: isErrorCalculate,
      isSuccess: isSuccessCalculate,
      error: errorCalculate,
    },
  ] = useCalculateProfitMutation() || {};

  const handleCalculateCashbackamount = (e) => {
    e.preventDefault();
    if (!productLink || !price) {
      toast.error("Please fill the form");
    } else {
      setCalculateValue(0);
      calculateProfit({
        access_token,
        bodyData: {
          fullLink: productLink,
          source: "cashback",
          category,
          price,
        },
      });
    }
  };

  // get commission category new code
  // useEffect(() => {
  //   const fetchData = async () => {
  //     if (urlRegex.test(productLink)) {
  //       const res = await commissionCategory({
  //         access_token,
  //         fullLink: productLink,
  //       });
  //       console.log(res);
  //     }
  //   };

  //   fetchData();
  // }, [productLink, urlRegex, commissionCategory, access_token]);

  // setting the all category value
  // useEffect(() => {
  //   if (isSuccessCategory && !isErrorCategory) {
  //     console.log(dataCategory);
  //     setAllCategories({ ...dataCategory?.category });
  //   }
  // }, [isSuccessCategory, isErrorCategory, dataCategory]);

  // matching the product link and call the category api after the categoryCall value changed to true
  // useEffect(() => {
  //   if (productLink && ) {
  //     setCallCategory(true);
  //   } else {
  //     setCallCategory(false);
  //   }
  // }, [productLink]);

  // setting category empty
  // useEffect(() => {
  //   if (productLink.length === 0) {
  //     console.log("OK");
  //     setAllCategories({});
  //     setCategory("");
  //   }
  // }, [productLink]);

  // calculation success message
  useEffect(() => {
    if (isSuccessCalculate && !isErrorCalculate) {
      setCalculateValue(dataCalculate?.TotalCommission?.toFixed(2));
      toast.success("Calculated Successfully!");
    }
  }, [isSuccessCalculate, isErrorCalculate]);

  // calculation error message
  useEffect(() => {
    if (!isSuccessCalculate && isErrorCalculate) {
      toast.error(errorCalculate?.data?.message || "Calculated Failed!");
    }
  }, [isSuccessCalculate, isErrorCalculate]);

  const handleChangeProductLink = useMemo(
    () => async (e) => {
      const updatedProductLink = e.target.value;
      setProductLink(updatedProductLink);
      // console.log(updatedProductLink);
      // console.log(urlRegex.test(updatedProductLink));
      const urlStartsWithProtocol = /^(https?:\/\/)/i.test(updatedProductLink);
      // console.log(urlStartsWithProtocol);
      if (urlRegex.test(updatedProductLink) || urlStartsWithProtocol) {
        // console.log(updatedProductLink);
        const res = await commissionCategory({
          access_token,
          fullLink: updatedProductLink,
        });
        const { category } = res?.data || {};
        console.log(category);
        if (category) {
          setAllCategories(category);
        }
        // console.log(res);
      }
    },
    [urlRegex, access_token, commissionCategory]
  );

  return (
    <div className="py-2 md:p-8">
      <div className="w-full flex flex-col">
        <h1 className="text-base md:text-lg text-gray-500 font-outfit font-medium">
          Cashback Calculator
        </h1>
        <p className="text-xs md:text-base text-gray-400 font-outfit font-light">
          Calculate your cashback amount here and find out how much you can
          save!
        </p>
      </div>
      <div className="flex flex-col justify-center items-center gap-y-4 mt-4 mb-8">
        {/* <CircularProgress progressData={calculateValue} /> */}
        <div className="w-32 h-32 md:w-36 md:h-36 flex justify-center items-center border-2 rounded-full border-dotted border-primaryColor">
          <span className="whitespace-pre  text-xl md:text-2xl font-medium text-primaryColor">
            <CountUp start={0} end={Math.floor(calculateValue)} duration={6} />{" "}
            &#2547;
          </span>
        </div>
        <div className="flex flex-col justify-center items-center gap-y-2">
          <h1 className="text-sm md:text-lg text-gray-500 font-outfit font-medium">
            Calculate Your Cashback Amount Here
          </h1>
          <p className="text-xs md:text-base text-center text-gray-400 font-outfit font-light -mt-2 mb-1">
            Copy the product link, paste it, and calculate the cashback.
          </p>
          <form className="w-[90%] md:w-[420px] space-y-4">
            <input
              className="w-full text-xs md:text-base border rounded-md px-4 py-2  outline-none font-light mr-1 text-gray-500"
              placeholder="Paste the product link here"
              type="text"
              value={productLink}
              onChange={handleChangeProductLink}
            />

            <div className="w-full grid grid-cols-1 md:grid-cols-2 gap-4 relative">
              {Object.keys(allCategories)?.length !== 0 &&
              !allCategories?.hasOwnProperty("Flat") ? (
                <div className={`border rounded-md px-2`}>
                  <select
                    value={category}
                    onChange={(e) => setCategory(e.target.value)}
                    className={`w-full text-xs md:text-base   outline-none font-light text-gray-500 px-4 py-2 
                  }`}
                  >
                    <option value="">Select Category</option>
                    {allCategories &&
                      Object.keys(allCategories)?.length !== 0 &&
                      Object.keys(allCategories)?.map((item, index) => (
                        <option key={index} value={item}>
                          {item}
                        </option>
                      ))}
                  </select>
                </div>
              ) : (
                <div
                  className={`border rounded-md px-2 relative cursor-not-allowed`}
                >
                  <CiLock className="absolute top-[50%] translate-y-[-50%] right-4 text-lg" />
                  <select
                    disabled
                    className={`w-full text-xs md:text-base outline-none font-light text-gray-500 px-4 py-2 appearance-none  
                  }`}
                  >
                    <option value="">Select Category</option>
                  </select>
                </div>
              )}
              <input
                className="w-full text-xs md:text-base border rounded-md px-4 py-2  outline-none font-light text-gray-500"
                placeholder="Enter Price"
                type="number"
                value={price}
                min={0}
                onChange={(e) => setPrice(e.target.value)}
              />
              {productLink && (
                <span className="absolute -bottom-5 left-0 text-xs text-gray-400">
                  {allCategories?.hasOwnProperty("Flat")
                    ? `Category not required`
                    : `Select a category`}
                </span>
              )}
            </div>
            <div className="flex items-center gap-2">
              <button
                type="button"
                onClick={handleCalculateCashbackamount}
                disabled={isLoadingCalculate}
                className="w-full flex justify-center items-center gap-x-1 text-xs md:text-base bg-primaryColor hover:bg-[#13b4a7] transition-all duration-300 text-white rounded-md px-4 py-2 mt-2"
              >
                {isLoadingCalculate ? "Processing.." : "Calculate Cashback"}
              </button>
              <button
                title="Reset Form"
                type="button"
                onClick={() => {
                  setProductLink("");
                  setCategory("");
                  setPrice("");
                }}
                className="w-10 flex justify-center items-center gap-x-1 text-xs md:text-base text-primaryColor border-2 bg-transparent hover:bg-primaryColor transition-all duration-300 hover:text-white rounded-md px-1 py-1.5 mt-2"
              >
                <BsArrowCounterclockwise className="text-[17px] md:text-[25px]" />
              </button>
            </div>
          </form>
        </div>
      </div>
      <div>
        <h1 className="text-base md:text-lg text-gray-500 font-outfit font-medium mt-3 mb-2 md:my-4">
          History
        </h1>
        <CalculationHistoryTable source="cashback" response={response} />
        {data?.total[0]?.count > limit && (
          <div className="w-full px-3 md:px-[55px] flex justify-center md:justify-end items-center my-8">
            <ReactPaginate
              breakLabel="..."
              previousLabel="Prev"
              className="text-primaryColor flex items-center gap-2 text-sm"
              previousClassName="text-primaryColor text-sm"
              nextClassName="text-primaryColor text-sm"
              activeClassName="bg-primaryColor text-sm"
              activeLinkClassName="text-white px-2"
              nextLabel="Next"
              onPageChange={(e) => setPage(e.selected + 1)}
              pageRangeDisplayed={2}
              marginPagesDisplayed={2}
              pageCount={Math.ceil(data?.total[0]?.count / limit)}
              renderOnZeroPageCount={null}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default CashbackCalculationPage;
