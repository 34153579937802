import React from "react";

const CategoriesHero = () => {
  return (
    <>
      <div className="flex flex-col justify-center items-center px-4 md:px-40 md:py-10 py-4">
        <h1 className="font-outfit text-xl md:text-3xl text-gray-700 font-semibold text-center">
          Do you love getting Great Deals?
        </h1>
        <h4 className="font-outfit text-xs md:text-2xl text-gray-600 font-normal text-center">
          Shop & Save with Cashbacks! Find your desired products like groceries,
          fashion products, electronics accessories, home appliances and what
          not!
        </h4>
      </div>
    </>
  );
};

export default CategoriesHero;
