import React from "react";
import { BeatLoader } from "react-spinners";

const ButtonLoader = () => {
  return (
    <div>
      <BeatLoader size={5} color="#ffffff" />
    </div>
  );
};

export default ButtonLoader;
