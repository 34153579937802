import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  prevComponent: "",
  currentComponent: "",
};

export const goPrevNextSlice = createSlice({
  name: "goPrevNextSlice",
  initialState,
  reducers: {
    goback: (state, action) => {
      state.prevComponent = action.payload.prevComponent;
    },
    goCurrent: (state, action) => {
      state.currentComponent = action.payload.currentComponent;
    },
  },
});

// Action creators are generated for each case reducer function
export const { goCurrent, goback } = goPrevNextSlice.actions;

export default goPrevNextSlice.reducer;
