import Cookies from "js-cookie";
import React, { useState } from "react";
import { MdArrowForwardIos } from "react-icons/md";
import { Autoplay } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import { Swiper, SwiperSlide } from "swiper/react";
import { useGetCampaignTrendingDataQuery } from "../../features/Trending/TrendingApiSlice";
import TrendingCard from "../Card/TrendingCard";
import CommonComponent from "../CommonComponent/CommonComponent";
import Error from "../Error/Error";
import NoDataFound from "../NodataFound/NoDataFound";
import TrendingCardSkeleton from "../Skeleton/TrendingCardSkeleton";

const Trending = ({ newData }) => {
    const { title, dataLimit, source } = newData || {};
    const [swiperRef, setSwiperRef] = useState(null);

    const prevHandler = () => {
        swiperRef.slidePrev();
    };

    const nextHandler = () => {
        swiperRef.slideNext();
    };
    const access_token = Cookies.get("ayykori");

    const { data, isLoading, isSuccess, isError } =
        useGetCampaignTrendingDataQuery(
            {
                access_token,
                page: 1,
                limit: dataLimit ? dataLimit : 20,
                // tag: title.toLowerCase(),
                tag: source
                    ? `${title.toLowerCase()}-${source}`
                    : title.toLowerCase(),
            },
            { refetchOnReconnect: true }
        ) || {};

    let content = null;

    //   handle loading case
    if (isLoading && !isSuccess) {
        content = (
            <>
                <div className="w-full px-3 md:px-[55px] grid grid-cols-1 sm:grid-cols-3 md:grid-cols-5 gap-4 mx-auto relative group transition-all duration-1000">
                    {Array(
                        window.innerWidth <= 576
                            ? 1
                            : window.innerWidth <= 764
                            ? 3
                            : 5
                    )
                        ?.fill()
                        ?.map((item, i) => (
                            <SwiperSlide key={i}>
                                <TrendingCardSkeleton />
                            </SwiperSlide>
                        ))}
                </div>
            </>
        );
    }

    // handle success case
    if (!isLoading && isSuccess && !isError) {
        console.log(data);
        content = (
            <>
                <div className="  w-full px-3 md:px-[55px] mx-auto relative group transition-all duration-1000">
                    <div
                        onClick={prevHandler}
                        className="hidden  cursor-pointer group-hover:block  absolute  left-[-100%] group-hover:left-[2.5%] top-[50%] translate-y-[-50%] z-[100] rounded-full p-3 bg-gray-50 shadow-lg shadow-gray-400"
                    >
                        <MdArrowForwardIos
                            size={20}
                            className="rotate-180 cursor-pointer text-primaryColor"
                        />
                    </div>

                    {/* slider */}
                    {/* <Swiper
                        slidesPerView={1.2}
                        spaceBetween={8}
                        loop={true}
                        autoplay={{ delay: 5000 }}
                        modules={[Autoplay]}
                        onSwiper={(swiper) => setSwiperRef(swiper)}
                        className="mySwiper p-2"
                        breakpoints={{
                            // when window width is >= 320px
                            320: {
                                slidesPerView: 1.2,
                                spaceBetween: 8,
                            },
                            // when window width is >= 480px
                            480: {
                                slidesPerView: 1.2,
                                spaceBetween: 8,
                            },
                            // when window width is >= 640px
                            640: {
                                slidesPerView: 3,
                                spaceBetween: 8,
                            },
                            // when window width is >= 640px
                            920: {
                                slidesPerView: 4,
                                spaceBetween: 10,
                            },
                            // when window width is >= 920
                            1200: {
                                slidesPerView: 4,
                                spaceBetween: 10,
                            },
                        }}
                    >
                        {data?.data &&
                            data?.data?.map((item, i) => (
                                <SwiperSlide key={i}>
                                    <TrendingCard data={item} />
                                </SwiperSlide>
                            ))}
                    </Swiper> */}

                    {/* show all */}
                    <div className=" w-full grid grid-cols-2 sm:grid-cols-2  md:grid-cols-3 lg:grid-cols-5 gap-y-3 md:gap-y-6 gap-x-3 md:gap-x-8">
                        {data?.data &&
                            data?.data?.map((item, i) => (
                                <TrendingCard key={i} data={item} />
                            ))}
                    </div>

                    <div
                        onClick={nextHandler}
                        className="hidden cursor-pointer  group-hover:block absolute right-[-100%] group-hover:right-[2.5%] top-[50%] translate-y-[-50%] z-[100] rounded-full p-3 bg-gray-50 shadow-lg shadow-gray-400"
                    >
                        <MdArrowForwardIos
                            size={20}
                            className=" text-primaryColor"
                        />
                    </div>
                </div>
            </>
        );
    }

    // handle error case
    if (!isLoading && isError) {
        content = (
            <>
                <Error />
            </>
        );
    }
    // handle empty case
    if (!isLoading && isSuccess && data?.resData?.length === 0) {
        content = (
            <>
                <NoDataFound />
            </>
        );
    }
    return (
        <div className="my-10 md:my-16">
            <CommonComponent
                data={{
                    title: title,
                    // link: "/brands/trending",
                    // linktitle: "See All",
                    // show: true,
                }}
            />

            {content}
        </div>
    );
};

export default Trending;
