import Cookies from "js-cookie";
import React, { useState } from "react";
import cashback from "../../Assets/images/banner/knowinsideout/cashback/cashback.jpg";
import cashbackMobile from "../../Assets/images/banner/knowinsideout/cashback/cashback_mobile.jpg";

import { Helmet } from "react-helmet";
import ReactPaginate from "react-paginate";
import { useParams } from "react-router-dom";
import KnowInsideOutBanner from "../../components/Banner/KnowInsideOutBanner";
import BlogCard from "../../components/Card/BlogCard";
import BlogVideoCard from "../../components/Card/BlogVideoCard";
import BlogSortComponent from "../../components/CommonComponent/BlogSortComponent";
import Error from "../../components/Error/Error";
import NoDataFound from "../../components/NodataFound/NoDataFound";
import BlogCardSkeleton from "../../components/Skeleton/BlogCardSkeleton";
import {
  useGetAllTextBlogsQuery,
  useGetAllVideoBlogsQuery,
} from "../../features/blog/blogApiSlice";
import CommonLayout from "../../layout/CommonLayout";
const BlogPage = () => {
  const { blogtype, type } = useParams();
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(8);

  const access_token = Cookies.get("ayykori");
  const {
    data: dataText,
    isLoading: isLoadingText,
    isSuccess: isSuccessText,
    isError: isErrorText,
  } = useGetAllTextBlogsQuery(
    {
      access_token,
      blogtype: blogtype,
      category: "text",
      page: page,
      limit: limit,
    },
    { skip: type === "text" ? false : true, refetchOnReconnect: true }
  ) || {};

  const {
    data: dataVideo,
    isLoading: isLoadingVideo,
    isSuccess: isSuccessVideo,
    isError: isErrorVideo,
  } = useGetAllVideoBlogsQuery(
    {
      access_token,
      blogtype: blogtype,
      category: "video",
      page: page,
      limit: limit,
    },
    { skip: type === "video" ? false : true, refetchOnReconnect: true }
  ) || {};

  let content1 = null;
  let content2 = null;
  // text blogs
  // handle loading case
  if (isLoadingText && !isSuccessText) {
    content1 = (
      <>
        <div className="col-span-4 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6">
          {Array(5)
            ?.fill()
            ?.map((item, index) => (
              <BlogCardSkeleton data={item} key={index} />
            ))}
        </div>
      </>
    );
  }
  // handle success case
  if (!isLoadingText && isSuccessText && !isErrorText) {
    content1 = (
      <>
        <div className="col-span-4 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6">
          {dataText?.blog &&
            dataText?.blog?.map((item, index) => (
              <BlogCard data={item} key={index} />
            ))}
        </div>
      </>
    );
  }
  // handle error case
  if (!isLoadingText && isErrorText) {
    content1 = (
      <>
        <Error />
      </>
    );
  }
  // handle empty case
  if (!isLoadingText && isSuccessText && dataText?.blog?.length === 0) {
    content1 = (
      <>
        <NoDataFound />
      </>
    );
  }

  //   video blogs
  // handle loading case
  if (isLoadingVideo && !isSuccessVideo) {
    content2 = (
      <>
        <div className="col-span-4 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6">
          {Array(5)
            ?.fill()
            ?.map((item, index) => (
              <BlogCardSkeleton data={item} key={index} />
            ))}
        </div>
      </>
    );
  }
  // handle success case
  if (!isLoadingVideo && isSuccessVideo && !isErrorVideo) {
    content2 = (
      <>
        <div className="col-span-4 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6">
          {dataVideo?.blog &&
            dataVideo?.blog?.map((item, index) => (
              <BlogVideoCard
                data={item}
                styleData={{ width: "100%", height: "164px " }}
                key={index}
              />
            ))}
        </div>
      </>
    );
  }
  // handle error case
  if (!isLoadingVideo && isErrorVideo) {
    content2 = (
      <>
        <Error />
      </>
    );
  }
  // handle empty case
  if (!isLoadingVideo && isSuccessVideo && dataVideo?.blog?.length === 0) {
    content2 = (
      <>
        <NoDataFound />
      </>
    );
  }

  return (
    <>
      <Helmet>
        <title>Blogs | Ayykori</title>
      </Helmet>
      <CommonLayout>
        <div className="hidden md:block">
          <KnowInsideOutBanner banner={cashback} />
        </div>

        <div className="block md:hidden">
          <KnowInsideOutBanner banner={cashbackMobile} />
        </div>
        <div className="mt-20">
          {/* common component use */}
          <BlogSortComponent title="Blogs" limit={limit} setLimit={setLimit} />

          {/* all brand start */}
          {type === "text" && (
            <div className="w-full px-3 md:px-[55px] grid grid-cols-4 gap-4 my-10">
              {content1}
            </div>
          )}
          {type === "video" && (
            <div className="w-full px-3 md:px-[55px] grid grid-cols-4 gap-4 my-10">
              {content2}
            </div>
          )}
        </div>
        {/* all brand end */}

        {/* load more */}

        {type === "text" && dataText?.totalBrands[0]?.count > limit && (
          <div className="w-full px-3 md:px-[55px] flex justify-center md:justify-end items-center my-8">
            <ReactPaginate
              breakLabel="..."
              previousLabel="Prev"
              className="text-primaryColor flex items-center gap-2 text-sm"
              previousClassName="text-primaryColor text-sm"
              nextClassName="text-primaryColor text-sm"
              activeClassName="bg-primaryColor text-sm"
              activeLinkClassName="text-white px-2"
              nextLabel="Next"
              onPageChange={(e) => setPage(e.selected + 1)}
              pageRangeDisplayed={2}
              marginPagesDisplayed={2}
              pageCount={Math.ceil(dataText?.totalBrands[0]?.count / limit)}
              renderOnZeroPageCount={null}
            />
          </div>
        )}
        {type === "video" && dataVideo?.totalBrands[0]?.count > limit && (
          <div className="w-full px-3 md:px-[55px] flex justify-center md:justify-end items-center my-8">
            <ReactPaginate
              breakLabel="..."
              previousLabel="Prev"
              className="text-primaryColor flex items-center gap-2 text-sm"
              previousClassName="text-primaryColor text-sm"
              nextClassName="text-primaryColor text-sm"
              activeClassName="bg-primaryColor text-sm"
              activeLinkClassName="text-white px-2"
              nextLabel="Next"
              onPageChange={(e) => setPage(e.selected + 1)}
              pageRangeDisplayed={2}
              marginPagesDisplayed={2}
              pageCount={Math.ceil(dataVideo?.totalBrands[0]?.count / limit)}
              renderOnZeroPageCount={null}
            />
          </div>
        )}
      </CommonLayout>
    </>
  );
};

export default BlogPage;
