import React, { useState } from "react";
import { Helmet } from "react-helmet";
import ReactPlayer from "react-player";
import youtubeLogo from "../../Assets/images/icon/icons8-youtube-94.png";
import { police } from "../../Data/campaignVideoData";
import response from "../../Data/tempData";
import BrandPageRightPanel from "../../components/Brands/BrandPageRightPanel";
import CampaignCard from "../../components/Campaign/CampaignCard";
import CampaignStaticCard from "../../components/Campaign/CampaignStaticCard";
import CashbackProduct from "../../components/Products/CashBackProduct";
import Trending from "../../components/Trending/Trending";
import CommonLayout from "../../layout/CommonLayout";
export const PolicePrivilege = ({ data }) => {
  const [type, setType] = useState("Cashback");
  const [sliceValue, setSliceValue] = useState(500);

  return (
    <>
      <Helmet>
        <title>Police Privilege | Ayykori</title>
      </Helmet>
      <CommonLayout>
        <div className="px-3 md:mx-14 md:mb-16">
          {/* <VideoPlayer videoSource={campaignVideo1} /> */}

          <CampaignCard
            campaignVideo={
              "https://res.cloudinary.com/dxodgllun/image/upload/v1703997630/ayykori_management/gifImage/vchxb54cmnbct8xldkoj.gif"
            }
          />
        </div>

        <div className="my-10 md:my-12">
          <div className=" w-full px-3 md:px-[55px] my-5 grid md:grid-cols-5 grid-cols-1 gap-y-5 md:gap-y-0 md:gap-5">
            <div className="md:col-span-3">
              <BrandPageRightPanel
                type={type}
                response={response}
                campaignData={police}
              />
            </div>

            <div className="w-full col-span-2">
              {/* <iframe
                className="md:block hidden rounded-lg"
                width="100%"
                height="100%"
                src="https://www.youtube.com/embed/w7bUtERXK60?si=ijzLsOrrJusJepds"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen
              ></iframe>

              <iframe
                className="block md:hidden rounded-lg"
                width="100%"
                height="200px"
                src="https://www.youtube.com/embed/w7bUtERXK60?si=ijzLsOrrJusJepds"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen
              ></iframe> */}

              <ReactPlayer
                width={"100%"}
                height={"100%"}
                playing={true}
                light={true}
                stopOnUnmount={true}
                controls={true}
                playIcon={<img src={youtubeLogo} alt="" className="w-16   " />}
                url={
                  "https://www.youtube.com/embed/w7bUtERXK60?si=ijzLsOrrJusJepds"
                }
              />
            </div>
          </div>
        </div>

        <CampaignStaticCard
          newData={{ title: "Exclusive Offer", dataLimit: 20, source: "sheba" }}
        />
        {/* <div className="px-3 md:mx-14 md:mb-16">
          {campaignBanner && (
            <CampaignCard
              campaignVideo={campaignBanner[1].image}
              link={"/campaign"}
            />
          )}
        </div> */}
        <Trending
          newData={{
            title: "Daraz Exclusive offer",
            dataLimit: 20,
            source: "sheba",
          }}
        />
        <CampaignStaticCard
          newData={{ title: "Premium Brands", dataLimit: 20, source: "sheba" }}
        />

        <CashbackProduct path={"/cashback-products/all"} />

        {data ? (
          <div className="px-3 md:mx-14 md:mb-16">
            <div className="">
              <h2 className="text-xl font-semibold text-gray-700 my-2">
                {data.title}
              </h2>
              <p className=" text-base text-gray-500 my-2">{data?.subTitle}</p>
              <ul className=" text-base text-gray-500 ml-8 my-2 space-y-1 list-disc">
                {data.list &&
                  data.list.map((item, i) => <li>{item.listData}</li>)}
              </ul>
              <p className=" text-base text-gray-500 my-2">{data?.paragraph}</p>
            </div>
          </div>
        ) : (
          <div className="px-3 md:mx-14 md:mb-16"></div>
        )}
      </CommonLayout>
    </>
  );
};
