import React, { useState } from "react";
import { FiChevronDown } from "react-icons/fi";
import { RiSearchLine } from "react-icons/ri";
import { Link, useNavigate } from "react-router-dom";
import logoImg from "../../Assets/images/logo/AK-Horizontal-Logo.png";
import { navbarAccessMenuLinks } from "../../Data/navbarAccessMenuLinks";
// import logoImg from "../../Assets/images/mainlogoEng.png";
import Cookies from "js-cookie";
import { toast } from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import headphone from "../../Assets/images/icon/headset-96.png";
import stars from "../../Assets/images/icon/stars.png";
import { isLogoutUser } from "../../features/auth/authSlice";
import { showModal } from "../../features/loginModalShowSlice.js/loginModalShowSlice";
import { setReload } from "../../features/reload/reloadSlice";
import { useGetSearchBrandsQuery } from "../../features/search/searchApiSlice";
import { useGetSingleUserQuery } from "../../features/user/userApiSlice";
import { auth } from "../../firebase/Firebase";
import useAuth from "../../hooks/useAuth";
import BrandSearchCard from "../Card/BrandSearchCard";
import Dropdown from "../Dropdown/Dropdown";
import Error from "../Error/Error";
import NoDataFound from "../NodataFound/NoDataFound";
import BrandSearchCardSkeleton from "../Skeleton/BrandSearchCardSkeleton";
import { removeToken } from "../Utility/LocalStorage";

const UpperNavBar = () => {
  const { access_token: access_tokenStore } = useSelector(
    (state) => state?.authUser
  );
  // const { searchVal } = useParams();
  const [showSubMunu, setShowSubMenu] = useState(null);
  const isAuth = useAuth();
  const [search, setSearch] = useState("");
  const [enableSearch, setEnableSearch] = useState(true);
  // const searchRef = useRef(null);
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const dispatch = useDispatch();
  // const access_token = Cookies?.get("ayykori");
  const access_token = Cookies.get("ayykori");
  const { isSuccess, data } =
    useGetSingleUserQuery(access_token, {
      skip: !access_token,
      refetchOnReconnect: true,
    }) || {};

  const {
    data: dataSearch,
    isLoading: isLoadingSearch,
    isSuccess: isSuccessSearch,
    isError: isErrorSearch,
  } = useGetSearchBrandsQuery(
    { access_token, search, limit: 10, page: 1 },
    {
      skip: enableSearch,
      refetchOnReconnect: true,
    }
  ) || {};
  const handleLogout = () => {
    Cookies.remove("ayykori");
    removeToken("ayykori");
    auth.signOut();
    dispatch(isLogoutUser());
    dispatch(setReload(1));
    setShow(false);
    navigate("/");
    toast.success("Logout Successfull!!");
  };
  const handleSearch = async (e) => {
    setSearch(e.target.value);
    if (e.target.value) {
      setEnableSearch(false);
    }
  };

  // for active link style
  const activeNavLink = ({ isActive }) => {
    return {
      color: isActive ? "#11998E" : "#6b7280",
      // borderColor: isActive ? "#7efff5" : "#ffffff",
    };
  };

  let content1 = null;
  // handle loading case
  if (isLoadingSearch && !isSuccessSearch) {
    content1 = (
      <>
        <div className="grid grid-cols-4 gap-2 p-2">
          {Array(8)
            .fill()
            ?.map((item, i) => (
              <BrandSearchCardSkeleton key={i} />
            ))}
        </div>
      </>
    );
  }

  // handle success case
  if (!isLoadingSearch && isSuccessSearch && !isErrorSearch) {
    content1 = (
      <>
        <div className="grid grid-cols-2 gap-2 p-2">
          {dataSearch?.resData[0]?.Result?.map((item, i) => (
            <BrandSearchCard data={item} type={"cashback"} key={i} />
          ))}
        </div>
      </>
    );
  }
  // handle error case
  if (!isLoadingSearch && isErrorSearch) {
    content1 = (
      <>
        <Error />
      </>
    );
  }
  // handle empty case
  if (
    !isLoadingSearch &&
    isSuccessSearch &&
    dataSearch?.resData[0]?.Result?.length === 0
  ) {
    content1 = (
      <>
        <NoDataFound />
      </>
    );
  }

  // // handle loading case
  // if (isLoadingSearch && !isSuccessSearch) {
  //   content2 = (
  //     <>
  //       <div className="grid grid-cols-4 gap-2 p-2">
  //         {Array(8)
  //           .fill()
  //           ?.map((item, i) => (
  //             <BrandSearchCardSkeleton key={i} />
  //           ))}
  //       </div>
  //     </>
  //   );
  // }
  // // handle success case
  // if (!isLoadingSearch && isSuccessSearch && !isErrorSearch) {
  //   content2 = (
  //     <>
  //       <div className="grid grid-cols-4 gap-2 p-2">
  //         {dataSearch?.resData[0]?.Result?.map((item, i) => (
  //           <Link to={`/brand/earning/${item?._id}`} key={i}>
  //             <BrandSearchCard data={item} />
  //           </Link>
  //         ))}
  //       </div>
  //     </>
  //   );
  // }
  // // handle error case
  // if (!isLoadingSearch && isErrorSearch) {
  //   content2 = (
  //     <>
  //       <Error />
  //     </>
  //   );
  // }
  // // handle empty case
  // if (
  //   !isLoadingSearch &&
  //   isSuccessSearch &&
  //   dataSearch?.resData[0]?.Result?.length === 0
  // ) {
  //   content2 = (
  //     <>
  //       <NoDataFound />
  //     </>
  //   );
  // }

  const handleLoginSignup = () => {
    if ((isAuth || Cookies.get("ayykori")) && isSuccess) {
      navigate("/my-account");
    } else {
      dispatch(showModal());
    }
  };
  return (
    <div className="h-[60px] lg:h-[85px] flex items-center w-full shadow-[0px_2px_2px_#00000040] relative ">
      <div className="w-full px-3 md:px-[55px] flex items-center justify-between gap-4 max-w-[1632px] mx-auto">
        <Link to={"/"} className={`${search ? "hidden md:block" : "block"}`}>
          <img className="w-28" src={logoImg} alt="" />
        </Link>

        <div className="w-full lg:w-[450px] flex items-center justify-between gap-3 border rounded-full lg:rounded-lg ">
          <input
            className="ml-1 text-xs lg:text-base px-3 outline-none border-none w-full"
            type="text"
            value={search}
            onChange={handleSearch}
            // ref={searchRef}
            placeholder="Search store, products, category and brands"
          />
          <span className="bg-primaryColor py-2 sm:py-2.5 px-4 rounded-r-full lg:rounded-r-lg self-end">
            <RiSearchLine className={` text-white text-base lg:text-xl `} />
          </span>
        </div>

        <div className=" text-textColor font-medium hidden lg:flex items-center gap-x-1">
          {/* <BsHeadset size={28} className="text-gray-600 animate-pulse" /> */}
          <img src={headphone} className="w-8 animate-pulse" alt="" />
          <div>
            <h1 className="text-sm text-primaryColor font-outfit">
              +8809678221460
            </h1>
            <h6 className="text-[9px] text-gray-500">
              Support Center - 10:00 am to 6:00 pm
            </h6>
          </div>
        </div>

        <div
          className="hidden lg:flex items-cente text-md w-[280px] py-2 px-4 items-center justify-between bg-gradient-to-r from-[#67B26F]  to-[#4CA2CD] text-white 
           rounded-lg whitespace-pre relative "
        >
          <div className="flex items-center cursor-pointer">
            {(isAuth || Cookies.get("ayykori")) && isSuccess ? (
              <button
                onClick={handleLoginSignup}
                to={"/my-account"}
                className="capitalize"
              >
                {data?.user?.fullname?.split(" ")[0]}
              </button>
            ) : (
              <button onClick={handleLoginSignup}>Sign In</button>
            )}
            <img className="h-7" src={stars} alt="" />
          </div>
          <div
            className="flex items-center cursor-pointer gap-x-1"
            onClick={() => setShow(!show)}
          >
            {/* <img className="w-7" src={moneyImg} alt="" /> */}
            <span>0.00 BDT</span>
            {(isAuth || Cookies.get("ayykori")) && (
              <FiChevronDown
                className={`${
                  show && "rotate-180"
                } text-lg cursor-pointer transition-all duration-500`}
              />
            )}
          </div>

          {/* dropdown menu */}
          {(isAuth || Cookies.get("ayykori")) && show && (
            <>
              <Dropdown
                links={navbarAccessMenuLinks}
                handleLogout={handleLogout}
              />
            </>
          )}
        </div>

        {/* <div className="hidden lg:flex items-center gap-2">
          <IoNotificationsOutline className=" text-2xl text-gray-500" />
          <div className=" flex items-center gap-2">
            <span className=" font-medium text-gray-500">EN/BN</span>
            <ToggleButton />
          </div>
        </div> */}

        {/* <div className="hidden md:flex text-center">
          <div className="md:flex md:items-center md:justify-center">
            <div>
              <p className="text-xs text-gray-500">Scan me &</p>
              <p className="text-xs text-primaryColor">Download app</p>
            </div>
            <img className="w-20" src={pwaAppDownoadImg} alt="" />
          </div>
        </div> */}
      </div>
      <div
        className={`${
          search ? "flex" : "hidden"
        } absolute w-full md:w-[50%] h-auto min-h-72 top-full left-0 right-0 md:left-[14.5%] md:right-full  rounded-md shadow-md bg-white z-50
         flex-col border px-2 pt-2 pb-6 max-w-[1632px] mx-auto`}
      >
        <div
          className="w-full mt-2 overflow-y-auto scroll-smooth 
    scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-white 
    scrollbar-thumb-rounded-full scrollbar-track-rounded-full scrollbar-w-1"
        >
          {/* <h1
            className="text-left border-b font-outfit text-gray-500 text-base font-medium pb-2
          "
          >
            Cashback Brand
          </h1> */}
          {content1}
        </div>
      </div>
    </div>
  );
};

export default UpperNavBar;
