import { apiRequest } from "../apiRequest/apiRequest";

export const userApiSlice = apiRequest.injectEndpoints({
  endpoints: (builder) => ({
    getSingleUser: builder.query({
      query: (access_token) => ({
        url: `/myprofile`,
        method: "GET",
        headers: {
          Authorization: `Bearer ${access_token}`,
          "Content-type": "application/json;charset=UTF-8",
        },
      }),
      providesTags: ["User"],
    }),
    updateUser: builder.mutation({
      query: (newData) => {
        const { access_token, data } = newData;
        return {
          url: "/update/myprofile",
          method: "PUT",
          body: data,
          headers: {
            Authorization: `Bearer ${access_token}`,
            "Content-Type": "application/json;charset=UTF-8",
          },
        };
      },
      invalidatesTags: ["User"],
    }),
    updatePassword: builder.mutation({
      query: (newData) => {
        const { access_token, data } = newData;
        return {
          url: "/update/my-password",
          method: "PUT",
          body: data,
          headers: {
            Authorization: `Bearer ${access_token}`,
            "Content-Type": "application/json;charset=UTF-8",
          },
        };
      },
      invalidatesTags: ["User"],
    }),
  }),
});

export const {
  useGetSingleUserQuery,
  useUpdateUserMutation,
  useUpdatePasswordMutation,
} = userApiSlice;
