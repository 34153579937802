import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const BaseURL = process.env.REACT_APP_BASE_URL_MANAGEMENT;

export const managementApiSlice = createApi({
  reducerPath: "managementApiSlice",
  baseQuery: fetchBaseQuery({
    baseUrl: BaseURL,
  }),
  endpoints: (builder) => ({}),
});
