import Cookies from "js-cookie";
import { useDispatch } from "react-redux";
import { Navigate, useLocation } from "react-router-dom";
import useAuth from "../hooks/useAuth";

const PublicRoute = ({ children, path }) => {
  const dispatch = useDispatch();
  const isAuth = useAuth();
  const location = useLocation();
  // if (!isAuth) {
  //   dispatch(showModal());
  // }
  return !isAuth || !Cookies.get("ayykori") ? (
    children
  ) : (
    <Navigate to={location?.state ? location?.state : "/"} replace />
  );
};
export default PublicRoute;
