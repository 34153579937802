import React, { useState } from "react";
import { IoIosArrowForward } from "react-icons/io";
import { NavLink } from "react-router-dom";

const SubDropdownMenu = ({ links }) => {
  const [showSubMenu, setShowSubMenu] = useState(null);
  return (
    <>
      <ul
        className={`flex absolute top-0 right-full w-52 rounded-sm shadow-[1px_1px_10px_1px_rgba(0,0,0,0.2)] bg-white py-4 flex-col items-start gap-y-4 opacity-100 z-50`}
      >
        {links?.map((menu, index) => {
          return (
            <li
              key={index}
              onMouseEnter={() => setShowSubMenu(menu.id)}
              onMouseLeave={() => setShowSubMenu(null)}
              className="w-full relative "
            >
              {menu?.sublinks ? (
                <span
                  className={
                    "flex items-center justify-between cursor-pointer  "
                  }
                >
                  <span
                    className={` flex items-center text-gray-500 hover:text-primaryColor cursor-pointer gap-x-2 pl-3 text-sm `}
                  >
                    <menu.Icon />
                    {menu?.title}
                  </span>
                  {menu?.sublinks && (
                    <IoIosArrowForward
                      className={`${
                        showSubMenu === menu.id && "rotate-90"
                      } text-gray-500 transition-all duration-500 mr-2`}
                    />
                  )}
                </span>
              ) : (
                <NavLink
                  to={menu?.link}
                  end
                  className={"flex items-center justify-between cursor-pointer"}
                >
                  <span className="flex items-center text-gray-500 hover:text-primaryColor cursor-pointer gap-x-2 pl-3 text-sm">
                    <menu.Icon />
                    {menu?.title}
                  </span>
                  {menu?.sublinks && (
                    <IoIosArrowForward
                      className={`${
                        showSubMenu === menu.id && "rotate-90"
                      } text-gray-500 transition-all duration-500 mr-2`}
                    />
                  )}
                </NavLink>
              )}

              {menu?.sublinks && showSubMenu === menu.id && (
                <SubDropdownMenu links={menu?.sublinks} />
              )}
            </li>
          );
        })}
      </ul>
    </>
  );
};

export default SubDropdownMenu;
