export const activationBannerGif = [
  {
    image:
      "https://res.cloudinary.com/dr45xloag/image/upload/v1706821877/ayykori_management/default/fkybeadpatmejbgeuyyd.gif",
    id: "1",
  },
  {
    image:
      "https://res.cloudinary.com/dr45xloag/image/upload/v1706821877/ayykori_management/default/dezgisa8s9glbizonbkj.gif",
    id: "2",
  },
];

export const brandPartner = [
  {
    _id: "6492caedfcffebeab046c7b8",
    brandName: "Esquire Electronics ltd.",
    brandimg:
      "https://res.cloudinary.com/dxodgllun/image/upload/v1699701927/ayykori_management/subBrandimg/szqe3qu3gdt5uqfnlijh.png",
  },
  {
    _id: "6492caedfcffebeab046c7c0",
    brandName: "Ensign",
    brandimg:
      "https://res.cloudinary.com/dxodgllun/image/upload/v1699702097/ayykori_management/brandimg/mmnvu41gg601mwbahmw2.png",
  },
  {
    _id: "6492caedfcffebeab046c7cb",
    brandName: "PinkFlash BD",
    brandimg:
      "https://res.cloudinary.com/dxodgllun/image/upload/v1699701595/ayykori_management/subBrandimg/tvhpov9n0zpppr8gixd1.png",
  },
  {
    _id: "6492caedfcffebeab046c7ce",
    brandName: "Deen",
    brandimg:
      "https://res.cloudinary.com/dxodgllun/image/upload/v1699702310/ayykori_management/brandimg/ahi1dvadjdsws3slmlri.png",
  },
  {
    _id: "6492caedfcffebeab046c7d1",
    brandName: "Focallure BD",
    brandimg:
      "https://res.cloudinary.com/dxodgllun/image/upload/v1699702588/ayykori_management/brandimg/j7hzduksa3jeyrql4ffy.png",
  },
  {
    _id: "6492caedfcffebeab046c7d7",
    brandName: "One Ummah BD",
    brandimg:
      "https://res.cloudinary.com/dxodgllun/image/upload/v1699703086/ayykori_management/brandimg/mjfyr7v54dig3cyor12j.png",
  },
  {
    _id: "6492caedfcffebeab046c7ce",
    brandName: "Deen",
    brandimg:
      "https://res.cloudinary.com/dxodgllun/image/upload/v1699702310/ayykori_management/brandimg/ahi1dvadjdsws3slmlri.png",
  },
  {
    _id: "6492caedfcffebeab046c7d1",
    brandName: "Focallure BD",
    brandimg:
      "https://res.cloudinary.com/dxodgllun/image/upload/v1699702588/ayykori_management/brandimg/j7hzduksa3jeyrql4ffy.png",
  },
  {
    _id: "6492caedfcffebeab046c7d7",
    brandName: "One Ummah BD",
    brandimg:
      "https://res.cloudinary.com/dxodgllun/image/upload/v1699703086/ayykori_management/brandimg/mjfyr7v54dig3cyor12j.png",
  },
];

export const affiateescription = [
  {
    title: "Affiliate Marketing MasterClass Program",
    subTitle: "",
    list: [
      {
        listData:
          "Looking for a reliable way to earn extra income without any investment? if, yes then AyyKori Affiliate Program is here for you – your ticket to earning money online from your home. With our program, you can turn your spare time into a great source of income by simply sharing our affiliate link and promoting top-notch products from authentic brands.",
      },
      {
        listData:
          "Working as an AyyKori Affiliate gives you the freedom to choose your own hours, making it a flexible job that fits around your schedule. Whether you're a stay-at-home, parent, a student, a specially abled, or simply looking to grow your income, our program offers a convenient work opportunity that allows you to earn money on your terms",
      },
      {
        listData:
          "Our commission structure ensures that you earn a commission for every sale made through your affiliate link, guaranteeing you a steady stream of income with each successful transaction.",
      },
      {
        listData:
          " But that's not all. When you join our program, you'll receive top-notch training from affiliate marketing experts. With 6 dedicated insightful classes available both online and offline, 1st class and last class will take place at our office premises physically and the rest of the classes will be conducted Online ( google meet). Snacks will be provided during physical classes. Each class duration will be 45 minutes. 1st batch starts from 8th/10th March (Probable time) and ends within the first week of April with a total duration of 1 month. You can choose classes according to your flexibility either on weekdays or on weekends. You'll gain invaluable knowledge about sales tactics and strategies to maximize your earning potential. Class Contents will be provided to you in the first class. Registration fees for the total tenure will be 500 BDT only. Registration fees will be received from you in the first class.           ",
      },
      {
        listData:
          "  Moreover, you'll get real-time exposure to the market and benefit from corporate experiences, providing you with a comprehensive understanding of the industry landscape. Our program also offers guidance from previous top affiliates, giving you access to insider tips and tricks to accelerate your success.     ",
      },
      {
        listData:
          "  As you progress through the program, you'll have the opportunity to earn professional work certificates and exciting goodies as incentives for your smart work and dedication.   ",
      },
      {
        listData:
          " Don't let this opportunity slip through your fingers. Apply Now for the AyyKori Affiliate Program today and start your journey towards financial freedom without any investment .   ",
      },
    ],
  },
];

export const ambassadorImg = [
  {
    _id: 4,
    blogTitle:
      "How Sakibul Hasan of Faridpur got 17000 taka cashback by shopping | How to Get Cashback | AyyKori",
    blogLink: "https://youtu.be/QnPxC5JtOu8?si=enANyL3Jk3TQHMXo",
  },
  {
    _id: 1,
    blogTitle: "Affiliate Marketing | AyyKori",
    blogLink: "https://youtu.be/-pqtZ6_DxT8?si=GsDlhQhZUTeJDhVS",
  },
  {
    _id: 3,
    blogTitle:
      "How Sakibul Hasan of Faridpur got 17000 taka cashback by shopping | How to Get Cashback | AyyKori",
    blogLink: "https://youtu.be/GGvY-ELu8FM?si=oxLyC6A9Yrqjsyl5",
  },
  {
    _id: 2,
    blogTitle:
      "How Ripon Matabbar earned 20,000 taka commission | Affiliate Marketing | AyyKori",
    blogLink: "https://youtu.be/5rAoDkGyDqk?si=gyNmXPlz70OSjawl",
  },
];
