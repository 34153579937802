import React from "react";
import { Helmet } from "react-helmet";
import earning from "../../Assets/images/banner/knowinsideout/earning/earning.jpg";
import earning_mobile from "../../Assets/images/banner/knowinsideout/earning/earning_mobile.jpg";
import KnowInsideOutBanner from "../../components/Banner/KnowInsideOutBanner";
import EarnBlog from "../../components/Blog/EarningBlog";
import CommonLayout from "../../layout/CommonLayout";

const LearnAboutEarnPage = () => {
  return (
    <>
      <Helmet>
        <title>Learn About Earning | Ayykori</title>
      </Helmet>
      <CommonLayout>
        <div className=" hidden md:block">
          <KnowInsideOutBanner banner={earning} />
        </div>
        <div className="block md:hidden">
          <KnowInsideOutBanner banner={earning_mobile} />
        </div>
        <EarnBlog />
      </CommonLayout>
    </>
  );
};

export default LearnAboutEarnPage;
