export const campaignPageDes = [
  {
    title: "আয়করি ক্যাশব্যাক হাট Campaign",
    subTitle:
      "AyyKori and Sheba teamed up for a special program just for Sheba employees. Let's check out what this program offers:",
    list: [
      {
        listData:
          "A BDT 50 (Fifty Taka) bonus on Sheba Employee’s first purchase (Minimum order value 1,000 BDT)",
      },
      {
        listData:
          "  A Bonus of 200 BDT for a total of 10 purchases within two months (minimum purchase value per order 1000 BDT; this offer is applicable for the first two months only, starting from the first order placement date)",
      },
      { listData: "A dedicated customer service agent for Sheba Employees" },
      {
        listData:
          " A dedicated landing page for Sheba employees on the Ayykori website/m-site/app displaying premium offers for only Sheba employees",
      },
      {
        listData:
          "  The highest Purchaser from Sheba employees will get an exclusive surprise giveaway from Ayykori",
      },
    ],

    paragraph:
      " AyyKori and Sheba Employee Privilege Program brings a bundle of exclusive benefits to Sheba employees. From a warm welcome bonus and bonus rewards for regular shopping to personalized customer service, social media promotions, and a special landing page with premium offers – there's a lot to look forward to. And for the Sheba employee with the most purchases, a fantastic surprise giveaway awaits! This program is designed to make AyyKori experience extra special for Sheba's dedicated team. Happy saving while shopping, Sheba employees!",
  },
  {
    title: "আয়করি ক্যাশব্যাক হাট Campaign ",
    list: [
      {
        listSubTitle:
          "আয়করি ক্যাশব্যাক হাট Campaign  Schedule: 25 May- 20 june, 2024",
        listData:
          "First Order Reward: Gift Get a mini charger fan with purchases up to 6000 taka and earphones with purchases up to 1000 taka. Cashback Percentage: Up to 20% (Details in Description). Dashboard Update: Daraz 48 hours, Other Brands: 20 mins ",
      },
      {
        listSubTitle: "How To Get Cashack :",
        listData:
          "After coming to AyyKori App or site, choose where to shop from and click on to go to your favorite brand (For example: Daraz, MedEasy, Othoba, Pickaboo, Chaldal, Shohoz (Bus), Esquire Electronics etc.) Now click on the Get Cashback and it will take you to your favorite brand’s App or Website. Now you can purchase whatever you want, pay however you want, and come back to AyyKori’s Dashboard and go to Cashback Balance where you will see your cashback amount. The dashboard gets updated within 48 hours for Daraz and approximately 20 minutes for Other Brands.  But manual brands take 30 days to update cashback to dashboard. The cashback will be automatically sent to your bkash/ Nagad/ Bank account. Don’t forget to choose your preferred payment method. You don’t have to use any voucher or coupon to get cashback or need to withdraw cash. We will send your cashback money to your account directly. Before shopping, you can even calculate the cashback amount of your desired products from any brand on Ayykori platform using the Cashback calculator (except Khaas Food & Medeasy). If you want to earn money by doing an affiliate, then you can make your own link of any brand/specific product from the affiliate option, and promote that in your community.           ",
      },
      {
        listSubTitle: "Other Offers include :  ",
        listData:
          "Upto 83% Discount Hot Deals, Budget Deals, Free Delivery for some brands, Instant bKash Cashbacks for some brands, Prepayment discounts for some brands, Combo offers on specific brands along with up to 20% additional cashback from Ayykori (based on categories of brands).  ",
      },
      {
        listSubTitle: "Daraz Category Wise Cashback Upto:",
        listData:
          "Mother & Baby: 8.64%, Home Appliances, Fashion: 5.04%, Mobiles & Tablets, Laundry & Cleaning 4.32%, Motors, TV, Audio / Video, Gaming & Wearables, Health & Beauty , Computers & Laptops , Furniture & Décor, Sports & Outdoors, Watches, Sunglasses, & Jewellery, Tools, DIY & Outdoor, Cameras, Bags & Travel, Kitchen & Dining, Toys & Games, Bedding & Bath, Media, Music, & Books, Medicine 3.6%, Groceries , Digital Goods, Pet Supplies 2.16%, Special Digital Products 1.08% ",
      },
      {
        listSubTitle: "Medeasy Category Wise Cashback Upto: ",
        listData:
          "OTC Medicine - 10.8%, Women's Choice - 10.8%, Sexual Wellness - 10.8%, Diabetic Carem- 10.8%, Baby Care - 10.8%, Dental Care - 10.8%, Personal Care - 10.8%, Devices - 3.6%, Doctor Consultation - 7.2%, Prescription Medicine - 3.6%. ",
      },
      {
        listSubTitle: "Baccha Kaccha Category Wise Cashback Upto:  ",
        listData:
          "Diapering: 2.16%,Baby Food:0.72%,Baby Health & Safety: 3.6%,Baby Bath & Skin: 3.6%,Toys & Gifts: 2.88%,Cosmetics: 3.6%,Mommy: 2.88%,Groceries: 3.6%,Feeding: 2.88%,Boy Fashion:8.64%,Girl Fashion: 8.64%,Family Care: 2.88%,School Supplies:3.6%,Nursery:2.88%. ",
      },
      {
        listSubTitle: "Khaas Food Category Wise Cashback Upto ",
        listData:
          "Honey 3.6%, Nuts & Dates 3.6%, Spices 3.6%, Oil 3.6%, Rice 0.72%, Pulses 3.6%. Grains (Brown Atta (আটা) 0.72%, Chaler Gura (চালের গুঁড়া) & Chaler Gurar Suji (সুজি) 3.6%), HerBal Powder 3.6%, Beans & Boosters 3.6% (except Khaas Energy Booster 5.04%), Sweeteners 3.6% (except Red (Brown) Sugar (লাল চিনি)  0.72%), Dairy (except Full Cream Milk Powder (দুধ পাউডার) & Milk (দুধ) 0.72%) 3.6%, Pickle & Chutney 3.6%, Tea & Snacks 3.6%, Fruits 3.6%, Fish 3.6%, Egg & Beef 0.72%, Chicken & Duck 3.6%. ",
      },
      {
        listSubTitle: "Pickaboo Category Wise Cashback Upto: ",
        listData:
          "Smartphone: 0.72%, Electronics & Appliances: 1.08%, Mobile Accessories: 1.44%, Computers: Upto 1.08% (Sub Category: Laptop & Notebook: 0.36%,Tablets,Mini PC:0.72%, Mouse & Keyboard, IP Cameras, Pen Drive: 1.08%), Computer Accessories:1.08%, Lifestyle: 1.44%.",
      },
      {
        listSubTitle: "Protein Market Category Wise Cashback Upto:  ",
        listData: "Chicken 5.76%, Fish 5.76%, Beef 3.76%, Mutton 5.76%.",
      },
      {
        listSubTitle: "Vista Bangladesh Category Wise Cashback Upto:",
        listData:
          "TV: 10.8%, Air Conditioner: 10.8%, Rice cooker: 7.2%, Mixer grinder 7.2%.",
      },
      {
        listSubTitle: "Bimafy Category Wise Cashback Upto:",
        listData:
          "Health Insurance: 3.6%; Accident Insurance, Travel Insurance, Car Insurance, Motorbike Insurance: 7.2%.",
      },
      {
        listSubTitle: "Legal X Category Wise Cashback Upto:",
        listData:
          "Daily package cashback: Upto 7.2%, Weekly package cashback: Upto 9%, Monthly package cashback: Upto 9%.",
      },
      {
        listSubTitle: "Shohoz Bus Cashback Upto:",
        listData: "10 taka Cashback only on bus tickets.",
      },
    ],
    paragraph:
      "Don’t miss the amazing cashback offers of আয়করি ক্যাশব্যাক হাট Campaign!",
  },
  {
    title: "AyyKori and Bangladesh Police Employee Privilege Program",
    subTitle:
      "AyyKori and Bangladesh Police teamed up for a special program just for Bangladesh Police Employees. Let's check out what this program offers:",
    list: [
      {
        listData:
          "A BDT 50 (Fifty Taka) bonus on Bangladesh Police Employee’s first purchase (Minimum order value 1,000 BDT)",
      },
      {
        listData:
          "  A Bonus of 200 BDT for a total of 10 purchases within two months (minimum purchase value per order 1000 BDT; this offer is applicable for the first two months only, starting from the first order placement date)",
      },
      {
        listData:
          "A dedicated customer service agent for Bangladesh Police Employees",
      },
      {
        listData:
          " A dedicated landing page for Bangladesh Police employees on the Ayykori website/m-site/app displaying premium offers for only Bangladesh Police employees",
      },
      {
        listData:
          " The highest Purchaser from Bangladesh Police employees will get an exclusive surprise giveaway from Ayykori",
      },
    ],

    paragraph:
      "AyyKori and Bangladesh Police Employee Privilege Program brings a bundle of exclusive benefits to Bangladesh Police employees. From a warm welcome bonus and bonus rewards for regular shopping to personalized customer service, social media promotions, and a special landing page with premium offers – there's a lot to look forward to. And for the Bangladesh Police employees with the most purchases, a fantastic surprise giveaway awaits! This program is designed to make AyyKori experience extra special for Bangladesh Police's dedicated team. Happy saving while shopping, Bangladesh Police employees!",
  },
];
