import React from "react";
import { BsCheckCircleFill } from "react-icons/bs";

const SuccessCaseContent = () => {
  return (
    <div className=" w-full h-full flex flex-col items-center justify-center gap-2">
      {
        <BsCheckCircleFill className=" text-4xl md:text-8xl text-primaryColor rounded-full" />
      }
      <h2 className=" w-full md:w-[80%] text-xl font-bold text-gray-600 text-center">
        Thank you for submitting your order details. Once the brand verifies, we
        will add the cashback in your dashboard (Max. 30 days).
      </h2>
    </div>
  );
};

export default SuccessCaseContent;
