import React from "react";
const KnowInsideOutBanner = ({ banner }) => {
  return (
    <div>
      <img
        className="w-full h-36 md:h-80 md:object-cover rounded-lg"
        src={banner}
        alt=""
      />
    </div>
  );
};

export default KnowInsideOutBanner;
