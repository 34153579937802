import Cookies from "js-cookie";
import moment from "moment";
import React, { forwardRef, useEffect, useState } from "react";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { SlCalender } from "react-icons/sl";
import ReactPaginate from "react-paginate";
import Error from "../../components/Error/Error";
import NoDataFound from "../../components/NodataFound/NoDataFound";
import { useGetUserClicksQuery } from "../../features/balance/balanceApiSlice";
const UserActivityLogPage = () => {
  const [page, setPage] = useState(1);
  const [limit] = useState(10);
  const ExampleCustomInputMonth = forwardRef(({ value, onClick }, ref) => (
    <button
      className="w-28 bg-transparent flex items-center gap-2 border px-2 py-1 rounded-md text-[12px] text-gray-500"
      onClick={onClick}
      ref={ref}
    >
      <SlCalender className="text-primaryColor" />
      {value}
    </button>
  ));
  const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
    <button
      className="w-28 bg-transparent flex items-center gap-2 border px-2 py-1 rounded-md text-[12px] text-gray-500"
      onClick={onClick}
      ref={ref}
    >
      <SlCalender className="text-primaryColor" />
      {value}
    </button>
  ));
  const [tab, setTab] = useState(1);
  const [date, setDate] = useState(new Date());
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [month, setMonth] = useState(new Date());
  const access_token = Cookies?.get("ayykori");
  const {
    isLoading: isLoadingUserActivity,
    isSuccess: isSuccessUserActivity,
    isError: isErrorUserActivity,
    data: dataUserActivity,
  } = useGetUserClicksQuery(
    {
      access_token,
      source: "earning",
      month: tab === 2 && moment(month).format("MM/YYYY").toString(),
      date: tab === 1 && moment(date).toISOString(),
      startDate: tab === 3 && moment(startDate).toISOString(),
      endDate: tab === 3 && moment(endDate).toISOString(),
      page,
      limit,
    },
    { refetchOnReconnect: true }
  ) || {};

  let content = null;
  // handle loading case
  if (isLoadingUserActivity && !isSuccessUserActivity) {
    content = (
      <>
        <tbody>
          {Array(5)
            ?.fill()
            ?.map((item, index) => (
              <tr key={index} className="border-t border-gray-300">
                <td className="text-xs min-w-[250px] w-fit px-3 py-2 gap-x-0 text-gray-500 text-left">
                  <div className="bg-gray-200 animate-pulse w-full h-5"></div>
                </td>
                <td className="text-xs w-fit px-3 py-2 gap-x-0 text-gray-500 text-left">
                  <div className="bg-gray-200 animate-pulse w-full h-5"></div>
                </td>
                {/* <td className="text-xs w-fit px-3 py-2 gap-x-0 text-gray-500 text-left">
                  <div className="bg-gray-200 animate-pulse w-full h-5"></div>
                </td> */}
                <td className="text-xs w-fit px-3 py-2 gap-x-0 text-gray-500 text-left">
                  <div className="bg-gray-200 animate-pulse w-full h-5"></div>
                </td>
                <td className="text-xs w-fit px-3 py-2 gap-x-0 text-gray-500 text-left">
                  <div className="bg-gray-200 animate-pulse w-full h-5"></div>
                </td>
              </tr>
            ))}
        </tbody>
      </>
    );
  }
  // handle success case
  if (!isLoadingUserActivity && isSuccessUserActivity && !isErrorUserActivity) {
    content = (
      <>
        <tbody>
          {dataUserActivity?.click &&
            dataUserActivity?.click?.length !== 0 &&
            dataUserActivity?.click?.map((item, index) => (
              <tr key={index} className="border-t border-gray-300">
                <td className="text-xs w-fit px-3 py-2 gap-x-0 text-gray-500 text-left">
                  {moment(item?.createdAt).format("LL")}
                </td>
                <td className="text-xs w-fit px-3 py-2 gap-x-0 text-gray-500 text-left">
                  {item?.shortLink}
                </td>
                {/* <td className="text-xs w-fit px-3 py-2 gap-x-0 text-gray-500 text-center">
                  {item?.totalClickCount}
                </td> */}
                <td className="text-xs w-fit px-3 py-2 gap-x-0 text-gray-500 text-center">
                  {item?.totalClickCount}
                </td>
                <td className="text-xs w-fit px-3 py-2 gap-x-0 text-gray-500 text-center">
                  {item?.orderDetailsLength}
                </td>
              </tr>
            ))}
        </tbody>
      </>
    );
  }
  // handle error case
  if (!isLoadingUserActivity && isErrorUserActivity) {
    content = (
      <>
        <tbody>
          <tr className="border-t border-gray-300">
            <td
              colSpan={4}
              className="text-xs min-w-[250px] w-fit px-3 py-2 gap-x-0 text-gray-500 text-left"
            >
              <Error />
            </td>
          </tr>
        </tbody>
      </>
    );
  }
  // handle empty case
  if (
    !isLoadingUserActivity &&
    isSuccessUserActivity &&
    dataUserActivity?.click?.length === 0
  ) {
    content = (
      <tbody>
        <tr className="border-t border-gray-300">
          <td
            colSpan={4}
            className="text-xs min-w-[250px] w-fit px-3 py-2 gap-x-0 text-gray-500 text-left"
          >
            <NoDataFound />
          </td>
        </tr>
      </tbody>
    );
  }

  // after navigate this page show in top
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  let total_sales = dataUserActivity?.subTotalOrder?.reduce(
    (acc, item, i) => (acc += item?.orderDetailsLength),
    0
  );
  return (
    <div className="w-full py-2 md:p-8 relative md:static">
      <div className="w-full flex flex-col">
        <h1 className="text-base md:text-lg text-gray-500 font-outfit font-medium">
          Activity Log
        </h1>
        <p className="text-xs md:text-base text-gray-400 font-outfit font-light">
          Track your earning performance here
        </p>
      </div>

      <div className="w-full flex flex-col md:flex-row justify-start md:justify-between items-center gap-4 mt-4">
        <div className="w-full md:w-fit justify-start flex items-center gap-2">
          <div className="flex bg-gray-100 rounded-md items-center md:gap-2">
            <button
              onClick={() => {
                setTab(1);
                setPage(1);
              }}
              className={`${
                tab === 1
                  ? "bg-primaryColor text-white"
                  : "bg-transparent text-primaryColor"
              } text-[10px] md:text-xs px-2 py-0.5 rounded-md  transition-all duration-500`}
            >
              Date
            </button>
            <button
              onClick={() => {
                setTab(2);
                setPage(1);
              }}
              className={`${
                tab === 2
                  ? "bg-primaryColor text-white"
                  : "bg-transparent text-primaryColor"
              } text-[10px] md:text-xs px-2 py-0.5 rounded-md  transition-all duration-500 text-primaryColor`}
            >
              Month
            </button>
            <button
              onClick={() => {
                setTab(3);
                setPage(1);
              }}
              className={`${
                tab === 3
                  ? "bg-primaryColor text-white"
                  : "bg-transparent text-primaryColor"
              } text-[10px] md:text-xs px-2 py-0.5 rounded-md  transition-all duration-500 text-primaryColor`}
            >
              Range
            </button>
          </div>
        </div>
        <div className="w-full flex justify-between items-center gap-2">
          <div className="flex items-center gap-4">
            {tab === 1 && (
              <div>
                <ReactDatePicker
                  selected={date}
                  maxDate={new Date()}
                  onChange={(date) => {
                    setDate(date);
                  }}
                  customInput={<ExampleCustomInput />}
                />
              </div>
            )}
            {tab === 2 && (
              <div>
                <ReactDatePicker
                  selected={month}
                  onChange={(date) => {
                    setMonth(date);
                  }}
                  dateFormat="MM/yyyy"
                  maxDate={new Date()}
                  showMonthYearPicker
                  showFullMonthYearPicker
                  customInput={<ExampleCustomInputMonth />}
                />
              </div>
            )}
            {tab === 3 && (
              <div className="flex items-center gap-2">
                <div>
                  <ReactDatePicker
                    selected={startDate}
                    maxDate={new Date()}
                    onChange={(date) => {
                      setStartDate(date);
                    }}
                    customInput={<ExampleCustomInput />}
                  />
                </div>
                -
                <div>
                  <ReactDatePicker
                    selected={endDate}
                    maxDate={new Date()}
                    onChange={(date) => {
                      setEndDate(date);
                    }}
                    customInput={<ExampleCustomInput />}
                  />
                </div>
              </div>
            )}
          </div>
          <div className="absolute top-2 right-0 md:static flex items-center gap-2">
            <div className="text-[10px] md:text-xs text-gray-500">
              Total Click:{" "}
              <span className="text-primaryColor text-sm">
                {dataUserActivity?.subTotalclick[0]?.totalClick
                  ? dataUserActivity?.subTotalclick[0]?.totalClick
                  : 0}
              </span>
            </div>
            <div className="text-[10px] md:text-xs text-gray-500">
              Total Sales:{" "}
              <span className="text-primaryColor text-xs md:text-sm">
                {total_sales}
              </span>
            </div>
          </div>
        </div>
      </div>

      <div className="mt-4">
        <div
          className="w-full max-h-96 overflow-auto scroll-smooth 
    scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-white 
    scrollbar-thumb-rounded-full scrollbar-track-rounded-full scrollbar-w-1"
        >
          <table
            className=" whitespace-nowrap w-full h-full p-[4rem]
border-collapse rounded transition duration-700 shadow-lg"
          >
            <thead>
              <tr className="bg-gradient-to-b from-gray-100 to-transparent">
                <th className="text-xs py-2 px-3 gap-x-0 text-gray-400 font-medium text-left">
                  Created Date
                </th>
                <th className="text-xs py-2 px-3 gap-x-0 text-gray-400 font-medium text-left">
                  Affiliate Link
                </th>
                {/* <th className="text-xs  py-2 px-3 gap-x-0 text-gray-400 font-medium text-center">
                  Total Click
                </th> */}
                <th className="text-xs py-2 px-3 gap-x-0 text-gray-400 font-medium text-center">
                  Unique Click
                </th>
                <th className="text-xs py-2 px-3 gap-x-0 text-gray-400 font-medium text-center">
                  Sales Count
                </th>
              </tr>
            </thead>
            {content}
          </table>
        </div>
        {dataUserActivity?.total[0]?.count > limit && (
          <div className="w-full flex justify-center md:justify-end items-center my-8">
            <div className="w-full px-3 md:px-[55px] flex justify-center md:justify-end items-center my-8">
              <ReactPaginate
                breakLabel="..."
                previousLabel="Prev"
                className="text-primaryColor flex items-center gap-2 text-sm"
                previousClassName="text-primaryColor text-sm"
                nextClassName="text-primaryColor text-sm"
                activeClassName="bg-primaryColor text-sm"
                activeLinkClassName="text-white px-2"
                nextLabel="Next"
                onPageChange={(e) => setPage(e.selected + 1)}
                pageRangeDisplayed={2}
                marginPagesDisplayed={2}
                pageCount={Math.ceil(dataUserActivity?.total[0]?.count / limit)}
                renderOnZeroPageCount={null}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default UserActivityLogPage;
