import { apiRequest } from "../apiRequest/apiRequest";

export const contactApiSlice = apiRequest.injectEndpoints({
  endpoints: (builder) => ({
    createContact: builder.mutation({
      query: (data) => {
        const { access_token, bodyData } = data || {};
        return {
          url: `/create-contact`,
          method: "POST",
          headers: {
            Authorization: `Bearer ${access_token}`,
            "Content-Type": "application/json;charset=UTF-8",
          },
          body: bodyData,
        };
      },
      invalidatesTags: ["Contact"],
    }),
  }),
});

export const { useCreateContactMutation } = contactApiSlice;
