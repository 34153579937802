import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isModalOpen: false,
};

export const loginModalShowSlice = createSlice({
  name: "loginModalShowSlice",
  initialState,
  reducers: {
    showModal: (state, action) => {
      state.isModalOpen = true;
    },
    hideModal: (state, action) => {
      state.isModalOpen = false;
    },
    // showToast: (state, action) => {
    //   state.isModalOpen = false;
    // },
  },
});

// Action creators are generated for each case reducer function
export const { showModal, hideModal } = loginModalShowSlice.actions;

export default loginModalShowSlice.reducer;
