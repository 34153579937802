import { apiRequest } from "../apiRequest/apiRequest";

export const blogApiSlice = apiRequest.injectEndpoints({
  endpoints: (builder) => ({
    getAllTextBlogs: builder.query({
      query: (data) => {
        const { access_token, blogtype, category, page, limit } = data;
        return {
          url: `/blog-get-by-blogtype?blogtype=${blogtype}&category=${category}&page=${page}&limit=${limit}`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${access_token}`,
            "Content-Type": "application/json;charset=UTF-8",
          },
        };
      },
      providesTags: ["Blog"],
    }),
    getAllVideoBlogs: builder.query({
      query: (data) => {
        const { access_token, blogtype, category, page, limit } = data;
        return {
          url: `/blog-get-by-blogtype?blogtype=${blogtype}&category=${category}&page=${page}&limit=${limit}`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${access_token}`,
            "Content-Type": "application/json;charset=UTF-8",
          },
        };
      },
      providesTags: ["Blog"],
    }),
    getAllHighestViewTextBlogs: builder.query({
      query: (data) => {
        const { access_token, blogtype, category, page, limit } = data;
        return {
          url: `/blog-highest-view?blogtype=${blogtype}&category=${category}&page=${page}&limit=${limit}`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${access_token}`,
            "Content-Type": "application/json;charset=UTF-8",
          },
        };
      },
      providesTags: ["Blog"],
    }),
    getAllHighestViewVideoBlogs: builder.query({
      query: (data) => {
        const { access_token, blogtype, category, page, limit } = data;
        return {
          url: `/blog-highest-view?blogtype=${blogtype}&category=${category}&page=${page}&limit=${limit}`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${access_token}`,
            "Content-Type": "application/json;charset=UTF-8",
          },
        };
      },
      providesTags: ["Blog"],
    }),
    updateBlogViewCount: builder.mutation({
      query: (data) => {
        const { id } = data;
        return {
          url: `/blog-update-view/${id}`,
          method: "PUT",
          headers: {
            "Content-Type": "application/json;charset=UTF-8",
          },
        };
      },
      invalidatesTags: ["Blog"],
    }),
  }),
});

export const {
  useGetAllTextBlogsQuery,
  useGetAllVideoBlogsQuery,
  useGetAllHighestViewTextBlogsQuery,
  useGetAllHighestViewVideoBlogsQuery,
  useUpdateBlogViewCountMutation,
} = blogApiSlice;
