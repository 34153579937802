import React from "react";
import contactusGif from "../../Assets/images/gif/88560-contact.gif";
const ContactUsGif = () => {
  return (
    <div className="px-10 md:px-16 items-start mt-4 ">
      <img className="md:-mt-16" src={contactusGif} alt="" />
    </div>
  );
};

export default ContactUsGif;
