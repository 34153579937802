import { apiRequest } from "../apiRequest/apiRequest";

export const searchApiSlice = apiRequest.injectEndpoints({
  endpoints: (builder) => ({
    getSearchBrands: builder.query({
      query: (data) => {
        const { access_token, search, page, limit } = data || {};
        return {
          url: `/brand-all-search?search=${search}&limit=${limit}&page=${page}`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${access_token}`,
            "Content-Type": "application/json;charset=UTF-8",
          },
        };
      },
      providesTags: [""],
    }),
  }),
});

export const { useGetSearchBrandsQuery } = searchApiSlice;
