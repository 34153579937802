import Cookies from "js-cookie";
import React, { useState } from "react";
import { useGetCampaignTrendingDataQuery } from "../../features/Trending/TrendingApiSlice";
import TrendingCard from "../Card/TrendingCard";
import Error from "../Error/Error";
import NoDataFound from "../NodataFound/NoDataFound";
import TrendingCardSkeleton from "../Skeleton/TrendingCardSkeleton";

const CampaignStaticCard = ({ newData }) => {
  const { title, dataLimit, source } = newData || {};
  const [limit, setLimit] = useState(10);

  const [campaign, setCampaign] = useState("");

  const [page, setPage] = useState(1);

  const access_token = Cookies.get("ayykori");
  const { data, isLoading, isSuccess, isError } =
    useGetCampaignTrendingDataQuery(
      {
        access_token,
        page: page,
        limit: dataLimit ? dataLimit : 50,
        tag: source ? `${title.toLowerCase()}-${source}` : title.toLowerCase(),
      },
      { refetchOnReconnect: true }
    ) || {};

  let content = null;
  // handle loading case
  if (isLoading && !isSuccess) {
    content = (
      <>
        <div
          className=" w-full px-3 md:px-[55px] grid grid-cols-1 sm:grid-cols-2  md:grid-cols-3 lg:grid-cols-5  
      gap-y-4 gap-x-4 my-10"
        >
          {Array(10)
            ?.fill()
            ?.map((item, i) => (
              <TrendingCardSkeleton key={i} />
            ))}
        </div>
      </>
    );
  }
  // handle success case
  if (!isLoading && isSuccess && !isError) {
    content = (
      <>
        <div
          className=" w-full px-3 md:px-[55px] grid grid-cols-2 sm:grid-cols-2  md:grid-cols-3 lg:grid-cols-4  
      gap-y-10 gap-x-4 md:my-10"
        >
          {data?.data &&
            data?.data?.map((item, i) => <TrendingCard data={item} key={i} />)}
        </div>
      </>
    );
  }
  // handle error case
  if (!isLoading && isError) {
    content = (
      <>
        <Error />
      </>
    );
  }
  // handle empty case
  if (!isLoading && isSuccess && data?.data?.length === 0) {
    content = (
      <>
        <NoDataFound />
      </>
    );
  }

  return (
    <div className=" my-14 md:my-20">
      <div className=" w-full px-3 md:px-[55px] my-5 ">
        <div className=" text-center flex items-center justify-between gap-2 ">
          <div className="  w-full h-[2.5px] flex justify-between items-end gap-x-5 bg-gray-300 ">
            <h1
              className="whitespace-pre  w-fit text-sm sm:text-lg md:text-2xl text-gray-600 font-medium pb-2 border-b-[3px]
 border-primaryColor capitalize"
            >
              {title}
            </h1>

            {/* Sort Start */}
            {/* <div className="flex items-center gap-x-2 mb-2 text-gray-500">
              <div className="flex items-center border text-sm rounded-lg px-3 gap-x-2">
                <RxDashboard />
                Show
                <select
                  value={limit}
                  onChange={(e) => setLimit(e.target.value)}
                  className=" px-1 py-1 md:py-2 bg-transparent  text-gray-500 outline-none cursor-pointer"
                >
                  <option value={16}>16</option>
                  <option value={20}>20</option>
                  <option value={25}>25</option>
                </select>
              </div>
              <div className="hidden md:flex items-center border rounded-lg text-sm px-2">
                <BiSort />
                Sort By:
                <select
                  onChange={(e) => setCampaign(e.target.value)}
                  value={campaign}
                  className=" px-2 py-1 md:py-2 bg-transparent  text-gray-500 outline-none cursor-pointer"
                >
                  <option value={""}>Please Selete</option>
                  <option value={1}>Latest First</option>
                  <option value={-1}>Previous First</option>
                </select>
              </div>
            </div> */}
            {/* Sort End */}
          </div>
        </div>
      </div>
      {content}

      {/* {data?.data.length !== 0 && data?.total > data?.data?.length && (
        <ReactPaginateHelper setPage={setPage} data={data} limit={limit} />
      )} */}
    </div>
  );
};

export default CampaignStaticCard;
