import { apiRequest } from "../apiRequest/apiRequest";

export const favBrandApiSlice = apiRequest.injectEndpoints({
  endpoints: (builder) => ({
    createFavBrand: builder.mutation({
      query: (data) => {
        const { access_token, favBrandID, whichBasisFav } = data || {};
        return {
          url: `/create-fav-brand`,
          method: "POST",
          headers: {
            Authorization: `Bearer ${access_token}`,
            "Content-Type": "application/json;charset=UTF-8",
          },
          body: {
            favBrandID,
            whichBasisFav:
              whichBasisFav === "cashback" ? "cashback" : "earning",
          },
        };
      },
      invalidatesTags: ["FavBrand"],
    }),
    deleteFavBrand: builder.mutation({
      query: (data) => {
        const { access_token, id } = data || {};
        return {
          url: `/delete-fav-brand/${id}`,
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${access_token}`,
            "Content-Type": "application/json;charset=UTF-8",
          },
        };
      },
      invalidatesTags: ["FavBrand"],
    }),
    getFavBrands: builder.query({
      query: (data) => {
        const { access_token, favbrand, page, limit } = data || {};
        return {
          url: `/get-fav-brand?favBrand=${
            favbrand === "cashback" ? "cashback" : "earning"
          }&page=${page}&limit=${limit}`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${access_token}`,
            "Content-Type": "application/json;charset=UTF-8",
          },
        };
      },
      providesTags: ["FavBrand"],
    }),
  }),
});

export const {
  useCreateFavBrandMutation,
  useDeleteFavBrandMutation,
  useGetFavBrandsQuery,
} = favBrandApiSlice;
