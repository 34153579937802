export const campaignBlogData = {
  blogLink: "https://youtu.be/F7r1tARJxgk?si=tchM9CYWFG5dwNrt",
  blogTitle: "svava",
};

export const campaignNew = {
  "Campaign Schedule": " 25 May- 20 june, 2024  ",
  "First Order Reward":
    "Gift Get a mini charger fan with purchases up to 6000 taka and earphones with purchases up to 1000 taka.",
  "Cashback Percentage": "Up to 20% (Details in Description).",
  // "Store specific Schedule": " ",
  Dashboard: "Daraz 48 hours, Other Brands: 20 mins  ",
};
export const sheba = {
  "Campaign Schedule": " 2 February - 16 February,2024",
  "First Order Reward": "50 Taka Bonus  ( Min order value: 1000 BDT)",
  "Cashback Percentage": "Upto 16% Cashback",
  "Store specific Schedule": " ",
  Dashboard: "Daraz 48 hours, Other Brands - 20 mins ",
};
export const police = {
  // "Campaign Schedule": "12 December - 21 December 2023",
  "First Order Reward": "50 Taka Bonus ( Min order value: 1000 BDT)",
  "Cashback Rate": "Upto 16%",
  "Store specific Schedule": " ",
  Dashboard: "Daraz 48 hours, Other Brands - 20 mins ",
};
