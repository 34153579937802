import { apiRequest } from "../apiRequest/apiRequest";

export const profitLinkApiSlice = apiRequest.injectEndpoints({
  endpoints: (builder) => ({
    createProfitLink: builder.mutation({
      query: (data) => {
        const { access_token, fullLink, source } = data || {};
        let device = localStorage.getItem("app") ? "app" : undefined;

        // console.log(device);
        return {
          url: `/create-shortlink`,
          method: "POST",
          headers: {
            Authorization: `Bearer ${access_token}`,
            "Content-Type": "application/json;charset=UTF-8",
          },
          body: { fullLink, source, device },
        };
      },
      invalidatesTags: [""],
    }),
    createProfitLinkForShare: builder.mutation({
      query: (data) => {
        const { access_token, fullLink, source } = data || {};
        let device = localStorage.getItem("app") && "app";
        return {
          url: `/create-shortlink`,
          method: "POST",
          headers: {
            Authorization: `Bearer ${access_token}`,
            "Content-Type": "application/json;charset=UTF-8",
          },
          body: { fullLink, source, device },
        };
      },
      invalidatesTags: [""],
    }),
    createProfitLinkFromShortLink: builder.mutation({
      query: (data) => {
        const { uID, shortLink } = data || {};
        let device = localStorage.getItem("app") && "app";
        return {
          url: `/create-profitlink`,
          method: "POST",
          headers: {
            "Content-Type": "application/json;charset=UTF-8",
          },
          body: { shortLink, uID, device },
        };
      },
      invalidatesTags: [""],
    }),
  }),
});

export const {
  useCreateProfitLinkMutation,
  useCreateProfitLinkForShareMutation,
  useCreateProfitLinkFromShortLinkMutation,
} = profitLinkApiSlice;
