import Cookies from "js-cookie";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { TbHeartOff } from "react-icons/tb";
import { TiTags } from "react-icons/ti";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { useDeleteFavBrandMutation } from "../../features/favBrands/favBrandApiSlice";
import { showModal } from "../../features/loginModalShowSlice.js/loginModalShowSlice";
import { useCreateProfitLinkMutation } from "../../features/profitlink/profitlinkApiSlice";
import useAuth from "../../hooks/useAuth";
import CopyLinkModal from "../Modal/CopyLinkModal";

const BrandCard = ({ data }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const isAuth = useAuth();
  const [copyModal, setCopyModal] = useState(false);
  let cashbackSubBrand = data?.subBrand === "Yes" && data?.type === "cashback";
  let earningSubBrand = data?.subBrand === "Yes" && data?.type === "earning";
  const access_token = Cookies.get("ayykori");
  const [
    createProfitLink,
    {
      data: dataProfit,
      isLoading: isLoadingProfit,
      isError: isErrorProfit,
      isSuccess: isSuccessProfit,
    },
  ] = useCreateProfitLinkMutation() || {};

  const [deleteFavBrand, { isLoading: isLoadingDelete }] =
    useDeleteFavBrandMutation() || {};

  const handleCashbackOrEarning = async () => {
    if (isAuth || Cookies.get("ayykori")) {
      if (data?.type === "cashback") {
        const res = await createProfitLink({
          access_token,
          fullLink: data?.brandHomepageLink,
          source: "cashback",
        });

        const { data: shortlinkData } = res || {};
        if (
          shortlinkData &&
          shortlinkData?.shortLink &&
          shortlinkData?.succss
        ) {
          // const profitLinkRes = await createProfitLinkFromShortLink({
          //   access_token,
          //   shortLink: shortlinkData?.shortLink,
          // });
          // const { data: profitLinkResData } = profitLinkRes || {};

          // if (
          //   profitLinkResData &&
          //   profitLinkResData.profitLink &&
          //   profitLinkResData.succss
          // ) {
          let uid =
            shortlinkData?.shortLink?.split("_")[
              shortlinkData?.shortLink?.split("_")?.length - 1
            ];
          window.open(
            `/cashback-activated/${data?.id}?uid=${uid}`,
            "_blank",
            // "popup",
            "noreferrer"
          );
          // }
        }
      }
      if (data?.type === "earning") {
        setCopyModal(true);
        createProfitLink({
          access_token,
          fullLink: data?.brandHomepageLink,
          source: "earning",
        });
      }
    } else {
      dispatch(showModal());
    }
  };

  const handleGotoBrand = (data) => {
    // console.log(`/brand/${data?.type}/${data?.id}`);
    const access_token = Cookies.get("ayykori");
    // if (isAuth || Cookies.get("ayykori")) {
    // if (data.brandUnid === "2001") {
    //   navigate(`/campaign`);
    // } else {
    navigate(`/brand/${data?.type}/${data?.id}`);
    // }

    window.scrollTo(0, 0);
    // } else {

    //   dispatch(showModal());
    // }
  };

  useEffect(() => {
    if (isErrorProfit) {
      toast.error("Something Went Wrong!! please Try again!");
    }
  }, [isErrorProfit]);

  return (
    <>
      <CopyLinkModal
        copyModal={copyModal}
        setCopyModal={setCopyModal}
        proftlink={dataProfit?.shortLink}
        isLoading={isLoadingProfit}
        isError={isErrorProfit}
      />
      <div
        className={`group h-fit flex flex-col justify-between border  
     ${
       !data.borderShow
         ? "border-none"
         : "border hover:shadow-[.8px_.8px_.8px_.8px_#00000040]"
     } rounded-lg py-3 px-4 space-y-3   transition-all duration-500 relative md:static`}
      >
        <div className="md:flex gap-3">
          <div className="w-20 mx-auto md:mx-0 h-20 flex justify-center items-center pb-2">
            <LazyLoadImage
              className="w-full h-full object-cover border rounded-lg  "
              src={data?.brandCardimg}
              loading="lazy"
              alt=""
              // placeholderSrc={blurimg}
              // effect="blur"
            />
            {/* <img
              className="w-full h-full object-cover border rounded-lg  "
              src={data?.brandCardimg}
              alt=""
            /> */}
          </div>
          <div className=" space-y-1.5 flex  flex-col items-center md:items-start">
            <h1 className=" text-xs md:text-sm font-semibold text-gray-700 whitespace-pre">
              {data?.name?.length > 22
                ? data?.name.slice(0, 20) + "..."
                : data?.name}
            </h1>
            <div className="flex items-center gap-1 ">
              <TiTags className="text-primaryColor" />
              <p className=" text-gray-500 text-xs md:text-sm whitespace-pre">
                {data?.brandType?.length > 20
                  ? data?.brandType.slice(0, 18) + "..."
                  : data?.brandType}
              </p>
            </div>
            <span className=" flex items-center gap-x-2 text-xs md:text-sm ">
              {data?.brandUnid === "2040" ||
              data?.brandId === "65e844670754c7e14046814a" ||
              data?.brandName === "Shohoz (Bus)" ? (
                <p className=" text-primaryColor whitespace-pre">
                  {data?.userCommission} &#2547; Per ticket
                  {data?.type === "earning" ? " Commission" : " Cashback"}
                </p>
              ) : (
                <p className=" text-primaryColor whitespace-pre">
                  Upto {data?.userCommission} %
                  {data?.type === "earning" ? "Commission" : "Cashback"}
                </p>
              )}
            </span>
          </div>
        </div>

        <div className="flex items-center justify-between gap-x-2 ">
          {cashbackSubBrand || earningSubBrand ? (
            <button
              onClick={handleCashbackOrEarning}
              className="w-full text-primaryColor p-2 md:p-2 border border-primaryColor rounded-lg  text-xs md:text-base
      text-center group-hover:bg-primaryColor group-hover:text-white transition-all duration-700 "
            >
              {cashbackSubBrand &&
                (isLoadingProfit ? "Loading..." : "Get Cashback")}
              {earningSubBrand &&
                (isLoadingProfit ? "Loading..." : "Affiliate Link")}
            </button>
          ) : (
            <button
              // to={`/brand/${data?.type}/${data?.id}`}
              onClick={() => handleGotoBrand(data)}
              className="w-full text-primaryColor p-2 md:p-2 border border-primaryColor rounded-lg 
      text-center group-hover:bg-primaryColor group-hover:text-white transition-all duration-700 text-xs md:text-base "
            >
              {data?.type === "earning" ? "Affiliate Link" : "Get Cashback"}
            </button>
          )}
          {data?.favId && (
            <button
              title="Remove Favourite"
              disabled={isLoadingDelete}
              onClick={() => deleteFavBrand({ access_token, id: data?.favId })}
              className="absolute top-1 right-1 md:static cursor-pointer p-1 md:p-2 border border-primaryColor rounded-full md:rounded-lg 
      text-center transition-all duration-700 text-primaryColor"
            >
              <TbHeartOff className={"text-xs md:text-2xl"} />
            </button>
          )}
        </div>
      </div>
    </>
  );
};

export default BrandCard;
