import React, { useEffect } from "react";
import Footer from "../components/Footer/Footer";
import Navbar from "../components/NavBar/Navbar";

const CommonLayout = ({ children }) => {
  // after navigate this page show in top
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="mb-16 lg:mb-0">
      <Navbar />
      <div className="mt-[70px] sm:mt-[165px] max-w-[1632px] mx-auto">
        {children}
      </div>
      <Footer />
    </div>
  );
};

export default CommonLayout;
