import React from "react";
import ReactPlayer from "react-player/lazy";
import youtubeLogo from "../../Assets/images/icon/icons8-youtube-94.png";
const YoutubeIframe = ({ data, styleData }) => {
  return (
    <div>
      {" "}
      <ReactPlayer
        width={styleData?.width}
        height={styleData?.height}
        bor
        playing={true}
        light={true}
        stopOnUnmount={true}
        controls={true}
        playIcon={<img src={youtubeLogo} alt="" className="w-12   " />}
        url={data}
      />
    </div>
  );
};

export default YoutubeIframe;
