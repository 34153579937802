import Cookies from "js-cookie";
import React from "react";
import {
  useGetAllHighestViewTextBlogsQuery,
  useGetAllHighestViewVideoBlogsQuery,
  useGetAllTextBlogsQuery,
  useGetAllVideoBlogsQuery,
} from "../../features/blog/blogApiSlice";
import BlogCard from "../Card/BlogCard";
import BlogVideoCard from "../Card/BlogVideoCard";
import SidebarCard from "../Card/SidebarCard";
import SidebarCardVideo from "../Card/SidebarCardVideo";
import CommonComponent from "../CommonComponent/CommonComponent";
import Error from "../Error/Error";
import NoDataFound from "../NodataFound/NoDataFound";
import BlogCardSkeleton from "../Skeleton/BlogCardSkeleton";

const CashbackBlog = () => {
  const access_token = Cookies.get("ayykori");

  const {
    data: dataText,
    isLoading: isLoadingText,
    isSuccess: isSuccessText,
    isError: isErrorText,
  } = useGetAllTextBlogsQuery(
    {
      access_token,
      blogtype: "cashback",
      category: "text",
      page: 1,
      limit: 6,
    },
    { refetchOnReconnect: true }
  ) || {};
  const {
    data: dataVideo,
    isLoading: isLoadingVideo,
    isSuccess: isSuccessVideo,
    isError: isErrorVideo,
  } = useGetAllVideoBlogsQuery({
    access_token,
    blogtype: "cashback",
    category: "video",
    page: 1,
    limit: 6,
  }) || {};
  const {
    data: dataViewText,
    isLoading: isLoadingViewText,
    isSuccess: isSuccessViewText,
    isError: isErrorViewText,
  } = useGetAllHighestViewTextBlogsQuery({
    access_token,
    blogtype: "cashback",
    category: "text",
    page: 1,
    limit: 6,
  }) || {};
  const {
    data: dataViewVideo,
    isLoading: isLoadingViewVideo,
    isSuccess: isSuccessViewVideo,
    isError: isErrorViewVideo,
  } = useGetAllHighestViewVideoBlogsQuery({
    access_token,
    blogtype: "cashback",
    category: "video",
    page: 1,
    limit: 6,
  }) || {};

  let content1 = null;
  let content1View = null;
  let content2 = null;
  let content2View = null;

  // ==========text blogs start here===========
  // handle loading case
  if (isLoadingText && !isSuccessText) {
    content1 = (
      <>
        <div className="col-span-4 lg:col-span-3 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
          {Array(5)
            ?.fill()
            ?.map((item, index) => (
              <BlogCardSkeleton data={item} key={index} />
            ))}
        </div>
      </>
    );
  }
  // handle success case
  if (!isLoadingText && isSuccessText && !isErrorText) {
    content1 = (
      <>
        <div className="col-span-4 lg:col-span-3 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
          {dataText?.blog &&
            dataText?.blog?.map((item, index) => (
              <BlogCard data={item} key={index} />
            ))}
        </div>
      </>
    );
  }
  // handle error case
  if (!isLoadingText && isErrorText) {
    content1 = (
      <>
        <Error />
      </>
    );
  }
  // handle empty case
  if (!isLoadingText && isSuccessText && dataText?.blog?.length === 0) {
    content1 = (
      <>
        <NoDataFound />
      </>
    );
  }
  // handle loading case
  if (isLoadingViewText && !isSuccessViewText) {
    // content1View = (
    //   <>
    //     <div className="col-span-4 lg:col-span-3 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
    //       {Array(5)
    //         ?.fill()
    //         ?.map((item, index) => (
    //           <BlogCardSkeleton data={item} key={index} />
    //         ))}
    //     </div>
    //   </>
    // );
  }
  // handle success case
  if (!isLoadingViewText && isSuccessViewText && !isErrorViewText) {
    content1View = (
      <>
        <div className="mt-6 md:mt-0 col-span-4 lg:col-span-1 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:flex lg:flex-col gap-6 overflow-y-auto">
          {dataViewText?.blog &&
            dataViewText?.blog?.map((item, index) => (
              <SidebarCard data={item} key={index} />
            ))}
        </div>
      </>
    );
  }
  // handle error case
  if (!isLoadingViewText && isErrorViewText) {
    content1View = (
      <>
        <Error />
      </>
    );
  }
  // handle empty case
  if (
    !isLoadingViewText &&
    isSuccessViewText &&
    dataViewText?.blog?.length === 0
  ) {
    content1View = (
      <>
        <NoDataFound />
      </>
    );
  }
  // =========== text blogs ends here ==========

  // =========== video blogs start here =========
  // handle loading case
  if (isLoadingVideo && !isSuccessVideo) {
    content2 = (
      <>
        <div className="col-span-4 lg:col-span-3 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
          {Array(5)
            ?.fill()
            ?.map((item, index) => (
              <BlogCardSkeleton data={item} key={index} />
            ))}
        </div>
      </>
    );
  }
  // handle success case
  if (!isLoadingVideo && isSuccessVideo && !isErrorVideo) {
    content2 = (
      <>
        <div className="h-fit col-span-4 lg:col-span-3 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-x-6 gap-y-10">
          {dataVideo?.blog &&
            dataVideo?.blog?.map((item, index) => (
              <BlogVideoCard
                data={item}
                styleData={{ width: "100%", height: "164px " }}
                key={index}
              />
            ))}
        </div>
      </>
    );
  }
  // handle error case
  if (!isLoadingVideo && isErrorVideo) {
    content2 = (
      <>
        <Error />
      </>
    );
  }
  // handle empty case
  if (!isLoadingVideo && isSuccessVideo && dataVideo?.blog?.length === 0) {
    content2 = (
      <>
        <NoDataFound />
      </>
    );
  }
  // handle loading case
  if (isLoadingViewVideo && !isSuccessViewVideo) {
    // content2View = (
    //   <>
    //     <div className="col-span-4 lg:col-span-3 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
    //       {Array(5)
    //         ?.fill()
    //         ?.map((item, index) => (
    //           <BlogCardSkeleton data={item} key={index} />
    //         ))}
    //     </div>
    //   </>
    // );
  }
  // handle success case
  if (!isLoadingViewVideo && isSuccessViewVideo && !isErrorViewVideo) {
    content2View = (
      <>
        <div className="mt-6 md:mt-0 col-span-4 lg:col-span-1 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:flex lg:flex-col gap-6 lg:h-60 overflow-y-auto">
          {dataViewVideo?.blog &&
            dataViewVideo?.blog?.map((item, index) => (
              <SidebarCardVideo
                styleData={{ width: "100%", height: "49px" }}
                data={item}
                key={index}
              />
            ))}
        </div>
      </>
    );
  }
  // handle error case
  if (!isLoadingViewVideo && isErrorViewVideo) {
    content2View = (
      <>
        <Error />
      </>
    );
  }
  // handle empty case
  if (
    !isLoadingViewVideo &&
    isSuccessViewVideo &&
    dataViewVideo?.blog?.length === 0
  ) {
    content2View = (
      <>
        <NoDataFound />
      </>
    );
  }
  // ========== video blogs ends here ===========
  return (
    <>
      <div className="mt-20">
        <CommonComponent
          data={{
            title: "Explore Tutorials",
            link: `/blogs/cashback/video`,
            linktitle: "See All",
            show: true,
          }}
        />

        <div className="w-full px-3 md:px-[55px] grid grid-cols-4 gap-4  my-5">
          {content2}
          {content1View}
        </div>
      </div>
      <div className="mt-20">
        <CommonComponent
          data={{
            title: "Explore Latest Blogs",
            link: `/blogs/cashback/text`,
            linktitle: "See All",
            show: true,
          }}
        />
        <div className="w-full px-3 md:px-[55px] grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-2 md:gap-4 my-5">
          {content1}
          {content2View}
        </div>
      </div>
    </>
  );
};

export default CashbackBlog;
