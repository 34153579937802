import React from "react";
import { Helmet } from "react-helmet";
import cashback from "../../Assets/images/banner/knowinsideout/cashback/cashback.jpg";
import cashbackMobile from "../../Assets/images/banner/knowinsideout/cashback/cashback_mobile.jpg";
import KnowinsideOutBanner from "../../components/Banner/KnowInsideOutBanner";
import CashbackBlog from "../../components/Blog/CashbackBlog";
import CommonLayout from "../../layout/CommonLayout";

const LearnAboutCashbackPage = () => {
  return (
    <>
      <Helmet>
        <title>Learn About Cashback | Ayykori</title>
      </Helmet>
      <CommonLayout>
        <div className="hidden md:block">
          <KnowinsideOutBanner banner={cashback} />
        </div>

        <div className="block md:hidden">
          <KnowinsideOutBanner banner={cashbackMobile} />
        </div>
        <CashbackBlog />
      </CommonLayout>
    </>
  );
};

export default LearnAboutCashbackPage;
