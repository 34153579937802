import Cookies from "js-cookie";
import React, { useEffect, useState } from "react";
import { AiOutlineArrowRight } from "react-icons/ai";
import { useDispatch } from "react-redux";
import { useParams, useSearchParams } from "react-router-dom";
import {
  categoryApiSlice,
  useGetAllSubCategoryByCategoryQuery,
} from "../../features/category/categoryApiSlice";

const CategoriesPageLeftPanel = ({ data }) => {
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [, setQuery] = useSearchParams();
  let { categoryname } = useParams();
  categoryname = categoryname.replace(/-/g, " ");
  const access_token = Cookies.get("ayykori");

  const {
    data: dataCategory,
    isLoading: isLoadingCategory,
    isSuccess: isSuccessCategory,
  } = useGetAllSubCategoryByCategoryQuery(
    {
      access_token,
      categoryname,
      limit: 15,
    },
    { refetchOnReconnect: true }
  ) || {};

  const [selectedSubcategory, setSelectedSubCategory] = useState(null);

  const handleSubcategoryChange = (event) => {
    setSelectedSubCategory(event.target.value);
    setQuery((prev) => {
      const prevQuery = new URLSearchParams(prev);
      prevQuery.set("subCategory", event.target.value);
      return prevQuery;
    });
  };

  useEffect(() => {
    if (page > 1) {
      dispatch(
        categoryApiSlice.endpoints.getMoreAllSubCategoryByCategory.initiate({
          access_token,
          categoryname,
          page: page,
          limit: 15,
        })
      );
    }
  }, [page, access_token, categoryname, dispatch]);

  return (
    <div
      className={`basis-full md:basis-[30%] h-[700px] overflow-y-auto scroll-smooth 
    scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-white 
    scrollbar-thumb-rounded-full scrollbar-track-rounded-full scrollbar-w-0 border rounded-lg md:p-6  md:block  ${
      data ? "block" : "hidden"
    }`}
    >
      <div className="mb-4  ">
        <ul className="flex flex-col pb-2">
          <h1 className="font-outfit text-sx md:text-2xl font-normal text-gray-700">
            Sub Categories
          </h1>
          <div className={`ml-2 mt-2 text-sm  `}>
            {dataCategory?.subCategory[0]?.subcategories &&
              isSuccessCategory &&
              dataCategory?.subCategory[0]?.subcategories?.map(
                (item, index) => (
                  <li
                    key={index}
                    className="flex items-center gap-x-3 my-2 capitalize text-gray-700"
                  >
                    <input
                      name="subcategory"
                      type="checkbox"
                      value={item}
                      checked={selectedSubcategory === item}
                      onChange={handleSubcategoryChange}
                    />
                    {item}
                  </li>
                )
              )}
            {isLoadingCategory &&
              Array(5)
                .fill()
                ?.map((item, i) => (
                  <li
                    key={i}
                    role="status"
                    className="transition-all duration-500 text-gray-600 ease-in-out group py-1 w-full h-4 space-y-4 my-2  rounded shadow animate-pulse bg-gray-200"
                  ></li>
                ))}
          </div>
        </ul>

        {/* load more function */}
        {dataCategory?.subCategory?.length >= 10 &&
          dataCategory?.subCategory?.length !==
            dataCategory?.total[0]?.count && (
            <span
              onClick={() => setPage(page + 1)}
              className="text-primaryColor flex items-center gap-x-1 font-outfit text-xs font-normal ml-2 -mt-3 cursor-pointer"
            >
              View More <AiOutlineArrowRight />
            </span>
          )}
      </div>
    </div>
  );
};

export default CategoriesPageLeftPanel;
