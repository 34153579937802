import Cookies from "js-cookie";
import React, { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { AiOutlineQuestionCircle } from "react-icons/ai";
import { BsFillShareFill } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useGetDynamicFieldQuery } from "../../features/dynamicField/dynamicFieldForBrandSlice";
import { useCreateFavBrandMutation } from "../../features/favBrands/favBrandApiSlice";
import { showModal } from "../../features/loginModalShowSlice.js/loginModalShowSlice";
import { setMkActivityId } from "../../features/mkActivityId/mkActivityIdSlice";
import {
    useCreateProfitLinkForShareMutation,
    useCreateProfitLinkFromShortLinkMutation,
    useCreateProfitLinkMutation,
} from "../../features/profitlink/profitlinkApiSlice";
import {
    changeShareLink,
    resetShareLink,
} from "../../features/sharelink/sharelinkSlice";
import useAuth from "../../hooks/useAuth";
import modernCopyToClipboardFunction from "../../utils/modernCopyClipBoard";
import Error from "../Error/Error";
import CopyLinkModal from "../Modal/CopyLinkModal";
import FormModal from "../Modal/FormModal";
import ShareModal from "../Modal/ShareModal";
import NoDataFound from "../NodataFound/NoDataFound";
import SingleBrandPageHeroCashbackCardSkeleton from "../Skeleton/SingleBrandPageHeroCashbackCardSkeleton";

const GetCashBackCart = ({ response, type }) => {
    const { id } = useParams();
    const [showAddUser, setShowAddUser] = useState(false);
    const auth = useAuth();
    const dispatch = useDispatch();
    const access_token = Cookies.get("ayykori");

    const { brandDetails: singleBrand } = useSelector(
        (state) => state?.singleBrand
    );

    // console.log(singleBrand);
    const [
        createProfitLink,
        {
            data: dataProfit,
            isLoading: isLoadingProfit,
            isError: isErrorProfit,
            isSuccess: isSuccessProfit,
        },
    ] = useCreateProfitLinkMutation() || {};

    const [
        createProfitLinkForShare,
        {
            data: dataProfitShare,
            isLoading: isLoadingProfitShare,
            isError: isErrorProfitShare,
            isSuccess: isSuccessProfitShare,
        },
    ] = useCreateProfitLinkForShareMutation() || {};
    const [
        createFavBrand,
        {
            isLoading: isLoadingCreate,
            isSuccess: isSuccessCreate,
            isError: isErrorCreate,
            error: errorCreate,
        },
    ] = useCreateFavBrandMutation() || {};
    const [
        createProfitLinkFromShortLink,
        { data: dataLink, isError: isErrorLink, isSuccess: isSuccessLink },
    ] = useCreateProfitLinkFromShortLinkMutation() || {};

    const [shareModal, setShareModal] = useState(false);
    const [copyModal, setCopyModal] = useState(false);
    const [dynamicBrand, setDynamicBrand] = useState(null);
    const { data, isLoading, isSuccess, isError } = response || {};

    const brandDetails = data?.resData[0];

    const {
        data: dynamicField,
        isLoading: isLoadingdynamicField,
        isError: isErrordynamicField,
        isSuccess: isSuccessdynamicField,
    } = useGetDynamicFieldQuery(brandDetails?.brandDomain, {
        skip: !singleBrand?.brandDomain,
    }) || {};
    // if (!isLoadingdynamicField && !isErrordynamicField && isSuccessdynamicField) {
    //   const { brandDomain, type } = dynamicField?.result || {};
    //   if (type === "manual") {
    //     setDynamicBrand(brandDomain);
    //   }
    // }
    const handleGetCashback = async () => {
        const access_token = Cookies.get("ayykori");
        if (auth || access_token) {
            // const specialDomains = ["learning.uvtrbd.com"];
            if (
                dynamicField?.result?.brandDomain === singleBrand?.brandDomain
            ) {
                setShowAddUser(true);
                const shortlinkRes = await createProfitLink({
                    access_token,
                    fullLink: brandDetails?.brandHomepageLink,
                    source: "cashback",
                });
                const { data: shortlinkData } = shortlinkRes || {};
                if (shortlinkData && shortlinkData?.shortLink) {
                    let uid =
                        shortlinkData?.shortLink?.split("_")[
                            shortlinkData?.shortLink?.split("_")?.length - 1
                        ];
                    const activityId = await createProfitLinkFromShortLink({
                        uID: uid,
                    });
                    // console.log(activityId);
                    const { profitLink } = activityId?.data || {};
                    // console.log(profitLink);
                    const url = new URL(profitLink);
                    const searchParams = new URLSearchParams(url.search);
                    const mkActivityIdValue =
                        searchParams.get("mk_activity_id");

                    dispatch(setMkActivityId(mkActivityIdValue));
                }
            } else {
                const shortlinkRes = await createProfitLink({
                    access_token,
                    fullLink: brandDetails?.brandHomepageLink,
                    source: "cashback",
                });
                const { data: shortlinkData } = shortlinkRes || {};
                if (shortlinkData && shortlinkData?.shortLink) {
                    let uid =
                        shortlinkData?.shortLink?.split("_")[
                            shortlinkData?.shortLink?.split("_")?.length - 1
                        ];
                    if (type === "cashback" && shortlinkData?.shortLink) {
                        window.open(
                            `/cashback-activated/${id}?uid=${uid}`,
                            "_blank",
                            // "popup",
                            "noreferrer"
                        );
                    }
                }
            }
        } else {
            dispatch(showModal());
        }
    };
    let content = null;
    // handle loading case
    if (isLoading && !isSuccess) {
        content = (
            <>
                <SingleBrandPageHeroCashbackCardSkeleton />
            </>
        );
    }
    // handle success case
    if (!isLoading && isSuccess && !isError) {
        content = (
            <>
                <div className="min-h-[320px] border  flex flex-col justify-center p-2 rounded-xl">
                    <div className=" flex flex-col items-center rounded-md justify-center mb-2">
                        <img
                            className="w-20 h-20 object-cover rounded-md"
                            src={brandDetails?.brandimg}
                            alt=""
                        />
                    </div>
                    <div>
                        <h1 className="text-center font-outfit text-lg font-semibold text-gray-600">
                            {brandDetails?.subBrandName
                                ? brandDetails?.subBrandName
                                : brandDetails?.brandName}
                        </h1>
                        {brandDetails?.brandUnid === "2040" ||
                        brandDetails.brandDonain ===
                            "shohoz.com/bus-tickets" ? (
                            <h2 className="text-primaryColor text-lg font-medium text-center font-outfit">
                                Upto{" "}
                                {type === "earning"
                                    ? brandDetails?.earningUserCommission
                                    : brandDetails?.cashbackUserCommission}
                                &#2547;{" "}
                                {type === "earning" ? "Affiliate " : "Cashback"}
                            </h2>
                        ) : (
                            <h2 className="text-primaryColor text-lg font-medium text-center font-outfit">
                                Upto{" "}
                                {type === "earning"
                                    ? brandDetails?.earningUserCommission
                                    : brandDetails?.cashbackUserCommission}
                                %{" "}
                                {type === "earning" ? "Affiliate " : "Cashback"}
                            </h2>
                        )}

                        {type === "cashback" ? (
                            <button
                                onClick={handleGetCashback}
                                className="w-full cursor-pointer block text-center text-white border border-transparent rounded-md bg-primaryColor text-sm py-2 px-2 my-1 hover:bg-transparent hover:text-primaryColor hover:border-primaryColor transition-all duration-300 "
                            >
                                {type === "cashback" &&
                                    (isLoadingProfit
                                        ? "Loading..."
                                        : "Avail Cashback")}
                            </button>
                        ) : (
                            <button
                                onClick={(e) => {
                                    setCopyModal(true);
                                    createProfitLink({
                                        access_token,
                                        fullLink:
                                            brandDetails?.brandHomepageLink,
                                        source: "earning",
                                    });
                                }}
                                className="w-full text-white border border-transparent rounded-md bg-primaryColor text-sm py-2 px-2 my-1 hover:bg-transparent hover:text-primaryColor hover:border-primaryColor transition-all duration-300"
                            >
                                {type === "earning" &&
                                    (isLoadingProfit
                                        ? "Loading..."
                                        : "Affiliate  Link")}
                            </button>
                        )}
                        {/* <button
              className="w-full rounded-md text-sm py-2 px-2 border text-gray-500 my-1 flex items-center justify-center gap-x-2 hover:bg-primaryColor hover:text-white transition-all duration-300"
              onClick={() =>
                // navigate("/know-inside-out/learn-about-cashback")
                // console.log("first")
                window.open(
                  `https://www.youtube.com/watch?v=w7bUtERXK60`,
                  "_blank",
                  // "popup",
                  "noreferrer"
                )
              }
            >
              <AiOutlineQuestionCircle
                className="text-xl"
                // onClick={() =>
                //   navigate("/know-inside-out/learn-about-cashback")
                // }
              />
              {type === "cashback"
                ? "How to get Cashback"
                : "How to Generate Affiliate  Link"}
            </button> */}
                        <div className="w-full grid grid-cols-3  justify-between items-center gap-x-2 mt-3">
                            {/* <button
                disabled={isLoadingCreate}
                onClick={() =>
                  createFavBrand({
                    access_token,
                    favBrandID: brandDetails?._id,
                    whichBasisFav: type,
                  })
                }
                className="w-full rounded-md text-sm py-2 px-2 border text-gray-500 my-1 flex items-center justify-center gap-x-2 hover:bg-primaryColor hover:text-white transition-all duration-300 group"
              >
                <BsFillSuitHeartFill className="text-xl p-1 rounded-full border border-gray-500 group-hover:border-white" />
                {isLoadingCreate ? "Loading.." : "Favourite"}
              </button> */}

                            <button
                                className="col-span-3 lg:col-span-2 w-full rounded-md text-sm py-2 px-2 border text-gray-500 my-1 flex items-center justify-center gap-x-2 hover:bg-primaryColor hover:text-white transition-all duration-300 group"
                                onClick={() =>
                                    // navigate("/know-inside-out/learn-about-cashback")
                                    // console.log("first")
                                    window.open(
                                        singleBrand?.videoLink
                                            ? singleBrand?.videoLink
                                            : `https://www.youtube.com/watch?v=w7bUtERXK60`,
                                        "_blank",
                                        "noreferrer"
                                    )
                                }
                            >
                                <AiOutlineQuestionCircle
                                    className="text-md"
                                    // onClick={() =>
                                    //   navigate("/know-inside-out/learn-about-cashback")
                                    // }
                                />
                                {type === "cashback"
                                    ? "How to get Cashback"
                                    : "How to Generate Affiliate  Link"}
                            </button>
                            <button
                                disabled={isLoadingProfitShare}
                                onClick={() => {
                                    createProfitLinkForShare({
                                        access_token,
                                        fullLink: brandDetails?.subBrandLink
                                            ? brandDetails?.subBrandLink
                                            : brandDetails?.brandHomepageLink,
                                        source:
                                            data?.buttontitle ===
                                            ("Get Cashback" || "Avail Cashback")
                                                ? "cashback"
                                                : "earning",
                                    });
                                    dispatch(resetShareLink());
                                    setShareModal(true);
                                }}
                                className="col-span-3 lg:col-span-1 w-full rounded-md text-sm py-2 px-2 border text-gray-500 my-1 flex items-center justify-center gap-x-2 hover:bg-primaryColor hover:text-white transition-all duration-300 group"
                            >
                                <BsFillShareFill className="text-xl p-1 rounded-full border border-gray-500 group-hover:border-white" />
                                Share
                            </button>
                        </div>
                    </div>
                </div>
            </>
        );
    }
    // handle error case
    if (!isLoading && isError) {
        content = (
            <>
                <Error />
            </>
        );
    }
    // handle empty case
    if (!isLoading && isSuccess && data?.resData?.length === 0) {
        content = (
            <>
                <NoDataFound />
            </>
        );
    }
    // adding brands to favourite
    useEffect(() => {
        if (isSuccessCreate) {
            toast.success("Favourite Brand Added!!");
        }
        if (isErrorCreate) {
            toast.error(errorCreate?.data?.message || "Something Went Wrong!");
        }
    }, [isSuccessCreate, isErrorCreate, errorCreate?.data?.message]);

    useEffect(() => {
        // if (type === "cashback" && isSuccessProfit) {
        //   createProfitLinkFromShortLink({
        //     access_token,
        //     shortLink: dataProfit?.shortLink,
        //   });
        // }
        if (isSuccessProfitShare && dataProfitShare?.shortLink) {
            modernCopyToClipboardFunction(dataProfitShare?.shortLink);
            // navigator?.clipboard?.writeText(dataProfitShare?.shortLink);
            dispatch(changeShareLink(dataProfitShare?.shortLink));
        }
    }, [
        isSuccessProfit,
        isSuccessProfitShare,
        dataProfitShare?.shortLink,
        dispatch,
    ]);

    // redirecting it to the shortLink
    // useEffect(() => {
    //   if (isSuccessProfit && !isLoadingProfit && !isErrorProfit) {
    //     let uid =
    //       dataProfit?.shortLink?.split("_")[
    //         dataProfit?.shortLink?.split("_")?.length - 1
    //       ];
    //     if (type === "cashback" && isSuccessProfit) {
    //       window.open(
    //         `/cashback-activated/${id}?uid=${uid}`,
    //         "_blank",
    //         // "popup",
    //         "noreferrer"
    //       );
    //     }
    //   }
    // }, [
    //   isSuccessProfit,
    //   dataProfit?.shortLink,
    //   id,
    //   isErrorProfit,
    //   isLoadingProfit,
    //   type,
    // ]);

    // showing error message
    useEffect(() => {
        if (isErrorProfit && type === "cashback") {
            toast.error("Something Went Wrong!!Please Try again Later!!");
        }
        if (isErrorProfit && type === "earning") {
            toast.error("Something Went Wrong!! Please Try again Later!!");
        }
        if (isErrorProfitShare && type === "cashback") {
            toast.error("Something Went Wrong!! Please Try again Later!!");
        }
        if (isErrorProfitShare && type === "earning") {
            toast.error("Something Went Wrong!! Please Try again Later!!");
        }
    }, [isErrorProfit, isErrorProfitShare, type]);

    return (
        <div className=" ">
            <CopyLinkModal
                copyModal={copyModal}
                setCopyModal={setCopyModal}
                proftlink={dataProfit?.shortLink}
                isLoading={isLoadingProfit}
                isError={isErrorProfit}
            />
            <ShareModal shareModal={shareModal} setShareModal={setShareModal} />

            <FormModal
                setShowAddUser={setShowAddUser}
                showAddUser={showAddUser}
                // type={"updateUser"}
                // data={userData}
            />

            {content}
        </div>
    );
};

export default GetCashBackCart;
