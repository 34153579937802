import Cookies from "js-cookie";
import React, { useEffect } from "react";
import { toast } from "react-hot-toast";
import { BsExclamationCircle } from "react-icons/bs";
import { Link, useParams, useSearchParams } from "react-router-dom";
import logoImg from "../../Assets/images/logo/AK-Horizontal-Logo.png";
import BrandRedirectPageStepper from "../../components/Brands/BrandRedirectPageStepper";
import { useGetSingleBrandQuery } from "../../features/brand/brandApiSlice";
import { useCreateProfitLinkFromShortLinkMutation } from "../../features/profitlink/profitlinkApiSlice";
import { useGetSingleUserQuery } from "../../features/user/userApiSlice";
const CashbackRedirectingPage = () => {
  const [query] = useSearchParams();
  const { id } = useParams();
  const access_token = Cookies.get("ayykori");

  const [
    createProfitLinkFromShortLink,
    { data: dataLink, isError: isErrorLink, isSuccess: isSuccessLink },
  ] = useCreateProfitLinkFromShortLinkMutation() || {};
  const { data: dataUser } = useGetSingleUserQuery(access_token) || {};
  const response =
    useGetSingleBrandQuery(
      { access_token, id },
      { refetchOnReconnect: true }
    ) || {};
  const { data } = response;

  const brandDetails = data?.resData[0];

  //  getting profit link from shortlink
  useEffect(() => {
    createProfitLinkFromShortLink({
      uID: query?.get("uid"),
    });
  }, [access_token, createProfitLinkFromShortLink, query]);

  // redirecting it to the shortLink
  useEffect(() => {
    if (isSuccessLink) {
      window.location.href = dataLink?.profitLink;
    }
  }, [isSuccessLink, dataLink?.profitLink]);

  // showing error
  useEffect(() => {
    if (isErrorLink) {
      toast.error("Something Went Wrong!!");
    }

    // if (isError) {
    //   toast.error("Something Went Wrong!!");
    // }
  }, [isErrorLink]);

  return (
    <div className=" w-screen md:w-full  h-screen md:h-full py-10 bg-gray-200 flex flex-col justify-center items-center">
      <Link to="/" className="">
        <img src={logoImg} className="w-24 md:w-32 object-cover" alt="" />
      </Link>
      <div className="w-[95%] sm:w-[80%] md:w-[70%] lg:w-[45%] flex flex-col gap-y-2 md:gap-y-4 items-center justify-center rounded-md bg-white border px-3 md:px-6 py-10">
        <div className="flex items-center gap-x-2">
          <img
            src={
              brandDetails?.subBrandimg
                ? brandDetails?.subBrandimg
                : brandDetails?.brandimg
            }
            alt=""
            className="w-16 md:w-24 h-16 md:h-24 object-contain rounded-full md:rounded-md border"
          />
          <h1 className="text-lg md:text-2xl font-medium font-outfit">
            {brandDetails?.subBrandName
              ? brandDetails?.subBrandName
              : brandDetails?.brandName}
          </h1>
        </div>
        <h3 className="text-gray-600 font-outfit text-center text-base md:text-xl">
          Congratulations!{" "}
          {dataUser?.user?.fullname ? dataUser?.user?.fullname : ""}, Your
        </h3>

        {brandDetails?.brandUnid === "2040" ||
        brandDetails?.brandId === "65e844670754c7e14046814a" ||
        brandDetails?.brandName === "Shohoz (Bus)" ? (
          <h6 className="text-xl md:text-3xl text-red-400 font-medium">
            Up to {brandDetails?.cashbackUserCommission}&#2547; cashback
          </h6>
        ) : (
          <h6 className="text-xl md:text-3xl text-red-400 font-medium">
            Up to {brandDetails?.cashbackUserCommission}% cashback
          </h6>
        )}
        {/* <h4 className="text-xl md:text-3xl text-red-400 font-medium">
          Upto {brandDetails?.cashbackUserCommission}% Cashback
        </h4> */}
        <h4 className="text-lg md:text-2xl text-gray-500 mb-2 md:mb-4">
          Activated
        </h4>
        <div className="w-full px-4 md:px-20">
          <BrandRedirectPageStepper response={response} />
        </div>

        <div className="flex items-center gap-x-2 text-base md:text-xl text-gray-600 mt-3 md:mt-5">
          <BsExclamationCircle className="text-gray-400" />
          <h1>Max: {brandDetails?.cashbackLimit}</h1>
        </div>

        <p className="text-xs md:text-lg text-gray-500 font-outfit font-normal text-center px-8 md:px-20 mt-2 md:mt-10">
          Cashback will be applied to total bill and automatically added to your
          Ayykori balance!!
        </p>
      </div>
    </div>
  );
};

export default CashbackRedirectingPage;
