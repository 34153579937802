import Cookies from "js-cookie";
import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { AiOutlineLink } from "react-icons/ai";
import ReactPaginate from "react-paginate";
import ProductCard from "../../components/Card/ProductCard";
import BestDealsProductsSortComponent from "../../components/CommonComponent/BestDealsProductsSortComponent";
import Error from "../../components/Error/Error";
import NoDataFound from "../../components/NodataFound/NoDataFound";
import ProductCardSkeleton from "../../components/Skeleton/ProductCardSkeleton";
import ShareAndEarnSlider from "../../components/Slider/ShareAndEarnSlider";
import { useGetProductsByCategoryQuery } from "../../features/product/productApiSlice";
import CommonLayout from "../../layout/CommonLayout";

const CommissionProductPage = () => {
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(15);
  const access_token = Cookies.get("ayykori");
  const { data, isLoading, isSuccess, isError } =
    useGetProductsByCategoryQuery(
      {
        access_token,
        page: page,
        limit: limit,
      },
      { refetchOnReconnect: true }
    ) || {};

  let content = null;
  // handle loading case
  if (isLoading && !isSuccess) {
    content = (
      <>
        <div
          className="w-full px-3 md:px-[55px] grid grid-cols-2 sm:grid-cols-2  md:grid-cols-3 lg:grid-cols-5
      gap-y-2 md:gap-y-4 gap-x-2 md:gap-x-4"
        >
          {Array(12)
            .fill()
            ?.map((item, i) => (
              <ProductCardSkeleton key={i} />
            ))}
        </div>
      </>
    );
  }
  // handle success case
  if (!isLoading && isSuccess && !isError) {
    content = (
      <>
        <div
          className="w-full px-3 md:px-[55px] grid grid-cols-2 sm:grid-cols-2  md:grid-cols-3 lg:grid-cols-5
      gap-y-2 md:gap-y-4 gap-x-2 md:gap-x-4"
        >
          {data?.resData &&
            isSuccess &&
            data?.resData?.map((item, i) => (
              <ProductCard
                key={i}
                data={{
                  id: item?._id,
                  image: item?.productImg,
                  name: item?.productTitle,
                  price: item?.productPrice,
                  rating: item?.productRating,
                  category: item?.category,
                  subCategory: item?.subCategory,
                  productDetails: item?.productDetails,
                  productLink: item?.productLink,
                  // brandId: item?.brandDetails[0]?._id,
                  brandId: item?.brandUnid,
                  discount: item?.productProfitInPercent,
                  profit: item?.earningProductProfitAmount,
                  brandimg: item?.brandimg,
                  whichBasisFav: "earning",
                  buttontitle: "Affiliate Link",
                  Icon: AiOutlineLink,
                }}
              />
            ))}
        </div>
      </>
    );
  }
  // handle error case
  if (!isLoading && isError) {
    content = (
      <>
        <Error />
      </>
    );
  }
  // handle empty case
  if (!isLoading && isSuccess && data?.resData?.length === 0) {
    content = (
      <>
        <NoDataFound />
      </>
    );
  }

  return (
    <>
      <Helmet>
        <title>Best Deals To Share | Ayykori</title>
      </Helmet>
      <CommonLayout>
        <ShareAndEarnSlider />

        <div className="mb-10 mt-16">
          <BestDealsProductsSortComponent
            title="Best Deals To Share"
            limit={limit}
            setLimit={setLimit}
          />
          {content}
          {data?.totalData[0]?.count > limit && (
            <div className="w-full px-3 md:px-[55px] flex justify-center md:justify-end items-center my-8">
              <ReactPaginate
                breakLabel="..."
                previousLabel="Prev"
                className="text-primaryColor flex items-center gap-2 text-sm"
                previousClassName="text-primaryColor text-sm"
                nextClassName="text-primaryColor text-sm"
                activeClassName="bg-primaryColor text-sm"
                activeLinkClassName="text-white px-2"
                nextLabel="Next"
                onPageChange={(e) => setPage(e.selected + 1)}
                pageRangeDisplayed={2}
                marginPagesDisplayed={2}
                pageCount={Math.ceil(data?.totalData[0]?.count / limit)}
                renderOnZeroPageCount={null}
              />
            </div>
          )}
        </div>
      </CommonLayout>
    </>
  );
};

export default CommissionProductPage;
