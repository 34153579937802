import Cookies from "js-cookie";
import moment from "moment/moment";
import React, { useEffect } from "react";
import { toast } from "react-hot-toast";
import { HiPlusCircle } from "react-icons/hi";
import { TfiTimer } from "react-icons/tfi";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import { useDispatch } from "react-redux";
import blur from "../../Assets/images/blur.jpg";
import { showModal } from "../../features/loginModalShowSlice.js/loginModalShowSlice";
import {
    useCreateProfitLinkForShareMutation,
    useCreateProfitLinkFromShortLinkMutation,
    useCreateProfitLinkMutation,
} from "../../features/profitlink/profitlinkApiSlice";
import {
    changeShareLink,
    resetShareLink,
} from "../../features/sharelink/sharelinkSlice";
import useAuth from "../../hooks/useAuth";
import modernCopyToClipboardFunction from "../../utils/modernCopyClipBoard";
import { Link } from "react-router-dom";
const ShareandjoyCard = ({ data, shareModal, setShareModal }) => {
    const dispatch = useDispatch();
    const isAuth = useAuth();

    const access_token = Cookies.get("ayykori");
    const [
        createProfitLinkFromShortLink,
        {
            isLoading: isLoadingLink,
            isError: isErrorLink,
            isSuccess: isSuccessLink,
        },
    ] = useCreateProfitLinkFromShortLinkMutation() || {};

    const [
        createProfitLink,
        {
            data: dataProfit,
            isLoading: isLoadingProfit,
            isError: isErrorProfit,
            isSuccess: isSuccessProfit,
        },
    ] = useCreateProfitLinkMutation() || {};

    const [
        createProfitLinkForShare,
        {
            data: dataProfitShare,
            isLoading: isLoadingProfitShare,
            isError: isErrorProfitShare,
            isSuccess: isSuccessProfitShare,
        },
    ] = useCreateProfitLinkForShareMutation() || {};

    const timeConversion = (timestamp) => {
        const isoDate = moment(timestamp, "DD/MM/YYYY").toISOString();
        const now = moment();
        const end = moment(isoDate);
        const duration = moment.duration(end.diff(now));
        const days = Math.abs(Math.floor(duration.asDays()));
        const hours = Math.abs(Math.floor(duration.asHours() % 24));
        const minutes = Math.abs(Math.floor(duration.asMinutes() % 60));

        const durationString = `${days}d ${hours}h ${minutes}m`;
        return duration?._milliseconds < 0 ? "expired" : durationString;
    };

    // showing error message
    useEffect(() => {
        if (isErrorProfit) {
            toast.error("Something Went Wrong!!");
        }
        if (isErrorProfitShare) {
            toast.error("Something Went Wrong!!");
        }
    }, [isErrorProfit, isErrorProfitShare]);

    const getDealHandle = async () => {
        if (isAuth || Cookies.get("ayykori")) {
            const shortlinkRes = await createProfitLink({
                access_token,
                fullLink: data?.campainLink,
                source: "cashback",
            });

            const { data: shortlinkData } = shortlinkRes || {};
            // console.log(shortlinkData);
            // console.log(data);
            if (
                shortlinkData &&
                shortlinkData?.shortLink &&
                shortlinkData?.succss
            ) {
                let uid =
                    shortlinkData?.shortLink?.split("_")[
                        shortlinkData?.shortLink?.split("_")?.length - 1
                    ];

                window.open(
                    `/cashback-activated/${
                        data?.brandUnid ? data?.brandUnid : data?._id
                    }?uid=${uid}`,
                    "_blank",
                    // "popup",-
                    "noreferrer"
                );
            }
        } else {
            // navigate("/login", { state: location?.pathname });
            dispatch(showModal());
        }
    };

    const handleEarning = async () => {
        if (isAuth || Cookies.get("ayykori")) {
            const shortlinkRes = await createProfitLinkForShare({
                access_token,
                fullLink: data?.campainLink,
                source: "earning",
            });
            dispatch(resetShareLink());
            setShareModal(true);
            const { data: shortlinkData } = shortlinkRes || {};

            if (
                shortlinkData &&
                shortlinkData?.shortLink &&
                shortlinkData?.succss
            ) {
                // navigator?.clipboard?.writeText(shortlinkData?.shortLink);
                modernCopyToClipboardFunction(shortlinkData?.shortLink);
                toast.success("Link Copied successfull!!");
                dispatch(resetShareLink());
                dispatch(changeShareLink(shortlinkData?.shortLink));
                setShareModal(true);
            }
        } else {
            dispatch(showModal());
            // navigate("/login", { state: location?.pathname });
        }
    };

    return (
        <>
            <div className="w-full mx-auto shadow-md rounded-lg cursor-pointer hover:shadow-lg overflow-hidden ">
                <div className="w-full flex flex-col  gap-2 pb-2">
                    <LazyLoadImage
                        className="w-full md:min-h-[245px] h-full rounded-lg rounded-b-none hover:scale-[1.01] 
          transition duration-300"
                        src={data?.shareandjoyImg}
                        placeholderSrc={blur}
                        alt=""
                    />
                    <h1 className="px-3 text-lg font-medium text-gray-700 capitalize">
                        {data?.shareandjoyName?.length >= 22
                            ? data?.shareandjoyName?.slice(0, 18) + "..."
                            : data?.shareandjoyName}
                    </h1>
                    <span className=" px-3 flex flex-col gap-2">
                        <p className="whitespace-pre  flex  items-center gap-1 text-xs text-primaryColor">
                            {/* <span>
                <TbDiscount size={18} />
              </span>{" "} */}
                            {/* Flat {data.off}% off */}
                            {data?.shareandjoyOFF}
                        </p>
                        <div className="flex items-center justify-between">
                            <p className="whitespace-pre flex gap-1 items-center text-[10px] text-secondary">
                                <span>
                                    <HiPlusCircle size={15} />
                                </span>{" "}
                                {/* Upto {data.cashback}% Cashback */}
                                {data?.shareandjoyCashBack}
                            </p>
                            {/^([0-9]{2})\/([0-9]{2})\/([0-9]{4})$/.test(
                                data?.campainTime
                            ) && (
                                <p className=" flex gap-1 items-center px-3 text-[10px] whitespace-pre text-gray-400">
                                    <span>
                                        <TfiTimer />
                                    </span>
                                    {timeConversion(data?.campainTime)}
                                </p>
                            )}
                        </div>
                    </span>

                    <div className=" px-3 py-2 flex items-center whitespace-pre justify-between gap-3 flex-wrap">
                        {/* previous Get Deal Button */}
                        {/* <button
                            disabled={isLoadingProfit}
                            onClick={getDealHandle}
                            className=" text-sm text-white rounded-lg border border-primaryColor
           bg-primaryColor hover:bg-transparent hover:text-primaryColor px-8 py-[5px] whitespace-pre  "
                        >
                            {isLoadingProfit ? "Loading..." : "Get Deal"}
                        </button> */}

                        {/* new Get Deal Button */}
                        <Link
                            to={data?.campainLink}
                            className=" text-sm text-white rounded-lg border border-primaryColor bg-primaryColor hover:bg-transparent hover:text-primaryColor px-8 py-[5px] whitespace-pre"
                        >
                            Get Deal
                        </Link>

                        <button
                            disabled={isLoadingProfitShare}
                            onClick={handleEarning}
                            className="py-[3px] border border-primaryColor px-2 rounded-md  
          text-base text-primaryColor hover:bg-primaryColor hover:text-white"
                        >
                            {/* <RiShareForward2Fill /> */}
                            Share
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ShareandjoyCard;
