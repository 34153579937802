import React, { useEffect, useState } from "react";

import { AiOutlineSchedule } from "react-icons/ai";
import { BsLightningCharge } from "react-icons/bs";
import { RiUserVoiceLine } from "react-icons/ri";
import workshop from "../../Assets/images/gif/workshop.gif";
import masterclass from "../../Assets/images/online-workshop/masterclass.png";
import regularclass from "../../Assets/images/online-workshop/regularclass.png";

import WorkShopModal from "../../components/Modal/WorkShopModal";

const OnlineWorkShopPage = () => {
  const [show, setShow] = useState();

  // after navigate this page show in top
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <WorkShopModal show={show} setShow={setShow} />
      <div className="py-2 md:p-8">
        <h1 className="text-base md:text-lg text-gray-500 font-outfit font-medium">
          Online Workshop
        </h1>
        <p className="text-xs md:text-base text-gray-400 font-outfit font-light">
          Attend digital workshops to connect with experts, learn new skills,
          and take your earnings to the next level!
        </p>

        <div className="flex flex-col-reverse md:grid grid-cols-2 md:my-7">
          <ol class="h-fit ml-4  relative text-gray-500 border-l border-gray-100">
            <li class="mb-6 md:mb-10 ml-6">
              <span class="absolute flex items-center justify-center w-10 h-10 bg-gray-50 p-2.5 rounded-full -left-5 ">
                <AiOutlineSchedule size={25} className="text-primaryColor" />
              </span>
              <div className="ml-2">
                <h1 className="text-sm md:text-base font-outfit text-gray-500">
                  Get Schedule First
                </h1>
                <p className="text-xs md:text-sm text-gray-400 font-outfit font-light">
                  Get your slot for a regular (daily) or master (Saturday) class
                </p>
              </div>
            </li>
            <li class="mb-8 md:mb-10 ml-6">
              <span class="absolute flex items-center justify-center w-10 h-10 bg-gray-50 p-2.5 rounded-full -left-5 ">
                <RiUserVoiceLine size={25} className="text-primaryColor" />
              </span>
              <div className="ml-2">
                <h1 className="text-sm md:text-base font-outfit text-gray-700">
                  Connect with Experts
                </h1>
                <p className=" text-xs md:text-sm text-gray-400 font-outfit font-light">
                  Meet experts, get answers for queries, and start earning
                </p>
              </div>
            </li>
            <li class="mb-0 ml-6">
              <span class="absolute flex items-center justify-center w-10 h-10 bg-gray-50 p-2.5 rounded-full -left-5 ">
                <BsLightningCharge size={25} className="text-primaryColor" />
              </span>
              <div className="ml-2">
                <h1 className="text-sm md:text-base font-outfit text-gray-700">
                  Boost your Earning
                </h1>
                <p className="text-xs md:text-sm text-gray-400 font-outfit font-light">
                  Learn tricks from experts to earn more and empower others.
                </p>
              </div>
            </li>
          </ol>

          {/* <div className="flex flex-col justify-start gap-6">
          <div className="flex items-center gap-x-2">
            <TiInfoOutline size={25} className="text-gray-500" />
            <div>
              <h1 className="text-base font-outfit text-gray-500">
                About The Workshop
              </h1>
              <p className="text-sm text-gray-400 font-outfit font-light">
                Lorem ipsum dolor, sit amet consectetur adipisicing elit.
              </p>
            </div>
          </div>
          <div className="flex items-center gap-x-2">
            <BsQuestionDiamond size={25} className="text-primaryColor" />
            <div>
              <h1 className="text-base font-outfit text-gray-700">
                Why to choose the Masterclass
              </h1>
              <p className="text-sm text-gray-400 font-outfit font-light">
                Lorem ipsum dolor, sit amet consectetur adipisicing elit.
              </p>
            </div>
          </div>
          <div className="flex items-center gap-x-2">
            <BsShare size={25} className="text-primaryColor" />
            <div>
              <h1 className="text-base font-outfit text-gray-700">
                Help others to learn
              </h1>
              <p className="text-sm text-gray-400 font-outfit font-light">
                Lorem ipsum dolor, sit amet consectetur adipisicing elit.
              </p>
            </div>
          </div>
        </div> */}
          <div className="flex justify-center">
            <img
              src={workshop}
              alt=""
              className="md:w-[70%] md:h-[70%] object-cover"
            />
          </div>
        </div>

        <div className="mt-8 md:-mt-10">
          <h1 className="text-base text-center text-gray-500 font-outfit font-medium">
            Book Your Schedule
          </h1>
          <p className="text-xs md:text-sm text-center text-gray-400 font-outfit font-light">
            Please click on the button to book your desired class
          </p>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-5 md:gap-10 mt-5">
            <div className="flex flex-col bg-[#D2FFFB57] p-4 rounded-md">
              <div className="flex flex-col justify-center md:flex-row items-center md:items-start gap-1 md:gap-4">
                <img
                  src={regularclass}
                  alt=""
                  className="w-20 h-20 object-cover"
                />
                <div className="flex flex-col items-center md:items-start">
                  <h1 className="text-sm md:text-base text-center md:text-left text-gray-500 font-outfit font-medium mb-1">
                    Why to Choose Master Class
                  </h1>
                  <p className="text-xs md:text-sm text-center md:text-left text-gray-400 font-outfit font-light">
                    Learn the hacks to increase your earning from affiliate
                    industry experts
                  </p>

                  <a
                    // onClick={() => setShow(true)}
                    href="https://forms.gle/2KsLzi5keb4LgAwJ9"
                    target="_blank"
                    className="w-fit px-4 py-1 rounded-full text-xs md:text-sm text-primaryColor border border-primaryColor mt-2"
                    rel="noreferrer"
                  >
                    Book Schedule
                  </a>
                </div>
              </div>
            </div>
            <div className="flex flex-col bg-[#FFFBD857] p-4 rounded-md">
              <div className="flex flex-col justify-center md:flex-row items-center md:items-start gap-1 md:gap-4">
                <img
                  src={masterclass}
                  alt=""
                  className="w-20 h-20 object-cover"
                />
                <div className="flex flex-col items-center md:items-start">
                  <h1 className="text-sm md:text-base text-center md:text-left text-gray-500 font-outfit font-medium mb-1">
                    Why to Choose Regular Class
                  </h1>
                  <p className="text-xs md:text-sm text-center md:text-left text-gray-400 font-outfit font-light">
                    Personalized meeting to get your queries answered & analyze
                    your potential.
                  </p>

                  <a
                    // onClick={() => setShow(true)}
                    href="https://forms.gle/NHvGoWiCt3zh6BzV9"
                    target="_blank"
                    className="w-fit px-4 py-1 rounded-full text-xs md:text-sm text-primaryColor border border-primaryColor mt-2"
                    rel="noreferrer"
                  >
                    Book Schedule
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OnlineWorkShopPage;
