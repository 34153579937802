import { apiRequest } from "../apiRequest/apiRequest";

export const dynamicFieldForBrandSlice = apiRequest.injectEndpoints({
  endpoints: (builder) => ({
    getDynamicField: builder.query({
      query: (brandDomain) => {
        return {
          url: `/brand-dynamic/dynamic-fields/${brandDomain}`,
          method: "GET",
          headers: {
            "Content-Type": "application/json;charset=UTF-8",
          },
        };
      },
      //   keepUnusedDataFor: 1000,
    }),
  }),
});

export const { useGetDynamicFieldQuery } = dynamicFieldForBrandSlice;
