import React, { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { IoCloseOutline } from "react-icons/io5";
import { TbUnlink } from "react-icons/tb";
import {
  FacebookIcon,
  FacebookMessengerIcon,
  FacebookMessengerShareButton,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from "react-share";
import ready_profit_linkt from "../../Assets/images/make-commission-link/ready_profit_linkt.gif";
import modernCopyToClipboardFunction from "../../utils/modernCopyClipBoard";

const ProfitLinkModal = ({ profitModal, setProfitModal, profitLink }) => {
  const [, setProcess] = useState(true);
  const handleCopy = () => {
    modernCopyToClipboardFunction(profitLink);
    // navigator.clipboard.writeText(profitLink);
    toast.success("Link Copied!!");
  };

  useEffect(() => {
    if (profitModal) {
      setProcess(true);
    }
  }, [profitModal]);
  return (
    <div
      className={`w-screen h-screen bg-[rgba(0,0,0,0.5)] ${
        profitModal ? "flex" : "hidden"
      } items-center justify-center fixed top-0 left-0 right-0 bottom-0 z-[10000]`}
    >
      <div className="w-[90%] md:w-[40%] h-[60vh] overflow-y-auto scrollbar-w-0 bg-white rounded-lg  px-2 md:px-6 py-2 relative flex flex-col items-center justify-center">
        <div className="flex flex-col justify-center items-center gap-y-2">
          <img src={ready_profit_linkt} alt="" className="w-28" />
          <h1 className="text-lg text-gray-500 font-outfit font-medium -mt-2">
            Your Affiliate Link is Ready
          </h1>
          <p className="text-sm md:text-base text-center text-gray-400 font-outfit font-light mb-1">
            Affiliate link ready! Share favorite brands & earn with Ayykori's
            Customized Link!
          </p>
          <div className="w-full flex justify-between items-center gap-x-4">
            <div className="w-full md:w-[90%] mx-auto flex items-center border rounded-full pl-4">
              <input
                className="w-full outline-none text-sm md:text-base font-light mr-1 text-gray-500 cursor-not-allowed"
                readOnly
                value={profitLink}
                type="text"
              />
              <button
                onClick={handleCopy}
                className="flex items-center text-sm md:text-base gap-x-1 bg-primaryColor text-white rounded-r-full px-2 md:px-4 py-1 md:py-1.5"
              >
                Copy
                <TbUnlink />
              </button>
            </div>
          </div>
          <div className="flex items-center gap-x-4 mt-2">
            <FacebookShareButton url={profitLink}>
              <FacebookIcon size={30} className="rounded-full" />
            </FacebookShareButton>

            <FacebookMessengerShareButton url={profitLink}>
              <FacebookMessengerIcon size={30} className="rounded-full" />
            </FacebookMessengerShareButton>

            <WhatsappShareButton url={profitLink}>
              <WhatsappIcon size={30} className="rounded-full" />
            </WhatsappShareButton>

            <LinkedinShareButton url={profitLink}>
              <LinkedinIcon size={30} className="rounded-full" />
            </LinkedinShareButton>
            <TwitterShareButton url={profitLink}>
              <TwitterIcon size={30} className="rounded-full" />
            </TwitterShareButton>
          </div>
        </div>

        <div
          onClick={() => setProfitModal(false)}
          className="absolute top-4 right-4 text-gray-500 cursor-pointer"
        >
          <IoCloseOutline size={25} />
        </div>
      </div>
    </div>
  );
};

export default ProfitLinkModal;
