import Cookies from "js-cookie";
import React, { useEffect, useState } from "react";
import confirmBalanceImg from "../../../Assets/images/my-balance/confirm.gif";
import pendingMoneyImg from "../../../Assets/images/my-balance/pendingBalance2.gif";
import totalMoneyImg from "../../../Assets/images/my-balance/totalBalance.gif";
import Error from "../../../components/Error/Error";
import NoDataFound from "../../../components/NodataFound/NoDataFound";
import BalanceTable from "../../../components/Table/BalanceTable";
import { useGetUserOrderTrackQuery } from "../../../features/balance/balanceApiSlice";
const CashBackBalancePage = () => {
    const access_token = Cookies?.get("ayykori");
    const [approxBalance, setApproxBalance] = useState(0);

    const {
        isLoading: isLoadingOrderTrack,
        isSuccess: isSuccessOrderTrack,
        isError: isErrorOrderTrack,
        data: dataOrderTrack,
    } = useGetUserOrderTrackQuery(
        {
            access_token,
            source: "cashback",
            page: 1,
            limit: 10000000000,
        },
        { refetchOnReconnect: true }
    ) || {};
    //== setting the approximate balance

    // pabel useEffect code
    // useEffect(() => {
    //     if (isSuccessOrderTrack && !isErrorOrderTrack) {
    //         let total = 0;
    //         let campain = 0;
    //         if (dataOrderTrack?.OrderActivity?.length !== 0) {
    //             dataOrderTrack?.OrderActivity?.forEach((item) => {
    //                 // console.log(item?.commission);
    //                 total = total + Number(item?.commission?.toFixed(2));
    //                 // console.log(total);
    //                 if (
    //                     item?.CampaingTkFromDaraz ||
    //                     item?.CampaingTkFromOthers
    //                 ) {
    //                     campain = campain + 1;
    //                 }
    //             });
    //         }

    //         if (campain > 0) {
    //             setApproxBalance(Math.floor(total + 50));
    //         } else {
    //             setApproxBalance(Math.floor(total));
    //         }
    //     }
    // }, [isSuccessOrderTrack, isErrorOrderTrack, dataOrderTrack?.orderTrack]);

    // rifat useEffect code
    useEffect(() => {
        if (isSuccessOrderTrack && !isErrorOrderTrack) {
            let total = 0;
            let campain = 0;
            if (dataOrderTrack?.OrderActivity?.length !== 0) {
                dataOrderTrack?.OrderActivity?.forEach((item) => {
                    // console.log(item?.commission);

                    if (item?.commission) {
                        total = total + Number(item?.commission?.toFixed(2));
                    }

                    if (
                        item?.CampaingTkFromDaraz ||
                        item?.CampaingTkFromOthers
                    ) {
                        campain = campain + 1;
                    }
                });
            }

            if (campain > 0) {
                setApproxBalance(Math.floor(total + 50));
            } else {
                setApproxBalance(Math.floor(total));
            }
        }
    }, [isSuccessOrderTrack, isErrorOrderTrack, dataOrderTrack?.orderTrack]);

    let content = null;
    // handle loading case
    if (isLoadingOrderTrack && !isSuccessOrderTrack) {
        content = (
            <>
                <tbody>
                    {Array(5)
                        ?.fill()
                        ?.map((item, index) => (
                            <tr
                                key={index}
                                className="border-t border-gray-300"
                            >
                                <td className="text-xs min-w-[250px] w-fit px-3 py-2 gap-x-0 text-gray-500 text-left">
                                    <div className="bg-gray-200 animate-pulse w-full h-5"></div>
                                </td>
                                <td className="text-xs w-fit px-3 py-2 gap-x-0 text-gray-500 text-left">
                                    <div className="bg-gray-200 animate-pulse w-full h-5"></div>
                                </td>
                                <td className="text-xs w-fit px-3 py-2 gap-x-0 text-gray-500 text-left">
                                    <div className="bg-gray-200 animate-pulse w-full h-5"></div>
                                </td>
                                <td className="text-xs w-fit px-3 py-2 gap-x-0 text-gray-500 text-left">
                                    <div className="bg-gray-200 animate-pulse w-full h-5"></div>
                                </td>
                                <td className="text-xs w-fit px-3 py-2 gap-x-0 text-gray-500 text-left">
                                    <div className="bg-gray-200 animate-pulse w-full h-5"></div>
                                </td>
                            </tr>
                        ))}
                </tbody>
            </>
        );
    }

    // handle success case
    if (!isLoadingOrderTrack && isSuccessOrderTrack && !isErrorOrderTrack) {
        content = (
            <>
                <BalanceTable source="cashback" data={dataOrderTrack} />
            </>
        );
    }
    // handle error case
    if (!isLoadingOrderTrack && isErrorOrderTrack) {
        content = (
            <>
                <tbody>
                    <tr className="border-t border-gray-300">
                        <td
                            colSpan={5}
                            className="text-xs min-w-[250px] w-fit px-3 py-2 gap-x-0 text-gray-500 text-left"
                        >
                            <Error />
                        </td>
                    </tr>
                </tbody>
            </>
        );
    }

    // handle empty case
    if (
        !isLoadingOrderTrack &&
        isSuccessOrderTrack &&
        dataOrderTrack?.OrderActivity?.length === 0
    ) {
        content = (
            <tbody>
                <tr className="border-t border-gray-300">
                    <td
                        colSpan={5}
                        className="text-xs min-w-[250px] w-fit px-3 py-2 gap-x-0 text-gray-500 text-left"
                    >
                        <NoDataFound />
                    </td>
                </tr>
            </tbody>
        );
    }

    // after navigate this page show in top
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <div className="py-2 md:p-8">
            <div className="w-full flex flex-col">
                <h1 className="text-base md:text-lg text-gray-500 font-outfit font-medium">
                    Cashback Balance
                </h1>
                <p className="text-xs md:text-base text-gray-400 font-outfit font-light">
                    Track your Cashback amount here to stay updated
                </p>

                {/* <div className="flex items-center gap-x-4">
          <h1 className="text-xl font-outfit text-gray-500">BDT 0.00</h1>
          <button className="bg-primaryColor text-white font-outfit px-2 py-1 rounded-full">
            Withdraw
          </button>
        </div> */}
            </div>
            <div className="hidden md:grid grid-cols-3 gap-4 my-7">
                <div className="rounded-lg h-32 bg-white flex flex-col items-center justify-center text-center gap-2 p-2 border shadow-md ">
                    <div>
                        <img src={totalMoneyImg} alt="" />
                    </div>
                    <div>
                        <h1 className="text-xs md:text-sm font-outfit text-gray-400">
                            Total Cashback Balance
                        </h1>
                        <h1 className="text-lg font-medium font-outfit text-gray-600">
                            0 <span>&#2547;</span>
                        </h1>
                    </div>
                </div>
                <div className="rounded-lg h-32 bg-white flex flex-col items-center justify-center text-center gap-2 p-2  border-b-4 border border-green-300 shadow-md ">
                    <div>
                        <img src={confirmBalanceImg} alt="" />
                    </div>
                    <div>
                        <h1 className="text-xs md:text-sm font-outfit text-gray-400">
                            Withdrawable Balance
                        </h1>
                        <h1 className="text-lg font-medium font-outfit text-gray-600">
                            0 <span>&#2547;</span>
                        </h1>
                    </div>
                </div>
                <div className="rounded-lg h-32 bg-white flex flex-col items-center justify-center text-center gap-2 p-2 border shadow-md">
                    <div>
                        <img className="" src={pendingMoneyImg} alt="" />
                    </div>
                    <div>
                        <h1 className="text-xs md:text-sm font-outfit text-gray-400">
                            Approx. Cashback Balance
                        </h1>
                        <h1 className="text-lg font-medium font-outfit text-gray-600">
                            {approxBalance || 0} <span>&#2547;</span>
                        </h1>
                    </div>
                </div>
            </div>
            <div className="grid md:hidden grid-cols-1 gap-3 my-7">
                <div className="rounded-lg h-32 bg-white flex flex-col items-center justify-center text-center gap-2 p-2  border-b-4 border border-green-300 shadow-md ">
                    <div>
                        <img src={confirmBalanceImg} alt="" />
                    </div>
                    <div>
                        <h1 className="text-xs md:text-sm font-outfit text-gray-400">
                            Withdrawable Balance
                        </h1>
                        <h1 className="text-sm font-medium font-outfit text-gray-600">
                            0 <span>&#2547;</span>
                        </h1>
                    </div>
                </div>
                <div className="grid grid-cols-2 gap-3">
                    <div className="rounded-lg h-32 bg-white flex flex-col items-center justify-center text-center gap-2 p-2 border shadow-md ">
                        <div>
                            <img src={totalMoneyImg} alt="" />
                        </div>
                        <div>
                            <h1 className="text-xs md:text-sm font-outfit text-gray-400">
                                Total Cashback Balance
                            </h1>
                            <h1 className="text-sm font-medium font-outfit text-gray-600">
                                0 <span>&#2547;</span>
                            </h1>
                        </div>
                    </div>
                    <div className="rounded-lg h-32 bg-white flex flex-col items-center justify-center text-center gap-2 p-2 border shadow-md">
                        <div>
                            <img className="" src={pendingMoneyImg} alt="" />
                        </div>
                        <div>
                            <h1 className="text-xs md:text-sm font-outfit text-gray-400">
                                Approx. Cashback Balance
                            </h1>
                            <h1 className="text-sm font-medium font-outfit text-gray-600">
                                {approxBalance || 0} <span>&#2547;</span>
                            </h1>
                        </div>
                    </div>
                </div>
            </div>
            {/* <div className=" flex items-center justify-between ">
        <div></div>
        <div className=" self-end ">
          <MonthPicker />
        </div>
      </div> */}

            <div className="mt-4">
                <div
                    className="w-full max-h-80 overflow-auto scroll-smooth 
    scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-white 
    scrollbar-thumb-rounded-full scrollbar-track-rounded-full scrollbar-w-1"
                >
                    <table
                        className=" whitespace-nowrap w-full h-full p-[4rem]
border-collapse rounded transition duration-700 shadow-lg"
                    >
                        <thead>
                            <tr className="bg-gradient-to-b from-gray-100 to-transparent">
                                <th className="text-xs py-2 px-3 gap-x-0 text-gray-400 font-medium text-left">
                                    Date of Purchase
                                </th>
                                <th className="text-xs  py-2 px-3 gap-x-0 text-gray-400 font-medium text-left">
                                    Brand Name
                                </th>
                                <th className="text-xs  py-2 px-3 gap-x-0 text-gray-400 font-medium text-left">
                                    Status
                                </th>
                                <th className="text-xs  py-2 px-3 gap-x-0 text-gray-400 font-medium text-left">
                                    Cashback Amount
                                </th>
                                <th className="text-xs py-2 px-3 gap-x-0 text-gray-400 font-medium text-left">
                                    Product Details
                                </th>
                            </tr>
                        </thead>
                        {content}
                    </table>
                </div>
            </div>
        </div>
    );
};

export default CashBackBalancePage;
