import { imageCreateApiSlice } from "../apiRequest/imageCreateApiSlice";

export const manualOrderImageApislice = imageCreateApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    createImage: builder.mutation({
      query: (data) => {
        const { brandDomain } = data || {};
        const formData = new FormData();
        if (data?.selectedFile) {
          formData.append("image", data?.selectedFile);
        }
        return {
          url: `/api/v1/manual/origin/${brandDomain}`,
          method: "POST",
          preparedHeaders: (headers) => {
            headers.set("Content-type", "multipart/form-data");
            return headers;
          },
          body: formData,
          formData: true,
          headers: {
            // Authorization: `Bearer ${access_token}`,
            // "Content-Type": "multipart/form-data",
            // "x-api-key": "BB04B1FC-7B23-4395-B871-069CFBE495D3",
          },
        };
      },
      providesTags: ["Order"],
    }),
  }),
});

export const { useCreateImageMutation } = manualOrderImageApislice;
