import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  access_token: undefined,
  user: undefined,
};

export const authUserSlice = createSlice({
  name: "authUserSlice",
  initialState,
  reducers: {
    userLoggedIn: (state, action) => {
      state.access_token = action.payload.access_token;
      state.user = action.payload.user;
    },
    userLoggedOut: (state) => {
      state.access_token = undefined;
      state.user = undefined;
    },
  },
});

export const { userLoggedIn, userLoggedOut } = authUserSlice.actions;

export default authUserSlice.reducer;
