import React, { useState } from "react";
import { MdArrowForwardIos } from "react-icons/md";
import { Autoplay } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import { Swiper, SwiperSlide } from "swiper/react";
import { ambassadorImg } from "../../Data/activationData";
import BlogVideoCard from "../Card/BlogVideoCard";
import CommonComponent from "../CommonComponent/CommonComponent";

const AmbassadorSliderImg = ({ newData }) => {
  const { title, dataLimit, source } = newData || {};
  const [swiperRef, setSwiperRef] = useState(null);

  const prevHandler = () => {
    swiperRef.slidePrev();
  };

  const nextHandler = () => {
    swiperRef.slideNext();
  };

  return (
    <div className="my-10 md:my-16">
      <CommonComponent
        data={{
          title: title,
          // link: "/brands/trending",
          // linktitle: "See All",
          // show: true,
        }}
      />

      <div className="  w-full px-3 md:px-[55px] mx-auto relative group transition-all duration-1000">
        <div
          onClick={prevHandler}
          className="hidden  cursor-pointer group-hover:block  absolute  left-[-100%] group-hover:left-[2.5%] top-[50%] translate-y-[-50%] z-[100] rounded-full p-3 bg-gray-50 shadow-lg shadow-gray-400"
        >
          <MdArrowForwardIos
            size={20}
            className="rotate-180 cursor-pointer text-primaryColor"
          />
        </div>

        <Swiper
          slidesPerView={1.2}
          spaceBetween={8}
          loop={true}
          autoplay={{ delay: 5000 }}
          modules={[Autoplay]}
          onSwiper={(swiper) => setSwiperRef(swiper)}
          className="mySwiper p-2"
          breakpoints={{
            // when window width is >= 320px
            320: {
              slidesPerView: 1.2,
              spaceBetween: 8,
            },
            // when window width is >= 480px
            480: {
              slidesPerView: 1.2,
              spaceBetween: 8,
            },
            // when window width is >= 640px
            640: {
              slidesPerView: 3,
              spaceBetween: 8,
            },
            // when window width is >= 640px
            920: {
              slidesPerView: 4,
              spaceBetween: 10,
            },
            // when window width is >= 920
            1200: {
              slidesPerView: 4,
              spaceBetween: 10,
            },
          }}
        >
          {ambassadorImg &&
            ambassadorImg?.map((item, i) => (
              <SwiperSlide key={i}>
                <BlogVideoCard
                  data={item}
                  styleData={{ width: "100%", height: "164px " }}
                  key={i}
                />
              </SwiperSlide>
            ))}
        </Swiper>
        <div
          onClick={nextHandler}
          className="hidden cursor-pointer  group-hover:block absolute right-[-100%] group-hover:right-[2.5%] top-[50%] translate-y-[-50%] z-[100] rounded-full p-3 bg-gray-50 shadow-lg shadow-gray-400"
        >
          <MdArrowForwardIos size={20} className=" text-primaryColor" />
        </div>
      </div>
    </div>
  );
};

export default AmbassadorSliderImg;
