import moment from "moment";
import React from "react";
import avatar from "../../Assets/images/placeholder-image/avatar.png";

const AllReferralTable = ({ dataRefer }) => {
  return (
    <tbody>
      {dataRefer?.refferalUser &&
        dataRefer?.refferalUser?.map((user, index) => {
          const userDetails = user?.usedUserDetails[0];
          if (!userDetails) {
            return null; // Skip rendering if usedUserDetails is empty
          }
          return (
            <tr key={index} className="border-t border-gray-300">
              <td className="text-xs min-w-[250px] w-fit px-3 py-2 gap-x-0 text-gray-500 text-left">
                <div className=" flex items-center gap-x-2">
                  <img
                    src={userDetails?.photo?.url || avatar}
                    className="w-8 h-8 rounded-full object-contain"
                    alt=""
                  />
                  <div className=" flex flex-col gap-2 ">
                    <div className="">
                      <p className=" text-gray-500 font-medium flex items-center gap-1">
                        {userDetails?.fullname}
                      </p>
                      <p className=" text-gray-500 font-medium flex items-center gap-1">
                        {userDetails?.email}
                      </p>
                    </div>
                  </div>
                </div>
              </td>
              <td className="text-xs min-w-[250px] w-fit px-3 py-2 gap-x-0 text-gray-500 text-left">
                <div className=" flex flex-col gap-2 ">
                  <div className="">
                    <p className=" text-gray-500 font-medium flex items-center gap-1">
                      {userDetails?.phone}
                    </p>
                  </div>
                </div>
              </td>
              <td className="text-xs w-fit px-3 py-2 gap-x-0 text-gray-500 text-left">
                {moment(user?.createdAt).format("LL")}
              </td>
              <td className="text-xs w-fit px-3 py-2 gap-x-0 text-gray-500 text-left">
                {moment(user?.createdAt).format("LT")}
              </td>
            </tr>
          );
        })}
    </tbody>
  );
};

export default AllReferralTable;
