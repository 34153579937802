import React, { useEffect, useState } from "react";
import "./counter.css";

const Counter = ({ totalTime, onTimeEnd }) => {
  const [timeRemaining, setTimeRemaining] = useState(
    Math.floor(totalTime / 1000)
  );

  useEffect(() => {
    if (timeRemaining > 0) {
      const countdownInterval = setInterval(() => {
        setTimeRemaining((prevTime) => {
          if (prevTime <= 1) {
            clearInterval(countdownInterval);
            onTimeEnd();
          }
          return prevTime - 1;
        });
      }, 1000);

      return () => clearInterval(countdownInterval);
    }
  }, [timeRemaining, onTimeEnd]);

  const calculateProgress = () => {
    const progress =
      ((Math.floor(totalTime / 1000) - timeRemaining) /
        Math.floor(totalTime / 1000)) *
      100;
    return progress;
  };

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${minutes}:${seconds.toString().padStart(2, "0")}`;
  };

  return (
    <div className=" ">
      <div className="countdown-container">
        <div
          className="countdown"
          style={{
            clipPath: `polygon(0 0, 100% 0, 100% ${calculateProgress()}%, 0 ${calculateProgress()}%)`,
          }}
        ></div>
        <div className="countdown-text">{formatTime(timeRemaining)}</div>
      </div>
    </div>
  );
};

export default Counter;
