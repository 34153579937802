import Cookies from "js-cookie";
import React, { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import { useDispatch } from "react-redux";
import { useLocation, useParams, useSearchParams } from "react-router-dom";
import {
  useGetAllBrandsAndSubBrandsQuery,
  useGetAllMarketplaceByCategoryQuery,
} from "../../features/brand/brandApiSlice";
import { changeBrandCount } from "../../features/brand/brandSlice";
import BrandCard from "../Card/BrandCard";
import Error from "../Error/Error";
import NoDataFound from "../NodataFound/NoDataFound";
import BrandCardSkeleton from "../Skeleton/BrandCardSkeleton";

const CategoriesPageRightPanel = ({ limit, cashback }) => {
  const { pathname } = useLocation();
  const [page, setPage] = useState(1);
  const access_token = Cookies.get("ayykori");
  let { categoryname } = useParams();
  categoryname = categoryname.replace(/-/g, " ");
  const [query] = useSearchParams();
  const subCategory = query.get("subCategory");
  const dispatch = useDispatch();
  const { data, isLoading, isSuccess, isError } =
    useGetAllBrandsAndSubBrandsQuery(
      {
        access_token,
        categoryname: categoryname,
        subCategory,
        page: page,
        limit: limit,
        cashback,
      },
      { refetchOnReconnect: true }
    );
  const {
    data: dataMarketPlace,
    isLoading: isLoadingMarketPlace,
    isSuccess: isSuccessMarketPlace,
    isError: isErrorMarketPlace,
  } = useGetAllMarketplaceByCategoryQuery(
    {
      access_token,
      categoryname,
      page: 1,
      limit: 100,
    },
    { refetchOnReconnect: true }
  ) || {};

  useEffect(() => {
    if (isSuccess) {
      dispatch(changeBrandCount(data?.totalData[0]?.count));
    }
  }, [isSuccess, data?.totalData, dispatch]);

  let content1 = null;
  let content2 = null;
  // handle loading case
  if (isLoading && !isSuccess) {
    content1 = (
      <>
        <div className="basis-full md:basis-[75%] rounded-lg grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
          {Array(8)
            .fill()
            ?.map((item, i) => (
              <BrandCardSkeleton key={i} />
            ))}
        </div>
      </>
    );
  }
  // handle success case
  if (!isLoading && !isError && isSuccess) {
    content1 = (
      <>
        <div className="basis-full md:basis-[75%] rounded-lg grid grid-cols-2 sm:grid-cols-2  md:grid-cols-3 gap-y-3 md:gap-y-6 gap-x-3 md:gap-x-8">
          {data?.resData &&
            data?.resData?.map((item, index) => (
              <BrandCard
                key={index}
                data={{
                  id: item?._id,
                  brandUnid: item?.brandUnid,
                  name: item?.subBrandName
                    ? item?.subBrandName
                    : item?.brandName,
                  brandCardimg: item?.subBrandimg
                    ? item?.subBrandimg
                    : item?.brandimg,
                  brandType: item?.brandType,
                  userCommission:
                    pathname === "/affiliate"
                      ? item?.earningUserCommission
                      : item?.cashbackUserCommission,
                  brandHomepageLink: item?.subBrandLink
                    ? item?.subBrandLink
                    : item?.brandHomepageLink,
                  newPercent: 4,
                  borderShow: true,
                  subBrand: item?.subBrandName ? "Yes" : "No",
                  type: pathname === "/affiliate" ? "earning" : "cashback",
                }}
              />
            ))}
        </div>
      </>
    );
  }
  // handle error case
  if (!isLoading && isError) {
    content1 = (
      <>
        <Error />
      </>
    );
  }
  // handle empty case
  if (!isLoading && isSuccess && data?.resData?.length === 0) {
    content1 = (
      <>
        <NoDataFound />
      </>
    );
  }

  // handle loading case
  if (isLoadingMarketPlace && !isSuccessMarketPlace) {
    content2 = (
      <>
        <div className="basis-full md:basis-[75%] rounded-lg grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
          {Array(8)
            .fill()
            ?.map((item, i) => (
              <BrandCardSkeleton key={i} />
            ))}
        </div>
      </>
    );
  }
  // handle success case
  if (!isLoadingMarketPlace && isSuccessMarketPlace && !isErrorMarketPlace) {
    content2 = (
      <>
        <div className="basis-full md:basis-[75%] rounded-lg grid grid-cols-2 sm:grid-cols-2 lg:grid-cols-3  md:gap-y-6 gap-x-3 md:gap-x-8">
          {dataMarketPlace?.marketPlace &&
            dataMarketPlace?.marketPlace?.map((item, index) => (
              <BrandCard
                key={index}
                data={{
                  id: item?.brandId,
                  name: item?.subBrandName
                    ? item?.subBrandName
                    : item?.brandName,
                  brandCardimg: item?.subBrandimg
                    ? item?.subBrandimg
                    : item?.brandImg,
                  brandType: item?.brandType,
                  userCommission:
                    pathname === "/affiliate"
                      ? item?.earningUserCommission
                      : item?.cashbackUserCommission,
                  brandHomepageLink: item?.brandHomepageLink,
                  newPercent: 4,
                  borderShow: true,
                  subBrand: item?.subBrandName ? "Yes" : "No",
                  type: pathname === "/affiliate" ? "earning" : "cashback",
                }}
              />
            ))}
        </div>
      </>
    );
  }
  // handle error case
  if (!isLoadingMarketPlace && isErrorMarketPlace) {
    content2 = (
      <>
        <Error />
      </>
    );
  }
  // handle empty case
  if (
    !isLoadingMarketPlace &&
    isSuccessMarketPlace &&
    dataMarketPlace?.marketPlac?.length === 0
  ) {
    content2 = (
      <>
        <NoDataFound />
      </>
    );
  }

  return (
    <div
      className="w-full h-[700px] overflow-y-auto scroll-smooth 
    scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-white 
    scrollbar-thumb-rounded-full scrollbar-track-rounded-full scrollbar-w-0"
    >
      {dataMarketPlace?.marketPlace?.length !== 0 && (
        <div className="mb-4">
          <h1 className="text-sm sm:text-lg md:text-2xl  font-outfit text-gray-600 font-medium border-b mb-4">
            Marketplace
          </h1>
          {content2}
        </div>
      )}
      <div>
        <h1 className=" text-sm sm:text-lg md:text-2xl  font-outfit text-gray-600 font-medium border-b mb-4 p-1">
          Individual Brands
        </h1>
        {content1}
      </div>
      {/* load more function */}
      {data?.totalData[0]?.count > limit && (
        <div className="w-full px-3 md:px-[55px] flex justify-center md:justify-end items-center my-8">
          <ReactPaginate
            breakLabel="..."
            previousLabel="Prev"
            className="text-primaryColor flex items-center gap-2 text-sm"
            previousClassName="text-primaryColor text-sm"
            nextClassName="text-primaryColor text-sm"
            activeClassName="bg-primaryColor text-sm"
            activeLinkClassName="text-white px-2"
            nextLabel="Next"
            onPageChange={(e) => setPage(e.selected + 1)}
            pageRangeDisplayed={2}
            marginPagesDisplayed={2}
            pageCount={Math.ceil(data?.totalData[0]?.count / limit)}
            renderOnZeroPageCount={null}
          />
        </div>
      )}
    </div>
  );
};

export default CategoriesPageRightPanel;
