import React, { useState } from "react";
import { MdArrowForwardIos } from "react-icons/md";
import AppOverViewCard from "../Card/AppOverViewCard";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import { Autoplay } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import app_website_1 from "../../Assets/images/app-screenshots/website/1.jpg";
import app_website_2 from "../../Assets/images/app-screenshots/website/2.jpg";
import app_website_3 from "../../Assets/images/app-screenshots/website/3.jpg";
import app_website_4 from "../../Assets/images/app-screenshots/website/4.jpg";
import app_website_5 from "../../Assets/images/app-screenshots/website/5.jpg";
import app_website_6 from "../../Assets/images/app-screenshots/website/6.jpg";
import app_mobile_1 from "../../Assets/images/app-screenshots/mobile/1.jpg";
import app_mobile_2 from "../../Assets/images/app-screenshots/mobile/2.jpg";
import app_mobile_3 from "../../Assets/images/app-screenshots/mobile/3.jpg";
import app_mobile_4 from "../../Assets/images/app-screenshots/mobile/4.jpg";
import app_mobile_5 from "../../Assets/images/app-screenshots/mobile/5.jpg";
import app_mobile_6 from "../../Assets/images/app-screenshots/mobile/6.jpg";

const appMobileImageData = [
  app_mobile_1,
  app_mobile_2,
  app_mobile_3,
  app_mobile_4,
  app_mobile_5,
  app_mobile_6,
];
const appWebsiteImageData = [
  app_website_1,
  app_website_2,
  app_website_3,
  app_website_4,
  app_website_5,
  app_website_6,
];
const AppPageSlider = () => {
  const [swiperRef, setSwiperRef] = useState(null);
  const prevHandler = () => {
    swiperRef.slidePrev();
  };
  const nextHandler = () => {
    swiperRef.slideNext();
  };
  return (

    <div className=" w-full px-3 md:px-[55px] ">
      <h1 className=" text-gray-800 text-2xl font-medium">
        AyyKori App Overview
      </h1>
      <div className="relative">
        <div className="absolute -left-2 top-[50%] translate-y-[-50%] z-[100] bg-white rounded-full p-2 text-primaryColor">
          <MdArrowForwardIos
            size={20}
            className="rotate-180 cursor-pointer"
            onClick={prevHandler}
          />
        </div>
        <Swiper
          slidesPerView={1}
          spaceBetween={10}
          loop={true}
          autoplay={{ delay: 5000 }}
          modules={[Autoplay]}
          onSwiper={(swiper) => setSwiperRef(swiper)}
          className="mySwiper p-2"
          breakpoints={{
            // when window width is >= 320px
            320: {
              slidesPerView: 1,
            },
            // when window width is >= 480px
            480: {
              slidesPerView: 2,
            },
            // when window width is >= 640px
            640: {
              slidesPerView: 3,
            },
            // when window width is >= 640px
            920: {
              slidesPerView: 4,
            },
            // when window width is >= 920
            1200: {
              slidesPerView: 5,
            },
          }}
        >
          <div className="hidden md:block">
            {appWebsiteImageData &&
              appWebsiteImageData.map((item, i) => (
                <SwiperSlide key={i}>
                  <AppOverViewCard data={item} />
                </SwiperSlide>
              ))}
          </div>
          <div className="block md:hidden">
            {appMobileImageData &&
              appMobileImageData.map((item, i) => (
                <SwiperSlide key={i}>
                  <AppOverViewCard data={item} />
                </SwiperSlide>
              ))}
          </div>
        </Swiper>
        <div className="absolute -right-2 top-[50%] translate-y-[-50%] z-[100] bg-white rounded-full p-2 text-primaryColor">
          <MdArrowForwardIos
            size={20}
            className="cursor-pointer"
            onClick={nextHandler}
          />
        </div>
      </div>
    </div>

  );
};

export default AppPageSlider;
