import React from "react";
import { RxCross1 } from "react-icons/rx";

const ProductsListModal = ({ setShow, products }) => {
  return (
    <div
      className={`w-screen h-screen bg-[rgba(0,0,0,0.5)] flex items-center justify-center fixed top-0 left-0 right-0 bottom-0 z-[10000] `}
    >
      <div className=" w-[90%] md:w-[80%] lg:w-[55%] h-[40vh] md:h-[70vh] overflow-y-auto scrollbar-w-0 bg-white rounded-lg flex justify-center items-start p-1 md:p-6 relative">
        <div
          className="w-full max-h-[90%] mt-10 overflow-auto scroll-smooth 
    scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-white 
    scrollbar-thumb-rounded-full scrollbar-track-rounded-full scrollbar-w-1 scrollbar-h-1"
        >
          <table
            className=" whitespace-nowrap w-full h-full p-[4rem]
border-collapse rounded transition duration-700 shadow-lg"
          >
            <thead>
              <tr className="bg-gradient-to-b from-gray-100 to-transparent">
                <th className="text-xs py-2 px-3 gap-x-0 text-gray-400 font-medium text-left">
                  Product Id
                </th>
                <th className="text-xs  py-2 px-3 gap-x-0 text-gray-400 font-medium text-left">
                  Product Name
                </th>
                <th className="text-xs  py-2 px-3 gap-x-0 text-gray-400 font-medium text-left">
                  Category
                </th>
                <th className="text-xs  py-2 px-3 gap-x-0 text-gray-400 font-medium text-left">
                  Offer Price
                </th>
                <th className="text-xs  py-2 px-3 gap-x-0 text-gray-400 font-medium text-left">
                  Regular Price
                </th>
                <th className="text-xs  py-2 px-3 gap-x-0 text-gray-400 font-medium text-left">
                  Quantity
                </th>
              </tr>
            </thead>
            <tbody>
              {products &&
                products?.length !== 0 &&
                products?.map((item, index) => (
                  <tr key={index} className="border-t border-gray-300">
                    <td className="text-xs w-fit px-3 py-2 gap-x-0 text-gray-500 text-left">
                      {item?.product_id || "N/A"}
                    </td>
                    <td className="text-xs w-fit px-3 py-2 gap-x-0 text-gray-500 text-left">
                      {item?.product_name || "N/A"}
                    </td>
                    <td className="text-xs w-fit px-3 py-2 gap-x-0 text-gray-500 text-left">
                      {item?.category || "N/A"}
                    </td>
                    {item?.offer_price ? (
                      <td className="text-xs w-fit px-3 py-2 gap-x-0 text-gray-500 text-left">
                        {item?.offer_price} &#2547;
                      </td>
                    ) : (
                      <td className="text-xs w-fit px-3 py-2 gap-x-0 text-gray-500 text-left">
                        {"N/A"}
                      </td>
                    )}
                    {item?.regular_price ? (
                      <td className="text-xs w-fit px-3 py-2 gap-x-0 text-gray-500 text-left">
                        {item?.regular_price} &#2547;
                      </td>
                    ) : (
                      <td className="text-xs w-fit px-3 py-2 gap-x-0 text-gray-500 text-left">
                        {"N/A"}
                      </td>
                    )}
                    <td className="text-xs w-fit px-3 py-2 gap-x-0 text-gray-500 text-left">
                      {item?.quantity || "N/A"}
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
        <span
          onClick={() => setShow(false)}
          className="absolute top-2 md:top-4 right-2 md:right-4 cursor-pointer rounded-full p-1"
        >
          <RxCross1 size={20} className="text-gray-500" />
        </span>
      </div>
    </div>
  );
};

export default ProductsListModal;
