import moment from "moment";
import React from "react";
import { AiOutlineCalendar } from "react-icons/ai";
import { MdOutlineWatchLater } from "react-icons/md";
import ReactPlayer from "react-player/lazy";
import youtubeLogo from "../../Assets/images/icon/icons8-youtube-94.png";
import { useUpdateBlogViewCountMutation } from "../../features/blog/blogApiSlice";
const BlogVideoCard = ({ data, styleData }) => {
    const [updateBlogViewCount] = useUpdateBlogViewCountMutation() || {};
    return (
        <div className="blog_video__Class  flex flex-col gap-2  ">
            <ReactPlayer
                width={styleData?.width}
                height={styleData?.height}
                playing={true}
                light={true}
                stopOnUnmount={true}
                controls={true}
                playIcon={<img src={youtubeLogo} alt="" className="w-12   " />}
                url={data?.blogLink}
            />
            <a
                onClick={() => {
                    updateBlogViewCount({ id: data?._id });
                }}
                href={data?.blogLink}
                target="_blank"
                rel="noreferrer"
            >
                <h1 className="text-base font-medium font-outfit text-gray-700  hover:text-primaryColor">
                    {data?.blogTitle}
                </h1>
            </a>
            {/* {data?.createdAt && (
                <div className="flex items-center gap-x-3 text-gray-400">
                    <p className=" flex text-xs items-center gap-1 ">
                        <span className="text-xl">
                            <AiOutlineCalendar />
                        </span>
                        {moment(data?.createdAt).format("ll")}
                    </p>
                    <p className=" flex text-xs items-center gap-1 ">
                        <span className="text-xl">
                            <MdOutlineWatchLater />
                        </span>
                        {moment(data?.createdAt).format("LT")}
                    </p>
                </div>
            )} */}
        </div>
    );
};

export default BlogVideoCard;
