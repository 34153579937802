import React from "react";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";
import { affiliateAmbassadorData } from "../../Data/AffiliateAndAmbassadorData";
import AmbassadorSliderImg from "../../components/Ambassador/AmbassadorSliderImg";
import ApplyButton from "../../components/Button/ApplyButton";
import CampaignCard from "../../components/Campaign/CampaignCard";
import CommonDescriptions from "../../components/Descriptions/CommonDescriptions";
import YoutubeIframe from "../../components/Videos/YoutubeIframe";
import CommonLayout from "../../layout/CommonLayout";

import "swiper/css";
import "swiper/css/pagination";
import PartnerSlider from "../../components/Swiper/PartnerSlider";
const ActivationPage = () => {
  const { title } = useParams();

  const dataObject = affiliateAmbassadorData.find(
    (item) => item.titleSlug === title
  );

  return (
    <>
      <Helmet>
        <title>
          {dataObject
            ? dataObject?.title
            : "Bangladesh's First Cashback & Affiliate Marketplace | Join Free & Save Money"}
        </title>
        <link
          rel="icon"
          type="image/x-icon"
          href={"../../../public/logo.png"}
        />
      </Helmet>
      <CommonLayout>
        <>
          <div className="px-3 md:mx-14 md:mb-16">
            {dataObject && (
              <CampaignCard
                campaignVideo={dataObject?.banner}
                link={dataObject?.affiliateApplyLink}
              />
            )}
          </div>

          <div className="my-10 md:my-12">
            <div className=" w-full px-3 md:px-[70px] my-5 grid md:grid-cols-5 grid-cols-1 gap-y-5 md:gap-y-0 md:gap-5  ">
              <div className="md:col-span-3 ">
                <CommonDescriptions data={dataObject?.about[0]} />
              </div>

              <div className="w-full  col-span-2 ">
                <div className={"hidden md:block"}>
                  <YoutubeIframe
                    data={dataObject.youtubeVideo}
                    styleData={{ width: "100%", height: "300px" }}
                  />
                </div>
                <div className={"md:hidden block"}>
                  <YoutubeIframe
                    data={dataObject.youtubeVideo}
                    styleData={{ width: "100%", height: "200px" }}
                  />
                </div>

                {/* <iframe
                  className="md:block hidden rounded-lg"
                  width="100%"
                  height="100%"
                  src="https://www.youtube.com/embed/w7bUtERXK60?si=ijzLsOrrJusJepds"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen
                ></iframe> */}

                {/* <iframe
                  className="block md:hidden rounded-lg"
                  width="100%"
                  height="200px"
                  src="https://www.youtube.com/embed/w7bUtERXK60?si=ijzLsOrrJusJepds"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen
                ></iframe> */}

                {/* <ReactPlayer
            width={"475px"}
            height={"300px"}
            playing={true}
            light={true}
            stopOnUnmount={true}
            controls={true}
            playIcon={<img src={youtubeLogo} alt="" className="w-16   " />}
            url={item?.blogLink}
          /> */}
              </div>
            </div>
          </div>

          <PartnerSlider data={dataObject.partnerShip} />

          <div className="md:my-16 my-5 flex items-center justify-center  ">
            <ApplyButton link={dataObject?.affiliateApplyLink}>
              Apply Now
            </ApplyButton>
          </div>

          <div className="px-3 md:mx-14 md:mb-16 mb-5">
            <CommonDescriptions data={dataObject.description} />
          </div>
          {dataObject?.testimonials && (
            <div className=" flex items-center justify-center my-6 ">
              <ApplyButton link={dataObject?.downloadCarriculam}>
                Download Curriculum
              </ApplyButton>
            </div>
          )}

          {dataObject?.testimonials && (
            <AmbassadorSliderImg
              newData={{
                title: "Top Affiliate Marketers Video ",
                dataLimit: 20,
              }}
            />
          )}

          {dataObject?.testimonials && (
            <div className=" flex items-center justify-center my-6 ">
              <ApplyButton link={dataObject?.affiliateApplyLink}>
                Apply Now
              </ApplyButton>
            </div>
          )}
        </>
      </CommonLayout>
    </>
  );
};

export default ActivationPage;
