import { AnimatePresence, motion } from "framer-motion";
import React, { useEffect, useState } from "react";
import successfull from "../../Assets/images/icon/check.gif";
import processing from "../../Assets/images/icon/loading.gif";
import modernCopyToClipboardFunction from "../../utils/modernCopyClipBoard";

const CopyLinkModal = ({
  copyModal,
  setCopyModal,
  proftlink,
  isLoading,
  isError,
}) => {
  const [process, setProcess] = useState(true);
  const backdrop = {
    visible: { opacity: 1 },
    hidden: { opacity: 0 },
  };

  const modal = {
    hidden: { y: "-100vh", opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
      transition: { delay: 0.5 },
    },
  };
  useEffect(() => {
    if (!isLoading && proftlink) {
      modernCopyToClipboardFunction(proftlink);
      // navigator?.clipboard?.writeText(proftlink);

      setProcess(false);
      setTimeout(() => {
        setCopyModal(false);
      }, 5000);
    } else {
      setProcess(true);
    }
  }, [isLoading, proftlink, setCopyModal]);
  useEffect(() => {
    if (isError) {
      setCopyModal(false);
    }
  }, [isError, setCopyModal]);

  return (
    <AnimatePresence>
      {copyModal && (
        <motion.div
          variants={backdrop}
          initial="hidden"
          animate="visible"
          exit="hidden"
          className={`w-screen h-screen bg-[rgba(0,0,0,0.5)] flex items-center justify-center fixed top-0 left-0 right-0 bottom-0 z-[10000]`}
        >
          <motion.div
            variants={modal}
            className="w-[60%] md:w-[30%] md:h-40 overflow-y-auto scrollbar-w-0 bg-white rounded-md  p-4 md:p-6 relative flex flex-col items-center justify-center"
          >
            {process && (
              <img className="w-10 object-cover" src={processing} alt="" />
            )}
            {!process && (
              <img className="w-10  object-cover" src={successfull} alt="" />
            )}
            {isError && (
              <img className="w-10  object-cover" src={successfull} alt="" />
            )}
            <h1 className="text-center mt-2 font-outfit text-lg  text-primaryColor font-medium">
              {process && "Please Wait!!"}
              {!process && "Link Copied!!"}
              {isError && "Link Not Copied!!"}
            </h1>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default CopyLinkModal;
