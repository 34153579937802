import Cookies from "js-cookie";
import React, { useEffect } from "react";
import { toast } from "react-hot-toast";
import { HiPlusCircle } from "react-icons/hi";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import blur from "../../Assets/images/blur.jpg";
import { showModal } from "../../features/loginModalShowSlice.js/loginModalShowSlice";
import {
    useCreateProfitLinkFromShortLinkMutation,
    useCreateProfitLinkMutation,
} from "../../features/profitlink/profitlinkApiSlice";
import useAuth from "../../hooks/useAuth";
import { Link } from "react-router-dom";

const TrendingCard = ({ data }) => {
    const dispatch = useDispatch();
    const isAuth = useAuth();
    const { img, brandsDetails, subBrandDetails, link, cashback } = data || {};

    const location = useLocation();

    const navigate = useNavigate();
    const access_token = Cookies.get("ayykori");
    const [
        createProfitLinkFromShortLink,
        {
            isLoading: isLoadingShortLink,
            isError: isErrorShortLink,
            isSuccess: isSuccessShortLink,
        },
    ] = useCreateProfitLinkFromShortLinkMutation() || {};

    const [
        createProfitLink,
        {
            data: dataProfit,
            isLoading: isLoadingProfit,
            isError: isErrorProfit,
            isSuccess: isSuccessProfit,
        },
    ] = useCreateProfitLinkMutation() || {};

    // showing error message
    useEffect(() => {
        if (isErrorProfit) {
            toast.error("Something Went Wrong!!");
        }
    }, [isErrorProfit]);

    const getCashbackHandler = async () => {
        const access_token = Cookies.get("ayykori");
        if (isAuth || access_token) {
            const shortLinkRes = await createProfitLink({
                access_token,
                fullLink: link ? link : brandsDetails[0]?.brandHomepageLink,
                source: "cashback",
            });
            const { data: shortlinkData } = shortLinkRes || {};
            if (
                shortlinkData &&
                shortlinkData?.shortLink &&
                shortlinkData?.succss
            ) {
                // const profitLinkRes = await createProfitLinkFromShortLink({
                //   access_token,
                //   shortLink: shortlinkData?.shortLink,
                // });
                // const { data: profitLinkResData } = profitLinkRes || {};

                // if (
                //   profitLinkResData &&
                //   profitLinkResData.profitLink &&
                //   profitLinkResData.succss
                // ) {
                let uid =
                    shortlinkData?.shortLink?.split("_")[
                        shortlinkData?.shortLink?.split("_")?.length - 1
                    ];
                window.open(
                    `/cashback-activated/${
                        data?.brandsDetails[0]?._id
                            ? data?.brandsDetails[0]?._id
                            : data?.brandUnid
                    }?uid=${uid}`,
                    "_blank",
                    // "popup",
                    "noreferrer"
                );
                // }
            }
        } else {
            dispatch(showModal());
            // navigate("/login", { state: location?.pathname });
        }
    };

    return (
        <>
            {/* previous code */}
            {/* <div className="cursor-pointer" onClick={getCashbackHandler}>
      <div className="relative ">
        <div>
          <LazyLoadImage
            className="rounded-md shadow-lg"
            src={img}
            placeholderSrc={blur}
            alt="treningImage"
          />
        </div>

        <div className="w-10 h-10 rounded-full border-red-100 shadow-md absolute bottom-3 bg-white left-2">
          <img
            className="w-10 h-10 rounded-full"
            src={
              subBrandDetails[0]?.subBrandimg
                ? subBrandDetails[0]?.subBrandimg
                : brandsDetails[0]?.brandimg
            }
            alt=""
          />
        </div>
      </div>
      <div className="mt-5">
        <h1 className="text-lg text-gray-600 font-medium">
          {subBrandDetails[0]?.subBrandName
            ? subBrandDetails[0]?.subBrandName
            : brandsDetails[0]?.brandName}
        </h1>
        <div className="text-sm text-[#E80C5B] flex items-center gap-1">
          <HiPlusCircle />
          <p>
            {cashback
              ? cashback
              : `Upto ${brandsDetails[0]?.cashbackUserCommission} % Cash Back`}
          </p>
        </div>
      </div>
    </div> */}

            {/* new code */}
            <Link to={data?.link} className="cursor-pointer">
                <div className="relative ">
                    <div>
                        <LazyLoadImage
                            className="rounded-md shadow-lg"
                            src={img}
                            placeholderSrc={blur}
                            alt="treningImage"
                        />
                    </div>

                    <div className="w-10 h-10 rounded-full border-red-100 shadow-md absolute bottom-3 bg-white left-2">
                        <img
                            className="w-10 h-10 rounded-full"
                            src={
                                subBrandDetails[0]?.subBrandimg
                                    ? subBrandDetails[0]?.subBrandimg
                                    : brandsDetails[0]?.brandimg
                            }
                            alt=""
                        />
                    </div>
                </div>
                <div className="mt-5">
                    <h1 className="text-lg text-gray-600 font-medium">
                        {subBrandDetails[0]?.subBrandName
                            ? subBrandDetails[0]?.subBrandName
                            : brandsDetails[0]?.brandName}
                    </h1>
                    <div className="text-sm text-[#E80C5B] flex items-center gap-1">
                        <HiPlusCircle />
                        <p>
                            {cashback
                                ? cashback
                                : `Upto ${brandsDetails[0]?.cashbackUserCommission} % Cash Back`}
                        </p>
                    </div>
                </div>
            </Link>
        </>
    );
};

export default TrendingCard;
