import Cookies from "js-cookie";
import React, { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { BsEye, BsFillShareFill, BsStarFill } from "react-icons/bs";
import "react-lazy-load-image-component/src/effects/blur.css";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {
  useCreateFavProductMutation,
  useDeleteFavProductMutation,
} from "../../features/favProducts/favProductApiSlice";
import { showModal } from "../../features/loginModalShowSlice.js/loginModalShowSlice";
import {
  useCreateProfitLinkForShareMutation,
  useCreateProfitLinkMutation,
} from "../../features/profitlink/profitlinkApiSlice";
import {
  changeShareLink,
  resetShareLink,
} from "../../features/sharelink/sharelinkSlice";
import useAuth from "../../hooks/useAuth";
import modernCopyToClipboardFunction from "../../utils/modernCopyClipBoard";
import CopyLinkModal from "../Modal/CopyLinkModal";
import ProductModal from "../Modal/ProductModal";
import ShareModal from "../Modal/ShareModal";

const ProductCard = ({ data }) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const isAuth = useAuth();
  const navigate = useNavigate();
  const [
    createProfitLink,
    {
      data: dataProfit,
      isLoading: isLoadingProfit,
      isError: isErrorProfit,
      isSuccess: isSuccessProfit,
    },
  ] = useCreateProfitLinkMutation() || {};
  const [
    createProfitLinkForShare,
    {
      data: dataProfitShare,
      isLoading: isLoadingProfitShare,
      isError: isErrorProfitShare,
      isSuccess: isSuccessProfitShare,
    },
  ] = useCreateProfitLinkForShareMutation() || {};

  const Icon = data?.Icon;
  const [shareModal, setShareModal] = useState(false);
  const [copyModal, setCopyModal] = useState(false);
  const [show, setShow] = useState(false);
  const access_token = Cookies.get("ayykori");

  const [
    createFavProduct,
    { isSuccess: isSuccessCreate, isError: isErrorCreate, error: errorCreate },
  ] = useCreateFavProductMutation() || {};
  const [deleteFavProduct] = useDeleteFavProductMutation() || {};

  useEffect(() => {
    if (isSuccessCreate) {
      toast.success("Favourite Product Added!!");
    }
    if (isErrorCreate) {
      toast.error(errorCreate?.data?.message || "Something Went Wrong!");
    }
  }, [isSuccessCreate, isErrorCreate, errorCreate?.data?.message]);

  // showing error message
  useEffect(() => {
    if (isErrorProfit && data?.buttontitle === "Get Cashback") {
      toast.error("Something Went Wrong!!");
    }
    if (isErrorProfit && data?.buttontitle === "Affiliate  Link") {
      toast.error("Something Went Wrong!!");
    }
    if (isErrorProfitShare && data?.buttontitle === "Get Cashback") {
      toast.error("Something Went Wrong!!");
    }
    if (isErrorProfitShare && data?.buttontitle === "Affiliate  Link") {
      toast.error("Something Went Wrong!!");
    }
  }, [isErrorProfit, isErrorProfitShare, data?.buttontitle]);
  useEffect(() => {
    // redirecting to the link
    if (isSuccessProfitShare && dataProfitShare?.shortLink) {
      modernCopyToClipboardFunction(dataProfitShare?.shortLink);
      // navigator?.clipboard?.writeText(dataProfitShare?.shortLink);
      dispatch(changeShareLink(dataProfitShare?.shortLink));
    }
  }, [
    isSuccessProfit,
    isSuccessProfitShare,
    dataProfit,
    data?.brandId,
    data?.buttontitle,
    dataProfit?.shortLink,
    dataProfitShare?.shortLink,
    dispatch,
  ]);

  const handleCashback = async () => {
    const access_token = Cookies.get("ayykori");
    if (isAuth || Cookies.get("ayykori")) {
      const shortlinkRes = await createProfitLink({
        access_token,
        fullLink: data?.productLink,
        source: "cashback",
      });

      const { data: shortlinkData } = shortlinkRes || {};

      if (shortlinkData && shortlinkData?.shortLink && shortlinkData?.succss) {
        let uid =
          shortlinkData?.shortLink?.split("_")[
            shortlinkData?.shortLink?.split("_")?.length - 1
          ];
        window.open(
          `/cashback-activated/${data?.brandId}?uid=${uid}`,
          "_blank",
          // "popup",
          "noreferrer"
        );
      }
    } else {
      dispatch(showModal());
    }
  };

  const handleEarning = async () => {
    const access_token = Cookies.get("ayykori");
    if (isAuth || Cookies.get("ayykori")) {
      data?.buttontitle === "Affiliate  Link" && setCopyModal(true);
      const shortLinkRes = await createProfitLink({
        access_token,
        fullLink: data?.productLink,
        source: "earning",
      });
      const { data: shortlinkData } = shortLinkRes || {};
      if (shortlinkData && shortlinkData?.shortLink) {
        modernCopyToClipboardFunction(shortlinkData?.shortLink);
        // navigator?.clipboard?.writeText(shortlinkData?.shortLink);
        dispatch(changeShareLink(shortlinkData?.shortLink));
      }
    } else {
      dispatch(showModal());
    }
  };

  // const handleEarningShare = async () => {
  //   const access_token = Cookies.get("ayykori");
  //   if (isAuth || Cookies.get("ayykori")) {
  //     const shortLinkRes = await createProfitLink({
  //       access_token,
  //       fullLink: data?.productLink,
  //       source: "earning",
  //     });
  //     console.log(shortLinkRes);
  //     const { data: shortlinkData } = shortLinkRes || {};
  //     if (shortlinkData && shortlinkData?.shortLink) {
  //       modernCopyToClipboardFunction(shortlinkData?.shortLink);
  //       // navigator?.clipboard?.writeText(shortlinkData?.shortLink);
  //       dispatch(resetShareLink());
  //       setShareModal(true);
  //       dispatch(changeShareLink(dataProfitShare?.shortLink));
  //     }
  //   } else {
  //     dispatch(showModal());
  //   }
  // };

  const handleEarningShare = async () => {
    if (isAuth || Cookies.get("ayykori")) {
      const shortlinkRes = await createProfitLinkForShare({
        access_token,
        fullLink: data?.productLink,
        source: "earning",
      });
      dispatch(resetShareLink());
      setShareModal(true);
      const { data: shortlinkData } = shortlinkRes || {};

      if (shortlinkData && shortlinkData?.shortLink && shortlinkData?.succss) {
        // navigator?.clipboard?.writeText(shortlinkData?.shortLink);
        modernCopyToClipboardFunction(shortlinkData?.shortLink);
        toast.success("Link Copied successfull!!");
        dispatch(resetShareLink());
        dispatch(changeShareLink(shortlinkData?.shortLink));
        setShareModal(true);
      }
    } else {
      dispatch(showModal());
      // navigate("/login", { state: location?.pathname });
    }
  };

  return (
    <>
      <ProductModal
        show={show}
        setShow={setShow}
        data={data}
        shareModal={shareModal}
        setShareModal={setShareModal}
        copyModal={copyModal}
        setCopyModal={setCopyModal}
      />
      <CopyLinkModal
        copyModal={copyModal}
        setCopyModal={setCopyModal}
        proftlink={dataProfit?.shortLink}
        isLoading={isLoadingProfit}
        isError={isErrorProfit}
      />
      <ShareModal shareModal={shareModal} setShareModal={setShareModal} />
      <div className=" relative rounded-md md:rounded-xl cursor-pointer group border-[0.5px] hover:shadow-lg shadow-[rgba(0,0,0,0.3)] hover:border-green-300 overflow-hidden transition-all duration-700 ">
        <div className="w-full h-full">
          <div className="w-full h-40 md:h-[250px] relative overflow-hidden group">
            {/* <LazyLoadImage
              className=" w-full object-cover h-full rounded-lg rounded-b-none  group-hover:scale-[1.1] 
              transition duration-[1.1s]"
              effect="blur"
              src={data?.image}
            /> */}
            <img
              className=" w-full object-cover h-full rounded-md md:rounded-lg md:rounded-b-none  group-hover:scale-[1.1] 
              transition duration-[1.1s]"
              src={data?.image}
              alt=""
            />
            <div className="absolute w-full h-full top-0 left-0 scale-0 bg-[rgba(0,0,0,0.2)] group-hover:scale-100 transition-all duration-700 flex justify-center items-center">
              <div className="flex w-[80%] md:w-[60%] mx-auto bg-transparent text-gray-300  bg-white rounded-md gap-x-2 items-center justify-center py-1">
                <span className="relative">
                  <BsFillShareFill
                    title="Share"
                    disabled={isLoadingProfitShare}
                    // onClick={() => {
                    //   const access_token = Cookies.get("ayykori");
                    //   if (isAuth || Cookies.get("ayykori")) {
                    //     createProfitLinkForShare({
                    //       access_token,
                    //       fullLink: data?.productLink,
                    //       source:
                    //         data?.buttontitle === "Get Cashback"
                    //           ? "cashback"
                    //           : "earning",
                    //     });
                    //     dispatch(resetShareLink());
                    //     setShareModal(true);
                    //   } else {
                    //     dispatch(showModal());
                    //   }
                    // }}
                    onClick={handleEarningShare}
                    size={18}
                    className="text-primaryColor hover:text-red-400"
                  />
                </span>
                |
                <span className="">
                  <BsEye
                    title="View"
                    onClick={() => setShow(true)}
                    size={18}
                    className="text-primaryColor hover:text-red-400"
                  />
                </span>{" "}
              </div>
            </div>
          </div>
          <div className="w-full flex justify-between flex-col gap-y-1 p-2">
            <div className="flex items-center justify-between">
              <h6 className="hidden md:block text-xs text-gray-400">
                <span>{data?.subCategory}</span>
              </h6>
              <h6 className="block md:hidden text-xs text-gray-400">
                {data?.subCategory?.length > 15 ? (
                  <span>{data?.subCategory?.slice(0, 15)}...</span>
                ) : (
                  <span>{data?.subCategory}</span>
                )}
              </h6>
              <div className="flex items-center gap-x-1">
                <BsStarFill size={10} className="text-[#FF790F]" />
                <p className="text-xs text-gray-500 font-outfit">
                  {data?.rating}
                </p>
              </div>
            </div>

            <h1 className="hidden md:block text-sm text-gray-600">
              {data?.name?.length > 25 ? (
                <span>{data?.name?.slice(0, 25)}...</span>
              ) : (
                <span>{data?.name}</span>
              )}
            </h1>
            <h1 className="block md:hidden text-sm text-gray-600">
              {data?.name?.length > 15 ? (
                <span>{data?.name?.slice(0, 15)}...</span>
              ) : (
                <span>{data?.name}</span>
              )}
            </h1>

            <div className="flex items-center justify-between relative my-1 text-gray-400">
              <h1 className="text-[10px] md:text-sm whitespace-pre">
                Price{" "}
                <span className="text-primaryColor text-[10px] md:text-xs font-medium">
                  {Math.floor(data?.price)}
                </span>{" "}
                &#2547;
              </h1>
              <h1 className="text-[10px] md:text-sm font-normal whitespace-pre ">
                {data?.buttontitle === "Get Cashback"
                  ? "Cashback"
                  : "Commission"}{" "}
                <span className="text-primaryColor text-[10px] md:text-xs font-medium">
                  {data?.profit}
                </span>{" "}
                &#2547;
              </h1>
            </div>

            {data?.buttontitle === "Affiliate  Link" && (
              <button
                disabled={isLoadingProfit}
                onClick={handleEarning}
                className="text-primaryColor font-medium text-xs bg-green-100 px-2 py-2 justify-center rounded-md flex items-center gap-x-1 mt-1 hover:bg-primaryColor hover:text-white transition-all duration-500"
              >
                <Icon size={18} />
                {data?.buttontitle}
              </button>
            )}
            {data?.buttontitle === "Get Cashback" && (
              <button
                disabled={isLoadingProfit}
                onClick={handleCashback}
                className="text-primaryColor font-medium text-xs bg-green-100 px-2 py-2 justify-center rounded-md flex items-center gap-x-1 mt-1 hover:bg-primaryColor hover:text-white transition-all duration-500"
              >
                <Icon size={18} />
                {data?.buttontitle}
              </button>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default ProductCard;
