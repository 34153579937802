import React from "react";

const ProductSortComponent = ({ title, limit, setLimit }) => {
  return (
    <div className=" w-full px-3 md:px-[55px] mt-6 ">
      <div className=" text-center flex items-center justify-between gap-2 ">
        <div className=" w-full h-[2.5px] flex justify-between items-end gap-x-5 bg-gray-300 ">
          <h1
            className=" w-fit text-base md:text-2xl text-gray-600 font-medium pb-2 border-b-[3px]
     border-primaryColor "
          >
            {title}
          </h1>

          {/* sort start */}
          {/* <div className="flex items-center gap-x-2 mb-2 text-gray-500">
            <div className="flex items-center border text-xs md:text-sm rounded-lg px-3 gap-x-2">
              <RxDashboard />
              Show
              <select
                value={limit}
                onChange={(e) => setLimit(e.target.value)}
                className=" px-1 py-1 md:py-2 bg-transparent  text-gray-500 outline-none cursor-pointer"
              >
                <option value={15}>15</option>
                <option value={20}>20</option>
                <option value={25}>25</option>
                <option value={30}>30</option>
              </select>
            </div>
          
          </div> */}
          {/* sort end */}
        </div>
      </div>
    </div>
  );
};

export default ProductSortComponent;
