import { useFormik } from "formik";
import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { toast } from "react-hot-toast";
import { HiOutlineMail } from "react-icons/hi";
import { Link } from "react-router-dom";
import signup from "../../Assets/images/authentication/signup2.gif";
import warning from "../../Assets/images/icon/forgot-password.png";
import ButtonLoader from "../../Loading/ButtonLoader";
import { useForgetPasswordMutation } from "../../features/auth/authApiSlice";
import CommonLayout from "../../layout/CommonLayout";
import { forgetPasswordSchema } from "../../validation/Schema";

const ForgotPassword = () => {
  const initialValues = {
    email: "",
  };

  //== For forget password api
  const [forgetPassword, { data, isLoading, isSuccess, isError, error }] =
    useForgetPasswordMutation() || {};
  //   normal login
  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      initialValues,
      validationSchema: forgetPasswordSchema,
      onSubmit: async (values, action) => {
        forgetPassword(values);
        // action.resetForm();
      },
    });
  //== showing success message
  useEffect(() => {
    if (isSuccess && !isError) {
      toast.success(data?.message);
    }
  }, [isSuccess, data?.message, isError]);

  //== showing error message
  useEffect(() => {
    if (isError) {
      toast.error(error?.data?.message || "Something Went Wrong!");
    }
  }, [isError, error?.data?.message]);
  return (
    <>
      <Helmet>
        <title>Forget Password | Ayykori</title>
      </Helmet>
      <CommonLayout>
        <div className="w-full py-10 md:py-0 md:h-screen bg-gradient-to-r from-[#67B26F]  to-[#4CA2CD] flex  justify-center items-start">
          <div className="w-[90%] md:w-[60%] lg:w-[55%] h-[68%] mt-5 rounded-md flex flex-col md:flex-row">
            <div className="w-full md:w-[50%] h-full bg-white rounded-t-md md:rounded-t-none md:rounded-tl-md md:border-r md:rounded-l-md p-3">
              <img
                className="w-full h-full object-contain"
                src={signup}
                alt=""
              />
            </div>
            <div className="w-full md:w-[50%] h-full bg-white rounded-b-md md:rounded-b-none md:rounded-r-md flex flex-col items-center pt-10 py-2">
              <img src={warning} alt="" />
              <h1 className="text-xl font-semibold text-gray-500 font-outfit ">
                Forgot Password?
              </h1>
              <h5 className="text-xs text text-gray-600 font-semibold">
                Send your email address to reset password |
                <Link to="/login">
                  <span className="text-primaryColor tracking-wider ml-1">
                    Login
                  </span>
                </Link>
              </h5>
              <div className="w-full px-4 pt-4">
                <form onSubmit={handleSubmit}>
                  <div className="flex flex-col gap-y-1 my-2 text-sm">
                    <label htmlFor="">Email Address</label>
                    <div className=" text-gray-500 flex items-center border gap-2 bg-white rounded-md px-3 py-2">
                      <HiOutlineMail size={20} />
                      <input
                        type="text"
                        placeholder="Email Address..."
                        name="email"
                        value={values.email}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className=" outline-none border-none w-full  bg-transparent text-gray-500"
                      />
                    </div>
                  </div>
                  {errors.email && touched.email ? (
                    <p className="text-[10px] text-red-500 -mt-2">
                      {errors.email}
                    </p>
                  ) : null}

                  <div className="flex items-center justify-center mt-4 text-sm w-full">
                    {isLoading ? (
                      <button
                        disabled={isLoading}
                        className={`${
                          isLoading && "cursor-not-allowed"
                        } rounded-md border text-white bg-primaryColor py-1.5 px-7 flex gap-2 items-center mr-2`}
                      >
                        Processing <ButtonLoader />
                      </button>
                    ) : (
                      <button className="rounded-md border text-white bg-primaryColor py-1.5 px-7 flex gap-2 items-center mr-2">
                        Send
                      </button>
                    )}
                  </div>
                  <p className="text-center font-normal text-xs text-gray-500 mt-4">
                    <span className="font-bold">NB:</span>A mail will be sent
                    within 5-10 minutes. If you did not get any mail, kindly
                    check your spam. And click
                    <span className="font-medium">"looks safe"</span>
                    or <span className="font-medium">"Report not spam"</span>.
                  </p>
                </form>
              </div>
            </div>
          </div>
        </div>
      </CommonLayout>
    </>
  );
};

export default ForgotPassword;
