import React, { useState } from "react";
import { MdArrowForwardIos } from "react-icons/md";
// Import Swiper React components

// Import Swiper styles
import { Autoplay } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import { Swiper, SwiperSlide } from "swiper/react";
import {
  shareAndEarnBannerData,
  shareAndEarnMobileBannerData,
} from "../../Data/sliderBannerData";
import CommonBanner from "../Banner/CommonBanner";

const ShareAndEarnSlider = () => {
  const [swiperRef, setSwiperRef] = useState(null);
  const prevHandler = () => {
    swiperRef.slidePrev();
  };
  const nextHandler = () => {
    swiperRef.slideNext();
  };
  return (
    <>
      <div>
        {/* this is for desktop */}
        <div className="w-full hidden md:block px-3 md:px-[55px] my-6 md:my-10 relative">
          <button
            className="absolute hidden md:block  left-9 top-[50%] translate-y-[-50%] z-[100] rounded-full p-2 bg-gray-100 shadow-md shadow-gray-300"
            onClick={prevHandler}
          >
            <MdArrowForwardIos
              size={18}
              className="rotate-180 cursor-pointer text-gray-600"
            />
          </button>
          {/* <div className="absolute -left-8 top-0 bottom-0 w-[8%] h-full bg-gray-200 z-50 blur-lg bg-opacity-80 "></div> */}
          <Swiper
            slidesPerView={1.2}
            spaceBetween={10}
            // onActiveIndexChange={(index)=> setSlidesPerView(index)}
            loop={true}
            autoplay={{ delay: 5000 }}
            modules={[Autoplay]}
            onSwiper={(swiper) => setSwiperRef(swiper)}
            className="mySwiper w-full"
          >
            {shareAndEarnBannerData &&
              shareAndEarnBannerData?.map((item, index) => (
                <SwiperSlide key={index}>
                  <CommonBanner data={{ image: item?.image }} />
                </SwiperSlide>
              ))}
          </Swiper>
          <div className="absolute -right-0 sm:-right-8 top-0 bottom-0 w-[20%] h-full bg-gradient-to-r from-transparent via-[rgba(255,255,255,0.8)] to-white z-50"></div>
          <button
            className="hidden md:block absolute right-9 top-[50%] translate-y-[-50%] z-[100] rounded-full p-2 bg-gray-100 shadow-md shadow-gray-300"
            onClick={nextHandler}
          >
            <MdArrowForwardIos
              size={18}
              className="cursor-pointer text-gray-600"
            />
          </button>
        </div>

        {/* this is for mobile */}

        <div className="w-full block md:hidden px-3 md:px-[55px] my-6 md:my-10 relative">
          {/* <div className="absolute hidden md:block  left-9 top-[50%] translate-y-[-50%] z-[100] rounded-full p-2 bg-gray-100 shadow-md shadow-gray-300">
            <MdArrowForwardIos
              size={18}
              className="rotate-180 cursor-pointer text-gray-600"
              onClick={prevHandler}
            />
          </div> */}
          {/* <div className="absolute -left-8 top-0 bottom-0 w-[8%] h-full bg-gray-200 z-50 blur-lg bg-opacity-80 "></div> */}
          <Swiper
            slidesPerView={1.2}
            spaceBetween={10}
            // onActiveIndexChange={(index)=> setSlidesPerView(index)}
            loop={true}
            autoplay={{ delay: 5000 }}
            modules={[Autoplay]}
            // onSwiper={(swiper) => setSwiperRef(swiper)}
            className="mySwiper w-full"
          >
            {shareAndEarnMobileBannerData &&
              shareAndEarnMobileBannerData?.map((item, index) => (
                <SwiperSlide key={index}>
                  <CommonBanner data={{ image: item?.image }} />
                </SwiperSlide>
              ))}
          </Swiper>
          <div className="absolute -right-0 sm:-right-8 top-0 bottom-0 w-[20%] h-full bg-gradient-to-r from-transparent via-[rgba(255,255,255,0.8)] to-white z-50"></div>
          {/* <div className="hidden md:block absolute right-9 top-[50%] translate-y-[-50%] z-[100] rounded-full p-2 bg-gray-100 shadow-md shadow-gray-300">
            <MdArrowForwardIos
              size={18}
              className="cursor-pointer text-gray-600"
              onClick={nextHandler}
            />
          </div> */}
        </div>
      </div>
    </>
  );
};

export default ShareAndEarnSlider;
