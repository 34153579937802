import React, { useState } from "react";
import { HiOutlineArrowNarrowRight } from "react-icons/hi";
// import { Autoplay } from "swiper";
import { A11y, Autoplay, Navigation, Pagination, Scrollbar } from "swiper";
import "swiper/css";
import { Swiper, SwiperSlide } from "swiper/react";
import { journeyData } from "../../Data/journeyData";

const Journey = ({ setSteps }) => {
  const [journey] = useState(journeyData);
  return (
    <div className="mt-8">
      <Swiper
        modules={[Autoplay, Navigation, Pagination, Scrollbar, A11y]}
        spaceBetween={50}
        slidesPerView={1}
        navigation
        // pagination={{ clickable: true }}
        loop={true}
        autoplay={{
          delay: 4000,
          disableOnInteraction: false,
        }}
      >
        {journey?.map((item, index) => (
          <SwiperSlide key={index}>
            <div>
              <img
                src={item?.img}
                alt=""
                className="h-[220px] object-contain mx-auto"
              />
              <h2 className={`text-gray-500 uppercase w-fit`}>
                Step {index + 1}
              </h2>
              <h2 className={`text-xl text-gray-700 font-bold`}>
                {item?.title}
              </h2>
              <p className="text-sm text-gray-500">{item.description}</p>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
      <div
        onClick={() => setSteps(1)}
        className="flex justify-end items-center gap-2 mt-4 text-primaryColor lg:hidden md:hidden "
      >
        Open Account
        <HiOutlineArrowNarrowRight className="text-lg mt-0.5" />
      </div>
    </div>
  );
};

export default Journey;
