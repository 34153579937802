import Cookies from "js-cookie";
import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import blur from "../../Assets/images/blur.jpg";
import { showModal } from "../../features/loginModalShowSlice.js/loginModalShowSlice";
import useAuth from "../../hooks/useAuth";
const CommonBanner = ({ data, tempLink }) => {
  const isAuth = useAuth();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleOnclick = () => {
    if (data?.link) {
      navigate(data.link);
    } else if (data?.brandId) {
      if (isAuth || Cookies.get("ayykori")) {
        navigate(`brand/cashback/${data.brandId}`);
      } else {
        dispatch(showModal());
      }
    } else if (tempLink) {
      navigate(tempLink);
    }
  };
  return (
    <div onClick={handleOnclick}>
      <LazyLoadImage
        className="w-full h-full object-contain rounded-lg"
        src={data?.bannerImage?.url}
        placeholderSrc={blur}
        alt="homepageBanner"
      />
      {/* <img
        className="w-full h-full object-contain rounded-lg"
        src={data?.bannerImage?.url}
        alt=""
      /> */}
    </div>
  );
};

export default CommonBanner;
