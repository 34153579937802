import React from "react";

const BanglaTramsAndConditions = () => {
  return (
    <div>
      <h2>&nbsp;</h2>
      <p>
        <strong>১। পরিচিতি:</strong>
      </p>
      <p className="">
        www.AyyKori .com-এ স্বাগতম। পরিষেবাটি মাস্টারকি টেকনোলজিস লিমিটেড দ্বারা
        পরিচালিত হয় (“আমরা”, “আমাদের”, “আয়করি” বা “আমাদের” যেখানে এই ধরনের
        অভিব্যক্তি তার প্রেক্ষাপটের বিপরীত না হলে, তার নিজ নিজ আইনগত
        উত্তরাধিকারী, প্রতিনিধি, প্রশাসক, অনুমোদিত উত্তরাধিকারীদের অন্তর্ভুক্ত
        বলে মনে করা হবে। এবং বরাদ্দ)। অনুগ্রহ করে এই নিয়ম ও শর্তাবলী ("নিয়ম ও
        শর্তাবলী" বা "চুক্তি") সাবধানে পড়ুন কারণ এতে আইনী শর্তাবলী রয়েছে যা
        আপনি যখন ওয়েবসাইট এবং অ্যাপের মাধ্যমে আমাদের প্রদত্ত পরিষেবা ব্যবহার
        করেন তখন আপনি সম্মত হন ("প্ল্যাটফর্ম", " ওয়েবসাইট")।
      </p>
      <p>
        <strong>২. সদস্যপদ:</strong>
      </p>
      <p>&nbsp;</p>
      <p>
        আপনি AyyKori-তে কিছু অনুরোধ করা তথ্য জমা দেওয়ার পরে আমাদের সদস্যপদ
        নিবন্ধনের মাধ্যমে উপলব্ধ। রেজিস্ট্রেশনের সময়, আমরা আপনার দেখার জন্য
        আমাদের বর্তমান নিয়ম ও শর্তাবলী প্রদর্শন করি। AyyKori ব্যবহারকারীদের
        কোনো পূর্ব সম্মতি বা অনুমতি ছাড়াই যখন প্রয়োজন তখন এখানে প্রদত্ত নিয়ম
        ও শর্তাবলী আপডেট ও পরিবর্তন করার অধিকার সংরক্ষণ করে। অনুগ্রহ করে অবহিত
        করুন যে AyyKori সদস্যরা Google, Facebook বা অন্য কোনো সাইটে AyyKori-এর
        উল্লেখ করে কোনো স্পনসর করা বিজ্ঞাপন চালাতে পারে। এই ধরনের ক্ষেত্রে
        অবশ্যই AyyKori থেকে লিখিতভাবে পূর্বানুমতি নিতে হবে, তা করতে ব্যর্থ হলে
        AyyKori-এর বিবেচনার ভিত্তিতে সদস্যের অ্যাকাউন্ট বন্ধ করে দেওয়া হতে
        পারে।
      </p>
      <p>
        অনুগ্রহ করে অবহিত করুন যে AyyKori সদস্যরা Google, Facebook বা অন্য কোনো
        সাইটে AyyKori-এর উল্লেখ করে যে কোনো স্পনসর করা বিজ্ঞাপন চালাতে পারে। এই
        ধরনের ক্ষেত্রে অবশ্যই AyyKori থেকে লিখিতভাবে পূর্বানুমতি নিতে হবে, তা
        করতে ব্যর্থ হলে AyyKori-এর বিবেচনার ভিত্তিতে সদস্যের অ্যাকাউন্ট বন্ধ করে
        দেওয়া হতে পারে।
      </p>
      <p>
        <strong>৩. আমাদের পরিষেবা:</strong>
        <p>
          আমাদের পরিষেবা সেই ব্যবহারকারীদের মঞ্জুরি দেয় যারা প্ল্যাটফর্মে একজন
          প্রচারক বা ক্রেতা হিসাবে নিবন্ধন করে এবং সেইজন্য আমাদের সাথে একটি
          অ্যাকাউন্ট তৈরি করে ("অ্যাকাউন্ট") ব্র্যান্ড অংশীদারদের পণ্যের ট্র্যাক
          করা ক্রয়ের উপর একটি মুনাফা বা ক্যাশব্যাক উপার্জন করতে, এর
          প্রচারকারীদের দ্বারা ভাগ করা কাস্টমাইজ করা লিঙ্কগুলির মাধ্যমে AyyKori
          প্ল্যাটফর্ম। অ্যাফিলিয়েট মার্কেটিং লাভের জন্য যোগ্যতা অর্জনের জন্য,
          ব্র্যান্ড অংশীদারকে অবশ্যই নিশ্চিত করতে হবে যে কাস্টমাইজ করা লিঙ্কের
          মাধ্যমে প্রচারকের কেনাকাটা ট্র্যাক করা হয়েছে, প্রকৃত এবং সফল হয়েছে
          (একটি "যোগ্য লেনদেন" গঠন করে)। প্রচারকারীরা এই কাস্টমাইজড AyyKori
          লিঙ্কগুলি তাদের বন্ধু, পরিবার এবং আরও অনেকের সাথে শেয়ার করতে পারেন।
          যখন এই লিঙ্কগুলির মাধ্যমে যেকোন যোগ্য লেনদেন তৈরি করা হয় এবং AyyKori
          ব্র্যান্ড অংশীদারদের কাছ থেকে এই যোগ্য লেনদেনের জন্য একটি কমিশন ফি
          পায়, তখন AyyKori তাদের কাস্টমাইজড AyyKori লিঙ্কগুলির মাধ্যমে উৎপন্ন
          বিক্রয়ের জন্য প্রচারকদের "লাভ" প্রদান করবে। অনুগ্রহ করে মনে রাখবেন যে
          কোনো কারণে যদি, AyyKori উপযুক্ত লেনদেনের জন্য ব্র্যান্ড অংশীদারদের কাছ
          থেকে অর্থপ্রদানের কমিশন না পায়, তাহলে প্রোমোটারের জন্যও কোনো লাভ হবে
          না।
        </p>
      </p>
      <p>&nbsp;</p>
      <p>
        <strong>৪. সদস্য হওয়া:</strong>
      </p>
      <p>&nbsp;</p>
      আপনি যদি একজন স্বাভাবিক ব্যক্তি হন, (i) আপনার প্ল্যাটফর্ম ব্যবহারের শর্ত
      হিসাবে, আপনার বয়স 18 (আঠার) বছর বা তার বেশি হতে হবে; এবং (ii) প্ল্যাটফর্ম
      পরিদর্শন করে বা এই শর্তাবলী গ্রহণ করে, আপনি AyyKori-এর কাছে প্রতিনিধিত্ব
      করেন এবং ওয়ারেন্টি দেন যে আপনার বয়স 18 (আঠার) বছর বা তার বেশি এবং আপনার
      প্ল্যাটফর্ম ব্যবহার করার অধিকার, কর্তৃত্ব এবং ক্ষমতা রয়েছে এবং সম্মত হন
      এবং এই শর্তাবলী মেনে চলুন; তবে শর্ত থাকে যে আপনি যদি 18 (আঠার) বছরের কম
      বয়সী একজন ব্যক্তির পিতামাতা বা আইনী অভিভাবক হন, তাহলে আপনি এই ধরনের
      ব্যক্তিদের পক্ষ থেকে পরিষেবাগুলি সংগ্রহের উদ্দেশ্যে প্ল্যাটফর্ম অ্যাক্সেস
      এবং ব্যবহার করার অনুমতি পাবেন আপনাকে অবশ্যই আপনার সম্পর্কে সঠিক এবং
      বর্তমান তথ্য ব্যবহার করে পরিষেবার জন্য নিবন্ধন করতে হবে - আপনার সঠিক নাম,
      ঠিকানা এবং অন্য যেকোন অনুরোধ করা বিবরণ সহ। যদি আপনাকে জিজ্ঞাসা করা হয় এবং
      প্রদান করা হয়, একটি ব্যাঙ্ক অ্যাকাউন্টের বিশদ বিবরণ বা চেক অর্থপ্রদানের
      বিবরণ বা অন্যান্য অর্থপ্রদানের মোডের বিশদ বিবরণ যেখানে আপনি অর্থপ্রদান
      পেতে চান (আপনার "লাভ প্রাপ্তির পদ্ধতি"), আপনাকে (ক) নিশ্চিত করতে হবে যে
      আপনি আছেন এবং থাকবেন , সেই মুনাফা রসিদ পদ্ধতি ব্যবহার করার সম্পূর্ণ
      অধিকারী, এবং (খ) নিশ্চিত করুন যে আপনি সেই লাভ রসিদ পদ্ধতির মাধ্যমে লাভ
      পেতে চান। আপনার অ্যাকাউন্টের মাধ্যমে এই তথ্য আপডেট করা উচিত। 'সংবেদনশীল
      ব্যক্তিগত ডেটা বা তথ্য' তৈরি, সংগ্রহ এবং প্রচার ডিজিটাল নিরাপত্তা আইন,
      2018 অনুযায়ী সময়ে সময়ে সংশোধিত এবং সংশ্লিষ্ট নিয়মগুলির জন্য
      ব্যবহারকারীর স্পষ্ট সম্মতি প্রয়োজন। নিবন্ধনের সময় "আমি শর্তাবলী এবং
      নীতির সাথে সম্মত" বোতামে ক্লিক করে এই নীতিতে সম্মতি নিশ্চিত করার মাধ্যমে,
      ব্যবহারকারী প্রযোজ্য আইনের অধীনে প্রয়োজনীয় তথ্য তৈরি এবং সংগ্রহের জন্য
      সম্মতি প্রদান করে। আমাদের ব্র্যান্ড অংশীদারদের ঝুঁকি বিশ্লেষণের উদ্দেশ্যে
      এবং অন্য কোনো সংবিধিবদ্ধ প্রয়োজনীয়তার জন্য আমাদের এই ডেটা শেয়ার করতে
      হতে পারে। "আমি শর্তাবলী এবং নীতির সাথে সম্মত" বোতামে ক্লিক করার মাধ্যমে
      আপনি আমাদের ব্র্যান্ড অংশীদারদের সাথে আপনার ডেটা এবং তথ্য ভাগ করার অনুমতি
      দেন৷ মনে রাখবেন যে আপনার লাভ রসিদ পদ্ধতিতে (যেমন BFTN, চেক, MFS
      স্থানান্তর, উপহার কার্ড বা অন্যান্য অর্থপ্রদানের মোড যেমন আমরা প্রবর্তন
      করতে পারি) সেই লাভ রসিদ পদ্ধতির মাধ্যমে আপনি যে সর্বাধিক বা সর্বনিম্ন
      অর্থপ্রদান পেতে পারেন সে সম্পর্কে নিয়ম থাকতে পারে। গুরুত্বপূর্ণ: আপনাকে
      অবশ্যই নিশ্চিত করতে হবে যে আমরা আপনার জন্য যে ইমেল ঠিকানা এবং মোবাইল নম্বর
      রাখি তা আপ-টু-ডেট রাখা হয়েছে এবং এতে আপনার সম্পূর্ণ অ্যাক্সেস রয়েছে –
      আমরা সেখানে আপনাকে গুরুত্বপূর্ণ বার্তা পাঠাব। আপনি যদি আপনার ইমেল ঠিকানা
      বা মোবাইল নম্বর পরিবর্তন করেন, তাহলে আপনাকে অবশ্যই আপনার অ্যাকাউন্টে আপনার
      জন্য রাখা বিশদ পরিবর্তন করতে হবে।
      <p>
        <strong>৫. লাভ এবং রেফারেল ফি:</strong>
      </p>
      <p>
        একজন সদস্য সফলভাবে একটি যোগ্য লেনদেন সম্পন্ন করার পরে, এবং একবার আমরা
        সেই যোগ্যতামূলক লেনদেনের জন্য ফলস্বরূপ লাভ পেয়ে গেলে, আমরা সদস্যকে তার
        লাভের রসিদ পদ্ধতির মাধ্যমে সেই লাভটি প্রেরণ করি। এই ধরনের অর্থপ্রদানের
        জন্য আবেদন করা হবে এবং আবেদনের 45-80 তারিখের পরে সদস্যের কাছে
        স্বয়ংক্রিয়ভাবে বিতরণ করা হবে, প্রতি মাসের 1 তম দিনে এবং 16 তম দিনে,
        যতদিন এটি একটি কার্যদিবস থাকে। যদি তা না হয়, প্রথম পরের কার্যদিবসে অর্থ
        প্রদান করা হবে। প্রকাশিতদের কাছে পপ-আপ উইথড্র বোতামে ক্লিক করে অর্জিত
        লাভের সম্পূর্ণ বা আংশিক যোগফল তুলে নেওয়ার বিকল্প থাকবে যা উপরে উল্লেখিত
        [NR1] তারিখে তাদের পোর্টালে উপলব্ধ করা হবে। সদস্যদের তাদের পছন্দের
        অর্থপ্রদানের মোড (যেমন ব্যাঙ্ক/মোবাইল ওয়ালেট) বেছে নেওয়ার অধিকার থাকবে
        এবং যে ক্ষেত্রে সদস্যরা AyyKori-তে সঠিক অর্থপ্রদানের তথ্য প্রদান করতে
        ব্যর্থ হয়েছেন সে ক্ষেত্রে অ-হস্তান্তর বা ভুলভাবে অর্থ স্থানান্তরের জন্য
        AyyKori দায়ী থাকবে না। পোর্টাল/ড্যাশবোর্ড। অনুগ্রহ করে মনে রাখবেন যে
        বিভিন্ন পরিস্থিতিতে একটি ব্র্যান্ড অংশীদারের সাথে একটি লেনদেন একটি যোগ্য
        লেনদেন গঠন নাও হতে পারে বা এর ফলে লাভ নাও হতে পারে৷ সদস্য বোঝেন যে তার
        দ্বারা করা সমস্ত লেনদেন আমাদের সাথে নয়, তবে পৃথক ব্র্যান্ড অংশীদারের
        সাথে। আমাদের সহায়তা পৃষ্ঠাগুলি এই পরিস্থিতি সম্পর্কে আরও তথ্য প্রদান
        করে। উপরন্তু, সময়ে সময়ে ব্র্যান্ড অংশীদাররা প্রদত্ত কমিশন বাড়াতে বা
        কমাতে পারে – সেক্ষেত্রে আমাদের প্ল্যাটফর্মে দেখানো লাভের অফারটি ভুল হতে
        পারে। ডিফল্টরূপে, আপনার প্রযোজ্য লেনদেনগুলি ব্র্যান্ড অংশীদার দ্বারা
        আমাদের রিপোর্ট করা কমিশনের সাথে সঙ্গতি রেখে ক্রেডিট করা হবে, যা
        বিজ্ঞাপনের হারের চেয়ে বেশি বা কম হতে পারে। সদস্যের প্রত্যাশিত মুনাফা
        এবং সদস্য দ্বারা প্রাপ্ত প্রকৃত লাভের কোনো পার্থক্যের জন্য আমরা দায়ী
        থাকব না। যখন আমরা আপনার অ্যাকাউন্টে একটি অর্থপ্রদানকারী, যোগ্য লেনদেন
        খুঁজে পাই, তখন আমরা আপনার অ্যাকাউন্টে একটি 'মুলতুবি পেমেন্ট' জমা দেব।
        ব্র্যান্ড পার্টনার একবার লেনদেন নিশ্চিত করলে, অর্থপ্রদানটি 'বৈধিত'
        হিসেবে চিহ্নিত করা হবে - এটি কেনার তারিখ থেকে 30 থেকে 80 দিন বা তার বেশি
        সময় নিতে পারে। যদি পণ্য ফেরত দেওয়া হয় বা বিক্রয় বিপরীত হয় বা কোনো
        উপায়ে সংশোধন করা হয় তাহলে লাভের অর্থ প্রদান করা হবে না। ব্র্যান্ড
        পার্টনার ছাড়াও, সদস্যদের জন্য AyyKori-কে যে কোনো রিটার্ন বা বিনিময়
        সম্পর্কে অবহিত করা বাধ্যতামূলক, যার জন্য তারা অযথা মুনাফা অর্জন করতে
        পারে। একটি লেনদেন লাভের জন্য যোগ্য লেনদেন (একটি যোগ্যতা রেফারেল ফি সহ)
        হিসাবে যোগ্য হবে কিনা তা ব্র্যান্ড অংশীদার বা আমাদের এবং সদস্যের নিজস্ব
        বিবেচনার ভিত্তিতে হবে এবং সদস্য এটি বোঝে এবং সম্মত হয়৷ যদি ব্র্যান্ড
        অংশীদার আমাদের কাছে বিক্রয়ের প্রতিবেদন করতে ব্যর্থ হয় বা যোগ্য লেনদেন
        হিসাবে একটি লেনদেনকে যোগ্য না করে তবে আমরা দায়ী হব না। তদুপরি, কিছু
        প্রযুক্তিগত বা অন্যান্য ত্রুটির কারণে আমরা ইভেন্টে দায়বদ্ধ হব না আমরা
        সদস্যের কাছে একটি বিক্রয় ট্রেস করতে পারি না এবং যেকোন কারণে লেনদেনকে
        একটি যোগ্য লেনদেন বা একটি যোগ্য রেফারেল হিসাবে যোগ্যতা অর্জন করি না।
        যদিও আমরা ব্র্যান্ড পার্টনারের কাছ থেকে অ-পেমেন্ট পুনরুদ্ধার করার চেষ্টা
        করব, আমাদের নিজেদের সিদ্ধান্ত, ব্র্যান্ড পার্টনার বা ব্র্যান্ড
        পার্টনারের ট্র্যাকিং এজেন্ট চূড়ান্ত। আমরা চেষ্টা করব এবং হারিয়ে যাওয়া
        কমিশনগুলি পুনরুদ্ধার করব, যে কোনও সময়ে আমরা অনুপস্থিত কমিশন দাবিগুলি না
        করার অধিকার সংরক্ষণ করি, বিশেষ করে যেখানে কোনও ক্রয় করা হয়নি৷ আরও, যদি
        ব্র্যান্ড পার্টনার মনে করেন যে কোনো কারণেই ক্রয়টি আসল নয় এবং আমরা
        লেনদেনের জন্য কোনো লাভ পাই না, সদস্য কোনো লাভ পাবেন না। AyyKori পরিষেবা
        তার সদস্যদের প্ল্যাটফর্মে প্রদত্ত পদ্ধতিতে তাদের দ্বারা প্রবর্তিত নতুন
        সদস্যদের জন্য একটি অনন্য এককালীন রেফারেল ফি প্রদান করে ("যোগ্যতা
        প্রদানকারী রেফারেল"), তবে শর্ত থাকে যে সদ্য রেফার করা সদস্য কমপক্ষে দুটি
        সফল হওয়ার জন্য সদস্য থাকবেন। উপরে উল্লিখিত অর্থপ্রদানের চক্র এবং
        অতিরিক্তভাবে তাদের যোগ্য লেনদেনের মাধ্যমে 1000tk এর একটি যোগ্যতা অর্জন
        করতে হবে। রেফারিং সদস্যকে কোন রেফারেল ফি প্রদান করা হবে না যতক্ষণ না
        রেফার করা সদস্য উপরের মত যোগ্যতা অর্জন করে। কোম্পানি তার সদস্যদের কোনো
        বিজ্ঞপ্তি ছাড়াই তার নিজস্ব বিবেচনার ভিত্তিতে যোগ্যতার পরিমাণ সংশোধন
        করার অধিকার সংরক্ষণ করে। আমরা সেই অনুযায়ী পুনরুদ্ধার বা ব্যালেন্স
        সামঞ্জস্য করার অধিকার সংরক্ষণ করি যেখানে এটি প্রতিষ্ঠিত হয়েছে যে কোনো
        ক্রেডিট ভুলভাবে প্রয়োগ করা হয়েছে। এর মধ্যে অন্তর্ভুক্ত থাকবে কিন্তু
        সীমাবদ্ধ নয় এমন লেনদেনের মধ্যে যেখানে ক্রেডিট প্রকৃত অর্থে বকেয়া নয়
        বা কোনো ক্রেডিট লেনদেনের জন্য কোনো ব্র্যান্ড অংশীদার বা তার এজেন্সিগুলির
        কাছ থেকে পেমেন্ট পাওয়া যায়নি এবং/অথবা অপব্যবহার বা জালিয়াতি। এর মধ্যে
        এমন লেনদেন অন্তর্ভুক্ত থাকতে পারে যেগুলি ইতিমধ্যেই প্রদেয় হিসাবে
        চিহ্নিত বা আপনার অ্যাকাউন্টে যাচাই করা হয়েছে, অথবা এমন লেনদেনের জন্য যা
        ইতিমধ্যেই আপনাকে BFTN, MFS স্থানান্তর, চেক, উপহার কার্ড বা অন্য কোনো
        অর্থপ্রদানের মোড দ্বারা প্রদান করা হয়েছে যা আমরা সময়ে সময়ে প্রবর্তন
        করতে পারি। . এই ধরনের সমস্ত ঘটনায়, আয়কোরির অধিকার রয়েছে সমস্ত
        অযৌক্তিক প্রদত্ত মুনাফা যা সদস্য উপার্জনের অধিকারী নয়, আইনী প্রক্রিয়ার
        মাধ্যমে, জমির আইন অনুসারে।
      </p>
      <p>
        <strong>৬. আপনার অ্যাকাউন্ট:</strong>
      </p>
      <p>&nbsp;</p>
      <p>
        উপরে উল্লিখিত অর্থপ্রদান চক্রের দিনে অর্জিত পরিমাণ নির্বিশেষে আপনি আপনার
        বৈধ মুনাফা প্রত্যাহার করতে পারেন। ইভেন্টে, সদস্য আমাদের সাথে
        অ্যাকাউন্টটি বন্ধ করার সিদ্ধান্ত নেয় (উদ্দেশ্যে/ভুলবশত) এবং যদি বন্ধ
        হওয়ার সময়টি একটি নির্দিষ্ট পেমেন্ট চক্রের দিনের আগে হয়, তাহলে আপনার
        অ্যাকাউন্টের লাভ বাজেয়াপ্ত করা হবে এবং সদস্য হবে না। AyyKori থেকে আরও
        কোনো পেমেন্ট পেতে সক্ষম। এমনকি এমন পরিস্থিতিতে যেখানে কোনও সদস্যের
        পূর্বে প্রকাশিত লিঙ্কগুলি অর্ডার দেওয়ার জন্য ব্যবহার করা হচ্ছে, সদস্যকে
        কোনও অর্থ প্রদান করা হবে না এবং সদস্যকে আমাদের পরিষেবা এবং সুবিধাগুলি
        পেতে আমাদের ওয়েবসাইটে একটি অ্যাকাউন্ট পুনরায় খুলতে হবে।
      </p>
      <p>
        <strong>৭. মেধা সম্পত্তি:</strong>
      </p>
      <p>&nbsp;</p>
      <p>
        আপনি স্বীকার করেন যে প্ল্যাটফর্মে এবং এর সাথে সম্পর্কিত সমস্ত কপিরাইট,
        ট্রেডমার্ক এবং অন্যান্য মেধা সম্পত্তি অধিকার (সদস্য বা ব্র্যান্ড
        অংশীদারদের দ্বারা অবদান রাখা উপাদান সহ) আমাদের মালিকানাধীন বা
        লাইসেন্সপ্রাপ্ত। ওয়েব-সাইটগুলিতে প্রদর্শিত উপাদানগুলি অনুলিপি করা সহজ,
        তবে এর অর্থ এই নয় যে এটি আইনী। অতএব, কেউ কপি, বিতরণ, জনসমক্ষে দেখাতে বা
        প্ল্যাটফর্ম থেকে কোনও ডেরিভেটিভ কাজ তৈরি করতে পারে না, বা প্ল্যাটফর্মে
        পাওয়া যে কোনও উপাদান আমাদের দ্বারা সঠিকভাবে লাইসেন্স না করা পর্যন্ত।
        প্ল্যাটফর্মে কোনো উপাদান আপলোড বা অন্তর্ভুক্ত করে, একজন সদস্য স্পষ্টভাবে
        অনুদান দেন: ক) আমাদের কাছে একটি অ-এক্সক্লুসিভ লাইসেন্স (সাব-লাইসেন্স
        দেওয়ার অধিকার সহ) আমাদের পরিষেবা এবং অন্য কোনও ইন্টারেক্টিভ পরিষেবার
        মাধ্যমে সেই উপাদানটি ব্যবহার, পুনরুত্পাদন এবং বিতরণ করার জন্য যার
        মাধ্যমে আমরা বা আমাদের সাব-লাইসেন্স গ্রহীতা পরিষেবা (বা একটি পরিষেবা)
        তৈরি করি আমাদের পরিষেবার উপর ভিত্তি করে) উপলব্ধ; এবং খ) অন্যান্য
        সদস্যদের (আমাদের মাধ্যমে, এখানে উপরে উল্লেখিত লাইসেন্সের অধীনে),
        প্রাসঙ্গিক উপাদান দেখার অ-একচেটিয়া, ব্যক্তিগত, অ-হস্তান্তরযোগ্য অধিকার।
      </p>
      <p>
        <strong>৮. গোপনীয়তা নীতি:</strong>
      </p>
      <p>&nbsp;</p>
      <p>
        আমাদের গোপনীয়তা নীতি এই চুক্তির অংশ গঠন করে, এবং এই চুক্তিতে প্রবেশ
        করার মাধ্যমে আপনি সেই নীতির অধীনে আপনার ব্যক্তিগত ডেটা পরিচালনা করার
        পদ্ধতিতে আপনার সম্মতি দেন। ওয়ার্ল্ড ওয়াইড ওয়েবের বৈশ্বিক প্রকৃতির
        পরিপ্রেক্ষিতে, দয়া করে মনে রাখবেন যে প্ল্যাটফর্মে একটি পোস্টিং সারা
        বিশ্বের ইন্টারনেট ব্যবহারকারীদের কাছে অ্যাক্সেসযোগ্য হতে পারে। মাস্টারকি
        টেকনোলজিস লিমিটেড (যেমনটি হতে পারে) (আমরা", "আমাদের", "আয়কোরি" বা
        "আমাদের", যেখানে এই ধরনের অভিব্যক্তি তার প্রেক্ষাপটের বিপরীত না হলে, তার
        নিজ নিজ আইনি উত্তরাধিকারী, প্রতিনিধি, প্রশাসকদের অন্তর্ভুক্ত বলে গণ্য
        করা হবে। অনুমোদিত উত্তরসূরি এবং নিয়োগ) ওয়েবসাইট এবং মোবাইল
        অ্যাপ্লিকেশন AyyKori.net (“ওয়েবসাইট”, “আমাদের ওয়েবসাইট”, “সাইট”) মালিক
        এবং/ অথবা পরিচালনা করে। পরিষেবাগুলি প্রদানের উদ্দেশ্যে (নিচে ধারা 1 এ
        সংজ্ঞায়িত করা হয়েছে), AyyKori-কে পরিষেবাগুলি ব্যবহার করে ওয়েবসাইটের
        ব্যবহারকারীদের ("ব্যবহারকারী") নির্দিষ্ট তথ্য সংগ্রহ এবং ব্যবহার করতে
        হবে এবং এই ধরনের তথ্য ক্যাপচার, স্টোরেজ এবং ট্রান্সমিশন জড়িত। এই
        গোপনীয়তা নীতি ("গোপনীয়তা নীতি"/ "নীতি") ব্যাখ্যা করে কিভাবে আমরা
        পরিষেবার ব্যবহারকারীদের ব্যক্তিগত তথ্য সংগ্রহ, ব্যবহার, ভাগ এবং সুরক্ষা
        করি (যৌথভাবে এবং পৃথকভাবে "আপনি", "আপনার", "নিজেকে" বা এই গোপনীয়তা
        নীতিতে "ব্যবহারকারী" বা "ব্যবহারকারী")। আমাদের পরিষেবাগুলির সাথে
        যোগাযোগকারী ব্যবহারকারীদের তথ্যের গোপনীয়তার প্রতি আমাদের স্থির
        প্রতিশ্রুতি নিশ্চিত করার জন্য আমরা এই গোপনীয়তা নীতি তৈরি করেছি। আপনার
        পরিষেবাগুলির ব্যবহার এবং অ্যাক্সেস এই গোপনীয়তা নীতি এবং আমাদের শর্তাবলী
        সাপেক্ষে৷ এই গোপনীয়তা নীতিতে ব্যবহৃত যেকোন ক্যাপিটালাইজড শব্দ, কিন্তু
        সংজ্ঞায়িত করা হয়নি, আমাদের শর্তাবলীতে এটির জন্য দায়ী অর্থ থাকবে।
        এখানে ব্যবহৃত শিরোনাম শুধুমাত্র গোপনীয়তা নীতির বিভিন্ন বিধান সাজানোর
        উদ্দেশ্যে। শিরোনামগুলি শুধুমাত্র রেফারেন্সের উদ্দেশ্যে এবং এর মধ্যে থাকা
        ধারাগুলির বিধানগুলিকে সীমাবদ্ধ বা প্রসারিত করার জন্য ব্যাখ্যা করা হবে
        না৷
      </p>
      <p>
        <strong>৮.১ । সংজ্ঞা</strong>
      </p>
      <p>
        এই গোপনীয়তা নীতিতে, যদি না প্রেক্ষাপট অন্যথায় প্রয়োজন হয়, সংজ্ঞায়িত
        পদগুলি নীচে তাদের জন্য নির্ধারিত অর্থ বহন করবে, এবং তাদের জ্ঞানীয়
        অভিব্যক্তিগুলি সেই অনুযায়ী বোঝানো হবে। ক) ডিজিটাল নিরাপত্তা আইন 2018-এর
        ধারা 26-এ দেওয়া "পরিচয় সংক্রান্ত তথ্য"-এর একই অর্থ হবে যা একজন
        প্রাকৃতিক ব্যক্তির সাথে সম্পর্কিত যে কোনও তথ্যকে বোঝায়, যা প্রত্যক্ষ বা
        পরোক্ষভাবে, উপলব্ধ বা হতে পারে এমন অন্যান্য তথ্যের সংমিশ্রণে। একটি বডি
        কর্পোরেট উপলব্ধ, এই ধরনের ব্যক্তি সনাক্ত করতে সক্ষম. খ) "পরিচয়
        সংক্রান্ত তথ্য" বলতে বোঝায় যে কোনো বাহ্যিক, জৈবিক বা শারীরিক তথ্য বা
        অন্য কোনো তথ্য যা এককভাবে বা যৌথভাবে কোনো ব্যক্তি বা সিস্টেমকে চিহ্নিত
        করতে পারে, যেমন নাম, ছবি, ঠিকানা, জন্ম তারিখ, মায়ের নাম, পিতার নাম,
        স্বাক্ষর। , জাতীয় পরিচয়পত্র, জন্ম ও মৃত্যু নিবন্ধন নম্বর, আঙুলের ছাপ,
        পাসপোর্ট নম্বর, ব্যাঙ্ক অ্যাকাউন্ট নম্বর, ড্রাইভিং লাইসেন্স, ই-টিআইএন
        নম্বর, ইলেকট্রনিক বা ডিজিটাল স্বাক্ষর, ব্যবহারকারীর নাম, ক্রেডিট বা
        ডেবিট কার্ড নম্বর, ভয়েস প্রিন্ট, রেটিনার ছবি, আইরিস ইমেজ , ডিএনএ
        প্রোফাইল, নিরাপত্তা সংক্রান্ত প্রশ্ন বা অন্য কোনো শনাক্তকরণ যা অগ্রিম
        প্রযুক্তির জন্য উপলব্ধ। গ) “আপনি”, “আপনার”, “নিজেকে” এবং “ব্যবহারকারী”
        বলতে বোঝায় প্রাকৃতিক ও আইনি ব্যক্তি এবং আইনি সত্তা/কোম্পানী যারা
        পরিদর্শন করে এবং/অথবা পরিষেবাগুলি ব্যবহার করে এবং সেই
        ব্যক্তি/সত্তা/কোম্পানীগুলিকেও অন্তর্ভুক্ত করবে যারা অন্য কোন ব্যক্তির
        দ্বারা বিশদ জমা দিয়ে পরিষেবাগুলি গ্রহণ করুন ঘ)"তৃতীয় পক্ষ" বলতে
        ব্যবহারকারী এবং আমাদের ছাড়া যেকোনো ওয়েবসাইট/অ্যাপ্লিকেশন/ওয়েব
        পোর্টাল, কোম্পানি বা ব্যক্তিকে বোঝায়। ঙ)"পরিষেবা" বলতে বোঝাবে ওয়েবসাইট
        (https://AyyKori.net/) এবং মোবাইল অ্যাপ্লিকেশন (AyyKori) এবং ই-মেইল,
        এসএমএস সহ কিন্তু সীমাবদ্ধ নয়, বিভিন্ন যোগাযোগ চ্যানেলের মাধ্যমে
        ব্যবহারকারীদের কাছ থেকে প্রেরিত/প্রাপ্ত প্রাসঙ্গিক তথ্য। হোয়াটসঅ্যাপ,
        ফোন কল, ওয়েবসাইট চ্যাট, আইভিআর। আমরা প্রাথমিকভাবে ব্যবহারকারীদের এই
        ধরনের ব্যবহারকারীদের নেটওয়ার্কে বিভিন্ন পণ্যের জন্য কাস্টমাইজড
        লিঙ্কগুলি ভাগ করার অনুমতি দেওয়ার ব্যবসায় নিযুক্ত আছি, কাস্টমাইজ করা
        লিঙ্কগুলি ই-কমার্স ওয়েবসাইট বা ব্র্যান্ড অংশীদারদের কাছে বিক্রয় চালায়
        এবং এর ফলে ব্যবহারকারীরা মুনাফা অর্জন করে। আমরা বর্তমানে AyyKori
        ব্র্যান্ড নামে কাজ করি। চ) "ব্যবহারকারীর তথ্য" অর্থ ব্যক্তিগত তথ্য এবং
        সংবেদনশীল ব্যক্তিগত তথ্য বা তথ্য। ছ) “ওয়েবসাইট” এর অর্থ হবে এবং
        https://AyyKori.net/-কে উল্লেখ করবে, “অ্যাপ্লিকেশন” এবং/ বা “অ্যাপ” বলতে
        Android প্লে স্টোর বা iOS অ্যাপ স্টোরে উপলব্ধ AyyKori মোবাইল
        অ্যাপ্লিকেশনকে বোঝাবে। এগুলিকে সম্মিলিতভাবে "প্ল্যাটফর্ম" হিসাবে উল্লেখ
        করা হবে
      </p>
      ৮.৩। সাধারণ: aক) ব্যবহারকারী দ্ব্যর্থহীনভাবে সম্মত হন যে এই নীতি এবং উপরে
      উল্লিখিত শর্তাদি ব্যবহারকারী এবং AyyKori-এর মধ্যে একটি আইনগতভাবে
      বাধ্যতামূলক চুক্তি গঠন করে এবং ব্যবহারকারী যে কোনও পরিষেবার জন্য প্রযোজ্য
      নিয়ম, নির্দেশিকা, নীতি, শর্তাবলীর অধীন থাকবে পরিষেবাগুলি সহ AyyKori
      দ্বারা সরবরাহ করা হয়, এবং এটিকে শর্তাদি এবং শর্তাবলীতে অন্তর্ভুক্ত বলে
      গণ্য করা হবে এবং এটির অংশ হিসাবে বিবেচিত হবে৷ খ) তথ্য ও যোগাযোগ প্রযুক্তি
      আইন, 2006 এবং তথ্য ও যোগাযোগ প্রযুক্তি আইন, 2006 দ্বারা সংশোধিত বিভিন্ন
      বিধিতে ইলেকট্রনিক রেকর্ড সম্পর্কিত সংশোধিত বিধান এবং প্রযোজ্য নিয়মের
      অধীনে এই নথিটি একটি ইলেকট্রনিক রেকর্ড। এই ইলেকট্রনিক রেকর্ড একটি কম্পিউটার
      সিস্টেম দ্বারা উত্পন্ন হয় এবং কোন শারীরিক বা ডিজিটাল স্বাক্ষর প্রয়োজন
      হয় না. আরও, এই নথিটি প্রাসঙ্গিক নির্দেশিকাগুলির বিধান অনুসারে প্রকাশিত
      হয়। গ) 'পার্টি' এবং 'পার্টি' শব্দগুলি যথাক্রমে ব্যবহারকারী এবং AyyKori কে
      পৃথকভাবে এবং সমষ্টিগতভাবে উল্লেখ করার জন্য ব্যবহার করা হবে, প্রেক্ষাপটের
      প্রয়োজন অনুসারে। ঘ) এই নীতির প্রতিটি বিভাগের শিরোনামগুলি শুধুমাত্র এই
      নীতির অধীনে বিভিন্ন বিধানগুলিকে সুশৃঙ্খলভাবে সংগঠিত করার উদ্দেশ্যে এবং
      এখানে থাকা বিধানগুলিকে কোনও উপায়ে ব্যাখ্যা করতে উভয় পক্ষই ব্যবহার করবে
      না৷ আরও, এটি বিশেষভাবে পক্ষগুলি দ্বারা সম্মত হয় যে শিরোনামগুলির কোনও আইনি
      বা চুক্তিভিত্তিক মূল্য থাকবে না। ঙ) পক্ষগুলি স্পষ্টভাবে সম্মত হয় যে এই
      নথির ধারা 2 এর সাপেক্ষে, AyyKori সর্বোত্তম অনুশীলন এবং আইনের কথা মাথায়
      রেখে ব্যবহারকারীকে কোনো পূর্বানুমতি বা সূচনা ছাড়াই নীতি এবং পূর্বোক্ত
      শর্তাবলী সংশোধন বা পরিবর্তন করার একমাত্র এবং একচেটিয়া অধিকার বজায় রাখে।
      বাংলাদেশ সরকার কর্তৃক নির্ধারিত, এবং ব্যবহারকারী স্পষ্টভাবে সম্মত হন যে এই
      ধরনের কোনো সংশোধন বা পরিবর্তন অবিলম্বে কার্যকর হবে। ব্যবহারকারীর দায়িত্ব
      রয়েছে পর্যায়ক্রমে নীতি এবং শর্তাবলী পরীক্ষা করা এবং তাদের বিধান এবং
      প্রয়োজনীয়তা সম্পর্কে আপডেট থাকা। যদি ব্যবহারকারী এই ধরনের পরিবর্তনের পরে
      পরিষেবাগুলি ব্যবহার করা চালিয়ে যান, তবে ব্যবহারকারী নীতি এবং শর্তাবলীতে
      করা যেকোনো এবং সমস্ত সংশোধন/পরিবর্ধনে সম্মতি দিয়েছেন বলে মনে করা হবে।
      যতদূর পর্যন্ত ব্যবহারকারী নীতি এবং শর্তাদি মেনে চলে, তাকে পরিষেবাগুলিতে
      প্রবেশ, অ্যাক্সেস এবং ব্যবহার করার জন্য একটি ব্যক্তিগত, অ-এক্সক্লুসিভ,
      অ-হস্তান্তরযোগ্য, প্রত্যাহারযোগ্য, সীমিত বিশেষাধিকার দেওয়া হয়। ৮.৪
      ব্যক্তিগত তথ্য সংগ্রহ এবং পরিচালনা পক্ষগুলির গোপনীয়তা আমাদের কাছে প্রধান
      গুরুত্বপূর্ণ এবং সমস্ত পরিষেবা কঠোরভাবে বাংলাদেশ সরকার কর্তৃক সংজ্ঞায়িত
      আইনের এখতিয়ারের মধ্যে ডিজাইন করা হয়েছে। সাধারণত, পরিষেবাগুলির জন্য আপনি
      কে তা আমাদের জানার প্রয়োজন যাতে আমরা আপনার চাহিদাগুলি সর্বোত্তমভাবে পূরণ
      করতে পারি৷ আপনি যখন পরিষেবাগুলি অ্যাক্সেস করেন, আমরা আপনাকে স্বেচ্ছায়
      আমাদেরকে কিছু তথ্য প্রদান করতে বলতে পারি যা আপনাকে ব্যক্তিগতভাবে সনাক্ত
      করে বা আপনাকে ব্যক্তিগতভাবে সনাক্ত করতে ব্যবহার করা যেতে পারে। উপরোক্ত
      সাধারণত্বের প্রতি পূর্বাভাস না রেখে, আপনার কাছ থেকে আমাদের দ্বারা সংগৃহীত
      তথ্যের মধ্যে নিম্নলিখিতগুলি অন্তর্ভুক্ত থাকতে পারে (কিন্তু এতে সীমাবদ্ধ
      নয়): যোগাযোগের ডেটা (যেমন আপনার ইমেল ঠিকানা এবং ফোন নম্বর); ব্যবহারকারীর
      নাম এবং পাসওয়ার্ড; ডেমোগ্রাফিক ডেটা (যেমন আপনার নাম, লিঙ্গ, বয়স, আপনার
      জন্ম তারিখ এবং আপনার পিন কোড); আপনার পরিষেবাগুলির ব্যবহার সম্পর্কিত ডেটা
      এবং পরিষেবাগুলি ব্যবহারের মাধ্যমে আপনার দ্বারা বা আপনার সাথে করা অন্যান্য
      লেনদেন; AyyKori মোবাইল ডিভাইস, ওয়েব ব্রাউজার, ওয়েব এবং মোবাইল ব্রাউজিং
      প্যাটার্ন, ব্র্যান্ড পার্টনার পছন্দগুলিতে এবং থেকে আপনার ক্লিক সম্পর্কে
      তথ্য আপনার ব্যাঙ্ক অ্যাকাউন্টের তথ্য সহ ব্যাঙ্ক অ্যাকাউন্টের নাম,
      অ্যাকাউন্ট নম্বর, রাউটিং নম্বর, ব্যাঙ্ক শাখা বা অন্য কোনও
      পেমেন্ট-সম্পর্কিত তথ্য অন্য যেকোন তথ্য যা আপনি স্বেচ্ছায় আমাদের প্রদান
      করতে চান (যেমন ইমেল, কল বা চিঠির মাধ্যমে আপনার দ্বারা শেয়ার করা তথ্য,
      আপনার কাজের বিবরণ, বাড়ি/কাজের ঠিকানা, আপনার পরিবারের বিবরণ, ই-কমার্স
      সাইটে করা লেনদেনের বিবরণ, লেনদেনের স্ক্রিনশট, লেনদেনের জন্য অর্ডার আইডি,
      বিকল্প নম্বর এবং ইমেল এবং সময়ে সময়ে প্রদত্ত অন্যান্য তথ্য)। আমাদের
      দ্বারা আপনার কাছ থেকে সংগৃহীত তথ্য বাংলাদেশের ডিজিটাল নিরাপত্তা আইনের
      অধীনে 'ব্যক্তিগত তথ্য' গঠন করবে। ৮.৫। গোপনীয়তা বিবৃতি ব্যবহারকারী
      স্পষ্টভাবে সম্মত হন এবং স্বীকার করেন ক) তথ্য যা পাবলিক ডোমেনে অবাধে পাওয়া
      যায় বা তথ্য অধিকার আইন, 2009 বা অন্য কোনো আইনের অধীনে অ্যাক্সেসযোগ্য এই
      নীতির উদ্দেশ্যে 'ব্যক্তিগত তথ্য' হিসাবে বিবেচিত হবে না। যে AyyKori
      স্বয়ংক্রিয়ভাবে ব্যবহারকারীর আইপি ঠিকানা এবং প্ল্যাটফর্মে ব্যবহারকারীর
      আচরণের উপর ভিত্তি করে ব্যবহারকারী সম্পর্কে তথ্য ট্র্যাক করতে পারে এবং
      ব্যবহারকারী স্পষ্টভাবে এতে সম্মতি দেয়। ব্যবহারকারী সচেতন যে এই তথ্যগুলি
      ব্যবহারকারীর জনসংখ্যা, আগ্রহ এবং আচরণের উপর অভ্যন্তরীণ গবেষণা পরিচালনা
      করতে, AyyKori কে আরও ভালভাবে বুঝতে এবং ব্যবহারকারীদের স্বার্থ পূরণ করতে
      সক্ষম করতে ব্যবহার করা যেতে পারে। উপরন্তু, ব্যবহারকারীকে স্পষ্টভাবে সচেতন
      করা হয় যে এই ধরনের তথ্য ব্যবহারকারীর কম্পিউটার এবং ওয়েব ব্রাউজারের তথ্য,
      ব্যবহারকারীর আইপি ঠিকানা, মোবাইল ডিভাইসের বিবরণ ইত্যাদি অন্তর্ভুক্ত থাকতে
      পারে। ব্যবহারকারীর আইপি ঠিকানা এবং ব্যবহারকারীর ব্যক্তিগতভাবে শনাক্তযোগ্য
      তথ্যের মধ্যে যোগসূত্র তৃতীয়টির সাথে শেয়ার করা বা প্রকাশ করা যেতে পারে।
      আপনাকে পরিষেবার বিধানগুলি সহজতর করার জন্য দলগুলি৷ ব্যবহারকারী এতদ্বারা এই
      ধরনের তথ্য তৃতীয় পক্ষের সাথে ভাগ করে নিতে সম্মতি দেয় যা সময়ে সময়ে
      AyyKori দ্বারা নির্ধারিত হতে পারে। আরও, আমরা বাজার গবেষণা এবং নতুন
      বৈশিষ্ট্য বিকাশের জন্য তৃতীয় পক্ষের সাথে বেনামী আকারে (অর্থাৎ,
      ব্যক্তিগতভাবে সনাক্তযোগ্য নয়) কিছু সমষ্টিগত ফলাফল (নির্দিষ্ট ডেটা নয়)
      ভাগ করতে এবং প্রকাশ করতে পারি। খ) যে কোন এবং সমস্ত তথ্য AyyKori দ্বারা
      সংগৃহীত ব্যবহারকারীর সাথে সম্পর্কিত, ব্যবহারকারীর দ্বারা সরাসরি AyyKori কে
      প্রদান করা হোক বা না হোক, যার মধ্যে ব্যক্তিগত চিঠিপত্র যেমন ইমেল বা চিঠি
      বা এসএমএস বা হোয়াটসঅ্যাপ বা কল, অন্যান্য ব্যবহারকারীদের প্রতিক্রিয়া সহ
      কিন্তু সীমাবদ্ধ নয় ব্যবহারকারীর ক্রিয়াকলাপ বা প্ল্যাটফর্মে পোস্টিং
      ইত্যাদি সম্পর্কিত তৃতীয় পক্ষগুলি AyyKori দ্বারা একটি ফাইল/ফোল্ডারে সংগ্রহ
      এবং সংকলন করা যেতে পারে যা ব্যবহারকারীর জন্য বিশেষভাবে তৈরি/বরাদ্দ করা
      হয়েছে এবং ব্যবহারকারী এতদ্বারা স্পষ্টভাবে এতে সম্মতি দিয়েছেন। এছাড়াও,
      ওয়েবসাইটে আপনার কার্যকলাপ সম্পর্কে আপনাকে অবহিত রাখার জন্য আমরা মাঝে মাঝে
      আপনাকে ইমেল, এসএমএস, অ্যাপ বিজ্ঞপ্তি এবং অন্যান্য বিপণন যোগাযোগ পাঠাই।
      আপনি কত উপার্জন করেছেন তা দেখানোর জন্য আপনার লেনদেনের বার্তাগুলি, রেফারেল
      বার্তাগুলি যা আপনাকে দেখায় যে আপনি রেফারেলগুলি থেকে কত উপার্জন করেছেন,
      আপনাকে অর্থপ্রদানের জন্য অর্থপ্রদানের নিশ্চিতকরণ এবং, ওয়েবসাইটে আপনার
      কার্যকলাপ নিশ্চিত করার জন্য গুরুত্বপূর্ণ প্রশাসনিক বার্তা এবং বার্তাগুলি
      অন্তর্ভুক্ত করে৷ এই ইমেলগুলি আপনি ছাড়া অন্য কারো সাথে শেয়ার করা হয় না।
      এছাড়াও আমরা নিউজলেটার, এসএমএস, অ্যাপ বিজ্ঞপ্তি, ব্রাউজার বিজ্ঞপ্তি এবং
      অন্যান্য বিপণন পাঠাই যা আপনাকে আরও সঞ্চয় করতে সাহায্য করার জন্য আমাদের
      সেরা ধারণাগুলির বৈশিষ্ট্য রয়েছে। আপনি যে কোনো সময় আমাদের জানিয়ে AyyKori
      থেকে এই বিপণন যোগাযোগ না পাওয়ার সিদ্ধান্ত নিতে পারেন। আমরা আমাদের
      সদস্যদের দ্বারা স্প্যামিং সমর্থন করি না এবং আমরা আমাদের শর্তাবলীতে এটিকে
      স্পষ্টভাবে নিষিদ্ধ করি। আপনি যদি স্প্যামিংয়ের একটি ঘটনা রিপোর্ট করতে চান,
      তাহলে অনুগ্রহ করে আমাদের সাথে যোগাযোগ করুন যাতে আমরা তদন্ত করতে পারি এবং
      উপযুক্ত ব্যবস্থা নিতে পারি। গ) যে AyyKori-কে প্রদত্ত যোগাযোগের তথ্য
      ব্যবহারকারীর অফার এবং প্রচারগুলি পাঠাতে ব্যবহার করা যেতে পারে,
      ব্যবহারকারীর পূর্বের আগ্রহের উপর ভিত্তি করে হোক বা না হোক, এবং ব্যবহারকারী
      এতদ্বারা স্পষ্টভাবে এটি গ্রহণ করতে সম্মত হন। ব্যবহারকারী এই ধরনের
      প্রচারমূলক যোগাযোগের শেষে দেওয়া 'আনসাবস্ক্রাইব' লিঙ্কে ক্লিক করে অথবা
      support@ayykori.net-এ আমাদের ইমেল করে প্রচারমূলক যোগাযোগ থেকে সদস্যতা
      ত্যাগ করতে বেছে নিতে পারেন। ঘ) AyyKori মাঝে মাঝে ব্যবহারকারীকে ঐচ্ছিক
      অনলাইন সমীক্ষা সম্পূর্ণ করার জন্য অনুরোধ করতে পারে। এই সমীক্ষার জন্য
      ব্যবহারকারীকে যোগাযোগের তথ্য এবং জনসংখ্যা সংক্রান্ত তথ্য (যেমন জিপ কোড,
      বয়স, আয় বন্ধনী, লিঙ্গ ইত্যাদি) প্রদান করতে হতে পারে। ব্যবহারকারী সচেতন
      যে এই তথ্যগুলি ব্যবহারকারীর সুবিধার জন্য পরিষেবাগুলিকে উন্নত/কাস্টমাইজ
      করার জন্য এবং প্ল্যাটফর্মের সমস্ত ব্যবহারকারীদের পরিষেবা প্রদান করার জন্য
      ব্যবহার করা হয় যা AyyKori বিশ্বাস করে যে তারা পেতে আগ্রহী হতে পারে। ঙ) যে
      AyyKori পরিষেবার প্রশাসন, গ্রাহক সহায়তা, গবেষণা এবং উন্নয়ন এবং
      ব্যবহারকারীদের আরও ভাল সহায়তার উদ্দেশ্যে সহায়তা বা অন্যান্য উদ্দেশ্যে
      গৃহীত এবং করা ইলেকট্রনিক যোগাযোগ এবং টেলিফোন কলগুলির রেকর্ড রাখতে পারে। যে
      AyyKori মাঝে মাঝে ব্যবহারকারীকে প্ল্যাটফর্ম থেকে ব্যবহারকারীর দ্বারা
      নেওয়া পরিষেবাগুলির জন্য পর্যালোচনা লিখতে অনুরোধ করতে পারে। ব্যবহারকারী
      সচেতন যে এই ধরনের পর্যালোচনাগুলি প্ল্যাটফর্মের সম্ভাব্য ব্যবহারকারীদের
      পরিষেবাগুলি পেতে সাহায্য করবে, এবং ব্যবহারকারী এতদ্বারা AyyKori কে
      স্পষ্টভাবে অনুমতি দেয় যে প্ল্যাটফর্মে ব্যবহারকারীর দ্বারা লিখিত যেকোনো
      এবং সমস্ত পর্যালোচনা প্রকাশ করার জন্য, ব্যবহারকারীর নাম এবং নির্দিষ্ট
      যোগাযোগের বিবরণ সহ, অন্যান্য ব্যবহারকারীদের সুবিধা এবং ব্যবহারের জন্য।
      এখানে থাকা কোন কিছুই AyyKori কে ব্যবহারকারীর দ্বারা জমা দেওয়া
      বিষয়বস্তু/পর্যালোচনা/জরিপ/প্রতিক্রিয়া সঞ্চয়, আপলোড, প্রকাশ বা প্রদর্শন
      করতে বাধ্য করে বলে গণ্য করা হবে না এবং ব্যবহারকারী এতদ্বারা AyyKori-কে
      প্ল্যাটফর্ম থেকে এই জাতীয় যেকোন বিষয়বস্তু সরানোর জন্য স্পষ্টভাবে অনুমোদন
      দিয়েছেন , পর্যালোচনা, সমীক্ষা, বা প্রতিক্রিয়া ব্যবহারকারীর দ্বারা জমা
      দেওয়া, কারণ ছাড়াই বা ব্যবহারকারীকে একই বিষয়ে অবহিত করার প্রয়োজন। চ)
      ডিজিটাল নিরাপত্তা আইন, 2018 অনুযায়ী সময়ে সময়ে সংশোধিত এবং সংশ্লিষ্ট
      নিয়ম অনুযায়ী ‘ব্যক্তিগত তথ্য’ তৈরি ও সংগ্রহের জন্য ব্যবহারকারীর স্পষ্ট
      সম্মতি প্রয়োজন। এই নীতিতে সম্মতি নিশ্চিত করার পাশাপাশি রেজিস্ট্রেশনের
      সময় "আমি শর্তাবলী এবং নীতির সাথে সম্মত" বোতামে ক্লিক করার মাধ্যমে,
      ব্যবহারকারী প্রযোজ্য আইনের অধীনে প্রয়োজনীয় এই জাতীয় প্রজন্ম এবং
      সংগ্রহের সম্মতি প্রদান করে। ছ) AyyKori-এ জমা দেওয়া তথ্যের যথার্থতা
      নিশ্চিত করার জন্য ব্যবহারকারী দায়ী। ব্যবহারকারী support@ayykori.net-এ
      ইমেলের মাধ্যমে AyyKori-এর সাথে যোগাযোগ করে ভুল সংশোধন করতে, মুছে ফেলতে বা
      তথ্য সংশোধন করতে পারে। AyyKori যত তাড়াতাড়ি যুক্তিসঙ্গতভাবে ব্যবহারযোগ্য
      তত তাড়াতাড়ি ডাটাবেসে অনুরোধকৃত পরিবর্তনগুলি করার জন্য সরল বিশ্বাসের
      প্রচেষ্টা চালাবে। যদি ব্যবহারকারী এমন কোনো তথ্য প্রদান করে যা অসত্য, ভুল,
      পুরানো বা অসম্পূর্ণ (বা অসত্য, ভুল, পুরানো বা অসম্পূর্ণ হয়ে যায়), অথবা
      AyyKori-এর কাছে সন্দেহ করার যুক্তিসঙ্গত কারণ থাকে যে ব্যবহারকারীর দেওয়া
      তথ্য অসত্য, ভুল, মেয়াদোত্তীর্ণ বা অসম্পূর্ণ, AyyKori, তার নিজস্ব বিবেচনার
      ভিত্তিতে, শর্তাদি এবং শর্তাবলীতে বর্ণিত বিধান অনুযায়ী আপনার পরিষেবার
      বিধান বন্ধ করতে পারে৷ এমন পরিস্থিতি হতে পারে যেখানে AyyKori আপনার
      ব্যক্তিগত ডেটা সংশোধন, মুছে বা আপডেট করবে না, সহ (a) যেখানে ব্যক্তিগত ডেটা
      হল মতামত ডেটা যা শুধুমাত্র একটি মূল্যায়নের উদ্দেশ্যে রাখা হয়; এবং (খ)
      ব্যক্তিগত ডেটা প্রসিকিউশন সম্পর্কিত নথিতে থাকে যদি প্রসিকিউশন সংক্রান্ত
      সমস্ত প্রক্রিয়া সম্পন্ন না হয়। জ) সংবেদনশীল ব্যক্তিগত ডেটা বা তথ্য সহ
      ব্যবহারকারীর দ্বারা আমাদের দেওয়া সমস্ত তথ্য স্বেচ্ছায়। এই গোপনীয়তা
      নীতির শর্তাবলী এবং এই ধরনের ব্যবহারকারীর জন্য প্রযোজ্য শর্তাবলী অনুসারে
      ব্যবহারকারীর যে কোনো সময় তার সম্মতি প্রত্যাহার করার অধিকার রয়েছে, তবে
      এটি স্পষ্ট করা হচ্ছে যে সম্মতি প্রত্যাহার পূর্ববর্তী হবে না। যদি
      ব্যবহারকারী তার/তার অ্যাকাউন্ট মুছে ফেলতে চান বা অনুরোধ করেন যে AyyKori আর
      পরিষেবা প্রদানের জন্য ব্যবহারকারীর তথ্য ব্যবহার করবে না, ব্যবহারকারী
      support@ayykori.net-এ AyyKori এর সাথে যোগাযোগ করতে পারেন। যে উদ্দেশ্যে
      তথ্য আইনত ব্যবহার করা যেতে পারে বা আপাতত বলবৎ অন্য কোনো আইনের অধীনে
      প্রয়োজন হয় তার চেয়ে বেশি সময় ধরে আমরা এই ধরনের তথ্য সংরক্ষণ করব না।
      কিছু সময়ের পরে, আপনার ডেটা বেনামী এবং একত্রিত হতে পারে, এবং তারপরে আমাদের
      পরিষেবাগুলি কার্যকরভাবে সরবরাহ করার জন্য যতক্ষণ প্রয়োজন ততক্ষণ আমাদের
      দ্বারা আটকে রাখা যেতে পারে, তবে বেনামী ডেটার আমাদের ব্যবহার কেবলমাত্র
      বিশ্লেষণমূলক উদ্দেশ্যে হবে। অনুগ্রহ করে মনে রাখবেন যে আপনার সম্মতি
      প্রত্যাহার বা অ্যাকাউন্ট বাতিল করার ফলে AyyKori আপনাকে এর পরিষেবাগুলি
      প্রদান করতে অক্ষম হতে পারে বা আপনার সাথে AyyKori যে কোনো বিদ্যমান সম্পর্ক
      বন্ধ করতে পারে। ঝ) আপনি যদি পরিষেবা সম্পর্কিত প্রচারমূলক এবং
      বিপণন-সম্পর্কিত তথ্যের মতো অপ্রয়োজনীয় যোগাযোগগুলি গ্রহণ করা থেকে অপ্ট
      আউট করতে চান, তাহলে অনুগ্রহ করে support@ayykori.net-এ আমাদের একটি ইমেল
      পাঠান৷ ৮.৬। আপনার তথ্য আমাদের ব্যবহার ব্যক্তিগত তথ্য বা যেকোনো সংবেদনশীল
      ব্যক্তিগত ডেটা বা তথ্য সহ ব্যবহারকারীর দ্বারা AyyKori-কে দেওয়া সমস্ত তথ্য
      স্বেচ্ছায়। এই ধরনের তথ্য এর মূল আকারে যেকোন তৃতীয় পক্ষের সাথে শেয়ার করা
      যেতে পারে ব্যবহারকারীর সম্মতির জন্য এখানে দেওয়া হয়েছে। আপনি বুঝতে
      পেরেছেন যে AyyKori আপনার কিছু তথ্য ব্যবহার করতে পারে, যা নিম্নলিখিত
      উদ্দেশ্যে ব্যক্তিগত তথ্য হিসাবে মনোনীত করা হয়েছে: আপনাকে সেবা প্রদান;
      পণ্য এবং পরিষেবার প্রতিক্রিয়া গ্রহণ; নতুন পণ্য বা পরিষেবা এবং
      পরিষেবাগুলির বিপণনের জন্য; পণ্যের নকশা এবং উপযোগিতা উন্নত করার জন্য
      সফ্টওয়্যার ব্যবহারের ধরণ বিশ্লেষণের জন্য; অফার এবং অভ্যন্তরীণ রেকর্ডের
      জন্য সতর্কতা/অনুস্মারক/এসএমএস তৈরির পরিষেবা প্রদানের জন্য। বাণিজ্যিক
      উদ্দেশ্যে এবং গবেষণা, পরিসংখ্যানগত বিশ্লেষণ এবং ব্যবসায়িক বুদ্ধিমত্তার
      উদ্দেশ্যে সমষ্টিগত বা অ-ব্যক্তিগতভাবে সনাক্তযোগ্য আকারে, এই ধরনের গবেষণা,
      পরিসংখ্যান বা গোয়েন্দা তথ্য বিক্রি বা হস্তান্তরের জন্য একটি
      অ-ব্যক্তিগতভাবে শনাক্তযোগ্য আকারে তৃতীয় পক্ষ এবং সহযোগীদের কাছে; গ্রাহক
      সমর্থন-সম্পর্কিত সমস্যাগুলি ডিবাগ করা; এবং আমরা আপনার ট্র্যাকিং তথ্য যেমন
      আইপি ঠিকানা, এবং ডিভাইস আইডি ব্যবহার করতে পারি আপনাকে শনাক্ত করতে এবং
      বিস্তৃত জনসংখ্যার তথ্য সংগ্রহ করতে। যদি আমরা অন্য কোম্পানির দ্বারা অর্জিত
      বা একত্রিত হয়ে থাকি, আমরা আপনার দ্বারা প্রকাশিত তথ্য এবং আপনার সম্পর্কে
      তথ্য আমরা যে কোম্পানির দ্বারা অর্জিত বা একত্রিত হয়েছি তার কাছে হস্তান্তর
      করব এবং এই ধরনের কোম্পানির ব্যবহারকারীর ব্যক্তিগত তথ্য ব্যবহার করা চালিয়ে
      যাওয়ার অধিকার থাকবে এবং / অথবা অন্যান্য তথ্য যা একজন ব্যবহারকারী আমাদের
      প্রদান করে। ব্যবহারকারীরা স্পষ্টভাবে সম্মত হন এবং স্বীকার করেন যে AyyKori
      ব্যবহারকারীর ব্যক্তিগত তথ্য এবং/অথবা সংবেদনশীল ব্যক্তিগত তথ্য একটি
      সুরক্ষিত ক্লাউড-ভিত্তিক প্ল্যাটফর্মে সংগ্রহ করে এবং সংরক্ষণ করে যা
      ব্যবহারকারীর দ্বারা সময়ে সময়ে প্ল্যাটফর্মে বা অন্যান্য পরিষেবা ব্যবহার
      করার সময় প্রদান করা হয়। ব্যবহারকারী সচেতন যে এই তথ্যটি AyyKori তার
      পরিষেবাগুলি সরবরাহ করতে এবং প্ল্যাটফর্মের অভিজ্ঞতাকে আরও নিরাপদ এবং সহজে
      কাস্টমাইজ/উন্নত করতে সাহায্য করবে তবে ব্যবহারকারীর স্পষ্ট সম্মতি ব্যতীত
      কোনও পরিস্থিতিতে কোনও তৃতীয় পক্ষের সাথে ব্যক্তিগতভাবে শনাক্তযোগ্য তথ্য
      ভাগ করা হবে না যদি না নির্দেশিত হয় আইন. AyyKori-কে এই গোপনীয়তা নীতিতে
      উল্লিখিত উদ্দেশ্যে নিম্নলিখিত তৃতীয় পক্ষের কাছে ব্যবহারকারীর ব্যক্তিগত
      তথ্য প্রকাশ/স্থানান্তর করতে হতে পারে এবং এই ধরনের ব্যবহারকারীর ক্ষেত্রে
      প্রযোজ্য শর্তাবলী: প্রয়োজনীয় পরিমাণে সরকারি প্রতিষ্ঠান/কর্তৃপক্ষের কাছে:
      আইন, বিধি, এবং প্রবিধান এবং/অথবা কোনো প্রাসঙ্গিক বিচার বিভাগীয় বা
      আধা-বিচারিক কর্তৃপক্ষের আদেশের অধীনে; AyyKori এর অধিকার বা সম্পত্তি রক্ষা
      এবং রক্ষা করা; জালিয়াতি এবং ঋণ ঝুঁকির বিরুদ্ধে লড়াই করা; ব্যবহারকারীদের
      জন্য প্রযোজ্য AyyKori-এর নিয়ম ও শর্তাবলী প্রয়োগ করতে; বা যখন AyyKori,
      তার নিজস্ব বিবেচনার ভিত্তিতে, তার অধিকার বা অন্যদের অধিকার রক্ষা করার জন্য
      এটি প্রয়োজনীয় বলে মনে করে। অন্যথায় পরিচয় যাচাইয়ের উদ্দেশ্যে, বা
      সাইবার ঘটনা, প্রসিকিউশন এবং অপরাধের শাস্তি সহ প্রতিরোধ, সনাক্তকরণ, তদন্তের
      উদ্দেশ্যে সরকারী সংস্থাগুলির অনুসন্ধানের প্রতিক্রিয়া সহ আপাতত বলবৎ কোন
      আইনের অধীনে আদেশের দ্বারা প্রয়োজন হয়। যাইহোক, আমরা ইন্টারনেট জুড়ে এবং
      কখনও কখনও এই সাইটে আমাদের পক্ষ থেকে বিজ্ঞাপন পরিবেশনের জন্য তৃতীয় পক্ষের
      সাথে চুক্তি করি। তারা আমাদের ওয়েবসাইটে আপনার পরিদর্শন এবং আমাদের পণ্য এবং
      পরিষেবাগুলির সাথে আপনার মিথস্ক্রিয়া সম্পর্কে তথ্য সংগ্রহ করতে পারে। তারা
      পণ্য এবং পরিষেবার বিজ্ঞাপন লক্ষ্য করার জন্য এটি এবং অন্যান্য
      ওয়েবসাইটগুলিতে আপনার পরিদর্শন সম্পর্কে তথ্য ব্যবহার করতে পারে। এই
      তথ্যগুলি একটি পিক্সেল ট্যাগ ব্যবহারের মাধ্যমে সংগ্রহ করা হয়, যা বেশিরভাগ
      প্রধান ওয়েবসাইট দ্বারা ব্যবহৃত শিল্পের মানক প্রযুক্তি। এই ধরনের তৃতীয়
      পক্ষগুলিকে এই প্রক্রিয়ার অংশ হিসাবে আপনার ব্যক্তিগতভাবে সনাক্তযোগ্য তথ্য
      বিক্রি বা ভাগ করার অনুমতি নেই। Google, Facebook, বিজ্ঞাপন প্ল্যাটফর্ম,
      CleverTap-এর মতো পুনঃবিপণন প্ল্যাটফর্ম এবং Freshworks & Exotel-এর মতো
      গ্রাহক ক্যোয়ারী ম্যানেজমেন্ট প্ল্যাটফর্মগুলি সহ নিম্নলিখিত তৃতীয়-পক্ষের
      বিক্রেতারা একটি ওয়েবসাইটে ব্যবহারকারীর পূর্বে ভিজিটের ভিত্তিতে বিজ্ঞাপন
      পরিবেশন করতে কুকি ব্যবহার করে। Google-এর DoubleClick কুকির ব্যবহার এটি এবং
      এর অংশীদারদের এই সাইট এবং/অথবা ইন্টারনেটে অন্যান্য সাইটগুলিতে তাদের
      পরিদর্শনের ভিত্তিতে ব্যবহারকারীদের বিজ্ঞাপন পরিবেশন করতে সক্ষম করে৷
      ব্যবহারকারীরা বিজ্ঞাপন সেটিংসে গিয়ে আগ্রহ-ভিত্তিক বিজ্ঞাপনের জন্য
      DoubleClick কুকির ব্যবহার অপ্ট-আউট করতে পারেন৷ ৮.৭ । গোপনীয়তা এবং
      নিরাপত্তা ৮.৭।১ আপনার তথ্য গোপনীয় হিসাবে বিবেচিত হয় এবং তাই কোন তৃতীয়
      পক্ষের কাছে প্রকাশ করা হবে না, যদি না এখানে প্রদান করা হয় এবং যদি না আইনত
      যথাযথ কর্তৃপক্ষের কাছে এটি করার প্রয়োজন হয়, বা প্রয়োজনে, প্ল্যাটফর্মের
      মাধ্যমে পরিষেবা প্রদানের জন্য। ৭.২| আপনার ব্যক্তিগত তথ্য/সংবেদনশীল
      ব্যক্তিগত ডেটা আমাদের দ্বারা ইলেকট্রনিক আকারে আমাদের সরঞ্জামে এবং আমাদের
      কর্মচারীদের সরঞ্জামগুলিতে রক্ষণাবেক্ষণ করা হয়। এই ধরনের তথ্য সময়ে সময়ে
      শারীরিক আকারে রূপান্তরিত হতে পারে। ৮.৭.৩। যারা আপনার ব্যক্তিগত তথ্য
      অ্যাক্সেস করতে পারে: ব্যবহারকারীর তথ্য আমাদের কর্মচারী, অনুমোদিত কর্মী,
      বিপণন সংস্থা বা এজেন্টদের দ্বারা প্রক্রিয়া করা হবে, আমাদের দ্বারা
      ব্যবহারকারীর তথ্য সংগ্রহ করা হয়েছে এমন নির্দিষ্ট উদ্দেশ্যগুলির উপর নির্ভর
      করে, জানার প্রয়োজনের ভিত্তিতে। AyyKori, তাই, প্রাসঙ্গিক স্টেকহোল্ডারদের
      কাছে এই ধরনের সমস্ত রেকর্ড বজায় রাখতে এবং জমা দিতে পারে। ৮.৭.৪। নিরাপত্তা
      অনুশীলন. আমরা ডেটাকে একটি সম্পদ হিসাবে বিবেচনা করি যা অবশ্যই ক্ষতি এবং
      অননুমোদিত অ্যাক্সেস থেকে সুরক্ষিত থাকতে হবে। AyyKori-এর ভিতরে এবং বাইরের
      সদস্যদের অননুমোদিত অ্যাক্সেস থেকে এই ধরনের ডেটা রক্ষা করার জন্য আমরা
      বিভিন্ন নিরাপত্তা কৌশল ব্যবহার করি। আমাদের কাছে জমা দেওয়া ব্যবহারকারীর
      তথ্য এবং ম্যানেজারিয়াল, টেকনিক্যাল, অপারেশনাল এবং ফিজিক্যাল সিকিউরিটি
      কন্ট্রোল ব্যবস্থা সহ আমরা অ্যাক্সেস করেছি এমন তথ্য সুরক্ষার জন্য আমরা
      সাধারণত গৃহীত শিল্প মানগুলি অনুসরণ করি। যাইহোক, ব্যবহারকারীর ইলেকট্রনিক
      ডিভাইসগুলিতে অননুমোদিত অ্যাক্সেসের কারণে যেকোন ডেটা হারানো বা চুরির জন্য,
      যার মাধ্যমে ব্যবহারকারী পরিষেবাগুলি গ্রহণ করে, ব্যবহারকারীর দ্বারা যে কোনও
      ক্ষতির জন্য আমরা দায়ী থাকব না। ৮.৭.৫। আমরা আপনার কাছ থেকে নেওয়ার আশা
      করি: আপনার ব্যবহারকারীর তথ্য নিরাপদ ও সুরক্ষিত রাখতে আপনার ভূমিকা পালন করা
      গুরুত্বপূর্ণ। একটি অনলাইন অ্যাকাউন্টের জন্য সাইন আপ করার সময়, অনুগ্রহ করে
      একটি অ্যাকাউন্টের পাসওয়ার্ড চয়ন করতে ভুলবেন না যা অন্যদের অনুমান করা
      কঠিন হবে এবং অন্য কারো কাছে আপনার পাসওয়ার্ড প্রকাশ করবেন না। আপনি এই
      পাসওয়ার্ড গোপন রাখার জন্য এবং আপনার অ্যাকাউন্টের যেকোনো ব্যবহারের জন্য
      দায়ী। আপনি যদি একটি শেয়ার্ড বা সর্বজনীন কম্পিউটার ব্যবহার করেন, তাহলে
      কখনই আপনার লগইন আইডি/ইমেল ঠিকানা বা পাসওয়ার্ড মনে রাখা বাছাই করবেন না এবং
      প্রতিবার কম্পিউটার ছেড়ে যাওয়ার সময় আপনার অ্যাকাউন্ট থেকে লগ আউট করতে
      ভুলবেন না। আমাদের প্ল্যাটফর্মে আমরা আপনাকে যে গোপনীয়তা সেটিংস বা
      নিয়ন্ত্রণ সরবরাহ করি তাও আপনার ব্যবহার করা উচিত। ৮.৭.৬। ব্যবহারকারীর
      অ্যাকাউন্টের অননুমোদিত ব্যবহার। আপনার অ্যাকাউন্ট এবং পাসওয়ার্ডের কোনো
      অননুমোদিত ব্যবহারের জন্য আমরা কোনো দায়বদ্ধতা গ্রহণ করি না। আপনি যদি আপনার
      অ্যাকাউন্টের কোনো অননুমোদিত ব্যবহার সন্দেহ করেন, তাহলে আপনাকে অবিলম্বে
      support@ayykori.net-এ একটি ইমেল পাঠিয়ে আমাদের অবহিত করতে হবে। ৮.৭.৭। উপরে
      থাকা সত্ত্বেও, AyyKori এই ধরনের তৃতীয় পক্ষের সাথে আমাদের চুক্তির সুযোগের
      বাইরে তৃতীয় পক্ষের দ্বারা আপনার ব্যক্তিগত তথ্যের গোপনীয়তা, নিরাপত্তা বা
      বিতরণের জন্য দায়ী নয়। অধিকন্তু, AyyKori নিরাপত্তা লঙ্ঘনের জন্য বা কোনো
      তৃতীয় পক্ষের কোনো ক্রিয়াকলাপের জন্য দায়ী থাকবে না বা AyyKori-এর
      যুক্তিসঙ্গত নিয়ন্ত্রণের বাইরে এমন কোনো ইভেন্টের জন্য দায়ী থাকবে না যা
      সরকার, কম্পিউটার হ্যাকিং, কম্পিউটার ডেটা এবং স্টোরেজের অননুমোদিত অ্যাক্সেস
      সহ কিন্তু সীমাবদ্ধ নয়। ডিভাইস, কম্পিউটার ক্র্যাশ, নিরাপত্তা লঙ্ঘন এবং
      এনক্রিপশন। ৮.৮। আপনার ব্যক্তিগত তথ্য ধারণ প্রযোজ্য আইন অনুসারে, আমরা
      ব্যবহারকারীর তথ্য ব্যবহার করব যতক্ষণের জন্য এই ধরনের ব্যবহারকারীর তথ্য
      সংগ্রহ করা হয়েছে (উপরে ধারা -এ বর্ণিত) অথবা প্রযোজ্য আইনি প্রয়োজনীয়তা
      মেনে চলার জন্য। ৮.৯। তোমার অধিকারগুলো ৮.৯.১। ব্যক্তিগত তথ্য অ্যাক্সেস.
      আপনার কাছে থাকা তথ্যের একটি ফিজিক্যাল বা ইলেকট্রনিক কপি অ্যাক্সেস করার,
      পর্যালোচনা করার এবং অনুরোধ করার অধিকার আপনার আছে। আপনার ব্যক্তিগত তথ্যের
      উৎস সম্পর্কে তথ্যের অনুরোধ করার অধিকারও রয়েছে। ৮.৯.২। অতিরিক্ত অধিকার
      (যেমন পরিবর্তন, ব্যক্তিগত ডেটা মুছে ফেলা)। যেখানে আইন দ্বারা প্রদত্ত, আপনি
      (i) আপনার ব্যবহারকারীর তথ্যের বহনযোগ্যতা, সংশোধন বা সংশোধনের অনুরোধ করতে
      পারেন; (ii) আপনার ব্যক্তিগত ডেটার ব্যবহার এবং প্রকাশ সীমিত করুন; এবং (iii)
      আমাদের যেকোন ডেটা প্রসেসিং কার্যক্রমের সম্মতি প্রত্যাহার করুন। তবে শর্ত
      থাকে যে, আমাদের আইনি বা চুক্তিভিত্তিক বাধ্যবাধকতাগুলি পূরণ করার জন্য, আপনি
      মুছে ফেলার অনুরোধ করার পরে আমাদের আপনার কিছু ব্যবহারকারীর তথ্য ধরে রাখতে
      হবে। আমাদের ব্যবসার প্রয়োজন মেটানোর জন্য আপনার কিছু ব্যবহারকারীর তথ্য ধরে
      রাখার জন্য প্রযোজ্য আইন দ্বারাও আমরা অনুমতি পেতে পারি। ৮.১0। শিশু এবং
      নাবালকের গোপনীয়তা আমরা দৃঢ়ভাবে পিতামাতা এবং অভিভাবকদের তাদের
      অপ্রাপ্তবয়স্ক শিশুদের অনলাইন কার্যক্রম তত্ত্বাবধানে উত্সাহিত করি এবং একটি
      শিশু-বান্ধব অনলাইন পরিবেশ প্রদানে সহায়তা করার জন্য অনলাইন পরিষেবা এবং
      সফ্টওয়্যার নির্মাতাদের থেকে উপলব্ধ অভিভাবকীয় নিয়ন্ত্রণ সরঞ্জামগুলি
      ব্যবহার করার কথা বিবেচনা করি৷ এই সরঞ্জামগুলি পিতামাতার অনুমতি ছাড়াই
      নাবালকদের তাদের নাম, ঠিকানা এবং অন্যান্য ব্যক্তিগতভাবে সনাক্তযোগ্য তথ্য
      অনলাইনে প্রকাশ করা থেকেও আটকাতে পারে। যদিও পরিষেবাগুলি অপ্রাপ্তবয়স্কদের
      দ্বারা ব্যবহারের উদ্দেশ্যে নয়, আমরা অপ্রাপ্তবয়স্কদের গোপনীয়তাকে সম্মান
      করি যারা অসাবধানতাবশত ইন্টারনেট বা মোবাইল অ্যাপ্লিকেশন ব্যবহার করতে পারে৷
      ৮.১১ । এই নীতিতে সম্মতি আপনি স্বীকার করেন যে এই গোপনীয়তা নীতিটি ওয়েবসাইট
      এবং অন্যান্য পরিষেবার শর্তাবলীর একটি অংশ এবং আপনি স্বীকার করেন যে আপনি
      নিঃশর্তভাবে প্ল্যাটফর্মের ব্যবহারকারী হিসাবে সম্মত হয়েছেন এবং পরিষেবাগুলি
      এই গোপনীয়তা নীতিতে আপনার সম্মতি নির্দেশ করে৷ আপনার ওয়েবসাইট পরিদর্শন,
      অ্যাপের ব্যবহার এবং পরিষেবার ব্যবহার এই গোপনীয়তা নীতি এবং শর্তাবলীর
      সাপেক্ষে। ৮.১২। কুকিজ আপনি যখন প্ল্যাটফর্মে আমাদের পরিষেবাগুলি পান, তখন
      আপনার কম্পিউটারে একটি স্থায়ী কুকি রাখা হয়। এটি আমাদের অংশগ্রহণকারী
      ব্র্যান্ড পার্টনারদের সাথে আপনার করা যেকোনো কেনাকাটা ট্র্যাক করতে এবং
      আপনাকে ক্যাশব্যাক/পুরস্কার/পয়েন্ট প্রদান করতে সক্ষম করে। যদি আপনার
      কম্পিউটারে এই ধরনের ক্রমাগত কুকিজ সক্ষম না থাকে তবে আপনি আমাদের
      প্ল্যাটফর্মের মাধ্যমে আপনার অনলাইন শপিংয়ে লাভ/পয়েন্ট উপার্জন করতে পারবেন
      না। কুকিজ নিষ্ক্রিয়/সক্ষম করা: আপনার ব্রাউজারে সেটিংস পরিবর্তন করে কুকি
      গ্রহণ বা প্রত্যাখ্যান করার ক্ষমতা আপনার আছে। যাইহোক, কুকিজ নিষ্ক্রিয়
      থাকলে আপনি আমাদের প্ল্যাটফর্মের সমস্ত ইন্টারেক্টিভ বৈশিষ্ট্য ব্যবহার করতে
      পারবেন না। অনুগ্রহ করে মনে রাখবেন: আপনি যদি আপনার ব্রাউজারে কুকিজ
      নিষ্ক্রিয় করেন যা আমাদের প্ল্যাটফর্মের মাধ্যমে আপনার কেনাকাটা ট্র্যাক
      করতে ব্যবহৃত হয়, আপনি যখন আমাদের ওয়েবসাইট থেকে কেনাকাটা করেন তখন আপনি
      লাভ/পয়েন্ট উপার্জন করতে পারবেন না। কুকি ম্যানেজ করার অনেক উপায় আছে। আপনি
      যদি বিভিন্ন স্থানে বিভিন্ন কম্পিউটার ব্যবহার করেন তবে আপনাকে নিশ্চিত করতে
      হবে যে প্রতিটি ব্রাউজার আপনার কুকি পছন্দ অনুসারে সামঞ্জস্য করা হয়েছে।
      আপনার ব্রাউজারের কুকি ফোল্ডারে ইন্সটল করা যেকোন কুকিজকে আপনি সহজেই মুছে
      ফেলতে পারেন যখন প্রয়োজন হয়। ৮.১৩। এফিলিয়েট মার্কেটিং কমিশন AyyKori,
      একটি অ্যাফিলিয়েট মার্কেটিং প্ল্যাটফর্ম, মূলত একটি পরিষেবা প্রদানকারী যা
      ব্র্যান্ড অংশীদার এবং প্রকাশকদের সাথে সংযোগ স্থাপন করে। AyyKori যেকোনও
      কমিশনের জন্য দায়বদ্ধ নয় যা অংশীদার ব্র্যান্ড পার্টনার যেকোন কারণে বাতিল
      করেছে এবং বাল্ক ক্রয়, স্ব-ব্যবহার, অনাদায়ী বিক্রয়, পণ্য ফেরত বা বাতিল
      করা এবং/অথবা আমাদের কোনো অনুমোদিত নীতি লঙ্ঘনের মধ্যে সীমাবদ্ধ নয়। অংশীদার
      ব্র্যান্ড অংশীদার। ব্যবহারকারীদের ক্রমাগত লাভের হার এবং প্রচারাভিযানের
      শর্তাবলীর উপর নজর রাখতে হবে কারণ এগুলি পূর্ব বিজ্ঞপ্তি ছাড়াই রিয়েল-টাইম
      পরিবর্তনের বিষয়। AyyKori অংশীদার ব্র্যান্ড পার্টনার দ্বারা নির্দেশিত
      মুনাফা বাতিল করার অধিকার সংরক্ষণ করে। AyyKori-এ সাইন আপ করার মাধ্যমে,
      ব্যবহারকারী এই ধারা মেনে চলতে সম্মত হন। ৮.১৪। গোপনীয়তা নীতিতে সংশোধন বা
      পরিবর্তন AyyKori এই গোপনীয়তা নীতি যেকোন সময়, আগাম নোটিশ সহ বা ছাড়াই
      আপডেট করতে পারে। ইভেন্টে, AyyKori ব্যবহারকারীর তথ্যের সাথে আচরণ করার
      পদ্ধতিতে উল্লেখযোগ্য পরিবর্তন রয়েছে, অথবা গোপনীয়তা নীতিতে, AyyKori
      ওয়েবসাইটে একটি নোটিশ প্রদর্শন করবে বা ব্যবহারকারীদের একটি ইমেল পাঠাবে,
      যেমন উপরে দেওয়া হয়েছে, যাতে ব্যবহারকারী পরিবর্তিত শর্তাবলী পর্যালোচনা
      করতে পারে। পরিষেবাগুলি ব্যবহার চালিয়ে যাওয়ার আগে। বরাবরের মতো, যদি
      ব্যবহারকারী আমাদের শর্তাবলীর কোনো পরিবর্তনে আপত্তি জানায় এবং ব্যবহারকারী
      আর পরিষেবাগুলি ব্যবহার করতে না চান, তাহলে ব্যবহারকারী আপনার অ্যাকাউন্ট
      নিষ্ক্রিয় করতে support@ayykori.net-এ একই যোগাযোগ করতে পারে। অন্যথায় বলা
      না থাকলে, বর্তমান গোপনীয়তা নীতি আপনার এবং আপনার অ্যাকাউন্ট সম্পর্কে
      AyyKori-এর কাছে থাকা সমস্ত তথ্যের ক্ষেত্রে প্রযোজ্য। যদি কোনও ব্যবহারকারী
      এই ধরনের ব্যবহারকারীকে পরিবর্তনের নোটিশ পাঠানোর পরে বা প্ল্যাটফর্মে
      প্রকাশিত হওয়ার পরে পরিষেবাগুলি ব্যবহার করে, তাহলে এই ধরনের ব্যবহারকারী
      পরিবর্তিত শর্তাবলীতে তার/তার/তার সম্মতি প্রদান করে। ৮.১৫। গোপনীয়তা
      প্রশ্নগুলির জন্য ঠিকানা এই গোপনীয়তা নীতি বা AyyKori-এর তথ্য সংগ্রহ,
      ব্যবহার এবং প্রকাশের অনুশীলন সম্পর্কে আপনার কোন প্রশ্ন থাকলে, আপনি AyyKori
      দ্বারা নিযুক্ত ডেটা সুরক্ষা অফিসারের সাথে যোগাযোগ করতে পারেন। আমরা আপনার
      ব্যক্তিগত তথ্য আমাদের ব্যবহার সম্পর্কে আপনার থাকতে পারে যে কোনো অনুরোধ,
      প্রশ্ন বা উদ্বেগের অবিলম্বে প্রতিক্রিয়া করার জন্য যুক্তিসঙ্গত প্রচেষ্টা
      ব্যবহার করব। আপনার তথ্যের ব্যবহার সম্পর্কে আপনার কোনো অভিযোগ থাকলে, আপনি
      নিম্নলিখিত ইমেল ঠিকানায় আমাদের ইমেল করে ডেটা সুরক্ষা অফিসারের কাছে এই
      ধরনের অভিযোগ জানাতে পারেন ইমেল: support@ayykori.net বা আমাদের ওয়েবসাইটে
      উপলব্ধ সমর্থন পৃষ্ঠার মাধ্যমে ৯. আমাদের ভূমিকা: আমরা ব্র্যান্ড অংশীদারদের
      সাথে কোনও লেনদেনের পক্ষ নই এবং তারা যে পণ্য বা পরিষেবাগুলি উপলব্ধ করে তার
      বিক্রেতা বা সরবরাহকারী নই। এইভাবে, আমাদের কোন আইনি বাধ্যবাধকতা নেই যা সেই
      পণ্য বা পরিষেবাগুলির বিক্রেতাদের জন্য প্রযোজ্য। তদনুসারে, এর উপর আমাদের
      কোন নিয়ন্ত্রণ বা দায়িত্ব নেই: ব্র্যান্ড পার্টনারদের কাছ থেকে পাওয়া পণ্য
      বা পরিষেবার গুণমান, নিরাপত্তা বা বৈধতা; বা ব্র্যান্ড অংশীদার কোন পণ্য বা
      পরিষেবার জন্য ভাল শিরোনাম সরবরাহ করতে পারে বা দিতে পারে কিনা। সদস্যদের
      ব্র্যান্ড অংশীদারদের সাথে লেনদেনে প্রবেশের ক্ষেত্রে কম সতর্কতা অবলম্বন করা
      উচিত নয়, তারা যখন অফলাইনে অনুরূপ লেনদেন করতে পারে। প্রযোজ্য আইন যে
      পরিমাণে অনুমতি দেয়, আপনি আমাদের, আমাদের এজেন্ট এবং কর্মচারীদের ব্র্যান্ড
      অংশীদারদের সাথে যেকোন লেনদেন থেকে উদ্ভূত সমস্ত দায় থেকে মুক্তি দেন, যার
      মধ্যে ব্র্যান্ডের সাথে অসম্পূর্ণ বা সম্পূর্ণ লেনদেন সংক্রান্ত সমস্ত দাবি
      এবং দাবি (সীমাবদ্ধতা ছাড়াই) অংশীদার, বা পণ্য বা পরিষেবা বিক্রয় বা
      সরবরাহের জন্য অফার করা হয়, বা প্রকৃতপক্ষে বিক্রি বা সরবরাহ করা হয়,
      ব্র্যান্ড অংশীদারদের সাথে যেকোন লেনদেনের মাধ্যমে বা সংযোগে। ১0. অপব্যবহার:
      যদি আমাদের যুক্তিসঙ্গত দৃষ্টিতে প্রাসঙ্গিক সদস্য বা অ্যাকাউন্ট এই চুক্তির
      কোনো বিধান লঙ্ঘন করে বলে মনে হয় তবে আমরা আমাদের পরিষেবা, বা এর কিছু অংশে
      কোনো সদস্যের অ্যাক্সেস স্থগিত বা বন্ধ করার অধিকার সংরক্ষণ করি। সদস্যদের
      অবশ্যই একটি ব্র্যান্ড অংশীদারের সাথে বা মুনাফা অর্জনের জন্য কোনও লেনদেনে
      প্রবেশ করা বা প্রবেশ করার চেষ্টা করা উচিত নয় (ক) অন্য কারো ব্যক্তিগত তথ্য
      প্রদান করে, অথবা একটি অর্থপ্রদানের পদ্ধতি যা ব্যবহার করার অধিকার তাদের
      নেই, (খ) জাল বা অননুমোদিত রেফারেল লিঙ্ক তৈরি করা সহ কিন্তু সীমাবদ্ধ নয়
      এমন একটি ব্র্যান্ড অংশীদারের অফারকে প্রতারণামূলক বা অন্যায়ভাবে শোষণ করে,
      অথবা (গ) সেই লেনদেনে আমাদের বা ব্র্যান্ড অংশীদারের দ্বারা প্রযোজ্য যেকোন
      নিয়ম ও শর্তাবলী লঙ্ঘন। সদস্যদের দ্বারা আমাদের পরিষেবার এই ধরনের
      অপব্যবহারের ক্ষেত্রে সদস্যের অ্যাকাউন্টে কোনো মুলতুবি পেমেন্ট বা বৈধ
      অর্থপ্রদান বাজেয়াপ্ত করার অধিকার আমরা সংরক্ষণ করি। এটি নিশ্চিত করা
      প্রতিটি সদস্যের বাধ্যবাধকতা যে তার/তার দ্বারা পোস্ট করা বা তার
      অ্যাকাউন্টের সাথে সম্পর্কিত যে কোনও উপাদান: মানহানিকর, আপত্তিকর, বা
      অপমানজনক বা অশ্লীল, অশালীন বা ভয়ঙ্কর প্রকৃতির নয়; কোন ব্যক্তির জন্য
      অপ্রয়োজনীয় বিরক্তি, অসুবিধা বা কষ্টের কারণ বা উদ্দ্যেশ্য নয়; কোন
      কম্পিউটার ভাইরাস, ম্যাক্রো ভাইরাস, ট্রোজান হর্স, ওয়ার্ম, বা কম্পিউটারের
      স্বাভাবিক অপারেটিং পদ্ধতিতে হস্তক্ষেপ, বাধা, বা ব্যাহত করার জন্য বা
      গোপনীয়ভাবে বাধা দেওয়ার জন্য, কর্তৃত্ব ছাড়াই অ্যাক্সেস, বা কোনও সিস্টেম,
      ডেটা বা বাজেয়াপ্ত করার জন্য ডিজাইন করা অন্য কিছু নেই। ব্যক্তিগত তথ্য;
      প্রযোজ্য আইন বা প্রবিধান লঙ্ঘন করে না (ভোক্তা সুরক্ষা, দূরত্ব বিক্রয়,
      অন্যায্য প্রতিযোগিতা, বৈষম্য বিরোধী, মিথ্যা বিজ্ঞাপন, কপিরাইট, ট্রেডমার্ক
      এবং গোপনীয়তা নিয়ন্ত্রণকারী আইন সহ, তবে সীমাবদ্ধ নয়); কোনো ব্যক্তি বা
      সত্তার অধিকার লঙ্ঘন করে না (কোনও অধিকার বা গোপনীয়তার প্রত্যাশা সহ);
      যেখানে এটি একটি ব্র্যান্ড অংশীদার সম্পর্কে প্রতিক্রিয়া গঠন করে, সঠিক এবং
      ন্যায্য; এবং কোনো পণ্য বা পরিষেবার বিজ্ঞাপন দেয় না। এই চুক্তি এবং
      গোপনীয়তা নীতির অন্য কোথাও থাকা সত্ত্বেও, আমরা অভিযোগ তদন্ত করার বা এই
      চুক্তির লঙ্ঘনের রিপোর্ট করার অধিকার সংরক্ষণ করি এবং আইন প্রয়োগকারী
      কর্মকর্তা, নিয়ন্ত্রকদের সন্দেহজনক বেআইনি কার্যকলাপ রিপোর্ট করা সহ কিন্তু
      সীমাবদ্ধ নয় , বা অন্যান্য তৃতীয় পক্ষ এবং আপনার অ্যাকাউন্ট, ইমেল ঠিকানা,
      ব্যবহারের ইতিহাস, পোস্ট করা সামগ্রী, আইপি ঠিকানা এবং ট্রাফিক তথ্য
      সম্পর্কিত এই ধরনের ব্যক্তি বা সংস্থার জন্য প্রয়োজনীয় বা উপযুক্ত কোনো
      তথ্য প্রকাশ করা। যে কোনো সদস্য, যারা ব্র্যান্ড পার্টনার বা আমাদের নিজস্ব
      বিবেচনার ভিত্তিতে, প্ল্যাটফর্ম বা আমাদের পরিষেবা ব্যবহারে প্রতারণা করেছেন
      বা প্ল্যাটফর্ম বা পরিষেবার অপব্যবহার করেছেন, তাদের অ্যাকাউন্ট বন্ধ করে
      দেওয়া হবে, ইমেল এবং আইপি ঠিকানা কালো তালিকাভুক্ত করা হবে যাতে তারা এটি
      আবার ব্যবহার করতে পারবেন না এবং একই পিসি/ল্যাপটপ/স্মার্ট ডিভাইস থেকে
      নিবন্ধন করতে পারবেন না। আপনি যদি আমাদের প্ল্যাটফর্মে এমন কিছু দেখেন বা
      অনুভব করেন যা উপরের যেকোন প্রয়োজনীয়তা লঙ্ঘন করে বলে মনে হয়, আমরা চাই
      আপনি আমাদের যোগাযোগ ফর্ম ব্যবহার করে আমাদের জানান। প্রতিটি সদস্য স্বীকার
      করে যে আমরা এই চুক্তির লঙ্ঘনের জন্য তৃতীয় পক্ষ বা অন্যান্য সদস্যদের কাছ
      থেকে প্রাপ্ত তথ্যের উপর ভিত্তি করে – প্রদর্শিত কোনো উপাদান প্রত্যাহার করার
      অধিকারী, কিন্তু বাধ্য নই। ১১. তৃতীয় পক্ষ থেকে যোগাযোগ: যদি কেউ আপনার বা
      আপনার অ্যাকাউন্টের সাথে সম্পর্কিত উপাদান বা লেনদেনের বিষয়ে আমাদের সাথে
      যোগাযোগ করে, তাহলে আপনি সম্মত হন: সেই যোগাযোগে সাড়া দেওয়ার জন্য আমাদের
      প্রয়োজন হতে পারে এমন সমস্ত যুক্তিসঙ্গত তথ্য এবং সহায়তা প্রদানের জন্য;
      এবং অবিলম্বে এবং সঠিকভাবে এটির প্রতিক্রিয়া জানাতে, আমরা কি প্রতিক্রিয়ার
      জন্য আপনাকে বার্তাটি প্রেরণ করব। ১২ . অতিরিক্ত পরিষেবা: আমরা বা আমাদের
      অংশীদাররা সময়ে সময়ে প্ল্যাটফর্মের মাধ্যমে নতুন বা অতিরিক্ত পরিষেবা দিতে
      পারি। আপনার এই পরিষেবাগুলির ব্যবহার অতিরিক্ত শর্তাবলীর অধীন হতে পারে, যা
      আপনাকে অবশ্যই মেনে চলতে হবে। যদি আপনি সেই পরিষেবাগুলি নিতে সম্মত হন তখন
      সেই শর্তগুলি আপনাকে উপযুক্ত পদ্ধতিতে প্ল্যাটফর্মে অবহিত করা হয় (আমাদের
      যুক্তিসঙ্গত বিবেচনার ভিত্তিতে) যখন আপনি সেই পরিষেবাগুলিকে পরিচালনা করে এমন
      শর্তগুলির একটি উপাদান বিধান মেনে চলতে আপনার ব্যর্থতা হবে এই চুক্তির
      লঙ্ঘনের পরিমাণ। ১৩. আমাদের পরিষেবার অপারেশন: আমরা পরিষেবার দিকগুলি বা এটির
      সম্পূর্ণতা প্রত্যাহার, সংশোধন বা স্থগিত করার অধিকার সংরক্ষণ করি, যেখানে
      আমাদের কাছে এটি করার আইনি, নিরাপত্তা, প্রযুক্তিগত বা বাণিজ্যিক কারণ
      রয়েছে৷ আমরা এই ধরনের পদক্ষেপ নেওয়ার আগে আপনাকে 30 দিনের অগ্রিম নোটিশ
      দেওয়ার চেষ্টা করব, যেখানে নিরাপত্তার কারণে বা প্রযুক্তিগত সমস্যার কারণে
      আগে পদক্ষেপ নেওয়া প্রয়োজন যা অন্যথায় আমাদের পরিষেবাকে বিরূপভাবে
      প্রভাবিত করবে। এমনও সময় হতে পারে যখন আমাদের বা ইন্টারনেটে প্রযুক্তিগত
      সমস্যার কারণে পরিষেবাটি অ্যাক্সেসযোগ্য না হয়ে যায়; যাইহোক, আমরা
      যুক্তিসঙ্গত দক্ষতা এবং যত্ন ব্যবহার করব যেখানে এই অসুবিধাগুলি আমাদের
      নিয়ন্ত্রণের মধ্যে রয়েছে তা কাটিয়ে উঠতে। অনুগ্রহ করে মনে রাখবেন, যাইহোক,
      আমরা পরিষেবা বা এতে প্রদর্শিত কোনও সামগ্রীতে অবিচ্ছিন্ন অ্যাক্সেসের
      গ্যারান্টি দিতে পারি না। তবুও, আমরা নিশ্চিত করার চেষ্টা করব যে পরিকল্পিত
      অনুপলব্ধতার যেকোনো সময়, যা আপনি প্রাসঙ্গিক সময়ে পরিষেবাটি অ্যাক্সেস করার
      সময় আপনাকে জানানো হবে, ন্যূনতম রাখা হয়। নিরাপত্তা বা অন্যান্য কারণে,
      আমরা আপনাকে আপনার পাসওয়ার্ড বা অন্যান্য তথ্য পরিবর্তন করতে চাই যা
      পরিষেবাটিতে অ্যাক্সেসের সুবিধা দেয়; যাইহোক, আমরা কখনই ইমেল, টেলিফোন বা
      প্ল্যাটফর্ম ছাড়া অন্য কোনো মাধ্যমে আপনার পাসওয়ার্ড চাইব না। আপনার
      পাসওয়ার্ড এবং কোনো অতিরিক্ত শনাক্তকরণ তথ্যের গোপনীয়তা বজায় রাখার জন্য
      আপনি সম্পূর্ণভাবে দায়ী। ১৪. দাবিত্যাগ এবং দায়বদ্ধতার সীমাবদ্ধতা:
      দাবিত্যাগ: প্ল্যাটফর্ম থেকে বা এর মাধ্যমে বিষয়বস্তু এবং উপাদানগুলি
      "যেমন-যেমন আছে," "যেমন উপলব্ধ", "সমস্ত ত্রুটি" সহ প্রদান করা হয়, এবং
      সমস্ত ওয়ারেন্টি, প্রকাশ বা উহ্য, অস্বীকার করা হয় (কোন অন্তর্নিহিত
      ওয়ারেন্টির দাবিত্যাগ সহ কিন্তু সীমাবদ্ধ নয় ব্যবসায়িকতা, অ লঙ্ঘন, ত্রুটি
      থেকে মুক্তি এবং একটি নির্দিষ্ট উদ্দেশ্যে উপযুক্ততা)। তথ্য এবং
      পরিষেবাগুলিতে বাগ, ত্রুটি, সমস্যা বা অন্যান্য সীমাবদ্ধতা থাকতে পারে। আপনার
      কোনো তথ্য বা পরিষেবা ব্যবহারের জন্য আমরা এবং আমাদের অধিভুক্ত দলগুলোর কোনো
      দায় নেই। বিশেষ করে, তবে এর সীমাবদ্ধতা হিসাবে নয়, আমরা এবং আমাদের
      অধিভুক্ত পক্ষগুলি কোনো পরোক্ষ, বিশেষ, আনুষঙ্গিক বা ফলস্বরূপ ক্ষতির জন্য
      দায়ী নই (ব্যবসার ক্ষতি, লাভের ক্ষতি, সঞ্চয়, মোকদ্দমা, বা এর মতো ক্ষতি
      সহ) চুক্তির লঙ্ঘন, ওয়ারেন্টি লঙ্ঘন, টর্ট (অবহেলা সহ), পণ্যের দায় বা
      অন্যথায়, এমনকি যদি এই ধরনের ক্ষতির সম্ভাবনার পরামর্শ দেওয়া হয়। উপরে
      উল্লিখিত ক্ষতির অস্বীকার এবং সীমাবদ্ধতা আমাদের এবং আপনার মধ্যে চুক্তির
      ভিত্তির মৌলিক উপাদান। এই প্ল্যাটফর্ম এবং পণ্য, পরিষেবা, নথি, বিষয়বস্তু
      এবং উপকরণ এবং উপস্থাপিত তথ্য এই ধরনের সীমাবদ্ধতা ছাড়া প্রদান করা হবে না।
      কোন উপদেশ বা তথ্য, মৌখিক বা লিখিত হোক না কেন, প্ল্যাটফর্মের মাধ্যমে আমাদের
      কাছ থেকে প্রাপ্ত বা অন্যথায় এই চুক্তিতে স্পষ্টভাবে বলা হয়নি এমন কোনও
      ওয়ারেন্টি, প্রতিনিধিত্ব বা গ্যারান্টি তৈরি করবে না। একটি ফর্ম বা নথি
      ধারণকারী বৈদ্যুতিন ফাইলের মধ্যে থাকা ভাইরাস দ্বারা সৃষ্ট কোনো ক্ষতির জন্য
      সমস্ত দায়িত্ব বা দায় অস্বীকার করা হয়। দায়: ক) আমরা ওয়ারেন্টি দিচ্ছি
      যে পরিষেবাটির জন্য আমাদের স্পেসিফিকেশনগুলি পূরণ করার অভিপ্রায়ে পরিষেবাটি
      যুক্তিসঙ্গত যত্ন এবং দক্ষতার সাথে সরবরাহ করা হবে, তবে আমরা গ্যারান্টি দিতে
      পারি না এবং আমরা দিতে পারি না যে পরিষেবাটি আপনার প্রয়োজনীয়তাগুলি পূরণ
      করবে৷ খ) আমরা এই চুক্তিতে স্পষ্টভাবে প্রদত্ত হিসাবে দায়বদ্ধ থাকব, তবে
      চুক্তি, নির্যাতন (অবহেলা, বিধিবদ্ধ দায়িত্ব লঙ্ঘন এবং অন্য কোন নির্যাতন
      সহ) বা অন্য কোন দায়বদ্ধতা, কর্তব্য বা দায়বদ্ধতা থাকবে না। গ) দায়বদ্ধতার
      সীমাবদ্ধতা: সর্বদা সাব-ক্লজ ঘ. নীচে, চুক্তিতে, নির্যাতন (অবহেলা, বিধিবদ্ধ
      কর্তব্য লঙ্ঘন বা অন্যান্য নির্যাতন সহ) বা অন্যথায়, এবং এটির কাজ বা বর্জন
      বা এর কর্মচারী, এজেন্ট বা উপ-কন্ট্রাক্টরদের দ্বারা সৃষ্ট হোক না কেন,
      শুধুমাত্র সরাসরি ক্ষতি বা ক্ষতির জন্য আমরা দায়ী থাকব। . প্ল্যাটফর্ম
      এবং/অথবা এখানে বা এতদ্বারা প্রদত্ত পণ্য, তথ্য, নথি এবং পরিষেবাগুলি থেকে
      উদ্ভূত বা সম্পর্কিত যে কোনও দাবির ক্ষেত্রে AyyKori এবং অনুমোদিত পক্ষগুলির
      সামগ্রিক দায় 20,000 টাকার বেশি হবে না এবং সেই পরিমাণটি এর পরিবর্তে হবে
      অন্যান্য সমস্ত প্রতিকার যা আপনার আমাদের এবং আমাদের সাথে কোন অনুমোদিত
      পক্ষের বিরুদ্ধে থাকতে পারে। ঘ) চুক্তিতে, নির্যাতন (অবহেলা, বিধিবদ্ধ
      দায়িত্ব লঙ্ঘন বা অন্যান্য নির্যাতন সহ) বা অন্যথায় আমরা আপনার বা অন্য
      কারও কাছে দায়বদ্ধ থাকব না (ক) রাজস্ব, ব্যবসা, প্রত্যাশিত সঞ্চয় বা লাভের
      কোনো ক্ষতির জন্য; (খ) প্ল্যাটফর্ম বা সেখান থেকে প্রাপ্ত যেকোন পরিষেবা বা
      পণ্যের মধ্যে কোনো ত্রুটি বা বাদ দেওয়া; (গ) প্ল্যাটফর্মের অনুপলব্ধতা বা
      বাধা বা তার কোনো বৈশিষ্ট্য; (ঘ) প্ল্যাটফর্মের আপনার ব্যবহার; (ঙ)
      প্ল্যাটফর্মে থাকা বিষয়বস্তু এবং উপকরণ; (চ) বা আমাদের নিয়ন্ত্রণের বাইরে
      কার্য সম্পাদনে কোনো বিলম্ব বা ব্যর্থতা বা আমাদের কোনো অনুমোদিত পক্ষ। কোনো
      পরোক্ষ, বিশেষ বা ফলস্বরূপ ক্ষতি, খরচ বা অন্যান্য দাবির জন্য, যাইহোক সৃষ্ট
      বা উদ্ভূত, পরিষেবার অ-সাপ্লাই বা বিলম্বে সরবরাহের মাধ্যমে বা এই চুক্তির
      অন্যান্য অ-কর্মক্ষমতা বা অন্যথায়। এই চুক্তিতে অন্য কোথাও স্পষ্টভাবে বলা
      ব্যতীত, সমস্ত উপস্থাপনা, ওয়ারেন্টি, শর্তাবলী এবং অন্যান্য শর্তাবলী, তা
      প্রকাশ বা উহ্য (সাধারণ আইন, সংবিধি, সমান্তরাল বা অন্যথায়) এতদ্বারা বাদ
      দেওয়া হয়েছে, প্রতারণার ক্ষেত্রে বা যেখানে এই ধরনের বাদ দেওয়া হয়
      প্রযোজ্য আইন দ্বারা অনুমোদিত নয়। সন্দেহ এড়ানোর জন্য, সদস্যদের অবদান,
      ব্র্যান্ড অংশীদারদের সাথে লেনদেন (বা অ-লেনদেন), বা এই জাতীয় উপাদান বা
      লেনদেনের সাথে সম্পর্কিত কোনও কার্যকলাপ বা যোগাযোগের ক্ষেত্রে আপনার বা অন্য
      কোনও ব্যক্তির কাছে আমাদের দায় থাকবে না। এই ধারা 14 এর বিধানগুলি এই
      চুক্তির সমাপ্তি বা মেয়াদ শেষ হওয়ার পরে বেঁচে থাকবে৷ ১৫ . তৃতীয় পক্ষের
      বিষয়বস্তু: তৃতীয় পক্ষের বিষয়বস্তু এবং উপকরণ প্ল্যাটফর্মে প্রদর্শিত হতে
      পারে বা প্ল্যাটফর্ম থেকে হাইপারলিংকের মাধ্যমে অ্যাক্সেসযোগ্য হতে পারে।
      বিবৃতি, মতামত, উপস্থাপনা বা প্ল্যাটফর্মে প্রদর্শিত বা অ্যাক্সেসযোগ্য অন্য
      যেকোন ধরণের বিষয়বস্তু এবং উপকরণগুলিতে কোনও ভুল, আইনের ভুল বিবৃতি,
      মানহানি, বাদ দেওয়া, মিথ্যা, অশ্লীলতা, অশ্লীলতা, অশ্লীলতা বা অশ্লীলতার
      জন্য আমরা দায়ী নই এবং কোনও দায়বদ্ধতা গ্রহণ করি না। প্ল্যাটফর্ম থেকে
      হাইপারলিংকের মাধ্যমে। ১৬. যোগাযোগ: আপনি এতদ্বারা এসএমএস, ই-মেইল, মোবাইল
      অ্যাপ এবং ব্রাউজার নোটিফিকেশন এবং AyyKori থেকে সময়ে সময়ে ওয়েবসাইটের
      মাধ্যমে প্রদত্ত পরিষেবাগুলির সাথে সম্পর্কিত যে কোনও বার্তার মাধ্যমে
      যোগাযোগ পেতে সম্মত হন। ১৭. ক্ষতিপূরণ: আপনি আমাদের সমস্ত দায়বদ্ধতা, দাবি
      এবং খরচের বিরুদ্ধে ক্ষতিপূরণ দিতে সম্মত হন যা থেকে বা এর সাথে সম্পর্কিত
      হতে পারে (ক) আপনার দ্বারা বা আপনার অ্যাকাউন্টের মাধ্যমে এই চুক্তির কোনো
      লঙ্ঘন, অথবা (b) একটি ব্র্যান্ড অংশীদারের সাথে যেকোনো লেনদেন। ১৮.
      অ্যাসাইনমেন্ট: আমরা এই চুক্তিটি বরাদ্দ করার অধিকার সংরক্ষণ করি, এবং এই
      চুক্তির অধীনে আমাদের যে কোনও বা সমস্ত অধিকার এবং বাধ্যবাধকতা বরাদ্দ বা
      সাবকন্ট্রাক্ট করার অধিকার রাখি, তবে এই চুক্তির অধীনে আপনাকে দেওয়া কোনও
      গ্যারান্টি কমানোর জন্য এমনভাবে তা করব না। আপনি আমাদের লিখিত সম্মতি ব্যতীত
      এই চুক্তিটি বরাদ্দ বা নিষ্পত্তি করতে পারবেন না বা এটির অধীনে আপনার কোনও
      অধিকার এবং বাধ্যবাধকতা সাবকন্ট্রাক্ট করতে পারবেন না। ১৯. সম্পূর্ণ চুক্তি:
      এই চুক্তিটি পরিষেবা সম্পর্কিত আমাদের সাথে আপনার সম্পূর্ণ চুক্তি ধারণ করার
      উদ্দেশ্যে করা হয়েছে; আমরা বিশ্বাস করি এটা ন্যায্য এবং যুক্তিসঙ্গত। এটি
      পরিষেবা সম্পর্কিত আপনার সাথে পূর্বের সমস্ত চুক্তি এবং বোঝাপড়া প্রতিস্থাপন
      করে, আমাদের মধ্যে যেকোনও প্রতারণা বা প্রতারণামূলক প্রতিনিধিত্ব ব্যতীত। ২০.
      এই চুক্তিতে পরিবর্তন: আমরা সময়ে সময়ে এই চুক্তিটি পরিবর্তন করার এবং
      পরিষেবাতে নতুন সংস্করণ পোস্ট করার অধিকার সংরক্ষণ করি। যখন আমরা তা করি,
      আমরা পরিষেবা সংক্রান্ত চুক্তির নতুন সংস্করণ পোস্ট করব, এবং এই শর্তাবলীর
      নতুন সংস্করণ কার্যকর হবে, এবং পরিষেবা এবং আমাদের সাথে আপনার সম্পর্ক
      পরিচালনা করবে পোস্ট করার তারিখের তিন দিনের কম সময় পরে (অথবা আমরা
      প্রাসঙ্গিক পোস্টিংয়ে ইঙ্গিত করি এমন পরবর্তী তারিখ) থেকে শুরু হওয়া, যদি
      এই চুক্তির একটি অপারেটিভ বিধানে কোন পরিবর্তন হয় যা আপনাকে প্রতিকূলভাবে
      প্রভাবিত করতে পারে; আপনি যদি চুক্তির নতুন সংস্করণ দ্বারা নিয়ন্ত্রিত হতে
      না চান, তাহলে চুক্তির নতুন সংস্করণ কার্যকর হওয়ার তারিখে বা তার আগে আপনি
      আমাদের অবহিত করতে পারেন এবং সেই তারিখ থেকে আপনাকে অবশ্যই আমাদের পরিষেবা
      ব্যবহার করা বন্ধ করতে হবে বা পোস্ট করার তারিখের সাথে সাথেই (অথবা
      প্রাসঙ্গিক পোস্টিংয়ে আমরা নির্দেশিত পরবর্তী তারিখে), যদি পরিবর্তনগুলি
      অপারেটিভ বিধানগুলিতে না হয়, বা আপনাকে বিরূপভাবে প্রভাবিত করতে সক্ষম না
      হয় - যার উদাহরণগুলি সীমাবদ্ধতা ছাড়াই, যোগাযোগের বিবরণে পরিবর্তনগুলি
      অন্তর্ভুক্ত করবে উল্লেখ করা হয়েছে, অথবা এই চুক্তিতে ইতিমধ্যেই অন্তর্ভুক্ত
      করা বিধানগুলির পরিমার্জন৷ ২১. সাধারণ: এই চুক্তির যে কোনো মেয়াদ অবৈধ বা
      অপ্রয়োগযোগ্য বলে ধরে নেওয়া হলে, এই চুক্তির অবশিষ্টাংশ বৈধ এবং বলবৎযোগ্য
      থাকবে। আপনি এবং আমরা স্বাধীন ঠিকাদার, এবং কোন এজেন্সি, অংশীদারিত্ব, যৌথ
      উদ্যোগ বা কর্মচারী-নিয়োগকর্তা সম্পর্ক এই চুক্তির দ্বারা উদ্দিষ্ট বা তৈরি
      করা হয় না। আপনার বা অন্যদের দ্বারা লঙ্ঘনের বিষয়ে কাজ করতে আমাদের
      ব্যর্থতা পরবর্তী বা অনুরূপ লঙ্ঘনের ক্ষেত্রে আমাদের কাজ করার অধিকারকে
      পরিত্যাগ করে না। ২২. পরিচালনা আইন: এই চুক্তি, এবং আপনার এবং প্রতিটি
      সদস্যের সাথে আমাদের সম্পর্ক, বাংলাদেশী আইন অনুযায়ী পরিচালিত হয়। আপনি এবং
      আমরা প্রত্যেকে এই চুক্তির সাথে সম্পর্কিত বিরোধের বিষয়ে বাংলাদেশের আদালতের
      অ-এক্সক্লুসিভ এখতিয়ারের কাছে জমা দিই। ২৩. এই চুক্তি পালন: যখন তারা
      পরিষেবার জন্য নিবন্ধন করে তখন আমরা সদস্যদের দ্বারা প্রবেশ করা পৃথক
      চুক্তিগুলি আলাদাভাবে ফাইল করি না। আপনি এটি www.AyyKori.net এ অ্যাক্সেস
      করতে পারেন। আপনার নিজের কম্পিউটারে ডাউনলোড করা কপি মুদ্রণ এবং/অথবা সংরক্ষণ
      করে এই চুক্তির একটি টেকসই অনুলিপি তৈরি করুন৷ এটি শুধুমাত্র ইংরেজিতে দেওয়া
      হয়। ২৪. যোগাযোগ: আপনি 'support@ayykori.net'-এ আমাদের সাথে যোগাযোগ করতে
      পারেন। [NR1]পপ-আপ তোলার বোতামটি মাসের 1-5 এবং 16-20 তারিখে উপলব্ধ হবে৷
    </div>
  );
};

export default BanglaTramsAndConditions;
