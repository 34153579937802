import Cookies from "js-cookie";
import React, { useState } from "react";
import { BiSort } from "react-icons/bi";
import { RxDashboard } from "react-icons/rx";
import ShareandjoyCard from "../../components/Card/ShareandjoyCard";
import Error from "../../components/Error/Error";
import NoDataFound from "../../components/NodataFound/NoDataFound";
import ShareAndJoySkeleton from "../../components/Skeleton/ShareAndJoySkeleton";
import CommonLayout from "../../layout/CommonLayout";

import { Helmet } from "react-helmet";
import ReactPaginate from "react-paginate";
import ShareModal from "../../components/Modal/ShareModal";
import AfterLoginSlider from "../../components/Slider/AfterLoginSlider";
import { useGetAllShareAndJoyQuery } from "../../features/shareandjoy/shareandjoyApiSlice";

const ShareAndJoyPage = () => {
  const [shareModal, setShareModal] = useState(false);
  const [limit, setLimit] = useState(10);
  const [campaign, setCampaign] = useState("");
  const [page, setPage] = useState(1);
  const access_token = Cookies.get("ayykori");
  const { data, isLoading, isSuccess, isError } = useGetAllShareAndJoyQuery(
    {
      access_token,
      page: page,
      limit: limit,
      campaign,
    },
    { refetchOnReconnect: true }
  );
  let content = null;
  // handle loading case
  if (isLoading && !isSuccess) {
    content = (
      <>
        <div
          className=" w-full px-3 md:px-[55px] grid grid-cols-1 sm:grid-cols-2  md:grid-cols-3 lg:grid-cols-5  
    gap-y-4 gap-x-4 my-10"
        >
          {Array(10)
            ?.fill()
            ?.map((item, i) => (
              <ShareAndJoySkeleton key={i} />
            ))}
        </div>
      </>
    );
  }
  // handle success case
  if (!isLoading && isSuccess && !isError) {
    content = (
      <>
        <div
          className=" w-full px-3 md:px-[55px] grid grid-cols-1 sm:grid-cols-2  md:grid-cols-3 lg:grid-cols-5  
    gap-y-4 gap-x-4 md:my-10"
        >
          {data?.resData &&
            data?.resData?.map((item, i) => (
              <ShareandjoyCard
                data={item}
                shareModal={shareModal}
                setShareModal={setShareModal}
                key={i}
              />
            ))}
        </div>
      </>
    );
  }
  // handle error case
  if (!isLoading && isError) {
    content = (
      <>
        <Error />
      </>
    );
  }
  // handle empty case
  if (!isLoading && isSuccess && data?.resData?.length === 0) {
    content = (
      <>
        <NoDataFound />
      </>
    );
  }

  return (
    <>
      <Helmet>
        <title>Share And Joy | Ayykori</title>
      </Helmet>
      <CommonLayout>
        <ShareModal shareModal={shareModal} setShareModal={setShareModal} />
        <AfterLoginSlider />

        <div className="my-14 md:my-20">
          <div className=" w-full px-3 md:px-[55px] my-5 ">
            <div className=" text-center flex items-center justify-between gap-2 ">
              <div className=" w-full h-[2.5px] flex justify-between items-end gap-x-5 bg-gray-300 ">
                <h1
                  className="whitespace-pre  w-fit text-sm sm:text-lg md:text-2xl text-gray-600 font-medium pb-2 border-b-[3px]
       border-primaryColor "
                >
                  Share Happiness & Joy
                </h1>

                {/* Sort Start */}
                <div className="flex items-center gap-x-2 mb-2 text-gray-500">
                  <div className="flex items-center border text-sm rounded-lg px-3 gap-x-2">
                    <RxDashboard />
                    Show
                    <select
                      value={limit}
                      onChange={(e) => setLimit(e.target.value)}
                      className=" px-1 py-1 md:py-2 bg-transparent  text-gray-500 outline-none cursor-pointer"
                    >
                      <option value={10}>10</option>
                      <option value={15}>15</option>
                      <option value={20}>20</option>
                      <option value={25}>25</option>
                    </select>
                  </div>
                  <div className="hidden md:flex items-center border rounded-lg text-sm px-2">
                    <BiSort />
                    Sort By:
                    <select
                      onChange={(e) => setCampaign(e.target.value)}
                      value={campaign}
                      className=" px-2 py-1 md:py-2 bg-transparent  text-gray-500 outline-none cursor-pointer"
                    >
                      <option value={""}>Campaign</option>
                      <option value={1}>Latest First</option>
                      <option value={-1}>Previous First</option>
                    </select>
                  </div>
                </div>
                {/* Sort End */}
              </div>
            </div>
          </div>
          {content}

          {data?.totalData[0]?.count > limit && (
            <div className="w-full px-3 md:px-[55px] flex justify-center md:justify-end items-center my-8">
              <ReactPaginate
                breakLabel="..."
                previousLabel="Prev"
                className="text-primaryColor flex items-center gap-2 text-sm"
                previousClassName="text-primaryColor text-sm"
                nextClassName="text-primaryColor text-sm"
                activeClassName="bg-primaryColor text-sm"
                activeLinkClassName="text-white px-2"
                nextLabel="Next"
                onPageChange={(e) => setPage(e.selected + 1)}
                pageRangeDisplayed={2}
                marginPagesDisplayed={2}
                pageCount={Math.ceil(data?.totalData[0]?.count / limit)}
                renderOnZeroPageCount={null}
              />
            </div>
          )}
        </div>
      </CommonLayout>
    </>
  );
};

export default ShareAndJoyPage;
