import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { FaChevronDown } from "react-icons/fa";
import { useParams } from "react-router-dom";
import CategoriesHero from "../../components/Categories/CategoriesHero";
import CategoriesPageLeftPanel from "../../components/Categories/CategoriesPageLeftPanel";
import CategoriesPageRightPanel from "../../components/Categories/CategoriesPageRightPanel";
import CategoriesPageSlider from "../../components/Categories/CategoriesPageSlider";
import CategoryPageSortComponent from "../../components/CommonComponent/CategoryPageSortComponent";
import CommonLayout from "../../layout/CommonLayout";
const CategoryWiseBrandsPage = () => {
  const [limit, setLimit] = useState(15);
  const [open, setOpen] = useState(false);
  const [cashback, setCashBack] = useState("descending");
  const { categoryname } = useParams();

  let categoryName = categoryname
    ?.replace(/-/g, " ")
    .replace(/\b\w/g, (c) => c.toUpperCase());

  return (
    <>
      <Helmet>
        <title className="capitalize">{categoryName} | Ayykori</title>
      </Helmet>
      <CommonLayout>
        <CategoriesHero />
        {/* <CommonComponent
        data={{
          title: "Categories",
          link: "/categories",
          linktitle: "See More",
          show: true,
        }}
      /> */}
        <CategoriesPageSlider />
        {/* <CategorySortHeader data={{ categoryName: categoryName }} /> */}
        <CategoryPageSortComponent
          title={categoryName}
          limit={limit}
          setLimit={setLimit}
          cashback={cashback}
          setCashBack={setCashBack}
        />

        <div className="flex -mt-5 mx-3">
          <div className="mx-auto w-full px-3 md:px-[55px] mb-3 py-5 md:hidden block relative bg-gradient-to-r from-[#67B26F]  to-[#4CA2CD] rounded-md">
            <div className="flex gap-1 items-center">
              <h3 className="text-white">
                Shop preferred brands in chosen categories.
              </h3>
              <button
                className="h-5 p-3 bg-gray-100 rounded-full text-gray-500 text-xs flex items-center gap-2 outline-none transition-all duration-700"
                onClick={() => setOpen(!open)}
              >
                SubCategory
                {open ? (
                  <FaChevronDown className="rotate-180" size={12} />
                ) : (
                  <FaChevronDown size={12} className="" />
                )}
              </button>
            </div>
            <div
              className={`${
                open ? "flex" : "hidden"
              } absolute w-full  h-auto min-h-72 top-full left-0 right-0  rounded-md shadow-md bg-white z-50
         flex-col border px-2 pt-2 pb-6 `}
            >
              <div
                className=" mt-2 overflow-y-auto scroll-smooth 
    scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-white 
    scrollbar-thumb-rounded-full scrollbar-track-rounded-full scrollbar-w-1"
              >
                <CategoriesPageLeftPanel data={"show"} />
              </div>
            </div>
          </div>
        </div>

        <div className="w-full px-3 md:px-[55px] flex overflow-auto  md:flex-row gap-x-6 gap-y-4 mb-10">
          <CategoriesPageLeftPanel />

          <CategoriesPageRightPanel limit={limit} cashback={cashback} />
        </div>
      </CommonLayout>
    </>
  );
};

export default CategoryWiseBrandsPage;
