import { managementApiSlice } from "../apiRequest/managementApiSlice";

export const manualOrderApiSlice = managementApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    createmanualOrder: builder.mutation({
      query: (orderData) => {
        const { access_token, data } = orderData || {};
        // const formData = new FormData();
        // formData.append("orderId", data?.orderId);
        // formData.append("date", data?.date);
        // formData.append("price", data?.price);
        // if (data?.selectedFile) {
        //   formData.append("image", data?.selectedFile);
        // }
        return {
          url: `/api/v1/amo/`,
          method: "POST",
          // preparedHeaders: (headers) => {
          //   headers.set("Content-type", "multipart/form-data");
          //   return headers;
          // },
          body: data,
          headers: {
            Authorization: `Bearer ${access_token}`,
            "Content-Type": "application/json;charset=UTF-8",
          },
        };
      },
      providesTags: ["Order"],
    }),
  }),
});

export const { useCreatemanualOrderMutation } = manualOrderApiSlice;
