import React from "react";
import { GiReceiveMoney } from "react-icons/gi";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { knowInsideModalHide } from "../../features/modal/modalSlice";

const KnowInsideOutModal = () => {
  const show1 = useSelector((state) => state.modal.show1);
  const dispatch = useDispatch();
  return (
    <div
      onClick={() => dispatch(knowInsideModalHide())}
      className={`w-screen h-screen bg-[rgba(9,30,66,0.2)] ${
        show1 ? "flex" : "hidden"
      } items-center justify-center fixed top-0 left-0 right-0 bottom-0 z-[10000] `}
    >
      <div className="w-screen h-screen flex justify-center backdrop-blur-[5px]  items-center gap-x-4 md:gap-x-8">
        <Link to={`/know-inside-out/learn-about-cashback`}>
          <div className="w-40 h-36 md:w-64 md:h-64 hover:scale-[0.95] transition-all duration-1000  rounded-md bg-gradient-to-br from-[#67B26F]  to-[#4CA2CD] flex flex-col gap-y-2 md:gap-y-4 py-4 items-center justify-center cursor-pointer">
            <GiReceiveMoney className="text-white text-3xl md:text-7xl" />
            <h1 className="text-base md:text-xl tracking-wider font-semibold text-white font-outfit uppercase">
              Cashback
            </h1>
            <p className="text-[10px] md:text-xs text-center text-white font-normal">
              Learn More About Cashback
            </p>
          </div>
        </Link>
        {/* <Link to={`/know-inside-out/learn-about-earn`}>
          <div className="w-40 h-36 md:w-64 md:h-64 hover:scale-[0.95] transition-all duration-1000 rounded-md bg-gradient-to-br from-[#67B26F]  to-[#4CA2CD] flex flex-col gap-y-2 md:gap-y-4 py-4 items-center justify-center cursor-pointer">
            <GiTakeMyMoney className="text-white text-3xl md:text-7xl" />
            <h1 className="text-base md:text-xl tracking-wider font-semibold text-white font-outfit uppercase">
              Earn
            </h1>
            <p className="text-[10px] md:text-xs text-center text-white font-normal">
              Learn More About Earn
            </p>
          </div>
        </Link> */}
      </div>
    </div>
  );
};

export default KnowInsideOutModal;
