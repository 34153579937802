import Cookies from "js-cookie";
import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { GiReceiveMoney } from "react-icons/gi";
import ReactPaginate from "react-paginate";
import { useParams } from "react-router-dom";
import ProductCard from "../../components/Card/ProductCard";
import ProductSortComponent from "../../components/CommonComponent/ProductSortComponent";
import Error from "../../components/Error/Error";
import NoDataFound from "../../components/NodataFound/NoDataFound";
import ProductCardSkeleton from "../../components/Skeleton/ProductCardSkeleton";
import AfterLoginSlider from "../../components/Slider/AfterLoginSlider";
import { useGetProductsByCategoryQuery } from "../../features/product/productApiSlice";
import CommonLayout from "../../layout/CommonLayout";

const CashbackProductPage = () => {
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(15);
  const [cashback, setCashBack] = useState("");
  let { categoryname } = useParams();
  if (categoryname === "all") {
    categoryname = undefined;
  }
  let arr = categoryname?.split("-");
  for (let i = 0; i < arr?.length; i++) {
    arr[i] = arr[i].slice(0, 1).toUpperCase() + arr[i].slice(1);
  }
  const title = arr?.join(" ");
  // const { limit, page, cashback } = useSelector((state) => state.product);
  const access_token = Cookies.get("ayykori");
  const { data, isLoading, isSuccess, isError } =
    useGetProductsByCategoryQuery(
      {
        access_token,
        categoryname,
        page,
        limit,
        cashback,
      },
      { refetchOnReconnect: true }
    ) || {};

  let content = null;
  // handle loading case
  if (isLoading && !isSuccess) {
    content = (
      <>
        <div
          className="  w-full px-3 md:px-[55px] grid grid-cols-1 sm:grid-cols-2  md:grid-cols-3 lg:grid-cols-5
      gap-y-4 gap-x-4 my-10"
        >
          {Array(12)
            .fill()
            ?.map((item, i) => (
              <ProductCardSkeleton key={i} />
            ))}
        </div>
      </>
    );
  }
  // handle success case
  if (!isLoading && isSuccess && !isError) {
    content = (
      <>
        <div className="mt-3 w-full px-3 md:px-[55px] grid grid-cols-2 sm:grid-cols-2  md:grid-cols-3 lg:grid-cols-5 gap-y-3 md:gap-y-6 gap-x-3 md:gap-x-8">
          {data?.resData &&
            isSuccess &&
            data?.resData?.map((item, i) => (
              <ProductCard
                key={i}
                data={{
                  id: item?._id,
                  image: item?.productImg,
                  name: item?.productTitle,
                  price: item?.productPrice,
                  rating: item?.productRating,
                  category: item?.category,
                  subCategory: item?.subCategory,
                  productDetails: item?.productDetails,
                  // brandId: item?.brandDetails[0]?._id,
                  brandId: item?.brandUnid,
                  productLink: item?.productLink,
                  brandimg: item?.brandimg,
                  discount: item?.productProfitInPercent,
                  profit: item?.cashbackProductProfitAmount,
                  whichBasisFav: "cashback",
                  buttontitle: "Get Cashback",
                  Icon: GiReceiveMoney,
                }}
              />
            ))}
        </div>
      </>
    );
  }

  // handle error case
  if (!isLoading && isError) {
    content = (
      <>
        <Error />
      </>
    );
  }
  // handle empty case
  if (!isLoading && isSuccess && data?.resData?.length === 0) {
    content = (
      <>
        <NoDataFound />
      </>
    );
  }

  return (
    <>
      <Helmet>
        <title>{title ? title : "All Products"} | Ayykori</title>
      </Helmet>
      <CommonLayout>
        <AfterLoginSlider />
        <div className="mb-10 mt-16">
          {/* <CommonComponentProduct
          data={{
            title: title,
            // link: "/categories",
            // linktitle: "See More",
            // show: false,
            showSort: true,
          }}
        /> */}
          <ProductSortComponent
            title={title ? title : "All Products"}
            limit={limit}
            setLimit={setLimit}
            cashback={cashback}
            setCashBack={setCashBack}
          />
          {content}
        </div>
        {/* pagination */}
        {data?.totalData[0]?.count > limit && (
          <div className="w-full px-3 md:px-[55px] flex justify-center md:justify-end items-center my-8">
            <ReactPaginate
              breakLabel="..."
              previousLabel="Prev"
              className="text-primaryColor flex items-center gap-2 text-sm"
              previousClassName="text-primaryColor text-sm"
              nextClassName="text-primaryColor text-sm"
              activeClassName="bg-primaryColor text-sm"
              activeLinkClassName="text-white px-2"
              nextLabel="Next"
              onPageChange={(e) => setPage(e.selected + 1)}
              pageRangeDisplayed={2}
              marginPagesDisplayed={2}
              pageCount={Math.ceil(data?.totalData[0]?.count / limit)}
              renderOnZeroPageCount={null}
            />
          </div>
        )}
      </CommonLayout>{" "}
    </>
  );
};

export default CashbackProductPage;
