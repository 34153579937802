import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  searchData: [],
};

export const searchSlice = createSlice({
  name: "search",
  initialState,
  reducers: {
    storeSearchData: (state, action) => {
      state.searchData = [...action.payload];
    },
  },
});

// Action creators are generated for each case reducer function
export const { storeSearchData } = searchSlice.actions;

export default searchSlice.reducer;
