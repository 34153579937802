import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const BaseURL = `${process.env.REACT_APP_BASE_URL}/api/v1`;
const API_KEY = "///...ayykori.net...///...";
// const encryptedApiKey = encryptApiKey(API_KEY);
export const apiRequest = createApi({
  reducerPath: "apiRequest",
  baseQuery: fetchBaseQuery({
    baseUrl: BaseURL,
    prepareHeaders: (headers) => {
      headers.set("x-key", API_KEY);
      return headers;
    },
  }),

  tagTypes: [
    "Auth",
    "User",
    "Brands",
    "Products",
    "FavProduct",
    "FavBrand",
    "Contact",
    "Blog",
    "Refer",
    "Balance",
    "CommissionCalculation",
  ],
  endpoints: (builder) => ({}),
});
