import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  paymentMethod: "cashOnDelivery",
  paymentWay: "COD",
  couponAdd: false,
  subtotal: "",
  shippingCost: "",
  total: "",
};

export const paymentSlice = createSlice({
  name: "payment",
  initialState,
  reducers: {
    setPayment: (state, action) => {
      state.couponAdd = action?.payload?.couponAdd;
      state.paymentMethod = action?.payload?.paymentMethod;
      state.paymentWay = action?.payload?.paymentWay;
      state.subtotal = action?.payload?.subtotal;
      state.shippingCost = action?.payload?.shippingCost;
      state.total = action?.payload?.total;
    },
    resetPayment: (state, action) => {
      state.paymentMethod = "cashOnDelivery";
      state.paymentWay = "COD";
      state.subtotal = "";
      state.shippingCost = "";
      state.total = "";
    },
  },
});

// Action creators are generated for each case reducer function
export const { setPayment, resetPayment } = paymentSlice.actions;
export default paymentSlice.reducer;
