import React from "react";
import { Collapse } from "react-collapse";
import { BsChevronRight } from "react-icons/bs";

export default function Accordion({
  data,
  title,
  index,
  active,
  setActive,
  activeTitle,
  setActiveTitle,
}) {
  // const [toggle, setToggle] = useState(false);

  const toggleState = () => {
    setActive(index);
    setActiveTitle(title);
  };
  return (
    <>
      <div className="my-4 border rounded-lg ">
        <div
          onClick={toggleState}
          className={`${
            activeTitle === title && active === index
              ? "bg-primaryColor rounded-t-lg"
              : "bg-white rounded-lg"
          }  py-[10px] px-[20px]  flex justify-between items-center cursor-pointer `}
        >
          <span
            className={`text-base md:text-lg ${
              active === index && activeTitle === title
                ? "text-white"
                : "text-gray-500"
            } font-medium`}
          >
            {data?.question}
          </span>
          <BsChevronRight
            className={`${
              activeTitle === title && active === index
                ? "text-white rotate-90"
                : "text-gray-500"
            } transition-all duration-500`}
          />
        </div>
        <Collapse isOpened={activeTitle === title && active === index}>
          <div className="text-sm md:text-base text-gray-500 px-[20px] py-[20px]">
            {data?.answer}
          </div>
        </Collapse>
      </div>
    </>
  );
}
