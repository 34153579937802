import { useSelector } from "react-redux";
import Form from "../ManualOrder/Form";
import { Link } from "react-router-dom";

const FormModal = ({ showAddUser, setShowAddUser, type, data }) => {
    const { manualOrderResetRef, formResetRef } = useSelector(
        (state) => state?.formReset
    );
    const closeModal = () => {
        setShowAddUser(false);
        if (manualOrderResetRef) manualOrderResetRef();
        if (formResetRef) formResetRef();
    };
    const { brandDetails: singleBrand } = useSelector(
        (state) => state?.singleBrand
    );

    // select affiliate link
    let affiliateLink;

    if (singleBrand?.brandName === "Daraz") {
        affiliateLink = "https://s.daraz.com.bd/s.oaHz?cc";
    } else if (singleBrand?.brandName === "vision") {
        affiliateLink = "https://s.daraz.com.bd/s.LHlB?cc";
    } else if (singleBrand?.brandName === "Dettol") {
        affiliateLink = "https://s.daraz.com.bd/s.LHOk?cc";
    } else if (singleBrand?.brandName === "Minister") {
        affiliateLink =
            "https://www.daraz.com.bd/shop/myone/?spm=a2a0e.pdp_revamp.seller.1.4cbe787e2v7fHT&itemId=295182898&channelSource=pdp";
    } else if (singleBrand?.brandName === "Lotto") {
        affiliateLink = "https://s.daraz.com.bd/s.LHm3?cc";
    } else if (singleBrand?.brandName === "Realme") {
        affiliateLink = "https://s.daraz.com.bd/s.LHNO?cc";
    } else if (singleBrand?.brandName === "Marico") {
        affiliateLink = "https://s.daraz.com.bd/s.LHNy?cc";
    } else if (singleBrand?.brandName === "Nestle") {
        affiliateLink = "https://s.daraz.com.bd/s.LHne?cc";
    } else if (singleBrand?.brandName === "ACI") {
        affiliateLink = "https://s.daraz.com.bd/s.LHo6?cc";
    } else if (singleBrand?.brandName === "Haier") {
        affiliateLink = "https://s.daraz.com.bd/s.LHod?cc";
    } else if (singleBrand?.brandName === "Walton") {
        affiliateLink = "https://s.daraz.com.bd/s.LHMD?cc";
    } else if (singleBrand?.brandName === "Unilever") {
        affiliateLink = "https://s.daraz.com.bd/s.LHML?cc";
    } else if (singleBrand?.brandName === "RFL Houseware") {
        affiliateLink = "https://s.daraz.com.bd/s.LHM8?cc";
    } else if (singleBrand?.brandName === "Fresh") {
        affiliateLink = "https://s.daraz.com.bd/s.LHMf?cc";
    } else if (singleBrand?.brandName === "Bata") {
        affiliateLink = "https://s.daraz.com.bd/s.LHny?cc";
    } else if (singleBrand?.brandName === "Ryans") {
        affiliateLink = "https://s.daraz.com.bd/s.LHnn?cc";
    } else {
        affiliateLink = "https://s.daraz.com.bd/s.oaHz?cc";
    }

    return (
        <>
            {singleBrand?.brandDomain === "ayykori.net" ? (
                // for daraz only
                <div hidden={!showAddUser} className={``}>
                    <div className="bg-[rgba(9,30,66,0.4)]  fixed  inset-x-0 px-4 pb-4 inset-0 flex justify-center  items-center z-[10000] backdrop-blur-[1px]  ">
                        <div className="bg-white rounded-lg shadow-xl overflow-auto md:overflow-hidden lg:overflow-hidden scroll-smooth transform transition-all  md:w-[700px] md:h-[500px] lg:w-[800] lg:h-[680px]  w-full max-h-[90%]">
                            <div className="p-5 ">
                                <div className="header flex justify-between items-center">
                                    <div className="text-gray-500 ">
                                        <div className=" flex items-center justify-start gap-1">
                                            <img
                                                className="w-10  object-cover rounded-md"
                                                src={singleBrand?.brandimg}
                                                alt={singleBrand?.brandname}
                                            />
                                            <h2 className="ml-4 text-lg font-semibold text-left">
                                                Get Cashback
                                            </h2>
                                        </div>
                                    </div>

                                    <div className="">
                                        <button
                                            onClick={closeModal}
                                            className="bg-gray-50 rounded-full  hover:text-red-500 "
                                        >
                                            <span
                                                className="text-2xl p-2"
                                                aria-hidden="true"
                                            >
                                                &times;
                                            </span>
                                        </button>
                                    </div>
                                </div>

                                <div className="mt-4">
                                    <div>
                                        <h2 className="text-lg font-medium">
                                            Step: 01 Mandatory
                                        </h2>
                                        <p className="text-sm text-left">
                                            Click the{" "}
                                            <span className="font-medium">
                                                "Shop on{" "}
                                                {singleBrand?.brandName}"
                                            </span>{" "}
                                            button and make your purchase!
                                            Without Clicking the{" "}
                                            <span className="font-medium">
                                                "Shop on{" "}
                                                {singleBrand?.brandName}"
                                            </span>{" "}
                                            button we Can't track your Orders &
                                            Can't Give you Cashback.
                                        </p>

                                        <div className="mt-3 flex justify-center">
                                            <Link
                                                to={affiliateLink}
                                                target="_blank"
                                                className="bg-[#11998E] text-white px-7 py-2 rounded-md"
                                            >
                                                <span>
                                                    Shop on{" "}
                                                    {singleBrand?.brandName}
                                                </span>
                                            </Link>
                                        </div>
                                    </div>

                                    <div className="mt-4">
                                        <h2 className="text-lg font-medium">
                                            Step: 02
                                        </h2>
                                        <p className="text-sm  text-left">
                                            Fill up the Information & Get
                                            Cashback
                                        </p>
                                    </div>
                                </div>

                                <div className="body mt-4 md:mt-8 ">
                                    <Form />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                // for others brand
                <div hidden={!showAddUser} className={``}>
                    <div className="bg-[rgba(9,30,66,0.4)]  fixed  inset-x-0 px-4 pb-4 inset-0 flex justify-center  items-center z-[10000] backdrop-blur-[1px]  ">
                        <div className="bg-white rounded-lg shadow-xl overflow-auto md:overflow-hidden lg:overflow-hidden scroll-smooth transform transition-all  md:w-[700px] md:h-[500px] lg:w-[800px] lg:h-[550px]  w-full max-h-[90%]">
                            <div className="p-5 ">
                                <div className="header flex justify-between items-center">
                                    <div className="text-gray-500 ">
                                        <div className=" flex items-center justify-start gap-1">
                                            <img
                                                className="w-10  object-cover rounded-md"
                                                src={singleBrand?.brandimg}
                                                alt={singleBrand?.brandname}
                                            />
                                            <h2 className="ml-4 text-lg font-semibold text-left">
                                                Get Cashback
                                            </h2>
                                        </div>
                                        <p className="text-sm  text-left">
                                            {" "}
                                            Fill up the Information & Get
                                            Cashback
                                        </p>
                                    </div>

                                    <div className="">
                                        <button
                                            onClick={closeModal}
                                            className="bg-gray-50 rounded-full  hover:text-red-500 "
                                        >
                                            <span
                                                className="text-2xl p-2"
                                                aria-hidden="true"
                                            >
                                                &times;
                                            </span>
                                        </button>
                                    </div>
                                </div>

                                <div className="body mt-4 md:mt-8 ">
                                    <Form />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default FormModal;
