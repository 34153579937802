export const faqData = [
  {
    title: "General Questions",
    data: [
      {
        question: "What is Ayykori?",
        answer:
          "Ayykori is the first cashback and affiliate marketplace in Bangladesh.",
      },
      {
        question: "What is cashback?",
        answer:
          "Cash back refers to a reward program that returns a percentage of each purchase to the buyer. A buyer gets back a portion of the sum spent on purchases. Cashback on the Ayykori platform is applicable for all the payment methods such as MFS, all Bangladeshi Bank accounts, Cash on delivery, etc.",
      },

      {
        question: "What is affiliate marketing?",
        answer:
          "A marketing arrangement by which a brand pays a Affiliate  to an external website/person for traffic or sales generated from its referrals.",
      },
      {
        question: "How to sign up in Ayykori?",
        answer:
          "To sign up for Ayykori, first, go to ayykori.net from your browser and then you’ll land on the homepage, where you need to click on “Signup” Button for creating a new account. Then you have to put your personal information (like your name, Valid Phone Number and email address, a strong password) and click “Signup” to join Ayykori. Also, You can click “Sign in with Google” for easy access. If you want to sign in with Google but you do not have a Google account, you need to make one and then sign in with your account. If you have a Gmail account, just add the account for signing with Google.",
      },
      {
        question: "How to sign in?",
        answer:
          "To sign in to Ayykori, if you have an existing account, you just have to visit ayykori.net or ayykori app and put your email ID & password and click log in. And if you sign in with your Google account just click the “Register or Login with Google” button, and your Google Accounts section will pop up. Just select your email address and then you will sign in.",
      },
      {
        question: "How to join?",
        answer: `To sign up for Ayykori, first, go to ayykori.net from your browser and then you’ll land on the homepage, where you need to click on “Signup” Button for creating a new account. Then you have to put your personal information (like your name, Valid Phone Number and email address, a strong password) and click “Signup” to join Ayykori.
        Also, You can click “Sign in with Google” for easy access. If you want to sign in with Google but you do not have a Google account, you need to make one and then sign in with your account. If you have a Gmail account, just add the account for signing with Google.To sign in to Ayykori, if you have an existing account, you just have to visit ayykori.net or ayykori app and put your email ID & password and click log in. And if you sign in with your Google account just click the “Register or Login with Google” button, and your Google Accounts section will pop up. Just select your email address and then you will sign in.`,
      },
      {
        question: "How to get help?",
        answer: `To get help with a problem regarding Ayykori,from mobile/app, you can click on the Bottom right “Menu” button, then click “Help” dropdown button.Then you will get 3 options
          1. Customer Support: Choose your problem and let us know. If your problem doesn’t match with the given options, choose others and write your problem in detail to us.
          2. FAQ: You can visit our FAQ section to find a lot of frequently asked questions and answers. 
          3. Contact Us: If you want to directly get connected, you can click “Contact Us” and then write your name, number, email, and details of your concerns with us. You can also find the necessary emails there to get connected with us.
          `,
      },
      {
        question: "How do I know about my product's delivery/refund status?",
        answer: `Click name, my balance, and click cashback for seeing the details of the products that you ordered
        You can find the details of the Delivery Status from the Brand’s website/app from where you ordered. For eg. If you ordered a product from Daraz, you can know your product’s detailed delivery status there. For earning report, you click dashboard, then click “earring balance”,  For cashback report, you click dashboard, then click “cashback balance”.`,
      },
      {
        question: "Does Ayykori cost me anything?",
        answer: `No, Ayykori costs you absolutely nothing! Opening an account and using this platform is free of cost.`,
      },
      {
        question: "How many ways can I get money",
        answer: `You can get money in three ways:
        a. Cashback: by successfully purchasing from brands on the Ayykori platform you can get cash back
        b. Affiliate : by promoting a Brand's/ product's link and whenever there is a successful purchase from your link you get a certain Affiliate .
        c. Refer: by referring your friends to Ayykori, you can earn 10% of your friend's ( your referred person) first month's earnings and cashback. (per person max referral bonus 1000 but)
        `,
      },
    ],
  },
  {
    title: "Payment",
    data: [
      {
        question: "How to withdraw money?",
        answer:
          "Homepage- login- click on the user name above right- landing page -click payment method and choose your desirable money receive option -you can use Nagad/BKash/ bank account for receiving money (Cashback/Affiliate ). Whenever AyyKori receives payment from brands for successful orders, the money will be sent to users’ bank account / Bkash or Nagad Account (according to the information provided by the users for receiving payment). Please see expected cashback / Affiliate  timeline from the landing page of the existing brands on Ayykori platform.",
      },
      {
        question: "How to receive money?",
        answer:
          "We will send your earned amount of Affiliate  or cashback to your BKash/Nagad account or bank account (whichever method you selected to receive payment) just after receiving payment from respective brands. Please see expected cashback / Affiliate  timeline from the landing page of the existing brands on Ayykori platform.",
      },
      {
        question: "When will I get my money?",
        answer:
          "To know when you can get your cashback or earned Affiliate , please click “go to brand” for that particular brand, on the landing page you can see expected cashback/ expected Affiliate  where you can find an approximate timeline of receiving your cashback/Affiliate .",
      },
      {
        question: "When will I get my cashback?",
        answer:
          "Please check the brand’s landing page to know your expected cashback timeline. After that timeline. Ayykori will send your cashback amount to your BKash/Nagad account or bank account (whichever method you selected to receive payment).",
      },
      {
        question: "Where can I see my earnings report?",
        answer:
          "From the Website, you can check the ‘My Balance’ option from your Profile to see your Earning report.",
      },
      {
        question: "Where can I see my earnings report?",
        answer:
          "From the Website, you can check the ‘My Balance’ option from your Profile to see your Earning report.From Mobile App/Msite, Click “Dashboard” in the below middle, then click “Earning Balance” to see your earning Report.From app or mobile, please click dashboard Bottom Middle, then click “Earning Balance”.",
      },
    ],
  },

  {
    title: "Refer",
    data: [
      {
        question: "How to refer to a friend?",
        answer:
          "After Signing in, you will get the option Refer and Earn at the top right corner of the homepage, Click the button, and then you will get your referral link, copy the link and then share the link with your friends and family. You can also use social media platforms to share your links. You can see the details of the people, who have used your referral link at the bottom of this page.",
      },
      {
        question: "How can I earn from referring?",
        answer:
          "By referring a person through your refer link, whenever your referred person starts earning or getting cashback from AyyKori, you will get his/her(referee's) 10% of the 1st month’s earnings/cashback (Maximum 1000 tk per refer).",
      },
      {
        question: "What is the maximum amount that I can earn from a referral?",
        answer:
          "You will earn a maximum of 10% of your referred person’s 1st month's earnings and cashback. The maximum amount that you can earn from a referral is 1000 but.",
      },
      {
        question: "Does my referred person get anything from me?",
        answer:
          "No, your referred person doesn't get anything from your earnings. He/she can start using the platform just like a normal user.",
      },
      {
        question: "Where to check my earning from referring?",
        answer: "You can check the ‘My Balance’ option from your Profile.",
      },
    ],
  },
  {
    title: "Cashback",
    data: [
      {
        question: "What is cashback?",
        answer:
          "Cashback is a site where you can get some portion of your cash after spending some money from more than 500 Brands and your desirable Products. Cashback service is available only in Bangladesh.",
      },
      {
        question: "How can I get my cashback?",
        answer: `Buy from your favorite brands through Ayykori and get fantastic cashback, consider using Ayykori’s Cashback site. This site offers policies that vary from brand to brand, but in almost all cases you'll be earning valuable rewards for your purchases.
          You can use Nagad/BKash/ bank account for receiving money (Cashback/Affiliate ). Whenever AyyKori receives payment from brands for successful orders, the money will be sent to users’ bank account / Bkash or Nagad Account (according to the information provided by the users for receiving payment). Please see expected cashback / Affiliate  timeline from the landing page of the existing brands on Ayykori platform.Check “Cashback balane” from “Dashboard” to know all the updates of cashback amount.
          `,
      },
      {
        question: "When will I get my cashback?",
        answer: `You will receive your cashback as soon as your order status is confirmed by our Brand Partners. This could take a few days, depending on the partner's policies. If you have ordered something with a return policy, you will receive your cashback when the return policy expires. You can use Nagad/BKash/ bank account for receiving money (Cashback/Affiliate ). Whenever AyyKori receives payment from brands for successful orders, the money will be sent to users’ bank account / Bkash or Nagad Account (according to the information provided by the users for receiving payment). Please see expected cashback / Affiliate  timeline from the landing page of the existing brands on Ayykori platform.Check “Cashback balane” from “Dashboard” to know all the updates of cashback amount.
          `,
      },
      {
        question: "Where can I see my cashback?",
        answer: `You can check the ‘My Balance’ option from your Profile to see your cashback. From App/Mobile, click “Dashboard”, then click “Cashback Balance”
          `,
      },
      {
        question: "Is cashback available on COD(Cash On Delivery)?",
        answer: `Yes, Cashback is available in COD (Cash On Delivery) depending on the brands.
          `,
      },
      {
        question: "Will Ayykori cost me anything?",
        answer: `No, Ayykori costs you absolutely nothing! Opening an account and using this platform is free of cost.
          `,
      },
      {
        question: "Will my cashback amount expire?",
        answer: `No, your cashback amount will never expire.
          `,
      },
    ],
  },
  {
    title: "Earning",
    data: [
      {
        question: "How can I earn from Ayykori?",
        answer: `Cashback is a site where you can get some portion of your cash after spending some money from more than 500 Brands and your desirable Products. Cashback service is available only in Bangladesh.",You can earn money from Ayykori in 2 ways:-
          a. Affiliate : by promoting a Brand's/ product's link and whenever there is a successful purchase from your link you get a certain Affiliate .
          b. Refer: by referring your friends to Ayykori, you can earn 10% of your friend's ( your referred person) first month's earnings and cashback. (per person max referral bonus 1000 but)`,
      },
      {
        question: "How to create a brand's link?",
        answer: `You must Sign in to create a brand's link. Click the “Share and Earn” option from the homepage. You will find lots of brands there. Below each brand logo, you will get a button “Go to Band”, click it and then you will go to the brand’s landing page. Click “Commossion link”  to create the brand’s link. Now you can share the link with your friends and family.`,
      },
      {
        question: "How to create a product link?",
        answer: `Product Affiliate link You must Sign in to create a Product link. Click the “Share and Earn” option from the homepage. Click the top middle option "Make Product Link”, you will find a bar here. Copy any product’s link from Ayykori’s brands, then paste the link on the bar to generate the link to the product. Now you can share the link with your friends and family.`,
      },
      {
        question: "When will I receive my earnings?",
        answer: `Check “Earning Balance” from Dashboard to know the updates of earning repor.tGo to Brand’s landing page to know the expected timeline of getting Affiliate .`,
      },
      {
        question: "Where can I see my earnings report?",
        answer: `Check “Earning Balance” from Dashboard to know the updates of earning report.`,
      },
      {
        question: "Where should I share the links?",
        answer: `You can share the links anywhere you want. Social media platforms (Whatsapp, Messenger, IMO, Facebook, Instagram, TikTok, youtube, etc.) are preferred to have a better result.`,
      },
      {
        question: "Do I get anything if there is no purchase from my link?",
        answer: `You don't get anything if there is no purchase from your link.`,
      },
    ],
  },
  {
    title: "Profile",
    data: [
      {
        question: "How do I change my basic information?",
        answer: `If you want to change your basic information, you need to hover on the “Help” button and select the “Customer Support” option. Then you need to click on “Change Personal Info” and fill up the form. Our respective department will contact you then.`,
      },
    ],
  },
];
