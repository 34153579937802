import { useFormik } from "formik";
import Cookies from "js-cookie";
import React, { useEffect } from "react";
import { toast } from "react-hot-toast";
import { BsPhone } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import {
  initialPopupModalShow,
  phoneNumberModalHide,
} from "../../features/modal/modalSlice";
import { useUpdateUserMutation } from "../../features/user/userApiSlice";
import { phoneNumberValidationSchema } from "../../validation/Schema";

const PhoneNumberModal = () => {
  const access_token = Cookies.get("ayykori");
  const dispatch = useDispatch();
  const show2 = useSelector((state) => state.modal.show2);
  const initialValues = {
    phone: "",
  };
  const [
    updateUser,
    {
      isLoading: isLoadingUser,
      isError: isErrorUser,
      isSuccess: isSuccessUser,
    },
  ] = useUpdateUserMutation();
  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      initialValues: initialValues,
      validationSchema: phoneNumberValidationSchema,
      onSubmit: async (values, action) => {
        updateUser({ access_token, data: values });
        // action.resetForm();
      },
    });
  useEffect(() => {
    if (isSuccessUser && !isErrorUser) {
      toast.success("Phone Number Added!!");
      dispatch(phoneNumberModalHide());
      dispatch(initialPopupModalShow());
    }
  }, [isSuccessUser, isErrorUser, dispatch]);
  return (
    <>
      <div
        className={`w-screen h-screen bg-[rgba(9,30,66,0.2)] ${
          show2 ? "flex" : "hidden"
        } items-center justify-center fixed top-0 left-0 right-0 bottom-0 z-[10000] `}
      >
        <div className="w-screen h-screen flex justify-center backdrop-blur-[3px]  items-center gap-x-4 md:gap-x-8">
          <div className="w-[80%] sm:w-[60%] md:w-[50%] lg:w-[30%] h-[30vh] sm:h-[30vh] md:h-[50vh] flex justify-center items-center bg-white backdrop-blur-[5px] rounded-md">
            <form
              onSubmit={handleSubmit}
              className="w-[80%] md:w-[80%] mx-auto md:my-7"
            >
              <div className="">
                <h1 className="text-gray-500 text-base md:text-xl text-center">
                  Never Miss a Deal!
                </h1>
                <p
                  className="text-sm text-gray-400 text-center
              "
                >
                  Enter Your Number Now and Get Access to Exclusive Cashback
                  Offers.
                </p>
                <div className="w-full flex flex-col gap-y-1 my-2 mt-3 text-sm">
                  <label
                    htmlFor=""
                    className="text-gray-500 text-sm md:text-base"
                  >
                    Phone Number
                  </label>
                  <div className=" w-full text-gray-500 flex items-center border gap-2 bg-white rounded-md px-3 py-1 md:py-2">
                    <BsPhone size={20} />
                    <input
                      type="text"
                      name="phone"
                      value={values.phone}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      placeholder="Enter Your Number"
                      className="outline-none border-none w-full  bg-transparent text-gray-500"
                    />
                  </div>
                  {errors.phone && touched.phone ? (
                    <p className="text-xs text-red-500">{errors.phone}</p>
                  ) : null}
                </div>
                <button
                  type="submit"
                  disabled={isLoadingUser}
                  className="w-full bg-primaryColor px-3 py-1 md:py-2 text-white rounded-sm"
                >
                  {isLoadingUser ? "Loading.." : "Save"}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default PhoneNumberModal;
