import React from "react";

import ContactUsForm from "../ContactUs/ContactUsForm";
import ContactUsGif from "./ContactUsGif";

const ContactUs = () => {
  return (
    <div className="w-full px-3 md:px-[55px] mt-24 md:mt-40 mb-10">
      <div className="">
        <div className="">
          <h1 className="text-gray-700 text-base md:text-2xl font-medium font-outfit">
            Contact With Us
          </h1>
          <p className=" pt-1 text-xs md:text-base text-gray-500 ">
            We are here to help you. Please feel free to <br /> contact us with
            your queries.
          </p>
        </div>

        <div className="my-6 flex flex-wrap-reverse md:grid md:grid-cols-2 gap-2   ">
          <ContactUsForm />

          <ContactUsGif />
        </div>
        <div className=" ">{/* <AllContactEmail /> */}</div>
      </div>
    </div>
  );
};

export default ContactUs;
