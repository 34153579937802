import { apiRequest } from "../apiRequest/apiRequest";

export const profitCalculationApiSlice = apiRequest.injectEndpoints({
  endpoints: (builder) => ({
    getCommissionCategory: builder.query({
      query: (data) => {
        const { access_token, fullLink } = data;

        return {
          url: `/get-commission-category`,
          method: "POST",
          headers: {
            Authorization: `Bearer ${access_token}`,
            "Content-Type": "application/json;charset=UTF-8",
          },
          body: {
            fullLink,
          },
        };
      },
      providesTags: ["CommissionCalculation"],
    }),
    calculateProfit: builder.mutation({
      query: (data) => {
        const { access_token, bodyData } = data;
        let device = localStorage.getItem("app") && "app";
        bodyData.device = device;
        return {
          url: `/create-calculate-profit`,
          method: "POST",
          headers: {
            Authorization: `Bearer ${access_token}`,
            "Content-Type": "application/json;charset=UTF-8",
          },
          body: bodyData,
        };
      },
      invalidatesTags: ["CommissionCalculation"],
    }),
    getAllCalculationList: builder.query({
      query: (data) => {
        const { access_token, source, page, limit } = data;
        return {
          url: `/get-commission-calculate-list?source=${source}&page=${page}&limit=${limit}`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${access_token}`,
            "Content-Type": "application/json;charset=UTF-8",
          },
        };
      },
      providesTags: ["CommissionCalculation"],
    }),
    commissionCategory: builder.mutation({
      query: (data) => {
        const { access_token, fullLink } = data;
        console.log(fullLink);
        return {
          url: `/get-commission-category`,
          method: "POST",
          headers: {
            Authorization: `Bearer ${access_token}`,
            "Content-Type": "application/json;charset=UTF-8",
          },
          body: {
            fullLink,
          },
        };
      },
      providesTags: ["CommissionCalculation"],
    }),
  }),
});

export const {
  useGetCommissionCategoryQuery,
  useCalculateProfitMutation,
  useGetAllCalculationListQuery,
  useCommissionCategoryMutation,
} = profitCalculationApiSlice;
