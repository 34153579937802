import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  phone: "",
  user: "",
  prevSend: false,
  time: 0,
};

export const authHelperSlice = createSlice({
  name: "authHelperSlice",
  initialState,
  reducers: {
    userPhone: (state, action) => {
      state.phone = action.payload.phone;
    },
    user: (state, action) => {
      state.user = action.payload.user;
    },
    prevSendData: (state, action) => {
      state.prevSend = action.payload.prevSend;
    },
    validTime: (state, action) => {
      state.time = action.payload.time;
    },
  },
});

// Action creators are generated for each case reducer function
export const { userPhone, user, validTime, prevSendData } =
  authHelperSlice.actions;

export default authHelperSlice.reducer;
