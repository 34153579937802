import React from "react";

const AboutApp = () => {
  return (
    <div className=" w-full px-3 md:px-[55px] my-10 space-y-4">
      <h1 className=" text-gray-800 text-2xl font-medium">About the app</h1>
      <p className=" text-xs text-gray-600 md:text-sm">
        Introducing AyyKori, the ultimate cashback app that rewards you for
        shopping!
      </p>
      <p className="my-2 text-xs text-gray-600 md:text-sm">
        With AyyKori, you can earn cashback on every purchase you make at over
        100+ brands. It's simple, just find your desired brand, click on "Go
        to Brand" icon, and you'll land on the "Know Your Brand" page where
        you can learn all about the brand and the estimated time for cashback.
        Click on "Get Cashback" and make your purchase on the brand's website
        or app. You can pay with MFS, bank transfer, or cash on delivery, and
        still receive cashback unless you return or cancel the product.
      </p>
      <p className="my-2 text-xs text-gray-600 md:text-sm">
        Track your earnings in real-time with our advanced technologies and
        view your cashback amount details in your dashboard under the "My
        Balance" option. We disburse your cashback as per the "Know Your
        Brand" page's timeline, and you can receive it in your preferred
        payment method such as Bkash, Nagad, or bank transfer.
      </p>
      <p className="my-2 text-xs text-gray-600 md:text-sm">
        Share brand deals with your friends and family, and earn more than
        30000+ BDT once people buy from your link! Refer your friends to join
        AyyKori using your refer link and earn commission on their earnings up
        to 10%! You can even book schedule and do free workshops with
        affiliate marketing industry experts to boost your earnings.
      </p>

      <p className="my-2 text-xs text-gray-600 md:text-sm">
        With AyyKori, you'll never miss out on cashback opportunities again.
        Download our app now and start earning up to 15% cashback on your
        purchases!
      </p>

    </div>
  );
};

export default AboutApp;
