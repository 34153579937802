import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const BaseURL = process.env.REACT_APP_BASE_URL_IMAGE_SERVER;

export const imageCreateApiSlice = createApi({
  reducerPath: "imageCreateApiSlice",
  baseQuery: fetchBaseQuery({
    baseUrl: BaseURL,
  }),
  endpoints: (builder) => ({}),
});
