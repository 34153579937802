import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isLogin: false,
  user: "",
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    isLoginUser: (state) => {
      state.isLogin = true;
    },
    isLogoutUser: (state) => {
      state.isLogin = false;
    },
    setUserDetails: (state, action) => {
      state.user = action.payload.user;
    },
    removeUserDetails: (state, action) => {
      state.user = undefined;
    },
  },
});

// Action creators are generated for each case reducer function
export const { isLoginUser, isLogoutUser, setUserDetails } = authSlice.actions;

export default authSlice.reducer;
