import Cookies from "js-cookie";
import React from "react";
import { AiOutlineLink } from "react-icons/ai";
import ProductCard from "../../../components/Card/ProductCard";
import Error from "../../../components/Error/Error";
import NoDataFound from "../../../components/NodataFound/NoDataFound";
import ProductCardSkeleton from "../../../components/Skeleton/ProductCardSkeleton";
import { useGetFavProductsQuery } from "../../../features/favProducts/favProductApiSlice";

const EarningFavouriteProductsPage = () => {
  const access_token = Cookies.get("ayykori");
  const { data, isLoading, isSuccess, isError } =
    useGetFavProductsQuery(
      {
        access_token,
        favproduct: "earning",
        page: 1,
        limit: 10,
      },
      { refetchOnReconnect: true }
    ) || {};

  let content = null;
  // handle loading case
  if (isLoading && !isSuccess) {
    content = (
      <>
        <div
          className=" grid grid-cols-1 sm:grid-cols-2  md:grid-cols-3 lg:grid-cols-4
      gap-y-4 gap-x-4 mt-10  overflow-y-auto scroll-smooth 
    scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-white 
    scrollbar-thumb-rounded-full scrollbar-track-rounded-full scrollbar-w-1"
        >
          {Array(8)
            .fill()
            ?.map((item, i) => (
              <ProductCardSkeleton key={i} />
            ))}
        </div>
      </>
    );
  }
  // handle success case
  if (!isLoading && isSuccess && !isError) {
    content = (
      <>
        <div
          className=" grid grid-cols-1 sm:grid-cols-2  md:grid-cols-3 lg:grid-cols-4
      gap-y-4 gap-x-4 mt-10  overflow-y-auto scroll-smooth 
    scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-white 
    scrollbar-thumb-rounded-full scrollbar-track-rounded-full scrollbar-w-1"
        >
          {data?.resData?.map((item, i) => (
            <ProductCard
              data={{
                id: item?.ProductDetails[0]?._id,
                image: item?.ProductDetails[0]?.productImg,
                name: item?.ProductDetails[0]?.productTitle,
                price: item?.ProductDetails[0]?.productPrice,
                rating: item?.ProductDetails[0]?.productRating,
                category: item?.ProductDetails[0]?.category,
                subCategory: item?.ProductDetails[0]?.subCategory,
                productDetails: item?.ProductDetails[0]?.productDetails,
                productLink: item?.ProductDetails[0]?.productLink,
                discount: item?.ProductDetails[0]?.productProfitInPercent,
                profit: item?.ProductDetails[0]?.earningProductProfitAmount,
                whichBasisFav: "earning",
                buttontitle: "Affiliate Link",
                Icon: AiOutlineLink,
                favId: item?._id,
              }}
            />
          ))}
        </div>
      </>
    );
  }
  // handle error case
  if (!isLoading && isError) {
    content = (
      <>
        <Error />
      </>
    );
  }
  // handle empty case
  if (!isLoading && isSuccess && data?.resData?.length === 0) {
    content = (
      <>
        <NoDataFound />
      </>
    );
  }
  return (
    <div className="p-8">
      <h1 className="text-lg text-gray-500 font-outfit font-medium">
        Earning Favourite Products
      </h1>
      <p className="text-base text-gray-400 font-outfit font-light">
        Lorem ipsum dolor, sit amet consectetur adipisicing elit. Exercitationem
        ut repellat ad aut quibusdam incidunt.
      </p>
      {content}
    </div>
  );
};

export default EarningFavouriteProductsPage;
