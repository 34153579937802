import React from "react";

const BrandPageLeftPanel = ({ response }) => {
  const { data } = response;
  const brandDetails = data?.resData[0];
  return (
    <div className="basis-full md:basis-[35%] md:border rounded-lg  md:p-6">
      <div className="mb-4">
        <h1 className="text-gray-700 font-semibold font-outfit text-sm md:text-lg">
          About the Brand
        </h1>
        <p className="text-xs md:text-base font-normal text-gray-500 font-outfit">
          {brandDetails?.aboutTheBrand}
        </p>
      </div>
      <div className="mb-2 md:mb-4">
        <h1 className="text-gray-700 font-semibold font-outfit text-sm md:text-lg">
          Specialities of the Brand{" "}
        </h1>
        <p className=" text-xs md:text-base font-normal text-gray-500 font-outfit">
          {brandDetails?.specialities}
        </p>
      </div>
    </div>
  );
};

export default BrandPageLeftPanel;
