import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  sharelink: "",
};

export const sharelinkSlice = createSlice({
  name: "sharelink",
  initialState,
  reducers: {
    changeShareLink: (state, action) => {
      state.sharelink = action.payload;
    },
    resetShareLink: (state) => {
      state.sharelink = "";
    },
  },
});

// Action creators are generated for each case reducer function
export const { changeShareLink, resetShareLink } = sharelinkSlice.actions;

export default sharelinkSlice.reducer;
