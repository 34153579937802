import { apiRequest } from "../apiRequest/apiRequest";

export const visitorApiSlice = apiRequest.injectEndpoints({
  endpoints: (builder) => ({
    getVisitorTracking: builder.query({
      query: (ip) => {
        // console.log(ip);
        return {
          url: `/analytice/create-visitor-user?ip=${ip}`,
          method: "GET",
          headers: {
            //   Authorization: `Bearer ${access_token}`,
            "Content-type": "application/json;charset=UTF-8",
          },
        };
      },
      async onQueryStarted(arg, { queryFulfilled }) {
        // console.log("onQueryStarted function called");
        try {
          const result = await queryFulfilled;

          localStorage.setItem(
            "ip",
            JSON.stringify({
              ip: result.data.visitorData.ipAddress,
            })
          );
        } catch (error) {
          // Handle errors if needed
        }
      },
      providesTags: ["visitors"],
    }),
  }),
});

export const { useGetVisitorTrackingQuery } = visitorApiSlice;
