import React from "react";

const ApplyButton = ({ children, link }) => {
  return (
    <a
      href={link && link}
      target="_blank"
      without
      rel="noreferrer"
      className="w-fit px-12 py-2 bg-primaryColor rounded-md text-white tracking-wide font-medium hover:bg-[#01675ed0]"
    >
      {children}
    </a>
  );
};

export default ApplyButton;
