import React from 'react'

const AppOverViewCard = ({ data }) => {
  return (
    <div className=" w-full  my-5 rounded-lg ">
      <div className="w-full  h-full mx-auto ">
        <img className="w-full h-full rounded-lg object-cover" src={data} alt="" />
      </div>
    </div>
  );
}

export default AppOverViewCard