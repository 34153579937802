import copyToClipboardFunctionTest from "./testCopy";

const modernCopyToClipboardFunction = async (textToCopy) => {
  try {
    await navigator.clipboard.writeText(textToCopy);
    // console.log("Text copied to clipboard:", textToCopy);
  } catch (err) {
    copyToClipboardFunctionTest(textToCopy);
    // console.error("Unable to copy to clipboard:", err);
    // Handle the error or provide user feedback
  }
};

export default modernCopyToClipboardFunction;
