import { apiRequest } from "../apiRequest/apiRequest";

export const trackDevicesApiSlice = apiRequest.injectEndpoints({
  endpoints: (builder) => ({
    createDeviceTracking: builder.mutation({
      query: (newData) => {
        return {
          url: "/device-event-log/create",
          method: "POST",
          body: newData,
          headers: {
            "Content-Type": "application/json;charset=UTF-8",
          },
        };
      },
    }),
  }),
});

export const { useCreateDeviceTrackingMutation } = trackDevicesApiSlice;
