import popup_website_img_1 from "../../Assets/images/initial-popup/Website/1.jpg";
import popup_website_img_2 from "../../Assets/images/initial-popup/Website/2.jpg";
import popup_website_img_3 from "../../Assets/images/initial-popup/Website/3.jpg";
import popup_website_img_4 from "../../Assets/images/initial-popup/Website/4.jpg";
import popup_website_img_5 from "../../Assets/images/initial-popup/Website/5.jpg";
import popup_website_img_6 from "../../Assets/images/initial-popup/Website/6.jpg";
import popup_app_img_1 from "../../Assets/images/initial-popup/App/1.jpg";
import popup_app_img_2 from "../../Assets/images/initial-popup/App/2.jpg";
import popup_app_img_3 from "../../Assets/images/initial-popup/App/3.jpg";
import popup_app_img_4 from "../../Assets/images/initial-popup/App/4.jpg";
import popup_app_img_5 from "../../Assets/images/initial-popup/App/5.jpg";
import popup_app_img_6 from "../../Assets/images/initial-popup/App/6.jpg";

export const popup_website_image = [
    popup_website_img_1,
    popup_website_img_2,
    popup_website_img_3,
    popup_website_img_4,
    popup_website_img_5,
    popup_website_img_6,
]
export const popup_app_image = [
    popup_app_img_1,
    popup_app_img_2,
    popup_app_img_3,
    popup_app_img_4,
    popup_app_img_5,
    popup_app_img_6,
]
