import React from "react";
import { Link } from "react-router-dom";

const CampaignCard = ({ campaignVideo, link }) => {
  return (
    <div className=" w-full   mx-auto my-8 ">
      <Link to={link} className="cursor-pointer">
        <img
          className="w-full h-full object-contain rounded-lg"
          src={campaignVideo}
          alt=""
        />

        {/* <VideoPlayer videoSource={campaignVideo} /> */}
      </Link>
    </div>
  );
};

export default CampaignCard;
