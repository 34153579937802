import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { useSearchParams } from "react-router-dom";
import AboutApp from "../../components/AppRelated/AboutApp";
import AppDownloadBanner from "../../components/Banner/AppDownloadBanner";
import AppPageSlider from "../../components/Slider/AppPageSlider";
import CommonLayout from "../../layout/CommonLayout";

const DownloadAppPage = () => {
  const [query] = useSearchParams();
  const refCode = query?.get("a_id");

  useEffect(() => {
    if (refCode) {
      localStorage?.setItem("referral_code", refCode);
    }
  }, [refCode]);

  return (
    <div>
      <Helmet>
        <title>App Download | Ayykori</title>
      </Helmet>
      <CommonLayout>
        <AppDownloadBanner />
        <AppPageSlider />
        <AboutApp />
      </CommonLayout>
    </div>
  );
};

export default DownloadAppPage;
