import homePageGif from "../Assets/images/campaign-gif/Home Page Gif.gif";
import darazBanner from "../Assets/images/campaign-gif/daraz-banner.jpg";
import othobaBanner from "../Assets/images/campaign-gif/othoba-banner.jpg";

export const shebaBannerGif = [
    {
        image: "https://res.cloudinary.com/dxodgllun/image/upload/v1706765905/ayykori_management/default/lcowanc4myt29amqx3wh.gif",
        id: "1",
    },
];
export const campaignBanner = [
    {
        image: homePageGif,
        id: "1",
    },
    {
        image: darazBanner,
        id: "2",
    },
    {
        image: othobaBanner,
        id: "3",
    },
];
