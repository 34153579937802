import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  show1: false,
  show2: false,
  show3: false,
};

export const modalSlice = createSlice({
  name: "modal",
  initialState,
  reducers: {
    knowInsideModalShow: (state) => {
      state.show1 = true;
    },
    knowInsideModalHide: (state) => {
      state.show1 = false;
    },
    phoneNumberModalShow: (state) => {
      state.show2 = true;
    },
    phoneNumberModalHide: (state) => {
      state.show2 = false;
    },
    initialPopupModalShow: (state) => {
      state.show3 = true;
    },
    initialPopupModalHide: (state) => {
      state.show3 = false;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  knowInsideModalShow,
  knowInsideModalHide,
  phoneNumberModalShow,
  phoneNumberModalHide,
  initialPopupModalShow,
  initialPopupModalHide,
} = modalSlice.actions;

export default modalSlice.reducer;
