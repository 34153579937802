import React from "react";
import { HiOutlineArrowLeft } from "react-icons/hi";
import { Link } from "react-router-dom";

const BackButton = ({ pathname }) => {
  return (
    <div>
      <Link
        to={`/my-account`}
        className="w-fit md:hidden flex items-center gap-x-2 mb-2"
      >
        <HiOutlineArrowLeft className="animate-pulse" />
        Go Back
      </Link>
    </div>
  );
};

export default BackButton;
