import { useFormik } from "formik";
import Cookies from "js-cookie";
import React, { useEffect } from "react";
import { toast } from "react-hot-toast";
import { AiOutlineMail, AiOutlineUser } from "react-icons/ai";
import { BiPhone } from "react-icons/bi";
import ButtonLoader from "../../Loading/ButtonLoader";
import { useCreateContactMutation } from "../../features/contactus/contactApiSlice";
import { contactUsSchema } from "../../validation/Schema";

const ContactUsForm = () => {
  const access_token = Cookies.get("ayykori");
  const [createContact, { isLoading, isError, isSuccess }] =
    useCreateContactMutation() || {};
  const initialValues = {
    fullName: "",
    phoneNumber: "",
    email: "",
    description: "",
  };

  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      initialValues,
      validationSchema: contactUsSchema,
      onSubmit: async (values, action) => {
        createContact({ access_token, bodyData: values });
        // action.resetForm();
      },
    });

  useEffect(() => {
    if (isSuccess) {
      toast.success("Message Sent Successfully!!");
    }
    if (isError) {
      toast.error("Something Went Wront!!");
    }
  }, [isError, isSuccess]);
  return (
    <>
      {/* <div className="grid grid-cols-1 md:grid-cols-2 items-center"> */}

      <div className="pt-5 w-full">
        <form onSubmit={handleSubmit} className="flex flex-col gap-4" action="">
          <div>
            <div className="border flex gap-1 items-center px-2 rounded-md">
              <AiOutlineUser className="text-gray-400"></AiOutlineUser>
              <input
                className="py-2 w-full text-sm focus:outline-none"
                type="text"
                name="fullName"
                value={values.fullName}
                onBlur={handleBlur}
                onChange={handleChange}
                placeholder="Full Name"
              />
            </div>
            {errors.fullName && touched.fullName ? (
              <p className="text-sm text-red-500">{errors.fullName}</p>
            ) : null}
          </div>
          <div>
            <div className="border flex gap-1 items-center px-2 rounded-md">
              <BiPhone className="text-gray-400"></BiPhone>
              <input
                className="py-2 w-full text-sm focus:outline-none"
                type="text"
                name="phoneNumber"
                value={values.phoneNumber}
                onBlur={handleBlur}
                onChange={handleChange}
                placeholder="Phone number"
              />
            </div>
            {errors.phoneNumber && touched.phoneNumber ? (
              <p className="text-sm text-red-500">{errors.phoneNumber}</p>
            ) : null}
          </div>
          <div>
            <div className="border flex gap-1 items-center px-2 rounded-md">
              <AiOutlineMail className="text-gray-400"></AiOutlineMail>
              <input
                className="py-2 w-full text-sm focus:outline-none"
                type="text"
                name="email"
                value={values.email}
                onChange={handleChange}
                onBlur={handleBlur}
                placeholder="Email Address"
              />
            </div>
            {errors.email && touched.email ? (
              <p className="text-sm text-red-500">{errors.email}</p>
            ) : null}
          </div>

          <div>
            <textarea
              rows={7}
              className="py-2 px-6 rounded-md text-sm border w-full focus:outline-none"
              type="text"
              name="description"
              value={values.description}
              onChange={handleChange}
              placeholder="Description"
            />
            {errors.description && touched.description ? (
              <p className="text-sm text-red-500">{errors.description}</p>
            ) : null}
          </div>
          <div className="pt-2">
            {isLoading ? (
              <button
                disabled={true}
                className=" bg-primaryColor py-2 px-6 text-white rounded-md
               duration-300 whitespace-pre hover:bg- "
              >
                Loading <ButtonLoader />
              </button>
            ) : (
              <button
                type="submit"
                className=" bg-primaryColor py-2 px-6 text-white rounded-md
               duration-300  hover:bg- "
              >
                Send Message
              </button>
            )}
          </div>
        </form>
      </div>

      {/* </div> */}
    </>
  );
};

export default ContactUsForm;
